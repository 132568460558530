import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LegosManager from './LegosManager';

dayjs.extend(relativeTime)

export default class LegosOtherManager extends LegosManager {
  constructor(props) {
    super(props);

    this.views = this.service('services/legos-other');
  }

  service(path) {
    // Make any component that works with legos use the service using alternative legos
    if(path === 'services/legos') {
      path = 'services/legos-other';
    }

    return super.service(path);
  }
}
