import React from 'react';

import {StateColors} from './StateBadge'
import MultiButtonSwitch from '../common/editors/MultiButtonSwitch';

export default function DataIssueStateSwitch({ value, onChange }) {
  const options = ['new', 'inprogress', 'solved', 'blocked', 'wontfix'];

  return <MultiButtonSwitch value={value} onChange={onChange} options={options} optionColorMap={StateColors}/>
}
