import React from 'react';
import _ from 'lodash';

import ManualFeaturesOilEditor from "./manuals-extraction/ManualFeaturesOilEditor";
import TaskEditorManualLegoExtraction from "./TaskEditorManualLegoExtraction";

export default class TaskEditorManualLegoOil extends TaskEditorManualLegoExtraction {
  constructor(props) {
    super(props);

    this.state.pdfInitialSearch = /\b((SAE )?(0|5|10|15|20)w-? ?\d0|ESE-\w+-\w+|WSS\W\w+\W\w+|GM\d{4}\w|(API )?S[MN]|API [SC][A-Z]|ILSAC( GF-\w+)?|API|ACEA( ?[ABC]\w)?|dexos( ?[12])?|b71 \d+|9\.55535(-\w+)?|LL.?\d\d|MB.?\d{3}\.\d+|RN.?\d{4}|VW.?5\d\d.\d\d|STJLR|MS.?\d+|GM \d{4}m)\b/.source
  }

  renderExtractionControls() {
    let { files, file, fileHash, autosuggestions, autosuggestionsExplanation } = this.state;

    if (file) {
      return <ManualFeaturesOilEditor manual={file}
                                      key={fileHash + autosuggestions}
                                      initialChanges={autosuggestions}
                                      onSave={async (changedManual, legosUpdates, markTaskDone) => this.save(changedManual, legosUpdates, markTaskDone)}/>
    } else {
      return "No manual"
    }
  }
}
