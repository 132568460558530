import React from 'react';
import _ from 'lodash';

import CreatableSelect from 'react-select/lib/Creatable';
import { IntentionTag } from './IntentionTag';

const getOption = (props) => {
  let { innerProps, isSelected, isFocused } = props;
  let { innerRef, ...filteredProps } = innerProps;

  return <div {...filteredProps} className={`p-1 ${isSelected || isFocused ? 'bg-light-primary' : ''}`}>
    <IntentionTag intention={props.value}/>
  </div>;
};

const getMultiValueLabel = (props) => <IntentionTag intention={props.children}/>;

export default function IntentionsSelector({ onChange, value: values, ...props }) {
  const handleSelectChange = (value, actionMeta) => onChange(_.map(value, 'value'));

  const options = IntentionTag.types().map(t => ({ label: t, value: t }));

  return <CreatableSelect
    closeOnSelect={true}
    isMulti
    components={{ Option: getOption, MultiValueLabel: getMultiValueLabel }}
    onChange={handleSelectChange}
    options={options}
    placeholder="Intention..."
    menuShouldScrollIntoView={false}
    styles={{ menu: (provided) => ({... provided, minWidth: '200px'})}} // Needed in lego editor
    value={_.map(values, v => ({ label: v, value: v }))}
    {...props}
  />;
}
