import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import CRUDPage from './CRUDPage';
import LegoContextSummary from "../components/lego/LegoContextSummary";
import {ManualsFiltersBar} from "../components/common/ManualsFiltersBar";
import {Icon} from "../components/common/Icon";
import submenuManuals from "./menus/submenu-crawling";
import CountryFlag from "../components/common/CountryFlag";
import MongoDocEditor from "../components/lego/MongoDocEditor";
import {CrawlingFiltersBar} from "../components/crawling/CrawlingFiltersBar";
import {SearchFilterButton} from "../components/common/SearchFilterButton";
import ModelContextEditor from "../components/lego/ModelContextEditor";
import SingleTextEditor from "../components/common/editors/SingleTextEditor";

export class CrawlingInfoboxFiltersBar extends Component {
  constructor(props) {
    super(props);
    this.state = {filter: this.props.filter || {}};

    this.debouncedFireOnChange = _.debounce(this.fireOnChange, 500);
  }

  onChange(field, value) {
    let newFilter = _.cloneDeep(this.state.filter || {});

    if(value)
      newFilter[field] = value;
    else
      delete newFilter[field];

    this.setState({filter: newFilter});

    this.debouncedFireOnChange();
  }

  fireOnChange() {
    this.props.onChange(this.state.filter);
  }

  render() {
    let { title } = this.state.filter;

    return <div>
      <span style={{width: '350px', display: 'inline-block'}} className={'zoom-75 ml-3'}>
        <SingleTextEditor value={title} onChange={(newText)=> this.onChange('title', newText)}/>
      </span>
    </div>;
  }
}

function JSONPreview({ value }) {
  if (_.isObject(value)) {
    return <table className={'JSONTable'}>
      <tbody>{
        _.map(value, (val, key) => <tr key={key}>
          <td><span className={`${_.isArray(value) ? 'text-info monospace' : 'text-info'} small`}>{key}</span></td>
          <td>
            <JSONPreview value={val}/>
          </td>
        </tr>)
      }
      </tbody>
    </table>
  } else {
    if(value && _.isString(value) && value.match(/\/\/.*\.(jpg|jpeg|png)$/i)) {
      return <img className={'img-200'} src={value} title={value} alt={value}/>
    } else {
      return <span className={'small'}>{(value || "").toString()}</span>;
    }
  }
}

export default class CrawlingWikiViewer extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/scrapping/wikiboxes');
    this.submenu = submenuManuals;

    this.canDelete = false;
    this.canCreate = false;
    this.canEdit = false;
  }

  getFacetFiltersComponent() {
    return <CrawlingInfoboxFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  buildObjectQuery() {
    let query = super.buildObjectQuery();
    if(query.title) {
      query.title = { $regex: query.title, $options: 'i' };
    }
    return query;
  }

  getColumnsDefinition(objects) {
    return [
      {content: 'Source', className: ''},
      {content: 'Model', className: 'text-center'},
      {content: 'Lang', className: ''},
      // {content: 'Pic', className: ''},
      // {content: 'Country/Lang', className: ''},
      // {content: '', className: ''},
      {content: 'Details', className: ''},
    ]
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    await super.fetchObjects({$sort: {uploadDate: -1}});
  }

  zoomImage(imgUrl) {
    this.modalActionsBus.emit('open', <div className={'text-center'}>
      <img src={imgUrl}/>
    </div>, {});
  }

  getObjectColumns(manual, definition) {
    let { _id, title, wikiUrl, wikiLang, infoboxes } = manual;

    // let viewStateClass = 'success' : 'warning';

    return [
      <td key={'source'} className={'context-column text-left'}>
        <div className={'text-secondary small'}>{_id}</div>
        <div>
          {/*<span className={'text-info small ml-2'}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>*/}
        </div>
        {/*{page} <CountryFlag country={country}/>*/}
      </td>,

      <td key={'ctx'} className={'context-column text-left'}>
        {/*<LegoContextSummary context={[{modelId: `${make}-${model}`}]}/>*/}
        <a href={wikiUrl} target={'_blank'}>{ title }</a>
      </td>,

      <td key={'wikiLang'} className={'context-column text-left'}>
        <span className={'badge badge-secondary'}>{ wikiLang }</span>
      </td>,
      //
      // <td key={'year'}>
      //   {year}
      // </td>,
      //
      // <td key={'cover'} style={{width: '200px'}} className={'text-center'}>
      //   { imgUrl ? <img src={imgUrl} className={'img-zoom-btn'} style={{ height: '100px' }} alt={_id} title={_id} onClick={() => this.zoomImage(imgUrl)}/> : null }
      // </td>,

      <td key={'keys'}>
        {_.map(infoboxes, ({title, images, ... infobox}, i) => {
          images = images || {};
          return <div key={i}>
            <table className={'JSONTable'}>
              <tr>
                <td style={{ width: '200px' }}>
                  {_.map(images.urls, url => <JSONPreview value={url}/>)}
                  {images['img-footer'] ? <div className={'small text-secondary'}>{images['img-footer']}</div> : null}
                </td>
                <td>
                  <h6 className={'bg-light-primary p-1'}>{title}</h6>
                  <JSONPreview value={infobox}/>
                </td>
              </tr>
            </table>
          </div>;
        })}
      </td>,

    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <MongoDocEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}
