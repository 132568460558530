import React from 'react'


export const legoStateColors = {
  published: 'success',
  inprogress: 'warning',
  unpublished: 'danger',
}

export function LegoStateBadge({ state }) {
  state = state || 'published';
  let color = legoStateColors[state] || 'dark';
  return <span className={`badge badge-${color}`}>{state}</span>;
}
