import React, {useState} from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import ModelContextEditor from "../components/lego/ModelContextEditor";
import HighlightedText from "../components/common/HighlightedText";
import {NotesManager} from "../components/notes/NotesManager";
import {useNotes} from "../components/notes/useNotes";
import {IconButton} from "../components/common/IconButton";
import {useAllNotes} from "../components/notes/useAllNotes";
import {SwitchInput} from "../components/common/SwitchInput";
import submenuTagging from './menus/submenu-tagging';

const linguisticNoteId = (text, semanticKey) => `${text}|${semanticKey}`;

function SemanticNode({node: { id, count, semanticKey, text, slug, children }, notesManager, filter}) {
  let childrenHierarchy = null;

  // Linguistic level comments should only depend on linguistic and semantic
  let noteId = linguisticNoteId(text, semanticKey);

  let notes = useNotes(notesManager, noteId);

  const tagLinguistic = (tag) => {
    tag = tag || prompt('Enter tag');
    if(tag) {
      notesManager.createOn(noteId, tag);
    }
  };

  let tags = _.map(notes, ({_id, content}) => {
    const deleteClick = () => notesManager.remove(_id);
    return <span key={content+'active'} className={'ml-2 btn badge badge-warning'} onClick={deleteClick}>
      {content}
    </span>;
  });

  const activeTags = _.map(notes, 'content');

  let otherTags =  _.map(_.difference(['linguistic', 'hierarchy', 'not_problem', 'dup_A', 'dup_B', 'dup_C', 'dup_D'], activeTags), tag => {
    return <span key={tag+'inactive'} className={'ml-2 btn badge badge-outline badge-warn'} onClick={() => tagLinguistic(tag)}>
      {tag}
    </span>;
  });

  if (children && children.length) {
    childrenHierarchy = <div style={{ zoom: '0.95' }} className={'ml-5 mb-2'}>
      <RecursiveHierarchy data={children} filter={filter} notesManager={notesManager}/>
    </div>
  }

  return <div key={semanticKey+activeTags}>
    <div className={tags.length ? 'bg-light-warn'  : ''}>
      <div className={'text-primary mr-3 text-right inline-block'} style={{ width: '50px' }}>{count || '-'}</div>

      {filter ? <HighlightedText regex={filter} text={text}/> : text}

      { tags }

      <span className={'parent-hover-visible'}>
        {otherTags}

        <span level={'primary'} className={'btn badge badge-warning badge-outline ml-2'} onClick={() => tagLinguistic()}>...</span>

        <span className={'small text-secondary ml-2 monospace'}>{semanticKey}</span>
      </span>
    </div>
    {childrenHierarchy}
  </div>;
};

function RecursiveHierarchy({ data, filter, notesManager }) {
  let rows = _.map(data, (node,i) => <SemanticNode key={node.semanticKey} node={node} notesManager={notesManager} filter={filter}/>);
  return <div>{rows}</div>
}

function TagSubsemantics({filteredData, limit, modelId, notesManager, filterRegex, setLimit}) {
  const [onlyDone, setOnlyDone] = useState(true);
  const [onlyIssues, setOnlyIssues] = useState(false);
  const allNotes = useAllNotes(notesManager);
  const notes = _.values(allNotes);

  let remainingLimit = limit;
  const btnSeeMore = <span className={'btn btn-primary'} onClick={() => setLimit(limit*2)}>
      More results
    </span>;

  const doneNoteId = (slug) => `TAG_DONE|${modelId}|${slug}`;
  const isSlugDone = (slug) => _.find(allNotes[doneNoteId(slug)], n => n.content.done === true)
  const markSlugDone = (slug) => notesManager.createOn(doneNoteId(slug), {done: true});

  const notDoneTags = _.filter(filteredData, ({slug}) => !isSlugDone(slug));
  let dataToShow = filteredData;

  if(onlyDone) {
    dataToShow = notDoneTags;
  }

  if(onlyIssues) {
    const semHasIssues = ({semanticKey, text}) => allNotes[linguisticNoteId(text, semanticKey)]?.length;

    const filterSemsWithNotes = subsemantics => _.filter(subsemantics, s => semHasIssues(s) || _.some(s.children, c => semHasIssues(c)));
    // Only leave tags with linguistics with notes (or linguistic children with notes)
    dataToShow = _.filter(dataToShow, ({subsemantics}) => filterSemsWithNotes(subsemantics).length > 0)

    // Remove those subsemantics without notes
    dataToShow = _.map(dataToShow, ({subsemantics, ... other}) => {
      let withIssues = filterSemsWithNotes(subsemantics);
      withIssues = _.map(withIssues, ({children, ... subothers}) => ({children: filterSemsWithNotes(children), ... subothers}))
      return ({ subsemantics: withIssues, ...other });
    })
  }

  return <div>
    <div className={'row d-flex align-items-center no-gutters bg-secondary px-2 pb-1'}>
      <SwitchInput value={onlyDone} onChange={(val) => setOnlyDone(!onlyDone)}>
        Hide done
      </SwitchInput>

      <SwitchInput value={onlyIssues} onChange={(val) => setOnlyIssues(!onlyIssues)} className={'ml-2'}>
        Only show issues
      </SwitchInput>

      <div className={'ml-2 badge badge-success'}>
        {notDoneTags.length} / {filteredData.length}
      </div>

    </div>

    <div className={'p-2 alternative-font font-weight-normal'}>
      {
        _.map(dataToShow, ({ value, subsemantics, semantics, slug, landingsShare }) => {
          if(remainingLimit <= 0)
            return null;

          let doneNoteId = slug;

          let tagIsDone = isSlugDone(slug);

          if(onlyDone && tagIsDone) {
            return <div>{slug} DONE</div>
          }

          let markDoneBtn = <IconButton level={'success'} icon={'done'} onClick={() => markSlugDone(slug)}>
            Mark done
          </IconButton>;

          let row = <div key={slug} className={`${tagIsDone ? 'bg-light-success' : ''}`}>
            <div className={`bg-light-${subsemantics.length ? 'secondary' : 'danger'} pl-2`}>
              <strong>{value}</strong>
              <span className={'text-secondary small monospace ml-2'}>{semantics.join(' - ')}</span>
              <span className={'badge badge-info ml-2'}>{landingsShare.toFixed(2)}%</span>

              { tagIsDone ? 'DONE' : markDoneBtn }
            </div>

            <RecursiveHierarchy data={subsemantics} filter={filterRegex} limit={remainingLimit} notesManager={notesManager}/>
          </div>;

          remainingLimit = remainingLimit - subsemantics.length;
          return row;
        })
      }

      {
        remainingLimit === 0 ? btnSeeMore : null
      }
    </div>
  </div>
}

export default class TaggedSemanticsExplorer extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;

    this.submenu = submenuTagging;
    this.state.modelId = 'Ford-Focus'
    this.state.limit = 40;
    this.state.filter = '';

    this.semantics = this.service('services/tagging/hierarchy');
    this.notes = new NotesManager('tagging-linguistics', this.getLoggedUserSignature());
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.fetchData());
  }

  async fetchData() {
    let [res] = await Promise.all([
      this.semantics.find({ query: { modelId: this.state.modelId } }),
    ])

    this.setState({ data: res})
  }

  modelChanged({ modelId }) {
    this.setState({modelId}, () => this.runAsync(this.fetchData()));
  }

  filterChanged(e) {
    this.setState({filter: e.target.value, limit: 40  })
  }

  renderPageBody() {
    const { data, filter, limit } = this.state

    let filteredData = data || [];
    let filterRE = null;
    if(filter) {
      try {
        filterRE = new RegExp(filter, 'i')
        filteredData = _.map(_.filter(data, (obj) => JSON.stringify(obj).match(filterRE)), data => ({
          ... data,
          subsemantics: _.filter(data.subsemantics, (obj) => JSON.stringify(obj).match(filterRE))
        }));
      } catch (err) {
      }
    }

    let res = <div>
      <div className={'row d-flex align-items-center no-gutters bg-secondary pb-1'}>
        <div className={'p-2 col-4'}>
          <input type={'text'} className={'form-control form-control-sm'} value={filter || ''}
                 placeholder={'Filter...'}
                 onChange={(e) => this.filterChanged(e)}/>
        </div>
        <div className={'p-2 col-4'}>
          <ModelContextEditor value={{modelId: this.state.modelId}} onChange={this.modelChanged.bind(this)}/>
        </div>
      </div>

      <TagSubsemantics filteredData={filteredData}
                      filterRegex={filterRE}
                      modelId={this.state.modelId}
                      notesManager={this.notes}
                      limit={limit}
                      setLimit={n => this.setState({limit: n})}/>
    </div>;

    return res;
  }
}
