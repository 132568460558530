import React, { Component } from 'react';
import { TypeTag } from '../lego/TypeTag';
import { IntentionTag } from '../lego/IntentionTag';
import { SearchFilterButton } from './SearchFilterButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import _ from 'lodash';
import MultiButtonSwitch from './editors/MultiButtonSwitch';
import { LocalesSelector } from '../lego/LocalesSelector';

export class ManualsFiltersBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange(field, value) {
    let newFilter = _.cloneDeep(this.props.filter || {});
    if (_.isEmpty(value)) {
      delete newFilter[field];
    } else {
      newFilter[field] = _.cloneDeep(value);
    }
    this.props.onChange(newFilter);
  }

  render() {
    let { manualCategory, context, locales, isoLanguage } = this.props.filter || {};

    return <span>
      <span style={{width: '350px', paddingBottom: '2px'}} className={'inline-block zoom-75 ml-1'}>
        <ModelContextEditor value={context} onChange={(newContext)=> this.onChange('context', newContext)}/>
      </span>

      <span className={'zoom-90 ml-1'}>
        <LocalesSelector value={locales} onChange={v => this.onChange('locales', v)}/>
      </span>

      <span className={'zoom-90 ml-1'}>
        <MultiButtonSwitch nullable value={manualCategory} options={[{value: 'MANUAL', label: 'Owners Manuals'},{value: 'MANUAL_DE_TALLER', label: 'Workshop'}, {label: 'Other', value: {$ne: 'MANUAL'}}]} onChange={v => this.onChange('manualCategory', v, true)}/>
      </span>


      <span className={'zoom-90 ml-1'}>
        <MultiButtonSwitch value={isoLanguage} nullable options={[{value:  { $regex: "^ES" }, label: 'Lang ES'}, {value:  { $regex: "^EN" }, label: 'Lang EN'}, {value:  { $regex: "^PT" }, label: 'Lang PT'}]} onChange={v => this.onChange('isoLanguage', v)}/>
      </span>

    </span>;
  }
}
