import _ from 'lodash';
import React from 'react';

import LegoLabel from '../lego/LegoLabel';

export function LabelsSummary({ labels, ...other }) {
  return <span {...other}>
    {_.map(labels, label => <LegoLabel className={'mr-1'} label={label} key={label}/>)}
  </span>;
}
