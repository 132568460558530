import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import { IconButton } from '../common/IconButton';
import JsonGUIEditor from '../common/editors/JsonGUIEditor';
import { UserAvatarLive } from '../common/UserAvatarLive';

export default function DataFeedbackRefsEditor({ value, onChange, vehicle }) {
  const { page } = useContext(LegoAdminPageContext);
  const dataFeedbackService = page.service('/services/data/data-feedback/');

  const [max, setMax] = useState(6);

  // const onClick = () => {
  //   page.openModal(<div className={'position-relative border-top'} style={{ margin: '-0.5em', minHeight: '500px' }}>
  //     <DataIssuesFinder vehicleContext={vehicle} onRowClick={(issue) => {
  //       page.closeModal();
  //       onChange(_.uniq([...(value || []), issue._id]));
  //     }}/>
  //   </div>, { title: 'Pick a data issue' });
  // };
  let [editing, setEditing] = useState(false);
  let [dataFeedbacks, setDataFeedbacks] = useState([])

  useEffect(() => {
    page.runAsync(dataFeedbackService.find({ query: {_id: {$in: value}} }).then(res => setDataFeedbacks(res)));
  }, value);

  if(!editing) {
    return <LeftRightLayout>
    <span>
      { value.length > max ? <span className={'text-primary'}>{value.length}  feedbacks: </span> : null}
      {_.map((value || []).slice(0, max), issueId => <span key={issueId} className={'no-wrap'}>
          <UserAvatarLive id={(_.find(dataFeedbacks, df => df._id === issueId) || {}).userId}/>
          <span className={'zoom-75 badge badge-secondary'}>{issueId}</span>
          <IconButton level={'danger'} icon={'clear'} size={'sm'} onClick={() => onChange(_.without(value, issueId))}/>
        </span>
      )}
      { value.length > max ? <span className={'small text-secondary'}>... {value.length - max} more... </span> : null}
    </span>
      <IconButton icon={'edit'} onClick={() => setEditing(true)}/>
    </LeftRightLayout>;
  } else {
    return <JsonGUIEditor value={value} onChange={onChange}/>
  }
}
