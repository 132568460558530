import _ from 'lodash';

import React from 'react';

export function PaginationLimit({ value, onChange }) {
  let breaks = [50, 100, 200, 500, 1000];

  return <span><span className={'small btn-group'}>
    {_.map(breaks, n => {
      if (n === value) {
        return <span key={n} className={'btn btn-sm btn-primary'}>{n}</span>;
      } else {
        return <span key={n} className={'btn btn-sm btn-dark'} onClick={() => onChange(n)}>{n}</span>;
      }
    })}
    </span>
    <span className={'align-middle ml-1 small'}>resultados</span>
  </span>;
}
