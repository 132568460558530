import React, { Component } from 'react';

export class TypeTag extends Component {
  static types() {
    return ['image', 'text', 'data', 'list', 'fusebox', 'fuseboxTemplate', 'subarticle','article', 'symptom']
  }


  render() {
    let { type } = this.props;

    let colors = {
      image: 'success',
      text: 'primary',
      data: 'secondary',
      list: 'gris-blue',
      article: 'violeta',
      subarticle: 'rosa',
      fuseboxTemplate: 'gris-blue',
      symptom: 'ladrillo'
    }


    return <span className={`badge badge-${colors[type] || 'primary'}`}>{type}</span>;
  }
}
