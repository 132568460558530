import GlobalDataCache from './GlobalDataCache';

let globalCache = null;

export default class DataProcessDefinitionsDataCache extends GlobalDataCache {
  async loadData() {
    if(!globalCache) {
      const query = {
        $select: {
          processType: true,
          updatedAt: true,
          impactMetric: true
        }
      };

      const dataProcesses = await client.service('/services/data-process-definition').find({query})
      globalCache = dataProcesses;
    }
    return globalCache
  }
}
