import _ from 'lodash'
import TextareaAutosize from 'react-textarea-autosize';
import React, { Component } from 'react';
import JSON5 from 'json5';

import SingleTextEditor from './SingleTextEditor';

export default class JsonTextEditor extends Component {
  constructor(props) {
    super(props);

    let jsonText;

    if (this.props.inline) {
      jsonText = JSON.stringify(this.props.value);
    } else {
      jsonText = JSON.stringify(this.props.value, true, 4);
    }

    this.state = { valid: true, jsonText };
  }

  static stringify(obj, inline) {
    if (inline) {
      return JSON.stringify(obj);
    } else {
      return JSON.stringify(obj, true, 2);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let propsJSON = JsonTextEditor.stringify(nextProps.value, nextProps.inline);

    if ((prevState.valid && prevState.json) || !_.isEqual(nextProps.value, prevState.previousJSON)) {
      return { jsonText: propsJSON, previousJSON: nextProps.value };
    } else {
      return { jsonText: prevState.jsonText };
    }
  }

  textChanged(newText) {
    let json = null;
    let valid = true;

    try {
      json = JSON.parse(newText);
    } catch (err) {
      if(!newText) {
        json = undefined;
      } else {
        try {
          json = JSON5.parse(newText);
          // newText = JsonTextEditor.stringify(json, this.props.inline)
        } catch (err) {
          valid = false;
        }
      }
    }

    this.setState({ jsonText: newText, previousJSON: valid ? json : this.state.previousJSON, valid });

    if(valid) {
      this.props.onChange(json);
    }
  }

  render() {
    let { rows, className, onChange, value, inline, ... otherProps } = this.props;
    className = 'form-control form-control-sm monospace ' + (className || '') + (this.state.valid ? '' : ' bg-light-danger text-danger');

    if (inline) {
      return <SingleTextEditor
        className={className}
        onChange={text => this.textChanged(text)}
        value={this.state.jsonText}
        {...otherProps}
      />;
    } else {
      return <TextareaAutosize
        minRows={1}
        maxRows={rows || 6}
        className={className}
        onChange={e => this.textChanged(e.target.value)}
        value={this.state.jsonText}
        {...otherProps}
      />;
    }
  }
}
