import React from 'react';

import {IconButton} from "../../common/IconButton";

export class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let { icon, label, active } = this.props;

    let className = 'RichEditor-styleButton';
    if (active) {
      className += ' RichEditor-activeButton';
    }

    if (icon) {
      return <span className={className}>
        <IconButton level={active ? 'active' : 'secondary'} description={label} onClick={this.onToggle} icon={icon}/>
      </span>
    } else {
      return (
        <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
      );
    }
  }
}
