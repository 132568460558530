import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export async function copyText(text) {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText((text || '').toString());
    // dispatch a custom event with the copied text as detail
    window.dispatchEvent(new CustomEvent('customClipboardTextCopied', { detail: text }));
  } else {
    throw new Error('Clipboard API not allowed or missing in browser');
  }
}

export default function ClipboardListener({ onChange }) {
  let [lastClipboard, setLastClipboard] = useState(null);

  useEffect(() => {
    let handler = async () => {
      if (window.navigator.clipboard) {
        try {
          let text = await window.navigator.clipboard.readText();

          if (text !== lastClipboard) {
            setLastClipboard(text);
            console.log('Clipboard changed ' + (text ? '(has data)' : '(EMPTY)'));
            onChange(text);
          }
        } catch (err) {
          console.warn('Could not access clipboard', err);
        }
      }
    };

    // event listener for the custom event
    let explicitCopyHandler = (event) => {
      if (event.detail !== lastClipboard) {
        setLastClipboard(event.detail);
        console.log('Clipboard changed ' + (event.detail ? '(has data)' : '(EMPTY)'));
        onChange(event.detail);
      }
    };

    // Check the first time if there is clipboard data
    handler();

    window.addEventListener('focus', handler);
    // listen to the custom event
    window.addEventListener('customClipboardTextCopied', explicitCopyHandler);
    // TODO: This doesn not seem to do anything, is it supported by Chrome?
    // navigator.clipboard.addEventListener('clipboardchange', this.clipboardChangeHandler);

    return () => {
      window.removeEventListener('focus', handler);
      window.removeEventListener('customClipboardTextCopied', explicitCopyHandler);
    };
  }, [onChange]);

  return null;
}
