import React from 'react';
import _ from 'lodash';

import TaskEditorManualLegoExtraction from "./TaskEditorManualLegoExtraction";
import ManualFeaturesOilQuantityEditor from "./manuals-extraction/ManualFeaturesOilQuantityEditor";
import ManualFeaturesTirePressureEditor from './manuals-extraction/ManualFeaturesTirePressureEditor';

export default class TaskEditorManualLegoTirePressure extends TaskEditorManualLegoExtraction {
  constructor(props) {
    super(props);

    this.state.pdfInitialSearch = /lbf?.?(pulg|in)\.?2?|kgf.?cm2?|psi|kpa|\bbar(es)?\b/.source
  }

  renderExtractionControls() {
    let { files, file, fileHash, autosuggestions, autosuggestionsExplanation } = this.state;

    if (file) {
      return <ManualFeaturesTirePressureEditor manual={file}
                                      key={fileHash + autosuggestions}
                                      initialChanges={autosuggestions}
                                      onSave={async (changedManual, legosUpdates, markTaskDone) => this.save(changedManual, legosUpdates, markTaskDone)}/>
    } else {
      return "No manual"
    }
  }
}
