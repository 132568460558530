import React, {Component} from 'react';

import FileReaderInput from './FileReaderInput';

export default function LoadFileButton({classes, title, onChange}) {
  title = title === undefined ? "Load JSON" : title;
  classes = classes || "btn btn-link btn-sm mr-1";

  return <FileReaderInput as="text" id="my-file-input" onChange={onChange} accept="application/json">
      <button className={classes} type='button' title={title}>
        <i className={'material-icons align-middle mr-1'}>insert_drive_file</i>
        <span>{title}</span>
      </button>
    </FileReaderInput>
}
