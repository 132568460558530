import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import ClusterIdEditor from './ClusterIdEditor';
import { SwitchInput } from '../common/SwitchInput';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import { ImagePreview } from '../images/ImagePreview';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import OpinautosUrlEditor from '../common/editors/OpinautosUrlEditor';
import LegoIdEditor from '../lego/LegoIdEditor';

function TemplatesWithClusterId({ clusterId }) {
  const { page } = useContext(LegoAdminPageContext);

  let [templates, setTemplates] = useState(null);

  const legosService = page.service('/services/legos');
  // const legoFuseboxClusteringService = page.service('/services/legos/fusebox-templates-clustering');

  useAsyncEffect(async () => {
    if (clusterId) {
      let templates = await page.runAsync(legosService.find({
        query: {
          'data.imageClusters': clusterId,
          'type': 'fuseboxTemplate'
        }
      }));

      setTemplates(templates);
    } else {
      setTemplates(null);
    }
  }, [clusterId]);

  return templates?.length ? <span className={'inline-block align-top ml-2'}>
    <div className={'text-secondary'}>Templates:</div>
    <div className={'p-1 border bg-light rounded'}>
    {
      _.map(templates, t => <span className={'inline-block mx-1'} key={t._id}>
        { t.data[0].boxDiagramImg ? <ImagePreview url={t.data[0].boxDiagramImg.url} zoom img50 cover/> : null }
        <div><a className={'small'} target={'_blank'} href={`/legos/all?editing="${t._id}"`}>Edit</a></div>
      </span>)
    }</div>
  </span> : null;
}

let flags = ['hasManual', 'manualHasDiagram', 'fuseboxHasCoverDiagram'];

export default function FuseboxIssueDataEditor({ value, year, modelId,onChange }) {
  value = value || {};

  let clusterEditor = <ClusterIdEditor value={value.clusterId} filterByYear={year} modelId={modelId}
                                       onChange={(id) => onChange({ ...value, clusterId: id })}/>;

  let fuseboxTemplates = <TemplatesWithClusterId clusterId={value.clusterId}/>;

  const flagsEditor = _.map(flags, flag => {
    const onSwitchChange = v => {
      if (v === undefined) {
        onChange(_.omit(value, flag));
      } else {
        onChange({ ...value, [flag]: v });
      }
    };

    return <div key={flag}>
      <SwitchInput triState={true} value={value[flag]}
                   onChange={onSwitchChange}>{flag}</SwitchInput>
    </div>;
  });

  let legoEditor = <LegoIdEditor value={value.fuseboxLegoId} placeholder={'Final Fusebox Lego ID'} small
                                 warnDifferentLocale={true}
                                     className={'my-2'}
                                     onChange={(txt) => onChange({ ...value, fuseboxLegoId: txt })}/>;

  let fuseboxUrlEditor = <OpinautosUrlEditor value={value.fuseboxUrl} placeholder={'Final fusebox url...'} small
                                           onChange={(txt) => onChange({ ...value, fuseboxUrl: txt })}/>;


  let usefulFuseboxUrlEditor = <OpinautosUrlEditor value={value.usefulFuseboxUrl} placeholder={'Useful fusebox url...'}
                                                 small
                                                 onChange={(txt) => onChange({ ...value, usefulFuseboxUrl: txt })}/>;

  return <div className={'row'}>
    <div className={'col-7'}>
      {clusterEditor}
      {fuseboxTemplates}
      {flagsEditor}
    </div>
    <div className={'col-5'}>
      <div className={'pt-1 small'}>Partial help fusebox url:</div>
      {usefulFuseboxUrlEditor}

      <div className={'mt-2 pt-1 small'}>Solution fusebox id:</div>
      {legoEditor}
      {fuseboxUrlEditor}

    </div>
    {/*{ _.isEmpty(other) ? null :  <JsonGUIEditor value={other} {...props}/> }*/}
  </div>;
}
