import React from 'react';

import MultiValueSelector from "./MultiValueSelector";
import CountryFlag from "../CountryFlag";
import { getCountryName } from "../../../../../components/interop/countryCodeConverterFront"

export default function MultiCountryEditor(props) {

  const options = [
    'de',
    'ar',
    'br',
    'ca',
    'cl',
    'co',
    'cu',
    'ec',
    'es',
    'us',
    'fr',
    'in',
    'it',
    'jp',
    'mx',
    'pe',
    'gb',
    'se',
    'th',
    'gb',
    've',
    'global',
    'latam',
    'eu',
    '*'
  ];

  const getOption = (props) => {
    let { innerProps, isSelected, isFocused } = props;
    let { innerRef, ...filteredProps } = innerProps;
    return <div {...filteredProps} className={'p-1 ' + ((isSelected || isFocused) ? 'bg-light-primary' : '')}>
      <CountryFlag countryCode={props.value}/> {getCountryName(props.label)}
    </div>;
  };

  const getValue = (props) => {
    return <span className={`small`} title={props.data.value}><CountryFlag countryCode={props.data.value}/></span>;
  };

  return <MultiValueSelector {...props} getOption={getOption} getValue={getValue} options={options}/>
}

