import React from 'react';

import MultiButtonSwitch from '../common/editors/MultiButtonSwitch';

const contributionStateColors = {
  approved: 'success',
  rejected: 'danger',
  duplicated: 'warning',
  pending: 'primary',
}

export default function ContributionStateSwitch({value, onChange}) {
  const options = [ "approved", "duplicated", "pending", "rejected" ];

  return <MultiButtonSwitch value={value} multi={true} onChange={onChange} options={options} optionColorMap={contributionStateColors}/>
}
