import _ from 'lodash'
import TextareaAutosize from 'react-textarea-autosize';
import React, {Component} from 'react';

import SingleTextEditor from './SingleTextEditor';
import {Icon} from "../Icon";
import {IconButton} from "../IconButton";
import {SwitchInput} from "../SwitchInput";
import JsonTextEditor from "./JsonTextEditor";
import LeftRightLayout from "../layout/LeftRightLayout";

const defaultNewObj = { fieldName: '' };

const defaults = {
  'object': defaultNewObj,
  'array': [''],
  'string': '',
  'number': 1,
  'boolean': true,
  'null': null,
  'undefined': undefined
}

const typeIcons = {
  'object': 'data_object',
  'array': 'data_array',
  'string': 'text_fields',
  'number': 'filter_1',
  'boolean': 'check_box',
  'null': 'check_box_outline_blank',
  'undefined': 'error'
}

export default class JsonGUIEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jsonTextMode: !_.isObject(props.value)
    };
  }

  addKey() {
    let copy = _.cloneDeep(this.props.value);
    if (_.isArray(copy)) {
      copy.push("");
    } else {
      let newKey = prompt(`Enter key name:`);
      if (newKey) {
        copy[newKey] = "";

        // Put selected field in edit mode if editOnClick mode
        if(this.props.editOnClick) {
          this.setState({editingField: newKey})
        }
      }
    }
    this.props.onChange(copy);
  }

  addItemToKey(fromKey, val) {
    let copy = _.cloneDeep(this.props.value);
    if (_.isArray(val)) {
      copy[fromKey].push("");
    } else {
      let newKey = prompt(`Enter key name:`);
      if (newKey) {
        copy[fromKey][newKey] = "";
      }
    }
    this.props.onChange(copy);
  }

  removeKey(key) {
    let copy = _.cloneDeep(this.props.value);
    // Little hack to reuse the component both for objects and arrays
    if (_.isArray(copy)) {
      copy.splice(parseInt(key), 1);
    } else {
      delete copy[key];
    }
    this.props.onChange(copy);
  }

  duplicate(key, val) {
    let copy = _.cloneDeep(this.props.value);
    copy.push(_.cloneDeep(val));
    this.props.onChange(copy);
  }

  changeKey(key, newValue) {
    let copy = _.cloneDeep(this.props.value);
    copy[key] = newValue;
    this.props.onChange(copy);
  }

  renameKey(key) {
    let newKey = prompt(`Enter new name for '${key}':`, key);
    if (newKey) {
      let copy = _.cloneDeep(this.props.value);

      if (newKey in copy) {
        return alert(`There is already a field named '${newKey}'`)
      }

      copy[newKey] = copy[key];
      delete copy[key];

      // Put selected field in edit mode if editOnClick mode
      if(this.props.editOnClick) {
        this.setState({editingField: newKey})
      }

      this.props.onChange(copy);
    }
  }

  changeType(key, val, type) {
    if (!_.includes(_.values(defaults), val) && !_.isEqual(val, ['']) && !_.isEqual(val, defaultNewObj)) {
      if (!confirm("You will loose the value: " + JSON.stringify(val))) {
        return;
      }
    }

    const types = _.keys(defaults);

    let newValue = defaults[types[(types.indexOf(type) + 1) % types.length]];

    let copy = _.cloneDeep(this.props.value);
    copy[key] = newValue;
    this.props.onChange(copy);
  }

  getFieldDisplayRow(val, key, obj) {
    let type = this.autodetectValueType(val, key, obj);
    let valueEditor;

    const onChange = (newVal) => this.changeKey(key, newVal);

    const schema = this.props.schema || {};

    if(!valueEditor) {
      switch (type) {
        case 'custom':
          valueEditor = <span className={`btn btn-${val ? 'primary' : 'secondary'} btn-xs`}
                              onClick={() => onChange(!val)}>{val.toString()}</span>;
          break;
        case 'boolean':
          valueEditor = <span className={`btn btn-${val ? 'primary' : 'secondary'} btn-xs`}
                              onClick={() => onChange(!val)}>{val?.toString()}</span>;
          break;
        case 'number':
          valueEditor = <div>
            <span className={'mt-1 zoom-90 text-info text-right'}>{val?.toString()}</span>
          </div>;
          break;
        case 'string':
          valueEditor = <div className={'mt-1 zoom-90'}>{val}</div>;
          // valueEditor = val;
          break;
        case 'array':
          if (_.every(val, v => !_.isObject(v))) {
            valueEditor = <div className={'mt-1 text-secondary'}>{_.map(val, v => JSON.stringify(v)).join(', ')}</div>;
          } else {
            valueEditor =
              <JsonGUIEditor editOnClick={true} className={''} value={val} onChange={onChange} compact={true}
                             schema={schema}/>;
          }
          break;
        case 'object':
          valueEditor =
            <JsonGUIEditor editOnClick={true} className={' my-1 '} value={val} onChange={onChange} compact={true}
                           schema={schema}/>;
          break;
        case 'null':
          valueEditor = <span className={'badge badge-dark'}>Null</span>;
          break;
        case 'undefined':
          valueEditor = <span className={'badge badge-secondary'}>undefined</span>;
          break;
        default:
          valueEditor = <pre>{JSON.stringify(val, true, 2)}</pre>;
      }
    }

    return <tr key={key} className={`clickable-row type-${type}`} onClick={() => this.setState({editingField: key})}>
      <td className={'field'}>{key}{_.isArray(this.props.value) ? '' : ':'}</td>
      <td className={'buttonsBefore'}>
      </td>
      <td className={'type'}></td>
      <td className={'value'}>{valueEditor}</td>
      <td className={'typeAfter'}></td>
      <td className={'buttonsAfter'}>
      </td>
    </tr>
  }

  autodetectValueType(val, key, obj) {
    const schema = this.props.schema || {};

    if (schema[key]) {
      if(_.isString(schema[key])) {
        return schema[key];
      } else {
        return 'custom';
      }
    } else if (val === false || val === true) {
      return 'boolean';
    } else if (_.isNumber(val)) {
      return 'number';
    } else if (_.isString(val)) {
      return 'string';
    } else if (_.isArray(val)) {
      return 'array';
    } else if (_.isObject(val)) {
      return 'object';
    } else if (val === null) {
      return 'null';
    } else if (val === undefined) {
      return 'undefined';
    } else {
      return '?';
    }
  }

  getFieldEditorRow(val, key, obj) {
    let valueEditor = JSON.stringify(val);
    let type = this.autodetectValueType(val, key, obj);

    const onChange = (newVal) => this.changeKey(key, newVal);

    const schema = this.props.schema || {};

    const btnAddKey = <IconButton level={'success'}
                                  icon={'add_box'}
                                  description={"Add key-value"}
                                  className={'p-0 zoom-75'}
                                  onClick={() => this.addItemToKey(key, val)}/>;


    switch (type) {
      case '?':
        break;
      case 'custom':
        valueEditor = schema[key](val, onChange, obj);
        break;
      case 'boolean':
        valueEditor = <span className={`btn btn-${val ? 'primary' : 'secondary'} btn-xs`} onClick={() => onChange(!val)}>
        {val?.toString()}
      </span>;
        break;
      case 'number':
        valueEditor = <div style={{ maxWidth: '100px' }}>
            <SingleTextEditor small className={'zoom-90 text-info text-right'} value={val?.toString()}
              onChange={(str) => {
                try {
                  if (str) {
                    onChange(Number.parseFloat(str));
                  } else {
                    onChange(undefined);
                  }
                } catch (err) {}
              }}
            />
          </div>;
        break;
      case 'string':
        valueEditor = <SingleTextEditor small className={'zoom-90'}  placeholder={val == undefined ? '<undefined>' : ''} value={val} onChange={onChange} />;
        break;
      case 'array':
        if (val?.length > 0) {
          valueEditor = <JsonGUIEditor className={''} value={val} onChange={onChange} compact={true} schema={schema} />;
        } else {
          valueEditor = <div className={'mt-2 text-center'}>{btnAddKey}</div>;
        }
        break;
      case 'object':
        if (_.isEmpty(val)) {
          valueEditor = <div className={'mt-2 text-center'}>{btnAddKey}</div>;
        } else {
          valueEditor = <JsonGUIEditor className={' my-1 '} value={val} onChange={onChange} compact={true} schema={schema} />;
        }
        break;
      case 'null':
        valueEditor = <span className={'badge badge-dark'}>Null</span>;
        break;
      case 'undefined':
        valueEditor = <span className={'badge badge-secondary'}>undefined</span>;
        break;
    }

    const btnChangeType = <IconButton level={'secondary'}
                                      icon={typeIcons[type]}
                                      description={"Change field type"}
                                      className={'p-0 zoom-75'}
                                      onClick={() => this.changeType(key, val, type)}/>;

    const btnDuplicateKey = <IconButton level={'success'} icon={'copy_all'} title={'Duplicate'}
                                        className={'p-0 zoom-75'} onClick={() => this.duplicate(key, val)}/>

    return <tr key={key} className={`type-${type} ${!_.keys(obj).includes(key) && !_.isArray(obj) ? 'translucent' : ''}`}>
      <td className={'field'}
          onDoubleClick={() => this.renameKey(key)}>{key}{_.isArray(this.props.value) ? '' : ':'}</td>
      <td className={'buttonsBefore'}>
        {btnChangeType}
        {type === 'object' || type === 'array' ? btnAddKey : null}
      </td>
      <td className={'type'}></td>
      <td className={'value'}>{valueEditor}</td>
      <td className={'typeAfter'}></td>
      <td className={'buttonsAfter'}>
        <IconButton level={'danger'} icon={'clear'} className={'p-0 zoom-75'} onClick={() => this.removeKey(key)}/>
        { _.isArray(obj) ? btnDuplicateKey : null}
      </td>
    </tr>
  }

  render() {
    let { rows, className, onChange, value, schema, showFields, hideFields, editOnClick, compact, ...otherProps } = this.props;

    if (this.state.jsonTextMode) {
      return <div>
        <div className={'text-right'}>
          <span className={'btn btn-link btn-sm'} onClick={() => this.setState({ jsonTextMode: false })}>Switch to GUI editor</span>
        </div>
        <JsonTextEditor rows={rows || 20} value={value} onChange={onChange}/>
      </div>
    }

    let externalClassName = 'JsonGUIEditorTable ' + (className || '');


    let sortedRowKeys = _.isArray(value) ? _.map(value, (v, i) => i) : _.keys(value).sort();

    if(showFields) {
      sortedRowKeys = _.union(sortedRowKeys, showFields);
    }

    if(schema) {
      let keysInSchemaOrder = _.intersection(_.keys(schema), sortedRowKeys);
      sortedRowKeys = keysInSchemaOrder.concat(_.difference(sortedRowKeys, keysInSchemaOrder));
    }

    const fields = _.compact(_.map(sortedRowKeys, (key) => {
      let fieldVal = value[key];
      if (hideFields && _.includes(hideFields, key))
        return null;

      if(!editOnClick || this.state.editingField === key) {
        return this.getFieldEditorRow(fieldVal, key, value);
      } else {
        return this.getFieldDisplayRow(fieldVal, key, value);
      }
    }));

    if(_.isArray(value)) {
      externalClassName += ' border'
    }

    const editAsText = <LeftRightLayout>
      <IconButton level={'success'}
                  icon={'add_box'}
                  description={"Change field type"}
                  className={'p-0'}
                  onClick={() => this.addKey()}>Add key</IconButton>

      <span className={'btn btn-link btn-sm'} onClick={() => this.setState({ jsonTextMode: true })}>Edit as text</span>
    </LeftRightLayout>

    return <div className={externalClassName}>
      {compact ? null : editAsText}
      <table>
        <tbody>
        {fields}
        </tbody>
      </table>
    </div>
  }
}
