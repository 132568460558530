import _ from "lodash";

let globalKeyHandlers = [];

export class ShortcutsManager {
  constructor() {
    this.handlers = {}
    this.realHandlers = {}
    this.keyMappings = {}
  }

  registerActionHandlers(actions) {
    this.realHandlers = { ...this.realHandlers, ...actions }

    if (this.updateCallback) {
      this.updateCallback();
    }
  }

  static onAnyShortcut(callback) {
    globalKeyHandlers = _.uniq([... globalKeyHandlers, callback])
  }

  static offAnyShortcut(callback) {
    globalKeyHandlers = _.without(globalKeyHandlers, callback)
  }

  registerKeyMappings(mappings) {
    this.keyMappings = { ...this.keyMappings, ...mappings }

    _.each(mappings, (key, command) => {
      this.handlers[command] = (...args) => {
        const realHandler = this.realHandlers[command];

        _.each(globalKeyHandlers, cbk => cbk(... args));

        if (!realHandler) {
          console.warn(`Shortcut '${key}' invoked command '${command}' but not handler was registered`);
        } else {
          // by default we want to keep events from bubbling up
          if (realHandler(...args) === true) {
            return
          } else {
            return false;
          }
        }
      }
    })

    if (this.updateCallback) {
      this.updateCallback();
    }
  }

  onUpdate(callback) {
    this.updateCallback = callback;
  }
}
