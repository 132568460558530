import React from 'react';
import _ from 'lodash';

import { formatDistanceToNow } from 'date-fns';

import LivePage from './LivePage';
import LeftRightLayout from '../components/common/layout/LeftRightLayout';
import CountryFlag from '../components/common/CountryFlag';
import { IconButton } from '../components/common/IconButton';
import submenuEmails from './menus/submenu-emails';
import { PaginationLimit } from '../components/common/PaginationLimit';
import InputRegexFilter from '../components/common/editors/InputRegexFilter';

function CampaignBadge({campaign}) {
  let [c] = campaign.split('.');
  const bdg = {
    'registracionResponder': 'primary',
    'registracionAgregar': 'primary',
    'registracionDirecta': 'primary',
    'respuestaDenuncia': 'violeta'
  }[c] || 'secondary';
  return <span className={`badge badge-${bdg}`}>{campaign}</span>
}

export default class EmailLogViewer extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;

    this.submenu = submenuEmails;
    this.state.limit = 100;
    this.state.shownLimit = 100;
    this.state.filterRegex = '';

    this.emails = this.service('services/data/emails/log');

    this.refreshDebounced = _.debounce(this.refresh.bind(this), 300);
  }

  componentDidMount() {
    super.componentDidMount();
    this.refresh();
  }

  renderMailsTable() {
    let {emails, shownLimit, limit, filterRegex} = this.state;
    if (!emails) {
      return "Loading emails...";
    }

    let filteredEmails = emails;

    if(filterRegex) {
      try {
        filteredEmails = _.filter(filteredEmails, o => JSON.stringify(o).match(filterRegex))
      } catch(err) {
        filteredEmails = _.filter(filteredEmails, o => JSON.stringify(o).match(_.escapeRegExp(filterRegex)))
      }
    }

    let moreRow = null;
    if(filteredEmails.length > shownLimit) {
      moreRow = <tr><td colSpan={3} className={'text-center bg-primary'}>{filteredEmails.length - shownLimit} more emails not shown. Filter with regexp</td></tr>
    }

    return <table className={'table table-sm table-striped EmailLogTable'}>
      <thead>
      <tr>
        <th>To</th>
        <th>Campaign / EmailId</th>
        <th>Preview</th>
      </tr>
      </thead>
      <tbody>
      {
        _.map(filteredEmails.slice(0,shownLimit), (email) => {
          const { createdAt, from, fromEmail, toEmail, subject, bodyText, bodyHtml, metadata } = email;
          return <tr key={email.metadata.emailId} onClick={() => this.setState({ selectedEmail: email })}>
            <td>
              <div className={''}><IconButton icon={'account_box'}/> {toEmail}</div>
              <div className={'ml-2 small text-secondary'}>{metadata.userId}</div>
            </td>

            <td>
              <div>
                <CampaignBadge campaign={metadata.campaign}/>
              </div>
              <div className={'small text-secondary'}>
                <CountryFlag countryCode={metadata.lang}/>
                &nbsp; <span className={'badge badge-secondary'}>{metadata.idReport}</span>
                &nbsp; {metadata.emailId}
              </div>
            </td>

            <td className={'ColumnGmail'}>
              <LeftRightLayout>
                <strong>{from}</strong>
                <span className={'text-secondary'}>{formatDistanceToNow(new Date(createdAt))}</span>
              </LeftRightLayout>
              <div className={'text-ellipsis'} title={subject}><strong>{subject}</strong></div>
              <div className={'text-ellipsis text-secondary'} title={bodyText}>{bodyText}</div>
            </td>
          </tr>
        })
      }
      { moreRow }
      </tbody>
    </table>
  }

  renderSelectedEmail() {
    if (!this.state.selectedEmail) {
      return <div className={'h2 text-center m-3'}>Select an email</div>
    }

    return <div className={''}>
      <h5 className={'mt-3 mb-3 ml-2'}>{this.state.selectedEmail.subject}</h5>
      <iframe
        style={{ width: '100%', height: 'calc(100% - 100px)', border: 'none', position: 'absolute' }}
        id={'email'}
        name={'email'}
        sandbox={'allow-same-origin'}
        srcDoc={this.state.selectedEmail.bodyHtml}
      />
    </div>
  }

  refresh() {
    const query = { limit: this.state.limit };
    if (this.state.filterRegex) {
      query.jsonRegex = this.state.filterRegex.source;
    }

    this.runAsync(this.emails.find({ query }).then((res) => {
      this.setState({ emails: res });
    }));
  }

  renderPageBody() {
    return <div className={'EmailLogsViewer'}>
      <div className={'bg-secondary text-light p-1 d-flex'}>
        <PaginationLimit value={this.state.limit} onChange={n => this.setState({limit: n}, () => this.refresh())}/>
        <div className={'w-25 ml-4'}>
          <InputRegexFilter small value={this.state.filterRegex} onChange={(filterRegex) => this.setState({filterRegex}, () =>  this.refreshDebounced())}/>
        </div>
      </div>
      <div className={'LayoutLeftBar'} style={{ gridTemplateColumns: 'auto 700px' }}>
        <div className={'overflow-auto pos-relative'}>
          {this.renderMailsTable()}
        </div>
        <div className={'bg-light pos-relative'} style={{ 'width': '700px' }}>
          {this.renderSelectedEmail()}
        </div>
      </div>
      {/*<pre>{JSON.stringify(this.state.emails || 'Loading...', true, 2)}</pre>*/}
    </div>
  }
}
