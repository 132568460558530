import React from 'react';
import _ from 'lodash';

export default function EditObjectFormTable({ rows, object, onEditField, ...other }) {
  let mappedRows = _.map(rows, cols => _.isArray(cols[0]) ? cols : [cols]);
  let maxCols = _.max(_.map(rows, cols => cols.length));

  return <table className={'table table-sm'} {...other}>
    <tbody>
    {mappedRows.map((cols, j) => {
        let columns = _.flatMap(cols, ([field, ComponentClass, otherProps]) => [
          <td key={field + 'def'} className={'text-right pr-2 small text-secondary align-middle'}
              style={{ width: '140px' }}>{field}:</td>,
          <td key={field} colSpan={(maxCols * 2 - cols.length * 2) / cols.length + 1} className={'align-middle'} style={{minWidth: '300px'}}>
            <ComponentClass value={_.get(object, field)} onChange={v => onEditField(field, v)}{...(otherProps || {})}/>
          </td>]);

        return <tr key={j}>{columns}</tr>;
      }
    )}
    </tbody>
  </table>;
}
