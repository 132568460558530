import React from 'react';
import _ from 'lodash';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import DataFieldSelector from './DataFieldSelector';
import MultipleItemsEditor from '../lego/MultipleItemsEditor';
import ProcessStateChangeSelector from './ProcessStateChangeSelector';

export class TaskTemplateEditor extends React.Component {
  editField(fieldPath, newValue) {
    let updated = this.props.value;
    _.set(updated, fieldPath, newValue);
    this.props.onChange(updated);
  }

  render() {
    const { onChange, value, process, options, ...props } = this.props;

    const { id, inputs, outputs } = value || {};

    const dataFields = process.dataTypes;

    return <div className={'p-1 border rounded bg-light'}>
      <div>
        <SingleTextEditor placeholder={'Enter a task id...'} small className={'mb-1'}
                          onChange={this.editField.bind(this, 'id')}
                          value={id}/>
      </div>

      <div className={'row zoom-75 '}>
        <div className={'col-2'}>
          <span className={'text-secondary ml-1'}>Input</span>
          <DataFieldSelector fields={dataFields} onChange={(json) => this.editField(`inputs`, json)}
                             value={inputs}/>
        </div>

        <div className={'col-10'}>
          <div className={'row no-gutters text-secondary ml-1'}>
            <div className={'col-3'}>Output name</div>
            <div className={'col-6'}>Output state changes</div>
            <div className={'col-3'}>Output data</div>
          </div>
          <MultipleItemsEditor onChange={(json) => this.editField('outputs', json)}
                               value={outputs}
                               defaultNewItem={{ name: 'NEW_OUTPUT', inputs: [], outputs: [] }}
                               renderComponent={({ name, state, data }, props, i) => {
                                 return <div className={'row no-gutters'}>
                                   <div className={'col-3'}>
                                     <SingleTextEditor
                                                       onChange={(txt) => this.editField(`outputs.${i}.name`, txt)}
                                                       value={name}/>
                                   </div>

                                   <div className={'col-6 pl-2'}>
                                     <ProcessStateChangeSelector
                                       validStates={process.states}
                                       onChange={(json) => this.editField(`outputs.${i}.state`, json)}
                                       value={state}/>
                                   </div>

                                   <div className={'col-3 pl-2'}>
                                     <DataFieldSelector fields={dataFields}
                                                        onChange={(json) => this.editField(`outputs.${i}.data`, json)}
                                                        value={data}/>
                                   </div>
                                 </div>;
                               }}
          />
        </div>
      </div>
    </div>;
  }
}
