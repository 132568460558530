import _ from 'lodash'
import React, { Component } from 'react';

import TrimSpecsEditor from './TrimSpecsEditor';
import SingleTextEditor from '../../common/editors/SingleTextEditor';
import TrimSpec from './TrimSpec';
import TrimPacksEditor from "./TrimPacksEditor";
import {IconButton} from "../../common/IconButton";

const ReadOnlyTrim = React.memo(function({specs, fantasyName, packs, focusClick}) {
  const sortedSpecs = _.sortBy(_.toPairs(specs), ([key, val]) => key);
  const sortedSpecsControls = _.map(sortedSpecs, ([key, val]) => <TrimSpec key={key + val} className={'mr-1'} spec={key}
                                                                           value={val}/>);

  return <div className={'row no-gutters zoom-90'}>
    <div className={'col-3 text-right pr-3'} onClick={(e) => focusClick(e, 'name')}>
      <span className={''}>{fantasyName || '-'}</span>
    </div>
    <div className={'col-9'} onClick={(e) => focusClick(e, 'specs')}>
      {sortedSpecsControls}
      {_.map(packs, p => <span key={p}
                               className={'badge badge-pill badge-outline badge-danger ml-1'}>{(p || "???").toString()}</span>)}
    </div>
  </div>;
});

export class TrimEditor extends Component {
  constructor(props) {
    super(props);

    let { value, onChange } = props;
    let { modelId, year, fantasyName, specs } = value || {};
    const emptyTrim = !fantasyName && _.isEmpty(specs);

    this.state = {
      editing: emptyTrim && onChange,
      editingPart: 'name'
    };

    this.focusClick = this.focusClick.bind(this);
  }

  onFieldChange(field, newValue) {
    let copy = { ...this.props.value, [field]: newValue };
    this.props.onChange(copy);
  }

  escapeHandler(e) {
    if (e.key === 'Escape' || e.key === 'Enter') {
      this.setState({ editing: false });
    }
  }

  focusClick(clickEvent, trimPart) {
    if(this.props.onChange && !clickEvent.shiftKey) {
      this.setState({ editing: true, editingPart: trimPart });
    }
  }

  focusOut(trimPart) {
    setTimeout(() => {
      if(trimPart === this.state.editingPart) {
        this.setState({editing: false, addingPack: false})
      }
    }, 1)
  }

  showAddPack() {
    this.setState({ addingPack: true, editingPart: 'packs' });
  }

  render() {
    let { value, onChange } = this.props;
    let { modelId, year, fantasyName, specs, packs } = value || {};

    let { editing, editingPart, addingPack } = this.state;

    let row = null;

    if (editing) {
      let packsEditor = null;
      let showingPackEditor = addingPack || (packs || []).length;
      if(showingPackEditor) {
        packsEditor = <div>
          <TrimPacksEditor value={packs}
                           // autoFocus={editingPart === 'packs'}
                           onChange={(newPacks) => this.onFieldChange('packs', newPacks)}
                           onFocus={() => this.setState({ editingPart: 'packs' })}
                           onBlur={() => this.focusOut('packs')}
          />
        </div>;
      }

      return <div className={'zoom-90 bg-light-warn'} onKeyDown={this.escapeHandler.bind(this)}>
        <div className={'row no-gutters'}>
          <div className={'col-3'}>
            <SingleTextEditor autoFocus={editingPart === 'name'}
                              value={fantasyName}
                              onChange={(text) => this.onFieldChange('fantasyName', text)}
                              onFocus={() => this.setState({ editingPart: 'name' })}
                              onBlur={() => this.focusOut('name')}/>
          </div>
          <div className={'col-8'}>
            <TrimSpecsEditor value={specs}
                             autoFocus={editingPart === 'specs'}
                             onChange={(newSpecs) => this.onFieldChange('specs', newSpecs)}
                             onFocus={() => this.setState({ editingPart: 'specs' })}
                             onBlur={() => this.focusOut('specs')}/>

          </div>
          <div className={'col-1'}>
            {showingPackEditor ? null : <IconButton onMouseDown={this.showAddPack.bind(this)} icon={'library_add'} title={'Add pack'}/>}
          </div>
        </div>
        {packsEditor}
      </div>
    } else {
      return <ReadOnlyTrim specs={specs} fantasyName={fantasyName} packs={packs} focusClick={this.focusClick}/>;
    }
  }
}
