if(!window.config)
  window.config = {};

const config = window.config;

if (process.env.NODE_ENV !== 'production') {
  config.development = true;
}

export default config;
