import React, {Component} from 'react';

export default React.memo(function GridLayoutLeftBar({ children, className, offsetTop, even, style, twoThree, ... other }) {
  const arrChildren = React.Children.toArray(children);

  if (!arrChildren) {
    return <div className={'alert alert-danger'}>FullscreenHeaderGrid has to be used with extactly 2 children.</div>;
  }

  let [left, right] = arrChildren;

  let layoutClass = '';
  if (even) {
    layoutClass = 'LayoutLeftBar--even';
  } else if (twoThree) {
    layoutClass = 'LayoutLeftBar--2_3';
  }

  style = style || {};

  if(offsetTop) {
    style.height = `calc(100% - ${offsetTop}px)`;
  }

  if(left && right) {
    return <div className={`LayoutLeftBar ${layoutClass} ${className || ''}`} style={style} {...other}>
      <div className={'pos-relative overflow-auto'}>{left}</div>
      <div className={'pos-relative overflow-auto'}>{right}</div>
    </div>
  } else if(left) {
    return left;
  }
});
