import React, { Component } from 'react';
import _ from 'lodash';

import { findRectangles, makeMonochrome, mergeRectanglesCloseToEachOther } from './img-rectangle-finder';

const CACHE_BUSTER = "?invalidatecache=1";

export default class ImageRectangleDetection extends Component {
  constructor(props) {
    super(props);

    this.defaultImageProps = {
      blackThreshold: 0.95,
      rectangleMinSize: 2,
      rectangleMaxSize: 20,
      mergeBoxes: false,
      invert: false,
      blur: false
    };

    this.state = {
      imageProps: { ... this.defaultImageProps },
      rectangles: [],
      maxHeight: 300,
      maxWidth: 300,
    };

    this.imgRef = React.createRef();
    this.boxRef = React.createRef();
    this.canvasRef = React.createRef();
    this.containerRef = React.createRef();

    this.debouncedProcessChangeInMonochrome = _.debounce(this.processChangeInMonochrome, 5)
    this.analizeImageDataDebounced = _.throttle(this.analizeImageData, 50)
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  analizeImageData() {
    let imgData = this.state.processedImageData;
    let width  = this.state.imageWidth;
    let height = this.state.imageHeight;

    let rawRectangles = findRectangles(imgData, width, height, this.state.imageProps.blackThreshold);

    this.setState({rawRectangles})

    this.updateRectangles();
  }

  processImage() {
    let keepRectangles = false;

    if(this.state.hasChanges) {
      if(!confirm('You will lose all the assigned fuses if you continue. Are you sure?')) {
        this.setState({processing: false})
        keepRectangles = true;
      } else {
        this.setState({ hasChanges: false })
      }
    } else {
      // For the first time the diagram is loaded
      const assignedFuses = _.filter(this.props.fuses, f => f.layout?.width);
      if(assignedFuses.length > 0 && this.state.hasChanges !== false) {
        this.setState({hasChanges: true})
        return;
      }
    }

    let img = this.imgRef.current;

    this.setState({processing: true, lastImageData: null})

    setTimeout( () => this.updateDimensions(), 1)

    // Create a javascript image that is not resized to use original size
    const newImg = new Image();
    newImg.crossOrigin = 'anonymous'
    newImg.onload = () => {
      // draw the test image on the canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let cw = newImg.width;
      let ch = newImg.height;

      canvas.width = cw;
      canvas.height = ch;
      if(this.state.imageProps.blur) {
        ctx.filter = 'blur(1px)';
      }
      ctx.drawImage(newImg, 0, 0, cw, ch);

      this.lastImageData = ctx.getImageData(0, 0, cw, ch);

      if(keepRectangles) {
        this.setState({aspectRatio: ch / cw, processing: false});
      } else {
        this.setState({rectangles: null});
        this.processChangeInMonochrome();
      }

      setTimeout( () => this.updateDimensions(), 1)
      setTimeout( () => this.updateDimensions(), 1000)
    }

    newImg.src = img.src;
  };

  processChangeInMonochrome() {
    if(this.lastImageData && this.canvasRef.current) {
      let src = this.lastImageData;
      let cw = src.width;
      let ch = src.height;

      const imageDataCopy = new ImageData(new Uint8ClampedArray(src.data), cw, ch)

      makeMonochrome(imageDataCopy.data, src.width, ch, this.state.imageProps.blackThreshold, this.state.imageProps.invert);

      let canvas = this.canvasRef.current;
      canvas.width = cw;
      canvas.height = ch;

      const previewContext = canvas.getContext('2d');
      previewContext.imageSmoothingEnabled = false;
      previewContext.putImageData(imageDataCopy, 0, 0);

      this.setState({
        rectangles: null, processedImageData: imageDataCopy.data, imageWidth: src.width, imageHeight: src.height
      });
      this.analizeImageDataDebounced()
      // Debugging
      // previewContext.putImageData(imageDataCopy, 0, 0);
    } else {
      this.processImage();
    }
  }

  updateRectangles() {
    let rectangles = this.state.rawRectangles;
    const w = this.state.imageWidth;
    const h = this.state.imageHeight;

    const ratio = Math.max(h / 500, w / 500);

    if(this.state.imageProps.mergeBoxes) {
      rectangles = mergeRectanglesCloseToEachOther(rectangles);
    }

    const filteredRectangles = _.compact(_.map(rectangles, ({x1, y1, x2, y2}, i) => {
      const boxW = x2 - x1;
      const boxH = y2 - y1;

      // Skipe huge boxes and very narrow boxes
      const MAX_AREA = Math.pow(this.state.imageProps.rectangleMaxSize/100, 2);
      const MIN_AREA = Math.pow(this.state.imageProps.rectangleMinSize/100, 2);

      let boxAreaPercentage = boxW * boxH / (w * h)
      if (boxAreaPercentage < MAX_AREA && boxAreaPercentage > MIN_AREA && Math.max(boxW/boxH, boxH/boxW) < 8) {
        return {
          top: y1 / h * 100,
          left: x1 / w * 100,
          width: (boxW / w * 100),
          height: (boxH / h * 100),
        };
      } else {
        return null;
      }
    }));

    this.setState({ ratio, rectangles: filteredRectangles, h, w, processing: false })

    this.props.onRectanglesChange(filteredRectangles)
  }

  handleImagePropChange(prop, newValue) {
    if(this.state.hasChanges) {
      if(!confirm('You will lose all the assigned fuses if you continue. Are you sure?')) {
        return;
      }
      this.setState({hasChanges: false})
    }

    let imageProps = { ... this.state.imageProps };
    imageProps[prop] = newValue;
    this.setState({imageProps}, () => {
      if(prop === 'blackThreshold' || prop === 'invert') {
        this.debouncedProcessChangeInMonochrome()
      } else if(prop === 'blur') {
        this.processImage();
      } else {
        this.updateRectangles();
      }
    })
  }

  handleImageBlackThresholdChange(event) {
    this.handleImagePropChange('blackThreshold', event.target.value)
  };

  handleImageRectangleSizeThresholdChange(event) {
    this.handleImagePropChange('rectangleMinSize', event.target.value)
  };

  handleImageRectangleMaxSizeChange(event) {
    this.handleImagePropChange('rectangleMaxSize', event.target.value)
  };

  handleMergeRectanglesChange(event) {
    this.handleImagePropChange('mergeBoxes', event.target.checked)
  };

  handleInvertChange(event) {
    this.handleImagePropChange('invert', event.target.checked)
  };

  handleBlurChange(event) {
    this.handleImagePropChange('blur', event.target.checked)
  };

  handleImageResetToDefaults() {
    this.setState({ imageProps: { ... this.defaultImageProps }, processing: true})
    this.debouncedProcessChangeInMonochrome();
  }

  getImageParamsUI() {
    const { rectangleMinSize, blackThreshold, invert, blur, mergeBoxes, rectangleMaxSize } = this.state.imageProps;

    const style = {
      marginLeft: this.props.width+'px',
      marginTop: '32px',
      width: '230px', // This number is coupled with FuseboxDiagramImgProcessor leaving space for it
      // top: 0,
      zIndex: 9,
      boxShadow: '0 0 2px white',
      borderRadius: '0 5px 5px 0',
      position: 'fixed'
    }

    return <div className={'small text-white bg-dark p-2'} style={style}>
      <div>
        <div className={''}>Black and white threshold: [{parseFloat(blackThreshold).toFixed(2)}%] </div>
        <input className={'align-middle mr-2'} type="range" min="0" max="1"
               value={blackThreshold} onChange={this.handleImageBlackThresholdChange.bind(this)}
               step="0.01"/>
      </div>
      <div>
        <div className={'align-middle'}>Small rectangles filter [{parseFloat(rectangleMinSize).toFixed(1)}%]:</div>
        <input className={'align-middle mr-2'} type="range" min="0" max="20"
               value={rectangleMinSize}
               onChange={this.handleImageRectangleSizeThresholdChange.bind(this)} step="0.001"/>
      </div>

      <div>
        <div className={'align-middle'}>Large rectangles filter [{parseFloat(rectangleMaxSize).toFixed(1)}%]:</div>
        <input className={'align-middle mr-2'} type="range" min="0" max="50"
               value={rectangleMaxSize}
               onChange={this.handleImageRectangleMaxSizeChange.bind(this)} step="0.1"/>
      </div>

      <div>
        <input className={'align-middle mr-2 ml-2'} type="checkbox" checked={mergeBoxes}
               onChange={this.handleMergeRectanglesChange.bind(this)}/>
        <span className={''}>Merge close rectangles</span>
      </div>

      <div>
        <input className={'align-middle mr-2 ml-2'} type="checkbox" checked={invert}
               onChange={this.handleInvertChange.bind(this)}/>
        <span className={''}>Invert image (black → white):</span>
      </div>

      <div>
        <input className={'align-middle mr-2 ml-2'} type="checkbox" checked={blur}
               onChange={(e) => this.handleBlurChange(e)}/>
        <span className={''}>Blur a little (to cover holes)</span>
      </div>

      <div className={'text-right'}>
      <button className={'btn btn-xs btn-link'} onClick={this.handleImageResetToDefaults.bind(this)}>
        Reset to defaults
      </button>
      </div>
    </div>;
  }

  updateDimensions() {
    let container = this.containerRef.current;

    if(container) {
      let maxHeight = $(container).outerHeight();
      let maxWidth = $(container).outerWidth();
      this.setState({maxHeight, maxWidth});
    }
  }

  render() {
    let { processing } = this.state;

    let imageUrl = this.props.imageUrl;

    const style = {
      height: `100%`,
      maxWidth: '100%',
      userSelect: 'none'
    };

    // Bust any potential cached version of the img with a bogus query string
    // parameter, otherwise there are CORS errors when trying to load the image because it could be cached
    // with a previous request that did not include crossOrigin, causing this request to fail
    // More about the problem: https://stackoverflow.com/questions/12648809/cors-policy-on-cached-image
    const img = <img ref={this.imgRef} crossOrigin={'anonymous'} src={imageUrl + CACHE_BUSTER} key={'fusebox'} alt={`Pasted:`}
               style={style}
               onLoad={() => this.processImage()}/>;

    const previewStyle = { ...style, position: 'absolute', left: 0, top: 0, opacity: 0.95 };

    let extraClasses = '';
    if (processing) {
      extraClasses += ' translucent';
    }

    return <div ref={this.containerRef} style={{ height: '100%', width: '100%' }}>
      { this.getImageParamsUI() }

      <div className={extraClasses} style={{ height: '100%', position: 'relative', display: 'inline-block' }}>
        {img}
        <canvas ref={this.canvasRef} style={previewStyle}/>
      </div>
    </div>;
  }
}
