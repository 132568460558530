import React, { Component } from 'react';

import _ from 'lodash'

import { IconButton } from '../IconButton';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import TextFieldEditor from './TextFieldEditor';

export class ExtractedTextEditor extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isCopying: false
    }
  }

  cancelOtherInstances() {
    if (!this.state.isCopying){
      this.context.page.action('cancelPdfSelectBox');
    }
  }

  copyFromPdf(message) {
    this.cancelOtherInstances()

    this.setState({isCopying: true})
    this.context.page.action('pdfSelectTextBox', {
      onSelected: ({ selectedText }) => {
        this.context.page.action('endPdfSelectBox');
        this.props.onChange(_.map(selectedText, 'textContent').join(' ').replace(/\s+/g, ' '))
        this.setState({isCopying: false})
        this.props.onTextSelected && this.props.onTextSelected();
      },
      onCancel: () => {
        this.setState({isCopying: false});
        this.props.onCancel && this.props.onCancel();
      },
      message
    });
  }

  render() {
  let { className, value, onChange, onTextSelected, overlayMessage, ...otherProps } = this.props;
  const { isCopying } = this.state;
  let background = isCopying ? 'bg-highlight' : '';

    return <div className={'d-flex ' + (className || '')} onFocus={() => this.cancelOtherInstances()}>
      <TextFieldEditor showCounter={false} minRows={1} className={'flex-grow-1 ' + background} value={value}
                       onChange={onChange} {...otherProps}/>

      <IconButton icon={'highlight_alt'} level={'primary'} onClick={() => this.copyFromPdf(overlayMessage)}/>
    </div>;
  }
}

ExtractedTextEditor.contextType = LegoAdminPageContext;
