import React, { Component } from 'react';
import SingleTextEditor from './SingleTextEditor';

export default function OpinautosUrlEditor({ value, onChange, ...other }) {
  const editUrlOnChange = (newValue) => {
    onChange(newValue.replace(/^https?:\/\/[\w.]+(\:\d+)?\//i, '/'));
  };

  return <SingleTextEditor value={value} onChange={editUrlOnChange} {...other}/>;
}
