import _ from "lodash";
import React from 'react';
import StatsTable from "./StatsTable";
import TrimsViewer from "../../pages/subpages/TrimsViewer";
import {IconButton} from "../common/IconButton";

export default class StatsManuals extends StatsTable {
  constructor(...args) {
    super(...args);
    this.dataIssuesService = this.data.service('services/data/issues');
  }

  async fetchData() {
    this.models = (await this.data.models()).models;
    this.modelsById = _.keyBy(this.models, 'modelId')

    let { trafficBreakdown: { pageviewsByModelId, modelsTrafficDistribution, modelYearsTrafficDistribution } } = await this.data.traffic('ar')

    this.pageviewsByModelId = pageviewsByModelId;
    this.modelsTrafficDistribution = modelsTrafficDistribution;
    this.modelYearsTrafficDistribution = modelYearsTrafficDistribution;

    this.trimIssues = (await this.data.trimIssues('ar')).issuesCountByModelIdYear;
  }

  groups() {
    let issues = [];

    _.each(this.trimIssues, (issuesByYear, modelId) => {
      let yearsWithIssues = _.keys(this.modelYearsTrafficDistribution[modelId])

      issues.push({ modelId, years: _.keys(issuesByYear), count: _.sum(_.values(issuesByYear)) })
    })

    _.each(this.models, ({ modelId }) => {
      if (!this.trimIssues[modelId]) {
        _.each(this.modelYearsTrafficDistribution[modelId], (share, year) => issues.push({
          modelId,
          years: [],
          count: 0
        }));
      }
    })

    return issues;
  }

  async markIssueDone(modelId) {
    this.page.runAsync((async () => {
      let res = await this.dataIssuesService.update({
        query: {
          'dataSelector.modelId': modelId,
          'dataSelector.countryCode': 'ar'
        }, dataType: 'trim'
      }, { $set: { state: 'solved' } });

      // TODO: Find better way
      await this.page.refresh();
    })())
  }

  groupKey({ modelId, years, count }) {
    return modelId;
  }

  groupWeight(key, { modelId, years, count }) {
    return this.modelsTrafficDistribution[modelId] || 0
  }

  groupCoverage(key, { modelId, years, count }) {
    const modelYears = this.modelYearsTrafficDistribution[modelId];
    if (modelYears && _.keys(modelYears).length) {
      return _.sum(_.map(modelYears, (share, year) => _.includes(years, year) ? 0 : share));
    } else {
      return count ? 0 : 1
    }
  }

  openTrimsEditor(modelId, year) {
    this.page.openModalSubpage(TrimsViewer, {
      modelFilter: { modelId, year },
      onSaveAndClose: () => {
        this.page.modalActionsBus.emit('close');
      }
    });
  }

  displayGroup(key, { modelId, years, count }) {
    return <div>
      <span className={`mr-2 text-${count ? 'danger' : 'secondary'}`}><strong>{count || 'No'}</strong> issues</span>
      <span className={'btn-link'} onClick={() => this.openTrimsEditor(modelId)}>
        {modelId}
      </span>
      <span className={'ml-2 small text-secondary'}>{years.join(', ')}</span>
      {count ? <IconButton onClick={() => this.markIssueDone(modelId)} icon={'done'} level={'primary'}
                           description={'Mark issue as solved'} className={'ml-2 p-0'}>Close</IconButton> : null}
    </div>;
  }
}
