import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ModelContextEditor from '../../components/lego/ModelContextEditor';
import TrimsOrganizer from '../../../../model/TrimsOrganizer.mjs'
import {TrimEditor} from "../../components/lego/trims/TrimEditor";
import {TrimSpecBatchEditor} from "../../components/lego/trims/TrimSpecBatchEditor";
import LiveSubpage from "../LiveSubpage";
import {IconButton} from "../../components/common/IconButton";

dayjs.extend(relativeTime);

export default class TrimsViewer extends LiveSubpage {
  constructor(props) {
    super(props);

    this.trimService = this.service('services/trims');

    // let modelFilter = this.getUrlParam('modelFilter') || {};
    // if (!modelFilter.modelId) {
    //   modelFilter.modelId = 'Volkswagen-Vento'
    //   this.setUrlParams({ modelFilter })
    // }
    //
    // debugger;
    // let country = this.getUrlParam('country', true);
    // if (!country) {
    //   country = 'ar';
    //   // this.setUrlParams({ country: 'ar' })
    // }

    this.state = {
      modelFilter: props.modelFilter || {modelId: 'Chevrolet-Aveo', year: 2012},
      country: props.country || 'ar',
      trims: [],
    };

    this.shortcuts.registerKeyMappings({ 'focusSearch': 'ctrl+f', });
    this.shortcuts.registerActionHandlers({
      'focusSearch': this.focusSearch.bind(this),
    });

    this.searchInputRef = React.createRef();
    this.textFilterChangedDebounced = _.debounce(this.textFilterChanged, 150).bind(this)
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.loadData(), 'Loading trims...');
  }

  focusSearch() {
    const node = this.searchInputRef.current;
    if (node) {
      node.focus();
      node.select();
    }
    return false;
  }

  async loadData(ignoreChanges) {
    let baseModelQuery = {
      ...this.state.modelFilter,
      country: this.state.country,
    };

    const trims = await this.trimService.find({ query: { ...baseModelQuery, } })

    this.setState({ trims })
  }

  changesCount() {
    return _.filter(this.state.trims, trim => trim.__changed || trim.__new || trim.__deleted).length;
  }

  async saveChanges(closeView) {
    let asyncOperations = _.compact(_.map(this.state.trims, async trim => {
      if(trim.__new) {
        let { _id, __new, __changed, ...newTrimData } = trim;
        const newTrim = await this.trimService.create(newTrimData);
        console.log('Adding trim', newTrim);
      } else if(trim.__deleted) {
        await this.trimService.remove(trim._id);
        console.log('Deleted trim ', trim);
      } else if(trim.__changed) {
        await this.trimService.update(trim._id, trim);
        console.log('Updated trim ', trim);
      }
    }));

    await Promise.all(asyncOperations);

    await this.loadData(true);

    if(this.props.onSave) {
      this.props.onSave();
    }

    if(closeView && this.props.onSaveAndClose) {
      this.props.onSaveAndClose();
    }
  }

  trimsUpdated(trims) {
    this.setState({ trims });
  }

  modelChanged(modelData) {
    let params = _.pick(modelData, 'modelId', 'year');
    params.modelId = params.modelId || ''

    this.setState({ modelFilter: params }, () => {
      this.setUrlParams({ modelFilter: params })
      if (params.modelId) {
        this.runAsync(this.loadData(), `Loading trims for ${params.modelId}...`);
      }
    });
  }

  textFilterChanged(text) {
    this.setState({ textFilter: text });
  }

  trimChange(newTrimData) {
    newTrimData.__changed = true;
    let changedTrims = _.map(this.state.trims, (trim) => {
      if(trim._id === newTrimData._id)
        return newTrimData
      else
        return trim
    })
    this.setState({trims: changedTrims})
  }

  trimGroupClickHandler(e, trims) {
    if(e.shiftKey) {
    } else {
      this.batchEdit(trims)
    }
  }

  renderGroupedTrims(trims) {
    let rows = TrimsOrganizer.groupYearTrimsBySpecs(trims);

    return <table className={'TrimsTable TrimsTable--expanded table table-sm'} key={(trims[0] || {}).modelId}>
      <tbody>
      {_.map(rows, (columns,i) => {
        const cols = []
        let isFirstRow = false;
        let hasIssue = false;
        _.each(columns, ({group, value, secondaryInfo, rowSpan, extra, final, first, last, trims, trim},j) => {
          const cellClasses = [];
          // We need to set hasIssue even if it has no span, so that final column is colorized
          if(value && value.match(/\?|\bnull\b/) || (final && trim && hasIssue)) {
            hasIssue = true; // We want to highlight group cell and trim cell
            cellClasses.push('trim-issue')
          }

          if(rowSpan) {

            if(final)
              cellClasses.push('trim-name-cell')

            if(first)
              cellClasses.push('trim-first')

            if(last)
              cellClasses.push('trim-last')

            if(trim && trim.__changed)
              cellClasses.push('highlight-warn')

            const onClickHandler = final ? () => false : (e) => this.trimGroupClickHandler(e,trims || [trim]);

            cols.push(<td key={j} rowSpan={rowSpan} className={cellClasses.join(' ')} onClick={onClickHandler}>
              <div className={'trim-text'}>
                { final && trim
                  ?
                  <TrimEditor value={trim} onChange={this.trimChange.bind(this)}/>
                  :
                  value ||
                  <span className={'text-dark'}>base</span>}

                { _.map(secondaryInfo, (text,i) => <div key={i} className={'trim-secondary-data'}>{text}</div>)}

                {/*{ extra ? <span className={'ml-2 text-info small trim-debug-cell'}>{extra}</span> : null }*/}

              </div>
              <div className={'trim-background'}>
              </div>
            </td>)

            isFirstRow = isFirstRow || first;
          }
        });

        return <tr key={columns[columns.length - 1].trim._id} className={isFirstRow ? 'first-row' : ''}>{cols}</tr>
      })
      }
      </tbody>
    </table>
  }

  batchEdit(trims = []) {
    let groupSpecsEdition = <TrimSpecBatchEditor trims={trims}
                                                 onSave={(changedTrims) => {
                                                   this.setState({trims: this.state.trims})
                                                   this.modalActionsBus.emit('close');
                                                 }}
    />

    this.modalActionsBus.emit('open', groupSpecsEdition, {minSize: true});

    // this.setState({batchEdit: true, selectedTrims: trims})
  }

  renderPageBody() {
    let { country, trims, sessionId, connectionStatus, textFilter, batchEdit, selectedTrims} = this.state;

    if(textFilter) {
      trims = _.filter(trims, trim => JSON.stringify(trim).toLowerCase().indexOf(textFilter.toLowerCase()) >= 0)
    }

    let trimsByYear = _.groupBy(trims, ({ year }) => year);

    let trimsViz = _.map(trimsByYear, (trims, year) => {
      return <div key={year}>
        <div className={'row'}>
          <div className={'col-3 text-center'}>
            <IconButton icon={'library_add_check'} onClick={() => this.batchEdit(trims)}><span>Edit {year} trims ({trims.length})</span></IconButton>
          </div>
          <div className={'col-6'}><h4 className={'text-center'}>{year}</h4></div>
          <div className={'col-3'}></div>
        </div>
        <div className={'p-2'}>
          {this.renderGroupedTrims(trims)}
        </div>
      </div>
    });

    if (trims.length) {
      const selectAll = <div className={'text-center'}>
        <IconButton key={'select-all'} icon={'library_add_check'} onClick={() => this.batchEdit(trims)}><span>Edit all trims ({trims.length})</span></IconButton>
      </div>
      trimsViz = [selectAll, ... trimsViz]
    } else {
      trimsViz = <h2 className={'text-center mt-5'}>There are no trims</h2>;
    }


    let headerBg = textFilter ? 'bg-primary' : 'bg-dark'

    return <div className={''}>
      <div className={`${headerBg} p-2 row no-gutters sticky-top`}>
            <span className={'col-4'}>
              <ModelContextEditor value={this.state.modelFilter} onChange={ctx => this.modelChanged(ctx || {})}/>
            </span>

        <span className={'col-1 text-center text-secondary h4 pt-1 m-0'}>
              <span className={'badge badge-info mr-2 '}>{country.toUpperCase()}</span>
            </span>

        <span className={'col-4 text-center text-secondary h4 pt-1 m-0'}>
          {
            this.changesCount()
              ?
              <span>
                <span className={'btn btn-sm btn-primary'}
                      onClick={() => this.runAsync(this.saveChanges(false), 'Saving changes...')}>
                  Save {this.changesCount()} changes
                </span>
                <span className={'btn btn-sm btn-link'}
                      onClick={() => this.runAsync(this.saveChanges(true), 'Saving changes...')}>
                  Save and close
                </span>
              </span>
              :
              null
          }
        </span>

        <span className={'col-3'}>
               <input ref={this.searchInputRef}
                      autoFocus={true}
                      className={`form-control ${this.state.textFilter ? '' : 'form-control-plaintext'}`}
                      placeholder={'Search...'} type={'text'}
                      onChange={e => this.textFilterChangedDebounced(e.target.value)}/>
            </span>
      </div>
      {trimsViz}
    </div>;
  }
}
