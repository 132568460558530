import React, { useContext, useEffect, useState } from 'react';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { VehicleSummary } from '../common/VehicleSummary';
import { DataTypeTag } from '../dataprocess/DataTypeTag';
import { UserSummary } from '../common/UserSummary';
import { formatDistanceToNowStrict } from 'date-fns';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import _ from 'lodash';
import { StateBadge } from './StateBadge';
import { ImagePreview } from '../images/ImagePreview';
import BadgeId from '../common/BadgeId';
import { AwaitingIcon } from './AwaitingIcon';
import TemplateEmailEditor from '../common/TemplateEmailEditor';
import { IconButton } from '../common/IconButton';
import { MailConversationsList } from '../common/MailConversationsList';
import LegoLabelsSelector from '../lego/LegoLabelsSelector';
import { LabelsSummary } from './LabelsSummary';

const cleanUrl = url => {
  return (url || '')
  .replace(/^.*\.com\//, '/')
  .replace(/#contrib-image-fuseboximg/i, '')
}

export function DataFeedbackSummary({ id }) {
  const { page } = useContext(LegoAdminPageContext)
  const [feedback, setFeedback] = useState(null);
  const [involvedIssues, setInvolvedIssues] = useState(null);
  const [editingLabels, setEditingLabels] = useState(false);

  const fetchFeedback = () => {
    if (id) {
      page.service('services/data/data-feedback').get(id).then(feedback => {
        setFeedback(feedback || false);
        page.service('services/data/issues').find({query: {involvedDataFeedback: id}}).then(issues => {
          if(issues.length) {
            setInvolvedIssues(issues);
          }
        });
      }).catch(err => setFeedback(err.toString()));
    }
  }

  useEffect(() => fetchFeedback(), [id]);

  let summary = `Loading ${id}...`

  if(feedback) {
    let {dataSelector: {vehicle}, userId, isCasual, awaiting, labels, data, conversationThreadIds, state, dataType, feedbackType, feedbackContext, updatedAt, createdAt} = feedback;

    const openSendEmail = () => {
      let state = {
        userId,
        modelId: vehicle.modelId,
        year: vehicle.year,
      }

      page.openModal(<TemplateEmailEditor preloadedState={state} userToId={userId} dataFeedbackId={id} onSent={() => {
          page.closeModal();
          fetchFeedback();
        }}/>,
        {fullScreen: true, noPadding: true, title: `Send email using template (data feedback ${id})`});
    };

    const setFeedbackState = (state) => {
      page.runAsync(async () => {
        page.service('services/data/data-feedback').update(id, {$set: {state : state}});
        fetchFeedback();
      })
    };

    const setLabels = (labels) => {
      page.runAsync(async () => {
        page.service('services/data/data-feedback').update(id, {$set: {labels}});
        fetchFeedback();
      })
    };

    const feedbackButtons = <div className={'mt-1 text-center'}>
      { isCasual ? null : <span className={'btn btn-xs btn-primary'} onClick={openSendEmail}>Send email</span> }

      <span className={'btn btn-xs btn-dark ml-1'} onClick={() => setFeedbackState('unclear')}>
        Mark unclear
      </span>

      <span className={'btn btn-xs btn-danger ml-1'} onClick={() => setFeedbackState('rejected')}>
        Mark rejected
      </span>

      { editingLabels ? null : <IconButton icon={'label'} onClick={() => setEditingLabels(true)}>Label...</IconButton>}
    </div>

    let conversationSummary = <div
      className={'text-center text-secondary small my-2'}>
      No conversation yet.<br/>
    </div>;

    if(conversationThreadIds?.length) {
      const openConversations = () => {
        page.openModal(<MailConversationsList threadIds={conversationThreadIds}/>, {fullScreen: true, noPadding: true, title: 'Data feedback conversations'})
      }
      conversationSummary = <div className={'text-center rounded mt-1'}>
        <IconButton icon={'email'} onClick={openConversations}>
          {conversationThreadIds.length} conversations...
        </IconButton>
      </div>;
    } else if(isCasual) {
      conversationSummary = null;
    }

    let background = awaiting ? 'bg-light-primary' : (state === 'closed' ? 'bg-light-success' : 'bg-light-secondary');

    let involvedDataIssuesList = null;
    if(involvedIssues?.length) {
      involvedDataIssuesList = <div className={'text-center bg-white mt-1 rounded'}>
        <span className={'small text-info'}>In data issues:</span>
        { _.map(involvedIssues, issue => <BadgeId key={issue._id} id={issue._id} className={'ml-1'}/> ) }
      </div>
    }

    // Due to the way we collect data feedback, sometimes the model is wrong and different from the real page model
    let modelMissmatchWarning = null;
    let match = feedbackContext.url.match(/\/([^/]+\/[^/]+)\/info/i);
    let modelIdFromUrl = match && match[1].replace('/', '-');
    let normalizedModelId = vehicle.modelId.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/ /g, '-');
    if(!normalizedModelId.endsWith(modelIdFromUrl)) {
      modelMissmatchWarning = <div className={'alert border-danger alert-danger text-center h5 my-2 p-2'}>
        Page is from <strong>{modelIdFromUrl}</strong> and <br/>
        vehicle from <strong>{normalizedModelId}</strong>
      </div>;
    }

    return <div className={`border m-2 rounded p-2 ${background}`}>
      <LeftRightLayout className={'mb-1'}>
        <div className={'text-secondary small '}>Feedback <DataTypeTag
          type={`${dataType}-${feedbackType}`}/></div>
        <a href={`/legos/data-feedback?editing="${id}"`} target={'_blank'}>
          <BadgeId className={''} id={id}/>
        </a>
      </LeftRightLayout>

      <LeftRightLayout className={'mb-1'}>
        <div>
          <UserSummary userId={userId}/>
        </div>

        <span>
          <AwaitingIcon awaiting={awaiting}/>
          <StateBadge state={state}/>
        </span>
      </LeftRightLayout>

      <LeftRightLayout className={'mb-1 pb-1'}>
        <div className={'small'}>from <a href={feedbackContext.url}
                                         target={'_blank'}
                                         className={'text-primary'}>{cleanUrl(feedbackContext.url)}</a>
        </div>
        <span className={'small text-secondary'}>{formatDistanceToNowStrict(new Date(createdAt))} ago</span>
      </LeftRightLayout>

      { modelMissmatchWarning }


      <div className={'zoom-75 mb-1'}>
        {!editingLabels ? <LabelsSummary labels={labels} onClick={() => setEditingLabels(true)}/> :
          <LegoLabelsSelector value={labels} onChange={setLabels}/>}
      </div>

      <div className={'bg-light p-1 rounded'}>
        <VehicleSummary vehicle={vehicle}/>

        <div className={'mt-2'}>
          <ImagePreview url={data.images?.[0]?.url} zoom={true}/>
        </div>

        { data.reason && <div>Feedback reason: <span className={'badge badge-primary'}>{data.reason}</span></div>}
      </div>

      {involvedDataIssuesList}

      {conversationSummary}

      {feedbackButtons}
    </div>;
  } else if(feedback === false) {
    summary = <div className={'text-danger text-center p-1'}>Feedback {id} not found</div>
  }

  if (!id) {
    return <div className={'text-center p-1'}>No associated data feedback</div>
  }

  return <div className={'border m-1 p-1 rounded bg-light-secondary'}>
    {summary}
  </div>;
}
