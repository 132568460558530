import React, {Component} from "react";
import MultiValueSelector from "../../common/editors/MultiValueSelector";
import _ from 'lodash';
import {IconButton} from "../../common/IconButton";
import TrimSpecsEditor from "../trims/TrimSpecsEditor";
import MultipleItemsEditor from "../MultipleItemsEditor";
import {OilNormsDataCache, OilViscositiesDataCache} from "../data-caches/SingleLegoDataCache";
import MagnitudeEditor from "../../common/editors/MagnitudeEditor";

export default function OilQuantityLegoEditor({ value, onChange }) {
  const updateContext = (newContext) => {
    if (newContext.length > 0) {
      let lego = _.cloneDeep(value);
      lego.context = newContext;
      onChange(lego);
    }
  }

  const updateData = (field, newValue) => {
    let lego = _.cloneDeep(value);

    lego.data[field] = newValue;
    // Remove null fields in data
    lego.data = _.pickBy(lego.data, v => v);

    onChange(lego);
  }

  const { context, data, _id } = value;

  const { withFilter, withoutFilter, total } = data || {};

  // Context

  let contexts = _.isArray(context) ? context : [context];
  // Remove 'trims' field from context, as it is useless and should be fixed in original manual's contexts
  contexts = _.map(contexts, ({ trims, ...restOfContext }) => restOfContext)

  let header = null;
  let stateBg = 'success'
  if (_id) {
    header = <div className={'small text-secondary '}>Lego {_id} <IconButton icon={''}/></div>
    stateBg = 'secondary'
  }

  let defaultNewContext = {};
  if (contexts[0] && contexts[0].modelId && contexts[0].year) {
    defaultNewContext = { modelId: contexts[0].modelId, year: contexts[0].year };
  }

  const contextEditor = <MultipleItemsEditor value={contexts}
                                             defaultNewItem={defaultNewContext}
                                             buttonsAlwaysVisible={true}
                                             renderComponent={(item, props) => <TrimSpecsEditor
                                               value={item} {...props}/>}
                                             onChange={(specs) => updateContext(specs)}
  />

  return <div className={`rounded p-1 bg-light-${stateBg} zoom-90`}>
    {header}

    {contextEditor}

    <div className={'d-flex justify-content-between'}>
      <MagnitudeEditor value={withFilter} onChange={(v) => updateData('withFilter', v)} small
                       label={'With filter'} unit={'liter'}/>
      <MagnitudeEditor value={withoutFilter} onChange={(v) => updateData('withoutFilter', v)} small
                       label={'Without filter'} unit={'liter'}/>
      <MagnitudeEditor value={total} onChange={(v) => updateData('total', v)} small
                       label={'(not specified)'} unit={'liter'}/>
    </div>
  </div>;
}

