import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import CRUDPage from './CRUDPage';
import DataProcessEditor from '../components/dataprocess/DataProcessEditor';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import submenu from './menus/submenu-tasks';

export default class DataProcessManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data-process');

    // Override superclass option
    this.newObjectTemplate = {
      impact: 0,
      state: {},
      data: {},
      finishedTasks: [],
      pendingTasks: [],
    }

    this.submenu = submenu;
  }

  getColumnsDefinition(objects) {
    return [
      {content: 'Impact', className: ''},
      {content: 'Id', className: ''},
      {content: 'Context', className: ''},
      {content: 'Process Name', className: ''},
      {content: 'State', className: 'text-center'},
    ]
  }

  getObjectColumns(view, definition) {
    let { _id, processType, impact, context, state, data, startedAt, finishedAt, createdAt, updatedAt } = view;

    const stateSummary = _.map(state, (value, name) => <div className={'small'} key={name}>
      <span className={'text-secondary'}>{name}: </span>
      <span className={'text-primary'}>{value.toUpperCase()}</span>
    </div>)

    return [

      <td key={'metric'}>
        <span className={'text-info'}>{impact}</span>
      </td>,

      <td key={'type'}>
        <div>
          <span className={'text-info small'} title={updatedAt}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
        </div>
        <div className={'text-secondary small'}>{_id}</div>
      </td>,

      <td key={'ctx'} className={'context-column'}><LegoContextSummary context={context}/></td>,

      <td key={'name'}>
        <strong className={'text-primary'}>{processType}</strong>
      </td>,

      <td key={'state'}>{stateSummary}</td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <DataProcessEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}
