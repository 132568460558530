import _ from "lodash";
import React from "react";

const months = {
  '1': ['enero', 'ene', 'january', 'jan'],
  '2': ['febrero','feb','february'],
  '3': ['marzo','mar','march'],
  '4': ['abril','abr','april','apr'],
  '5': ['mayo','may'],
  '6': ['junio','jun','june'],
  '7': ['julio','jul','july'],
  '8': ['agosto','ago','august','aug'],
  '9': ['septiembre','sep','september'],
  '10': ['octubre','oct','october'],
  '11': ['noviembre','nov','november'],
  '12': ['diciembre','dec','dic','december'],
};

const monthByString = {};
_.each(months, (names, monthNumber) => _.each(names, s => monthByString[s] = monthNumber))

const monthsRegex = _.map(_.values(months), versions => versions.join('|')).join('|');

const asFullYear = year => {
  year = parseInt(year.toString());
  return (year < 100 ? (year > 70 ? year + 1900 : year + 2000) : year).toString();
}

const asMonthNumber = month => {
  if(month.match(/^(0?\d|1[012])$/)) {
    return month;
  } else {
    return monthByString[month];
  }
}

export default function autodetectManualDate(pagesDescription, manualDocument, dateDataTask) {
  let { modelId, year: modelYear } = manualDocument.context[0] || manualDocument.context;

  let [makeName, modelName] = modelId.split('-')
  let modelRegex = `(?:${makeName} ${modelName}|${modelName})`
  modelYear = parseInt((modelYear.toString()));
  let yearRegex = `${modelYear}|${modelYear - 1}|${modelYear + 1}`;


  // 1) MODEL WITH YEAR: 2006 Focus
  const regexYearModel = new RegExp(`\\b((${yearRegex}) ${modelRegex})\\b`, 'i');
  const regexYearModel2 = new RegExp(`\\b(${modelRegex}[\\s-]+(${yearRegex}))\\b`, 'i');
  const yearModelPostprocessor = ([,,yearMatch]) => ({printedModelYear: yearMatch});

  // 2) YEAR WITH MONTH: Jan 2006, Jul. '08
  const regexMonthNameYear = new RegExp(`\\b((${monthsRegex})[.\s].{0,10}((19|20)\\d\\d))\\b`, 'i');
  const regexMonthNameShortYear = new RegExp(`\\b((${monthsRegex})\\.? ?['’]?(\\d\\d))\\b`, 'i');
  const monthYearPostprocessor = ([,,month,yearMatch], {printedDate}) => {
    yearMatch = asFullYear(yearMatch);
    month = asMonthNumber(month);
    if(Math.abs(modelYear - yearMatch) <= 2 && !printedDate) {
      return { printedDate: { year: yearMatch, month } };
    }
  };

  // 2) YEAR WITH MONTH (AND MAYBE DAY): 05/23/2009 25/5/2009 08-1999 7/2005
  const regexMonthFullDate = new RegExp(/\b((?:(0?[1-9]|1[012])[-/.])?(0?[1-9]|[12][0-9]|3[01])[-/.]((?:19|20)\d\d))\b/i);
  const regexMonthFullDateShort = new RegExp(/ ((?:(0?[1-9]|1[012])[-/.])(0?[1-9]|[12][0-9]|3[01])[-/.]((?:19|20)?\d\d)) /i);
  const datePostprocessor = ([,,monthDay,m,y], {printedDate}) => {
    y = asFullYear(y);
    m = monthDay ? (asMonthNumber(monthDay) || asMonthNumber(m)) : asMonthNumber(m);

    const isNewerDate = !printedDate || y > printedDate.year || (y === printedDate.year && (!printedDate.month || m >= printedDate.month));

    if(Math.abs(modelYear - y) <= 2 && isNewerDate) {
      return { printedDate: { year: y, month: m } };
    }
  };


  // 3) YEAR alone, but only consider current manual year +1 -1
  const regexSingleManualYearCopyright = new RegExp(`\\b(?:c|©) .{0,5}(${yearRegex})\\b`, 'i');
  const regexSingleManualYearCorporation = new RegExp(`\\b(${yearRegex}) .{0,20}(motors|corporation)\\b`, 'i');
  const regexSingleManualYear = new RegExp(`\\b(${yearRegex})\\b`, 'i');
  const yearPostprocessor = ([,year], {printedDate}) => !printedDate && ({ printedDate: { year: asFullYear(year) } });


  // Regexes ordered by priority, with their post-processor
  let orderedHeuristics = [
    [regexYearModel, yearModelPostprocessor],
    [regexYearModel2, yearModelPostprocessor],
    [regexMonthNameYear, monthYearPostprocessor],
    [regexMonthNameShortYear, monthYearPostprocessor],
    [regexMonthFullDate, datePostprocessor],
    [regexMonthFullDateShort, datePostprocessor],
    [regexSingleManualYearCopyright, yearPostprocessor],
    [regexSingleManualYearCorporation, yearPostprocessor],
    [regexSingleManualYear, yearPostprocessor],
  ];


  let suggestedFields = {};
  let resultsByValue = {}
  let results = [];
  let coveredPartsOfPage = [];

  for (const [regex, postprocessor] of orderedHeuristics) {
    _.each(pagesDescription.pages, (page, i) => {
      coveredPartsOfPage[i] = coveredPartsOfPage[i] || [];

      let matches = page.allText.match(regex);
      if (matches) {
        const matchStart = matches.index;
        const matchEnd = matchStart + matches[0].length;
        const snippet = page.allText.slice(Math.max(0, matchStart - 20), matchEnd + 20);

        // Check the match was not covered by a previous regex
        if (_.some(coveredPartsOfPage[i], ({ from, to }) => !(to < matchStart || from > (matchEnd)))) {
          return;
        }
        // Save match
        coveredPartsOfPage[i].push({ from: matchStart, to: matchEnd })

        // Assume first capturing group is the result
        const value = matches[1];

        // Update suggested fields with postprocessors. Never ovewrite first matches
        suggestedFields = { ...suggestedFields, ...postprocessor(matches, suggestedFields) }

        const matchDetails = { snippet, regex, page: i + 1 };

        resultsByValue[value] = resultsByValue[value] || [];
        resultsByValue[value].push(matchDetails);
      }
    })
  };

  return {suggestedFields, resultsByValue}
}
