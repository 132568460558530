import React from "react";
import _ from "lodash";

import {OilTypeLegoEditor} from "../../lego/extraction-data-editors/OilTypeLegoEditor";
import ManualFeaturesLegoEditor from "./ManualFeaturesLegoEditor";


const oilLegoSelector = {
  type: 'data',
  intentions: 'spec',
  semantics: 'TIPO_DE_ACEITE_MOTOR',
  'source.type': 'owner-manual',
}

const defaultNewEmptyLego = {
  data: {},
  type: 'data',
  intentions: ['spec'],
  semantics: ['TIPO_DE_ACEITE_MOTOR'],
}

export default class ManualFeaturesOilEditor extends ManualFeaturesLegoEditor {
  constructor(props) {
    super(props, 'Extract Oil', 'features.hasOilData', oilLegoSelector, defaultNewEmptyLego);
    this.showRecentLegosBtn = true;
  }

  getSingleLegoDataEditor(lego, props) {
    return <OilTypeLegoEditor value={lego} {...props}/>;
  }
}
