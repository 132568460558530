import EventEmitter from 'events';
import React, { Component } from 'react';
import ReactS3Uploader from 'react-s3-uploader';

import ClipboardDropZone from '../clipboard/ClipboardDropZone';
import ImageSearchDialog from '../../lego/ImageSearchDialog';
import ModalManager from '../../ModalManager';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import { LoadingSpinner } from '../LoadingSpinner';
import { convertToJPG } from './image-manipulation';
import { ImagePreview } from '../../images/ImagePreview';
import { IconButton } from '../IconButton';
import figmaIcon from '../../../public/images/logo-figma.svg?url';
import { formatDateFromNow } from '../../../../../components/common/formatters';

export class SingleImageUrlEditor extends Component {
  constructor(props) {
    super(props);

    this.s3LegoImagesService = window.client.service('services/s3-images-legos');
    this.imageService = window.client.service('services/data/images');

    this.state = {
      uploading: false,
      error: null,
      stateMessage: '',
      shouldShowButtons: false,
      isCapturing: false
    };

    this.id = `upload-btn-${Math.random()}`;

    this.modalActionsBus = new EventEmitter();

    this.onPaste = this.onPaste.bind(this);
    this.pickFromS3 = this.pickFromS3.bind(this);
    this.pickURL = this.pickURL.bind(this);
  }

  preprocessFile(File, cbk) {
    console.log('Upload start', File);

    this.setState({ uploading: true, error: null, stateMessage: 'Preparing for upload...' });

    if (this.props.forceJPG) {
      convertToJPG(File, this.props.jpgQuality || 0.80).then(cbk);
    } else {
      cbk(File);
    }
  }

  onUploadProgress(progress, msg, file) {
    this.setState({ progress, stateMessage: `Uploading ${file.name}...` });
  }

  onUploadError(err) {
    this.setState({ uploading: false, error: err.toString() });
  }

  onUploadFinish({ s3Url }) {
    this.props.onChange({ url: s3Url });
    this.createImage(s3Url);
    this.setState({ uploading: false });
  }

  getSignedUrl(file, callback) {
    // As pasted images seem to always have the name 'image.png',
    // an optional defaultName prop can be used for these cases.
    // (and when we convert png to jpeg, we change the name to .jpeg)
    let name = file.name;
    if (this.props.defaultName && (name === 'image.png' || name === 'image.jpg')) {
      let [, nameBase, extension] = name.match(/(.*)\.(\w+)$/);
      name = this.props.defaultName + '.' + extension;
    }

    this.s3LegoImagesService.create({
      fileName: name,
      mimeType: file.type,
      folder: this.props.folder ?? this.props.category, // Use category as folder by default
    }).then(callback).catch((err) => {
      this.setState({ uploading: false, error: err.toString() });
    });
  }

  async createImage(s3Url) {
    const category = this.props.category ?? 'lego';
    return this.imageService.create({
      url: s3Url,
      sourceType: 'lego-admin-uploaded',
      category: category,
      createdBy: this.context.page.getLoggedUserSignature(),
    });
  }

  onPaste({ imageData, files }) {
    if (this.uploadInput) {
      this.uploadInput.files = files;

      if (this.uploader) {
        this.uploader.uploadFile();
      }
    }
  }

  pickFromS3() {
    const defaultFolder = this.props.folder ?? this.props.category;
    const folder = defaultFolder ? `${defaultFolder}/` : '';

    this.modalActionsBus.emit('open', <div>
      <ImageSearchDialog folder={folder} onOk={async (s3Url) => {
        this.modalActionsBus.emit('close');
        this.props.onChange({ url: s3Url });
      }} onCancel={() => this.modalActionsBus.emit('close')}/>
    </div>, false);
  }

  pickURL() {
    let url = prompt('Enter image URL');
    if(url) {
      this.props.onChange({url});
    }
  }

  pickPdfScreenshot(message) {
    if (!this.state.isCapturing) {
      this.context.page.action('cancelPdfSelectBox');
    }
    this.context.page.action('pdfSelectBox', {
      onBoxSelected: this.onPdfScreenshotSelected.bind(this),
      onCancel: () => {
        this.setState({ isCapturing: false });
        this.props.onCancel && this.props.onCancel();
      },
      message
    })
    this.setState({ isCapturing: true });
  }

  async onPdfScreenshotSelected(box) {
    this.context.page.action('endPdfSelectBox');
    this.setState({ isCapturing: false})

    if(this.props.chain) {
      setTimeout(() => this.pickPdfScreenshot(), 0)
    }

    this.props.onSelected && this.props.onSelected();

    try {
      const screenshotService = this.context.page.service('services/s3-pdf-screenshot');

      // TODO: This breaks the component abtsraction horribly
      box.sourceUrl = this.props.manualPdfUrl;
      box.manualId = this.props.manualId;
      box.category = this.props.category;

      if(this.props.trimThreshold) {
        box.trimThreshold = this.props.trimThreshold;
      }

      this.setState({ uploading: true });


      const res = await screenshotService.get(box);
      // console.log(res)

      this.props.onChange({ url: res.url, source: res._id });
    } catch (err) {
      alert(err);
    }

    this.setState({ uploading: false });
  }

  zoomImage() {
    this.modalActionsBus.emit('open', <div>
      <img style={{ maxWidth: '90vw', maxHeight: '80vh' }} src={this.props.value.url}/>
    </div>, false);
  }

  toggleShowButtons(){
    this.setState({shouldShowButtons: !this.state.shouldShowButtons});
  }

  render() {
    let { uploading, progress, error, stateMessage, shouldShowButtons, isCapturing } = this.state;
    let { large, placeholder, value, compact, hideButtonsOnSelected, overlayMessage } = this.props
    let imageUrl = (value || {}).url;
    large = large || false;

    const background = isCapturing ? 'bg-highlight' : 'bg-light';
    const showButtons = shouldShowButtons || !value?.url || !hideButtonsOnSelected;

    let inputId = this.id;

    let img = null;
    let msg = null;
    if (uploading) {
      img = <div className={'img-sm-fixed bg-light-primary d-flex justify-content-center align-items-center'}>
        {progress ? progress + '%' : <LoadingSpinner/>}
      </div>;
      msg = <div className={'text-info small'}>{stateMessage}</div>;
    } else if (imageUrl) {
      img = <img alt={imageUrl} src={imageUrl}
                 className={`${large ? 'img-lg' : 'img-sm'} img-zoom-btn`}
                 onClick={() => this.zoomImage()}/>;
      msg = <div className={'m-2 text-secondary small text-ellipsis text-ellipsis--left'} title={imageUrl}>
        {imageUrl?.split && imageUrl.split('/').slice(3).join('/')}
      </div>;
    } else {
      if(!compact) {
        img = <div className={'img-sm-fixed bg-light-secondary'}></div>;
      }
      msg = <div className={'text-secondary'}>{placeholder || 'Drag or paste image...'}</div>;
    }

    const s3UploadBtn = <label htmlFor={inputId}>
      <span className={'btn btn-xs btn-secondary'}>File...</span>

      <ReactS3Uploader
        accept="image/*"

        getSignedUrl={this.getSignedUrl.bind(this)}
        preprocess={this.preprocessFile.bind(this)}

        // onSignedUrl={this.onSignedUrl.bind(this)}
        onProgress={this.onUploadProgress.bind(this)}
        onError={this.onUploadError.bind(this)}
        onFinish={this.onUploadFinish.bind(this)}
        ref={uploader => this.uploader = uploader}
        id={inputId}
        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default

        style={{ display: 'none' }}

        contentDisposition="auto"
        // scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}

        // server="http://cross-origin-server.com"
        inputRef={cmp => this.uploadInput = cmp}
        autoUpload={true}
      />
    </label>;

    let generationSource = null;
    if(value?.generated) {
      let {generated, source, figmaFileId, figmaFileVersion, layerName, updatedAt} = value;
      generationSource = <div className={'border rounded border-secondary pr-2 mt-2'}>
          <a href={`https://www.figma.com/file/${figmaFileId}`} target={'_blank'} className={'btn btn-sm btn-link'}>
            <img alt='figma' className={'mr-2'} src={'/'+figmaIcon}/>
            <span className={'inline-block ml-1 text-left align-middle'}>
              <div>File {figmaFileId}</div>
              <div><span className={'small'}>{layerName}</span></div>
            </span>
          </a>
          <span className={'text-secondary ml-2 small'}>{formatDateFromNow(new Date(updatedAt))}</span>

      </div>
    }

    let hideButtonsBtn = value?.url && hideButtonsOnSelected ? <IconButton icon={'unfold_less'} level={'secondary'} onClick={() => this.toggleShowButtons()} tittle={'Hide buttons'}/> : null;

    return <div className={'pos-relative'}>
      <div>
        <div
          className={'p-2 d-flex align-items-center justify-content-between rounded text-center ' + (large ? 'flex-column ' : ' ') + background}>
          <div style={{ minWidth: '60px' }}>{img}</div>

          {generationSource}

          {showButtons ? <div className={'overflow-hidden ml-1'} style={{ flexGrow: 1 }}>
              {msg}

              <div className={'text-center pt-1'}>
                {s3UploadBtn}

                <span className={'ml-1'}>
                  <button onClick={this.pickFromS3} className={'btn btn-xs btn-secondary'}>S3...</button>
                </span>

                <span className={'ml-1'}>
                  <button onClick={this.pickURL} className={'btn btn-xs btn-secondary'}>URL...</button>
                </span>

                {this.context.page.hasActionHandler('pdfSelectBox') ?
                  <span className={'ml-1'}>
                 <button onClick={() => this.pickPdfScreenshot(overlayMessage)}
                         className={'btn btn-xs btn-secondary'}>PDF fragment</button>
                </span> : null
                }

                {hideButtonsBtn}
              </div>

            </div>
            : <IconButton icon={'unfold_more'} level={'secondary'} onClick={() => this.toggleShowButtons()}
                          title={'Show buttons'}/>
          }
        </div>

        {error ? <div className={'alert alert-danger'}>{error}</div> : null}

      </div>

      <ClipboardDropZone onPaste={this.onPaste} acceptLinks/>

      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;

  }
}

SingleImageUrlEditor.contextType = LegoAdminPageContext;
