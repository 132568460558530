import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncData from '../../../../components/common/effects/useAsyncData';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import React, { useContext } from 'react';

export default function IssueCategorySelector({ value, onChange, dataType, ...other }) {
  let facetOptions = ['missingTemplate', 'missingYear'];
  if (dataType === 'manual') {
    facetOptions = ['missing'];
  } else if (dataType === 'model') {
    facetOptions = ['missing'];
  }

  return <SingleValueSelector placeholder={'Filter by task type...'}
                              value={value}
                              canCreate={true}
                              onChange={onChange}
                              options={facetOptions} {...other}/>;
}
