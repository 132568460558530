import React from 'react';
import _ from 'lodash';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import DataFieldSelector from './DataFieldSelector';
import MultipleItemsEditor from '../lego/MultipleItemsEditor';
import ProcessStateChangeSelector from './ProcessStateChangeSelector';
import JsonTextEditor from '../common/editors/JsonTextEditor';

export class TaskFlowEditor extends React.Component {
  editField(fieldPath, newValue) {
    let updated = this.props.value;
    _.set(updated, fieldPath, newValue);
    this.props.onChange(updated);
  }

  render() {
    const { onChange, value, process, ...props } = this.props;

    const { event, eventCondition, actions } = value || {};

    return <div className={'p-1 row no-gutters zoom-75'}>
      <div className={'col-2'}>
        <SingleTextEditor placeholder={'Event...'} className={'text-right'}
                          onChange={this.editField.bind(this, 'event')}
                          value={event}/>
      </div>

      <div className={'col-4 pl-2'}>
        <ProcessStateChangeSelector
          validStates={process.states}
          onChange={(json) => this.editField(`eventCondition`, json)}
          value={eventCondition}/>
      </div>

      <div className={'col-5 pl-2'}>
          <MultipleItemsEditor onChange={(json) => this.editField('actions', json)}
                               value={actions}
                               defaultNewItem={{ actionType: 'createTask', taskId: '' }}
                               renderComponent={(action, props, i) => {
                                 return <JsonTextEditor small {... props} value={action}/>;
                               }}
        />
    </div>
  </div>
    ;
  }
}
