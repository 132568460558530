import React, { Component, useContext, useState } from 'react';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';

import ModalBatchEditLabels, { defaultColumns, LOGIC_SET_UNSET__FIELD } from './ModalBatchEditLabels';


export default function ModalBatchEditLegos({ docs, onCancel, onSave  }) {
  let { page } = useContext(LegoAdminPageContext);
  const svc = page.service('services/legos');

  let columns = [
    defaultColumns.legoState,
    defaultColumns.locales,
    defaultColumns.context,
    defaultColumns.labels
  ];

  return <ModalBatchEditLabels docs={docs} onCancel={onCancel} onSave={onSave} service={svc} columns={columns}/>;
};
