import React, { useContext, useEffect, useState } from 'react';
import TextDiff from '../test-sets/TextDiff';
import PDFHtmlViewer from './PDFHtmlViewer';
import _ from 'lodash';

const hashCyrb53 = function(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
  h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1>>>0);
};

const cleanPage = str => str.toLowerCase()
                             .replace(/\(cb8\).*/g, '')
                             .replace(/\.+/g, '.')
                             .replace(/pagina \d+/g, 'pagina XXX')
                             .replace(/\bp(ag)?\. \d+/g, 'pagina XXX')
                             .replace(/\d+ focus/g, 'XXX focus')

const hashPdfPage = function(str) {
  return hashCyrb53(cleanPage(str).replace(/[^\w\s]/gi, ''));
};

export default function PDFTwoWayComparison({ pdfA, pdfB, from, to }) {
  let pages = [];

  from = from || 0;
  to = to || Math.max(pdfA.pages.length, pdfB.pages.length);

  console.time('hashPdfs')


  const groupFragments = ({pages}) => {
    let fragments = {};
    for(const p of pages) {
      for(const w of cleanPage(p.allText).split('\s')) {
        fragments[w] = fragments[w] || new Set();
        fragments[w].add(p.index);
      }
    }
    return fragments;
  };

  const findBestMatches = ({pages}, map) => {
    let res = [];
    for(const p of pages) {
      let candidates = _.range(0, pdfA.pages.length).map(() => 0);

      const tokens = cleanPage(p.allText).split('\s');

      for(const w of tokens) {
        for(const m of (map[w] || []))
          candidates[m]++;
      }

      let [v,maxPos] = _.maxBy(_.map(candidates, (c,i) => [c,i]), '0');
      // const topCount = _.sortBy(candidates, n => n).slice(-1)[0];

      const coverage = v / tokens.length * 100;
      console.log(`%cPage ${p.index} → ${maxPos}  [${coverage.toFixed(0)}%]`, `color: ${coverage > 90 ? 'green': 'red'}`);
      res.push([maxPos, coverage]);
    }
    return res;
  }

  let fragA = groupFragments(pdfB);

  let matches = findBestMatches(pdfA, fragA);

  // groupFragments(pdfB);

  let hashesA = _.map(pdfA.pages, page => hashPdfPage(page.allText));
  let hashesB = _.map(pdfB.pages, page => hashPdfPage(page.allText));

  console.timeEnd('hashPdfs')


  for (let i = from; i < to; i++) {
    let pageA = pdfA.pages[i];
    let [page, coverage] = matches[i] || [-1,0];

    let pageB = pdfB.pages[page];

    const background = `bg-light-${coverage > 90 ? 'success' : (coverage > 70 ? 'warning' : 'danger')}`;
    pages.push(<tr><td className={'border-dark border-top '+background} colSpan={4}>
      <h4>Page {i} → {page} <span className={'badge badge-secondary'}>{coverage.toFixed(0)}%</span></h4>
    </td></tr>)

    if(pageA && pageB) {
      const hA = hashesA[i];
      // const hB = hashesB[i];
      const coverImgSrc = PDFHtmlViewer.generatePageImgUrl(pdfA.hash, 'thumbnails', i+1, pdfA.pages.length, pdfA.s3Bucket);
      const coverImgSrcB = PDFHtmlViewer.generatePageImgUrl(pdfB.hash, 'thumbnails', page+1, pdfB.pages.length, pdfB.s3Bucket);

      if(hA && _.includes(hashesB, hA)) {
        pages.push(<tr className={'bg-light-success'} key={'page' + i}>
          <td>✔</td>
          <td className={''}><img src={coverImgSrc} className={''} style={{ height: '200px' }}/></td>
          <td className={''}><img src={coverImgSrcB} className={''} style={{ height: '200px' }}/></td>
          <td>✔</td>
        </tr>)
      } else {

        pages.push(<tr className={background} key={'page' + i}>
          {/*<td className={''} colSpan={2}>*/}
          {/*  <TextDiff type={'words'} inputA={pageA?.allText.slice(0,100)} inputB={pageB?.allText.slice(0,100)}/>*/}
          {/*</td>*/}

          <td className={''}>{cleanPage(pageA?.allText)}</td>
          <td className={''}><img src={coverImgSrc} className={''} style={{ height: '200px' }}/></td>
          <td className={''}><img src={coverImgSrcB} className={''} style={{ height: '200px' }}/></td>
          <td className={''}>{cleanPage(pageB?.allText)}</td>
        </tr>);
      }
    }
  }

  return <table><tbody>{pages}</tbody></table>;
}
