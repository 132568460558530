import React, { Component } from 'react';
import DataProcessDefinitionsDataCache from '../lego/data-caches/DataProcessDefinitionsDataCache';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import _ from 'lodash';

export default class ProcessIdSelector extends Component {
  constructor(props) {
    super(props);

    this.state = { processes: [] };

    this.dataCache = new DataProcessDefinitionsDataCache();
    this.dataCache.onDataUpdate(processes => this.setState({ processes }));
  }

  render() {
    const processType = this.props.value;

    const procesess = _.map(this.state.processes, 'processType');

    if (processType || procesess) {
      return <SingleValueSelector value={processType} onChange={this.props.onChange} options={procesess}/>
    } else {
      return <span>Loading processes...</span>;
    }
  }
}
