import React from 'react';
import CountryFlag from '../common/CountryFlag';

const localeToCountry = {
  'es': 'es',
  'en': 'us',
  'pt': 'br'
};

export function LocaleFlag({ locale, ... props }) {
  return <CountryFlag countryCode={localeToCountry[locale]} {... props} />;
}
