import React, { Component } from 'react';

import ManualSourceSelector from './ManualSourceSelector';
import MultipleItemsEditor from './MultipleItemsEditor';

export default function SourceEditor({value, onChange}) {
    return <div className={''}>
      <MultipleItemsEditor onChange={onChange}
                           value={value}
                           defaultNewItem={null}
                           renderComponent={(source, props, i) => {
                             return <ManualSourceSelector {... props} value={source}/>
                           }}/>
      {/*{source ? <div className={'small monospace mt-1 text-info'}>{JSON.stringify(source)}</div> : null}*/}
    </div>;
}
