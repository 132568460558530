import React from 'react';

import _ from 'lodash';
import Select from 'react-select';
import { DataTypeTag } from './DataTypeTag';

export default class ProcessStateChangeSelector extends React.Component {
  getOption(props) {
    let {innerProps, isSelected, isFocused} = props;
    let {innerRef, ... filteredProps} = innerProps;
    return <div {... filteredProps} className={'p-1 '+((isSelected || isFocused) ? 'bg-light-primary': '')}>
      <DataTypeTag type={props.value}/>
    </div>
  };

  getMultiValueLabel(props) {
    return <DataTypeTag type={props.children}/>
  };

  render() {
    const {onChange, value, validStates, ... props} = this.props;

    const addStateIcons = stateString => stateString.replace('complete', '✔').replace('unknown', '❔')

    const getLabel = (stateName, stateValue) => `${stateName}=${addStateIcons(stateValue)}`;

    const options = _.flatten(_.map(validStates, (states,stateName) => _.map(states, s => ({label: getLabel(stateName, s), value: [stateName, s]}))));

    let values = _.map(this.props.value || {}, (stateValue, stateName) => {
      let match = _.find(options, ({value: [name, state]}) => stateValue == state && stateName == name);
      return match || { label: getLabel('❓'+stateName, stateValue), value: [stateName, stateValue] };
    });

    return (
      <Select
        closeOnSelect={true}
        isMulti
        // components={{ Option: this.getOption.bind(this), MultiValueLabel: this.getMultiValueLabel.bind(this) }}
        onChange={this.onSelectChange.bind(this)}
        options={options}
        placeholder="No state change"
        menuShouldScrollIntoView={false}
        value={values}
        {... props}
      />
    );
  }

  onSelectChange(values) {
    let stateModifications = _.keyBy(values, v => v.value[0])
    let changedValue = _.mapValues(stateModifications, ({value: [subState,state]}) => state);
    return this.props.onChange(changedValue);
  }
}
