import React from "react";
import _ from "lodash";

import ManualFeaturesLegoEditor from "./ManualFeaturesLegoEditor";
import { FuseboxPdfExtractionEditor } from '../../lego/extraction-data-editors/FuseboxPdfExtractionEditor';


const fuseboxSelector = {
  type: 'fusebox',
  intentions: 'spec',
  'source.type': 'owner-manual',
}

const defaultNewEmptyLego = {
  data: [{
    fuses: []
  }],
  type: 'fusebox',
  intentions: ['spec'],
  state: 'inprogress',
  semantics: ['DIAGRAMA_DE_FUSIBLES'],
}

export default class ManualFeaturesFuseboxEditor extends ManualFeaturesLegoEditor {
  constructor(props) {
    super(props, 'Fusebox', 'features.hasFuseboxTable', fuseboxSelector, defaultNewEmptyLego);
    this.state.commonContext = false;
    this.state.featureName = 'Has fusebox table/diagram';
    this.useIndividualContext = true;
  }

  getSingleLegoDataEditor(lego, props) {
    // Parameters needed for manual fragment extraction
    const pdfUrl = this.props.manual.manualPdfUrl;
    const manualId = this.props.manual._id;
    return <FuseboxPdfExtractionEditor manualPdfUrl={pdfUrl} manualId={manualId} value={lego} {...props}/>;
  }
}
