import React, { Component } from 'react';
import _ from 'lodash';

export default class InputRegexFilter extends Component {

  render() {
    let {className, small, value, label, placeholder, onChange, onBlur, ... otherProps} = this.props;
    let extraClasses = ' ';

    if(!label) {
      extraClasses += (className || "") ;
    }

    if(small) {
      extraClasses+= ' form-control-sm'
    }

    onBlur = onBlur || (() => null);

    const validRegex = !value || !_.isString(value);
    const onTextChange = ({ target: { value } }) => {
      try {
        return onChange((value && new RegExp(value)) || '');
      } catch(err) {
        return onChange(value);
      }
    };

    const especialKeysHandler = (e) => {
      if(e.key === 'Escape') {
        onChange('')
      }
    };

    let input = <input type={'text'} className={'form-control ' + (validRegex ? '' : 'text-danger ') + extraClasses}
                                                                       spellCheck="false"
                                                                       onChange={onTextChange}
                                                                       onKeyUp={especialKeysHandler}
                                                                       onBlur={e => onBlur(e.target.value)}
                                                                       value={value?.source || value || ''} placeholder={placeholder || 'Filter with a regexp...'}
                                                                       { ... otherProps}
    />;

    if(label) {
      return <div className={"input-group"+(small? ' input-group-sm ': ' ')+(className || "")}>
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">{label}</span>
        </div>
        {input}
      </div>
    } else {
      return input
    }
  }
}
