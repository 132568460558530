import React, { Component } from 'react';

export class Icon extends Component {
  render() {
    let { icon, level, size, description, className, ... other } = this.props;

    let sizes = {
      'sm': 'zoom-75',
      'lg': 'h2 m-0',
      'xl': 'h1 m-0',
    }

    let sizeClass = size ? (sizes[size] || '') : '';

    return <i className={`material-icons align-middle text-${level || 'default '} ${sizeClass} ${className || ''}`} {... other}>
        {icon}
      </i>
  }
}
