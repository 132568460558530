import React from 'react';
import _ from 'lodash';

import CountryFlag from './CountryFlag';
import { Icon } from './Icon';

export function VehicleSummary({ vehicle: { modelId, year, trimText, trimId, countryCode, trimSpecs } }) {
  const trimSummary = _.compact(_.values(trimSpecs).join(' '));
  const trimDescription = trimId ? trimText : <span className={'text-warning'}>{trimText}</span>;
  let flag = countryCode ? <CountryFlag small countryCode={countryCode}/> : null;
  let trimIdMark = trimId ? <Icon size={'sm'} level={'violeta'} icon={'fingerprint'} title={trimId}/> : null;
  return <span>
    <span className={'ml-05 badge badge-violeta'}>
      {flag} <span className={'align-middle'}>{modelId} {year}</span>
      <span className={'ml-1 align-middle small text-light'}>{trimSummary} {trimDescription}</span>
    </span>
    {trimIdMark}
  </span>;
}
