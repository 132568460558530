import React from 'react';
import _ from 'lodash';
import '../styles/admin-manuals-upload-table.less';

import LivePage from './LivePage';
import SingleTextEditor from '../components/common/editors/SingleTextEditor';
import LoadFileButton from '../components/common/LoadFileButton';
import { IconButton } from '../components/common/IconButton';
import { Icon } from '../components/common/Icon';
import MultilineTextEditor from '../components/common/editors/MultilineTextEditor';
import downloadFile from '../components/downloadFile';
import JsonTextEditor from '../components/common/editors/JsonTextEditor';
import submenuManuals from './menus/submenu-manuals';
import InputManualsMultipleSemantics from '../components/common/editors/InputManualsMultipleSemantics';
import ReactS3Uploader from '../components/common/react-s3-upload/ReactS3Uploader';
import MultiCountryEditor from '../components/common/editors/MultiCountryEditor';

// const Sample = require('C:/Users/Edgar/Opinautos/json_manuales/chevrolet_ES.json');

const knownFields = ['description', 'idModelo', 'trims', 'year', 'country', 'locale', 'isoLanguage',
  'sourceUrl', 'otherOfficialInfo', 'originalUploadUserId', 'originalUploadName', 'manualCategory', 'manualSubsection', 'fileSize', 'groupId'];

export default class ManualsBatchUpload extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    this.manualsUploadService = this.service('services/data/manuals/upload');
    this.modelExtractorService = this.service('services/nlp/model/extractor');
    this.S3BucketService = window.client.service('services/s3-upload-tmp-manuals');

    this.state = {
      ...this.state,
      searchText: '',
      entries: []
      // entries: Sample
    };

    this.submenu = submenuManuals;
  }

  async componentDidMount() {
    super.componentDidMount();
    this.addRow();
  }

  async loadJSON(e, results) {
    const entries = [];

    results.forEach(result => {
      const [e, file] = result;

      try {
        const data = JSON.parse(e.target.result);
        const userSignature = this.getLoggedUserSignature();

        _.each(data, x => {
          if (!x.trims) x.trims = [];
          if (!x.otherOfficialInfo) x.otherOfficialInfo = {};
          if (x.year !== undefined && _.isString(x.year)) x.year = Number(x.year);
          x.originalUploadUserId = userSignature.id;
          if (!x.manualCategory) x.manualCategory = [];
          if (!x.manualSubsection) x.manualSubsection = [];
          if (x.fileSize) delete x.fileSize;
          const unknownFields = _.omit(x, knownFields);
          Object.assign(x.otherOfficialInfo, unknownFields);
        });

        entries.push(...data);
      } catch (err) {
        alert(err);
      }
    });

    // The UI can freeze horribly if by mistake (or not) a large array is loaded
    if (entries.length > 1000 && !confirm(`The loaded file has ${entries.length} manuals, the UI will be VERY slow above 500 manuals. Do you want to continune?`)) {
      return;
    }

    this.setState({ entries, searchText: '', invalidSearch: null });

    // Extract models from description
    const entriesByDescription = _.groupBy(entries, e => e.description || '');
    const promises = [];

    _.each(entriesByDescription, (selectedEntries, description) => {
      if (!_.isEmpty(description)) {
        promises.push(this.processDescription(description, selectedEntries));
      }
    });

    await Promise.allSettled(promises);
    this.forceUpdate();
    this.validate(entries);
  }

  validateDuplicatedEntries() {
    const getManualId = m => {
      let otherInfo = _.toPairs(m.otherOfficialInfo || {});
      otherInfo = _.sortBy(otherInfo, ([k, v]) => k);
      otherInfo = _.map(otherInfo, ([k, v]) => `${k}:${v}`).join('-');
      const trims = _.sortBy(m.trims || []).join('-');
      return `${m.idModelo}-${m.year}-${m.country}-${m.isoLanguage}-${trims}-${otherInfo}`;
    };

    const entries = this.state.entries;
    const entriesByManualId = {};

    for (let index = 0; index < entries.length; ++index) {
      const entry = entries[index];
      const manualId = getManualId(entry) + entry.sourceUrl;
      const existingManual = entriesByManualId[manualId];

      // Validate duplicate entries
      if (existingManual && existingManual.sourceUrl === entry.sourceUrl) {
        const err = new Error('Duplicated manual.');
        err.isDuplicate = true;
        this.onError(entry, err);
      } else {
        if (!existingManual) {
          entriesByManualId[manualId] = entry;
        }

        if (entry.error && entry.error.isDuplicate) {
          delete entry.state;
          delete entry.error;
        }
      }
    }
  }

  validate(entries) {
    this.validateDuplicatedEntries();
    entries = _.reject(entries, e => e.state === 'error');

    this.manualsUploadService.find({ query: { entries } }).then(errors => {
      _.each(errors, (err, index) => {
        const entry = entries[index];
        this.onError(entry, err);
      });

      this.forceUpdate();
    });
  }

  async uploadAll() {
    let entriesToUpload = _.filter(this.state.entries, e => e.selected);
    let isFirstEntryToUpload = true;
    // const promises = [];

    if (entriesToUpload.length === 0) {
      entriesToUpload = this.state.entries;
    }

    for (const e of entriesToUpload) {
      if (e.state === 'success') continue;

      const promise = this.uploadManual(e, isFirstEntryToUpload);
      await promise;
      // promises.push(promise);
      isFirstEntryToUpload = false;
    }
    // await Promise.allSettled(promises);
  }

  onError(entry, err) {
    if (err === 'Error: Manual already exists in DB with the same Url.') {
      entry.state = 'success';
      delete entry.error;
      delete entry.selected;
    } else {
      console.log(err);
      entry.state = 'error';
      entry.error = err;
    }
  };

  async uploadManual(entry, isFirstEntryToUpload = true) {
    try {
      entry.state = 'uploading';
      this.forceUpdate();

      const leanEntry = { ...entry };
      leanEntry.trims = _.compact(leanEntry.trims); // Remove "" strings
      delete leanEntry.state;
      delete leanEntry.error;
      delete leanEntry.selected;
      delete leanEntry.description;
      delete leanEntry.descriptionError;
      delete leanEntry.fileSize;
      console.log(leanEntry);

      const errors = await this.manualsUploadService.create({
        entries: [leanEntry],
        reuseCaches: !isFirstEntryToUpload
      });

      if (errors[0]) {
        const err = errors[0];
        this.onError(entry, err);
      } else {
        console.log('success');
        entry.state = 'success';
        delete entry.error;
        delete entry.selected;
      }
    } catch (err) {
      this.onError(entry, err);
    }

    this.forceUpdate();
  }

  onFieldChange(entry, fieldName, newValue) {
    // Needed when an empty row is added and entry receives value for the first time
    if (!entry.editingField) {
      this.editStart(entry, fieldName);
      this.setState({ entries: this.state.entries })
    }

    if (fieldName === 'year') {
      newValue = Number(newValue);
    }

    const selectedEntries = _.filter(this.state.entries, e => e.selected);

    if (selectedEntries.length === 0) {
      selectedEntries.push(entry);
    }

    for (let row of selectedEntries) {
      row[fieldName] = newValue;
    }

    this.forceUpdate();
  }

  selectEntry(entry) {
    entry.selected = !entry.selected;
    this.forceUpdate();
  }

  selectAllEntries(e) {
    this.selectNone();

    for (const entry of this.filteredEntries()) {
      if (entry.state === 'success') continue;
      entry.selected = e.target.checked
    }

    this.forceUpdate();
  }

  selectNone() {
    for (const entry of this.state.entries) {
      delete entry.selected;
    }
    this.forceUpdate();
  }

  async processDescription(description, entries) {
    _.each(entries, e => delete e.descriptionError);

    if (!_.isEmpty(description)) {
      let modelInfo;

      try {
        modelInfo = await this.modelExtractorService.create({ query: description });
      } catch (err) {
        _.each(entries, e => e.descriptionError = err);
      }

      if (modelInfo) {
        const unknownTokens = modelInfo.text.replace(/MAKE_TOKEN|MODEL_TOKEN|YEAR_TOKEN|TRIM_TOKEN|\s+|[.,;:]/g, ' ');
        const isFullParse = unknownTokens.trim() === '';

        if (!isFullParse) {
          const err = new Error(`Invalid model description. Cannot recognize: ${unknownTokens}`);
          _.each(entries, e => e.descriptionError = err);
        }

        for (const entry of entries) {
          if (modelInfo.idModelo && _.isEmpty(entry.idModelo)) {
            entry.idModelo = modelInfo.idModelo;
          }

          if (modelInfo.year && _.isEmpty(entry.year)) {
            entry.year = modelInfo.year;
          }

          if (modelInfo.trim && _.isEmpty(entry.trims)) {
            entry.trims = modelInfo.trim;
          }
        }
      }
    }
  }

  editStart(entry, fieldName) {
    if (!entry.selected) {
      this.selectNone();
    }

    entry.editingField = fieldName;
    this.forceUpdate();
  }

  async editEnd(entry) {
    const editingField = entry.editingField;
    delete entry.editingField;

    const selectedEntries = _.filter(this.state.entries, e => e.selected);

    if (selectedEntries.length === 0) {
      selectedEntries.push(entry);
    }

    _.each(selectedEntries, e => {
      delete e.state;
      delete e.error;
    });

    if (editingField === 'description') {
      await this.processDescription(entry.description, selectedEntries);
    }

    this.validate(selectedEntries);
  }

  filteredEntries() {
    let { searchText, invalidSearch } = this.state;

    if (searchText.trim().length && !invalidSearch) {
      try {
        // Search
        const regex = new RegExp(searchText, 'i');

        return _.filter(this.state.entries, entry => {
          return _.some(_.values(entry), value => JSON.stringify(value).match(regex));
        });
      } catch (err) {
        // Do nothing
      }
    }

    return this.state.entries;
  }

  async downloadAsJSON() {
    let entries = this.filteredEntries();

    if (entries.length) {
      entries = _.map(entries, entry => {
        const { state, error, selected, descriptionError, trims, ...leanEntry } = entry;
        leanEntry.trims = _.compact(trims); // Remove "" strings
        return leanEntry;
      });

      const fileName = `batch-${entries[0].idModelo}-${(entries.length)}.json`;
      downloadFile(JSON.stringify(entries, true, 4), fileName, 'text/plain');
    }
  }

  addRow() {
    const userSignature = this.getLoggedUserSignature();

    const newRow = {
      idModelo: '',
      year: '',
      trims: [],
      country: [],
      locale: '',
      isoLanguage: '',
      otherOfficialInfo: {},
      sourceUrl: '',
      originalUploadUserId: userSignature.id,
      manualCategory: [],
      manualSubsection: []
    };

    this.state.entries = [newRow, ...this.state.entries];

    this.forceUpdate();
  }

  duplicateRow(entry, index) {
    const newRow = { ...entry };

    this.state.entries.splice(index, 0, newRow);

    this.validate([newRow]);
  }

  deleteRow(entry) {
    this.state.entries = _.without(this.state.entries, entry);

    this.validate([]);
  }

  onSearchChange(text) {
    this.state.searchText = text;

    let invalidSearch = null;
    try {
      new RegExp(text, 'i');
    } catch (err) {
      invalidSearch = true;
    }
    this.setState({ searchText: text, invalidSearch });
  }

  getSignedUrl(file, callback) {
    let name = file.name;

    this.S3BucketService.create({
      fileName: name.replace(/[^\w\d_\-\.]+/ig, ''),
      mimeType: file.type,
      folder: this.props.folder
    }).then(callback).catch((err) => {
      this.setState({ uploading: false, error: err.toString() });
    });
  }

  onUploadProgress(progress, msg, file) {
    const userSignature = this.getLoggedUserSignature();
    let entries = this.state.entries;
    let { sourceUrl, manualCategory, modelId } = entries[0];
    if (msg === 'Waiting') {
      let newEntry = {
        sourceUrl: `${file.name}`,
        manualCategory: [],
        originalUploadUserId: userSignature.id,
        otherOfficialInfo: {},
        country: [],
        uploadingFromFile: 'Waiting',
        fileSize: `${(file.size / 1024 / 1024).toFixed(1)} MB`
      }
      if (!sourceUrl && !modelId && manualCategory.length === 0) {
        entries[0] = newEntry;
      } else {
        entries.push(newEntry);
      }
    }
    if (msg === 'Uploading') {
      _.find(entries, {sourceUrl: file.name}).uploadingFromFile = `${progress} %`
    }
    this.setState({ entries });
  }

  onUploadError(err, file) {
    console.warn(file.name, err)
    let entries = this.state.entries;
    _.find(entries, { sourceUrl: file.name }).uploadingFromFile = err;
    this.setState({ entries });
  }

  onUploadFinish({ s3Url, s3Filename }, file) {
    let entries = this.state.entries;
    let entry = _.find(entries, { sourceUrl: file.name })
    entry.sourceUrl = s3Url;
    delete entry.uploadingFromFile;

    this.setState({ entries });
  }

  generateGroupId () {
    const selectedEntries = _.filter(this.state.entries, e => e.selected);
    //groupoId generate UUIDv4
    const groupId = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    for (let entry of selectedEntries) {
      entry.groupId = groupId.slice(0,8);
    }

    this.forceUpdate();
  }

  renderEntry(entry, index) {
    let bgColor = '';

    if (entry.state === 'uploading') bgColor = ' bg-light-warn';
    else if (entry.state === 'error') bgColor = ' bg-light-danger';
    else if (entry.state === 'success') bgColor = ' bg-light-success';

    if (entry.selected) bgColor += ' highlight-primary';

    const renderField = field => {
      let result;

      let fieldValue = entry[field];
      const editingField = entry.editingField === field;
      let emptyField = (!fieldValue || (_.isArray(fieldValue) && !fieldValue.length)) && field !== 'description' && field !== 'trims'

      if (editingField) {
        if (field === 'trims') {
          result = <MultilineTextEditor autoFocus={editingField}
                                        maxRows={3}
                                        placeholder={'One Trim per line...'}
                                        value={(fieldValue || []).join('\n')}
                                        onChange={text => this.onFieldChange(entry, field, text.split('\n'))}
                                        onBlur={() => this.editEnd(entry)}/>;
        } else if (field === 'otherOfficialInfo') {
          result = <JsonTextEditor autoFocus={editingField}
                                   rows={3}
                                   value={fieldValue || {}}
                                   placeholder={'Other?'}
                                   onChange={obj => this.onFieldChange(entry, field, obj)}
                                   onBlur={() => this.editEnd(entry)}/>;
        } else if (field === 'manualCategory' || field === 'manualSubsection') {
          result = <InputManualsMultipleSemantics value={fieldValue || []}
                                                  onChange={text => this.onFieldChange(entry, field, text)}
                                                  onBlur={() => this.editEnd(entry)}/>;
        } else if (field === 'country'){
          result = <MultiCountryEditor value={fieldValue || []}
                              onChange={text => this.onFieldChange(entry, field, text)}/>
        } else {
          let options;

          if (['locale', 'isoLanguage', 'country'].includes(field)) {
            options = field + '_options';
          }
          result = <SingleTextEditor autoFocus={editingField}
                                     value={fieldValue}
                                     small={true}
                                     placeholder={field}
                                     list={options}
                                     onChange={text => this.onFieldChange(entry, field, text)}
                                     onBlur={() => this.editEnd(entry)}/>;
        }
      } else {
        if (field === 'trims') {
          if (_.compact(fieldValue).length) {
            fieldValue = _.map(fieldValue, trim => <div key={trim}>{trim}</div>);
          } else {
            fieldValue = <IconButton icon={'add'} level={'success'} description={'Add trim'}/>;
          }
        } else if (field === 'sourceUrl') {
          fieldValue = <div title={fieldValue}>{'...' + fieldValue.slice(-50)}</div>;
        } else if (field === 'otherOfficialInfo') {
          if (_.isEmpty(fieldValue)) {
            fieldValue = <div className={'text-center'}>
              <IconButton icon={'add'} level={'success'} description={'Add other info'}/>
            </div>;
          } else {
            fieldValue = _.map(fieldValue, (value, key) => <div key={key}>
              <div className={'font-italic text-primary'}>{key}:</div>
              <div>{_.isString(value) ? value : JSON.stringify(value)}</div>
            </div>);
          }
        } else if (field === 'manualCategory' || field === 'manualSubsection' || field === 'country') {
          if(_.isEmpty(fieldValue)) {
            fieldValue = <div className={'text-center'}> - </div>;
          } else {
            fieldValue = <div>{_.map(fieldValue, value => <div key={value}>{value}</div>)}</div>
          }
        } else if (field === 'groupId') {
          fieldValue = <div className={'badge badge-rosa zoom-75'}>{fieldValue}</div>
        }
        const editCell = () => this.editStart(entry, field);
        if (entry.state === 'success' || entry.state === 'uploading') {
          result = <div>{fieldValue}</div>;
        } else {
          result = <div tabIndex={0} onClick={editCell} onFocus={editCell}>{fieldValue || <span>&nbsp;</span>}</div>;
        }
      }
      if (field === 'uploadingFromFile') result = <span className={'badge badge-warning'}>{fieldValue}</span>;
      if (field === 'fileSize') result = <span className={'badge badge-info'}>{fieldValue}</span>;

      return result;
    };

    let renderGroupId = _.find(this.state.entries, 'groupId')

    const row = <tr key={index} className={'text-sm' + bgColor}>
      <td className={'text-center align-middle table-column-small text-secondary small'}>{index + 1}</td>
      <td className={'text-center align-middle table-column-small'}>
        {
          entry.state === 'success' ?
            undefined :
            <input type="checkbox" checked={!!entry.selected} onChange={() => this.selectEntry(entry)}/>
        }
      </td>
      <td className={'text-center align-middle table-column-small'}>
        {
          entry.state === 'success' ?
            <IconButton icon={'check'} level={'success'}/> :
            entry.state === 'uploading' ?
              <IconButton icon={'refresh'} className={'rotate'}/> :
              <IconButton icon={'cloud_upload'}
                          onClick={() => this.runAsync(this.uploadManual(entry), 'Uploading manual...')}/>
        }
      </td>
      <td className={'align-middle table-column-large'}>{renderField('description')}</td>
      {renderGroupId ? <td className={'align-middle table-column-small'}>{renderField('groupId')}</td> : null}
      <td className={'align-middle table-column-large'}>{renderField('idModelo')}</td>
      <td className={'text-center align-middle table-column-small'} style={{ width: '80px' }}>{renderField('year')}</td>
      <td className={'text-center align-middle'}>{renderField('trims')}</td>
      <td className={'text-center align-middle'}>{renderField('country')}</td>
      <td className={'text-center align-middle table-column-small'} style={{ width: '80px' }}>{renderField('locale')}</td>
      <td className={'text-center align-middle table-column-small'} style={{ width: '105px' }}>{renderField('isoLanguage')}</td>
      <td className={'align-middle small'}>{renderField('otherOfficialInfo')}</td>
      <td className={'align-middle small'} style={{ width: '200px' }}>{renderField('manualCategory')}</td>
      <td className={'align-middle small'} style={{ width: '200px' }}>{renderField('manualSubsection')}</td>
      <td className={'align-middle small'}>{renderField('sourceUrl')}{renderField('uploadingFromFile')}{renderField('fileSize')}</td>
      <td className={'text-center align-middle table-column-small'}>
        {
          entry.sourceUrl ?
            <a href={entry.sourceUrl} target={'_blank'}>
              <IconButton icon={'picture_as_pdf'} level={'primary'} description={'Open manual'}/>
            </a> :
            undefined
        }
      </td>
      <td className={'align-middle small'}>
        <IconButton icon={'file_copy'} level={'success'} onClick={() => this.duplicateRow(entry, index)}
                    description={'Duplicate row'}/>
        <IconButton icon={'clear'} level={'danger'} onClick={() => this.deleteRow(entry)} description={'Delete row'}/>
      </td>
    </tr>;

    const rows = [row];

    if (entry.descriptionError || entry.error) {
      let errors = _.compact([entry.descriptionError, entry.error]);

      errors = _.map(errors, (err, i) => {
        return <div key={i}>
          {err.innerError ? err.innerError.toString() : err.toString()}
        </div>;
      });

      const errorRow = <tr key={index + '_error'} className={'small text-danger bg-light-danger'}>
        <td colSpan={16}>{errors}</td>
      </tr>;

      rows.push(errorRow);
    }

    return rows;
  }

  renderPageBody() {
    const entries = this.filteredEntries();

    const errorsCount = _.filter(this.state.entries, e => e.state === 'error').length;
    const remainingCount = _.filter(this.state.entries, e => e.state !== 'success').length;
    const selectedCount = _.filter(this.state.entries, e => e.selected).length;
    const allSelected = _.every(entries, e => e.selected || e.state === 'success');

    // TODO: Find practical way to read countries and locales from i18n files

    return <React.Fragment>
      <div className={'bg-secondary p-2 m-0 d-flex align-items-baseline'}>
            <LoadFileButton title={'Load from JSON'}
                            classes={'btn btn-link btn-sm mr-1 text-light'}
                            onChange={(e, res) => this.runAsync(this.loadJSON(e, res), 'Loading JSON...')}/>
            <IconButton icon={'save_alt'} onClick={() => this.downloadAsJSON()} level={'light'}
                        description={'Save as JSON'}>
              Save as JSON
            </IconButton>
            <label htmlFor={'uploadFile'}>
                <span className={'btn btn-link btn-sm mr-1 text-light'}>
                  <Icon icon={'picture_as_pdf'} level={'light'} description={'Load manuals from local files'}/> Upload from file
                </span>
              <ReactS3Uploader
                accept="application/pdf"
                getSignedUrl={this.getSignedUrl.bind(this)}
                onProgress={this.onUploadProgress.bind(this)}
                onError={this.onUploadError.bind(this)}
                onFinish={this.onUploadFinish.bind(this)}
                ref={uploader => this.uploader = uploader}
                id={'uploadFile'}
                uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
                multiple
                style={{ display: 'none' }}
                inputRef={cmp => this.uploadInput = cmp}
                autoUpload={true}
              />
            </label>
            <IconButton icon={'queue'} onClick={() => this.generateGroupId()} level={'light'}
              description={'Generate a unique groupId for selected entries'} disabled={!selectedCount}>Generate Group ID
            </IconButton>
        <span className={'col-auto text-light'}>Found {errorsCount} errors</span>
        <span className={'col-auto'}>
          <SingleTextEditor className={this.state.invalidSearch ? 'bg-light-danger text-light' : ''}
                            value={this.state.searchText}
                            placeholder={'Search...'}
                            onChange={text => this.onSearchChange(text)}/>
        </span>
        <span className={'col text-sm-right'}>
          <button className={'ml-2 btn btn-primary'}
                  onClick={() => this.runAsync(this.uploadAll(), 'Uploading manuals...')}>
            {
              selectedCount > 0 ?
                `Upload selected (${selectedCount})` :
                `Upload all (${remainingCount})`
            }
          </button>
        </span>
      </div>
      <div className={''}>
        <table className={'table table-sm manuals-table'}>
          <thead>
          <tr>
            <th className={'text-center table-column-small'}></th>
            <th className={'text-center align-middle table-column-small'}>
              <input type="checkbox" checked={allSelected} onChange={e => this.selectAllEntries(e)}/>
            </th>
            <th className={'text-center table-column-small'}>Upload</th>
            <th className={'text-center table-column-large'}>Description</th>
            {_.find(entries, 'groupId') ? <th className={'text-center table-column-small'}>Group ID</th> : null}
            <th className={'text-center table-column-large'}>Model</th>
            <th className={'text-center'}>Year</th>
            <th className={'text-center'}>Trims</th>
            <th className={'text-center'}>Country</th>
            <th className={'text-center'}>Locale</th>
            <th className={'text-center'}>Language</th>
            <th className={'text-center'}>Other</th>
            <th className={'text-center'}>Category</th>
            <th className={'text-center'}>Subsection</th>
            <th className={'text-center table-column-large'}>Url</th>
            <th className={'text-center table-column-small'}></th>
            <th className={'text-center'}>
              <IconButton icon={'note_add'} level={'success'} onClick={this.addRow.bind(this)} description={'Add row'}/>
            </th>
          </tr>
          </thead>
          <tbody>
          {_.flatten(_.map(entries, (e, i) => this.renderEntry(e, i)))}
          </tbody>
        </table>
      </div>
      <datalist id="locale_options">
        <option>es</option>
        <option>en</option>
        <option>pt</option>
      </datalist>
      <datalist id="isoLanguage_options">
        <option>ES</option>
        <option>EN</option>
        <option>PT</option>
        <option>ES-ES</option>
        <option>ES-AR</option>
        <option>ES-MX</option>
        <option>EN-US</option>
      </datalist>
      <datalist id="country_options">
        <option>Argentina</option>
        <option>México</option>
        <option>España</option>
        <option>Brasil</option>
        <option>Canadá</option>
        <option>Chile</option>
        <option>Colombia</option>
        <option>Ecuador</option>
        <option>Estados Unidos</option>
        <option>Francia</option>
        <option>Italia</option>
        <option>Reino Unido</option>
        <option>Venezuela</option>
      </datalist>
    </React.Fragment>;
  }
}
