import _ from 'lodash'

import {decodePDF} from "./PDFTextLayers";

const pdfsCache = {};

let evictionQueue = [];

// Cache objects are large (> 1MB), limit the cache size
function addToCache(pdfId, pdfSummaryFile) {
  pdfsCache[pdfId] = pdfSummaryFile;

  if(evictionQueue.length > 30) {
    let [first, ... remaining] = evictionQueue;
    delete pdfsCache[first];
    evictionQueue = remaining;
  }
}

export default class CachedPDFManualLoader {
  static async loadManualTextLayers(pdfHash, s3Bucket) {
    if (!pdfsCache[pdfHash]) {
      let start = new Date();

      let domain = `${s3Bucket}.s3.amazonaws.com`;
      // domain = domain.replace('startmycar-manuals.s3.amazonaws.com', 'manuals.startmycar.com')
      // domain = domain.replace('opinautos-manuals.s3.amazonaws.com', 'manuals.opinautos.com')
      domain = domain.replace('opinautos-manuals.s3.amazonaws.com', 'd3rdx2e0fux0m7.cloudfront.net' );
      domain = domain.replace('opicarros-manuals.s3.amazonaws.com', 'd2ubriaib6wvzg.cloudfront.net' );
      domain = domain.replace('startmycar-manuals.s3.amazonaws.com', 'd1qmlszngpwayh.cloudfront.net' );

      // HACK: Cloudflare CORS caching does not seem work correctly for multiple domains. So we use a fake cache busting with the domain
      const pdfTextRes = await fetch(`${document.location.protocol}//${domain}/processed/v1/${pdfHash}/layersText.json?domain=${document.location.host}`
      );

      if(!pdfTextRes.ok) {
        throw new Error(`Could not load pdf json text structure, pdf hash "${pdfHash}": ${pdfTextRes.statusText} (${pdfTextRes.status})`)
      }

      const pdfSummaryFile = await pdfTextRes.json()

      console.log(`[${pdfHash}] Manual downloaded ${pdfSummaryFile.length} pdf pages in %c${new Date() - start} ms`, 'color: red; background: #DDD;')

      start = new Date();

      addToCache(pdfHash, decodePDF(pdfSummaryFile));

      console.log(`[${pdfHash}] Manual decoded ${pdfSummaryFile.length} pdf pages took %c${new Date() - start} ms`, 'color: green; background: #DDD;')
    }

    // Ensure pdf will now be the last one to be remove from cache
    evictionQueue = _.without(evictionQueue, pdfHash);
    evictionQueue.push(pdfHash);

    return pdfsCache[pdfHash];
  }

  static isCached(pdfHash) {
      return !!pdfsCache[pdfHash];
  }
};
