import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import CRUDPage from './CRUDPage';
import DataTaskEditor from '../components/dataprocess/DataTaskEditor';
import { Link } from 'react-router-dom';
import { prettyDuration } from '../components/common/TimeUtils';
import MiniProgressBar from '../components/common/MiniProgressBar';
import { SearchFilterButton } from '../components/common/SearchFilterButton';
import submenu from './menus/submenu-tasks';
import { DataTaskTypeSelector } from '../components/tasks/DataTaskTypeSelector';
import { IconButton } from '../components/common/IconButton';
import _ from 'lodash';

dayjs.extend(relativeTime);
dayjs.extend(duration);


export default class DataTasksBatchesManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data-tasks/batches');

    this.canEdit = false;
    this.canDelete = false;
    this.canCreate = false;

    this.submenu = submenu;
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Last updated', className: '', sorter: 'updatedAt' },
      { content: 'Batch Id', className: '', sorter: 'batchId' },
      { content: 'Impact (avg)', className: 'text-center', sorter: 'impactAvg' },
      { content: 'taskId', className: 'text-center', sorter: 'typeId' },
      { content: 'State and progress', className: 'text-center', sorter: o => (o.count - o.finishedCount) },
      { content: 'Speed', className: 'text-center', sorter: o => (o.workedTotalTime / o.finishedCount) || 'z' },
      { content: 'Total time', className: 'text-center', sorter: 'workedTotalTime'  },
      { content: '', className: '' },
    ];
  }

  getFacetFiltersComponent() {
    let filters = this.state.facetFilters || {};

    const onChange = (isOn, state) => {
      if(isOn) {
        filters.state = state;
      } else {
        delete filters.state;
      }
      this.onFiltersChange(filters);
    }

    const onTypeIdChange = (type) => {
      let { typeId, ... newFilter } = filters;
      if(type) {
        newFilter.typeId = type;
      }
      this.onFiltersChange(newFilter)
    }

    return <div className={'d-flex align-items-center'}>
      <SearchFilterButton onChange={(isOn) => onChange(isOn, 'finished')} value={filters.state === 'finished'}>
        <span className={'badge badge-success'}>Finished</span>
      </SearchFilterButton>

      <SearchFilterButton onChange={(isOn) => onChange(isOn, 'pending')} value={filters.state === 'pending'}>
        <span className={'badge badge-info'}>Not finished</span>
      </SearchFilterButton>

      <SearchFilterButton onChange={(isOn) => onChange(isOn, 'not-started')} value={filters.state === 'not-started'}>
        <span className={'badge badge-secondary'}>Not started</span>
      </SearchFilterButton>

      <span style={{ width: '300px' }} className={'ml-2 zoom-90'}>
        <DataTaskTypeSelector value={filters.typeId} onChange={onTypeIdChange}/>
      </span>
    </div>;
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <DataTaskEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>;
  }

  async workInBatch(batchId) {
    // task.startedAt = new Date();
    // await this.views.update(task._id, task);
    // this.openEdit(task)
  }

  getSelectionButtons(selection) {
    let compareBtn = null;
    if(selection.length > 1) {
      return <>
        <Link to={`/tasks/batches/${selection.map(b => b.batchId).join('_+_')}`} className={'btn btn-link p-0'}>
          <IconButton icon={'play_circle_filled'} onClick={() => this.openCreateTasksModal(selection)}>Work the {selection.length} batches...</IconButton>
        </Link>
      </>
    }
  }


  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    await super.fetchObjects({$limit: 1000});
  }

  async delete(batchId) {
    if (confirm(`Are you sure you want to delete batch ${batchId}?`)) {
      try {
        await this.views.remove(batchId);
      } catch(err) {
        return this.handleError(err);
      }

      await this.fetchObjects();
    }
  }

  handleObjectRemoved(obj) {
    // Override CRUDPage default. This is handled by fetchObjects, no need to do anything
  }

  getObjectColumns(task, definition) {
    let { typeId, count, finishedCount, impactAvg, workedTotalTime, batchId, createdAt, updatedAt } = task;

    let realState = 'Not started';
    let extraStateData = null;
    let stateStyle = 'bg-light-secondary';
    let taskQuickActions = null;

    if (finishedCount === count) {
      realState = 'Finished';
      stateStyle = 'bg-light-success';
    } else if (workedTotalTime > 0) {
      realState = 'In progress';
      stateStyle = 'bg-light-primary';
    }

    let avgTaskTime = null;
    if (finishedCount > 0 && workedTotalTime > 0) {
      avgTaskTime = prettyDuration(workedTotalTime / finishedCount);
    }

    let filtersParam = '';
    if(this.getUrlParamComponent('filters')) {
      filtersParam = '?' + this.getUrlParamComponent('filters');
    }

    return [
      <td key={'date'}>
        <span className={'text-info small'} title={updatedAt}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
      </td>,
      <td key={'batchId'}>
        <div className={'text-secondary'}>{
          batchId
            ?
            <Link to={`/tasks/batches/${batchId}${filtersParam}`} className={'btn btn-link p-0'}>
              {batchId}
              <span className={'badge badge-info ml-2'}>    {count}</span>
            </Link>
            :
            <span className={''}>NOT PART OF A BATCH</span>
        }</div>
      </td>,

      <td key={'impact'} className={'text-center'}>
        <span className={'text-secondary'}>{impactAvg ? impactAvg.toFixed(2) + '%' : '-'}</span>
      </td>,

      <td key={'typeId'} className={'text-center'}>{typeId}</td>,


      <td key={'state'} className={`${stateStyle}`}>

        <div>
          <MiniProgressBar progress={finishedCount / count * 100}/>
        </div>
        <div className={'d-flex justify-content-between small'}>
          <span className={'text-success'}>{finishedCount}/<span className={'text-success'}>{count}</span></span>
          <span className={'text-secondary'}>{realState}</span>
          <span className={'small text-secondary'}>{(finishedCount / count * 100).toFixed(0)}%</span>
        </div>
        {/*<span className={'small text-secondary'}> of </span>*/}

      </td>
      ,

      <td key={'taskAverageTime'} className={`text-center ${stateStyle}`}>
        {finishedCount > 0 ? <span className={'text-info'}>{avgTaskTime}</span> : null }
      </td>,

      <td key={'totalTime'} className={`text-center ${stateStyle}`}>
        <span className={'small text-secondary'}>{dayjs.duration(workedTotalTime).humanize()}</span>
      </td>,

      <td key={'deleteButton'}>
        {
          !workedTotalTime && !finishedCount ? <IconButton onClick={() => this.delete(batchId)} level={'danger'} icon={'delete_forever'}
                      description={'Delete'}/> : null
        }
      </td>
    ];
  }
}
