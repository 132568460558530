import { ImagePreview } from '../../images/ImagePreview';
import { useContext, useState } from 'react';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import TirePressureLegoPreview from './TirePressureLegoPreview';
import { IconButton } from '../../common/IconButton';
import { getImageThumbnailMedium } from '../../../../../lib/imageUtilsESM';

const sampleRes = {
  "message": "{\n  \"pressuresByTire\": [\n    {\n      \"tires\": [\"185/65R15 88H\"],\n      \"pressures\": [\n        {\n          \"wheels\": \"front\",\n          \"pressure\": {\n            \"kpa\": 240,\n            \"psi\": 35\n          },\n          \"condition\": \"loadNormal\"\n        },\n        {\n          \"wheels\": \"rear\",\n          \"pressure\": {\n            \"kpa\": 240,\n            \"psi\": 35\n          },\n          \"condition\": \"loadNormal\"\n        }\n      ]\n    },\n    {\n      \"tires\": [\"T115/70R15 90M\"],\n      \"pressures\": [\n        {\n          \"wheels\": \"spare\",\n          \"pressure\": {\n            \"kpa\": 420,\n            \"psi\": 60\n          }\n        }\n      ]\n    }\n  ],\n  \"labelId\": \"LSGEN5307PD040874 1GA291 6918\"\n}",
  "usage": {
    "prompt_tokens": 1267,
    "completion_tokens": 208,
    "total_tokens": 1475
  },
  "responseTime": 8201
};

export default function ParseTirePressureModal({contribution, onClose}) {
  const [res, setRes] = useState();
  const [parsing, setParsing] = useState();
  const [resId, setResId] = useState();
  const [error, setError] = useState();

  const {page} = useContext(LegoAdminPageContext);

  let imgUrl = contribution.data?.images?.[0]?.url;


  let parseImage = async () => {
    try {
      setParsing(true);
      let query = {
        imageUrl: imgUrl,
        // imageUrl: getImageThumbnailMedium(imgUrl),
        context: contribution.vehicleContext,
        // useClaude: true,
      };

      let res = await page.service(`services/ai/read-tire-pressure-label`).find({query: query});

      console.log(res);
      setRes(res);
      setResId(new Date().valueOf());
      setError(null);
    } catch(e) {
      setError("ERROR: "+e.message);
    } finally {
      setParsing(false);
    }
  };

  useAsyncEffect(parseImage, [contribution._id]);

  let content =  <h5>{parsing ? 'Parsing with AI...': 'Done'}</h5>;
  if(res) {
    try {
      let resData = JSON.parse(res.message);
      content = <>
        <h5>
            Response in {(res.responseTime / 1000).toFixed(1)}s<span className={'text-info'}>
            &nbsp;[{res.usage.total_tokens} tokens]</span>
            &nbsp;<IconButton icon={'photo_filter'} disabled={parsing} title="Reparse" onClick={parseImage}>Reparse</IconButton>
            {parsing ? ' Parsing with AI...' : null }
        </h5>

        <div>
          Label: <span className={'badge badge-primary'}>{resData.labelId}</span>
        </div>
        {resData.pressuresByTire?.map((data,i) => <TirePressureLegoPreview data={data} key={i+'-'+(data.tires || ['-']).join(',')+(data.models || []).join(',')+resId}/>)}

        <hr/>
        <pre className={'small mt-4 p-1 bg-light text-secondary'} style={{maxHeight: '400px'}}>{res.message}</pre>
      </>
    } catch(e) {
      content = <div className={'alert alert-danger'}>Error parsing tire pressure: {e.message}</div>
    }
  } else if(error) {
    content = <div className={'alert alert-danger'}>{error}</div>
  }

  return <div className={'row no-gutters'} style={{minWidth: '50vw'}}>
    <div className={'col-6'}>
      <ImagePreview fluidContain zoom={true} url={imgUrl}/>
    </div>
    <div className={'col-6 pl-3'}>
      {content}
    </div>
  </div>
}

