import React from 'react';

import _ from 'lodash';

import LivePage from './LivePage';

import submenuTagging from './menus/submenu-tagging';
import { SymptomsHierarchyEditor } from '../components/symptoms-editor/SymptomsHierarchyEditor';
import { IconButton } from '../components/common/IconButton';
import uuidv4 from '../../../components/logic/uuidv4';

function copyToClipboard(text) {
  const elem = document.createElement('textarea');
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand('copy');
  document.body.removeChild(elem);
}

const initialData = {
  groups: [
  ],
  ids: []
};

function processWizardData(savedData) {
  let byId = _.keyBy(savedData.groups, 'id');

  for (const b of savedData.groups) {
    b.reportIds = b.reportIds || [];

    if (b.parentId !== undefined) {
      const p = byId[b.parentId];
      if (p && p !== b) {
        p.children = _.uniq(_.union(p.children, [b]));
        b.parent = p;
      }
    }
    for(const pId of (b.altParentIds || [])) {
      const p = byId[pId];
      if (p && p !== b) {
        p.children = _.uniq(_.union(p.children, [b]));
      }
    }
  }
  return savedData;
}

function normalize(cleanSymptom) {
  return cleanSymptom.toLowerCase();
}

export default class SymptomsManager extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;

    this.submenu = submenuTagging;
    // this.state.modelId = 'Ford-Focus'
    // this.state.limit = 40;

    this.state.name = this.props.match.params.name;

    this.notesSvc = client.service('services/common/notes');
    this.wizardSvc = client.service('/services/symptoms-wizard');

    this.shortcuts.registerKeyMappings({
      'focusSearch': 'ctrl+f',
    });

    this.shortcuts.registerActionHandlers({
      // TODO: This should be inside SymptomsHierarchyEditor exposing shortcuts with useContext
      'focusSearch': () => $("#symptoms-filter-input").focus()
    });

    // this.semantics = this.service('services/tagging/hierarchy');
    // this.notes = new NotesManager('tagging-linguistics', this.getLoggedUserSignature());
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.fetchData());
  }

  async fetchData() {
    const name = this.state.name;
    const savedData = localStorage.getItem('symptoms-' + name);
    const data = savedData ? processWizardData(JSON.parse(savedData)) : initialData;

    const [wizardObj] = await this.wizardSvc.find({ query: { name } });

    const outOfSync = (wizardObj?.__v && data?.__v && wizardObj.__v > data.__v) || (wizardObj?.__v && !data?.__v);
    const { unsaved, __v } = data;
    delete data.unsaved;
    delete data.__v;

    this.setState({ data, unsaved, version: __v ?? 1, outOfSync, dataKey: new Date().valueOf() });
  }

  async refreshSymptomsFromDB() {
    const {ids, groups} = this.state.data;

    let notes = await this.notesSvc.find({query: {$limit: 10000, namespace: 'report-symptom-annotations', thingId: {"$in": ids}}})

    // Make a copy of the groups
    let allGroups = [... groups];

    // Start with all the current groups by name
    let symptomsByName = _.groupBy(allGroups, (g) => g.name.toLowerCase());

    // In case of collisions, use the group with most reportIds to add all ids
    symptomsByName = _.mapValues(symptomsByName, groups => _.sortBy(groups, g => - (g.reportIds?.length || 0))[0]);

    // Reset current group report ids
    _.each(allGroups, g => g.reportIds = [])

    for(const {content, thingId} of notes) {
      for(const line of (content?.report?.symptom || '').split('\n')) {
        // Clean string, remove prefixes "- ", "a)", "1) " and whitespace
        const cleanSymptom = line.replace(/^\s*(-|\w\))?/, '').trim();

        if(cleanSymptom) {
          // Assign notes to the existing symptom or create a new one
          let normalizedSymptom = normalize(cleanSymptom);
          if(!symptomsByName[normalizedSymptom]) {
            symptomsByName[normalizedSymptom] = { id: uuidv4(), name: cleanSymptom, reportIds: [] };
            allGroups.push(symptomsByName[normalizedSymptom]);
          }
          if(!_.includes(symptomsByName[normalizedSymptom].reportIds, thingId)) {
            symptomsByName[normalizedSymptom].reportIds.push(thingId);
          }
        }
      }
    }

    const data =  {ids, groups: _.filter(allGroups, (g) => {
        const keep = g.reportIds?.length || g.manuallyCreated || g.children?.length;
        if(!keep) {
          console.log('Discarding symptom node: ', g)
        }
        return keep;
    })};

    // Some symptoms without reportIds might have been removed. Remove those from children
    _.each(data.groups, g => g.children = _.intersection(g.children, data.groups))

    // dataKey forces a refresh
    this.setState({ data, dataKey: new Date().valueOf(), unsaved: true });
    this.saveToLocalStorage(data, this.state.name, this.state.version, true);
  }

  saveToLocalStorage({ ids, groups }, name, version, unsaved) {
    const cleanData = { ids, groups: _.map(groups, ({ parent, children, ...obj }) => obj), __v: version, unsaved };
    localStorage.setItem('symptoms-' + name, JSON.stringify(cleanData));
  }

  renderPageBody() {
    const {name, data} = this.state;

    if(!data)
      return <div>Loading...</div>

    const onChange = (newGroups) => {
      const newData = {ids: this.state.data.ids, groups: newGroups};
      this.setState({ data: newData, unsaved: true })
      this.saveToLocalStorage(newData, this.state.name, this.state.version, true);
    };

    let handleCopy = () => copyToClipboard(JSON.stringify(JSON.parse(localStorage.getItem('symptoms-' + name)), true, 4));

    let handlePaste = () => {
      let text = prompt('Paste JSON here');
      if (text) {
        try {
          JSON.parse(text);
          localStorage.setItem('symptoms-' + name, text);
          this.runAsync(this.fetchData())
        } catch (err) {
        }
      }
    };

    let handleSaveToDB = () => {
      const data = JSON.parse(localStorage.getItem('symptoms-' + name));
      delete data.unsaved;
      delete data.__v;
      this.runAsync(async () => {
        const [dbWizard] = await this.wizardSvc.find({ query: { name } });
        try {
          const newVersion = this.state.version + 1;
          if (dbWizard) {
            await this.wizardSvc.update(dbWizard._id, { $set: { data, __v: newVersion } });
          } else {
            await this.wizardSvc.create({ name, data, __v: this.state.version });
          }
          this.setState({ unsaved: false, version: newVersion });
          this.saveToLocalStorage(data, this.state.name, newVersion, false);
        } catch (err) {
          alert(err.message);
        }
      });
    };

    let fetchFromDb = () => {
      this.runAsync(async () => {
        const [dbWizard] = await this.wizardSvc.find({ query: { name } });
        const { data, __v } = dbWizard;
        this.saveToLocalStorage(data, this.state.name, __v, false);
        this.setState({ data: processWizardData(data), version: __v, unsaved: false, outOfSync: false, dataKey: new Date().valueOf() });
      });
    };

    let handleLoadFromDB = () => {
      if (this.state.unsaved) {
        if (!confirm('You have unsaved changes that will be lost! Do you want to proceed?')) {
          return;
        }
      }
      fetchFromDb();
    };

    let handleDiscardLocalChanges = () => {
      if (confirm('Are you sure you want to discard the local changes?')) {
        fetchFromDb();
      }
    };

    const renameSymptomHandler = (node, newName) => {
      const { id, name, reportIds, parent } = node;

      // Check if there is another node with the same name
      const [duplicate, more] = _.filter(this.state.data.groups, g => normalize(g.name) === normalize(newName) && g.id !== id);

      if (duplicate && reportIds?.length && duplicate.reportIds?.length) {
        if (confirm(`Theres already a node called "${newName}" with symptoms, do you want to rename this symptoms and merge both nodes? Cancel will abort the rename`)) {
          duplicate.reportIds = _.union(duplicate.reportIds, reportIds);
          if (parent) {
            parent.children = _.without(parent.children, node);
          }
          onChange(_.filter(this.state.data.groups, g => g.id !== id));
        } else {
          return;
        }
      } else {
        node.name = newName
        onChange(this.state.data.groups);
      }
      this.setState({dataKey: new Date().valueOf()})

      // Rename symptoms in Notes db
      if(reportIds.length) {
        this.runAsync(async () => {
          // Edit one by one every symptom note and replace the renamed string for the new string
          for(const thingId of reportIds) {
            let [note] = await this.notesSvc.find({query: {thingId, namespace: 'report-symptom-annotations'}});
            const symptom = note?.content?.report?.symptom;
            if(symptom) {
              const nameRegex = `\\b${_.escapeRegExp(name)}(\\s*($|\n))`;

              let newSymptom = symptom.replace(new RegExp(nameRegex, 'ig'), `${newName}$1`);
              if(symptom !== newSymptom) {
                await this.notesSvc.update(note._id, {$set: {'content.report.symptom': newSymptom}});
              } else {
                console.log('Rename of symptom annotation did not have any effect: '+symptom);
              }
            }
          }
        }, 'Renaming symptoms...')
      }
    };

    let shouldSave;
    if (this.state.unsaved) {
      shouldSave =
        <span>
          <span className={'small text-secondary'}>Unsaved:</span>
          <IconButton icon="save" onClick={handleSaveToDB} level={'primary'} title="Save"/>
          <IconButton icon="delete_forever" onClick={handleDiscardLocalChanges} level={'danger'} title="Discard changes"/>
        </span>;
    }
    let shouldRefresh;
    if (this.state.outOfSync) {
      shouldRefresh =
        <span>
          <span className={'small text-secondary'}>Outdated:</span>
          <IconButton icon="refresh" onClick={handleLoadFromDB} level={'primary'} title="Refresh"/>
        </span>;
    }
    let copyBtn = <IconButton icon="content_copy" onClick={handleCopy} level={'secondary'} title="Copy as JSON"/>;
    let pasteBtn = <IconButton icon="content_paste" onClick={handlePaste} level={'success'} title="Paste"/>;

    let promptIds = () => {
      const idsText = prompt('Enter ids separated with any char', this.state.data?.ids.join(', '));
      if(idsText?.length) {
        const ids = idsText.split(/\D+/ig).map(n => parseInt(n));
        this.setState({data: {... this.state.data, ids}}, () => onChange(this.state.data.groups));
      }
    };

    return <div>
      <div className={'bg-white p-2 d-flex justify-content-between align-items-center'}>
        <span>
          <span className={'h5 align-middle mr-4'}>
            <span>Symptoms</span>

            <span className={'text-primary ml-2 mr-2'}>{name}</span>{' '}

            <span className={'text-secondary small ml-2'}>Report ids: {data.ids.slice(0, 5).map(n => {
              return <span key={n} className={'badge badge-info ml-1'}>{n}</span>;
            })} {data.ids.length > 10 ? `(... ${data.ids.length - 10} more)`: ''}</span>

            <IconButton icon={'edit'} onClick={promptIds} className='mr-3'/>

            <IconButton icon={'refresh'} onClick={() => this.runAsync(this.refreshSymptomsFromDB())}>
              Update symptoms from notes
            </IconButton>
          </span>
        </span>
        <span/>

        <span>
          {shouldRefresh}
          &nbsp;
          {shouldSave}
          &nbsp;
          {copyBtn}
          &nbsp;
          {pasteBtn}
        </span>
      </div>

      <SymptomsHierarchyEditor key={this.state.dataKey} initialData={data.groups} name={name} onChange={onChange}
                               onRenameSymptom={renameSymptomHandler}/>
    </div>;
  }
}
