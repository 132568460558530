import _ from "lodash";

// Cache the last regex for performance, as it will be called ultiple times
let lastRegexCache;
let lastRegexText;

/**
 * If text matches /^[\w ]+$/ (words with spaces), it creates a regex that matches any string with the letters of text
 * in the original order, with zero or more chars in between.
 *
 * For example:
 * "text" returns => /t.*e.*x.*t/i
 * @param {String} text
 * @returns {RegExp}
 */
export const getFlexibleRegex = (text) => {
  // From
  if (lastRegexText !== text) {
    if (text.match(/^[\w @\-=]+$/)) {
      let letters = text.replace(/ /g, '').split('');

      // Build a regex that matches the SMALLEST possible string like the original with letters in between
      // if text=SAD and the search string is "__S__SAD" the idea is to prefer match "SAD" over "S__SAD"
      let regex = '';
      _.each(letters.slice(0, -1), l => regex += `${l}[^${l}]*?`);
      regex += letters[letters.length - 1];

      lastRegexCache = new RegExp(regex, 'i')
      console.log(lastRegexCache)
    } else {
      lastRegexCache = new RegExp(`(${_.escapeRegExp(text)})`.replace(/ /g, '.+'), 'i')
    }
    lastRegexText = text;
  }
  return lastRegexCache;
}
