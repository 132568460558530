import _ from 'lodash'
import Immutable from 'immutable'
import React from 'react';

export class EditorImage extends React.Component {
  editImage() {
    const { block, contentState } = this.props;

    let entityAt = block.getEntityAt(0);
    if (entityAt) {
      const { src, height } = contentState.getEntity(entityAt).getData();

      let url = prompt("Edit image url", src)
      if (url) {
        this.props.blockProps.onChange(contentState.mergeEntityData(entityAt, { src: url }))
      }
    }
  }

  render() {
    const { block, contentState } = this.props;

    let entityAt = block.getEntityAt(0);
    if (entityAt) {
      const data = contentState.getEntity(entityAt).getData();
      // return <div><img src={data.src} height={data.height}/></div>
      if (data.src.trim().match(/{{.*}}/)) {
        return <div onClick={() => this.editImage()} className='Article__inline-img'
                    style={{ backgroundImage: `url('dummy.jpg')`, height: data.height }}>{data.src}</div>
      } else {
        return <div onClick={() => this.editImage()} className='Article__inline-img'
                    style={{ backgroundImage: `url('${data.src}')`, height: data.height }}></div>
      }
    } else {
      return <div className={'bg-info p-2 m-2'}>BROKEN IMAGE</div>
    }
  }
}
