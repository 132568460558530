import React, { useContext, useEffect, useState } from 'react';

import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import FuseboxDiagram from '../../common/FuseboxDiagram';
import { extendFuseboxWithTemplate } from './fusebox-utils.mjs';

function TemplatedFuseboxDiagram({fusebox, ... props}) {
  const { page } = useContext(LegoAdminPageContext);
  const [finalFusebox, setFinalFusebox] = useState(null);
  const [status, setStatus] = useState('Loading fusebox template...');

  useEffect(() => {
    page.service('services/legos').get(fusebox.templateId).then(template => {
      if(template) {
        setFinalFusebox(extendFuseboxWithTemplate(fusebox, template.data[0]));
      } else {
        setStatus(`Templated with id ${fusebox.templateId} not found`)
      }
    });
  }, [fusebox.templateId]);

  if (finalFusebox) {
    return <FuseboxDiagram fusebox={finalFusebox} {... props}/>
  } else {
    return <div className={'text-secondary text-center mt-3'}>{status}</div>;
  }
}

export default function(props) {
  if(props.fusebox?.templateId) {
    return <TemplatedFuseboxDiagram {... props}/>
  } else {
    return <FuseboxDiagram {... props}/>
  }
}
