import React, { Component } from 'react';
import _ from 'lodash';
import JsonTextEditor from '../common/editors/JsonTextEditor';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import JsonGUIEditor from "../common/editors/JsonGUIEditor";

export default class DataviewEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changedObject: _.cloneDeep(this.props.obj),
    };
  }

  editField(field, newValue) {
    let updated = this.state.changedObject;
    updated[field] = newValue;
    this.setState({ changedObject: updated });
    this.checkChanges();
  }

  checkChanges() {
    const areEqual = _.isEqual(this.state.changedObject, this.props.obj);
    this.setState({ changed: !areEqual });
  }

  render() {
    let l = this.state.changedObject;

    let { _id, type, name, selector, options, preProcessors, postProcessors, __unsaved, __new, __deleted } = l;

    let rowClasses = 'p-1 ';
    if (__new) {
      rowClasses += ' bg-light-success';
    }
    if (__unsaved) {
      rowClasses += ' bg-light-warning';
    }
    if (__deleted) {
      rowClasses += ' bg-light-danger';
    }

    if (this.state.changed) {
      rowClasses += 'bg-light-warn';
    }

    return <div>
      <h5 className={rowClasses}>Editar DataView <span className={'text-secondary small'}>{_id}</span></h5>

      <table className={'table table-sm'}>
        <tbody>
        <tr>
          <td style={{width: '140px'}} className={'text-right'}>Name:</td>
          <td>
            <SingleTextEditor onChange={(json) => this.editField('name', json)} value={name}/>
          </td>
        </tr>

        <tr>
          <td style={{width: '140px'}} className={'text-right'}>Type:</td>
          <td>
            <SingleTextEditor onChange={(json) => this.editField('type', json)} value={type}/>
          </td>
        </tr>

        <tr>
          <td style={{width: '140px'}} className={'text-right'}>View parameters:
            <div className={'small text-secondary'}>(that are used in key)</div>
            <div className={'small text-info'}>Use 'NOT_EQUALS' for '$ne'</div>
            <div className={'small text-info'}>Use '__' for '.' in fields</div>

          </td>
          <td className={''}>
            <JsonGUIEditor className={'zoom-90 bg-light border'} onChange={(json) => this.editField('selector', json)} value={selector}/>
          </td>
        </tr>

        <tr>
          <td style={{width: '140px'}} className={'text-right'}>Options:</td>
          <td className={'small'}>
            <JsonGUIEditor className={'zoom-90 bg-light border'} rows={12} onChange={(json) => this.editField('options', json)}
                            value={options}/>
          </td>
        </tr>


        <tr>
          <td style={{width: '140px'}} className={'text-right'}>Preprocessors:</td>
          <td className={''}>
            <JsonGUIEditor className={'zoom-90 bg-light border'} rows={12} onChange={(json) => this.editField('preProcessors', json)}
                           value={preProcessors}/>
          </td>
        </tr>


        <tr>
          <td style={{width: '140px'}} className={'text-right'}>Postprocessors:</td>
          <td className={''}>
            <JsonGUIEditor className={'zoom-90 bg-light border'} rows={12} onChange={(json) => this.editField('postProcessors', json)} value={postProcessors}/>
          </td>
        </tr>
        </tbody>
      </table>


      <div className={'mt-3 '+rowClasses}>
        <button onClick={() => this.props.onSave(l, true)} className={'btn btn-primary mr-2'}>Ok</button>
        <button onClick={() => this.props.onCancel()} className={'btn btn-default'}>Cancelar</button>
      </div>
    </div>;
  }
}
