import React, { Component } from 'react';
import _ from 'lodash';

import { IconButton } from '../../common/IconButton';
import ImageRectangleDetection from './fusebox-layout-editor/ImageRectangleDetection';
import MultiButtonSwitch from '../../common/editors/MultiButtonSwitch';
import { legoStateColors } from '../LegoStateBadge';

const CACHE_BUSTER = "?invalidatecache=1";

function getSelectedBoxes(selectionBox, boxes) {
  let {top, left, width, height} = selectionBox;
  return _.filter(boxes, b => {
    return !(b.left > (left+width) || (b.left+b.width) < left || b.top > (top+height) || (b.top+b.height) < top);
  })
}

function getBoundingBox(boxes) {
  const top = _.min(_.map(boxes, 'top'));
  const left = _.min(_.map(boxes, 'left'));
  const bottom = _.max(_.map(boxes, b => b.top + b.height));
  const right = _.max(_.map(boxes, b => b.left + b.width));

  return { top, left, width: right - left, height: bottom - top, };
}

const MODE_PROCESSING = 'processImgRectangles';
const MODE_EDITING = 'editing';
const MODE_DRAWING = 'drawingBox';
const MODE_COPYING = 'copyingBox';
const MODE_NAMING = 'namingBoxes';

let globalCollisionsCount = 0;
export default class FuseboxDiagramImgProcessor extends Component {
  constructor(props) {
    super(props);

    const assignedFuses = _.filter(this.props.fuses, f => f.layout?.width);

    // Make sure each fuseId has ONLY ONE rectangle
    const takenIds = {};
    const rectangles = [];
    _.each(assignedFuses, ({layout, id}) => {
      if(!takenIds[id]) {
        rectangles.push({ ...layout, fuseId: id });
        takenIds[id] = true;
      }
    })


    this.state = {
      // imageData: props.imageUrl,
      selection: [],
      rectangles: rectangles,
      aspectRatio: this.props.aspectRatio || 1,
      maxHeight: 300,
      maxWidth: 300,
      expanded: props.expanded,
      mode: MODE_EDITING
    };

    this.imgRef = React.createRef();
    this.boxRef = React.createRef();
    this.canvasRef = React.createRef();
    this.containerRef = React.createRef();

    this.updateDimensions = this.updateDimensions.bind(this);
    this.onPasteImage = this.onPasteImage.bind(this);
  }

  static getDerivedStateFromProps(props, current_state) {
    let idsUsedByTable = _.map(props.fuses, 'id')
    if (!_.isEqual(idsUsedByTable, current_state.idsUsedByTable || [])) {
      FuseboxDiagramImgProcessor.synchronizeAssignedFuses(props, current_state || {})
      return {idsUsedByTable}
    }

    if(props.assigningFuses?.length && current_state.mode !== MODE_NAMING) {
      return { mode: MODE_EDITING };
    }

    return null
  }

  static synchronizeAssignedFuses(props, {rectangles, aspectRatio, mode}) {
    let renameMap = props.templateRenameMap || {};
    let newRenameMap = {... renameMap};

    _.each(props.fuses, (f) => {
      let box;
      // In rename mode, if a rectangle is renamed, try to find the previous id in the table and leave it as remap
      if(props.renameMode) {
        box = _.find(rectangles, r => r.fuseId && _.isEqual(f.layout, _.pick(r, 'top', 'left', 'width', 'height', 'rotation')));
        if(box && box.fuseId !== f.id) {
          // Save in remap the previous name, unless it was already remapped. In that case, keep the old remap
          newRenameMap[box.fuseId] = renameMap[f.id] || f.id;
          if(newRenameMap[box.fuseId] === box.fuseId) {
            delete newRenameMap[box.fuseId];
          }
          f.id = box.fuseId;
        }
      } else {
        box = _.find(rectangles, r => r.fuseId === f.id);
      }

      if(box) {
        let {top, left, width, height} = box;
        f.layout = {top, left, width, height};
        if(box.rotation)
          f.layout.rotation = box.rotation;
      } else {
        f.layout = {}
      }
    })

    if(props.renameMode) {
    // Delete unused renames from the past that are no longer fuse ids, otherwise the same original id can point to two different ids
      let ids = new Set(props.fuses.map(f => f.id));
      newRenameMap = _.pickBy(newRenameMap, (v, k) => ids.has(k));
    }

    props.onChange(props.fuses, aspectRatio, _.isEmpty(newRenameMap) ? null : newRenameMap)
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  generateFuseNames(idText, thingsToName) {
    // If only one fuse, there is no naming rule to infer
    if(thingsToName === 1) {
      return [idText];
    }

    let prefix = idText.match(/^[a-zA-Z ]+/) || '';
    let sufix = idText.match(/\d+([a-zA-Z ]+)$/) || '';
    if (prefix) {
      prefix = prefix[0];
      idText = idText.slice(prefix.length);
    }
    if (sufix) {
      sufix = sufix[1];
    }

    const leadingZero = (idText[0] === '0');

    let id = parseInt(idText);
    let isChar = false;
    if (prefix && prefix.match(/^\w$/) && !idText) {
      isChar = true;
      id = prefix.charCodeAt(0);
    }

    let res = [];
    for(let i = 0; i < thingsToName;i++) {
      let fuseId = undefined;
      if (!isNaN(id) && !isChar) {
        fuseId = `${prefix}${leadingZero && (id + i < 10) ? '0' : ''}${id + i}${sufix}`;
      } else if (isChar) {
        fuseId = String.fromCharCode(id + i);
      }
      res.push(fuseId);
    }

    return res;
  }

  onPasteImage({imageData}) {
    if(this.state.imageData !== imageData) {
      return this.setState({ imageData: imageData, processing: true });
    }
  }

  getBoxFromCoordinates(offsetA, offsetB) {
    let {top, left} = offsetA;
    let height = (Math.abs(top - offsetB.top));
    let width = (Math.abs(left - offsetB.left));

    return {height, width, left: Math.min(left, offsetB.left), top: Math.min(top, offsetB.top)}
  }

  getBoxFromCopyAndMouse(box, mousePos) {
    let {top, left, height, width} = box;

    if(Math.abs(mousePos.left - left) > Math.abs(mousePos.top-top)) {
      return { height, width, left: mousePos.left, top }
    } else {
      return { height, width, left, top: mousePos.top }
    }
  }

  buildBoxes() {
    let {ratio, rectangles, h, w, mode, selection, waitingCopyingBoxClick, selectionStart, mouseEditMode, mouseEditParams} = this.state;

    let assigningFuses = this.props.assigningFuses && this.props.assigningFuses.length;

    if(assigningFuses) {
      mode = MODE_NAMING;
    }

    const fuses = [];

    const temporal = [];

    const { drawingBoxStart, mousePosition } = this.state;
    let drawingBox = mode === MODE_DRAWING;
    let editing = mode === MODE_EDITING;
    let copyingBox = mode === MODE_COPYING;

    if(drawingBox) {
      if(drawingBoxStart) {
        temporal.push(this.getBoxFromCoordinates(drawingBoxStart, mousePosition))
      }
    } else if(copyingBox) {
      temporal.push(this.getBoxFromCopyAndMouse(selection[0], mousePosition));
    }

    _.each([... rectangles, ... temporal], (box, i) => {
      let { top, left, width, height, selected, fuseId, rotation } = box;

      let assigned = !!fuseId && _.find(this.props.fuses, ({id}) => id === fuseId)

      let css = {
        height: `${height}%`,
        width: `${width}%`,
        top: `${top}%`,
        left: `${left}%`,
      };

      if(rotation) {
        css.transform = `rotate(${rotation}deg)`
      }

      let classes = [];
      if(assigned) {
        classes.push('assigned');
      }
      if(selected) {
        classes.push('selected')
      }

      if(_.includes(selection, box) && editing) {
        classes.push(MODE_EDITING)
      }

      const onFuseMouseDown = (e) => {
        if (assigningFuses) {
          box.fuseId = this.props.assigningFuses[0].id;
          this.setState({rectangles, selection: [], hasChanges: true})
          FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
        } else if(mode === MODE_NAMING) {
          let selection = _.uniq([...this.state.selection, box]);
          box.selected = _.findIndex(selection, box) + 1;
          this.setState({ rectangles, globalMouseDown: true, selection });
        } else if (editing){
          // box.selected = true;
          if(e.ctrlKey) {
            if(_.includes(selection, box)) {
              this.setState({ rectangles, selection: _.without(selection, box) });
            } else {
              this.setState({ rectangles, selection: _.union(selection, [box]) });
            }
          } else {
            if(_.includes(selection, box)) {
              this.setState({ mouseEditMode: 'move', mouseEditParams: {} });
            } else {
              this.setState({ rectangles, selection: [box] });
            }
          }
          e.stopPropagation();
        } else if(drawingBox && !drawingBoxStart) {
          this.setState({ rectangles, selection: [box], mode: MODE_COPYING });
        }
      };

      const onFuseMouseUp = (e) => {
        if(editing) {
          // e.stopPropagation();
        }
      };

      const onFuseMouseEnter = () => {
        if (this.state.globalMouseDown) {
          let selection = _.uniq([...  this.state.selection, box]);
          box.selected  = _.findIndex(selection, box)+1;
          this.setState({rectangles, selection})
        }
      };

      fuses.push(<div key={i} className={`fusecandidate ${classes.join(' ')}`} style={css}
                      onMouseUp={onFuseMouseUp} onMouseDown={onFuseMouseDown} onMouseEnter={onFuseMouseEnter}>
        {selected ? <span className={'selection-id'}>{selected }</span>: fuseId || null}
      </div>);
    });

    const onFuseboxKeyDown = (e) => {
      if(e.key === 'Delete' || e.key === 'Backspace') {
        rectangles = _.difference(rectangles, selection);
        this.setState({rectangles, selection: []}, () => {
          FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
        })
      } else if(e.key === 'a' && e.ctrlKey) {
        this.setState({selection: [... rectangles]})
      } else if(selection.length && e.key === 'd') {
        let clones = _.cloneDeep(selection);
        for(const b of clones) {
          delete b.fuseId;
          b.top += 5;
          b.left += 5;
        }
        rectangles = _.union(rectangles, clones);
        FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
        this.setState({rectangles, selection: clones});
      } else if(_.includes(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'],e.key)) {
        for(const b of selection) {
          let { top, left, height, width } = b;
          let unit = 0.1;

          if(e.altKey) {
            unit = unit*10;
          }

          if (e.shiftKey) {
            switch (e.key) {
              case 'ArrowUp':
                b.height -= unit;
                break;
              case 'ArrowDown':
                b.height += unit;
                break;
              case 'ArrowLeft':
                b.width -= unit;
                break;
              case 'ArrowRight':
                b.width += unit;
                break;
            }
          } else if (e.ctrlKey) {
            switch (e.key) {
              case 'ArrowUp':
              case 'ArrowRight':
                b.rotation = (b.rotation || 0) + (e.altKey ? 90 : 5);
                break;
              case 'ArrowDown':
              case 'ArrowLeft':
                b.rotation = (b.rotation || 0) - (e.altKey ? 90 : 5);
                break;
            }
          } else {
            switch (e.key) {
              case 'ArrowUp':
                b.top -= unit/this.state.aspectRatio;
                break;
              case 'ArrowDown':
                b.top += unit/this.state.aspectRatio;
                break;
              case 'ArrowLeft':
                b.left -= unit;
                break;
              case 'ArrowRight':
                b.left += unit;
                break;
            }
          }
        }
        FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
        this.setState({rectangles});
      }

      if(e.key === 'Escape' && (copyingBox || drawingBox)) {
        this.setState({mode: MODE_DRAWING, selection: null, drawingBoxStart: null, waitingCopyingBoxClick: null})
      }
      e.stopPropagation();
      e.preventDefault();
    }

    const onFuseboxMouseDown = (e) => {
      if(editing && !e.ctrlKey) {
        this.setState({selectionStart: this.getMouseInDiagramCoordinates(e)})
      }
    }

    let onFuseboxMouseUp = (e) => {
      const {drawingBoxStart} = this.state;

      if(drawingBox) {
        let offset = this.getMouseInDiagramCoordinates(e);

        if(drawingBoxStart) {
          rectangles.push( this.getBoxFromCoordinates(drawingBoxStart, offset))
          this.setState({rectangles, drawingBoxStart: null})
        } else {
          this.setState({drawingBoxStart: offset})
        }

        return;
      } else if(copyingBox) {
        if(waitingCopyingBoxClick) {
          rectangles.push(this.getBoxFromCopyAndMouse(selection[0], mousePosition))
          this.setState({rectangles, waitingCopyingBoxClick: null, mode: MODE_DRAWING, selection: []})
        } else {
          this.setState({waitingCopyingBoxClick: true})
        }
        return;
      }

      // Click outside any fuse
      if(editing) {
        if(selectionStart && !e.ctrlKey) {
          this.setState({
            selectionStart: null,
            selection: getSelectedBoxes(this.getBoxFromCoordinates(selectionStart, mousePosition), rectangles)
          })
        } else if(mouseEditMode) {
          this.setState({mouseEditMode: null})
        }
        return;
      }

      if (!selection.length) {
        this.setState({globalMouseDown: false})
        return;
      }

      if(mode === MODE_NAMING) {
        let namingExample = prompt('Enter id numbering start');

        let automaticFuseNames = [];
        if (namingExample) {
          automaticFuseNames = this.generateFuseNames(namingExample, selection.length);
        }

        let notRenamedById = _.keyBy(_.difference(rectangles, selection), 'fuseId');

        // Empty naming example means "OK" to empty
        _.each(selection, (box, i) => {
          if (namingExample !== null) {
            let fuseId = automaticFuseNames[i];

            // When in renameMode, it's important not losing the history of the original id of a box in the template.
            // The only way to ensure that is that there are no two rectangles with same name, so force a unique name
            // in case of collisions.
            if (this.props.renameMode && notRenamedById[fuseId]) {
              notRenamedById[fuseId].fuseId = fuseId + '_COLLISION'+(globalCollisionsCount++)
            }

            box.fuseId = fuseId;

          }
          box.selected = false;
        });

        this.setState({ rectangles, selection: [], globalMouseDown: false, hasChanges: true })
        FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
      }
    };

    let selectionBox = null;
    if(editing && selectionStart) {
      let { top, left, width, height} = this.getBoxFromCoordinates(selectionStart, mousePosition);
      let css = {
        position: 'absolute',
        top: `${top}%`,
        left: `${left}%`,
        width: `${width}%`,
        height: `${height}%`,
      };

      selectionBox = <div className={'bg-light-primary'} style={css}>
      </div>
    }

    let onFuseboxMouseMove = (e) => {
      if(drawingBox || assigningFuses || copyingBox || editing) {
        let newMousePosition = this.getMouseInDiagramCoordinates(e);

        if(editing) {
          if(selectionStart) {
            this.setState({ selection: getSelectedBoxes(this.getBoxFromCoordinates(selectionStart, mousePosition), rectangles) })
          } else if(mouseEditMode) {
            let prevPosition = mousePosition;
            let dx = newMousePosition.left - prevPosition.left;
            let dy = newMousePosition.top - prevPosition.top;
            this.selectedRectanglesOperation(this.state.mouseEditMode, { dx, dy, ... mouseEditParams })
          }
        }

        this.setState({mousePosition: newMousePosition})
      }
    }

    let extraLabel = null;
    if(assigningFuses && mousePosition) {
      let { top, left} = this.getBoxFromCoordinates(mousePosition, mousePosition);
      let css = {
        top: `${top+3}%`,
        left: `${left+3}%`,
      };

      extraLabel = <div className={'assigning-box-id'} style={css}>
        <span className={'badge badge-primary'}>{this.props.assigningFuses[0].id}</span>
      </div>
    }

    let focused = editing || copyingBox;

    return <span tabIndex={focused ? 0 : null}
                 autoFocus={focused}
                 onKeyDown={onFuseboxKeyDown}
                 onMouseUp={onFuseboxMouseUp}
                 onMouseDown={onFuseboxMouseDown}
                 onMouseMove={onFuseboxMouseMove}
                 className={`fusebox-layout mode-${mode}`}>
      { fuses }{ extraLabel } { selectionBox } { this.getSelectionHelpers() }
    </span>
  }
  selectedRectanglesOperation(operation, params = {}) {
    let selection = this.state.selection;

    switch (operation) {
      case 'alignBottom':
        let bottom = _.max(selection.map(b => b.top + b.height));
        for (const b of selection) {
          b.top = bottom - b.height;
        }
        break;
      case 'alignTop':
        let top = _.min(selection.map(b => b.top));
        for (const b of selection) {
          b.top = top;
        }
        break;
      case 'alignLeft':
        let left = _.min(selection.map(b => b.left));
        for (const b of selection) {
          b.left = left;
        }
        break;
      case 'alignRight':
        let right = _.max(selection.map(b => b.left + b.width));
        for (const b of selection) {
          b.left = right - b.width;
        }
        break;
      case 'distributeHorizontal':
        let rightmostCenter = _.max(selection.map(b => b.left + b.width/2));
        let leftmostCenter = _.min(selection.map(b => b.left + b.width/2));
        _.each(_.sortBy(selection, r => r.left+r.width/2), (b,i) => b.left = ((rightmostCenter - leftmostCenter)/(selection.length - 1)*i)-b.width/2+leftmostCenter);
        break;
      case 'distributeVertical':
        let topmostCenter = _.min(selection.map(b => b.top + b.height/2));
        let bottommostCenter = _.max(selection.map(b => b.top + b.height/2));
        _.each(_.sortBy(selection, r => r.top+r.height/2), (b,i) => b.top = ((bottommostCenter - topmostCenter)/(selection.length - 1)*i)-b.height/2+topmostCenter);
        break;
      case 'averageSizes':
        let averageHeight = _.mean(selection.map(b => b.height));
        let averageWidth = _.mean(selection.map(b => b.width));
        for (const b of selection) {
          b.width = averageWidth;
          b.height = averageHeight;
        }
        break;
      case 'move': {
        let { dx, dy } = params;
        for (const b of selection) {
          b.top += dy || 0;
          b.left += dx || 0;
        }
      }
        break;
      case 'scaleFromPoint': {
        let { dx, dy, ox, oy } = params;
        let { height, width, top, left } = getBoundingBox(selection);
        let signX = (ox < (left+width/2)) ? 1 : -1;
        let signY = (oy < (top+height/2)) ? 1 : -1;

        let dw = (width + signX*dx) / width;
        let dh = (height + signY*dy) / height;

        for (const b of selection) {
          b.top += signY*(b.top - oy) * (dy / height) || 0;
          b.left += signX*(b.left - ox) * (dx / width) || 0;
          b.height *= dh || 0;
          b.width *= dw || 0;
        }
      }
        break;
      default:
        console.error(`Invalid selection operation: ${operation}`);
    }
    FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
    this.setState({ rectangles: this.state.rectangles });
  }

  getMouseInDiagramCoordinates(mouseEvent) {
    let {clientX, clientY} = mouseEvent;
    let container = this.boxRef.current;

    if(container) {
      let height = $(container).outerHeight();
      let width = $(container).outerWidth();
      let {top, left} = container.getBoundingClientRect();
      return {left: (clientX - left)/(width)*100, top: (clientY - top)/(height)*100}
    } else {
      console.error('No deberia pasar por aca')
      return this.state.mousePosition;
    }
  }

  toggleMode(mode) {
    if(this.state.mode === mode) {
      this.setState({mode: MODE_EDITING})
    } else {
      this.setState({mode})
    }
  }

  getBoxEditionOptions() {
    if(this.state.selection.length > 1) {
      return <div className={'float-bottom-left text-center bg-white rounded border-secondary border p-05'}>
        <IconButton level={'primary'} icon={'align_vertical_bottom'}
                    onClick={() => this.selectedRectanglesOperation('alignBottom')}
                    title={'Align boxes bottom'}/>

        <IconButton level={'primary'} icon={'align_vertical_top'}
                    onClick={() => this.selectedRectanglesOperation('alignTop')}
                    title={'Align boxes top'}/>

        <IconButton level={'primary'} icon={'align_horizontal_left'}
                    onClick={() => this.selectedRectanglesOperation('alignLeft')}
                    title={'Align boxes left'}/>

        <IconButton level={'primary'} icon={'align_horizontal_right'}
                    onClick={() => this.selectedRectanglesOperation('alignRight')}
                    title={'Align boxes right'}/>

        <IconButton level={'primary'}  className={'ml-3'} icon={'horizontal_distribute'}
                    onClick={() => this.selectedRectanglesOperation('distributeHorizontal')}
                    title={'Distribute horizontal space between boxes evenly'}/>

        <IconButton level={'primary'} icon={'vertical_distribute'}
                    onClick={() => this.selectedRectanglesOperation('distributeVertical')}
                    title={'Distribute vertical space between boxes evenly'}/>

        <IconButton level={'primary'}  className={'ml-3'} icon={'photo_size_select_small'}
                    onClick={() => this.selectedRectanglesOperation('averageSizes')}
                    title={'Make all boxes the same size, doing average'}/>
      </div>;
    }
  }

  getSelectionHelpers() {
    const { selection, mode, selectionStart } = this.state;

    if(mode === MODE_EDITING && selection.length && !selectionStart) {
      let { top, left, height, width } = getBoundingBox(selection);
      let style = { height: `${height}%`, width: `${width}%`, top: `${top}%`, left: `${left}%` }

      let pointStyle = {top: '50%', left: '50%'};

      const setMode = (mode, params, e) => this.setState({ mouseEditMode: mode, mouseEditParams: params }) + e.stopPropagation();

      const scaleControlPoints = [
        [{ ox: left, oy: top }, { top: `${top + height}%`, left: `${left + width}%` }],
        [{ ox: left + width, oy: top + height }, { top: `${top}%`, left: `${left}%` }],
        [{ ox: left + width, oy: top }, { top: `${top + height}%`, left: `${left}%` }],
        [{ ox: left, oy: top + height }, { top: `${top}%`, left: `${left + width}%` }],
      ];

      return <>
        <div className={'edit-fuses-bbox'} style={style}></div>

        <span className={'control-point'} onMouseDown={(e) => setMode('move', {}, e)}
              style={{ top: `${top + height / 2}%`, left: `${left + width / 2}%`, borderRadius: '50%' }}/>

          {scaleControlPoints.map(([params, style], i) => <span key={i} className={'control-point'}
                                                                onMouseDown={(e) => setMode('scaleFromPoint', params, e)}
                                                                style={style}/>)}
      </>;
    } else {
      return null;
    }
  }

  getImageParamsUI() {
    const {  mode, rectangles } = this.state;

    let btns;

    const processDiagram = () => {
      if(rectangles?.length && !confirm('You will lose all the assigned fuses if you continue. Are you sure?')) {
        return;
      }

      this.setState({mode: MODE_PROCESSING});
    }

    if(mode === MODE_PROCESSING) {
      btns = <>
        <IconButton level={'success'} icon={'filter_b_and_w'}
                    onClick={() => this.setState({ mode: MODE_EDITING })}
                    title={'Show options to fix diagram detection problems...'}/>
      </>
    } else {
      btns = <>
          <IconButton level={'secondary'} icon={'filter_b_and_w'}
                      onClick={processDiagram}
                      title={'Show options to fix diagram detection problems...'}>
          </IconButton>

          <IconButton level={mode === MODE_EDITING ? 'success' : 'primary'} icon={'format_shapes'}
                      onClick={() => this.toggleMode(MODE_EDITING)}
                      title={'Show options to fix diagram detection problems...'}/>

          <IconButton level={mode === MODE_DRAWING ? 'success' : 'primary'} icon={'add_box'}
                      onClick={() => this.toggleMode(MODE_DRAWING)}
                      title={'Show options to fix diagram detection problems...'}/>

          <IconButton level={mode === MODE_NAMING ? 'success' : 'primary'} icon={'format_list_numbered_rtl'}
                    onClick={() => this.toggleMode(MODE_NAMING)}
                    title={'Assign sequential Id to fuses...'}/>
      </>
    }

    return <div className={'diagram-processor-buttonbar bg-dark'} >{btns}</div>;
  }

  updateDimensions() {
    let container = this.containerRef.current;

    if(container) {
      let maxHeight = $(container).outerHeight();
      let maxWidth = $(container).outerWidth();
      this.setState({maxHeight, maxWidth});
    }
  }

  updateImageAspectRatio(img) {
    // For the first time the diagram is loaded
    // const assignedFuses = _.filter(this.props.fuses, f => f.layout?.width);
    // if(assignedFuses.length > 0 && this.state.hasChanges !== false) {
    //   this.setState({hasChanges: true})
    //   return;
    // }

    let cw = img.naturalWidth;
    let ch = img.naturalHeight;

    let aspectRatio = ch / cw;
    if(aspectRatio !== this.state.aspectRatio) {
      console.log('UPDATE ASPECT RATIO', aspectRatio, this.state.aspectRatio)
      this.setState({ aspectRatio }, () => {
        FuseboxDiagramImgProcessor.synchronizeAssignedFusesInstant(this.props, this.state);
        setTimeout(() => this.updateDimensions(), 0)
      });
    }
  };

  render() {
    let img = null

    let { rectangles, globalMouseDown, showOptions, mode} = this.state;
    let imageUrl = this.props.imageUrl;

    if(imageUrl || rectangles.length) {
      let boxes = rectangles ? this.buildBoxes() : null

      if(this.state.expanded !== this.props.expanded) {
        setTimeout( () => {
          this.setState({expanded: this.props.expanded})
          this.updateDimensions();
        }, 1)
      }

      let processingImage = mode === MODE_PROCESSING;

      let vertical = this.state.boxAspectRatio > 1.1;

      let maxHeight = this.state.maxHeight - (vertical ? 32 : 0);
      let maxWidth = this.state.maxWidth - (vertical ? 0 : 32) - (processingImage ? 0 : 0); // Leave space for controls

      let h,w=null;
      if (maxHeight / maxWidth > this.state.aspectRatio) {
        h = this.state.aspectRatio * maxWidth
        w = maxWidth
      } else {
        w = maxHeight / this.state.aspectRatio
        h = maxHeight
      }

      let boxesStyle = { height: `${h}px`, width: `${w}px`, position: 'absolute', top: "0px" }
      boxes= <div ref={this.boxRef} style={boxesStyle}>{boxes}</div>

      let boxDetectionPreview = null

      if(imageUrl) {
        const style = {
          height: `${h}px`,
          width: `${w}px`,
          userSelect: 'none'
        }

        if(processingImage) {
          boxDetectionPreview = <div style={style}>
            <ImageRectangleDetection
            aspectRatio={this.state.aspectRatio}
            imageUrl={imageUrl}
            height={h}
            width={w}
            onRectanglesChange={(newRects) => {
              this.setState({ rectangles: newRects, selection: [], globalMouseDown: false });
              FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
            }}/>
          </div>;
        } else {
          // Bust any potential cached version of the img with a bogus query string
          // parameter, otherwise there are CORS errors when trying to load the image because it could be cached
          // with a previous request that did not include crossOrigin, causing this request to fail
          // More about the problem: https://stackoverflow.com/questions/12648809/cors-policy-on-cached-image
          img = <img ref={this.imgRef} crossOrigin={"anonymous"} src={imageUrl + CACHE_BUSTER} key={'fusebox'}
                     alt={`Pasted:`} style={style}
                     onLoad={(e) => this.updateImageAspectRatio(e.target)}
          />
        }
      } else {
        img = <div>Drag a fusebox image</div>
      }


      let extraClasses = '';
      if(globalMouseDown) { extraClasses += ' cursor-pointer'}

      return <div ref={this.containerRef} style={{ height: '100%', width: '100%' }}>
        {imageUrl ? this.getImageParamsUI() : null}

        <div className={extraClasses} style={{ height: '100%', position: 'relative', display: 'inline-block' }}>
          {boxDetectionPreview}

          {img}

          {boxes}

          {mode !== MODE_PROCESSING ? this.getBoxEditionOptions() : null}
        </div>
      </div>;
    } else {
      return <div className={'p-4 text-center'} style={{height: '100%', width: '100%'}}>
        Copy an image and paste it into the page (ctrl+v)
      </div>
    }
  }
}
FuseboxDiagramImgProcessor.synchronizeAssignedFusesInstant = FuseboxDiagramImgProcessor.synchronizeAssignedFuses;
FuseboxDiagramImgProcessor.synchronizeAssignedFuses = _.debounce(FuseboxDiagramImgProcessor.synchronizeAssignedFuses, 200)
