import React from 'react';

export const prettyDuration = (ms) => {
  if (!ms) {
    return '-';
  }

  if (ms > 60000) {
    const min = Math.floor(ms / (1000 * 60));

    if (min < 10) {
      return <span>{min}<span className={'translucent small mr-1'}>m</span><span
        className={'small'}>{prettyDuration(ms % (60000))}</span></span>;
    } else if (min < 60) {
      return <span>{min}<span className={'translucent small'}>m</span></span>;
    } else {
      return <span>{Math.floor(min / 60)}<span className={'translucent small'}>h</span> {min % 60}<span
        className={'translucent small'}>m</span></span>;
    }
  } else {
    return <span>{Math.floor(ms / 1000).toString()}<span className={'translucent small'}>s</span></span>;
  }
};
