import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import CRUDPage from './CRUDPage';
import LegoContextSummary from "../components/lego/LegoContextSummary";
import submenuManuals from "./menus/submenu-crawling";
import CountryFlag from "../components/common/CountryFlag";
import MongoDocEditor from "../components/lego/MongoDocEditor";
import { UserTrimsFiltersBar } from "../components/crawling/UserTrimsFiltersBar";
import { IconButton } from "../components/common/IconButton";

function getIntensityColorStyle(count, total = 20, deg = 100) {
  return {
    background: `${count ? `hsl(${deg}deg 100% ${100 - Math.min(1, count / total) * 50}%)` : 'gray'}`
  };
}

export default class UserTrimsViewer extends CRUDPage {
  constructor(props) {
    super(props, '/services/data/usertrims');
    this.submenu = submenuManuals;

    this.state.summaryOpen = false;
    this.state.tableSortBy = 'count';
    this.state.tableSortAsc = false;
    this.state.statsOpen = true;

    this.canDelete = false;
    this.canCreate = false;
    this.canEdit = false;
    this.stats = this.service('services/data/stats/usertrims');
  }

  getFacetFiltersComponent() {
    return <UserTrimsFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters} />
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Source', className: '' },
      { content: 'Model', className: '' },
      { content: 'Year', className: '' },
      { content: 'Trim', className: '' },
    ]
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    if (this.state.facetFilters.make || this.state.facetFilters.modelId) {
      await super.fetchObjects({ $sort: { year: -1 }, $limit: 5000 });
      if (this.state.facetFilters.country) {
        let modelStats = await this.stats.find({ query: { modelId: this.state.facetFilters.modelId, country: this.state.facetFilters.country[0] } });
        this.setState({ modelStats });
      }
    } else {
      await super.fetchObjects({ $sort: {}, $limit: 100 });
      if (this.state.facetFilters.country) {
        if ((this.state.coverageStats || '').country !== this.state.facetFilters.country) {
          let coverageStats = await this.stats.get({ query: { country: this.state.facetFilters.country[0] } });
          coverageStats.country = this.state.facetFilters.country[0];
          this.setState({ coverageStats, modelStats: null });
        }
      }
    }
  }


  getObjectColumns(manual, definition) {
    let { _id, modelId, country, year, otherTrimName, selectedTrimName, otherYear } = manual || {};

    return [
      <td key={'source'} className={'context-column text-left'}>
        <div className={'text-secondary small'}>{_id}</div>
        <div>
          <CountryFlag countryCode={country} />
        </div>
        {/* <div className={'text-secondary small'}>{JSON.stringify(manual)}</div> */}
      </td>,
      <td key={'modelId'} className={'context-column text-left'}>
        <div className={'text-secondary small'}><LegoContextSummary context={[{ modelId }]} /></div>
      </td>,
      <td key={'year'}>
        <div>
          <span>{year}</span>
        </div>
        <div>
          <span className='badge badge-darkred mr-1 align-middle'>{otherYear}</span>
        </div>
      </td>,
      <td key={'trim'}>
        <div className={'text-secondary small'}>
          <span className='badge badge-gris-blue mr-1 align-middle'>{selectedTrimName}</span>
        </div>
        <div className={'text-secondary small'}>
          <span className='badge badge-darkred mr-1 align-middle'>{otherTrimName}</span>
        </div>
      </td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <MongoDocEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk} />
  }

  onClick(fields) {
    let newFilter = _.cloneDeep(this.state.facetFilters || {});
    let { modelId, country, year, otherTrimName, selectedTrimName } = fields;
    if (modelId) {
      delete newFilter.modelId;
      newFilter.modelId = modelId;
    }
    if (country) {
      delete newFilter.country;
      newFilter.country = country;
    }
    if (year) {
      delete newFilter.year;
      newFilter.year = year;
    }
    if (otherTrimName) {
      delete newFilter.otherTrimName;
      delete newFilter.selectedTrimName;
      newFilter.otherTrimName = { $exists: true, $ne: '' };
    }
    if (selectedTrimName) {
      delete newFilter.selectedTrimName;
      delete newFilter.otherTrimName;
      newFilter.selectedTrimName = { $exists: true, $ne: '' };
    }
    this.onFiltersChange(newFilter);
  }

  divByYear(yearsList, colorDeg) {
    const maxCount = _.max(_.map(yearsList, 'count')) || 1;
    return _.map(yearsList, ({ _id, count }) => {
      return {
        year: _id.year,
        div: <div key={(_id.year || '-')} style={getIntensityColorStyle(count, maxCount, colorDeg)} className={'m-05 p-1 rounded text-center small inline-block shadow-sm'}>
          <div className={'small text-secondary'}>{(_id.year || '-')}</div>
          <div className={'text-primary'}>{count}</div>
        </div>
      }
    }
    );
  }

  showCountByYear() {
    const { countByOtherYear, countByYear } = this.state.modelStats;
    let years = _.sortBy(_.union(_.map([...countByYear, ...countByOtherYear], '_id.year')))
    let divByYear = this.divByYear(countByYear, 100);
    let divByOtherYear = this.divByYear(countByOtherYear, 0);

    return <div className={'d-flex align-items-center'}>
      {_.map(years, y => _.find([...divByYear, ...divByOtherYear], { year: y }).div)}
    </div>
  }

  otherTrimRatio() {
    let result = []
    let years = _.union(_.map([...this.state.modelStats.countSelectedTrimByYear, ...this.state.modelStats.countOtherTrimByYear], item => (item._id.year || item._id.otherYear)))
    for (const year of years) {
      let values = {
        selectedTrim: (_.find(this.state.modelStats.countSelectedTrimByYear, { '_id': { 'year': year } }) || []).count,
        otherTrim: (
          (_.find(this.state.modelStats.countOtherTrimByYear, { '_id': { 'year': year } }) || []).count
          ||
          (_.find(this.state.modelStats.countOtherTrimByYear, { '_id': { 'otherYear': year } }) || []).count
        )
      }
      if (values.selectedTrim && values.otherTrim) {
        values.ratio = Math.round(values.otherTrim * 100 / (values.selectedTrim + values.otherTrim));
      }
      result.push({ year, values })
    }

    const maxCount = _.max(_.map(result, item => item.values.ratio)) || 1;
    return <div className={'d-flex align-items-center'}>
      {_.map(_.orderBy(result, 'year'), item =>
        <div key={item.year} style={getIntensityColorStyle((item.values.ratio || 1), maxCount)} className={'m-05 p-1 rounded text-center small inline-block shadow-sm'}>
          <div className={'small text-secondary'}>{item.year}</div>
          <div className={'text-primary'}>{(item.values.ratio || '-')}%</div>
          <div className={'small text-primary'} onClick={() => this.onClick({ year: item.year, selectedTrimName: true })}>{(item.values.selectedTrim || '-')}</div>
          <div className={'small text-primary'} onClick={() => this.onClick({ year: item.year, otherTrimName: true })}>{(item.values.otherTrim || '-')}</div>
        </div>
      )}
    </div>;
  }

  getStatsTable() {
    let data = this.state.coverageStats.fullCount;
    const open = this.state.statsOpen;

    data = _.orderBy(data, this.state.tableSortBy, this.state.tableSortAsc ? 'asc': 'desc');

    let header = <IconButton icon={open ? 'expand_less' : 'expand_more'} level={'primary'}
      onClick={() => this.setState({ statsOpen: !open })}>
      Tabla de modelos - {this.state.coverageStats.country}
    </IconButton>

    let table = null
    if (open) {
      table = <table className={"table table-small"}>
        <thead>
          <tr>
            <th onClick={() => this.setState({ tableSortBy: 'count', tableSortAsc: !this.state.tableSortAsc })}>Total Count</th>
            <th onClick={() => this.setState({ tableSortBy: 'otherTrimCount', tableSortAsc: !this.state.tableSortAsc })}>OtherTrim Count</th>
            <th onClick={() => this.setState({ tableSortBy: 'otherTrimRatio', tableSortAsc: !this.state.tableSortAsc })}>OtherTrim %</th>
            <th onClick={() => this.setState({ tableSortBy: '_id.modelId', tableSortAsc: !this.state.tableSortAsc })}>Model</th>
          </tr>
        </thead>
        <tbody>
          {_.map(data, row =>
            <tr key={row._id.modelId}>
              <td>{row.count}</td>
              <td>{row.otherTrimCount}</td>
              <td>{(row.otherTrimRatio || 0).toFixed(1)}</td>
              <td onClick={() => this.onClick({ modelId: row._id.modelId, country: this.state.facetFilters.country })}>{row._id.modelId}</td>
            </tr>)}
        </tbody>
      </table>
    }
    return <div><div>{header}</div><div>{table}</div></div>
  }

  trimsSpecs() {
    //for unstructured Trims, currently not implemented.
    // console.log(this.state.modelStats.trimSpecsGroups);
    let years = _.sortBy(_.uniq(_.flattenDeep(_.map(this.state.modelStats.trimSpecsGroups, specsGroup => _.map(specsGroup.byYear, 'year')))));
    let maxCount = _.max(_.flattenDeep(_.map(this.state.modelStats.trimSpecsGroups, specsGroup => _.map(years, y =>
      _.filter(specsGroup.byYear, function (o) { return o.year == y }).length
    ))))
    let body = _.map(this.state.modelStats.trimSpecsGroups, specsGroup => {
      return <tr>
        <th className={'small text-secondary'} key={specsGroup._id}>
          {JSON.stringify(specsGroup._id)}
        </th>
        {_.map(years, y => {
          let byYearCount = _.filter(specsGroup.byYear, function (o) { return o.year === y }).length
          if (byYearCount === 0) { byYearCount = '' }
          return <td key={y} style={getIntensityColorStyle((byYearCount || 1), maxCount)}>{byYearCount}</td>
        })}
      </tr>
    })

    return <table className={"table table-small"}>
      <thead>
        <tr>
          <th>Specs:</th>{_.map(years, y => <th key={y}>{y}</th>)}
        </tr>
      </thead>
      <tbody>
        {body}
      </tbody>
    </table>
  }

  showStats() {
    // trimSpecsByYear is for mx results, currently not implemented.
    let countByYear = <div></div>;
    let otherTrimRatio = <div></div>;
    let trimSpecsByYear = null

    if (this.state.modelStats) {
      countByYear = <div> Distribución por año (en rojo otherYear):  {this.showCountByYear()}</div>;
      otherTrimRatio = <div> Porcentaje de otherTrims (unicamente resultados en que se incluyó texto):  {this.otherTrimRatio()}</div>;
      // trimSpecsByYear = <div>Trims specs: {this.trimsSpecs()}</div>;
    }

    let statsTable = null
    if (this.state.coverageStats) {
      statsTable = this.getStatsTable()
    }

    return <span>
      <div>{statsTable}</div>
      <div>{countByYear}</div>
      <div>{otherTrimRatio}</div>
      <div>{trimSpecsByYear}</div>
    </span>
  }
}
