export const normalize = (str, lowercase= true, trim = true) => {
  if (!str) {
    return '';
  }

  let normalized = str.normalize('NFD')
                       .replace(/[\u0300-\u036f]/g, '')
                       .replace(/\s+/g, ' ');

  if(trim) {
    normalized = normalized.trim();
  }

  return lowercase ? normalized.toLowerCase() : normalized;
};
