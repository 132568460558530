/* eslint-disable no-else-return */
import _ from 'lodash';

export default class TrimsOrganizer {
  static formatSpecValue(trim, group, useDefaults = false) {
    const groupSpecs = group.specs || [group.spec];

    let combinedRes = null;
    _.each(groupSpecs, (groupDef) => {
      const defaultSpecs = {
        engine_fuel: 'Nafta',
        transmission: 'Man',
        engine_size: '?',
        doors: 'Unknown',
        traction: '-',
        other_acara_lineYear: 'Línea nueva',
      };

      const specs = trim.specs || {};

      let res = specs[groupDef];

      if (useDefaults) {
        res = res || defaultSpecs[groupDef];
      }

      if (groupDef === 'doors') {
        if (specs[groupDef]) {
          res = `${res}p`;
        } else {
          res = specs.bodyStyle;

          if (res === 'Sedan') {
            res = '4p';
          }
        }
      } else if (res && groupDef === 'engine_size') {
        res = `${res} L`;
      } else if (res && groupDef === 'engine_power') {
        res = `${res}cv`;
      } else if (groupDef === 'other_acara_lineYear') {
        if (res && res.match(/^[012]\d$/)) {
          res = `Línea 20${res}`;
        }
      } else if (!res && groupDef === 'transmission') {
        res = 'Man';
      }

      if (res) {
        if (combinedRes) {
          combinedRes = `${combinedRes}|${res}`;
        } else {
          combinedRes = res;
        }
      }
    });

    return combinedRes;
  }

  static groupYearTrimsBySpecs(allTrims) {
    if (!allTrims || !allTrims.length) {
      return [];
    }

    let groups = [
      { spec: 'other_acara_lineYear', alwaysFirst: true, minGroups: 2 },
      { spec: 'cabina' },
      { spec: 'utilitarioLength' },
      { spec: 'traction' },
      { spec: 'doors', minGroups: 2 },
      { specs: ['engine_fuel', 'engine_injection', 'engine_turboType'] },
      { specs: ['engine_size', 'engine_power'] },
      { specs: ['transmission', 'transmission_tech'] },
    ];

    groups = _.filter(groups, (group) => {
      const uniqueValues = _.uniq(_.map(allTrims, (t) => this.formatSpecValue(t, group)));
      const uniqueNonNullValues = _.compact(uniqueValues);
      return uniqueNonNullValues.length > 0 && uniqueValues.length >= (group.minGroups || 1);
    });

    groups = _.sortBy(groups, (group) => {
      if (group.alwaysFirst) {
        return -1;
      }

      const uniqValues = _.uniq(_.map(allTrims, (t) => this.formatSpecValue(t, group)));

      return (uniqValues.length > 1) ? 1 : 0;
    });

    const res = { all: allTrims };
    const recursiveGroup = (trimsList, groupindex = 0) => _.mapValues(trimsList, (list) => {
      if (groupindex < groups.length) {
        const groupDefinition = groups[groupindex];

        const data = _.groupBy(list, (t) => this.formatSpecValue(t, groupDefinition, true));

        return { subgroups: recursiveGroup(data, groupindex + 1), trims: list };
      }

      return { subgroups: _.sortBy(list, 'fantasyName'), trims: list };
    });

    const expandRows = ({ subgroups }, row = []) => {
      if (_.isArray(subgroups)) {
        const subrows = _.map(subgroups, (trim, i) => [...row, {
          group: i,
          value: trim.fantasyName,
          rowSpan: 1,
          final: true,
          extra: trim.displayName,
          trim,
        }]);
        subrows[0][row.length].first = true;
        subrows[subrows.length - 1][row.length].last = true;
        // subrows[0][row.length].first = true;
        return subrows;
      } else {
        const rows = _.map(subgroups, (subgroup, key) => {
          const [value, ...secondaryInfo] = key.split('|');
          const trims = (subgroup || {}).trims || [];

          const subrows = expandRows(subgroup, [...row, { group: groups[row.length], value, secondaryInfo, trims }]);

          // Ensure only the first of each group has rowSpan
          subrows[0][row.length] = { ...subrows[0][row.length], rowSpan: subrows.length };

          return subrows;
        });

        return _.flatten(rows);
      }
    };

    const groupedData = recursiveGroup(res);

    return expandRows(groupedData.all);
  }
}
