import LeftRightLayout from "../common/layout/LeftRightLayout";
import {IconButton} from "../common/IconButton";
import _ from "lodash";
import React, {useState} from 'react';
import FacetId, {idOfPropDefinitionRegex, typePropDefinitionRegex} from "./FacetId";
import FacetProxy from "./FacetsProxy";
import {getIdColorClass} from "./facetStyleUtils";
import {useDrop} from 'react-dnd'
import {Icon} from "../common/Icon";

const FacetCard = ({ facet, subtypeOf, config, onDelete, onCopy, onEdit, onClick, onClickParent, onAddParent, onRemoveParent, ...other }) => {
  let { id, parents, subtypesFacets } = facet;
  let { name, type } = FacetProxy.parseId(id);
  const color = getIdColorClass(id, config);

  let extras = [];
  if (_.includes(parents, 'meta:autogenerated')) {
    extras.push('FacetCard--dashed')
  }

  let subtypesCards = null;
  if (subtypesFacets) {
    subtypesCards = <div className={`FacetCard__subtypes mt-2`}>{_.map(subtypesFacets, (sf) => {
      return <FacetCard facet={sf}
                        subtypeOf={id}
                        key={sf.id}
                        onDelete={onDelete}
                        onCopy={onCopy}
                        onEdit={onEdit}
                        onClick={onClick}
                        onClickParent={onClickParent}
                        onAddParent={onAddParent}
                        onRemoveParent={onRemoveParent}
                        config={config}/>;
    })}</div>
  }

  const mouseHandler = (handler, ...args) => ((e) => (handler && handler(...args, e)) + (e && e.stopPropagation && e.stopPropagation()))

  const typeOrSubtype = subtypeOf ? type.replace(subtypeOf, '') : type + ':';

  const parentsSorted = _.sortBy(parents, id => id.match(idOfPropDefinitionRegex) ? ' ' + id : id);
  const parentTags = _.compact(_.map(parentsSorted, (parentId) => {
    if (parentId !== type) {
      return <FacetId draggable={true} parentOf={id} config={config} key={parentId} id={parentId}
                      onClick={mouseHandler(onClickParent, { id }, parentId)}
                      onMovedOut={mouseHandler(onRemoveParent, id, parentId)}
      />;
    }
  }));

  const [hasDropped, setHasDropped] = useState(false)
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false)
  const [{ highlighted }, drop] = useDrop({
    accept: "facetId",
    drop(item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }

      if (onAddParent && id !== item.parentOf) {
        console.log(`DROPPED ${item.facetId} to ${id} from ${item.parentOf}`)
        onAddParent(id, item.facetId);
      }

      setHasDropped(true)
      setHasDroppedOnChild(didDrop)
    },
    canDrop: (item, monitor) => {
      return item.parentOf !== id && monitor.isOver({ shallow: true });
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
    }),
  })

  let dragOverOrAddBtn = null;
  if (highlighted) {
    dragOverOrAddBtn = <span className={'text-success zoom-75'}><Icon icon={'add_circle'}/></span>;
  } else {
    dragOverOrAddBtn = <span className={'onlyOnHover zoom-75'}>
      <IconButton icon={'add_circle'} level={'success'} className={'p-0'}
                  onClick={mouseHandler(onClickParent, { id }, ' ')}/>
    </span>;
  }

  const dragOverStyle = highlighted ? ' bg-light-success rounded' : '';

  return <div ref={drop} className={`FacetCard bg-light-${color} overflow-hidden ${extras.join(' ')}`}
              onDoubleClick={mouseHandler(onEdit, id)} {...other}>

    <div className={''}>
      <LeftRightLayout>
        <div className={'h6 m-0 '} title={id}>
          {type ? <span className={'text-secondary FacetCard__superType mr-1 align-middle'}
                        onClick={mouseHandler(onClick, { id: type })}>{typeOrSubtype}</span> : null}
          <span className={'FacetCard__superType align-middle mr-3'}
                onClick={mouseHandler(onClick, facet)}>{name}</span>

          <span className={'align-middle inline-block' + dragOverStyle}>{parentTags}{dragOverOrAddBtn}</span>
        </div>
        <div className={'onlyOnHover zoom-90'}>
          <IconButton icon={'edit'} level={'primary'} className={'p-0'} onClick={mouseHandler(onEdit, id)}/>
          <IconButton icon={'delete'} level={'danger'} className={'p-0 ml-1'} onClick={mouseHandler(onDelete, id)}/>
          <IconButton icon={'content_copy'} level={'success'} className={'p-0 ml-1'}
                      onClick={mouseHandler(onCopy, facet)}/>
        </div>
      </LeftRightLayout>
    </div>

    {subtypesCards}
  </div>
}

export default FacetCard;
