import React from 'react';
import _ from 'lodash';

import { IconButton } from '../common/IconButton';

export default function MultipleItemsEditor({ value, onChange, compact, buttonsAlwaysVisible, defaultNewItem, renderComponent }) {
  const addItem = () => {
    let items = [...(value || [])];
    items.push(_.clone(defaultNewItem === undefined ? {} : defaultNewItem));
    onChange(items);
  };

  const updateItem = (index, newItem) => {
    const items = [...value];
    items[index] = newItem;
    onChange(items);
  };

  const removeItem = (index) => {
    const items = [...value];
    items.splice(index, 1);
    onChange(items);
  };

  const renderItem = (item, index) => {
    return renderComponent(item, { key: index, onChange: (item) => updateItem(index, item) }, index);
  };

  let rows = _.map(value || [], (item, i) => {
    return <div key={i} className={compact ? '' : 'row no-gutters mb-1'}>
      <div className={compact ? '' : 'col-11'}> {renderItem(item, i)} </div>

      <div className={(compact ? '' : 'col-1') + (buttonsAlwaysVisible ? '' : ' parent-hover-transparent')}>
        <div>
          <IconButton onClick={() => removeItem(i)}
                      level={buttonsAlwaysVisible ? 'secondary' : 'danger'}
                      icon={'remove_circle_outline'} tabIndex={-1}
                      description={'Remove item'} className={'p-0 zoom-75'}/>

          <IconButton onClick={addItem}
                      level={buttonsAlwaysVisible ? 'secondary' : 'success'}
                      icon={'add_circle'}
                      description={'Add item'} tabIndex={-1} className={'p-0 zoom-75'}/>
        </div>
      </div>
    </div>;
  });

  if (!(value || []).length) {
    rows.push(<div key={'add'}>
      <IconButton onClick={addItem} level={'success'} icon={'add_circle'} description={'Add item'}/>
    </div>);
  }

  return <div>
    {rows}
  </div>;
}
