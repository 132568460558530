import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import format from 'date-fns/format';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import ImageClusterPreview from '../images/ImageClusterPreview';
import TemplatedFuseboxDiagram from '../lego/fusebox-editor/TemplatedFuseboxDiagram';
import { IconButton } from '../common/IconButton';
import GridLayoutLeftBar from '../common/layout/GridLayoutLeftBar';
import BadgeId from '../common/BadgeId';
import { Icon } from '../common/Icon';
import LegoContextSummary from '../lego/LegoContextSummary';
import ImageClusterGallery from '../images/ImageClusterGallery';
import { ImagePreview } from '../images/ImagePreview';
import LegoLabel from '../lego/LegoLabel';
import DataIssueEditor from './DataIssueEditor';
import { LocalesList } from '../lego/LocalesList';
import { SwitchInput } from '../common/SwitchInput';
import LegoEditButton from '../lego/LegoEditButton';
import { LegoStateBadge } from '../lego/LegoStateBadge';

function legoDataIssueUrl(modelId, year, dataIssueId) {
  return `/legos/data-issues?filters=%7B"dataSelector.vehicle"%3A%7B"modelId"%3A"${encodeURIComponent(modelId)}"%2C"year"%3A${year}%7D%7D&editing="${dataIssueId}"`;
}

async function editDataIssueOnPage(page, issueId) {
  let service = page.service('/services/data/fusebox-data-issues');
  let freshObj = await service.get(issueId);

  return new Promise((resolve, reject) => {
    const onSave = async(changedObj, closeDialog) => {
      try {
        changedObj.updatedBy = page.getLoggedUserSignature();
        const res = await service.update(changedObj._id, changedObj);

        if (closeDialog) {
          page.modalActionsBus.emit('close');
        }

        resolve(res);
      } catch (err) {
        reject(err);
        page.handleError(err);
      }
    };

    const onCancel = () => {
      page.modalActionsBus.emit('close');
      resolve(null);
    };

    const objectEditorComponent = <DataIssueEditor obj={freshObj} onSave={onSave} onCancel={onCancel}/>;

    page.modalActionsBus.emit('open', <div>{objectEditorComponent}</div>, {
      // fullScreen: true,
      // onClose: () => this.deleteUrlParam('editing'),
      title: `Edit data issue`
    });
  })
}

function BoxPreview({box}) {
  if (box?.fuses?.length) {
    return <div style={{height: '300px'}}>
      <TemplatedFuseboxDiagram fusebox={box}/>
    </div>
  } else if (box) {
    let diagramAndTables = _.compact([box.boxDiagramImg?.url, ..._.map(box?.boxTablesImages, 'url')]);
    return <>{diagramAndTables.map(url => <ImagePreview left img200 key={url} url={url} className={'m-1 bg-light'} zoom/>)}</>;
  }
}

function OtherBoxesPreview({ fusebox, modelId, year, skipCluster, skipBox }) {
  const { page } = useContext(LegoAdminPageContext);

  const dataIssuesService = page.service('/services/data/fusebox-data-issues');
  const legosService = page.service('/services/legos');

  const [{dataIssues, templateClusters}, setData] = useState({});

  useAsyncEffect(async () => {
    const modelDataIssues = await dataIssuesService.find({ query: { 'dataSelector.vehicle.modelId': modelId } });
    console.log('Model data issues', modelDataIssues);

    let templates = await Promise.all(_.map(fusebox.data, box => box.templateId && legosService.get(box.templateId)));
    const templateClusters = _.map(templates, t => t && t.data[0]?.imageClusters);
    console.log('TEMPLATES', templateClusters);

    setData({dataIssues: modelDataIssues, templateClusters});
  }, [fusebox._id]);

  if (fusebox && dataIssues) {
    return <div>
      {
        fusebox.data.map((box, i) => {
          let closestYears = _.sortBy(dataIssues, di => Math.abs(di.dataSelector.vehicle?.year - year));
          let uniqueClusterIds = _.without(_.compact(_.uniq(closestYears.map(di => di.data?.clusterId))), skipCluster);

          let boxClusters = _.union(box.imageClusters, templateClusters[i]);

          if (_.intersection(boxClusters, uniqueClusterIds).length > 0) {
            uniqueClusterIds = _.intersection(boxClusters, uniqueClusterIds);
          }

          if (i !== skipBox) {
            return <div key={i}>
              <div className={'px-3 d-flex pt-2 border-top'}>
                <div style={{ height: '500px', width: '500px' }}>
                  <BoxPreview box={box}/>
                </div>

                <div style={{}}>
                  {uniqueClusterIds.map(id => id !== dataIssues ? <div key={id}>
                    <ImageClusterPreview maxImages={5} clusterId={id}/>
                  </div> : null)}
                </div>
              </div>
            </div>;
          }
        })
      }
    </div>;
  } else {
    return 'Loading...'
  }
}

const LABEL_HARD = 'difficult:hard';
const LABEL_DONE = 'freelancer:done';
const LABEL_WORK_NEEDED = 'freelancer:workneeded';

export function PotentiallySolvedDataIssuesAssistant({clusterIds: initialClusterIds}) {
  const { page } = useContext(LegoAdminPageContext);

  const [stats, setStats] = useState();
  const [clusterIds, setClusterIds] = useState(initialClusterIds || null);
  const [fuseboxes, setFuseboxes] = useState(null);
  const [skipWorkNeeded, setSkipWorkNeeded] = useState(true);
  const [fuseboxesUrls, setFuseboxesUrls] = useState(null);
  const [contextCollisions, setContextCollisions] = useState(null);
  const [currentId, setCurrentId] = useState();
  const [solvedDataIssuesIds, setSolved] = useState(new Set());

  const statsService = page.service('/services/data/issues/potentially-solved');
  const legosService = page.service('/services/legos');
  const fuseboxUrlService = page.service('/services/legos/fusebox-url');
  const dataIssuesService = page.service('/services/data/fusebox-data-issues');


  let rowsByCluster = _.groupBy(stats, 'clusterId');
  let sortedRows;
  if(clusterIds?.length) {
    sortedRows = _.flatten(_.map(clusterIds, id => _.sortBy(rowsByCluster[id], 'year')));
  } else {
    let clustersByFeedbackCount = _.sortBy(_.toPairs(rowsByCluster), ([id, rows]) => -_.sum(_.map(rows, 'involvedDataFeedbackCount')));
    sortedRows = _.flatten(_.map(clustersByFeedbackCount, p => _.sortBy(p[1], 'year')));
  }


  let currentIndex = sortedRows?.findIndex(({dataIssueId}) => dataIssueId === currentId);
  if(currentIndex === -1) {
    currentIndex = 0;
  }
  const currentFuseboxIds = (stats?.length && _.map(sortedRows[currentIndex]?.candidates, '_id')) || [];
  const { year, modelId } = sortedRows[currentIndex] || {};

  const updateIssues = async (keepCurrent) => {
    const query = {};

    if(clusterIds) {
      query.clusterIds = clusterIds;
    }

    if(skipWorkNeeded) {
      query.labels = { $nin: [LABEL_WORK_NEEDED] };
    }

    if(!keepCurrent) {
      setFuseboxes([]);
      setFuseboxesUrls([]);
      setCurrent(0);
    }

    setStats(await statsService.find({ query }));
    setSolved(new Set())
  };

  const updateFuseboxCollisions = async () => {
    const urls = await Promise.all(currentFuseboxIds.map(id => fuseboxUrlService.find({ query: { modelId, year, _id: id } })));
    setFuseboxesUrls(urls);

    const compatibleContexts = await fuseboxUrlService.find({ query: { modelId, year } });
    setContextCollisions(compatibleContexts)
  };

  useAsyncEffect(updateIssues, [JSON.stringify(clusterIds), skipWorkNeeded]);

  useAsyncEffect(async () => {
    setFuseboxes([]);
    setFuseboxesUrls([]);
    setFuseboxes(await Promise.all(currentFuseboxIds.map(id => legosService.get(id))));
  }, [JSON.stringify([currentFuseboxIds, currentId])]);


  useAsyncEffect(updateFuseboxCollisions, [JSON.stringify(currentFuseboxIds), modelId, year]);

  const solveDataIssue = (issueId, fuseboxId, fuseboxUrl) => {
    page.runAsync(async () => {
      // Fetch fresh data issue, add dataFeedbackId and save
      let clone = await dataIssuesService.get(issueId);

      clone.state = 'solved';
      clone.isOpen = false;
      clone.data.fuseboxLegoId = fuseboxId;
      clone.data.fuseboxUrl = fuseboxUrl;

      await dataIssuesService.update(issueId, clone);

      solvedDataIssuesIds.add(issueId);
      setSolved(solvedDataIssuesIds);
      goTo(currentIndex + 1);
    });
  };


  const addLabels = (issueId, labels) => {
    page.runAsync(async () => {
      let clone = await dataIssuesService.get(issueId);
      clone.labels = _.uniq(_.union(clone.labels, labels));
      await dataIssuesService.update(issueId, clone);
      await updateIssues(true);
      goTo(currentIndex + 1);
    });
  }

  const addContext = (fuseboxId, context) => {
    page.runAsync(async () => {
      legosService.update(fuseboxId, { $addToSet: { context } });
      setFuseboxes(await Promise.all(currentFuseboxIds.map(id => legosService.get(id))));
      await updateFuseboxCollisions();
    });
  };

  const markIssueHardDone = (issueId) => addLabels(issueId, [LABEL_HARD, LABEL_DONE]);
  const markIssueWorkNeeded = (issueId) => addLabels(issueId, [LABEL_WORK_NEEDED]);
  const markIssueDone = (issueId) => addLabels(issueId, [LABEL_DONE]);

  const selectClusters = () => {
    let ids = prompt('Enter list of cluster ids as one string separated by spaces or commas');
    if(ids) {
      let matches = ids.match(/[a-f\d]{24}/gi);

      if(matches?.length && !_.isEqual(matches,clusterIds)) {
        setStats(null);
        setClusterIds(matches);
      }
    }
  }

  const linkBox = (candidate, boxNumber) => {
    candidate.boxNumber = boxNumber;
    setStats([... stats]);
  }

  let content;

  function goTo(i) {
    if(currentIndex !== i) {
      let clampedIndex = Math.max(0, Math.min(i, sortedRows.length - 1));
      setCurrentId(sortedRows[clampedIndex].dataIssueId);
      setFuseboxes([]);
      setFuseboxesUrls([]);
      setContextCollisions(null);
    }
  }

  if (stats?.length) {
    let { dataIssueId, involvedDataFeedbackCount, clusterId, labels, candidates } = sortedRows[currentIndex];

    const editIssue = async () => {
      const changedIssue = await editDataIssueOnPage(page, dataIssueId);
      if(changedIssue) {
        updateIssues(true);
      }
    }

    let otherBoxesCheck = null;

    let globalActions = [];

    let issueContext = { modelId, year };

    if(candidates?.length === 1) {
      let [fusebox] = candidates;

      let matchingContexts = _.filter((fuseboxes[0] || fusebox).context, issueContext);

      let hasSameLocales = fusebox.locales?.includes(window.config.locale);

      let onlyOneOption = fusebox.boxNumber !== undefined || fusebox.data?.length === 1;

      if (hasSameLocales) {
        if (matchingContexts.length) {
          if(!labels?.includes(LABEL_DONE)) {
            globalActions.push(<IconButton fill level={'success'} icon={'done'} key={'markDone'}
                                           className={'ml-2 align-middle'}
                                           onClick={() => markIssueDone(dataIssueId)}>Mark done</IconButton>);
          }
        } else if(onlyOneOption) {
          globalActions.push(<IconButton fill className={''} icon={'label'} level={'primary'} key={'addCtx'}
                                         onClick={() => addContext(fusebox._id, issueContext)}>
            Add context <LegoContextSummary inline context={issueContext}/> to lego&nbsp;
          </IconButton>);

          if(fusebox.data?.length > 1) {
            globalActions.push(<span key={'other'} className={' inline-block text-center m-2 p-1 alert alert-warning'}>
            Check other boxes!
          </span>);
          }

          globalActions.push(<IconButton fill icon={'file_copy'} level={'success'} key={'clone'}
                                         className={'ml-2 align-middle'} onClick={() => alert('todo')}>
            Clone box and this context
          </IconButton>);
        } else {
          debugger;
        }
      } else {
        globalActions.push(<span key={'locales'} className={'m-2 p-1 alert alert-danger'}>
          Different locale: <LocalesList locales={fusebox.locales}/>
        </span>)
      }

      //  && (match!_.compact(fuseboxesUrls?.length)
      if(_.difference(contextCollisions, fuseboxesUrls).length) {
        globalActions.push(<div key={'collisions'} className={'m-0 mt-2 p-1 alert alert-danger'}>
          Colliding contexts with published fuses: {contextCollisions.map(relativeUrl => <div key={relativeUrl}>
          <a target={'_blank'}
             href={`${window.config.opinautosUrl}${relativeUrl}`}>{relativeUrl}</a>
          </div>)}
        </div>)
      }
    }

    if (!labels?.includes(LABEL_WORK_NEEDED)) {
      globalActions.push(<IconButton fill icon={'construction'} level={'warning'} key={'markWorkNeeded'}
                                     className={'ml-2 align-middle'} onClick={() => markIssueWorkNeeded(dataIssueId)}>
        Set WORK NEEDED
      </IconButton>);
    }

    if (!labels?.includes(LABEL_HARD)) {
      globalActions.push(<IconButton fill icon={'security'} level={'dark'} key={'markHard'}
                                     className={'ml-2 align-middle'} onClick={() => markIssueHardDone(dataIssueId)}>
        Set HARD and done
      </IconButton>);
    }


    // if (true) {
    //   globalActions.push(<IconButton fill icon={'dangerous'} level={'danger'} key={'markWrong'}
    //                                  className={'ml-2 align-middle'} onClick={() => alert('todo')}>
    //     Wrong year
    //   </IconButton>);
    // }



    content = <div>
      <GridLayoutLeftBar offsetTop={45}>
        <div className={'d-flex align-items-center mb-2'}>
          <table className={'table table-sm'} style={{ width: '300px' }}>
            <thead>
            <tr className={'bg-light'}>
              <th colSpan={5} className={'align-middle text-center'}>
                <SwitchInput value={skipWorkNeeded} onChange={setSkipWorkNeeded}>Skip Work Needed</SwitchInput>
              </th>
            </tr>
            <tr className={'bg-light'}>
              <th colSpan={1} className={'text-center'}> <IconButton icon={'add'} onClick={selectClusters}>Set cluster ids...</IconButton>
              </th>
              <th colSpan={4} className={'align-middle'}>{stats.length} issues</th>
            </tr>
            </thead>
            <tbody>
            {sortedRows.map(({
                               dataIssueId, involvedDataFeedbackCount, clusterId, fuseboxLegoId, candidates, year,
                               modelId, labels
                             }, i) => {
              let classes = dataIssueId === currentId ? 'row-highlight' : '';

              let freelancerDone = _.includes(labels, LABEL_DONE);
              let workNeeded = _.includes(labels, LABEL_WORK_NEEDED);

              if (solvedDataIssuesIds.has(dataIssueId)) {
                classes += ' bg-success';
              } else if (workNeeded) {
                classes += ' bg-light-warn';
              } else if (_.includes(labels, LABEL_HARD)) {
                classes += ' bg-light-darkblue';
              } else if (freelancerDone) {
                classes += ' bg-light-success';
              }


              if (i > 0 && sortedRows[i - 1].clusterId !== clusterId) {
                classes += ' row-separator';
              }

              let issueState = 'error';
              let issueStateColor = 'danger';
              if (fuseboxLegoId) {
                if(candidates.length && candidates[0].state === 'published') {
                  issueState = 'edit';
                  issueStateColor = 'primary'
                } else {
                  issueState = 'note_add';
                  issueStateColor = 'warning'
                }
              } else if (_.some(candidates, {state: 'published'})) {
                issueState = 'published_with_changes';
                issueStateColor = 'info'
              }

              return <tr key={i} className={classes} onClick={() => goTo(i)}>
                <td className={'pl-3'}>{modelId} {year}</td>
                <td className={'pl-3 text-center text-primary'}><Icon level={issueStateColor} icon={issueState}/></td>
                <td className={'h5 text-right pr-2'}>{involvedDataFeedbackCount}</td>
                <td>{candidates.length !== 1 ? `(${candidates.length})` : null}</td>
                <td>{workNeeded ? '⚒' : (freelancerDone ? '✔' : null)}</td>
              </tr>;
            })}
            </tbody>
          </table>
        </div>

        <div>
          <div className={'d-flex align-items-center justify-content-center bg-dark text-white'}>
            <IconButton size={'lg'} icon={'arrow_back'} onClick={() => goTo(currentIndex - 1)}/>
            <span className={'h3 m-0 monospace'}>{(currentIndex + 1).toString().padStart(3, '0')} of {stats.length}</span>
            <IconButton size={'lg'} icon={'arrow_forward'}
                        onClick={() => goTo(currentIndex + 1)}/>
          </div>

          <div className={'text-left d-flex align-items-center m-0 pl-3 pt-3 pb-3 bg-light border-bottom'}>
            <IconButton onClick={editIssue} icon={'edit'}/>
            <span className={'h5 m-0'}>Data issue <BadgeId id={dataIssueId}/></span>

            <span className={'h4 m-0 ml-2 mr-2'}><LegoContextSummary inline context={{modelId, year}}/></span>

            <span className={'align-middle'}>{(labels || []).map(l => <LegoLabel className={'ml-1'} label={l} key={l}/>)} </span>
          </div>

          {
            globalActions.length ? <div className={'bg-light-secondary border-bottom p-3'}>{globalActions}</div> : null
          }

          <div className={'d-flex justify-content-left mt-3 px-3'}>
            <div>
              {_.map(fuseboxes, (fusebox, i) => {
                if (!fusebox || !candidates[i]) {
                  return <div className={'alert alert-danger'}>Fusebox <BadgeId id={currentFuseboxIds[i]}/> not found.
                    Maybe it was merged and deleted?<br/><strong>Fix referenced fusebox id in data issue</strong></div>;
                }

                const currentFuseboxId = fusebox._id;
                const boxNumber = candidates[i].boxNumber ?? (fusebox?.data?.length === 1 ? 0 : null);
                const fuseboxUrl = fuseboxesUrls[i];
                const box = fusebox?.data?.[boxNumber];

                let fuseboxPreview = 'Loading fusebox...';

                const progressBackground = box?.fuses?.length ? '' : 'p-1 bg-warning';


                if (box) {
                  fuseboxPreview = <div style={{ height: '500px', width: '500px', overflow: 'auto' }} className={progressBackground}>
                    <BoxPreview box={box}/>
                  </div>
                } else if (fusebox && boxNumber == null && fusebox.data?.length > 1) {
                  fuseboxPreview = <div>
                    <div className={'alert alert-warning'}>Fusebox lego has more than one box, data issue does not
                      specify which and cluster is not linked in any box.</div>

                      {fusebox?.data.map((box,boxIndex) => <div key={boxIndex}>
                        <div>
                          <IconButton fill icon={'link'} level={'primary'} onClick={() => linkBox(candidates[i],boxIndex)}>
                            Link box {boxIndex}
                          </IconButton>
                        </div>
                        <div style={{ width: '300px' }} className={progressBackground}>
                          <BoxPreview box={box} key={boxIndex}/>
                        </div>
                      </div>)}
                  </div>
                }

                if(boxNumber !== null && fusebox.data?.length > 1) {
                  otherBoxesCheck = <OtherBoxesPreview modelId={modelId} year={year} skipCluster={clusterId} skipBox={boxNumber} fusebox={fusebox}/>
                }


                let matchingContexts = _.filter(fusebox.context, issueContext);

                let btnSolveUsingFusebox = null;

                if (fuseboxUrl) {
                  btnSolveUsingFusebox = <div className={'mt-1'}>
                    <span className={'btn btn-success mb-3'}
                          onClick={() => solveDataIssue(dataIssueId, currentFuseboxId, fuseboxUrl)}>
                      <Icon icon={'check_circle'} level={'white'}/>
                      &nbsp; Use it to solve and close data issue&nbsp;
                      <Icon icon={'trending_flat'} level={'white'}/>
                    </span>

                    <div className={'monospace small'}>{fuseboxUrl}</div>
                  </div>;
                }


                return <div key={i} className={'border-bottom mb-3'}>
                  {btnSolveUsingFusebox}

                  <h6 className={''}>
                    <LocalesList locales={fusebox.locales}/>
                    &nbsp;<LegoEditButton legoId={currentFuseboxId} lego={{type: 'fusebox', context: [{modelId, year}]}}/>

                    &nbsp;Fusebox <BadgeId id={currentFuseboxId}/> (box {boxNumber+1} of {fusebox.data.length})

                    &nbsp;<LegoStateBadge state={fusebox.state}/>
                  </h6>

                  <div className={matchingContexts?.length ? 'bg-light-success p-1' : 'bg-light p-1'}>
                    {fusebox && matchingContexts.map((ctx, j) => <LegoContextSummary key={j} context={ctx}/>)}

                    { !matchingContexts?.length ? <LegoContextSummary context={fusebox.context}/> : null }
                  </div>

                  <div className={progressBackground}>
                    {fuseboxPreview}
                  </div>

                </div>;
              })}
            </div>

            <div className={'ml-2'}>
              <h6>Cluster <BadgeId id={clusterId}/></h6>
              {clusterId ? <ImageClusterGallery key={clusterId} filterByYear={year} clusterId={clusterId} maxImages={8}/> : 'No cluster id in data issue'}
            </div>
          </div>

          {otherBoxesCheck}
        </div>
      </GridLayoutLeftBar>

    </div>;
  } else {
    if (stats) {
      content = <h3 className={'text-center my-4'}>No potentially solved data issues found!</h3>;
    } else {
      content = <h3 className={'text-center my-4'}>Loading potentially solved data issues...</h3>;
    }
  }

  return <div>
    {content}
  </div>;
}
