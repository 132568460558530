import _ from 'lodash';
import React from 'react';

export default function LegoWarningLightsPreview({ lego, textFilter }) {
  let { data, semantics } = lego;

  let filterRE = null;
  if(textFilter) {
    filterRE = new RegExp(_.escapeRegExp(textFilter), 'i');
  }

  return <div className={'small'}>
    {_.map(data['warning-lights'], (light,i) => {
      const { lightName, lightImg, semantic, semanticVariation } = light || {};

      let statusClass = semantic ? 'border-success' : 'border-secondary';

      let src = (lightImg?.url || '').replace('//opinautos-images.s3.amazonaws.com/', '//images.opinautos.com/');

      if (filterRE) {
        if(`${semantic || ''}-${semanticVariation || ''}-${lightName}`.match(filterRE)) {
          statusClass = 'border-primary';
        } else {
          statusClass += ' translucent';
        }
      }

      return <img src={src}
                  key={(lightName || "")+i}
                  height={40}
                  width={40}
                  style={{ objectFit: 'contain' }}
                  alt={lightName}
                  className={'border rounded ' + statusClass}
                  title={`${semantic || ''}#${semanticVariation || ''} - "${lightName}"`}
      />;
    })}
  </div>;
}
