import React, { Component } from 'react';
import ManualSourceSelector from '../lego/ManualSourceSelector';
import { MultipleImagesEditor } from '../common/editors/MultipleImagesEditor';
import { LegoReferenceSelector } from '../common/editors/LegoReferenceSelector';

export class GenericDataTypeEditor extends Component {
  render() {
    let { type, onChange, value } = this.props;

    switch (type) {
      case 'LegoRef':
        return <LegoReferenceSelector onChange={onChange} value={value}/>;
      case 'LegoFuseboxRef':
        const emptyFuseboxTemplate = {
          type: 'fusebox',
          intentions: ['spec'],
          locales: [window.config.locale],
          semantics: ['DIAGRAMA_DE_FUSIBLES'],
          data: [{ fuses: [] }],
          state: 'inprogress'
        };

        return <LegoReferenceSelector legoTemplate={emptyFuseboxTemplate} legoFilter={{ type: 'fusebox' }}
                                      onChange={onChange} value={value}/>;
      case 'ManualRef':
        return <ManualSourceSelector onChange={onChange} value={value}/>;
      case 'Photos':
        return <MultipleImagesEditor onChange={onChange} value={value}/>;
      default:
        return <span className={'alert alert-danger'}>Unsupported type {type}</span>;
    }
  }
}
