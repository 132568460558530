import React, { Component, useContext } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import CRUDPage from './CRUDPage';
import LegoContextSummary from "../components/lego/LegoContextSummary";
import {ManualsFiltersBar} from "../components/common/ManualsFiltersBar";
import {Icon} from "../components/common/Icon";
import submenuManuals from "./menus/submenu-manuals";
import CountryFlag from "../components/common/CountryFlag";
import PDFHtmlViewer from "../components/pdf-tools/PDFHtmlViewer";
import MongoDocEditor from "../components/lego/MongoDocEditor";
import SemanticTag from '../components/semantic/SemanticTag';
import { IconButton } from '../components/common/IconButton';
import ManualComparison from '../components/manuals/ManualComparison';
import SingleManualViewer from './SingleManualViewer';
import ModalCreateTasksFromManuals from '../components/lego/ModalCreateTasksFromManuals';
import InputManualsMultipleSemantics from '../components/common/editors/InputManualsMultipleSemantics';
import { LocalesSelector } from '../components/lego/LocalesSelector';
import { LocalesList } from '../components/lego/LocalesList';
import LegoAdminPageContext from './legoAdminPageContext';
import ModalBatchEditLabels, {
  defaultColumns,
  LOGIC_ARRAY_SET_COMMON_FACTOR,
  LOGIC_SET_UNSET__FIELD
} from '../components/lego/ModalBatchEditLabels';
import SingleTextEditor from '../components/common/editors/SingleTextEditor';

function createGroupId() {
  // generate UUIDv4 and use the last 8 chars
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)).slice(0,8);
}

function ModalBatchEditManuals({ docs, onCancel, onSave}) {
  let { page } = useContext(LegoAdminPageContext);
  const svc = page.service('services/data/manuals');

  let columns = [
    defaultColumns.legoState,
    defaultColumns.context,
    defaultColumns.locales,
    {
      dataField: 'groupId',
      valueEditor: (value, onChange) => <div>
        <span className={'d-inline-block'} style={{width: '100px'}}>
          <SingleTextEditor small value={value} onChange={s => onChange(s || undefined)}/>
        </span>
        { !value ? <IconButton icon={'add_circle'} level={'success'} onClick={() => onChange(createGroupId())}/> : null }
      </div>,
      valueDisplay: (value) => value ? <span className={'badge badge-rosa'}>{value}</span> : '-',
      logic: LOGIC_SET_UNSET__FIELD
    },
    {
      dataField: 'manualCategory',
      valueEditor: (value, onChange) => <InputManualsMultipleSemantics value={value} onChange={onChange}/>,
      valueDisplay: (value) => <span>{_.map(value, sem => <SemanticTag level={'primary'} key={sem}
                                                                       semKey={sem}/>)}</span>,
      logic: LOGIC_ARRAY_SET_COMMON_FACTOR
    },
  ];

  return <ModalBatchEditLabels docs={docs} onCancel={onCancel} onSave={onSave} service={svc} columns={columns}/>;
}


export default class ManualsManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/manuals');

    this.submenu = submenuManuals;
  }

  buildObjectQuery() {
    // Override CRUD buildObjectQuery to do a special transformations when models are filtered by make
    // If context.make present, ALSO include legos with a modelId that start with that make
    let originalQuery = super.buildObjectQuery();

    if(originalQuery['context.make']) {
      let {$limit, $select, ... query} = originalQuery;
      let restOfQuery = _.omit(query, 'context.make', '$select', '$limit');
      restOfQuery['context.modelId'] = {$regex: `^${query['context.make']}-`}
      originalQuery = { $or: [query, restOfQuery], $select, $limit };
    }
    return originalQuery;
  }

  getFacetFiltersComponent() {
    return <ManualsFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  getColumnsDefinition(objects) {
    return [
      {content: 'Type', className: ''},
      {content: 'Locale', className: 'text-center' },
      {
        content: 'Context', className: '', sorter: (l) => {
          return _.min(_.map(l.context, 'year'));
        }
      },
      { content: 'Country/Lang', className: '' },
      { content: '', className: '' },
      { content: 'Details', className: '' },
      { content: 'Features', className: '' },
    ]
  }

  openCompareModal(manualA, manualB) {
    this.openModal(<ManualComparison manualIdA={manualA._id} manualIdB={manualB._id}/>, {fullScreen: true});
  }

  async openBatchEditContexts(manuals) {
    this.openModal(<ModalBatchEditManuals docs={manuals} onCancel={() => this.closeModal()}/>, {title: 'Edit manuals in batch'});
  }

  getSelectionButtons(selection) {
    let buttons = [];

    if(selection.length === 2) {
      const a = selection[0];
      const b = selection[1];
      buttons.push(<IconButton icon={'compare'} onClick={() => this.openCompareModal(a, b)}/>);

      if(a.metadata?.textHash && a.metadata?.textHash === b.metadata?.textHash) {
        buttons.push(<span><span className={'badge badge-success ml-2'}>SAME TEXT HASH</span></span>);
      }
    }

    if(selection.length > 1) {
      buttons.push(<IconButton icon={'app_registration'} title={'Edit contexts in batch'} onClick={() => this.openBatchEditContexts(selection)}></IconButton>)
    }

    buttons.push(<IconButton icon={'add_task'} onClick={() => this.openCreateTasksModal(selection)} title={'Create tasks'}/>);

    return <>
      {buttons}
    </>
  }

  openCreateTasksModal(selection) {
    this.modalActionsBus.emit('open', <ModalCreateTasksFromManuals manuals={selection} onCancel={() => this.modalActionsBus.emit('close')}/>);
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    await super.fetchObjects({$sort: {uploadDate: -1}});
  }

  searchManual(id) {
    this.openModal(<SingleManualViewer manualId={id}/>, { title: `Manual ${id}`,  noPadding: true, fullScreen: true});
  }

  getManualBadges(features) {
    let known = {
      hasFuseboxTable: 'FUSES',
      hasWarningLights: 'LIGHTS',
      hasOilData: 'OIL'
    };

    let badges = [];
    _.each(known, (letters, key) => {
      if(features[key] !== undefined) {
        let styleClass = features[key] ? 'success' : 'secondary text-strike';
        badges.push(<div><span key={key} className={`mt-1 ml-1 badge badge-${styleClass}`}>{letters}</span></div>)
      }
    })

    return <div className={'zoom-75'}>{badges}</div>
  }

  getObjectColumns(manual, definition) {
    let { _id, context, uploadDate, locales, legacy, isoLanguage, country, manualPdfUrl, manualCategory, manualSubsection, sourceUrl, metadata,published, features, updatedAt, groupId, olderVersionOf} = manual;

    let viewStateClass = published ? 'success' : 'warning';

    let coverImgSrc = null;
    if(metadata) {
      let {hash, pages, s3Bucket} = metadata;
      coverImgSrc = PDFHtmlViewer.generatePageImgUrl(hash, 'thumbnails', 1, pages, s3Bucket);
    }
    let tdClassNames = olderVersionOf ? 'bg-light-warning' : null;

    return [
      <td key={'type'}>
        <div>
          <span className={'text-info small ml-2'}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
        </div>
        <div className={'text-secondary small'}>{_id}</div>
        <div className={'text-primary small'}>
          { _.map(manualCategory, sem => <SemanticTag level={'primary'} key={sem} semKey={sem}/>) }
          { _.map(manualSubsection, sem => <SemanticTag level={'secondary'} key={sem} semKey={sem}/>) }
        </div>
        { !published ? <span className={'badge badge-danger'}>unpublished</span> : null }
      </td>,

      <td key={'lang'} className={'text-center'}>
        <LocalesList locales={locales}/>
      </td>,

      <td key={'ctx'} className={'context-column text-left'}><LegoContextSummary context={context}/></td>,

      <td key={'isoLanguage'}>
        {_.map(country, c => <CountryFlag key={c} countryCode={c} className={'mr-1'}/>)}
        <span className={'badge badge-primary'}>{isoLanguage}</span>
      </td>,


      <td key={'search'} style={{width: '50px'}} className={'text-center'}>
        <IconButton icon={'search'} onClick={() => this.searchManual(_id)}/>
      </td>,

      <td key={'cover'} style={{width: '100px'}} className={'text-center'}>
        { coverImgSrc ? <img src={coverImgSrc} className={''} style={{ height: '50px' }} alt={_id} title={_id}/> : null }
      </td>,

      <td key={'keys'} className={tdClassNames}>
        <div className={'small text-info'}>
          <span className={'badge badge-secondary mr-1'}>Uploaded {dayjs(uploadDate).fromNow()}</span>

          {
            metadata
              ?
              <span>
                <span className={'badge badge-info mr-1'}>{metadata.pages} pages</span>
                <span className={'badge badge-secondary mr-1'}>{(metadata.size / (2**20)).toFixed(1)} mb</span>
              </span>
              :
              <span className={'badge badge-warning mr-1'}>No metadata</span>
          }
          {
            groupId ?
              <span className={'badge badge-rosa mr-1'}>Group ID {groupId}</span>
              :
              null
          }
          {
            olderVersionOf ?
              <span className={'badge badge-warning mr-1'}>Older version of {olderVersionOf}</span>
              :
              null
          }
          {
            legacy && legacy.otherOfficialInfo ?
              <span className={'text-secondary small break-word-all'}>{JSON.stringify(legacy.otherOfficialInfo)}</span>
              :
              null
          }
          {
            features?.printedDate ? <span className={'badge badge-rosa'}>{_.values(features.printedDate).join(' - ')}</span> : null
          }

          {
            features?.hasText === false ? <span className={'badge badge-warning'}>Only images</span> : null
          }


        </div>

        <div className={'small text-info break-word-all zoom-90'} title={sourceUrl || ''}>
          {sourceUrl ? `Source: ${sourceUrl}` : null}
        </div>

        <a href={manualPdfUrl?.replace('opinautos-manuals.s3.amazonaws.com', 'manuals.opinautos.com')} target={'_blank'} className={'small'}>
          <Icon icon={'picture_as_pdf'}/>
          &nbsp;{manualPdfUrl?.replace('https://opinautos-manuals.s3.amazonaws.com/published/', '')}
        </a>

      </td>,

      <td>
        {
          this.getManualBadges(features || {})
        }
      </td>

    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    const schema = {
      '_id': (value, onChange) => <div className={'p-1'}>
        <IconButton icon={'search'} onClick={() => this.searchManual(value)}>Search text in manual</IconButton>
      </div>,
      'locales': (value, onChange) => <LocalesSelector value={value} onChange={onChange}/>,
      'manualCategory': (value, onChange) => <InputManualsMultipleSemantics value={value} onChange={onChange}/>,
      'manualSubsection': (value, onChange) => <InputManualsMultipleSemantics value={value} onChange={onChange}/>,
    }

    return <MongoDocEditor showFields={['_id']} obj={obj} extendSchema={schema}  onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}
