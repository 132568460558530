import React from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import { IconButton } from '../components/common/IconButton';
import GridLayoutLeftBar from '../components/common/layout/GridLayoutLeftBar';
import { metricOptions, PageSampleMetricSelector } from '../components/stats/PageSampleMetricSelector';
import ImportJSONButton from '../components/common/ImportJSONButton';
import { Icon } from '../components/common/Icon';

function MetadataStats({ metadata }) {
  let nonNull = _.compact(metadata);
  let counts = {'No tags': _.filter(nonNull, ({tags}) => !tags?.length).length};
  _.each(nonNull, ({ tags }) => _.each(tags, tag => counts[tag] = (counts[tag] || 0) + 1));

  return <table className={'table table-sm zoom-75 border table-striped'}>
    <tbody>
    {_.map(_.sortBy(_.toPairs(counts), '1'), ([tag, count]) => <tr key={tag}>
        <td>{tag}</td>
        <td className={'text-info text-right'}>{count}</td>
        <td className={'text-secondary text-right pr-5'}>{(count/nonNull.length*100).toFixed(1)}%</td>
      </tr>)
    }
    </tbody>
  </table>;
}

function SampleMetadataEditor({ sampleMetric, metadata, allMetadata, onChange }) {
  if (!metadata) {
    metadata = {};
  }

  let tags = metadata.tags || [];

  const toggleTag = tag => {
    metadata.tags = tags.includes(tag) ? _.without(tags, tag) : [...tags, tag];
    onChange(metadata);
  };

  const addCustomTag = () => {
    let id = prompt('Enter new tag (can use emojis 😉)');
    if (id) {
      toggleTag(id);
    }
  };

  let possibleTags = _.filter(sampleMetric?.fields, f => f.type === 'tag');
  let usedTags = _.uniq(_.compact(_.flatMap(allMetadata, 'tags')));
  possibleTags.push(... _.difference(usedTags, _.map(possibleTags, 'id')).map(id => ({ id, label: id })));

  return <div className={'inline-block text-left'}>
    {_.map(possibleTags, ({ id, label, type }) => {
      let stateClass = tags.includes(id) ? 'btn-primary' : 'btn-outline-secondary';
      return <div className={'text-center m-1'} key={id}>
        <span className={'btn btn-block btn-sm ' + stateClass} onClick={() => toggleTag(id)}>{label}</span>
      </div>;
    })
    }

    <div className={'text-center'}>
      <IconButton icon={'add_box'} onClick={addCustomTag}>Add custom tag</IconButton>
    </div>
  </div>;
}

export default class SitePagesQualitativeSample extends LivePage {
  constructor(props) {
    super(props);

    this.fullScreen = true;

    this.state.rawUrls = [];
    this.state.urls = [];
    this.state.sampleData = { at: 0, urlsMetadata: [] };
    this.state.sampleMetric = metricOptions[0];
    this.state.loaded = {};
    if(document.location.hostname === 'localhost') {
      this.state.domain = 'http://localhost:3000';
    } else {
      this.state.domain = 'https://opinautos.com';
    }

    let urlSampleId = this.getUrlParam('urlSampleId');
    if (urlSampleId) {
      this.state.urlSampleId = urlSampleId;
    }

    this.shortcuts.registerKeyMappings({
      'prevPage': ['alt+left'],
      'nextPage': ['alt+right'],
    });

    this.shortcuts.registerActionHandlers({
      'prevPage': this.previous.bind(this),
      'nextPage': this.next.bind(this),
    });

    this.scripts = this.service('/services/misc/notrack-urls');
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.state.urlSampleId) {
      this.loadSampleFromId(this.state.urlSampleId);
    } /* else { // For development
      this.loadData(testUrls);
    }*/
  }

  loadSampleFromId(urlSampleId) {
    let jsonStr = localStorage.getItem('urlSample' + urlSampleId);

    if (!jsonStr) {
      return alert(`Could not find data for urlSample ${urlSampleId} in localStorage.`);
    }

    try {
      let { rawUrls, sampleData } = JSON.parse(jsonStr);
      this.setState({ rawUrls, sampleData, loaded: {}, urls: [] }, () => this.fetchNoTrackingUrls().catch(alert));
    } catch (err) {
      alert(err);
    }
  }

  saveSampleState() {
    let { rawUrls, sampleData } = this.state;
    localStorage.setItem('urlSample' + this.state.urlSampleId, JSON.stringify({ rawUrls, sampleData }));
  }

  async fetchNoTrackingUrls() {
    let noTrackUrls = await this.scripts.find({ query: { urls: this.state.rawUrls } });
    this.setState({ urls: noTrackUrls });
  }

  async runScript(scriptName, params) {
    if (!params) {
      params = {};
    }

    await this.scripts.create({ scriptName, parameters: params });
  }

  async cancelScript(runId) {
    await this.scripts.remove(runId);
  }

  loadData(json) {
    if (_.isArray(json) && json.length && _.every(json, obj => _.isString(obj))) {
      let slice = prompt(`JSON has ${json.length} urls, keep only the first: `, '100');

      if (slice && slice.match(/\d+/)) {
        json = json.slice(0, parseInt(slice));
      }

      this.state.urlSampleId = new Date().toISOString();
      this.setUrlParam('urlSampleId', this.state.urlSampleId);

      this.setState({ rawUrls: json, urls: [], sampleData: {
        at: 0,
          urlsMetadata: [_.clone(this.state.sampleMetric.default)] } }, () => {
        this.saveSampleState();
        this.fetchNoTrackingUrls().catch(alert);
      });
    } else {
      alert('Invalid JSON format. Must be an array of string urls');
    }
  }

  setMetric(sampleMetric) {
    this.setState({ sampleMetric });
  }

  previous() {
    let { sampleData, urls } = this.state;
    if (this.state.sampleData.at > 0) {
      sampleData.at--;
      this.setState({ sampleData });
      this.saveSampleState();
    }
  }

  next() {
    let { sampleData, urls } = this.state;
    if (this.state.sampleData.at < this.state.urls.length - 1) {
      sampleData.at++;
      if (!sampleData.urlsMetadata[sampleData.at]) {
        sampleData.urlsMetadata[sampleData.at] = _.clone(this.state.sampleMetric.default);
      }
      this.setState({ sampleData });
      this.saveSampleState();
    }
  }

  updateMetadata(urlIndex, metadata) {
    let { sampleData } = this.state;
    sampleData.urlsMetadata = sampleData.urlsMetadata || [];
    sampleData.urlsMetadata[urlIndex] = metadata;
    this.setState({ sampleData });
  }

  renderPageBody() {
    let { urls, domain, sampleData, sampleMetric, urlSampleId, loaded } = this.state;

    let { at, urlsMetadata } = sampleData;

    let halfWindow = 2;
    let windowSize = halfWindow * 2 + 1;

    let from = Math.max(at - halfWindow, 0);
    let to = Math.min(at + halfWindow + 1, urls.length);
    if (from === 0) {
      to = Math.min(from + windowSize, urls.length);
    } else if (to === urls.length) {
      from = Math.max(0, to - windowSize);
    }

    let urlsWindow = urls.map((u, i) => [u, i]).slice(from, to);
    let current = urlsWindow.find(([, i]) => i === at)?.[0];
    let currentRaw = this.state.rawUrls[at];

    return <GridLayoutLeftBar className={'bg-light'}>
      <div style={{ width: '400px' }} className={'p-0'}>
        <div className={'text-center'}>
          <div className={'bg-dark p-2 m-2 rounded'}>
            <ImportJSONButton onChange={(data) => this.loadData(data)}>Load JSON with urls</ImportJSONButton>
            {/*<PageSampleMetricSelector value={sampleMetric} onChange={(v) => this.setMetric(v)}/>*/}
          </div>

          <div className={'p-2 mb-4'}>
            <div className={'monospace mb-1'}>Session {urlSampleId}</div>
            <div className={'h3 mb-4 text-secondary'}>{at + 1} / {urls.length} urls <span
              className={'ml-3 badge badge-secondary'}>{((at + 1) / urls.length * 100).toFixed(1)}%</span></div>
            <div>
              <IconButton icon={'arrow_back'} level={'primary'} onClick={() => this.previous()}/>
              {
                _.map(urlsWindow, ([uri, i]) => {
                  let color = i === at ? 'badge-primary' : 'badge-dark';
                  let outline = loaded[i] ? '' : 'badge-outline';
                  return <span key={i} className={`badge px-1 py-2 mx-1 ${color} ${outline}`}
                               style={{ minWidth: '55px' }}>
                          {i + 1}
                  </span>;
                })
              }
              <IconButton icon={'arrow_forward'} level={'primary'} onClick={() => this.next()}/>
            </div>
          </div>

          <div className={'bg-light-secondary p-2'}>
            <SampleMetadataEditor sampleMetric={sampleMetric} metadata={(urlsMetadata || [])[at]}
                                  allMetadata={urlsMetadata} onChange={(v) => this.updateMetadata(at, v)}/>

            <MetadataStats metadata={urlsMetadata}/>
          </div>
        </div>
      </div>

      <div className={'p-0 bg-dark position-absolute h-100 w-100 p-2'}>
        <div className={'h5 bg-light mb-2 p-2 px-4 rounded'}>
          {domain}<a href={domain + current} target={'_blank'}>{currentRaw} <Icon icon={'link'}/></a>
        </div>
        {_.map(urlsWindow, ([uri, i]) => {
          if (!loaded[at] && i !== at) {
            return null;
          }

          let src = domain + uri;
          let style = {
            overflowY: 'scroll',
            display: i === at ? 'block' : 'none',
            height: 'calc(100% - 50px)'
          };
          const onLoad = () => this.setState({ loaded: { ...this.state.loaded, [i]: true } });
          return <iframe key={src + (i)} style={style} className={'position-relative w-100'} src={src}
                         onLoad={onLoad}/>;
        })}
      </div>
    </GridLayoutLeftBar>;
  }
}

const testUrls = [
  '/do/hyundai/tucson/defectos/aire-acondicionado',
  '/ar/volkswagen/gol-power/defectos/prende-y-se-apaga',
  '/mx/ford/transit/defectos/da-marcha-no-arranca',
  '/ford/focus/temas/daca7aa65a/humo-negro-al-acelerar',
  '/es/mini/cooper/defectos/control-remoto',
  '/us/kia/sorento/defectos/se-acelera'
];
