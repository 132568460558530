import React, { useContext } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { DataIssuesFinder } from './DataIssuesFinder';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import { IconButton } from '../common/IconButton';

export default function DataIssueRefsEditor({ value, onChange, vehicle }) {
  const { page } = useContext(LegoAdminPageContext);

  const onClick = () => {
    page.openModal(<div className={'position-relative border-top'} style={{ margin: '-0.5em', minHeight: '500px' }}>
      <DataIssuesFinder vehicleContext={vehicle} onRowClick={(issue) => {
        page.closeModal();
        onChange(_.uniq([...(value || []), issue._id]));
      }}/>
    </div>, { title: 'Pick a data issue' });
  };

  return <LeftRightLayout>
    <span>
      {_.map(value, issueId => <span key={issueId} className={''}>
        <span className={'badge badge-secondary'}>{issueId}</span>
          <IconButton level={'danger'} icon={'clear'} size={'sm'} onClick={() => onChange(_.without(value, issueId))}/>
        </span>
      )}
    </span>
    <IconButton icon={'manage_search'} onClick={onClick}/>
  </LeftRightLayout>;
}
