import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LegosManager from './LegosManager';

dayjs.extend(relativeTime)

export default class DeletedLegosManager extends LegosManager {
  constructor(props) {
    super(props);

    this.views = this.service('services/legos-deleted');

    this.state.columnSort = {asc: false, sorter: 'deletedAt'}
  }

  buildObjectQuery() {
    let res = super.buildObjectQuery();
    res.$sort = [['deletedAt', -1]];
    return res;
  }
}
