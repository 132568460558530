export default {
  requestInfo: {
    templatePath: 'datafeedback/fusebox/requestInfo.pug',
    i18nSubjectKey: 'emails.campaigns.dataFeedback.fusebox.requestInfo.subject',
    parameters: [
      { name: 'userId', type: 'userId' },
      { name: 'modelId', type: 'modelId' },
      { name: 'year', type: 'string' },
    ],
  },
  requestInfoSuggestFusebox: {
    templatePath: 'datafeedback/fusebox/requestInfoSuggestFusebox.pug',
    i18nSubjectKey: 'emails.campaigns.dataFeedback.fusebox.requestInfoSuggest.subject',
    parameters: [
      { name: 'userId', type: 'userId' },
      { name: 'url', type: 'siteUrl' },
      { name: 'modelId', type: 'modelId' },
      { name: 'year', type: 'string' },
    ],
  },
  alreadyHaveFusebox: {
    templatePath: 'datafeedback/fusebox/alreadyHaveFusebox.pug',
    i18nSubjectKey: 'emails.campaigns.dataFeedback.fusebox.requestInfo.subject',
    parameters: [
      { name: 'userId', type: 'userId' },
      { name: 'url', type: 'siteUrl' },
      { name: 'modelId', type: 'modelId' },
      { name: 'year', type: 'string' },
    ],
  },
  weFoundFusebox: {
    templatePath: 'datafeedback/fusebox/weFoundFusebox.pug',
    i18nSubjectKey: 'emails.campaigns.dataFeedback.fusebox.weFoundFusebox.subject',
    parameters: [
      { name: 'userId', type: 'userId' },
      { name: 'fuseboxUrl', type: 'siteUrl' },
      { name: 'modelId', type: 'modelId' },
      { name: 'year', type: 'string' },
    ],
  },
  customMessage: {
    templatePath: 'datafeedback/custom-message.pug',
    i18nSubjectKey: 'emails.campaigns.dataFeedback.customSubject',
    parameters: [
      { name: 'userId', type: 'userId' },
      { name: 'subjectHtml', type: 'string' },
      { name: 'bodyHtml', type: 'string' },
    ],
  },
}
