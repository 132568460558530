import React, { Component } from 'react';

export class LoadingOverlay extends Component {
  render() {
    if(this.props.isLoading) {
      return <div className={'text-secondary LoadingOverlay'}>
        <i className={'material-icons animate-rotating'}>refresh</i>
        <span className={'ml-4'}>&nbsp;{this.props.loadingMessage}</span>
      </div>;
    } else {
      return null;
    }
  }
}
