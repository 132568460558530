import React from 'react';
import { MailConversationsList } from '../components/common/MailConversationsList';
import TopBarLayout from '../components/common/layout/TopBarLayout';
import LivePage from './LivePage';
import submenuEmails from './menus/submenu-emails';
import _ from 'lodash';
import FiltersBar from '../components/common/filters-bar/FiltersBar';
import MultiButtonSwitch from '../components/common/editors/MultiButtonSwitch';
import InputUserId from '../components/common/InputUserId';
import { IconButton } from '../components/common/IconButton';

export default class UserConversationsViewer extends LivePage {
  constructor(... params) {
    super(... params);

    this.state.userId = this.getUrlParam('userId') || '';
    this.state.mailType = this.getUrlParam('mailType') || 'all';

    this.fullScreen = true;
    this.submenu = submenuEmails;

    this.conversations = this.service('services/data/emails/conversations');
  }

  async queryConversations() {
    const { mailType } = this.state;

    const lastIncomingMails = {
      $sort: '-date',
      $limit: 100,
      $select: {threadId: 1, date: 1},
      ... (mailType !== 'all' ? {type: mailType} : {})
    }

    const messages = await this.conversations.find({query: lastIncomingMails})
    this.setState({threadIds: _.uniq(_.map(messages, 'threadId'))})
  }

  componentDidMount() {
    super.componentDidMount();
    this.queryConversations().catch(alert);
  }

  updateFilter(field, value) {
    this.setState({[field]: value}, () => this.queryConversations());

    if (value !== undefined && !_.isEmpty(value)) {
      this.setUrlParam(field, value);
    } else {
      this.deleteUrlParam(field);
    }
  }

  renderPageBody() {
    let {userId, threadIds, mailType} = this.state;

    const filter = userId ? {userId} : {threadIds};

    const onUserIdChange = id => this.updateFilter('userId',id);
    const onMailTypeChange = type => this.updateFilter('mailType', type);

    return <TopBarLayout>
      <FiltersBar>
        <MultiButtonSwitch value={mailType} onChange={onMailTypeChange} options={['sent', 'received', 'all']}/>

        <InputUserId className={'ml-3 w-75'} isClearable={true} value={userId} onChange={id => onUserIdChange(id)} isMulti/>

        <IconButton className={'ml-3'} icon={'create'} onClick={() => this.setUrlParam('editing', true)} title={'Create new email'}/>
      </FiltersBar>

      <MailConversationsList {... filter}/>
    </TopBarLayout>
  }
}
