import React from 'react';

import LiveSubpage from '../../pages/LiveSubpage';
import EventEmitter from 'events';
import ClusterSemanticsAssign from '../images/ClusterSemanticsAssign';

export default class TaskEditorWarningLightsAssignSemantics extends LiveSubpage {
  constructor(props) {
    super(props);

    this.modalActionsBus = new EventEmitter();

    this.state = {
      ...this.state,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  renderPageBody() {
    let { error } = this.state;

    let { state, input: { id } } = this.props.task || {};

    const onApprove = async () => {
      await this.props.batchEditor.doneAndNext();
    };

    const isComplete = state === 'complete';

    return <React.Fragment>
      <div className={'grid-pdf-extraction'}>
        <div className={'pos-relative bg-secondary'}>
          {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

          <div>
            <ClusterSemanticsAssign clusterId={id} modalActionsBus={this.modalActionsBus}/>
          </div>
        </div>

        <div className={'bg-white'} id={'extractionInputArea'} key={id}>
          <div className={'p-2 small text-center'}>
            <span className={`btn btn-${isComplete ? 'primary' : 'success'}`}
                  onClick={async () => this.runAsync(onApprove)}>
              {isComplete ? 'Resave and next' : 'Done and next'}
            </span>
          </div>

          <div className={'border border-left-0 border-right-0'}>
            {this.props.batchEditor.getTaskStateControls()}
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}
