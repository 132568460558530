import React, { useState, useContext } from 'react';
import { TypeTag } from '../lego/TypeTag';
import { SearchFilterButton } from '../common/SearchFilterButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import _ from 'lodash';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { DataIssuesStatistics } from './DataIssuesStatistics';
import LegoLabel from '../lego/LegoLabel';
import { PotentiallySolvedDataIssuesAssistant } from './PotentiallySolvedDataIssuesAssistant';
import MultiButtonSwitch from '../common/editors/MultiButtonSwitch';
import { IconButton } from '../common/IconButton';
import LegoLabelsSelector from '../lego/LegoLabelsSelector';

export function DataIssuesFiltersBar({onChange, filter}) {
  const context = useContext(LegoAdminPageContext);

  const fieldChange = (field, value) => {
    let newFilter = _.cloneDeep(filter || {});
    if (field === 'dataSelector.vehicle') {
      if (_.isEmpty(value)) {
        delete newFilter['dataSelector.vehicle'];
      } else {
        newFilter['dataSelector.vehicle'] = _.cloneDeep(value);

        if (newFilter.top100) {
          delete newFilter.top100;
        }
      }
    } else {
      newFilter[field] = value;

      if (!newFilter[field] || _.isEqual(newFilter[field], {})) {
        delete newFilter[field];
      }

      if (newFilter.top100 && newFilter['dataSelector.vehicle']) {
        delete newFilter['dataSelector.vehicle'``];
      }
    }
    onChange(newFilter);
  };

  const openStatistics = () => {
    context.page.openModal(<DataIssuesStatistics />, { title: 'Fusebox data feedback statistics', minSize: false });
  };

  const openCloseIssues = () => {
    context.page.openModal(<PotentiallySolvedDataIssuesAssistant />, { title: 'Close solved data issues assistant', fullScreen: true, noPadding: true });
  };

  const superviseIssues = (ids = null) => {
    ids = ids || prompt('Enter list of cluster ids as one string separated by spaces or commas') || '6166086dd3eb0a0004193bfc\n' +
      '62790986135b42e7897f4e32';
    if (ids) {
      let matches = ids.match(/[a-f\d]{24}/gi);

      if (matches?.length) {
        context.page.openModal(<PotentiallySolvedDataIssuesAssistant
          clusterIds={matches} />, { title: 'Close solved data issues assistant', fullScreen: true, noPadding: true });
      } else {
        alert('Invalid ids list: ' + ids);
      }
    }
  };

  const filterById = (ids) => {
    ids = ids || prompt('Enter list of cluster ids as one string separated by spaces or commas');
    if (ids) {
      let matches = _.isArray(ids) ? ids : ids.match(/[a-f\d]{24}/gi);

      if (matches?.length) {
        fieldChange('_id', {$in: matches});
      } else {
        alert('Invalid ids list: ' + ids);
      }
    }
  }

  const f = filter || {};
  let { isOpen, labels, top100, groupByCluster, _id } = f;

  const negLabelChange = (ls) => {
    let {$ne, $nin, ... rest} = labels || {};
    let newLabels = ls.length ? (ls.length === 1 ? { ... rest, $ne: ls[0] } : { ... rest, $nin: ls }) : rest;
    fieldChange('labels', newLabels);
  };

  const posLabelChange = (ls) => {
    let {$all, ... rest} = labels || {};
    let newLabels = ls.length ? {... rest, $all: ls } : rest;
    fieldChange('labels', newLabels);
  };

  let labelsToSkip = ['difficult:hard', 'freelancer:done', 'freelancer:assigned'].map(l => ({ value: l, label: <LegoLabel label={':'+l.split(':')[1]}/> }));
  let negLabels = labels ? (labels.$ne ? [labels.$ne] : (labels.$nin ? labels.$nin : [])) : [];
  let inLabels = labels?.$all ? labels.$all  : [];
  return (
    <div className={'d-flex align-items-center py-1'}>
      <div>
        <div style={{ width: '335px', display: 'inline-block' }} key={'labels'} className={'zoom-75 mr-3 align-middle text-left'}>
          <LegoLabelsSelector placeholder={'Labels...'} value={inLabels} onChange={posLabelChange}/>
      </div>

        <div>
          <MultiButtonSwitch value={negLabels} multi={true} label={'Skip:'} defaultMulti onChange={negLabelChange} options={labelsToSkip}/>
        </div>
      </div>

      <div className={'ml-2 text-center'}>
        <IconButton icon={'data_object'} onClick={() => filterById()} title={'Filter by data issue _id...'}>By ID...</IconButton>

        {_id ?  <div>
          <span className={'badge badge-info'}>Filtering {_id['$in'].length} ids</span>
          <IconButton icon={'clear'} onClick={() => fieldChange('_id', undefined)}/>
        </div> : null}
      </div>

      <div>
        <div>

          <span style={{ width: '370px', display: 'inline-block' }} className={'zoom-75 ml-3 mr-3'}>
            <ModelContextEditor value={f['dataSelector.vehicle']} onChange={(newContext) => fieldChange('dataSelector.vehicle', newContext)} />
          </span>
        </div>

        <div className={'text-center'}>
          <SearchFilterButton onChange={top100 => fieldChange('top100', top100)} value={top100}>
            <TypeTag type={'Get top 100'} />
          </SearchFilterButton>&nbsp;

          <SearchFilterButton onChange={isOn => fieldChange('isOpen', isOn)} value={isOpen}>
            <TypeTag type={'Only open'} />
          </SearchFilterButton>

          <span className={'ml-2'}>
            <SearchFilterButton onChange={isOn => fieldChange('groupByCluster', isOn)} value={groupByCluster}>
              <TypeTag type={'Group by clusterId'} />
            </SearchFilterButton>
          </span>
        </div>
      </div>



      <IconButton icon={'published_with_changes'} onClick={() => openCloseIssues()} title={'Close solved data issues...'}>Solve issues</IconButton>

      <IconButton icon={'checklist'} onClick={() => superviseIssues()} title={'Check data issues clusters...'}>Check issues</IconButton>

      <IconButton icon={'poll'} onClick={openStatistics} title={'Statistics...'}>Stats</IconButton>
    </div>
  );
}
