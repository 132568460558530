import _ from "lodash";
import StatsDataSource from "./StatsDataSource";

export default class StatsTable {
  constructor(title, slug) {
    this.data = new StatsDataSource();
    this._slug = slug;
    this._title = title;

    this.displayRow = this.displayRow.bind(this);
  }

  async fetchStats() {
    await this.fetchData()

    this.groupsByKey = {}
    for(const g of (this.groups() || [])) {
      this.groupsByKey[this.groupKey(g)] = g
    }

    const rawGroupsWeight = _.mapValues(this.groupsByKey, (g,key) => this.groupWeight(key,g))
    // Normalize weights in 0-1 scale
    const totalWeight = _.sum(_.values(rawGroupsWeight));

    this.groupsWeight = {
      shares: _.mapValues(rawGroupsWeight, w => w/totalWeight)
    }

    this.groupsCoverage = {
      portions: _.mapValues(this.groupsByKey, (g, key) => this.groupCoverage(key, g))
    }
  }

  searchFilters() {
    return {
    }
  }

  get title() {
    return this._title;
  }

  get slug() {
    return this._slug;
  }

  dataCoverage() {
    return this.groupsCoverage
  }

  coverageMetric() {
    return this.groupsWeight
  }

  async fetchData() {
    // Can be overriden by subclasses
  }

  groupKey(group) {
    // Can be overriden by subclasses
    if(_.isString(group)) {
      return group;
    } else {
      return JSON.stringify(group);
    }
  }

  groups() {
    throw Error('Must be overriden')
  }

  groupWeight(key, group) {
    throw Error('Must be overriden')
  }

  groupCoverage(key, group) {
    throw Error('Must be overriden')
  }

  displayRow(key) {
    return this.displayGroup(key, this.groupsByKey[key]);
  }

  displayGroup(key, group) {
    // Can be overriden by subclasses
    return key;
  }
}