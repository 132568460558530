import React from 'react';
import LegoAdminPageContext from './../../pages/legoAdminPageContext';
import ImgCache from './GlobalImageCache';
import HotKeys from 'react-hotkeys/es/HotKeys';
import { ShortcutsManager } from '../common/ShortcutsManager';

export default class PDFPagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clippingEnabled: false,
      imageLoaded: false,
      zoom: this.props.zoom || 1,
    };

    this.containerRef = React.createRef();

    // To add and remove as handler
    this.calculateWindowSize = this.calculateWindowSize.bind(this);
  }

  zoomIn() {
    this.setState({ zoom: this.state.zoom + (this.state.zoom >= 1 ? 0.2 : 0.1) });
  }

  zoomOut() {
    this.setState({ zoom: this.state.zoom - (this.state.zoom > 1 ? 0.2 : 0.1) });
  }

  zoomReset() {
    this.setState({ zoom: 1 });
  }

  loadImage(imgSrc) {
    ImgCache.load(imgSrc).then((img) => {
      this.setState({ imageLoaded: true, height: img.naturalHeight, width: img.naturalWidth });
    });
  }

  componentDidMount() {
    this.loadImage(this.props.pageImage);


    window.addEventListener('resize', this.calculateWindowSize);
    this.calculateWindowSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateWindowSize);
  }

  calculateWindowSize() {
    if (this.containerRef.current) {
      let $container = $(this.containerRef.current);
      let h = $container.parent().height();
      let w = $container.parent().width();
      this.setState({ availableWidth: w, availableHeight: h });
      $container.focus();
    }
  }

  render() {
    const { pageImage } = this.props;
    const { imageLoaded, availableWidth, availableHeight, height, width, zoom } = this.state;

    if (!imageLoaded) {
      return <div ref={this.containerRef}
                  className={'text-center h-100 w-100 d-flex justify-content-center align-items-center'}>Loading...</div>;
    }

    // Fit page to available space
    let zoomHeight = height;
    let zoomWidth = width;

    let scaledZoom = 1;

    if (availableWidth) {
      if ((availableHeight / zoomHeight) < (availableWidth / zoomWidth)) {
        scaledZoom = availableHeight / zoomHeight;
      } else {
        scaledZoom = availableWidth / zoomWidth;
      }
    }

    scaledZoom = scaledZoom * zoom;

    let pageStyle = {};
    pageStyle.height = `${height * scaledZoom}px`;
    pageStyle.width = `${width * scaledZoom}px`;
    pageStyle.backgroundImage = `url(${pageImage})`;

    const clippingPageStyle = {
      maxHeight: `${availableHeight - 8}px`,
      maxWidth: `${availableWidth}px`
    };

    const zooming = zoom === 1 ? '' : 'clipping-page--zooming';

    return <div ref={this.containerRef} tabIndex={0} style={clippingPageStyle}
           className={`clipping-page ${zooming}`}>

        <div className={`pdf-page shadow pdf-page--image`} style={pageStyle}>
          {this.props.children || null}
        </div>
      </div>;
  }
}

PDFPagePreview.contextType = LegoAdminPageContext;
