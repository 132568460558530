import React, {useState, useEffect} from 'react';

export function useNotes(notesManager, key) {
  // Try to get cache synchronously to avoid preventable flashes in the UI
  const [notes, setNotes] = useState(notesManager.cacheLoaded() ? notesManager.getCached(key) : undefined);

  useEffect(() => {
    // Done this way as doing it async every time has a HUGE overhead in performance
    if (notesManager.cacheLoaded()) {
      setNotes(notesManager.getCached(key));
    } else {
      notesManager.get(key).then(notes => setNotes(notes));
    }

    // Unlike 'setState', setNotes via useState does not re-render if the object does not change
    // that means 'notes' array has to be a new array every time
    const onNoteEventHandler = (notes) => setNotes(notes);

    notesManager.addListener(key, onNoteEventHandler);

    return () => notesManager.removeListener(key, onNoteEventHandler)
  }, [key, notesManager])

  return notes;
}
