import React, {Component} from "react";
import _ from 'lodash';

import {IconButton} from "../../common/IconButton";
import { SingleImageUrlEditor } from '../../common/images/SingleImageUrlEditor';
import { MultipleImagesEditor } from '../../common/editors/MultipleImagesEditor';
import MultipleItemsEditor from '../MultipleItemsEditor';
import TrimSpecsEditor from '../trims/TrimSpecsEditor';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import { ExtractedTextEditor } from '../../common/editors/ExtractedTextEditor';

export class FuseboxPdfExtractionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
    }
  }

  getContextIdentifier() {
    return this.props.context && this.props.context.length ? _.values(this.props.context[0]).join('-').toLowerCase() : null;
  }

  editField(fuseboxIndex, field, newValue) {
    let lego = _.cloneDeep(this.props.value);


    if(!lego.data[fuseboxIndex]) {
      lego.data.push({fuses: []});
    }

    lego.data[fuseboxIndex][field] = newValue;

    // Remove null fields in data
    // lego.data = _.pickBy(lego.data, v => v);

    this.props.onChange(lego);
  }

  updateContext(newContext) {
    if (newContext.length > 0) {
      let lego = _.cloneDeep(this.props.value);
      lego.context = newContext;
      this.props.onChange(lego);
    }
  }

  render() {
    const { context, data, _id } = this.props.value;

    let header = null;
    let stateBg = 'success'
    if(_id) {
      header = <a target={'_blank'} className={'small '} href={`/legos/fusebox?editing="${_id}"`}>Fusebox {_id} <IconButton icon={''}/></a>
      stateBg = 'secondary'
    }

    let contexts = _.isArray(context) ? context : [context];

    let defaultNewContext = {};
    if (contexts[0] && contexts[0].modelId && contexts[0].year) {
      defaultNewContext = { modelId: contexts[0].modelId, year: contexts[0].year };
    }

    const addFusebox = () => {
      this.editField(this.props.value.data.length,'fuses', [])
    };

    const delFusebox = (fuseboxIndex) => {
      const lego = _.cloneDeep(this.props.value);
      lego.data.splice(fuseboxIndex, 1);
      this.props.onChange(lego);
    };

    const contextEditor = <MultipleItemsEditor value={contexts}
                                               defaultNewItem={defaultNewContext}
                                               buttonsAlwaysVisible={true}
                                               renderComponent={(item, props) => <TrimSpecsEditor
                                               value={item} {...props}/>}
                                               onChange={(specs) => this.updateContext(specs)}
    />;

    const fuseboxes = _.map(data, ({ boxAspectRatio, boxDiagramImg, boxTablesImages, boxDescription, boxName },i) => {
      return <div className={'bg-white rounded px-1 mb-1'} key={i}>
        <strong>Fusebox #{i+1}</strong><IconButton icon={'delete'} level={'secondary'} onClick={() => delFusebox(i)}/>
        <ExtractedTextEditor placeholder={"Fusebox location"}
                             value={boxName}
                             className={'mb-1 zoom-90'}
                             onChange={txt => this.editField(i, 'boxName', txt)}/>

        <ExtractedTextEditor placeholder={"Fusebox description"}
                             value={boxDescription}
                             className={'mb-1 zoom-90'}
                             onChange={txt => this.editField(i, 'boxDescription', txt)}/>

        <SingleImageUrlEditor forceJPG={true} value={boxDiagramImg}
                              defaultName={this.getContextIdentifier()}
                              manualId={this.props.manualId}
                              manualPdfUrl={this.props.manualPdfUrl}
                              placeholder={'DIAGRAMA FUSIBLES'}
                              folder={'fusebox-diagrams'}
                              category={'fusebox-diagram'}
                              onChange={img => this.editField(i,'boxDiagramImg', img)}/>

        <MultipleImagesEditor value={boxTablesImages || []}
                              defaultName={this.getContextIdentifier()}
                              manualId={this.props.manualId}
                              manualPdfUrl={this.props.manualPdfUrl}
                              placeholder={'TABLAS'}
                              folder={'fusebox-tables'}
                              category={'fusebox-table'}
                              chain={true}
                              onChange={(images) => this.editField(i,'boxTablesImages', images)}/>
      </div>;
    });

    return <div className={`rounded p-1 bg-light-${stateBg} zoom-90`}>
      {header}

      {contextEditor}

      {fuseboxes}
      <IconButton icon={'add'} level={'success'} onClick={addFusebox}>Add Fusebox #{fuseboxes.length+1}</IconButton>
    </div>;
  }
}

FuseboxPdfExtractionEditor.contextType = LegoAdminPageContext;
