import React, {Component} from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import CRUDPage from './CRUDPage';
import LegoContextSummary from "../components/lego/LegoContextSummary";
import {ManualsFiltersBar} from "../components/common/ManualsFiltersBar";
import {Icon} from "../components/common/Icon";
import submenuManuals from "./menus/submenu-crawling";
import CountryFlag from "../components/common/CountryFlag";
import MongoDocEditor from "../components/lego/MongoDocEditor";
import {CrawlingFiltersBar} from "../components/crawling/CrawlingFiltersBar";
import {IconButton} from "../components/common/IconButton";

function getIntensityColorStyle(count, total = 20) {
  return {
    background: `${count ? `hsl(100deg 100% ${100 - Math.min(1, count/total)*50}%)` : 'gray'}`
  };
}

export default class CrawlingViewer extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/scrapping/cars');
    this.submenu = submenuManuals;

    this.state.summaryOpen = false;

    this.canDelete = false;
    this.canCreate = false;
    this.canEdit = false;
  }

  getFacetFiltersComponent() {
    return <CrawlingFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Source', className: '' },
      { content: 'Model', className: 'text-center' },
      { content: 'Year', className: '' },
      { content: 'Pic', className: '' },
      // {content: 'Country/Lang', className: ''},
      // {content: '', className: ''},
      { content: 'Details', className: '' },
    ]
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    if(this.state.facetFilters.make || this.state.facetFilters.idModelo) {
      await super.fetchObjects({ $sort: { year: -1 }, $limit: 5000});
    } else {
      await super.fetchObjects({ $sort: { uploadDate: -1}, $limit: 100 });
    }
  }

  zoomImage(imgUrl) {
    this.modalActionsBus.emit('open', <div className={'text-center'}>
      <img src={imgUrl}/>
    </div>, {});
  }

  getObjectColumns(manual, definition) {
    let { _id, itemUrl, title, year, km, currency, price, imgUrl, make, model, page, country, crawl_id} = manual;

    // let viewStateClass = 'success' : 'warning';

    return [
      <td key={'source'} className={'context-column text-left'}>
        <div className={'text-secondary small'}>{_id}</div>
        <div>
          {/*<span className={'text-info small ml-2'}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>*/}
        </div>
        <div title={crawl_id}>{page} <CountryFlag country={country}/></div>
      </td>,

      <td key={'ctx'} className={'context-column text-left'}>
        <LegoContextSummary context={[{ modelId: `${make}-${model}` }]}/>
      </td>,

      <td key={'year'}>
        {year}
      </td>,

      <td key={'cover'} style={{ width: '200px' }} className={'text-center'}>
        {imgUrl ? <img src={imgUrl} className={'img-zoom-btn'} style={{ height: '100px' }} alt={_id} title={_id}
                       onClick={() => this.zoomImage(imgUrl)}/> : null}
      </td>,

      <td key={'keys'}>
        <h6 className={''}>{title}</h6>

        <div>
          <span className={'badge badge-info'}>{currency} <strong>{price}</strong></span>

          <em className={'text-secondary'}> {km}</em>

          <a href={itemUrl} target={'_blank'} className={'ml-2 small'}>
            Source
          </a>
        </div>
      </td>,

    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <MongoDocEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }

  getResultsSummary(cars, Allcars) {
    const open = this.state.summaryOpen;

    const yearCounts = _(cars).groupBy('year').mapValues(g => g.length).toPairs().sortBy('0').value();
    const maxCount = _.max(_.map(yearCounts, '1')) || 1;

    const byYearSummary = <div className={'d-flex align-items-center'}>
      <IconButton icon={open ? 'expand_less' : 'expand_more'} level={'primary'}
                  onClick={() => this.setState({ summaryOpen: !open })}>
        Distribución por palabra
      </IconButton>

      {
        _.map(yearCounts, ([year, count]) => <div key={year} style={getIntensityColorStyle(count, maxCount)}
                                                  className={'m-05 p-1 rounded text-center small inline-block shadow-sm'}>
          <div className={'small text-secondary'}>{year}</div>
          <div className={'text-primary'}>{count}</div>
        </div>)
      }
    </div>;

    let table = null;

    if (open) {
      let titleTransform = (title) => {
        return title
          // .replace(/\b(\d+ ?cv)\b/ig, '')
          .replace(/\b(por( \w+){1,3}$)\b/ig, '')
          .replace(/\b(\d+) (p|puertas?|ptas?\.?)\b/ig, '$1p')
          .replace(/\b(\d+) (cv|hp)\b/ig, '$1cv')
          ;
        // return title.match(/\b\d\.\d\b/) || 'SIN MOTOR'
      }

      let topTokens = {};
      for (const c of cars) {
        for (const w of titleTransform(c.title.toLowerCase()).split(/\s+/)) {
          if (w.trim()) {
            (topTokens[w] = topTokens[w] || []).push(c);
          }
        }
      }

      let years = _.uniq(_.map(Allcars, 'year')).sort();
      // let byTitle = _.sortBy(_.toPairs(topTokens), ([token,g]) => _.min(_.map(g, 'year')));
      let byTitle = _.sortBy(_.toPairs(topTokens), ([token, g]) => -g.length);
      byTitle = _.sortBy(_.filter(byTitle, p => p[1].length > 1), ([token, g]) => _.min(_.map(g, 'year')))

      let timelineRows = _.map(byTitle.slice(0, 150), ([title, cars]) => {
        const cols = _.map(years, y => {
          const yearCars = _.filter(cars, { year: y });
          const count = yearCars.length;
          const color = getIntensityColorStyle(count, 20)
          return <td key={y} style={color} title={_.map(yearCars, 'title').join('\n')}>{count || ''}</td>;
        })

        return <tr key={title}>
          <th className={'text-left pl-2'}>{title}</th>
          {cols}
        </tr>
      });

      table = <table className={'TimelineTable mt-1'}>
        <thead>
        <tr>
          <th>Version</th>
          {_.map(years, y => <th key={y}>{y}</th>)}
        </tr>
        </thead>
        <tbody>
        {timelineRows}
        </tbody>
      </table>
    }

    return <div className={'bg-light p-2'}>
      {byYearSummary}
      {table}
    </div>
  }
}
