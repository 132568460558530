import React from 'react';
import _ from 'lodash';
import HighlightedText from '../common/HighlightedText';
import { IconButton } from '../common/IconButton';
import LegoAdminPageContext from './../../pages/legoAdminPageContext';
import { BoxSelectionOverlay } from './BoxSelectionOverlay';
import ImgCache from './GlobalImageCache';

const colors = ["red", "blue", "green", "purple", "black", "gray", "magenta"]

const globalLoadedImagesCache = {};

export default class PDFPageTextPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clippingEnabled: false,
      hasImage: ImgCache.has(props.pageImage) || !props.lazyPageImage
    };

    this.containerRef = React.createRef();

    // To add and remove as handler
    this.calculateWindowSize = this.calculateWindowSize.bind(this)
    this.actionPdfSelectBox = this.actionPdfSelectBox.bind(this);
    this.actionEndPdfSelectBox = this.actionEndPdfSelectBox.bind(this)
    this.actionCancelPdfSelectBox = this.actionCancelPdfSelectBox.bind(this)
    this.actionSelectTextBox = this.actionSelectTextBox.bind(this)
  }

  loadImage(imgSrc) {
    // Only start loading image after a while
    if(this.props.lazyPageImage) {
      ImgCache.load(imgSrc).then(() => this.setState({ hasImage: true }))
    }
  }

  componentDidMount() {
    if(this.props.pageImage) {
      this.loadImage(this.props.pageImage);
    }
    window.addEventListener("resize", this.calculateWindowSize);
    this.calculateWindowSize();

    this.context.page.onAction('pdfSelectBox', this.actionPdfSelectBox)
    this.context.page.onAction('endPdfSelectBox', this.actionEndPdfSelectBox)
    this.context.page.onAction('cancelPdfSelectBox', this.actionCancelPdfSelectBox)
    this.context.page.onAction('pdfSelectTextBox', this.actionSelectTextBox)
  }

  componentWillReceiveProps(newProps) {
    if(newProps.pageImage) {
      this.loadImage(newProps.pageImage);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loadImageTimeout);
    window.removeEventListener("resize", this.calculateWindowSize);
    this.context.page.offAction('pdfSelectBox', this.actionPdfSelectBox)
    this.context.page.offAction('endPdfSelectBox', this.actionEndPdfSelectBox)
    this.context.page.offAction('cancelPdfSelectBox', this.actionCancelPdfSelectBox)
    this.context.page.offAction('pdfSelectTextBox', this.actionSelectTextBox)
  }

  actionSelectTextBox({onSelected, onCancel, message}) {
    this.setState({selectingTextBox: true, selectingBoxMessage: message, onBoxSelected: onSelected, onCancel})
  }

  actionPdfSelectBox({onBoxSelected, onCancel, message}) {
    this.setState({selectingBox: true, selectingBoxMessage: message, onBoxSelected, onCancel})
  }

  actionEndPdfSelectBox() {
    this.setState({selectingBox: false, selectingTextBox: false, onBoxSelected: null, onCancel: null})
  }

  actionCancelPdfSelectBox() {
    this.state.onCancel && this.state.onCancel();
    this.setState({selectingBox: false, selectingTextBox: false, onBoxSelected: null, onCancel: null})
  }

  calculateWindowSize() {
    if(this.containerRef.current) {
      let $container = $(this.containerRef.current);
      let $window = $(window);
      if ($window.length) {
        let h = $window.outerHeight() - $container.offset().top;
        let w = $container.parent().width();

        this.setState({availableWidth: w, availableHeight: h});
      }
    }
  }

  getIntersectingText(boundingBox) {
    let overlayElement = $('.BoxSelectionOverlay__box', this.containerRef.current)[0];
    if(overlayElement) {
      let sel = overlayElement.getBoundingClientRect();

      let blocks = _.filter(this.props.page.pageItems, ({ textContent, normalizedText, style }, i) => {
        // let { left, top, height, width } = style;
        let $span = $('span.item-' + i, this.containerRef.current);
        if ($span.length) {
          let { left, top, width, height } = $span[0].getBoundingClientRect();
          if (!((top + height) < sel.top
            || (left + width) < sel.left
            || top > (sel.top + sel.height)
            || left > (sel.left + sel.width))) {
            return true;
          }
        }
      });
      return blocks;
    }
    return [];
  }

  render() {
    const { page, currentFilter, globalStyles, pageImage } = this.props;
    const { pageItems, styles, viewport } = page;
    const { hasImage, availableWidth, availableHeight, clippingEnabled} = this.state;

    // Check if there is a lot of space without text and crop the page to the text if necessary
    let minTop = _.min(_.map(pageItems, i => i.style.top));
    let minLeft = _.min(_.map(pageItems, i => i.style.left));
    let maxRight = _.max(_.map(pageItems, i => i.style.left + i.style.width));
    let maxBottom = _.max(_.map(pageItems, i => i.style.top + i.style.height));

    // Fit page to available space
    let zoomHeight = viewport.height;
    let zoomWidth = viewport.width;
    let clippingPage = false;
    let wouldClipButDisabled = false;

    let zoom = 1;
    const nonBlankAreaRatio = ((maxRight-minLeft)*(maxBottom - minTop))/(zoomWidth*zoomHeight);
    if (nonBlankAreaRatio < 0.7 && (nonBlankAreaRatio) > 0.25) {
      if(clippingEnabled) {
        clippingPage = true;
        // Adjust final width and height accordingly to compute zoom
        zoomHeight = viewport.height - minTop - (zoomHeight - maxBottom);
        zoomWidth = viewport.width - minLeft - (zoomWidth - maxRight);
      } else {
        wouldClipButDisabled = true;
      }
    }

    // Fit page to available space
    if(availableWidth) {
      //TODO: Mega hardcodeado margins y sólo funciona en ciertos layouts de página
      let h = availableHeight - 8 - (clippingPage ? 44 : 0);
      let w = availableWidth - 16 - (clippingPage ? 44 : 0);

      if ((h / zoomHeight) < (w / zoomWidth)) {
        zoom = h / zoomHeight
      } else {
        zoom = w / zoomWidth
      }
    }

    zoom = zoom*this.props.zoom;

    let pageStyle = {};
    pageStyle.height = `${viewport.height*zoom}px`;
    pageStyle.width = `${viewport.width*zoom}px`;
    if(clippingPage) {
      pageStyle.marginLeft =  `-${minLeft*zoom}px`;
      pageStyle.marginRight =  `-${(viewport.width - maxRight)*zoom}px`;
      pageStyle.marginTop =  `-${minTop*zoom}px`;
      pageStyle.marginBottom =  `-${(viewport.height - maxBottom)*zoom}px`;
    }


    let spans = [];

    if(currentFilter || !pageImage) {
      spans = _.map(pageItems || [], ({ textContent, normalizedText, style }, i) => {
        let globalStyle = globalStyles[style.fontName] || { color: 'magenta' };

        let spanStyle = {
          // fontFamily: styles[style.fontName],
          fontSize: `${style.fontSize*zoom}px`,
          left: `${style.left*zoom}px`,
          top: `${style.top*zoom}px`,
          width: `${style.width*zoom}px`,
          height: `${style.height*zoom}px`,
          transform: style.transform,
          ...globalStyle
        }

        let isMatch = false;
        if (currentFilter && normalizedText.match(currentFilter)) {
          isMatch = true;
        }

        const textWithSpaces = textContent.replace(/  /g, "\u00a0\u00a0");

        if(_.includes(this.state.selectedSpans, pageItems[i])) {
          return <span key={'item-' + i} className={'bg-light-primary item-' + i} style={spanStyle}>{textWithSpaces}</span>;
        }

        if (isMatch && currentFilter.source.length > 0) {
          return <HighlightedText regex={currentFilter} key={'item-' + i} text={textWithSpaces} style={spanStyle}
                                  className={`highlight item-${i}`}/>
        } else {
          return <span key={'item-' + i} className={'item-'+i} style={spanStyle}>{textWithSpaces}</span>;
        }
      })
    }


    let hasImageClass = '';
    if (pageImage && hasImage) {
      pageStyle.backgroundImage = `url(${pageImage}),url(${this.props.pageImageThumb})`
      // pageStyle.backgroundImage = `url(${this.props.pageImageThumb})`
      hasImageClass = 'pdf-page--image';
    }

    const clippingPageStyle = {
      maxHeight: `${availableHeight - 8}px`,
      maxWidth: `${availableWidth}px`
    }

    const toggleClipping = ()=> this.setState({clippingEnabled: !clippingEnabled});
    let clipToggleBtn = null;
    if((wouldClipButDisabled || clippingPage) && this.props.zoom === 1) {
      clipToggleBtn = <IconButton icon={'photo_size_select_large'} className={'clip-page-btn'} onClick={toggleClipping}>
        {clippingEnabled ? 'Show entire page' : 'Clip extra space'}
      </IconButton>;
    }


    let boxSelectionOverlay = null;
    if(this.state.selectingBox || this.state.selectingTextBox) {
      boxSelectionOverlay = <BoxSelectionOverlay message={this.state.selectingBoxMessage} onSelection={(boundingBox) => {
        let selectedText = undefined;
        if(this.state.selectingTextBox) {
          selectedText = this.getIntersectingText(boundingBox);
        }

        this.state.onBoxSelected({
          ... boundingBox,
          selectedText,
          pageNumber: this.props.page.index + 1,
          aspectRatio: this.props.page.viewport.width / this.props.page.viewport.height
        });
      }} onCancel={() => {
        this.state.onCancel && this.state.onCancel();
        this.setState({selectingBox: false, selectingTextBox: false})
      }} onBoxMove={(boundingBox) => {
          // this.setState({selectedSpans: _.map(this.getIntersectingText(boundingBox), 'textContent')})
      }}/>
    }

    const clipping = clippingPage ? 'clipping-page--clipping' : '';
    const zooming = this.props.zoom === 1 ? '' : 'clipping-page--zooming'

    return <div ref={this.containerRef} style={clippingPageStyle} className={`clipping-page ${clipping} ${zooming}`}>

      {clipToggleBtn}

      <div className={`pdf-page shadow ${hasImageClass}`} style={pageStyle}>
        {boxSelectionOverlay}
        {spans}
      </div>
    </div>;
  }
}
PDFPageTextPreview.contextType = LegoAdminPageContext;
