import LegoEditor from './LegoEditor';

export function openLego(legoId, pageContext, editorProps = {}) {
  pageContext.runAsync(async () => {
    const lego = await pageContext.service('services/legos').get(legoId);
    pageContext.openModal(<div>
      <LegoEditor id={legoId} lego={lego} onCancel={() => pageContext.closeModal()} {... editorProps}/>
    </div>, { title: `Edit ${lego.type} "${legoId}"` });
  }, `Loading lego ${legoId}...`);
}
