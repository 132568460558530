import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { IconButton } from '../common/IconButton';
import PDFPagePreview from '../pdf-tools/PDFPagePreview';
import PDFHtmlViewer from '../pdf-tools/PDFHtmlViewer';

export default function ImageSourcePanel({ imageId }) {
  let [img, setImg] = useState();
  let [manual, setManual] = useState();

  const { page } = useContext(LegoAdminPageContext);
  const imageSvc = page.service('services/data/images');
  const manualsSvc = page.service('services/data/manuals');

  const fetchImage = () => {
    page.runAsync(async () => {
      const data = await imageSvc.get(imageId);
      setImg(data || false);

      if (data?.sourceMetadata && data.sourceType === 'pdf-fragment') {
        setManual((await manualsSvc.find({ query: { manualPdfUrl: data.sourceMetadata.sourceUrl } }))[0]);
      } else {
        setManual(false);
      }
    });
  };

  useEffect(fetchImage, [imageId]);

  if (img === undefined || manual === undefined) {
    return <div className={'alert alert-primary'}>Loading source manual for "{imageId}"...</div>;
  }

  if (img.sourceMetadata === false) {
    return <div className={'alert alert-danger'}>Image source for "{imageId}" not found</div>;
  }

  let { sourceType, sourceMetadata } = img;

  if (sourceType !== 'pdf-fragment' || !manual) {
    return <pre>
      {JSON.stringify(img, null, 2)}
    </pre>;
  } else {
    let { sourceUrl, pageNumber, xStart, yStart, width, height, aspectRatio } = sourceMetadata;

    let clipStyle = {
      border: 'dashed 2px red',
      borderRadius: '2px',
      position: 'absolute',
      left: `${xStart}%`,
      top: `${yStart}%`,
      width: `${width}%`,
      height: `${height}%`
    };

    let page = pageNumber;

    const coverImgSrc = PDFHtmlViewer.generatePageImgUrl(manual.metadata.hash, manual.metadata.imagesHighResFolder, page, manual.metadata.pages, manual.metadata.s3Bucket);

    return <div className={'d-flex h-100 w-100 flex-column'}>
      <div className={'text-center'}>
        <a href={manual.manualPdfUrl + '#page=' + (page)} target={'_blank'} className={'text-primary'}>
          <IconButton icon={'picture_as_pdf'}/><span className={'mr-2'}>{manual.manualPdfUrl}</span>
        </a>
        <span className={'badge badge-primary'}>Page {page} of {manual.metadata.pages}</span>
      </div>

      <div className={'pdf-html-viewer p-4 text-center'} style={{ height: '100%', width: '100%' }}>
        <PDFPagePreview pageImage={coverImgSrc} zoom={1}>
          <div style={clipStyle}/>
        </PDFPagePreview>
      </div>
    </div>;
  }

}
