import _ from 'lodash'
import React, {useState, useEffect} from 'react';

const namedLegos = {
  'trimSpecs': {
    query: { type: 'data', intentions: 'definition', semantics: 'TRIM_SPECS_SELECTOR' },
    postprocess: ({ data }) => _.mapValues(data || {}, values => [...values, ..._.map(values, v => '!' + v)])
  },
  'legoDefaultLabels': {
    query: { type: 'data', intentions: 'definition', semantics: 'LEGO_DEFAULT_LABELS' },
    postprocess: ({ data }) => data
  },
  'fuseboxColors': {
    query: { type: 'list', intentions: 'definition', semantics: 'FUSEBOX_COLORS' },
    postprocess: ({ data }) => data
  }
}

const globalLegoCache = {};
const globalLegoCacheFetching = {};

const getLegoFromCache = async (queryOrDataName, setData) => {
  let queryId, query, postprocess = (lego) => lego.data;

  if (_.isString(queryOrDataName)) {
    let queryData = namedLegos[queryOrDataName];
    if (!queryData) {
      throw new Error(`Invalid useLegoData dataset name '${queryOrDataName}'`);
    }
    query = queryData.query;
    postprocess = queryData.postprocess || postprocess;
    queryId = queryOrDataName;
  } else {
    queryId = JSON.stringify(queryOrDataName)
    query = queryOrDataName;
  }

  if (!globalLegoCache[queryId]) {
    // All this logic is to prevent multiple simultaneous instances of the same component requesting the async data
    // So the first one asking fot something that is not in the cache will make the async request and then will call
    // all the callbacks for other components waiting for the very same data
    if(!globalLegoCacheFetching[queryId]) {
      console.log(`useLegoData: Fetchié lego y postprocesé [${queryId}]`)
      globalLegoCacheFetching[queryId] = []

      const [lego] = await client.service('/services/legos').find({ query });

      if (lego) {
        globalLegoCache[queryId] = postprocess(lego);

        setData(globalLegoCache[queryId]);

        console.log(`useLegoData: Dispatching ${globalLegoCacheFetching[queryId].length} waiting callbacks`)
        for(const cbk of globalLegoCacheFetching[queryId]) {
          cbk(globalLegoCache[queryId]);
        }
        delete globalLegoCacheFetching[queryId];
      } else {
        console.error(`Single lego cache: Could not find a lego matching query ${JSON.stringify(query)}`);
      }
    } else {
      // Push setData callback so that when the
      globalLegoCacheFetching[queryId].push(setData);
    }
  } else {
    setData(globalLegoCache[queryId]);
  }
};

const tryGetLegoFromCache = (queryOrDataName) => {
  let queryId;

  if (_.isString(queryOrDataName)) {
    let queryData = namedLegos[queryOrDataName];
    if (!queryData) {
      throw new Error(`Invalid useLegoData dataset name '${queryId}'`);
    }
    queryId = queryOrDataName;
  } else {
    queryId = JSON.stringify(queryOrDataName)
  }

  if (globalLegoCache[queryId]) {
    return globalLegoCache[queryId];
  }
  return null;
}

export default function useLegoData(queryOrDataName, defaultData) {
  // First try to get data from cache in a synchronous call
  // This is to prevent flashes in the UI just because of an async call in useEffect
  const cacheData = tryGetLegoFromCache(queryOrDataName);
  const [data, setData] = useState(cacheData || defaultData);

  useEffect(() => {
    let mounted = true;
    if (!cacheData) {
      getLegoFromCache(queryOrDataName, (... args) => mounted && setData(...args)).catch(console.error);
    }
    return () => mounted = false
  }, []);

  return data;
}
