import React from 'react';
import _ from 'lodash';

import PDFHtmlViewer from "../components/pdf-tools/PDFHtmlViewer";
import CachedPDFManualLoader from "../components/pdf-tools/CachedPDFManualLoader"
import LiveSubpage from './LiveSubpage';
import { getCountryName } from '../../../components/interop/countryCodeConverterFront';

export default class SingleManualViewer extends LiveSubpage {
  constructor(props) {
    super(props);

    this.pdfService = this.service('services/data/manuals');

    this.state = {
      ...this.state,
      pagesDescription: null,
      fileIndex: 0,
      onlyEs: false,
      onlyOwnerManuals: false,
      startPage: this.props.startPage ?? 0,
      contextFilter: { modelId: 'Ford-Focus' }
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadFile().catch(alert);
  }

  fileName(file) {
    let { modelId, year } = file.context[0];
    if(file.context.length > 1) {
      modelId = _.uniq(_.map(file.context, 'modelId')).join('/')
    }
    const countryNames = _.map(file.country, c => getCountryName(c))
    return `${modelId}-${year}-${file.isoLanguage}-${countryNames}`
  }

  async loadFile() {
    let file = await this.pdfService.get(this.props.manualId);
    if(file) {
      const fileHash = file.metadata.hash;
      const s3Bucket = file.metadata.s3Bucket;

      const fileName = this.fileName(file);

      await this.runAsync((async () => {
        let pdf = await this.loadPdfSummary(fileHash, s3Bucket);
        if (this.mounted && this.setUrlParams) {
          this.setState({ pagesDescription: pdf, fileHash, fileName, file, s3Bucket })
          this.setUrlParams({ fileHash })
        }
      })(), `Loading ${fileName}...`)
    }
  }

  async loadPdfSummary(pdfId, s3Bucket) {
    return await CachedPDFManualLoader.loadManualTextLayers(pdfId, s3Bucket);
  }

  renderPageBody() {
    let { file, pagesDescription, startPage, fileName, fileHash, fileIndex, s3Bucket } = this.state;

    let originalUrl = (file?.manualPdfUrl || '').replace('s3.amazonaws.com/opinautos-manuals', 'manuals.opinautos.com');

    return <div className={''}>
        {pagesDescription ?
          <PDFHtmlViewer name={fileName} hash={fileHash} pages={pagesDescription.pages}
                         initialSearch={this.props.initialSearch}
                         startPage={startPage}
                         originalUrl={originalUrl} globalStyles={pagesDescription.globalStyles}
                         shortcuts={this.shortcuts} s3Bucket={s3Bucket}/>
          :
          "No pdf loaded"}
      </div>;
  }
}
