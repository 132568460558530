import React from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import submenuTagging from './menus/submenu-tagging';
import InputMultipleSemantics from '../components/common/editors/InputMultipleSemantics';
import { Icon } from '../components/common/Icon';
import { IconButton } from '../components/common/IconButton';
import SemanticAncestorsModal from '../components/semantic/SemanticAncestorsModal';

export default class TaggedReportsExplorer extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;

    this.submenu = submenuTagging;
    this.state.modelId = null;
    this.state.semantics = ['TESTIGO_CHECK_ENGINE'];
    this.state.linguistics = [];
    this.state.limit = 100;

    this.semantics = this.service('services/tagging/semantic-reports');
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.fetchData());
  }

  async fetchData() {
    const { semantics, modelId, limit } = this.state;

    if (semantics.length) {
      this.setState({ linguistics: [] });

      let [res, ... linguistics] = await Promise.all([
        this.semantics.find({ query: { modelId, semantics, limit } }),
        ... (semantics.map(sem => this.semantics.get(sem)))
      ]);

      this.setState({ reports: res.reports, total: res.total, linguistics });
    }
  }

  modelChanged({ modelId }) {
    this.setState({ modelId }, () => this.runAsync(this.fetchData()));
  }

  updateSemantics(s) {
    this.setState({ semantics: s }, () => this.runAsync(this.fetchData()));
  };


  renderPageBody() {
    const { reports, total, semantics, linguistics, modelId, limit } = this.state;


    const semanticFromHash = () => {
      let hash = prompt('Enter semantic hash (e.g. "d2caf89ef3")')
      if(hash) {
        hash = hash.trim().toLowerCase();

        this.runAsync(async () => {
          let res = await this.semantics.get(hash);
          if(res?.semanticKey) {
            this.updateSemantics([res.semanticKey])
          } else {
            alert(`Hash "${hash}" not found in semantic index`)
          }
        })
      }
    };

    let res = <div>
      <div className={'row d-flex align-items-center no-gutters bg-secondary pb-1'}>
        <IconButton level={'white'} icon={'tag'} onClick={semanticFromHash}>From hash...</IconButton>

        <div className={'p-2 col-6'}>
          <InputMultipleSemantics value={semantics} onChange={this.updateSemantics.bind(this)}/>
        </div>

        <div className={'p-2 col-4'}>
          <ModelContextEditor value={{ modelId }} onChange={this.modelChanged.bind(this)}/>
        </div>
      </div>

      <div className={'p-2'}>
        <div className={'d-flex mb-2 align-items-center'}>
          <h5 className={'mb-0 mr-4'}>
            {reports ? reports.length + ' reports' : 'No reports'} {total && reports?.length !== total ? ` of ${total}` : ''}
          </h5>

          { _.map(semantics, (sem,i) => {
            let temasUrl = `//opinautos.com/ford/focus/temas/${linguistics[i]?.id}/${linguistics[i]?.slug}`;

            return <div className={'mr-2 small rounded px-2 bg-light-primary no-select d-flex'}>
              <div>
                <div className={'monospace'}>
                  <span className={'select-text'}>{sem}</span>&nbsp;
                  {linguistics.length && <a href={temasUrl} target={'_blank'}>{linguistics[i]?.id || 'Not found'}</a> }
                </div>
                <div className={'text-center font-italic text-secondary'}>{linguistics[i]?.text || (linguistics.length ? '---' : '...')}</div>
              </div>
              <IconButton icon={'account_tree'} title={'Edit'} onClick={() => this.openModal(<SemanticAncestorsModal semantic={sem}/>, {title: 'Semantic Ancestors'})}/>
            </div>;
          })}
        </div>

        <table className={'table table-sm'}>
          <tbody>
          {((reports || []).map(({ tituloEditado, comments, vehicle, idReport, solucionado, locked }) => <tr key={idReport}>
            <td>
              <a href={`//opinautos.com/kia/rio/defectos/${idReport}/redirect?debug=1`} target={'_blank'}>
                {idReport}
              </a>
            </td>
            <td>{vehicle.modelId}</td>
            <td className={'text-right text-secondary'}>{comments} <Icon icon={'comment'}/></td>
            <td className={'text-left'}>
              {solucionado && <Icon icon={'done'} className={'text-success'}/>}
              {locked && <Icon icon={'lock'} className={'text-danger'}/>}
            </td>
            <td>{tituloEditado}</td>
            <td></td>
          </tr>))}
          </tbody>
        </table>

        {/*<pre className={'small'}>{JSON.stringify(reports, null, 2)}</pre>*/}

      </div>
    </div>;

    return res;
  }
}
