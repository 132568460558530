import React, { useContext } from 'react';
import _ from 'lodash';

import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { Async } from 'react-select';
import LegoLabel from './LegoLabel';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncData from '../../../../components/common/effects/useAsyncData';
import useLegoData from './data-caches/useLegoData';
import { getFlexibleRegex } from '../common/editors/autocomplete-filter-logic';

export default function LegoLabelsSelector({ onChange, value, ...props }) {
  let { page } = useContext(LegoAdminPageContext);

  let predefinedLabels = useLegoData('legoDefaultLabels', []);
  let usedLabels = useAsyncData('usedLabels', async () => page.service('services/legos/labels').find(), []);

  const handleSelectChange = (value, actionMeta) => onChange(_.map(value, 'value'));

  const getOption = (props) => {
    let { innerProps, isSelected, isFocused } = props;
    let { innerRef, ...filteredProps } = innerProps;
    return <div {...filteredProps} className={'p-1 ' + ((isSelected || isFocused) ? 'bg-light-primary' : '')}>
      {props.data.isNew ? 'Create ' : null} <LegoLabel label={props.value}/>
    </div>;
  };

  const getMultiValueLabel = (props) => <LegoLabel label={props.children}/>;

  const options = _.uniq(predefinedLabels.concat(usedLabels)).sort().map(t => ({ label: t, value: t }));

  let loadOptions = async (input) => {
    let regex, flexibleRegex;

    try {
      regex = new RegExp((input || "").trim(), 'i');
      flexibleRegex = getFlexibleRegex((input || "").trim());
    } catch(e) {
      regex = new RegExp(_.escapeRegExp(input));
      flexibleRegex = regex;
    }

    let matches = options.map((op) => [op, op.label.match(regex) || op.label.match(flexibleRegex)]).filter(m => m[1]);

    let sortedMatches = _.sortBy(matches, ([op, matches]) => {
      let matchLength = matches[0].length;
      let resLength = op.label.length;
      return matchLength + resLength/100;
    }).map(m => m[0]);

    sortedMatches.push({label: input, value: input, isNew: true})

    return sortedMatches.slice(0,20)
  };

  return <Async
    closeOnSelect={true}
    isMulti
    components={{
      Option: getOption,
      MultiValueLabel: getMultiValueLabel
    }}
    onChange={handleSelectChange}
    loadOptions={loadOptions}
    placeholder="Pick or write a label..."
    styles={{ menu: (provided) => ({... provided, zIndex: 1000, minWidth: '300px'})}} // Needed in lego editor
    menuShouldScrollIntoView={false}
    defaultOptions={options}
    value={_.map(value, v => ({ label: v, value: v, isNew: true }))}
    {...props}
  />;
}
