import React, { Component, useContext } from 'react';
import _ from 'lodash';

import FuseboxDiagram from '../../common/FuseboxDiagram';
import FuseboxDiagramImgProcessor from './FuseboxDiagramImgProcessor';
import FuseboxTableEditor from './FuseboxTableEditor';
import SingleTextEditor from '../../common/editors/SingleTextEditor';
import TextFieldEditor from '../../common/editors/TextFieldEditor';
import { IconButton } from '../../common/IconButton';
import { SingleImageUrlEditor } from '../../common/images/SingleImageUrlEditor';
import { MultipleImagesEditor } from '../../common/editors/MultipleImagesEditor';
import EventEmitter from 'events';
import ModalManager from '../../ModalManager';
import { FuseboxTemplateFinder } from './FuseboxTemplateFinder';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import TopBarLayout from '../../common/layout/TopBarLayout';
import {
  buildFuseWithTemplate,

  extendFuseboxWithTemplate,
  extendFusesWithTemplate,
  parseFuseboxDescriptionsInferringLanguage,
  refactorFuseboxToUseTemplate
} from './fusebox-utils.mjs';
import { Icon } from '../../common/Icon';

import LegoContextSummary from '../LegoContextSummary';
import { VehicleSummary } from '../../common/VehicleSummary';
import ImageClusterPreview from '../../images/ImageClusterPreview';
import { ImagePreview } from '../../images/ImagePreview';
import ImageClusterFinder from '../../images/ImageClusterFinder';
import MultiButtonSwitch from '../../common/editors/MultiButtonSwitch';
import ModalColorSelector from '../ModalColorSelector';
import JsonListEditor from '../../common/editors/JsonListEditor';
import LegoEditButton from '../LegoEditButton';
import { SwitchInput } from '../../common/SwitchInput';


function ColorPicker({ value, onChange }) {
  const { page } = useContext(LegoAdminPageContext);

  const style = {
    width: '1em', height: '1em',
    border: 'solid 1px white',
    backgroundColor: value || 'transparent',
    cursor: 'pointer',
    verticalAlign: 'middle'
  };

  const changeColor = () => {
    page.openModal(<ModalColorSelector value={value} onChange={(newColor) => {
      page.closeModal();
      onChange(newColor);
    }}/>, {title: 'Pick a color'});
  }


  // setTimeout(changeColor, 1000);

  return <span onClick={changeColor} style={style} className={'inline-block'}></span>;
}

export default class FuseboxDiagramEditor extends Component {
  constructor(props) {
    super(props);

    const hasLayout = _.some(this.props.fusebox?.fuses, f => !_.isEmpty(f?.layout));

    this.modalActionsBus = new EventEmitter();

    this.state = {
      changedFusebox: _.cloneDeep(this.props.fusebox),
      showChangePicture: !this.props.fusebox.templateId && (!this.props.fusebox.boxDiagramImg),
      templateData: null,
      templateContext: null
    };

    this.fuseDiagramRef = React.createRef();
    this.imageProcessorRef = React.createRef();
    this.fuseTableRef = React.createRef();
    this.updateAcronymsDebounced = _.debounce(this.updateAcronyms.bind(this), 500);
    // setTimeout(() => this.searchTemplates(),50);
  }

  legoService() {
    return this.context.page.service('services/legos')
  }

  componentDidMount() {
    if(this.props.fusebox.templateId) {
      this.context.page.runAsync(this.fetchTemplateData(this.props.fusebox.templateId));
    }
  }

  editField(field, newValue) {
    this.state.changedFusebox[field] = newValue;
    // this.props.onChange(this.props.value);
    //TODO: HACK, this is needed by the cache mechanism of FuseboxDiagram
    this.state.changedFusebox.processed = false;

    if(!this.state.changedFusebox.boxRotation)
      delete this.state.changedFusebox.boxRotation;

    if(!this.state.changedFusebox.useAcronyms) {
      delete this.state.changedFusebox.useAcronyms;
    }

    this.setState({ changedFusebox: this.state.changedFusebox });
  }

  editFields(fieldsMap) {
    _.each(fieldsMap, (newValue, field) => {
      this.state.changedFusebox[field] = newValue;
    })

    //TODO: HACK, this is needed by the cache mechanism of FuseboxDiagram
    this.state.changedFusebox.processed = false;

    if(!this.state.changedFusebox.boxRotation)
      delete this.state.changedFusebox.boxRotation;

    if(!this.state.changedFusebox.templateRenameMap)
      delete this.state.changedFusebox.templateRenameMap;

    this.setState({ changedFusebox: this.state.changedFusebox });
  }

  fuseboxDiagramChanged(fuses, boxAspectRatio, templateRenameMap) {
    this.editFields({fuses, boxAspectRatio, templateRenameMap});
  }

  async parseAndSaveDiagram() {
    let now = this.state.changedFusebox;
    let old = this.props.fusebox;

    const newDescriptions = _.map(now.fuses, f => old.useAcronyms ? f.extendedDescription || f.description : f.description);
    const oldDescriptions = _.map(now.fuses,  f => now.useAcronyms ? f.extendedDescription || f.description : f.description);
    if (!_.isEqual(newDescriptions, oldDescriptions)) {
      try {
        const nlpService = this.context.page.service('services/semantic/nlp');
        // parseFuseboxDescriptionsInferringLanguage takes the whole fusebox.data with multiple boxes, so pass this one in an array
        await parseFuseboxDescriptionsInferringLanguage([now], nlpService);
        await this.updateAcronyms();
      } catch(e) {
        alert(`Could not parse fusebox! ${e.toString()}`);
      }
    }

    this.props.onSave(now);
  }

  async fetchTemplateData(fuseboxTemplateId) {
    const {data, context} = await this.context.page.service('services/legos').get(fuseboxTemplateId);

    // Add mark to template fuses, useful for table editor
    _.each(data[0]?.fuses, f => f.fromTemplate = true);

    this.setState({templateData: data[0], templateContext: context})

    return data[0];
  }

  searchTemplates() {
    this.modalActionsBus.emit('open', <FuseboxTemplateFinder
      fuseboxData={this.state.changedFusebox}
      context={this.props.lego.context}
      currentTemplateId={this.state.changedFusebox.templateId}
      currentLegoId={this.props.lego._id}
      onSelected={async (fuseboxTemplate, fuseboxIndex) => {
        let templateId = fuseboxTemplate._id;
        let templateRenameMap;

        if(fuseboxTemplate.type === 'fusebox'){
          if(fuseboxTemplate.data[fuseboxIndex].templateId) {
            // If fusebox has temnplateId, means we need to copy the templateId
            templateId = fuseboxTemplate.data[fuseboxIndex].templateId;

            // Copy templateRenameMap
            templateRenameMap = fuseboxTemplate.data[fuseboxIndex].templateRenameMap;
          } else {
            // Else extract new template from fusebox
            if (!confirm('Are you sure you want to refactor that fusebox and create a new template?')) {
              this.modalActionsBus.emit('close');
              return
            }

            // "overwrite" the selected fusebox with the newly created template
            fuseboxTemplate = await this.context.page.runAsync(this.refactorFuseboxIntoTemplate(templateId, fuseboxIndex), 'Creating template...')
            fuseboxIndex = 0;
          }
        }

        const hasFusesWithLayout = _.some(this.state.changedFusebox.fuses, f => !_.isEmpty(f.layout));

        if(hasFusesWithLayout) {
          if(confirm('The fusebox has boxes drawn. They will be lost and replaced with the template, are you sure?')) {
            this.context.page.runAsync(this.overwriteLayoutWithTemplate(templateId, templateRenameMap));
          }
        } else {
          this.editField('templateId', templateId);

          if(!_.isEmpty(templateRenameMap)) {
            this.editField('templateRenameMap', templateRenameMap);
          }

          this.setState({templateData: null, templateContext: null, showChangePicture: false})
          this.context.page.runAsync(this.fetchTemplateData(templateId))
        }

        this.modalActionsBus.emit('close');
      }}
    />, {fullScreen: true})
  }

  async refactorFuseboxIntoTemplate(fuseboxLegoId, fuseboxIndex) {
    // Fetch the full lego data for the fusebox id
    const selectedFuseboxLego = await this.context.page.service('services/legos').get(fuseboxLegoId);

    let selectedFusebox = selectedFuseboxLego.data[fuseboxIndex];

    // Create a new fuseboxTemplate lego from the selcted fusebox
    const templateLego = await this.createTemplateFromFusebox(selectedFusebox);

    // Refactor the source fusebox using the new template
    selectedFuseboxLego.data[fuseboxIndex] = refactorFuseboxToUseTemplate(selectedFusebox, templateLego.data[0]);
    selectedFuseboxLego.data[fuseboxIndex].templateId = templateLego._id;

    selectedFuseboxLego.updatedBy = this.context.page.getLoggedUserSignature();
    await this.legoService().update(selectedFuseboxLego._id, selectedFuseboxLego);

    return templateLego;
  }

  async overwriteLayoutWithTemplate(templateId, templateRenameMap) {
    let template = await this.fetchTemplateData(templateId);

    const changedFusebox = refactorFuseboxToUseTemplate(this.state.changedFusebox, template);
    changedFusebox.templateId = templateId;
    changedFusebox.templateRenameMap = templateRenameMap;
    changedFusebox.processed = false;

    this.setState({changedFusebox, templateId, showChangePicture: false});
  }

  async createTemplateFromFusebox(fusebox) {
    if(!fusebox.templateId) {
      let fuseboxTemplate = _.cloneDeep(fusebox);

      _.each(fuseboxTemplate.fuses, f => {
        delete f.description;
        delete f.relatedEntities;
        delete f.kind.amp;
        delete f.kind.notUsed;
      })

      // Templates can't have the same Id more than once
      fuseboxTemplate.fuses = _.uniqBy(fuseboxTemplate.fuses, 'id')

      let newLego = {
        type: 'fuseboxTemplate',
        semantics: ["DIAGRAMA_DE_FUSIBLES"],
        intentions: ["template"],
        locales: [... this.props.lego.locales],
        state: window.user?.level === 'admin' ? 'published' : 'inprogress',
        context: _.cloneDeep(this.props.lego.context),
        source: _.cloneDeep(this.props.lego.source),
        data: [fuseboxTemplate],
        createdBy: this.context.page.getLoggedUserSignature(),
        updatedBy: this.context.page.getLoggedUserSignature()
      }

      return this.legoService().create(newLego);
    } else {
      throw new Error("Cannot refactor a fusebox that is already using a template")
    }
  }

  async refactorIntoNewTemplate() {
    try {
      let newTemplate = await this.createTemplateFromFusebox(this.state.changedFusebox)
      await this.overwriteLayoutWithTemplate(newTemplate._id);
    } catch(err) {
      alert(err);
    }
  }

  selectFuse(fuses, fromTable = false) {
    if(fuses?.length === 1 && fuses[0]?.id) {
      this.fuseTableRef.current?.scrollToFuse(fuses[0].id);
    }

    // Fuses can be indexes or fuses, if fuses, convert to their indexes
    if(fuses && fuses.length && _.isObject(fuses[0])) {
      let finalFusebox = this.buildFinalFuseboxWithTemplate();
      const getIndexesOfSameIdFuses = f => _.filter(_.map(finalFusebox.fuses, ({id},j) => f.id === id ? j : null ), index => index !== null)

      fuses = _.sortBy(_.uniq(_.flatten(_.map(fuses, getIndexesOfSameIdFuses))))

    }

    // Hack to select the same fuse in the diagram
    if(fromTable && fuses.length) {
      if(this.fuseDiagramRef.current) {
        this.fuseDiagramRef.current.selectFusesByIndexes(fuses);
      }
    }

    this.setState({ selectedFuses: fuses });
  }

  createMissingTableRows() {
    let imgProcessor = this.imageProcessorRef.current;
    if(imgProcessor) {
      let fuses = [ ... this.state.changedFusebox.fuses];
      let byId = _.groupBy(fuses, 'id');
      for(const rect of imgProcessor.state.rectangles) {
        if(rect.fuseId && !byId[rect.fuseId]) {
          const newFuse = {
            id: rect.fuseId,
            kind: {  },
          };

          fuses.push(newFuse)
        }
      }
      this.state.changedFusebox.fuses = fuses;
      this.setState({changedFusebox: this.state.changedFusebox});
    }
    // FuseboxDiagramImgProcessor.synchronizeAssignedFuses(this.props, this.state);
  }

  selectFusesCorrespondingToRectangles() {
    let imgProcessor = this.imageProcessorRef.current;
    if(imgProcessor) {
      let selectedIds = _.compact(_.map(imgProcessor.state.selection, 'fuseId'));
      let selection = _.filter(this.state.changedFusebox?.fuses, f => selectedIds.includes(f?.id));
      if(selection?.length) {
        this.selectFuse(selection, true);
      }
    }
  }


  toggleZoom() {
    this.setState({ expanded: !this.state.expanded }, () => {
      // Hack to trigger layout calculations of components that measure their parents. Such as fusebox editor
      window.dispatchEvent(new Event('resize'));
    })
  }

  toggleShowChangePic() {
    this.setState({ showChangePicture: !this.state.showChangePicture })
  }

  getContextIdentifier() {
    let ctx = this.props.lego.context;
    return ctx && ctx.length ? _.values(ctx[0]).join('-').toLowerCase() : null;
  }

  onFuseboxTableChanges(fuses, fuseTableImgs, descriptionChanged) {
    this.editField('fuses', fuses)
    if(descriptionChanged && this.state.changedFusebox.useAcronyms) {
      this.updateAcronymsDebounced();
    }

    if(fuseTableImgs?.length) {
      let images = _.uniqBy([...(this.state.changedFusebox.boxTablesImages || []), ...fuseTableImgs], 'url');
      this.editField('boxTablesImages', images)
    }
  }

  openImagesModal (template, fusebox) {
    const styleWith = {maxWidth: '100%', maxHeight: '80vh', cursor: 'zoom-in'}
    const viewFullImage = (path) => this.modalActionsBus.emit('open', <div onClick={viewBothImages}>
      <div className={'text-large'}>Click to return</div>
      <img style={styleWith} src={path || ''}/>
    </div>)

    const viewBothImages = () => this.modalActionsBus.emit('open', <div>
        <div className={'row no-gutters'}>
          <div className={'col-6 bg-gris-blue text-center'} onClick={() => viewFullImage(template)}>
            <div className={'text-white'}><h5>Template</h5></div>
            <img style={styleWith}  src={template || ''} alt={'Template image'}/>
          </div>
          <div className={'col-6 bg-dark  text-center'} onClick={() => viewFullImage(fusebox)}>
            <div className={'text-white'}><h5>Fusebox</h5></div>
            <img style={styleWith} src={fusebox || ''} alt={'Fusebox image'}/>
          </div>
        </div>
      </div>
      , { fullScreen: true })
    viewBothImages()
  }

  openSearchClusters() {
    let modelId = this.props.lego.context?.[0]?.modelId;
    this.context.page.openModal(<div>
        <ImageClusterFinder clusterType={'fusebox-broad'}
                            sampleImage={this.state.changedFusebox.boxDiagramImg?.url || ''}
                            vehicleContext={{modelId}}
                            onClusterSelected={(c) => {
                              if(c) {
                                let newClusters = _.uniq([... (this.state.changedFusebox.imageClusters || []), c._id]);
                                this.editField('imageClusters', newClusters);
                              }
                              this.context.page.closeModal();
                            }}
        />
      </div>
      , { fullScreen: true, noPadding: true, title: 'Find image cluster' })
  }

  render() {
    const fusebox = this.state.changedFusebox;
    const {expanded, showChangePicture, assigningFuses, templateData, templateContext, renameMode} = this.state;

    const { templateId, templateRenameMap, boxName, boxDescription, boxDiagramImg, boxReferenceImg, boxTablesImages, boxAspectRatio, fuses, boxScaleMultiplier, boxRotation, backgroundColor, imageClusters } = fusebox;

    const hasChanged = !_.isEqual(this.state.changedFusebox, this.props.fusebox);
    const hasLayout = _.some(this.state.changedFusebox.fuses, f => !_.isEmpty(f.layout));

    let uploadLayer = null;
    if(showChangePicture) {
      const btnFindAndReuse = <button className={'btn btn-sm btn-primary mr-2 mb-2'} onClick={() => this.searchTemplates()}>
        Find and reuse
        template
      </button>;

      const btnRefactor = <button className={'btn btn-sm btn-success mb-2'} onClick={() => this.context.page.runAsync(this.refactorIntoNewTemplate())}>
        Refactor into new template
      </button>;

      // const btnCreateNew = <button className={'btn btn-sm btn-success'} onClick={() => this.searchTemplates()}>
      //   Create new template
      // </button>;

      uploadLayer = <div className={'full-screen-center'}>
        <div className={'w-75 box-shadow bg-light rounded p-2'}>
          <SingleImageUrlEditor folder={'fusebox-diagrams'} category={'fusebox-diagram'} value={boxDiagramImg}
                                defaultName={this.getContextIdentifier()}
                                forceJPG={true}
                                placeholder={'Diagram picture...'}
                                onChange={img => {
                                  this.setState({ showChangePicture: false })
                                  return this.editField('boxDiagramImg', img);
                                }}/>

          <hr className={'m-0'}/>

          <SingleImageUrlEditor folder={'fusebox-pictures'} category={'fusebox-picture'} forceJPG={true}
                                placeholder={'Format reference picture...'}
                                value={boxReferenceImg} defaultName={this.getContextIdentifier()}
                                onChange={img => this.editField('boxReferenceImg', img)}/>

          <hr className={'m-0'}/>

          <div className={'p-2 text-center'}>
            {btnFindAndReuse}

            {hasLayout ? btnRefactor : null}
          </div>
        </div>

      </div>
    }

    let btnZoom = <IconButton level={'primary'} onClick={this.toggleZoom.bind(this) } icon={expanded ? 'zoom_in_map' : 'zoom_out_map'}/>;
    let btnChangePic = <IconButton level={showChangePicture ? 'success' : 'primary'} onClick={this.toggleShowChangePic.bind(this)} icon={'add_photo_alternate'}/>;
    let btnCreateRows = <IconButton level={'primary'}
                            title={'Create table rows for all the missing fuse ids'}
                            onClick={() => this.createMissingTableRows()}
                            icon={'view_list'}/>;

    let btnSelectFuses = <IconButton level={'primary'}
                            title={'Select rectangles with ids in the diagram'}
                            onClick={() => this.selectFusesCorrespondingToRectangles()}
                            icon={'select_all'}/>;

    let btnPerspective = <a href={'https://perspective-match.vercel.app'} target={'_blank'} title={'Perspective tool'}>
      <img height={20} className={'ml-3'} src={'https://perspective-match.vercel.app/favicon.png'}/>
    </a>

    let unassignedFuses = _.filter(fuses, f => !f.layout?.width);
    let btnAssignFuses;
    if(unassignedFuses.length) {
      btnAssignFuses = <IconButton level={(unassignedFuses && assigningFuses) ? 'success' : 'primary'}
                                        onClick={() => this.setState({ assigningFuses: !assigningFuses })}
                                        title={'Assign fuse ids that were not assigned to any rectangle'}
                                        icon={'text_rotation_none'}/>;
    }

    let isDiagram = !boxReferenceImg || this.state.forceShowDiagram;

    const switchImage = (v) => {
      if(this.imageProcessorRef.current) {
        if(v === 'Diagram') {
          this.setState({forceShowDiagram: true})
        } else {
          this.setState({forceShowDiagram: false})
        }
        // this.imageProcessorRef.current.updateImageAspectRatio(e);
      }

    };

    const addRenameMap = () => {
      if(renameMode) {
        this.setState({ changedFusebox: this.state.changedFusebox, renameMode: false });
      } else {
        this.state.changedFusebox.templateRenameMap = this.state.changedFusebox.templateRenameMap || {};
        this.setState({ changedFusebox: this.state.changedFusebox, renameMode: true });
      }
    }

    const btnSwitchImage = <span className={'pl-3 mr-3 zoom-75'}>
      <MultiButtonSwitch value={isDiagram ? 'Diagram' : 'Format'} onChange={switchImage} options={['Diagram', 'Format']}/>
    </span>

    const btnRenameMap =  <IconButton icon={'swap_calls'} className={'ml-1'} level={renameMode ? 'success' : 'primary'} onClick={addRenameMap} title={'Rename fuses'}></IconButton>;

    let diagramMenuItems = <>
      <span>
        {btnChangePic}
        {!templateId && boxReferenceImg && btnSwitchImage}
      </span>

      <span>
        {!templateId && btnSelectFuses }
        {!templateId && btnCreateRows}
        {!templateId && btnAssignFuses}
        {!templateId && this.props.lego.type === 'fuseboxTemplate' && btnRenameMap }
        {!templateId && btnPerspective}
      </span>

      <span>{btnZoom}</span>
    </>


    let finalFusebox = fusebox;

    let diagramEditorOrTemplate = null;
    if(templateId) {
      let templateFuseboxDiagram =  <div className={'full-screen-center bg-dark text-white'}>Loading fusebox template #{templateId}...</div>;
      if(templateData) {
        finalFusebox = this.buildFinalFuseboxWithTemplate();

        if (renameMode) {
          templateFuseboxDiagram = <ImagePreview url={boxDiagramImg?.url || ''} fluidContain/>;
        } else {
          templateFuseboxDiagram = <FuseboxDiagram development={true} fusebox={templateData}
                                                   onSelectFuse={(fuse) => this.selectFuse([fuse])}/>;
        }
      }

      const refreshTemplate = () => this.context.page.runAsync(this.fetchTemplateData(templateId));

      const removeTemplate = () => {
        delete this.state.changedFusebox.templateId;
        return this.setState({changedFusebox: this.state.changedFusebox, templateData: null});
      }


      diagramEditorOrTemplate =<TopBarLayout style={{filter: 'sepia(0.6)'}}>
        <div className={'p-2 bg-dark text-white'}>
          <div className={'mb-2 small'}>
            <Icon icon={'fingerprint'}/>

            <span className={'inline-block align-middle'}>
              <div>
                 Template <span className={'small text-secondary ml-1'}>{templateId}</span>
                {!_.isEmpty(templateRenameMap) ? <span className={'ml-1 badge badge-info'}>REMAPPED</span> : null}
                {templateContext ? <span className={'zoom-90 ml-1'}><VehicleSummary vehicle={templateContext[0]}/></span> : null}
              </div>
            </span>

          </div>

          <IconButton icon={'swap_horiz'} className={'ml-1'} onClick={() => this.searchTemplates()}>Change</IconButton>

          <LegoEditButton title={'Open template'} icon={'edit'} legoId={templateId} extraQuery={'openedFusebox=0'} editorProps={{onAfterSave: refreshTemplate}}/>

          <IconButton icon={'refresh'} className={'ml-1'} onClick={refreshTemplate} title={'Reload template'}></IconButton>

          <IconButton icon={'clear'} className={'ml-1'} level={'danger'} onClick={removeTemplate} title={'Remove template'}></IconButton>

          {templateData ?
            <IconButton icon={'compare'} className={'mr-2'}
                        onClick={() => this.openImagesModal(templateData.boxDiagramImg?.url, fusebox.boxDiagramImg?.url)}
                        title={'Compare template images'}></IconButton>
            : null}

          {btnRenameMap}
        </div>

        {templateFuseboxDiagram}
      </TopBarLayout>
    } else {
      let imageUrl = this.state.forceShowDiagram ? boxDiagramImg : (boxReferenceImg || boxDiagramImg)

      diagramEditorOrTemplate = <FuseboxDiagramImgProcessor fuses={fuses}
                                                            expanded={expanded}
                                                            aspectRatio={boxAspectRatio}
                                                            templateRenameMap={templateRenameMap}
                                                            renameMode={renameMode}
                                                            imageUrl={(imageUrl || {}).url}
                                                            ref={this.imageProcessorRef}
                                                            assigningFuses={assigningFuses ? unassignedFuses : null}
                                                            onChange={(fuses, boxAspectRatio, templateRenameMap) => this.fuseboxDiagramChanged(fuses, boxAspectRatio, templateRenameMap)}/>;
    }

    let vertical = boxAspectRatio > 1.1;

    let backgroundStyle = hasChanged ? ' bg-light-warn' : ''
    let verticalStyle = vertical ? ' FuseboxGrid2to1--vertical' : '';
    let expandedStyle = expanded ? ' FuseboxGrid2to1--expanded' : '';

    let referenceImage;
    if (imageClusters?.length || finalFusebox.imageClusters?.length) {
      let clusterIds = _.union(imageClusters, finalFusebox.imageClusters);

      referenceImage = <div>{ clusterIds.map(id => <span className={'no-wrap'} key={id}>
        <ImageClusterPreview maxImages={clusterIds.length > 1 ? 1 : 5} clusterId={id}/>
        {_.includes(imageClusters, id) ?
          <IconButton icon={'delete'}
                      onClick={() => this.editField('imageClusters', _.without(imageClusters, id))}/> : null }
          < /span>)}</div>;

      if(!imageClusters?.length) {
        referenceImage = <div>{referenceImage} <div className="small text-secondary">(cluster from template)</div></div>
      }
    }
    const sortByIsMissingInTemplate = f => _.find(templateData?.fuses, {id: f.id}) ? 1 : 0;
    let fuseToRename =  renameMode && _.sortBy(_.filter(fusebox.fuses, f => !(templateRenameMap || {})[f.id]), sortByIsMissingInTemplate)[0];

    let renameModeMarks = null;
    if(renameMode && templateRenameMap) {
      // Mark each renamed fuse with its original name
      const remappedFuses = _.filter(fusebox.fuses, f => templateRenameMap[f.id]);
      renameModeMarks = _.fromPairs(remappedFuses.map(f => [templateRenameMap[f.id], f.id]));
    }

    const onDiagramSelectFuse = (fuse) => {
      if(renameMode && fuseToRename && fuse) {
        this.editField('templateRenameMap', { ...templateRenameMap, [fuseToRename.id]: fuse.id });
      } else {
        this.selectFuse(fuse ? [fuse] : []);
      }
    };

    const onTableFuseSelection = (selection) => !renameMode && this.selectFuse(selection, true);

    return <div className={'bg-light fusebox-editor'}>
      <div className={'FuseboxGrid2to1' + backgroundStyle + verticalStyle + expandedStyle}>
        <div className={'FuseboxGrid2to1__topLeft bg-secondary'}>
          <div className={'bg-light d-flex justify-content-between'} style={{height: '32px', borderBottom: 'solid #333 1px'}}>
            {diagramMenuItems}
          </div>

          <div className={'position-absolute bg-secondary'} style={{top: '32px', width: '100%', left: 0, height: 'calc(100% - 32px)'}}>
            {diagramEditorOrTemplate}

            {uploadLayer}
          </div>
        </div>


        <div className={'FuseboxGrid2to1__bottomLeft bg-dark'}>
          {renameMode ? this.getRenameModeBar(fuseToRename, templateRenameMap) : null}

          <div className={vertical ? 'zoom-75 float-bottom-right p-2 bg-dark' :  'float-top-right zoom-75 p-2 bg-dark'}>
            <span className={`text-${backgroundColor ? 'white' : 'secondary'} ml-3`}>Color: &nbsp;</span>
            <ColorPicker value={finalFusebox.backgroundColor} onChange={(v)=> this.editField('backgroundColor', v)}/>

            <span className={`text-${boxScaleMultiplier ? 'white' : 'secondary'} ml-3`}>Scale: </span>
            <input className={'align-middle mr-2 ml-2 text-right text-info'} style={{width: '40px'}}
                   placeholder={finalFusebox.boxScaleMultiplier || 1} type="text"
                   value={boxScaleMultiplier}
                   onChange={(e) => this.editField('boxScaleMultiplier', e.target.value)}/>


            <span className={`text-${boxRotation ? 'white' : 'secondary'} ml-3`}>Rotation: </span>
            <span className={`text-${boxRotation? 'info' : 'secondary'} ml-3`}>{finalFusebox.boxRotation || '0'}</span>
            <IconButton icon={'rotate_90_degrees_cw'} onClick={()=> this.editField('boxRotation', (((boxRotation || 0)+90) % 360 || null))}/>
          </div>

          <FuseboxDiagram ref={this.fuseDiagramRef}
                          development={true}
                          key={`${vertical} ${expanded}`} // Ensure dimensions are updated if layout changes
                          fusebox={finalFusebox}
                          onSelectFuse={onDiagramSelectFuse}
                          marks={renameModeMarks}/>
        </div>


        <div className={'FuseboxGrid2to1__right'}>
          <div className={'row m-0 pt-2 pb-2'}>
            <div className={'col-6 '}>
              {referenceImage}
              <div className={'text-info small text-center'}>
                <strong>{this.getContextIdentifier()} </strong>
                <a href={'#'} className={'ml-2'} onClick={() => this.openSearchClusters()}>Find clusters...</a></div>
            </div>

            <div className={'col-6 text-center'}>
              <SingleTextEditor className={'mb-2'} onChange={(text) => this.editField('boxName', text)}
                                placeholder={'Fusebox name...'}
                                value={boxName}/>

              <TextFieldEditor showCounter={false} placeholder={'Description...'} minRows={1} rows={4}
                               onChange={(text) => this.editField('boxDescription', text)}
                               value={boxDescription || ''}/>

              <div className={'text-left'}>
                <SwitchInput label={'Replace acronyms'} onChange={v => this.toggleUseAcronyms(v)} value={fusebox.useAcronyms || false}/>
                {fusebox.useAcronyms ? <IconButton icon={'refresh'} onClick={() => this.updateAcronyms()} title={'Update acronyms'}/> : null}
              </div>

              {hasChanged ?
               <div> <button className={'btn btn-primary mt-2'} onClick={() => this.context.page.runAsync(this.parseAndSaveDiagram(), 'Parsing fusebox...')}>Apply changes and
                 close</button></div> : null}


            </div>
          </div>

          <FuseboxTableEditor contextId={this.getContextIdentifier()}
                              fuses={fuses}
                              ref={this.fuseTableRef}
                              templateRenameMap={templateRenameMap}
                              templateFuses={templateData?.fuses}
                              selectedFuses={this.state.selectedFuses}
                              tableImages={boxTablesImages}
                              useAcronyms={fusebox.useAcronyms}
                              onRenameMapChange={(map) => this.editField('templateRenameMap', map)}
                              onChange={(fuses, fuseTableImgs, descriptionChanged) => this.onFuseboxTableChanges(fuses, fuseTableImgs, descriptionChanged)}
                              onFuseSelection={onTableFuseSelection}
          />

          <div className={'p-2'}>
            Fusebox table image sources:
            <MultipleImagesEditor folder={'fusebox-tables'} forceJPG={true} category={'fusebox-table'} value={boxTablesImages}
                                  onChange={(images) => this.editField('boxTablesImages', images)}/>
          </div>
        </div>
      </div>

      <ModalManager actionsBus={this.modalActionsBus}/>
      {/*<JsonTextEditor rows={25} small className={'monospace'}  onChange={this.props.onChange} value={this.props.value}/>*/}
    </div>;
  }

  updateAcronyms() {
    let locales = this.props.lego.locales;

    return this.context.page.runAsync(async () => {
      let acronyms = await this.context.page.service('/services/data/fusebox-acronyms').find({
        query: {
          fuses: this.state.changedFusebox.fuses,
          locale: locales[0]
        }
      });

      _.each(this.state.changedFusebox.fuses, (f, i) => {
        if (acronyms[i]) {
          f.extendedDescription = acronyms[i];
        } else {
          delete f.extendedDescription;
        }
      });
      this.setState({ changedFusebox: this.state.changedFusebox });
    })
  }

  toggleUseAcronyms(v) {
    if (v) {
      if(this.props.lego.locales.length !== 1) {
        alert(`A fusebox with acronyms can only have one locale, now only ${this.props.lego.locales[0]} will be used. FIX IT`);
      }
      this.updateAcronyms();
    }

    this.editField('useAcronyms', v);
  }

  getRenameModeBar(fuseToRename) {
    const fusebox = this.state.changedFusebox;
    const { templateRenameMap } = fusebox;

    const onUndo = () => this.editField('templateRenameMap', _.omit(templateRenameMap, _.keys(templateRenameMap).at(-1)));
    const onDone = () => this.setState({ renameMode: false });
    const onClear = () => this.editField('templateRenameMap', null);

    return <span className={'float-top bg-primary text-white px-2 py-1 m-0'} style={{ zIndex: '100', top: '0' }}>
              Remapping: <span className={'h5 badge badge-dark'}>{fuseToRename?.id}</span>
              <IconButton icon={'undo'} level={'dark'} onClick={onUndo} className={'ml-3'}>Undo</IconButton>
              <IconButton icon={'done'} level={'white'} onClick={onDone} className={'ml-1'}>Done</IconButton>
              <IconButton icon={'clear'} level={'danger'} onClick={onClear} className={'ml-1'}>Clear</IconButton>
           </span>;
  }

  buildFinalFuseboxWithTemplate() {
    const fusebox = this.state.changedFusebox;
    if(fusebox.templateId) {
      fusebox.boxAspectRatio = this.state.templateData.boxAspectRatio;
      return extendFuseboxWithTemplate(fusebox, this.state.templateData);
    } else {
      return fusebox;
    }
  }
}

FuseboxDiagramEditor.contextType = LegoAdminPageContext;
