import __ from './i18n';
import { normalize } from './utils';

export const isCountryCode = (code) => code?.length === 2 || code === 'other' || code === 'global';

// export for overriding this functions on front and back using specific i18n
export const getCountryName = (countryCode) => {
  if (isCountryCode(countryCode)) {
    const countryName = __(`countryCodeToName.${countryCode.toLowerCase()}`);
    if (!countryName.startsWith('countryCodeToName')) {
      return countryName;
    }
  }
  return countryCode;
};

export const getCountryCode = (countryName) => {
  if (!isCountryCode(countryName)) {
    const countryCode = __(`countryNameToCode.${normalize(countryName)}`);
    if (!countryCode.startsWith('countryNameToCode')) {
      return countryCode;
    }
  }
  return countryName;
};
