import React, { Component } from 'react';

import _ from 'lodash';

import SingleTextEditor from '../common/editors/SingleTextEditor';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { LegoPreview } from './LegoPreview';
import InputMultipleSemantics from '../common/editors/InputMultipleSemantics';
import ModalManager from '../ModalManager';
import EventEmitter from 'events';
import LegoEditor from './LegoEditor';
import { IconButton } from '../common/IconButton';
import LegoContextSummary from './LegoContextSummary';
import MultiButtonSwitch from '../common/editors/MultiButtonSwitch';
import SemanticTag from '../semantic/SemanticTag';

export default class SymptomLegoEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      causes: _.map(this.props.value?.causes, c => {
        return { semantics: c, data: {} };
      }),
      dataTypesMap: {
        HOWTO_KNOW_IF: 'How to identify',
        WHAT_IS: 'What is this',
        WHY_FAILS: 'Why it fails',
        HOWTO_SOLVE: 'How to solve'
      }
    };

    this.legoService = window.client.service('/services/legos');
    this.modalActionsBus = new EventEmitter();
  }

  componentDidMount() {
    this.context.page.runAsync(this.updateCausesData(_.map(this.state.causes, 'semantics')));
  }

  onChange(field, newValue) {
    this.props.onChange({ ...this.props.value, [field]: newValue });
    if (field === 'causes') {
      this.context.page.runAsync(this.updateCausesData(newValue));
    }
  }

  async updateCausesData(causes) {
    if (this.props.semantics){
      const res = await this.legoService.find({ query: { type: 'text', semantics: { $in: `WHAT_IS(what=${this.props.semantics})` } } });
      if (res){
        this.setState({whatIsLegos: res})
      }
    }

    if (causes) {
      let newData = [];
      const dataTypes = Object.keys(this.state.dataTypesMap);
      for (const cause of causes) {
        let semantics = _.map(dataTypes, dataType => `${dataType}(what=${cause})`).concat(`WHAT_IS(what=${cause})`);

        const resSymptom = await this.legoService.find({ query: { type: 'symptom', semantics: { $in: cause } } });
        const resText = await this.legoService.find({ query: { type: 'text', intention: 'guide', semantics: { $in: semantics } } });

        let data = {};
        for (const dataType of dataTypes) {
          data[dataType] = _.filter(resText, lego => _.some(lego.semantics, sem => sem.includes(dataType)));
        }
        newData.push({ semantics: cause, symptoms: resSymptom, data });
      }
      this.setState({ causes: newData });
    } else {
      this.setState({ causes: [] });
    }
  }

  async updateOrCreate(obj, changedObj) {
    // changedObj will have _id if is an update, otherwise is a save on an new unsaved original obj
    if (changedObj._id) {
      changedObj.updatedBy = this.context.page.getLoggedUserSignature();
      return await this.legoService.update(changedObj._id, changedObj);
    } else {
      changedObj.createdBy = this.context.page.getLoggedUserSignature();
      changedObj.updatedBy = changedObj.createdBy;
      return await this.legoService.create(changedObj);
    }
  }

  async openEditCreateLegoModal(legoData) {
    let lego = legoData;
    if (lego._id) {
      lego = (await this.legoService.find({ query: { _id: lego._id } }))[0];
    }

    this.modalActionsBus.emit('open', <div>
      <LegoEditor lego={lego} onSave={async (changedLego, closeDialog) => {
        try {
          let res = await this.updateOrCreate(lego, changedLego);
          if (closeDialog) {
            this.modalActionsBus.emit('close');
          }
          this.context.page.runAsync(this.updateCausesData(_.map(this.state.causes, 'semantics')));
          return res;
        } catch (err) {
          console.log(err);
        }
      }} onCancel={() => {
        return this.modalActionsBus.emit('close');
      }}
      />
    </div>);
  }

  render() {
    let { title, causes, riskLevel } = this.props.value || { content: '' };
    const warningLightsUI = this.state.symptomsType === 'warning-lights'

    const EditableLegoPreview = ({ lego }) => {
      if (lego) {
        return <div onClick={() => this.openEditCreateLegoModal(lego)}>
          {_.map(lego.intentions, i => {
            if (i.match('level')) {
              return <span key={i} className={'inline-block badge badge-info'}>{i}</span>;
            }
          })}
          {_.map(lego.context, i => {
              return <LegoContextSummary className={'inline-block ml-1'} key={i} context={i}/>;
          })}
          {lego.data?.riskLevel ? <RiskLevelSelector className={'disabled-zone zoom-75'} value={lego.data.riskLevel} /> : null}
          <LegoPreview lego={lego}/>
        </div>;
      }
    };

    const RiskLevelSelector = ({onChange, value, ...otherProps}) => {
      const levelsMap = {'low': 1, 'medium': 2, 'high': 3}
      return <span title={'Risk level'} {...otherProps}><MultiButtonSwitch value={_.invert(levelsMap)[value]} onChange={val => onChange && onChange(levelsMap[val])} options={Object.keys(levelsMap)}/></span>
    }
    const CausesTable = () => {
      return <div>
        <table className={'table table-sm'}>
          <thead>
          <tr>
            <th style={{ width: '20%' }}>Cause Title:</th>
            {_.map(this.state.dataTypesMap, dt => <th key={dt} style={{width: `${80 / this.state.dataTypesMap.length}%`}}>{dt}:</th>)}
          </tr>
          </thead>

          <tbody>

          {_.map(this.state.causes, (cause, i) => {
            const dataCols = _.map(Object.keys(this.state.dataTypesMap), (dataType, col) => {
              let legos = cause.data[dataType];

              const onClickHandler = () => {
                this.openEditCreateLegoModal({
                  type: 'text',
                  semantics: [`${dataType}(what=${cause.semantics})`],
                  locales: [window.config.locale],
                  state: 'published',
                  intentions: ['guide']
                })
              }

              return <td key={i + dataType}>
                {_.map(legos, l => <div key={l._id} className={'mt-1 ml-1 border-bottom pb-1 cursor-pointer'}>
                  <EditableLegoPreview lego={l}/>
                </div>)}
                <IconButton level={'success'} icon={'add_circle'}
                            description={'Add Lego'}
                            onClick={onClickHandler}
                />
              </td>;
            });

            const symptomLegoTemplate = {
              type: 'symptom',
              semantics: [cause.semantics],
              locales: [window.config.locale],
              data: { title: `Create SymptomLego for ${cause.semantics}` },
              state: 'published',
              ... warningLightsUI ? { intentions: ['warning-lights'] } : {}
            };

            let causeTitle;
            if (cause.symptoms?.length) {
              causeTitle = _.map(cause.symptoms, s =>
                <div key={s.data.title} className={'mt-1 ml-1 border-bottom pb-1'}>
                  <span className={'cursor-pointer'}><EditableLegoPreview lego={s}/></span>
                  <IconButton level={'success'} icon={'add_circle'}
                              description={'Add Lego'}
                              onClick={() => this.openEditCreateLegoModal(symptomLegoTemplate)}
                  />
                </div>);
            } else {
              causeTitle = <div className={'bg-light-warn border-bottom mb-1 cursor-pointer'}>
                <EditableLegoPreview lego={symptomLegoTemplate}/>
              </div>;
            }

            return <tr key={i}>
              <td>
                <div>
                  <SemanticTag level={'primary'} semKey={cause.semantics}/>
                  {causeTitle}
                </div>
              </td>
              {dataCols}
            </tr>;
          })}
          </tbody>
        </table>
      </div>;
    };

    return <div className={'pl-3'}>
      <div className={''}>
        <div className={'mb-2 row'}>
          <SingleTextEditor label={'Title'} className={'font-weight-bold' + warningLightsUI ? ' col-8' : ''} onChange={val => this.onChange('title', val)}
                            value={title}/>
          {warningLightsUI ? <div className={"input-group font-weight-bold align-items-center col-3"}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">Risk level</span>
            </div>
            <RiskLevelSelector value={riskLevel} onChange={val => this.onChange('riskLevel', val)}/>
          </div> : null}
        </div>

        {warningLightsUI ? <div className={'mb-2'}>
          <div className={"input-group font-weight-bold"}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">What is?</span>
            </div>
            {this.state.whatIsLegos?.length ?
              _.map(this.state.whatIsLegos, lego =>
                <div key={lego._id} className={'mt-1 ml-1 cursor-pointer'}>
                  <EditableLegoPreview lego={lego}/>
                </div>)
              :
              null
            }
            <IconButton level={'success'} icon={'add_circle'}
                        description={'Add WHAT_IS Lego'}
                        onClick={() => this.openEditCreateLegoModal({
                          type: 'text',
                          semantics: [`WHAT_IS(what=${this.props.semantics})`],
                          locales: [window.config.locale],
                          data: '',
                          state: 'published',
                          intentions: ['guide']
                        })}
            />
          </div>
        </div> : null}

        <div className={'input-group mb-2'}>
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">{warningLightsUI ?  'Light behaviour' : 'Causes'}</span>
          </div>
          <InputMultipleSemantics className={'flex-grow-1'} value={causes || []}
                                  onChange={val => this.onChange('causes', val)}/>
          <IconButton level={'success'} icon={'refresh'}
                      description={'Refresh causes data table'}
                      onClick={() => this.context.page.runAsync(this.updateCausesData(_.map(this.state.causes, 'semantics')))}
          />
        </div>
      </div>
      <CausesTable/>
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}

SymptomLegoEditor.contextType = LegoAdminPageContext;
