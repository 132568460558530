import EventEmitter from 'events';

export default class GlobalDataCache extends EventEmitter {
  constructor() {
    super();

    // loadData in next event loop pass just in case subclasses need to do stuff in their constructor overloads
    setTimeout(() => {
      this.loadData().then(data => {
        if(data !== undefined) {
          this.dataLoaded = true;
          this.lastData = data;
          this.emit('dataUpdate', data);
        }
      }).catch(console.error);
    })
  }

  onDataUpdate(cbk) {
    if (this.dataLoaded) {
      cbk(this.lastData);
    }
    this.on('dataUpdate', cbk);
  }

  dataUpdate(newData) {
    this.lastData = newData;
    this.emit('dataUpdate', this.lastData);
  }

  async loadData() {
    throw new Error('Should be implemented by subclass');
  }
}
