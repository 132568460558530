import React, {Component} from 'react';

import {Icon} from "./Icon";
import FileReaderInput from './FileReaderInput';

export default function ImportJSONButton({ classes, level,description, title, onChange, multi, children }) {
  title = title === undefined ? "Load JSON" : title;
  classes = classes || "btn btn-link btn-sm";

  const onFileLoad = (e, results) => {
    let files = results.map(result => {
      const [e, file] = result;
      // this.props.dispatch(uploadFile(e.target.result));
      try {
        return JSON.parse(e.target.result);
      } catch (err) {
        alert(`There was a problem loading file ${file.name}: ${err.toString()}`);
      }
    });

    if(multi)
      onChange(files);
    else
      onChange(files[0]);
  }

  let label = children?.length ? children : description || '';

  return <span className={'inline-block'} title={title}><FileReaderInput as="text" id="my-file-input" onChange={onFileLoad} accept="application/json" multiple={multi ? "multiple" : undefined}>
    <button className={classes} type='button' title={title}>
      <Icon level={level} icon={'playlist_add'}/>
      {label ? <span className={'ml-1'}>{label}</span> : null }
    </button>
  </FileReaderInput>
    </span>
}
