import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { ImagePreview } from './ImagePreview';
import { IconButton } from '../common/IconButton';
import CountryFlag from '../common/CountryFlag';
import { SwitchInput } from '../common/SwitchInput';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import TemplatedFuseboxDiagram from '../lego/fusebox-editor/TemplatedFuseboxDiagram';
import { DataIssuesFinder } from '../data-issues/DataIssuesFinder';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import FuseboxDiagram from '../common/FuseboxDiagram';

function ImageCluster({ cluster, selected, onImgSelect }) {
  const [maxShown, setMaxShown] = useState(8);
  const { images } = cluster;
  const selectedCluster = _.some(cluster.images, i => i.id === selected?.id);

  const sortedImages = _.sortBy(images, img => img.vehicle?.year);

  const seeMoreBtn = <span className={'btn btn-link p-1 align-self-center'} onClick={() => setMaxShown(maxShown+100)}>
    {sortedImages.length - maxShown}&nbsp;more...
  </span>;

  return <div className={`border overflow-auto bg-${selectedCluster ? 'light-primary' : 'light'} rounded p-1`}>
    <span style={{ minHeight: '100px' }} className={'d-flex align-items-top'}>

      {_.map(sortedImages.slice(0, maxShown), img => {
        let isSelected = selected.id === img.id;
        const vehicleString = _.values(_.omit(img.vehicle, 'trimId')).map(v => _.isObject(v) ? _.values(v).join(' ') :  v).join(' ');
        return <span className={`inline-block ${isSelected ? 'bg-primary' : ''}`} onClick={() => onImgSelect(img)}
                     key={img.id} title={vehicleString}>
          <div className={'small'}>
            <CountryFlag countryCode={(img.vehicle.countryCode || '').toLowerCase()}/>&nbsp;{img.vehicle.year}
          </div>
          <ImagePreview key={img.id} url={img.url || ''} className={'mr-1 bg-light'} selected={isSelected} zoom/>
        </span>;
      })}
      { sortedImages.length > maxShown ? seeMoreBtn : null }
  </span>
  </div>
}

function ClusterTemplateIdManager({ workingTemplate, templates, cluster }) {
  const { page } = useContext(LegoAdminPageContext);
  const legoFuseboxClusteringService = page.service('/services/legos/fusebox-templates-clustering');
  // const [cachedTemplates, setTemplates] = useState(templates);
  let cachedTemplates = templates;
  const onAddClusterToTemplate = () => {
    if (workingTemplate) {
      page.runAsync(legoFuseboxClusteringService.update({ query: { _id: workingTemplate._id } }, { addId: cluster._id })).
           then(() => workingTemplate.data[0].imageClusters.push(cluster._id));
    }
  };

  const removeTemplate = (template) => {
    page.runAsync(legoFuseboxClusteringService.remove({ query: { _id: template._id, removeId: cluster._id } }));
    template.data[0].imageClusters = _.without(template.data[0].imageClusters, cluster._id);
  };

  let renderTemplates = _.map(cachedTemplates, template => {
    return <span key={template._id}>
      <div className={`ClusterTemplate`}>
        <div className={'ClusterTemplate ClusterTemplate--Buttons'} title={'Fusebox templates'}>
          <IconButton icon={'remove_circle'} level={'danger'} onClick={() => removeTemplate(template)}
                      title={'Remove fusebox templates from cluster'}/>
        </div>
        <div>
          {template.fuseboxRef?.boxDiagramImg?.url ?
            <ImagePreview url={template.fuseboxRef.boxDiagramImg.url}/>
            :
            (template.fuseboxRef ? <TemplatedFuseboxDiagram hideTittle={true} fusebox={template.fuseboxRef}/> :
              <FuseboxDiagram hideTittle={true} fusebox={template.data[0]}/>)
          }
      </div>
    </div>
  </span>;

  });

  let addBtn;
  if (workingTemplate) {
    addBtn = <IconButton icon={'note_add'} level={'success'} onClick={onAddClusterToTemplate}
                         title={'Add cluster to selected fusebox template'}/>;
  }

  return <span className={'d-flex align-items-top'}>{addBtn}{renderTemplates}</span>;
}

export function ImageClustersEditor({ context, clusterType, selectedImage, onImgSelect, onClusterChange, editMode, onChangeEditMode, workingTemplateId, templates, dataFeedbackId, ...props }) {
  const { page } = useContext(LegoAdminPageContext);

  const clusterSvc = page.service('services/data/image-clusters');
  const legoFuseboxClusteringService = page.service('/services/legos/fusebox-templates-clustering');
  const dataIssuesService = page.service('/services/data/fusebox-data-issues');
  const dataFeedbackService = page.service('/services/data/data-feedback');
  const legosService = page.service('/services/legos')

  const [clusters, setClusters] = useState(null);

  const fetchClusters = async () => clusterSvc.find({
    query: {
      'context.modelId': context.modelId,
      clusterType
    }
  }).then(clusters => setClusters(clusters));

  const updateCluster = (clusterId) => page.runAsync(clusterSvc.find({query: {_id: clusterId}}).then(updatedClusters => {
    if(updatedClusters.length === 1) {
      setClusters(cs => cs.map(c => c._id === clusterId ? updatedClusters[0] : c));
      return updatedClusters[0];
    }
  }));

  let clusterIds = (clusters || []).map(c => c._id);

  useAsyncEffect(fetchClusters, [JSON.stringify(context), clusterType]);

  const removeFromCurrentCluster = async () => {
    //Added multiple currentClustersId to reacomodate images repeated in multiple clusters since previous bug in this function.
    let currentClustersIds = [];
    let shouldRemoveEmptyCluster = false;
    for (const cluster of clusters) {
      if (_.find(cluster.images, clusterImage => clusterImage.id === selectedImage.id)) {
        currentClustersIds.push(cluster._id)
        if (cluster.images.length === 1) {
          shouldRemoveEmptyCluster = true;
        }
        // Avoid querying cluster again
        cluster.images = _.filter(cluster.images, clusterImage => clusterImage.id !== selectedImage.id)
      }
    }

    if (currentClustersIds.length) {
      for (const currentClusterId of currentClustersIds) {
        if (shouldRemoveEmptyCluster) {
          let fuseboxTemplate = await page.runAsync(legoFuseboxClusteringService.find({ query: { 'data.imageClusters': currentClusterId } }));
          if (fuseboxTemplate.length) {
            const legoIds = _.map(fuseboxTemplate, '_id');
            await legoFuseboxClusteringService.remove({ query: { _id: { $in: legoIds }, removeId: currentClusterId } });
          }

          //TODO: consultar dataIssues que contienen este cluster, eliminarle el dataFeedbackId de selectedImage y el id de cluster
          let dataIssues = await page.runAsync(dataIssuesService.find({ query: { 'data.clusters': { $in: [currentClusterId] } } }));
          if (dataIssues.length) {
            // let dataFeedbackId = await page.runAsync(dataFeedbackService.find({query: {'data.clusters': {$in: [currentClusterId]}}}))
            // _.each(dataIssues)
          }

          setClusters(cs => cs.filter(c => c._id !== currentClusterId));

          await clusterSvc.remove(currentClusterId);
        } else {
          //TODO: consultar dataIssues que contienen este cluster, eliminarle el dataFeedbackId de selectedImage
          await clusterSvc.update(currentClusterId, {
            $pull: { images: _.pick(selectedImage, 'id', 'vehicle') },
            $set: { updatedBy: page.getLoggedUserSignature().id },
          });
          updateCluster(currentClusterId);
        }
      }
    }
  };

  const addToCluster = async (c) => {
    if(!_.find(c.images, {id: selectedImage.id})) {
      await removeFromCurrentCluster();

      await clusterSvc.update(c._id, {
        $push: { images: selectedImage },
        $set: { updatedBy: page.getLoggedUserSignature().id },
      });


      c = await updateCluster(c._id);
    }

    onClusterChange(c, selectedImage);
  };

  const removeFromCluster = async(c) => {
    await removeFromCurrentCluster();
    onClusterChange(null, selectedImage);
  }

  const onCreateGroup = async () => {
    await removeFromCurrentCluster();

    let res = await clusterSvc.create({
      clusterType,
      images: [selectedImage],
      context: {modelId: context.modelId},
      createdBy: page.getLoggedUserSignature().id,
      updatedBy: page.getLoggedUserSignature().id,
    });

    setClusters(cs => [... cs, res])
    res = await updateCluster(res._id);

    onClusterChange(res, selectedImage);
  };



  if (!clusters) {
    return <div className={'text-center py-3'}>Loading image cluster...</div>;
  } else {
    let createRow = null;
    if (selectedImage) {
      createRow =
        <LeftRightLayout>
          <span className={'flex-grow-1 text-center bg-light-success rounded m-2 ml-5'}>
            <IconButton icon={'add'} level={'success'} onClick={() => page.runAsync(onCreateGroup())}>
              Create new group with image
            </IconButton>
          </span>
          <SwitchInput value={editMode} onChange={onChangeEditMode}>Move any image mode &nbsp;</SwitchInput>
        </LeftRightLayout>;
    }

    let byId = _.keyBy(templates, '_id');
    let byClusterId = {};
    templates.forEach(t => t.data[0] && t.data[0].imageClusters?.forEach(cId => (byClusterId[cId] = byClusterId[cId] || []).push(t)));

    return <div>
      {createRow}

      {_.map(_.sortBy(clusters, c => -c.totalImages), c => <div key={c._id} className={'m-1 d-flex align-items-center'}>
        <div style={{width: '40px'}}>
          {
            !_.find(c.images, {id: selectedImage.id})
              ?
              <IconButton icon={'add'} level={'success'} onClick={() => addToCluster(c)}/>
              :
              <IconButton icon={'remove'} level={'danger'} onClick={() => removeFromCluster(c)}/>
          }
          {/*<IconButton icon={'format_list_bulleted'} title={'Open DataIssues for this cluster'} onClick={() => page.modalActionsBus.emit('open', <DataIssuesFinder cluster={c} />)}/>*/}
        </div>

        <ClusterTemplateIdManager workingTemplate={byId[workingTemplateId]} templates={byClusterId[c._id]} cluster={c}/>

        <div className={'flex-grow-1 overflow-hidden'}>
          <ImageCluster cluster={c} selected={selectedImage} onImgSelect={(img) => onImgSelect(img)}/>
        </div>
      </div>)}

      {clusters.length ? null : <div className={'text-center'}>No clusters found</div>}
    </div>
  }
}
