import React, { Component, useContext } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import submenuModels from './menus/submenu-models';
import CRUDPage from './CRUDPage';
import { DataIssuesFiltersBar } from '../components/data-issues/DataIssuesFiltersBar';
import NiceDate from '../components/common/NiceDate';
import BadgeId from '../components/common/BadgeId';
import { ImagePreview } from '../components/images/ImagePreview';
import MongoDocEditor from '../components/lego/MongoDocEditor';
import { DataIssuesStatistics } from '../components/data-issues/DataIssuesStatistics';
import { SearchFilterButton } from '../components/common/SearchFilterButton';
import { TypeTag } from '../components/lego/TypeTag';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import { SingleImageUrlEditor } from '../components/common/images/SingleImageUrlEditor';
import { StateBadge } from '../components/data-issues/StateBadge';
import { LegoPreview } from '../components/lego/LegoPreview';
import { IntentionTag } from '../components/lego/IntentionTag';
import SemanticTag from '../components/semantic/SemanticTag';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import CountryFlag from '../components/common/CountryFlag';
import TrimSpec from '../components/lego/trims/TrimSpec';
import MultiCountryEditor from '../components/common/editors/MultiCountryEditor';
import LegoAdminPageContext from './legoAdminPageContext';
import NotesEditor from '../components/common/editors/NotesEditor';
import { Icon } from '../components/common/Icon';

function GenerationsFiltersBar({ filter, onChange }) {
  const { page } = useContext(LegoAdminPageContext)

  const refreshModeloCaches = () => page.runAsync(page.service('/services/data/models').update({}, {}));
  const onFieldChange = (field, value) => {
    let newFilter = _.cloneDeep(filter || {});

    if (field === 'modelId') {
      delete newFilter['generations.genId'];
      delete newFilter.modelId;

      let {genId, modelId, make} = value || {};
      if (modelId) {
        newFilter.modelId = modelId;
      } else if (make) {
        newFilter.modelId = { $regex: '^' + make };
      }

      if (genId) {
        newFilter['generations.genId'] = genId;
      }
    } else {
      if (_.isEmpty(value)) {
        delete newFilter[field];
      } else {
        newFilter[field] = _.cloneDeep(value);
      }
    }

    onChange(newFilter);
  };

  let { modelId, 'generations.genId': genId } = filter || {};

  let context = { };
  if(modelId?.$regex) {
    context = {make: modelId.$regex.slice(1)}
  } else if(modelId) {
    context = {modelId}
  }
  if(genId) {
    context.genId = genId
  }

  return <div className={'d-flex align-items-center'}>
    <span style={{ width: '350px', display: 'inline-block' }} className={'zoom-75 ml-3'}>
      <ModelContextEditor value={context} onChange={(ctx) => onFieldChange('modelId', ctx)}/>
    </span>

    <span className={'ml-3 btn btn-sm btn-warning'} onClick={refreshModeloCaches}>Refresh site generations cache</span>
  </div>;
}

export default class GenerationsByCountryManager extends CRUDPage {
  constructor(props) {
    super(props, '/services/data/models/gensbycountry');

    this.submenu = submenuModels;
    this.state.columnSort = { index: 1, asc: true, sorter: ['modelId'] }
    this.newObjectTemplate = {
      countries: [],
      modelId: null,
      specs: null,
      generations: [
        {
          genId: "",
          name: "",
          fromYear: 0,
          toYear: 0,
        }
      ],
      state: 'inprogress'
    }

    this.modelListService = this.service('/services/data/models')
  }

  getFacetFiltersComponent() {
    return <GenerationsFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Last update', className: 'text-center', sorter: 'updatedAt' },
      { content: 'Model', className: 'text-center', sorter: ['modelId'] },
      { content: 'Countries', className: 'text-center'},
      { content: '', className: 'text-center' },
      { content: 'Generations', className: 'text-center' },
    ];
  }

  getObjectColumns(generation,def,i,allRows) {
    let {
      modelId, submodelOf, countries, generations, specs, state, notes, updatedAt, createdAt, updatedBy
    } = generation;

    let modelChangeStyle = {};
    if(i > 0 && allRows[i-1].modelId !== modelId) {
      modelChangeStyle = {borderTop: '10px solid #555'};
    }

    let genColors = {
      '1': 'bg-light-success',
      '2': 'bg-light-partial-success',
      '3': 'bg-patito',
      '4': 'bg-light-warning',
      '5': 'bg-light-ladrillo',
      '6': 'bg-light-rosa',
      '7': 'bg-light-violeta',
    }


    return [
      <td key={'id'}  style={modelChangeStyle}>
        { updatedAt && <NiceDate t={updatedAt}/> }
        <br/>
        <StateBadge state={state}/>
      </td>,

      <td  key={'modelId'}  className={'text-left'}  style={modelChangeStyle}>
        <LegoContextSummary context={{modelId, ... specs}}/>
       { submodelOf ?  <div>Submodel of: <TrimSpec spec={'modelId'} value={submodelOf}/> </div>: null }
      </td>,

      <td  key={'countries'} className={'text-center'} style={modelChangeStyle}>
        {countries.map(code => code === '*' ?
          <span key={'*'} className={'text-primary mx-1'}>✱</span>
          :
          <CountryFlag key={code} countryCode={code}/> )}
      </td>,

      <td key={'note'} style={modelChangeStyle}>{notes?.length ? <Icon icon={'chat'} level={'primary'}/> : null}</td>,

      <td  key={'context'}  style={modelChangeStyle}>
        <div className={'text-left'}>
          {generations.map(({genId, name, fromYear, toYear, photo, specs}) => {
            let genColorClass = genColors[(genId && genId.match(/@[^@]+@G(\d+).*$/)?.[1])] || 'bg-light-secondary';

            return <div key={genId} className={'p-1 '+genColorClass}>
              <LegoContextSummary inline context={{genId, ... specs}} className={'inline-block'}/>
              <span className={'ml-2 monospace'}>{name || ''}</span>
              {fromYear ? <span className={'ml-2 monospace text-info'}>{fromYear}</span> : null}
              {toYear ? <span className={'monospace text-info'}> - {toYear}</span> : null}
              {photo?.url ? <ImagePreview className={'ml-2'} img50 zoom={true} url={photo?.url}/> : null }
            </div>;
          })}
        </div>
      </td>,

    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    let genIdentifier = obj?.modelId || 'country_vehicle';
    let schema = {
      modelId: (value, onChange) => <ModelContextEditor value={value ? {modelId: value} : null} onChange={ctx => onChange(ctx?.modelId)}/>,
      countries: (value, onChange) => <MultiCountryEditor value={value} onChange={onChange}/>,
      specs: (value, onChange) => <ModelContextEditor value={value} onChange={ctx => onChange(ctx)}/>,
      generations: 'array',
      genId: (value, onChange) => <ModelContextEditor value={value ? {genId: value} : null} onChange={ctx => onChange(ctx?.genId)}/>,
      fromYear: 'number',
      toYear: 'number',
      photo: (value, onChange) => <SingleImageUrlEditor folder={'model-gen-pictures'}
                                                          category={'generation-picture'}
                                                          value={value} defaultName={genIdentifier}
                                                          onChange={onChange}/>,
      submodelOf: (value, onChange) => <ModelContextEditor value={value ? {modelId: value} : null} onChange={ctx => onChange(ctx?.modelId)}/>,
      notes: (value, onChange) => <NotesEditor value={value} onChange={onChange}/>,
    }

    let onSave = (changedObj, ... args) => {
      if(!changedObj.countries?.length) {
        return this.handleError('There must be at least one country')
      }
      if(!changedObj.modelId?.length) {
        return this.handleError('modelId is required')
      }
      if(!_.every(changedObj.generations, gen => gen.genId)) {
        return this.handleError('genId is required');
      }

      // Remove empty name, fromYear, toYear in generations
      changedObj.generations = changedObj.generations.map(gen => _.pickBy(gen));

      return defaultOnSaveCbk(changedObj, ... args);
    }

    return <MongoDocEditor extendSchema={schema} showFields={['specs', 'countries', 'modelId', 'generations', 'notes']} obj={obj} onSave={onSave} onCancel={defaultOnCancelCbk} />
  }

  groupObjectsInRows(objects) {
    let columns = _.uniq(_.flatten(_.flatMap(objects, o => o.generations.map(g => g.genId)))).sort().reverse();

    let columnsIndexes = {};

    for (let i = 0; i < columns.length; i++) {
      columnsIndexes[columns[i]] = i;
    }

    let rows = {};
    _.each(objects, o => o.countries.forEach(c => (rows[c] = rows[c] || []).push(o) ));

    let matrix = _.mapValues(rows, (objs, country) => {
      let values = _.map(columns, () => null);
      _.each(objs, (o) => {
        _.each(o.generations, (gen ,i) => values[columnsIndexes[gen.genId]] = {obj: gen, isReference: i > 0});
      });
      return values;
    });

    return { columns, rows: matrix}
  }

  getGrouppingObjectRowHeader(obj, country) {
    // let { genId, countries } = obj;
    return <div className={'text-nowrap text-center'}>
      <CountryFlag countryCode={country}/>
    </div>
  }

  getGrouppingObjectColumn(gen) {
    return JSON.stringify(gen)
  }

  getObjectCell(gen) {
    return gen ? gen.genId : '-';
  }
}
