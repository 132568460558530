import React from 'react';
import _ from 'lodash';

import ManualUsersUploadEditor from './manuals-extraction/ManualUsersUploadEditor';
import TaskEditorPdfExtraction from './TaskEditorPdfExtraction';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default class TaskEditorManualUsersUpload extends TaskEditorPdfExtraction {
  constructor(props) {
    super(props);

    this.userUploadManualsService = this.service('services/data/manuals/user-upload');
  }

  async listManualsFromTasks(tasks) {
    const originalFiles = _.map(tasks, task => task.input.uploaded_manual.originalFile);
    let filter = {
      'pdfs.name': { $in: _.map(originalFiles, path => path.toString()) }
    };

    let queryFiles = await this.userUploadManualsService.find({ query: filter });
    for (const item of queryFiles) {
      for (const pdf of item.pdfs) {
        pdf.originalUploadUserId = item.userId;
        pdf.originalUploadUserLocale = window.config.locale;
        if (item.userName) {
          pdf.originalUploadUserName = item.userName;
        }
        pdf.originalUploadName = pdf.originalName;
        delete pdf.originalName;
        pdf.originalS3Key = pdf.name;

        pdf.uploadDate = item.date;

        pdf.locales = [window.config.locale];

        pdf.context = [{
          modelId: `${item.marca}-${item.modelo}`,
          year: parseInt(item.anio.toString()) //fix to convert context.year to number
        }];

        if (!pdf.metadata || !pdf.metadata.hash) {
          console.warn(`Falta metadata o hash a originalName "${pdf.originalName}" ${pdf.metadata}`);
        } else {
          let domain = `https://${pdf.metadata.s3Bucket}.s3.amazonaws.com`;
          domain = domain.replace('startmycar-manuals.s3.amazonaws.com', 'manuals.startmycar.com');
          domain = domain.replace('opinautos-manuals.s3.amazonaws.com', 'manuals.opinautos.com');
          pdf.manualPdfUrl = `${domain}/${pdf.name}`;

          pdf.metadata.pages = pdf.metadata.pdfPageCount;
          if (!pdf.metadata.s3Folder) {
            pdf.metadata.s3Folder = 'processed';
          }
          let contributionId = _.find(tasks, task => task.input.uploaded_manual.originalFile === pdf.name)?.input?.contributionId
          if (contributionId){
            pdf.contributionId = contributionId;
          }
        }
      }
    }

    // Ensure files are in the same order as tasks
    let filesById = _.keyBy(_.flatten(_.map(queryFiles, 'pdfs')), o => o.name);
    let files = _.map(originalFiles, file => filesById[file]);

    this.setState({ files }, async () => {
      if (this.state.files && this.state.files.length) {
        let fileIndex = this.state.fileIndex;
        await this.loadManual(fileIndex);
      } else {
        this.setState({ pagesDescription: null, file: null });
        alert(`No manual found matching ${JSON.stringify(filter)}`);
      }
    });
  }

  async loadManual(fileIndex) {
    const file = this.state.files[fileIndex];
    if (!file || !file.metadata) {
      this.setState({
        fileIndex,
        file: null,
        pagesDescription: null,
        autosuggestions: null
      });
      return;
    }
    const fileHash = file.metadata.hash;
    const s3Bucket = file.metadata.s3Bucket;

    const fileName = this.fileName(file);

    this.setState({ fileIndex });

    await sleep(5);

    await this.runAsync((async () => {
      try {
        let pagesDescription = await this.loadPdfSummary(fileHash, s3Bucket);

        let manualHasText = _.sum(pagesDescription.pages.slice(0, 10).map(p => p.allText.length)) > 200;
        let filter;
        if (manualHasText) {
          filter = { 'metadata.textHash': file.metadata.textHash };
        } else {
          filter = { 'metadata.hash': file.metadata.hash };
        }
        let repeatedManual = await this.pdfService.find({ query: filter });

        this.setState({ pagesDescription, file, fileHash, fileName, autosuggestions: null, repeatedManual, error: null }, () => this.computeSuggestions());
      } catch (error) {
        this.setState({ error: error.toString(), pagesDescription: null });
      }
    })(), `Loading ${fileName}...`);

    await sleep(500);

    await this.precacheNextFiles(fileIndex);
  }

  renderExtractionControls() {
    let { file, fileHash, autosuggestions, autosuggestionsExplanation, repeatedManual } = this.state;
    if (file) {
      return <React.Fragment>
        <ManualUsersUploadEditor manual={file}
                                 key={fileHash + autosuggestions + this.state.file.moderationTag + file.uploadDate}
                                 repeatedManual={repeatedManual}
                                 initialChanges={autosuggestions}
                                 onApprove={async (changedManual) => this.runAsync(this.approve(changedManual))}
                                 onReject={async (changedManual, message) => this.runAsync(this.notApproved(changedManual, message))}
                                 onSave={async (changedManual) => this.runAsync(this.save(changedManual))}
        />
        {autosuggestionsExplanation}
      </React.Fragment>;
    } else {
      return 'No manual';
    }
  }

  async save(changedManual) {
    // TODO: Don't set the entire changedManual, select only the fields that could possibly be changed by tool to be safe
    await this.updateManual(changedManual._id, { $set: changedManual });
    await this.updateUploadedManualModerationTag(changedManual.name, 'merged-duplicated');
    await this.doneAndNext();
  }

  async notApproved(changedManual, message) {
    await this.updateUploadedManualModerationTag(changedManual.name, message);
    await this.doneAndNext();
  }

  async approve(changedManual) {
    changedManual.published = true;
    await this.userUploadManualsService.create({ manual: changedManual });
    await this.updateUploadedManualModerationTag(changedManual.name, 'approved');
    await this.doneAndNext();
  }

  async updateUploadedManualModerationTag(manualName, moderationTag) {
    await this.userUploadManualsService.update(
      { query: { 'pdfs.name': manualName } },
      { $set: { 'pdfs.$.moderationTag': moderationTag } }
    );

    let files = this.state.files;
    files[this.state.fileIndex]['moderationTag'] = moderationTag;
    this.setState({ files });
  }
}

TaskEditorManualUsersUpload.contextType = LegoAdminPageContext;
