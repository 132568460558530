import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import CRUDPage from './CRUDPage';
import DataProcessTemplateEditor from '../components/dataprocess/DataProcessTemplateEditor';
import submenu from './menus/submenu-tasks';

export default class DataProcessDefinitionManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data-process-definition');

    // Override superclass option
    this.openEditFullScreen = true;

    this.submenu = submenu;
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Id', className: '' },
      { content: 'Process Name', className: '' },
      { content: 'Metric', className: '' },
      { content: 'Summary', className: 'text-center' },
    ];
  }

  getObjectColumns(view, definition) {
    let { _id, processType, impactMetric, tasks, states, flows, updatedAt } = view;

    return [
      <td key={'type'}>
        <div>
          <span className={'text-info small'} title={updatedAt}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
        </div>
        <div className={'text-secondary small'}>{_id}</div>
      </td>,

      <td key={'name'}>
        <strong className={'text-primary'}>{processType}</strong>
      </td>,

      <td key={'metric'}>
        <span className={'text-info'}>{impactMetric}</span>
      </td>,

      <td key={'state'}>
        <div className={'text-left'}>
          {(tasks || []).length} tasks, {_.keys((states || {})).length} states and {(flows || []).length} flows
        </div>
      </td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <DataProcessTemplateEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }

  async openEdit(obj) {
    if(obj._id) {
      this.setUrlParams({editing: obj._id})
    }

    this.modalActionsBus.emit('open', <div>
      <DataProcessTemplateEditor obj={obj} onSave={async (changedObj, closeDialog) => {
        try {
          let res = await this.updateOrCreate(obj, changedObj);

          if (closeDialog) {
            this.modalActionsBus.emit('close');
          } else if (obj._id) {
            return await this.refreshObject(obj);
          }
          return res;
        } catch (err) {
          this.handleError(err);
        }
      }} onCancel={() => this.modalActionsBus.emit('close')}/>
    </div>, {
      fullScreen: true,
      onClose: () => this.deleteUrlParam('editing')
    });
  }
}
