import React, {Component} from 'react';

export default function({ progress }) {
  progress = progress || 0;

  const p = Math.max(0,Math.min(1, progress / 100));

  const hueRed = 0;
  const hueGreen = 121;
  const hue = (hueRed+(hueGreen-hueRed)*p);

  const style = {
    width: 100*p + '%',
    backgroundColor: `hsl(${hue}, ${80}%,${60}%)`
  }

  return <span className={'MiniProgressBar'}>
    <span style={style} className={'MiniProgressBar__bar'}>
    </span>
  </span>;
}
