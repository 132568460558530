import React, { Component, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import format from 'date-fns/format';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(duration);

import submenu from './menus/submenu-tasks';
import LivePage from "./LivePage";
import {UserAvatar} from "../components/common/UserAvatar";
import {prettyDuration} from "../components/common/TimeUtils";
import SingleTextEditor from "../components/common/editors/SingleTextEditor";
import { DataTaskTypeSelector } from '../components/tasks/DataTaskTypeSelector';

function DateTextEditor({value, onChange, className, ... other}) {
  const formattedValue = value && format(new Date(value), 'dd MMM yyyy');

  let [textValue, setText] = useState(formattedValue)
  let date = new Date(textValue) // && format(new Date(value), 'yyyy-MM-dd')) || '';

  const valid = textValue && isNaN(date) ? 'bg-warning' : '';

  useEffect(() => {
    if(!isNaN(date)) {
      onChange(date);
    } else if(!textValue) {
      onChange(null);
    }
  }, [textValue])

  return <input type={'text'}
                style={{ width: '180px' }}
                placeholder={'...'}
                className={`form-control inline-block ${valid} ${className || ''}`}
                value={textValue || ''}
                onBlur={() => setText(formattedValue)}
                onChange={e => setText(e.target.value)} {...other}
  />;
}

function DateRangePicker({valueFrom, valueTo, onChange}) {
  return <div className={'no-wrap'}>
    From: <DateTextEditor value={valueFrom} onChange={(v) => onChange({from: v, to: valueTo})}/>
    &nbsp; To: <DateTextEditor value={valueTo} onChange={(v) => onChange({from: valueFrom, to: v})}/>
  </div>
}

function FiltersBar({value, onChange}) {
  let {from, to, userId, taskType} = value || {};

  const set = (field, newValue) => {
    onChange({... value, [field]: newValue || ''});
  }

  return <div className={'bg-secondary p-2 d-flex'} style={{gap: '10px'}}>
    <DateRangePicker valueFrom={from} valueTo={to} onChange={({from, to}) => {
      onChange({... value, from: from ? new Date(from) : '', to: to ? new Date(to) : ''});
    }} placeholder={'To date'}/>

    <SingleTextEditor value={userId} onChange={(text) => set('userId', text)}  placeholder={'User...'}/>
    <div className={'w-100'}>
      <DataTaskTypeSelector value={taskType} onChange={(text) => set('taskType', text)}/>
    </div>
  </div>
}


export default class DataTasksBatchesStatistics extends LivePage {
  constructor(props) {
    super(props);

    this.statsService = this.service('services/data-tasks/batches')
    this.state.stats = null;
    this.state.filters = {  from: '', to: '', userId: '', taskType: '' , ... (this.getUrlParam('filters') || {}) };

    this.submenu = submenu;
  }

  componentDidMount() {
    super.componentDidMount();

    this.statsService.get('userTimes').then(stats => this.setState({ stats })).catch(alert);
  }

  renderPageBody() {
    let { stats, filters } = this.state;

    console.log(filters);

    let statsTable = "Loading...";
    if (stats) {
      let filtered = _.sortBy(stats, '_id.day').reverse();

      let {from, to, userId, taskType} = filters;

      if(from)
        filtered = _.filter(filtered, obj => from <= new Date(obj._id.day));

      if(to)
        filtered = _.filter(filtered, obj => new Date(obj._id.day) <= (to.valueOf()+1000*3600*24));

      if(userId)
        filtered = _.filter(filtered, obj => obj._id.contributorId.toLowerCase().includes(userId.toLowerCase()) || obj._id.username.toLowerCase().includes(userId.toLowerCase()));

      if(taskType)
        filtered = _.filter(filtered, obj => obj._id.typeId === taskType);

      if(filters) {
        let r = new RegExp(_.escapeRegExp(filters));
        try {
          r = new RegExp(filters);
        } catch(err){

        }
        filtered = _.filter(filtered, obj => JSON.stringify(obj).match(r));
      }

      const totalTime = _.sumBy(filtered, 'workedTotalTime');
      const total = _.sumBy(filtered, 'count');

      statsTable = <table className={'table table-sm'}>
        <thead>
        <tr>
          <th>Contributor Id</th>
          <th>Date</th>
          <th className={'text-center'}>Time</th>
          <th className={'text-center'}>Tasks</th>
          <th className={'text-center'}>Average time per task</th>
        </tr>
        </thead>

        <tbody>
        <tr className={'bg-light-primary'}>
          <td>TOTAL</td>
          <td>All days</td>
          <td className={'text-center'}>{prettyDuration(totalTime)}</td>
          <td className={'text-center'}>≈ {total}*</td>
          <td className={'text-center'}>{total ? <span>≈ {prettyDuration(totalTime/total)}*</span> : 'No tasks yet'}</td>
        </tr>

        {_.map(filtered, ({ _id: { typeId, contributorId, username, day }, count, workIntervalsCount, workedTotalTime }) => {
          return <tr key={typeId + contributorId + day}>
            <td><UserAvatar user={{id: contributorId, username}}/> {typeId}</td>
            <td>{day}</td>
            <td className={'text-center'}>{prettyDuration(workedTotalTime)}</td>
            <td className={'text-center'}>{count}</td>
            <td className={'text-center'}>{count ? prettyDuration(workedTotalTime/count) : '-'}</td>
          </tr>
        })}
        </tbody>
      </table>
    }

    const filtersChanged = newFilters => {
      this.setState({ filters: newFilters });
      const cleanFilters = _.pickBy(newFilters);
      if(_.isEmpty(cleanFilters))
        this.deleteUrlParam('filters')
      else
        this.setUrlParam('filters', cleanFilters);
    };

    return <div className={''}>
      <FiltersBar value={filters} onChange={filtersChanged}/>

      <div className={'p-2'}>
      {statsTable}
      </div>
      <div className={'small text-secondary p-2'}>* Total tasks and Average time per task could count the same task twice if the same task was worked in more than one day</div>
    </div>
  }
}
