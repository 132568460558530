import React, { useContext, useEffect, useState } from 'react';

import { UserAvatar } from './UserAvatar';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import CountryFlag from './CountryFlag';
import { formatDistanceToNowStrict } from 'date-fns';

export function UserSummary({ userId, className, showEmail }) {
  const { page } = useContext(LegoAdminPageContext)
  const [userData, setData] = useState(null);

  useEffect(() => {
      page.service( 'services/data/users/info').get(userId).then(data => {
        setData(data || false)
      }).catch(window.alert);
  }, [userId]);

  if (userId?.startsWith('casual-')) {
    return <span className={'inline-block '+(className || '')}><UserAvatar id={userId}/><span className={'ml-1 text-secondary monospace'}>
      <strong>CASUAL</strong> ...{userId.slice(-8)}
    </span></span>;
  } else {
    if(userData) {
      const { fechaRegistracion, fechaCreacion, facebookId, pais, username, email, level } = userData;

      return <span className={'align-middle inline-block '+(className || '')}>
        <CountryFlag countryCode={pais} className={'mr-1 align-middle'}/>
        <UserAvatar user={userData}/>
        <span className={'ml-1 align-middle'}>{username}</span>
        <span className={'small ml-2 text-secondary align-middle'} title={fechaRegistracion || '---'}>Age: {formatDistanceToNowStrict(new Date(fechaRegistracion || fechaCreacion))}</span>
        {facebookId ? <span className={'ml-1'} style={{ 'background-color': '#3b5998' }}>
            <img alt="facebook" style={{ 'zoom': '.5' }} src="//static.opinautos.com/./images/icons/facebook_logo_white.png"/>
        </span> : null}

        {showEmail && email ? <span className={'small ml-2 text-secondary align-middle' + (level === 'registered' ? ' text-danger' : null)}>{`<${email}>`}</span> : null}
      </span>;
    } else if(userData === false) {
      return <span><UserAvatar id={userId}/><span className={'text-danger small ml-1'}><strong>NOT FOUND:</strong> <span className={'monospace'}>{userId}</span></span></span>;
    } else {
      return <span className={'align-middle inline-block '+(className || '')}><UserAvatar id={userId}/>Loading... </span>;
    }
  }
}
