import React, { Component } from 'react';
import { TrimEditor } from './TrimEditor';
import { IconButton } from '../../common/IconButton';
import ObjectSearchHandler from '../../common/ObjectSearchHandler';
import _ from 'lodash'

const MODE_NORMAL = 'normal'
const MODE_LINKING = 'linking'
const MODE_CLONE = 'clone'

export class TrimsBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: MODE_NORMAL
    };
    this.cache = {};

    this.searchHandler = this.getSearchHandler();
  }

  getSearchHandler() {
    const legoSearchFilters = {
      type: type => (l => !type || l.type.startsWith(type)),
    };

    const getSorter = (searchText, textFilter) => {
      return [(l,i) => i];
    };

    return new ObjectSearchHandler(legoSearchFilters, txt => txt, getSorter);
  }

  componentDidUpdate(prevProps) {
    if (this.props.trims !== prevProps.trims || this.props.textFilter !== prevProps.textFilter) {
      this.cache = {}
      this.forceUpdate()
    }
  }

  invalidateCache(originalTrim) {
    delete this.cache[originalTrim.year]
  }

  editTrim(originalTrim, changedTrim) {
    this.invalidateCache(originalTrim)
    changedTrim.__changed = true;
    changedTrim.updatedBy = this.props.user;

    let newTrims = _.map(this.props.trims, t => t === originalTrim ? changedTrim : t);
    this.props.onChange(newTrims);
  }

  groupTrimsInRowsByYear(trims, ... trimSources) {
    let rowsByYear = {};
    let matchedById = _.map(trimSources, () => ({}));

    _.each(trimSources, (rawTrims,i) => {
      _.each(rawTrims, (rawTrim) => {
        const matchedTo = rawTrim.trimMatch;

        if (matchedTo) {
          (matchedById[i][matchedTo] = matchedById[i][matchedTo] || []).push(rawTrim);
        } else {
          const emptyRow = new Array(trimSources.length + 1).fill(null);
          emptyRow[i+1] = [rawTrim];
          (rowsByYear[rawTrim.year] = rowsByYear[rawTrim.year] || []).push(emptyRow);
        }
      });
    });

    _.each(trims, trim => {
      (rowsByYear[trim.year] = rowsByYear[trim.year] || []).push([trim, ... _.map(matchedById, byId => byId[trim._id])]);
    });

    // Sort everything by fantasyName, prioritizing trim over sources
    rowsByYear = _.mapValues(rowsByYear, rows => _.sortBy(rows, ([t, ... sources]) => {
      const trim = t || (_.compact(sources)[0][0]);
      return trim.fantasyName + _.map(trim.specs, (val,key) => `${key}=${val}`).sort().join(' ');
    }));

    this.searchHandler.updateFilter(this.props.textFilter || '')
    rowsByYear = _.mapValues(rowsByYear, rows => this.searchHandler.search(rows))

    return _(rowsByYear).toPairs().sortBy('0').valueOf();
  }

  unlink(sourceDoc, linkedTrim) {
    this.invalidateCache(sourceDoc)
    this.invalidateCache(linkedTrim)
    sourceDoc.__changed = true;
    sourceDoc.trimMatch = null;
    sourceDoc.matchType = 'manually';
    this.props.onSourceChange(sourceDoc)
  }

  startLink(sourceDoc) {
    if(sourceDoc) {
      this.invalidateCache(sourceDoc)
    }
    this.setState({mode: MODE_LINKING, linkingSource: sourceDoc})
  }

  cancelLinking() {
    if(this.state.mode === MODE_LINKING) {
      this.invalidateCache(this.state.linkingSource)
      this.setState({mode: MODE_NORMAL, linkingSource: null })
    }
  }

  toggleSkip(sourceDoc) {
    this.invalidateCache(sourceDoc)
    sourceDoc.skipTrim = !sourceDoc.skipTrim;
    sourceDoc.__changed = true;
    sourceDoc.trimMatch = null;
    sourceDoc.matchType = 'manually';
    this.props.onSourceChange(sourceDoc)
  }

  onKeyDown(e) {
    if(this.state.mode !== MODE_NORMAL && e.key === 'Escape') {
      this.cancelOperations();
    }
  }

  cancelOperations() {
    if(this.state.linkingSource) {
      this.invalidateCache(this.state.linkingSource)
    } else {
      this.cache = {}
    }
    this.setState({linkingSource: null, mode: MODE_NORMAL})
  }

  copyTrimProperties(source, target) {
    target.specs = _.clone(source.specs)
    target.packs = _.clone(source.packs)
    target.fantasyName = source.fantasyName
    this.editTrim(target, target);
  }

  onTrimClick(e, trim) {
    switch(this.state.mode) {
      case MODE_LINKING:
        const sourceDoc = this.state.linkingSource;
        if(!trim.__deleted) {
          this.invalidateCache(sourceDoc)
          this.invalidateCache(trim)
          sourceDoc.__changed = true;
          sourceDoc.trimMatch = trim._id;
          sourceDoc.matchType = 'manually'
          this.props.onSourceChange(sourceDoc)
          this.setState({mode: MODE_NORMAL})
          e.stopPropagation();
          return false
        }
        break;
      case MODE_CLONE:
        if(trim !== this.state.cloneSource) {
          this.copyTrimProperties(this.state.cloneSource, trim);
        }
        break;
      case MODE_NORMAL:
      default:
        if(e.shiftKey) {
          this.cache = {}
          this.setState({mode: MODE_CLONE, cloneSource: trim})
          e.stopPropagation();
          return true;
        } else {
          return false;
        }
    }
  }

  onSourcesClick(sources) {
    switch(this.state.mode) {
      case MODE_LINKING:
      case MODE_CLONE:
        this.cancelOperations();
        break;
      case MODE_NORMAL:
      default:
    }
  }

  deleteTrim(trim) {
    // If it is a new trim, just delete it from memory
    if(trim.__new) {
      this.invalidateCache(trim)
      let newTrims = _.without(this.props.trims, trim);
      this.props.onChange(newTrims);
    } else {
      // If it is a saved trim, mark it for deletion
      this.editTrim(trim, { ... trim, __deleted: true})
    }
  }

  undoDelete(trim) {
      delete trim.__deleted
      this.editTrim(trim, trim)
  }

  createTrimFromSource(source) {
    this.invalidateCache(source)
    const {modelId, country, year, displayName, fantasyName, specs} = source;
    const newTrim = {
      modelId,
      country,
      year,
      displayName,
      fantasyName,
      specs,
      __changed: true,
      __new: true,
      createdBy: this.props.user,
      updatedBy: this.props.user,
      _id: new Date().valueOf(), // Temporal id to match rawTrims with trim
    }

    let newTrims = [... this.props.trims, newTrim];

    source.__changed = true;
    source.trimMatch = newTrim._id;
    source.matchType = 'manually'
    if(source.skipTrim) {
      source.skipTrim = false
    }
    this.props.onSourceChange(source)
    this.props.onChange(newTrims);
  }

  cloneTrimAndLinkToSource(source) {
    this.invalidateCache(source)
    const {modelId, country, year, displayName, fantasyName, specs} = this.state.cloneSource;

    const newTrim = {
      modelId,
      country,
      year: source.year,
      displayName,
      fantasyName,
      specs,
      __changed: true,
      __new: true,
      createdBy: this.props.user,
      updatedBy: this.props.user,
      _id: new Date().valueOf(), // Temporal id to match rawTrims with trim
    }

    let newTrims = [... this.props.trims, newTrim];

    source.__changed = true;
    source.trimMatch = newTrim._id;
    source.matchType = 'manually'
    if(source.skipTrim) {
      source.skipTrim = false
    }
    this.props.onSourceChange(source)
    this.props.onChange(newTrims);
  }

  onYearTitleClick(year) {
    if(this.state.mode === MODE_CLONE) {
      let source = this.state.cloneSource;
      // Convert year to integer just in case
      this.addNewTrim(source.country, parseInt(year.toString()), source.modelId, {specs: _.clone(source.specs), fantasyName: source.fantasyName});
    }
  }

  addNewTrim(country, year, modelId, extraTrimProps = {}) {
    if(!country) {
      country = prompt('Enter a country code (e.g. "ar")')
      if(!country) {
        return;
      }
    }

    if(!modelId) {
      modelId = prompt('Enter a modelId (e.g. "Ford-Focus")')
      if(!modelId) {
        return;
      }
    }

    if(!year) {
      year = prompt('Enter a year (e.g. "2017")')
      if(!year) {
        return;
      } else {
        year = parseInt(year)
      }
    }

    const newTrim = {
      modelId,
      country,
      year,
      displayName: '',
      fantasyName: '',
      specs: {},
      __new: true,
      createdBy: this.props.user,
      updatedBy: this.props.user,
      _id: new Date().valueOf(), // Temporal id to match rawTrims with trim,
      ... extraTrimProps
    }



    this.invalidateCache(newTrim)

    let newTrims = [... this.props.trims, newTrim];

    this.props.onChange(newTrims);
  }

  renderTrimRow(year, [trim, ... sources], i, mode) {
    let rowTrimClasses = [];

    let sourcesCols = _.map(sources, () => null);
    let sourcesLinks = _.map(sources, (s,i) => <td key={i}></td>);
    let sourcesClasses = _.map(sources, () => []);
    let trimCol = null;

    _.each(sources, (source, i) => {
      if (source) {
        if (!source.length) {
          sources[i] = [source];
        }

        sourcesCols[i] = <div key={source[0]._id}>{_.map(source, (s, j) => {
          let yearWarning = s.year.toString() !== year.toString() ? <span className={'text-danger small'}>(trim from {s.year})</span> : null
          if (s.__new) {
            sourcesClasses[i].push('new-trim');
          } else if (s.__changed) {
            sourcesClasses[i].push('changed-trim');
          }

          return <div title={s.displayName} key={s._id}>
            <TrimEditor value={s}/>
            {yearWarning}
          </div>;
        })}</div>;

      }
    });

    if (trim) {
      if (this.state.mode === MODE_NORMAL) {
        trimCol = <div onClick={(e) => this.onTrimClick(e, trim)}>
          <TrimEditor value={trim} onChange={(newTrim) => this.editTrim(trim, newTrim)}/>
        </div>;
      } else {
        trimCol = <div onClick={(e) => this.onTrimClick(e, trim)}>
          <TrimEditor value={trim}/>
        </div>;
      }

      if(trim.__deleted) {
        rowTrimClasses.push('deleted-trim');
      } else if(trim.__new) {
        rowTrimClasses.push('new-trim');
      }else if(trim.__changed) {
        rowTrimClasses.push('changed-trim');
      }
    } else if(sources[0]) {
      if(this.state.mode === MODE_CLONE) {
        trimCol =<div className={'text-center clone-target'} onClick={() => this.cloneTrimAndLinkToSource(sources[0][0])}>
          <IconButton level={'success'} icon={'file_copy'}>Clone here</IconButton>
        </div>;
      } else {
        trimCol =
          <div><IconButton onClick={() => this.createTrimFromSource(sources[0][0])} level={'primary'}
                           icon={'arrow_forward'}>
            Create trim from left
          </IconButton></div>;
      }
    } else if(sources[1]) {
      if(this.state.mode === MODE_CLONE) {
        trimCol =<div className={'text-center clone-target'} onClick={() => this.cloneTrimAndLinkToSource(sources[1][0])}>
          <IconButton level={'success'} icon={'file_copy'}>Clone here</IconButton>
        </div>;
      } else {
        trimCol =
          <div className={'text-right'}><IconButton onClick={() => this.createTrimFromSource(sources[1][0])}
                                                    level={'primary'} icon={'arrow_back'}>
            Create trim from right
          </IconButton></div>;
      }
    }

    if (trim && _.some(sources)) {
      if(trim.updatedBy && trim.updatedBy.id) {
        rowTrimClasses.push('bg-light-success');
      } else if(trim.metadata && trim.metadata.isPartialMatch) {
        rowTrimClasses.push('bg-light-warn');
      } else {
        rowTrimClasses.push('bg-light-partial-success');
      }
      _.each(sources, (source, i) => {
        if(source) {
          let matchTypeBg = source[0].matchType === 'trimsMatchingScript' ? 'bg-light-partial-success' : 'bg-light-success'

          sourcesClasses[i].push(matchTypeBg);
          sourcesLinks[i] = <td className={`text-center ${matchTypeBg} p-0`}>
            <IconButton className={'p-0'} icon={'link'} level={'success'} onClick={() => this.unlink(source[0], trim)}/>
          </td>;
        }
      })
    } else {
      _.each(sources, (source, i) => {
        if (source) {
          const stateBg = source[0].skipTrim ? 'bg-light-secondary' : 'bg-light-danger';
          sourcesClasses[i].push(stateBg);
          sourcesCols[i] = <div>{sourcesCols[i]} <div className={'small text-center text-danger'}>{source[0].displayName}</div></div>

          if (this.state.linkingSource === source[0]) {
            sourcesLinks[i] = <td className={'text-center bg-light-warn p-0'}>
              <IconButton className={'p-0'} icon={'link'} level={'warning'} onClick={() => this.cancelLinking()}/>
            </td>;
          } else {
            if (source[0].skipTrim) {
              sourcesLinks[i] = <td className={`text-center ${stateBg} p-0`}>
                <IconButton className={'p-0'} icon={'undo'} level={'secondary'} title={'Activate source trim'}
                            onClick={() => this.toggleSkip(source[0])}/>
              </td>;
            } else {
              sourcesLinks[i] = <td className={`text-center ${stateBg} p-0`}>
                <IconButton className={'p-0'} icon={'clear'} level={'danger'} title={'Skip trim'}
                            onClick={() => this.toggleSkip(source[0])}/>
                <IconButton className={'p-0'} icon={'swap_calls'} level={'secondary'} title={'Assign to trim'}
                            onClick={() => this.startLink(source[0])}/>
              </td>;
            }
          }
        }
      })

      if (trim) {
        rowTrimClasses.push('bg-light');

        if(trim.__deleted) {
          // Show undelete button for trim
          sourcesLinks[1] = <td className={'text-center bg-light-danger p-0'}>
            <IconButton className={'p-0'} icon={'undo'} level={'danger'} onClick={() => this.undoDelete(trim)}/>
          </td>;
        } else {
          // Show delete button for trim
          sourcesLinks[1] = <td className={'text-center bg-light-danger p-0'}>
            <IconButton className={'p-0'} icon={'delete'} level={'danger'} onClick={() => this.deleteTrim(trim)}/>
          </td>;
        }
      }
    }

    return <tr key={(year + (trim || (_.compact(sources)[0][0]))._id)}>
      <td className={`px-2 py-1 m-0 ${sourcesClasses[0].join(' ')}`}
          onClick={() => this.onSourcesClick(sourcesCols[0])}>
        {sourcesCols[0]}
      </td>
      {sourcesLinks[0]}
      <td className={`px-2 py-1 m-0 ${rowTrimClasses.join(' ')}`}>
        {trimCol}
      </td>
      {sourcesLinks[1]}
      <td className={`px-2 py-1 m-0 ${sourcesClasses[1].join(' ')}`}
          onClick={() => this.onSourcesClick(sourcesCols[1])}>
        {sourcesCols[1]}
      </td>
    </tr>;
  }

  renderYearRows([year, rows], mode) {
    if(this.cache[year]) {
      return this.cache[year];
    }

    if(!rows.length) {
      return [<tr key={year} className={'bg-light-secondary text-white'}>
        <td colSpan={5}>
          <h5 className={'text-center m-0'}>{year}</h5>
        </td>
      </tr>
      ];
    }

    // Read modelId from first row
    const [trim, ... sources] = rows[0]
    const modelId = (trim || _.find(sources)[0]).modelId;
    const country = (trim || _.find(sources)[0]).country;

    let res = [<tr key={year} className={'bg-secondary text-white year-header'}>
      <td colSpan={5} onClick={(e) => this.onYearTitleClick(year)}>
        <h5 className={'text-center m-0'}>
          {year} {modelId}
          <IconButton icon={'add'} level={'success'} onClick={() => this.addNewTrim(country, year, modelId)}/>
        </h5>
      </td>
    </tr>
    ].concat(_.map(rows, (row, i) => this.renderTrimRow(year, row, i, mode)));

    this.cache[year] = res;
    return res;
  }

  render() {
    let { trims, sourceA, sourceB } = this.props;

    let { mode } = this.state;

    let groupedTrims = this.groupTrimsInRowsByYear(trims, sourceA, sourceB);

    let trimRows = _.flatten(_.map(groupedTrims, row => this.renderYearRows(row, mode)));

    let someTrim = (trims && trims[0]) || _.find([sourceA, sourceB])[0];
    let addYearBtn = null;
    if(someTrim) {
      let country = someTrim.country
      let modelId = someTrim.modelId
      addYearBtn = <IconButton icon={'add_circle'} level={'success'} onClick={() => this.addNewTrim(country, null, modelId)}>Add new trim to <strong>other year</strong> of {modelId} [{country}]...</IconButton>
    } else {
      addYearBtn = <IconButton icon={'create_new_folder'} level={'success'} onClick={() => this.addNewTrim()}>Add new trim to <strong>some country/modelId/year...</strong></IconButton>
    }

    return <table className={`mode-${this.state.mode} table table-sm trims-table`} onKeyDown={(e) => this.onKeyDown(e)}>
      <thead>
      <tr className={'text-center bg-dark text-white'}>
        <th colSpan={2}>Source 1</th>
        <th>
          Golden set
        </th>
        <th colSpan={2}>Source 2</th>
      </tr>
      </thead>
      <tbody>
      {trimRows}
      <tr><td colSpan={5} className={'bg-light-primary text-center'}>{addYearBtn}</td></tr>
      </tbody>
    </table>;
  }
}
