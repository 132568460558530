import i18n from './i18n';
import config from './config'
import _ from 'lodash';

// TODO: Repeated code from attchment.js . Refactor to remove duplication
let asJpg = name => name.substring(0, name.lastIndexOf('.')) + '.jpg';

let nameWithoutFolder = name => name.substring(name.lastIndexOf('/') + 1);

const attachment = {
  getBucketBaseUrl(bucketName) {
    return "https://" + bucketName + ".s3.amazonaws.com";
  },

  getImagesBaseUrl() {
    return this.getBucketBaseUrl(window.config.s3LegacyImageBucket);
  },

  getPdfsBaseUrl() {
    return this.getBucketBaseUrl(window.config.s3ManualBucket)
  },

  getPreviewlUrl(imageName) {
    // All previews are stored as .jpg
    return this.getImagesBaseUrl() + '/previews/' + asJpg(nameWithoutFolder(imageName));
  },

  getThumbnailUrl(imageName) {
    // All thumbnails are stored as .jpg
    return this.getImagesBaseUrl() + '/thumbnails/' + asJpg(nameWithoutFolder(imageName));
  },

  getImageUrl(imageName) {
    return this.getImagesBaseUrl() + '/' + imageName;
  }
}

const urls = {
  basePath: window.basePath || '',

  __: i18n,

  armarPath(array, open) {
    return `${this.basePath}/${array.join('/')}${open === false ? '/' : ''}`;
  },

  urlMyUserConfig() {
    return this.armarPath([this.__('urls.myAccount'), this.__('urls.userConfig')]);
  },

  urlTopByScore(topN) {
    let url = this.armarPath([this.__('urls.members'), this.__('urls.topByScore')]);
    if (topN) {
      url = `${url}?top=${topN}`;
    }
    return url;
  },

  urlModelTopByScore(modelObj, topN) {
    let url = this.armarPath([modelObj.marcaUrlSafe, modelObj.modeloUrlSafe, this.__('urls.members'), this.__('urls.topByScore')]);
    if (topN) {
      url = `${url}?top=${topN}`;
    }
    return url;
  },

  urlResumen(modelo) {
    return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe]);
  },

  urlDefectos(modelo, pageNumber = null, useScore = null) {
    let parts = [modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.problems')];
    if (useScore !== null) {
      if (useScore) {
        parts = [modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.problems'), this.__('urls.byNoAnswers')];
      } else {
        parts = [modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.problems'), this.__('urls.byDate')];
      }
    }
    if (pageNumber && pageNumber > 1) {
      parts.push(this.__('urls.pagePrefix') + pageNumber);
    }
    return this.armarPath(parts);
  },

  urlDefectosAgregar(modelo) {
    return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.problems'), this.__('urls.addNewProblem')]);
  },

  urlDenuncia(modelo, reportId, tituloUrlSafe) {
    return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.problems'), reportId, tituloUrlSafe]);
  },

  urlComment(modelo, reportId, tituloUrlSafe, commentId) {
    return `${this.urlDenuncia(modelo, reportId, tituloUrlSafe)}#select-com${commentId}`;
  },

  urlDefectosTag(modelo, tag, year) {
    return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.problems'), tag]) + (year ? `?anio=${year}` : '');
  },

  urlMemberProfile(userId) {
    return this.armarPath([this.__('urls.members'), this.__('urls.profile'), userId]);
  },

  // TODO: Las siguientes funciones se cargan "dinámicamente" en los constructores de otros módulos
  // Hay que encontrar la forma de arreglar toda esta duplicación
  urlModalAddReview(modelo) {
    return this.armarPath(['formaddreview'].concat(modelo ? [modelo.marcaUrlSafe, modelo.modeloUrlSafe] : []));
  },

  urlModelReviews(modelo) {
    return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.reviews')]);
  },

  urlSearchSymptoms(modelObj, userCase) {
    return this.armarPath([modelObj.marcaUrlSafe, modelObj.modeloUrlSafe, `${this.__('urls.symptomsWizard')}?case=${encodeURIComponent(JSON.stringify(userCase))}`]);
  },

  urlPostUpdateUser() {
    return this.armarPath(["user", "update"])
  },

  urlPostPassword() {
    return this.armarPath(['user', 'setpassword']);
  },

  urlProfessionalProfile(userId) {
    return this.armarPath([this.__('urls.professional'), this.__('urls.profile'), userId]);
  },

  urlImage(imageId) {
    return attachment.getImageUrl(imageId);
  },

  urlImagePreview(imageId) {
    return attachment.getPreviewlUrl(imageId);
  },

  urlImageThumbnail(imageId) {
    return attachment.getThumbnailUrl(imageId);
  },

  urlUserContributionImageUploadPermission(fileName, mimeType) {
    return this.armarPath(['utils', 'upload-permission', `contribution-image?fileName=${encodeURIComponent(fileName)}&mimeType=${encodeURIComponent(mimeType)}`]);
  },

  getImagesBaseUrl() {
    return attachment.getImagesBaseUrl();
  },

  getPdfsBaseUrl() {
    return attachment.getPdfsBaseUrl();
  },

  urlInfoOwnersManual(modelo, year = null) {
    if (year) {
      return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.information'), this.__('urls.ownersManual'), year]);
    }
    return this.armarPath([modelo.marcaUrlSafe, modelo.modeloUrlSafe, this.__('urls.information'), this.__('urls.ownersManual')]);
  },

  urlPostRegistracion() {
    return this.armarPath(['registracion']);
  },

  urlPostRegistrationContribution() {
    return this.armarPath(['registracion-contribution']);
  },

  urlFuseboxThumbnail(modelo, year, other, boxIndex) {
    const key = this.urlKeyFuseboxThumbnail(modelo, year, other, boxIndex);
    return `https://${config.s3FuseboxImageBucket}.s3.amazonaws.com/${key}`;
  },

  urlInfoFusebox(modelo, year = null, fuseboxSlug = null) {
    const make = modelo.marcaUrlSafe;
    const model = modelo.modeloUrlSafe;
    if (!year) {
      return this.armarPath([make, model, this.__('urls.information'), this.__('urls.fuseBox')]);
    }
    if (!fuseboxSlug) {
      return this.armarPath([make, model, this.__('urls.information'), this.__('urls.fuseBox'), year]);
    }
    return this.armarPath([make, model, this.__('urls.information'), this.__('urls.fuseBox'), year, fuseboxSlug]);
  },

  urlKeyFuseboxThumbnail(modelo, year, other, boxIndex, doubleEncodeForFetch = true) {
    let otherStr = this.urlInfoFuseboxOtherPropsKey(modelo, other);
    if (doubleEncodeForFetch) {
      // Very ugly: the first encodeURIComponent is the one we used when uploading the screenshot, the second is needed by S3 to fetch it
      otherStr = encodeURIComponent(otherStr);
    }
    return `legos/fusebox-thumbnails/fusebox-${modelo.marcaUrlSafe}-${modelo.modeloUrlSafe}-${year}${otherStr ? `-${otherStr}` : ''}-${boxIndex || 0}.png`; // "-0" is the first fusebox
  },

  urlInfoFuseboxOtherPropsKey(modelo, otherProps) {
    // Encode values of props: if they're arrays, convert them to string in the same way the redis keys are generated,
    // and replace any '-' that may come in the value, as it will break the way the FuseboxModule parses it
    // Props starting with _ are considered internal marks that should never reach the url
    const filteredProps = _.omitBy(otherProps, (val, key) => key.startsWith('_'));
    // Modify genId if present
    if (filteredProps.genId) {
      filteredProps.genId = filteredProps.genId.slice(1).replace('@', '_');
      if (modelo?.idModelo) {
        filteredProps.genId = filteredProps.genId.replace(`${modelo?.idModelo}_`, '');
      }
    }

    const otherPropsEncoded = _.map(_.sortBy(_.toPairs(filteredProps), '0'), ([k, v]) => [k, (_.isArray(v) ? v.join(',') : v.toString()).replace(/-/g, '__')]);
    return encodeURIComponent(_.flatten(otherPropsEncoded).join('-').toLowerCase());
  }
};


export default urls;

export let convertToDevUrl = function(url) {
  if (!url.match(/^http/) && url[0] === '/') {
    const { protocol, hostname } = document.location;
    return `${protocol}//${hostname}:3000${url}`;
  }
  return url;
}
