import React, { Component } from 'react';
import _ from 'lodash'

import TextareaAutosize from 'react-textarea-autosize';
import JsonTextEditor from './JsonTextEditor';

export default class TextFieldEditor extends Component {
  textChanged(newText) {
    this.props.onChange(newText);
  }

  render() {
    let {rows, minRows, className, onChange, value, showCounter, ... other} = this.props;
    let text  = value || "";

    if (_.isString(text)) {
      const area = <TextareaAutosize
        minRows={minRows || 4}
        maxRows={rows || 6}
        className={`form-control form-control-sm ${className || ''}`}
        onChange={e => this.textChanged(e.target.value)}
        value={text}
        {...other}
      />;
      if (showCounter !== false) {
        return <div className={'row m-0'}>
          {area} <span className={'text-info small'}>{text.length} chars</span>
        </div>;
      } else {
        return area;
      }
    } else {
      return <JsonTextEditor onChange={onChange} value={value}/>;
    }
  }
}
