import React, {Component} from 'react';
import _ from 'lodash';
import ModelContextEditor from './ModelContextEditor';
import SemanticEditor from './SemanticEditor';

export default class DataViewExplorer extends Component {
  constructor(props) {
    super(props);

    this.dataviewStates = window.client.service('services/dataviews/state');

    let initialContext = {
      model: { modelId: 'Chevrolet-Aveo' },
      // what: 'TESTIGO_CHECK_ENGINE'
    };

    let params = [];
    _.each(this.props.view.selector, (val, key) => {
      let m = (val.toString()).match(/\$(\w+)/);
      if(m) {
        params.push({key, name: m[1]})
      }
    })
    this.parameters = params;

    this.state = {
      loading: true,
      results: null,
      keysFilter: '',
      context: initialContext
    };

    this.parseQuery();

    this.parseQuery = _.debounce(this.parseQuery, 100);
  }

  // async updateSemantic() {
  //   let semantics = this.state.changedObject.semantics;
  //
  //   this.setState({ semanticLookups: await this.semanticRep.get(semantics) });
  // }

  async parseQuery() {
    this.setState({ loading: true });

    let res = await this.dataviewStates.find({
      query: {
        id: this.props.view._id,
        context: this.state.context
      }
    });

    let stats = await this.dataviewStates.get(this.props.view._id, {query: {includeSample: true, keysFilter: this.state.keysFilter, includeSize: false}})

    this.setState({ results: res, loading: false, stats });
  }

  async updateKeysFilter(newFilter) {
    this.setState({keysFilter: newFilter})
    await this.parseQuery();
  }

  async contextChanged(name, val) {
    this.state.context[name] = val;

    this.setState({ context: this.state.context});

    await this.parseQuery();
  }

  render() {
    let resultsRows = null;
    const { results, context, loading, stats, keysFilter } = this.state;

    let contextParameters = _.map(this.parameters, ({name, key}) => {
      if (key === 'model') {
        return <div className={'mr-2'} style={{minWidth: '300px'}} key={name}>
          <ModelContextEditor onChange={(ctx) => this.contextChanged(key, ctx)}  value={context[name]}/>
        </div>
      } else if (key === 'semantics') {
        return <div className={'mr-2'} style={{minWidth: '400px'}} key={name}>
          <SemanticEditor onChange={(sem) => this.contextChanged(key, sem)} value={context[name]}/>
        </div>
      } else {
        return <div className={'mr-2'} key={name}>
          <div>{name}</div>
          <input type={'text'} className={'form-control form-control-sm'} value={context[name] || ""}
                 onChange={(e) => this.contextChanged(name, e.target.value)}/>
        </div>
      }
    })

    if (loading) {
      resultsRows = <div className={'text-center'}>Loading...</div>;
    } else {
      resultsRows = _.map([results], (data, i) => {
        let content = null;

        if (_.isString(data)) {
          content = <div className={'bg-light-primary p-1 rounded'}>{data}</div>;
        } else {
          content = <pre className={'bg-light-primary p-1 rounded'}>{JSON.stringify(data, true, 2)}</pre>;
        }

        return <div key={i} className={'small'}>
          {content}
        </div>;
      });
    }

    let sampleRows = null, size;
    if (stats) {
      sampleRows = _.map(stats.valueSample, (s, i) => {
        let k = stats.keySample[i];
        let v = _.isString(s) ? JSON.stringify(JSON.parse(s), true, 2) : JSON.stringify(s, true, 2)
        return <div key={k}>
          <div className={'text-primary mb-1 monospace'}>{k}</div>
          <pre className={'bg-light-primary p-1 rounded small'}>{v}</pre>
        </div>;
      });

      if(stats.sizeBytes)
        size = <span className={'text-info ml-2'}>{(stats.sizeBytes/(1024*1024)).toFixed(2)} MB in memory</span>
    }

    return <div style={{ minWidth: '400px', minHeight: '200px' }}>
      <div className={'h5'}>View {this.props.view.name} {size}</div>
      <div className={'m-0 row align-items-center'}>
        {/*{ contextParameters }*/}
        <input type={'text'} className={'form-control form-control-sm'} value={keysFilter}
               placeholder={'Filter keys that contain a string...'}
               onChange={(e) => this.updateKeysFilter(e.target.value)}/>
      </div>
      {/*<div className={'mt-3'}>{resultsRows}</div>*/}
      <div className={'mt-2'}>{sampleRows}</div>
    </div>;
  }
}
