import React from 'react';

import _ from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

export default class SingleValueSelector extends React.Component {
  handleSelectChange(op, actionMeta) {
    this.props.onChange(op?.value);
  }

  getOption(props) {
    let { innerProps, isSelected, isFocused } = props;
    let { innerRef, ...filteredProps } = innerProps;
    return <div {...filteredProps} className={'p-1 ' + ((isSelected || isFocused) ? 'bg-light-primary bg-med' : '')}>
      {props.label}
    </div>;
  };

  getMultiValueLabel(props) {
    return props.children;
  };

  render() {
    const { onChange, value, canCreate, options, placeholder, isClearable, ...props } = this.props;

    const optionsObjects = _.map(options, (op) => {
      if (op.value && op.label) {
        return { label: op.label, value: op.value };
      } else {
        return { label: op.toString(), value: op };
      }
    });

    let valueOption = _.find(optionsObjects, op => op.value === value);
    if (value && !valueOption) {
      valueOption = { label: value.toString(), value: value };
    }

    const ClassName = canCreate ? CreatableSelect : Select;
    return (
      <ClassName closeOnSelect={true}
              components={{ Option: this.getOption.bind(this), MultiValueLabel: this.getMultiValueLabel.bind(this) }}
              onChange={this.handleSelectChange.bind(this)}
              options={optionsObjects}
              menuShouldScrollIntoView={false}
              placeholder={placeholder || "None"}
              isClearable={true}
              value={valueOption}
              {...props}
      />
    );
  }
}

