import React, { useContext, useState } from 'react';
import _ from 'lodash';

import TopBarLayout from './TopBarLayout';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';

export default function Tabs({ children, names, initialTab, persistInUrl, tabIndex, onTabChange, ... otherProps }) {
  initialTab = initialTab || 0;
  const { page } = useContext(LegoAdminPageContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(persistInUrl ? (page.getUrlParam('tab') ?? initialTab) : initialTab);
  if (!children.length) {
    return <div>CHILDREN ARE NEEDED</div>;
  }

  let currentTab = tabIndex ?? selectedTabIndex;

  const setTab = (i) => {
    if (i !== currentTab) {
      if (persistInUrl) {
        page.setUrlParam('tab', i || undefined);
      } else {
        page.deleteUrlParam('tab');
      }

      if (onTabChange) {
        onTabChange(i);
      } else {
        setSelectedTabIndex(i);
      }
    }
  };

  return <TopBarLayout {... otherProps}>
    <div className={'border-bottom border-light'}>
      { _.map(children, (o,i) => <span key={i}
                                       style={{borderRadius: '5px 5px 0 0'}}
                                       className={`btn btn-sm btn-${i === currentTab ? 'light' : 'dark'} ml-1 mt-1`}
                                       onClick={() => setTab(i)}>
        {names?.[i] || `Tab ${i+1}`}
      </span>)}
    </div>
    { children[currentTab]}
  </TopBarLayout>
}
