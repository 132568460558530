import React from 'react';

import InputMultipleSemantics from './InputMultipleSemantics';

export default function InputManualsMultipleSemantics({ ...otherProps }) {
  let manualCategories = [
    'MANUAL',
    'MANUAL_DE_TALLER',
    'MANUAL_DE_TALLER(origin=UNOFFICIAL)',
    'MANUAL_ESTEREO',
    'MANUAL_MULTIMEDIA',
    'MANUAL_DE_CAPACITACION',
    'GUIA_DE_INICIO_RAPIDO',
    'BROCHURE',
    'MOTOR',
    'DIAGRAMA_ELECTRICO',
    'SISTEMA_ELECTRICO',
    'CAJA_DE_TRANSMISION',
    'CHASIS',
    'CARROCERIA',
    'DIMENSIONES',
    'INYECCION',
    'CAJA_AUTOMATICA',
    'AIRE_ACONDICIONADO',
    'CALEFACCION',
    'NEUMATICO',
    'RUEDA',
    'BATERIA',
    'ARRANQUE',
    'SISTEMA_DE_ENFRIAMIENTO',
    'ESPEJO_RETROVISOR',
    'AVISO_SONORO',
    'LEVANTAVIDRIOS',
    'CIERRE_CENTRALIZADO',
    'AIRBAG',
    'SISTEMA_DE_IGNICION',
    'TABLERO', 'CANO_DE_ESCAPE',
    'MULTIPLE_DE_ADMISION',
    'SISTEMA_DE_SUSPENSION',
    'SISTEMA_DE_LUBRICACION',
    'LUZ_BALIZA',
    'LUZ_DIRECCIONAL',
    'LIMPIA_PARABRISAS',
    'SAPITO',
    'LUZ',
    'DIRECCION',
    'PARAGOLPES',
    'COMBUSTIBLE',
    'EMBRAGUE',
    'FRENO',
    'DIFERENCIAL',
    'SISTEMA_TRANSMISION',
    'BOCINA',
    'CRUISE_CONTROL',
    'ESTEREO',
    'ECU',
    'INMOVILIZADOR',
    'SISTEMA_ABS',
    'COMANDOS_DE_VOZ',
    'SISTEMA_DE_ALIMENTACION',
    'DIAGRAMA_DESPIECE',
    'GLOSARIO',
    'CONTROL_DE_EMISIONES',
    'FICHA_TECNICA',
    'MANUAL_DE_MANTENIMIENTO',
    'HERRAMIENTAS_ESPECIALES',
    'INTRODUCCION'
  ];

  return (
    <InputMultipleSemantics preloadedSemantics={manualCategories}
                            {...otherProps}/>
  );
}
