import React, { Component } from 'react';
import _ from 'lodash';

import PageHeader from '../components/PageHeader';

import LivePage from './LivePage';
import FuseboxDiagramEditor from '../components/lego/fusebox-editor/FuseboxDiagramEditor';
import GlobalClipboardZone from '../components/common/GlobalClipboardZone';

export default class TestFuseboxEditor extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    let emptyFusebox = {
      fuses: [],
      boxName: "",
      "boxDiagramImg": {url: "https://opinautos-images.s3.amazonaws.com/legos/fusebox-diagrams/f24de59e_image.png"},
      boxDescription: ""
    }

    this.state = {
      ...this.state,
      fusebox: fuseboxes[1]
      // fusebox: emptyFusebox
    };
  }

  renderPageBody() {
    return <div className={''}>
      <FuseboxDiagramEditor fusebox={this.state.fusebox}
                            onChange={fb => this.setState({ fusebox: fb })}/>
    </div>;
  }
}

var fuseboxes = [
  {
    "boxDiagramImg": {url: "https://opinautos-images.s3.amazonaws.com/legos/fusebox-diagrams/e7497c30_ford-f-150-2008.png"},
    // "boxDiagramImg": {url: "https://opinautos-images.s3.amazonaws.com/legos/fusebox-diagrams/6d586aed_image.png"},
    "boxTablesImages":[
      {url: "https://opinautos-images.s3.amazonaws.com/legos/fusebox-tables/e15ceb9a_image.jpg"},
      {url: "https://opinautos-images.s3.amazonaws.com/legos/fusebox-tables/e6d51fe9_image.jpg"}
      ],
    "fusesE": [],
    "fuses": [
      {
        "relatedEntities": [
          "AIRBAG"
        ],
        "layout": {
          // "height": 11.042944785276074,
          // "width": 15.167785234899329,
          // "left": 8.590604026845638,
          // "top": 7.157464212678937
        },
        "description": "Administrador de sistemas de seguridad",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "SDM"
      },
      {
        "relatedEntities": [
          "ECU",
          "SENSOR_POSICION_CIGUENAL",
          "SENSOR_ARBOL_DE_LEVAS",
          "VALVULA_PURGA_CANISTER"
        ],
        "layout": {
          // "height": 11.042944785276074,
          // "width": 15.167785234899329,
          // "left": 8.590604026845638,
          // "top": 20.449897750511248
        },
        "description": "Sistema de administración de motor",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "EMS1"
      },
      {
        "relatedEntities": [
          "SISTEMA_OBD"
        ],
        "layout": {
          // "height": 11.042944785276074,
          // "width": 15.167785234899329,
          // "left": 8.590604026845638,
          // "top": 33.74233128834356
        },
        "description": "Diagnóstico On-Board",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "OBD"
      },
      {
        "relatedEntities": [
          "SOLENOIDE_SEGURO_DE_PUERTA",
          "CIERRE_CENTRALIZADO"
        ],
        "layout": {
          "height": 10.838445807770961,
          "width": 15.167785234899329,
          "left": 8.590604026845638,
          "top": 47.239263803680984
        },
        "description": "Cierre centralizado",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "DOOR LOCK"
      },
      {
        "relatedEntities": [
          "BOCINA"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 8.590604026845638,
          "top": 60.32719836400818
        },
        "description": "Claxon",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "HORN"
      },
      {
        "relatedEntities": [
          "LIMPIA_PARABRISAS"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.302013422818792,
          "left": 25.369127516778523,
          "top": 7.157464212678937
        },
        "description": "Limpiaparabrisas",
        "kind": {
          "format": "mini",
          "amp": 25,
          "type": "fuse"
        },
        "id": "WIPER"
      },
      {
        "relatedEntities": [
          "LUZ_FRENO"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.302013422818792,
          "left": 25.369127516778523,
          "top": 20.449897750511248
        },
        "description": "Luz de freno",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "STOP LAMP"
      },
      {
        "relatedEntities": [
          "DESEMPANADOR_TRASERO"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.302013422818792,
          "left": 25.369127516778523,
          "top": 33.74233128834356
        },
        "description": "Desempañante trasero",
        "kind": {
          "format": "mini",
          "amp": 25,
          "type": "fuse"
        },
        "id": "RR DEFOG"
      },
      {
        "relatedEntities": [
          "LUZ_ANTINIEBLA"
        ],
        "layout": {
          "height": 10.838445807770961,
          "width": 15.302013422818792,
          "left": 25.369127516778523,
          "top": 47.239263803680984
        },
        "description": "Luz antiniebla traseras",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "RR FOG"
      },
      {
        "relatedEntities": [
          "TECHO_SOLAR",
          "ESPEJO_ELECTRICO",
          "ESPEJO_LATERAL",
          "ESPEJO_RETROVISOR"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.302013422818792,
          "left": 25.369127516778523,
          "top": 60.32719836400818
        },
        "description": "Espejo/ Techo corredizo",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "MIRROR/ SUNROOF*"
      },
      {
        "relatedEntities": [
          "TABLERO"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.302013422818792,
          "left": 41.87919463087248,
          "top": 7.157464212678937
        },
        "description": "Tablero de instrumentos",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "CLUSTER"
      },
      {
        "relatedEntities": [
          "ESTEREO",
          "RADIO",
          "PARLANTE",
          "CONTROL_REMOTO"
        ],
        "layout": {
          "height": 13.0879345603272,
          "width": 24.026845637583893,
          "left": 42.41610738255034,
          "top": 63.394683026584865
        },
        "description": "Acceso remoto sin llave",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "AUDIO/RKE*"
      },
      {
        "relatedEntities": [
          "LUZ_DIRECCIONAL"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 58.52348993288591,
          "top": 7.157464212678937
        },
        "description": "Señal de cruce",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "T/SIG"
      },
      {
        "relatedEntities": [
          "TOMA_12V"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 58.52348993288591,
          "top": 20.449897750511248
        },
        "description": "Encendedor/Toma 12V",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "CIGAR"
      },
      {
        "relatedEntities": [
          "TABLERO",
          "LUZ_DE_HABITACULO"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 58.52348993288591,
          "top": 33.74233128834356
        },
        "description": "Tablero de instrumentos / Luz de habitáculo",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "CLUSTER/ROOM LAMP"
      },
      {
        "relatedEntities": [
          "TECHO_SOLAR"
        ],
        "layout": {
          "height": 10.838445807770961,
          "width": 15.167785234899329,
          "left": 58.52348993288591,
          "top": 47.239263803680984
        },
        "description": "Techo corredizo",
        "kind": {
          "format": "mini",
          "amp": 20,
          "type": "fuse"
        },
        "id": "SUN ROOF"
      },
      {
        "relatedEntities": [
          "ECU"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 75.43624161073825,
          "top": 7.157464212678937
        },
        "description": "Sistema de administración de motor",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "EMS2"
      },
      {
        "relatedEntities": [
          "ESTEREO",
          "RADIO",
          "PARLANTE"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 75.43624161073825,
          "top": 20.449897750511248
        },
        "description": "Sistema de audio",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "AUDIO"
      },
      {
        "relatedMayBe": [
          "TOMA_12V"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 75.43624161073825,
          "top": 33.74233128834356
        },
        "description": "CAVIDAD",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "CAVIDAD"
      },
      {
        "relatedEntities": [
          "LUZ_RETROCESO"
        ],
        "layout": {
          "height": 10.838445807770961,
          "width": 15.167785234899329,
          "left": 75.43624161073825,
          "top": 47.239263803680984
        },
        "description": "Luz de reversa",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "B/UP LAMP"
      },
      {
        "relatedEntities": [
          "DESEMPANADOR_DE_ESPEJOS"
        ],
        "layout": {
          "height": 11.042944785276074,
          "width": 15.167785234899329,
          "left": 75.43624161073825,
          "top": 60.32719836400818
        },
        "description": "Desempañador de espejo",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "DEFOG MIRROR"
      }
    ],
    "boxAspectRatio": 0.6563758389261745,
    "boxDescription": "",
    "boxScaleMultiplier": 0.89,
    "boxName": "Bloque de fusibles del tablero de instrumentos"
  },
  {
    "fuses": [
      {
        "relatedEntities": [
          "BATERIA"
        ],
        "layout": {
          "height": 16.07565011820331,
          "width": 8,
          "left": 9.090909090909092,
          "top": 1.8912529550827424
        },
        "description": "Batería",
        "kind": {
          "format": "cartridge",
          "amp": 50,
          "type": "fuse"
        },
        "id": "BATT"
      },
      {
        "relatedEntities": [
          "LUZ_DE_ESTACIONAMIENTO"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 8.363636363636363,
          "left": 19.03030303030303,
          "top": 1.4184397163120568
        },
        "description": "Luz izquierda de estacionamiento",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "PK/LP LH"
      },
      {
        "relatedEntities": [
          "LUZ_DE_ESTACIONAMIENTO"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 8.363636363636363,
          "left": 19.03030303030303,
          "top": 12.529550827423167
        },
        "description": "Luz derecha de estacionamiento",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "PK/LP RH"
      },
      {
        "relatedEntities": [
          "BURRO_DE_ARRANQUE",
          "ARRANQUE"
        ],
        "layout": {
          "height": 16.07565011820331,
          "width": 8,
          "left": 29.333333333333332,
          "top": 1.8912529550827424
        },
        "description": "Encendido 2 / Arranque",
        "kind": {
          "format": "cartridge",
          "amp": 30,
          "type": "fuse"
        },
        "id": "IGN2/ST"
      },
      {
        "layout": {
          "height": 14.420803782505912,
          "width": 6.0606060606060606,
          "left": 52.36363636363637,
          "top": 1.8912529550827424
        },
        "description": "Herramienta extractora de fusibles",
        "kind": {
          "type": "fusepuller"
        },
        "id": "FUSE PULLER"
      },
      {
        "relatedEntities": [
          "LUZ_BAJA"
        ],
        "layout": {
          "height": 20.33096926713948,
          "width": 11.878787878787879,
          "left": 70.78787878787878,
          "top": 2.127659574468085
        },
        "description": "Relay Faro luz baja",
        "kind": {
          "type": "relay"
        },
        "id": "H/L LOW RELAY"
      },
      {
        "relatedEntities": [
          "ESTEREO",
          "RADIO",
          "VENTILADOR_DE_CLIMATIZADOR",
          "CONTACTO"
        ],
        "layout": {
          "height": 16.07565011820331,
          "width": 8,
          "left": 29.333333333333332,
          "top": 19.62174940898345
        },
        "description": "Accesorios / Encendido 1",
        "kind": {
          "format": "cartridge",
          "amp": 30,
          "type": "fuse"
        },
        "id": "ACC/IGN1"
      },
      {
        "relatedEntities": [
          "LUZ_BALIZA"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 8.363636363636363,
          "left": 39.87878787878788,
          "top": 21.749408983451538
        },
        "description": "Emergencia",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "HAZARD"
      },
      {
        "relatedEntities": [
          "LUZ_BAJA"
        ],
        "layout": {
          "height": 8.983451536643026,
          "width": 9.090909090909092,
          "left": 60.969696969696976,
          "top": 22.458628841607563
        },
        "description": "Luz baja de faro derecho",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "H/L LOW RH"
      },
      {
        "relatedEntities": [
          "LUZ_BAJA"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 9.090909090909092,
          "left": 60.969696969696976,
          "top": 33.56973995271868
        },
        "description": "Luz baja de faro izquierdo",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "H/L LOW LH"
      },
      {
        "relatedEntities": [
          "ELECTROVENTILADOR"
        ],
        "layout": {
          "height": 20.56737588652482,
          "width": 12,
          "left": 72.72727272727273,
          "top": 26.47754137115839
        },
        "description": "Relay de alta velocidad del electroventilador",
        "kind": {
          "type": "relay"
        },
        "id": "FAN HI RELAY"
      },
      {
        "relatedEntities": [
          "ELECTROVENTILADOR"
        ],
        "layout": {
          "height": 15.839243498817968,
          "width": 8,
          "left": 88.60606060606061,
          "top": 24.113475177304963
        },
        "description": "Velocidad alta del electroventilador",
        "kind": {
          "format": "cartridge",
          "amp": 30,
          "type": "fuse"
        },
        "id": "FAN HI"
      },
      {
        "relatedEntities": [
          "BOMBA_DE_COMBUSTIBLE"
        ],
        "layout": {
          "height": 27.659574468085108,
          "width": 9.212121212121211,
          "left": 1.8181818181818181,
          "top": 37.35224586288416
        },
        "description": "Relay de bomba de combustible",
        "kind": {
          "type": "relay"
        },
        "id": "FUEL PUMP RELAY"
      },
      {
        "relatedEntities": [
          "LEVANTAVIDRIOS"
        ],
        "layout": {
          "height": 27.659574468085108,
          "width": 9.212121212121211,
          "left": 13.939393939393941,
          "top": 37.35224586288416
        },
        "description": "Relay de ventana eléctrica *",
        "kind": {
          "type": "relay"
        },
        "id": "P/WINDOW RELAY*"
      },
      {
        "relatedEntities": [
          "LUZ_DE_ESTACIONAMIENTO"
        ],
        "layout": {
          "height": 27.659574468085108,
          "width": 9.212121212121211,
          "left": 26.181818181818183,
          "top": 37.35224586288416
        },
        "description": "Relay de Luces de estacionamiento",
        "kind": {
          "type": "relay"
        },
        "id": "PARK LAMP RELAY"
      },
      {
        "relatedEntities": [
          "LUZ_ANTINIEBLA"
        ],
        "layout": {
          "height": 27.659574468085108,
          "width": 9.333333333333334,
          "left": 38.42424242424242,
          "top": 37.35224586288416
        },
        "description": "Relay de Luces antiniebla *",
        "kind": {
          "type": "relay"
        },
        "id": "FRT FOG RELAY*"
      },
      {
        "relatedEntities": [
          "LUZ_ALTA"
        ],
        "layout": {
          "height": 27.659574468085108,
          "width": 9.212121212121211,
          "left": 50.42424242424243,
          "top": 37.35224586288416
        },
        "description": "Revelador Faro luz alto",
        "kind": {
          "type": "relay"
        },
        "id": "H/L HI RELAY"
      },
      {
        "relatedEntities": [
          "DESEMPANADOR",
          "CLIMATIZADOR"
        ],
        "layout": {
          "height": 8.983451536643026,
          "width": 9.090909090909092,
          "left": 60.969696969696976,
          "top": 44.680851063829785
        },
        "description": "Desempañador delantero *",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "FRT FOG*"
      },
      {
        "relatedEntities": [
          "LUZ_ALTA"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 9.090909090909092,
          "left": 60.969696969696976,
          "top": 55.55555555555556
        },
        "description": "Faro luz alta",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "H/L HI"
      },
      {
        "layout": {
          "height": 20.56737588652482,
          "width": 12,
          "left": 72.72727272727273,
          "top": 51.77304964539007
        },
        "description": "Velocidad baja de electroventilador",
        "kind": {
          "type": "relay"
        },
        "id": "FAN LOW RELAY"
      },
      {
        "relatedEntities": [
          "ELECTROVENTILADOR"
        ],
        "layout": {
          "height": 8.983451536643026,
          "width": 9.090909090909092,
          "left": 88.12121212121212,
          "top": 44.680851063829785
        },
        "description": "Velocidad baja de electroventilador",
        "kind": {
          "format": "mini",
          "amp": 20,
          "type": "fuse"
        },
        "id": "FAN LOW"
      },
      {
        "relatedEntities": [
          "COMPRESOR_DE_AC",
          "AIRE_ACONDICIONADO"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 9.090909090909092,
          "left": 88.12121212121212,
          "top": 55.55555555555556
        },
        "description": "Compresor de aire acondicionado*",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "A/C COMP*"
      },
      {
        "layout": {
          "height": 9.219858156028367,
          "width": 8.363636363636363,
          "left": 8.848484848484848,
          "top": 68.5579196217494
        },
        "description": "Ventana eléctrica 2*",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "P/WINDOW2*"
      },
      {
        "relatedEntities": [
          "LEVANTAVIDRIOS"
        ],
        "layout": {
          "height": 14.893617021276595,
          "width": 8.606060606060606,
          "left": 8.848484848484848,
          "top": 79.90543735224587
        },
        "description": "Ventana eléctrica 1*",
        "kind": {
          "format": "cartridge",
          "amp": 30,
          "type": "fuse"
        },
        "id": "P/WINDOW1*"
      },
      {
        "relatedEntities": [
          "BOMBA_DE_COMBUSTIBLE"
        ],
        "layout": {
          "height": 8.983451536643026,
          "width": 8.848484848484848,
          "left": 19.03030303030303,
          "top": 67.37588652482269
        },
        "description": "Bomba de combustible",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "FUEL PUMP"
      },
      {
        "relatedEntities": [
          "ECU"
        ],
        "layout": {
          "height": 9.219858156028367,
          "width": 8.848484848484848,
          "left": 19.03030303030303,
          "top": 78.25059101654847
        },
        "description": "Unidad de control electrónico",
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "ECU"
      },
      {
        "layout": {
          "height": 9.219858156028367,
          "width": 8.848484848484848,
          "left": 19.03030303030303,
          "top": 89.36170212765957
        },
        "description": "Sistema de administración de motor",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "EMS1*"
      },
      {
        "layout": {
          "height": 9.219858156028367,
          "width": 8.727272727272728,
          "left": 29.575757575757578,
          "top": 71.15839243498819
        },
        "description": "Sistema de administración de motor",
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "EMS2*"
      },
      {
        "relatedEntities": ["REPUESTO"],
        "layout": {
          "height": 8.983451536643026,
          "width": 8.242424242424242,
          "left": 51.272727272727266,
          "top": 67.37588652482269
        },
        "description": "Fusible de repuesto",
        "isSpare": true,
        "kind": {
          "format": "mini",
          "amp": 10,
          "type": "fuse"
        },
        "id": "SPARE 1"
      },
      {
        "layout": {
          "height": 9.219858156028367,
          "width": 8.242424242424242,
          "left": 51.272727272727266,
          "top": 78.25059101654847
        },
        "description": "Fusible de repuesto",
        "relatedEntities": ["REPUESTO"],
        "isSpare": true,
        "kind": {
          "format": "mini",
          "amp": 15,
          "type": "fuse"
        },
        "id": "SPARE 2"
      },
      {
        "layout": {
          "height": 9.219858156028367,
          "width": 8.242424242424242,
          "left": 51.272727272727266,
          "top": 89.36170212765957
        },
        "description": "Fusible de repuesto",
        "relatedEntities": ["REPUESTO"],
        "isSpare": true,
        "kind": {
          "format": "mini",
          "amp": 20,
          "type": "fuse"
        },
        "id": "SPARE 3"
      },
      {
        "relatedEntities": [
          "SISTEMA_ABS"
        ],
        "layout": {
          "height": 16.07565011820331,
          "width": 8,
          "left": 61.33333333333333,
          "top": 67.84869976359337
        },
        "description": "Frenos antibloqueo*",
        "kind": {
          "format": "cartridge",
          "amp": 50,
          "type": "fuse"
        },
        "id": "ABS*"
      },
      {
        "relatedEntities": [
          "COMPRESOR_DE_AC",
          "AIRE_ACONDICIONADO"
        ],
        "layout": {
          "height": 20.33096926713948,
          "width": 12,
          "left": 72.72727272727273,
          "top": 77.30496453900709
        },
        "description": "Relay de Aire acondicionado *",
        "kind": {
          "type": "relay"
        },
        "id": "A/C RELAY*"
      },
      {
        "relatedEntities": [
          "BOMBA_DE_COMBUSTIBLE",
          "INYECTOR",
          "ECU",
          "BOBINA_DE_IGNICION"
        ],
        "layout": {
          "height": 27.423167848699766,
          "width": 8.606060606060606,
          "left": 88.60606060606061,
          "top": 70.2127659574468
        },
        "description": "Relay principal *",
        "kind": {
          "type": "relay"
        },
        "id": "MAIN RELAY*"
      }
    ],
    "boxAspectRatio": 0.5127272727272727,
    "boxDescription": "",
    "boxName": "Bloque de fusibles del compartimiento del motor"
  }
];
