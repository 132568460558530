import React, { useState } from 'react';
import _ from 'lodash';
import { IconButton } from '../IconButton';
import SingleTextEditor from './SingleTextEditor';
import JsonTextEditor from './JsonTextEditor';
import { Icon } from '../Icon';

const JsonListEditor = ({ className, onChange, valueToDiff, treatValuesAsString, value, getExtraCells, getExtraHeader }) => {
  const [state, setState] = useState({
    columns: [... new Set(_.flatMap(value, obj => _.keys(obj)))].sort(),
    editingRowIndex: null,
    editingField: null,
    searchText: '',
    sortByColumn: null
  });

  const addRow = () => {
    editStart('0', state.columns[0]);
    return onChange([{}, ...(value)]);
  };

  const duplicateRow = (row) => onChange([{ ...row }, ...(value)]);

  const deleteRow = (row) => onChange(_.without(value, row));

  const onFieldChange = (row, name, newFieldValue) => {
    const rowIndex = value.indexOf(row);

    if (!state.editingRowIndex || !state.editingField) {
      setState({ ...state, editingRowIndex: rowIndex, editingField: name });
    }

    const oldFieldValue = row[name];

    if (oldFieldValue !== newFieldValue && onChange) {
      const newValue = [...(value)];
      const newRow = _.omit(row, name);
      newValue[rowIndex] = newRow;

      if (newFieldValue !== undefined && (!_.isString(newFieldValue) || newFieldValue.trim() !== '')) {
        newRow[name] = newFieldValue;
      }

      onChange(newValue);
    }
  };

  const editStart = (rowIndex, name) => setState({ ...state, editingRowIndex: rowIndex, editingField: name });

  const editEnd = () => setState({ ...state, editingRowIndex: null, editingField: null });

  const addColumn = () => {
    const name = prompt('New column:');
    if (name && !_.includes(state.columns, name)) {
      setState({ ...state, columns: [...state.columns, name] });
    }
  };

  const renameColumn = (oldName) => {
    const newName = prompt('Rename column:', oldName);

    if (newName && newName !== oldName) {
      const newValue = _.map(value, row => {
        if (oldName in row) {
          row = { ...row };
          row[newName] = row[oldName];
          delete row[oldName];
        }

        return row;
      });

      const newColumns = state.columns.map(name => name === oldName ? newName : name);
      onChange(newValue);
      setState({ ...state, columns: newColumns });
    }
  };

  const deleteColumn = (oldName) => {
    const newColumns = _.without(state.columns, oldName);
    const newValue = _.map(value, row => {
      if (oldName in row) {
        row = { ...row };
        row[newName] = row[oldName];
        delete row[oldName];
      }

      return row;
    });

    onChange(newValue);
    setState({ ...state, columns: newColumns });
  };

  const onSearchChange = (text) => {
    setState({ ...state, searchText: text });
  };

  const sortBy = (name) => {
    if(state.sortByColumn === name) {
      setState({ ...state, sortByColumn: null });
    } else {
      setState({ ...state, sortByColumn: name });
    }
  };

  const renderHeader = (entries) => {
    let { sortByColumn } = state;

    const columns = _.map(state.columns, (name, i) => {
      const isEmptyColumn = _.every(value, e => e[name] === undefined);

      const sorted = sortByColumn === name;
      return <th key={name} className={'text-center align-middle '+(sorted ? 'bg-primary text-white' : '')} onClick={() => sortBy(name)}>
        <span className={'align-middle'} onDoubleClick={() => renameColumn(name)}>{name}</span>
        {
          isEmptyColumn ?
            <span className={'ml-2'}>
              <IconButton icon={'clear'} level={'danger'} className={'p-0'} onClick={() => deleteColumn(name)} description={'Delete column'}/>
            </span>:
            null
        }
        {
          sorted ? <Icon icon={'expand_more'}/> : null
        }
      </th>;
    });

    const otherColumns = [
      <th key={'$last$'} className={'text-center align-middle no-wrap table-column-small'}>
        <IconButton icon={'post_add'} level={'success'} onClick={addColumn} description={"Add column"}/>
        <IconButton icon={'note_add'} level={'success'} onClick={addRow} description={"Add row"}/>
      </th>,

      <th key={'$first$'} className={'text-center align-middle table-column-small'} onClick={() => sortBy(null)}> </th>
    ];
    columns.unshift(otherColumns);

    if(getExtraHeader) {
      columns.push(... getExtraHeader());
    }

    return columns;
  };

  const renderRows = (rows) => {
    let { sortByColumn } = state;

    if(sortByColumn) {
      rows = _.sortBy(rows, ([i,r]) => !r[sortByColumn], ([i,r]) => r[sortByColumn]);
    }

    return _.map(rows, ([i,r]) => {
      return <tr key={i} className={'text-sm'}>{renderRow(r, i)}</tr>;
    });
  };

  const renderRow = (row, rowIndex) => {
    const fields = _.map(state.columns, (name, i) => renderField(row, rowIndex, name, i));

    const otherFields = [
      <td key={'x'} className={'text-center align-middle small table-column-small no-wrap bg-light-secondary'}>
        <IconButton icon={'delete_forever'} level={'danger'} onClick={() => deleteRow(row)}
                    className={'m-0 p-0 zoom-90'} description={'Delete row'}/>
        <IconButton icon={'file_copy'} level={'success'} onClick={() => duplicateRow(row)}
                    className={'m-0 p-0 zoom-90'} description={'Duplicate row'}/>
      </td>,

      <td key={0} className={'text-center align-middle table-column-small text-secondary small bg-light-secondary'}>{parseInt(rowIndex) + 1}</td>
    ];

    fields.unshift(otherFields);

    if(getExtraCells) {
      fields.push(... getExtraCells(row, rowIndex));
    }

    return fields;
  };

  const renderField = (row, rowIndex, name, columnIndex) => {
    const isEditingField = state.editingRowIndex === rowIndex && state.editingField === name;
    let v = row[name];
    let bgColor = !v ? 'json-list-editor--empty' : '';
    let result;

    if(valueToDiff && valueToDiff.length === value.length) {
      let oldValue = valueToDiff[rowIndex] && valueToDiff[rowIndex][name];
      if(!_.isEqual(oldValue, v)) {
        bgColor += ` outline-${v === undefined ? 'delete' : (oldValue === undefined ? 'add' : 'changed')}`;
      }
    }

    if (isEditingField) {
      if (treatValuesAsString && (!v || _.isString(v))) {
        result = <SingleTextEditor autoFocus={isEditingField}
                                   value={v}
                                   small={true}
                                   placeholder={name}
                                   className={'text-center'}
                                   onChange={text => onFieldChange(row, name, text)}
                                   onBlur={() => editEnd()}/>;
      } else {
        result = <JsonTextEditor autoFocus={isEditingField}
                                 value={v}
                                 small={true}
                                 className={'small'}
                                 placeholder={name}
                                 onChange={text => onFieldChange(row, name, text)}
                                 onBlur={() => editEnd()} />;
      }
    } else {
      const editCell = () => editStart(rowIndex, name);

      if(_.isArray(v)) {
        v = <div className={''}>{v.map((s, i) => <div><span className={'bg-white border m-05 mx-1 p-05 rounded px-2 inline-block'} key={i}>{s}</span></div>)}</div>;
      } else if(v && !_.isString(v)) {
        v = <div className={'monospace text-info'}>{JSON.stringify(v)}</div>;
      } else {
        v = !v ? <div>&nbsp;</div> : v.toString();
      }
      result = <div tabIndex={0} onClick={editCell} onFocus={editCell}>{v}</div>;
    }

    result = <td key={columnIndex + 1} className={'text-center align-middle ' + bgColor}>{result}</td>;
    return result;
  };

  const { searchText, editingRowIndex } = state;

  let entries = _.toPairs(value);
  if(searchText) {
    let regex;
    try {
      regex = new RegExp(searchText.trim(), 'ig');
    } catch (err) {
      regex = new RegExp(_.escapeRegExp(searchText.trim()), 'ig');
    }

    entries = _.filter(entries, ([i, entry]) => {
      return i === editingRowIndex || _.some(_.values(entry), value => (_.isString(value) ? value :JSON.stringify(value)).match(regex));
    });
  }

  return (
    <div>
      <div className={'p-2 bg-secondary'}>
        <SingleTextEditor value={searchText}
                          small={true}
                          className={state.invalidSearch ? 'text-danger' : ''}
                          placeholder={`Search ${(value || []).length} items...`}
                          onChange={text => onSearchChange(text)} />
      </div>
      <table className={'table table-sm small json-list-editor mb-0 ' + (className || '')}>
        <thead>
        <tr className={'bg-secondary text-white'}>
          {renderHeader(entries)}
        </tr>
        </thead>
        <tbody>
        {renderRows(entries.slice(0, state.showMore ? entries.length : 50))}
        {entries.length > 100 && <tr><td colSpan={state.columns.length + 2} className={'text-center align-middle bg-light-primary p-2'}>
          <button className={'btn btn-primary'} onClick={() => setState({ ...state, showMore: !state.showMore })}>{state.showMore ? 'Show only first 50 rows' : `Show all ${entries.length} rows`}</button></td></tr>}
        </tbody>
      </table>
    </div>
  );
};

export default JsonListEditor;
