import React, { useState } from 'react';

import LivePage from './LivePage';
import ImageSourcePanel from '../components/images/ImageSourcePanel';
import _ from 'lodash';

export default class TestPage extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    this.state = {
      ...this.state,
      url: '',
    };

    this.fullScreen = true;
    this.menu = [];
    this.submenu = [];

    this.searchSvc = this.service('services/data/image-search');
  }

  componentDidMount() {
    super.componentDidMount();
    this.openImageSourceModal();
  }

  openImageSourceModal(imageId) {
    this.openModal(<ImageSourcePanel imageId={imageId || '6285c5c0f48c39ee4838ecc9'}/>, { fullScreen: true });
  }

  renderPageBody() {
    let { res, phash, url, error } = this.state;

    return <div className={'margin'}>
        {
          _.map(['627bd7236fc33600e2300b60', '627bd7306fc33600e2300b64', '627bd7356fc33600e2300b66'], imageId =>
      <div>
          <a onClick={() => this.openImageSourceModal(imageId)} href={'javascript:void(0)'}>View source for {imageId}</a>
      </div>
        )}

      {/*<ImageSourcePanel imageId={"6285c5c0f48c39ee4838ecc9"}/>*/}
    </div>;
  }
}
