import React from 'react';
import _ from 'lodash';

import TaskEditorManualLegoExtraction from "./TaskEditorManualLegoExtraction";
import ManualFeaturesFuseboxEditor from './manuals-extraction/ManualFeaturesFuseboxEditor';

export default class TaskEditorManualFuseboxExtraction extends TaskEditorManualLegoExtraction {
  constructor(props) {
    super(props);

    // this.state.pdfInitialSearch = /\b(fusible|fuse)&&\b(amp|\d+A\b|circuito)/.source
    this.state.pdfInitialSearch = /\b(fusible|fuse|fusiv)/.source
  }

  renderExtractionControls() {
    let { files, file, fileHash, autosuggestions, autosuggestionsExplanation } = this.state;

    if (file) {
      return <ManualFeaturesFuseboxEditor manual={file}
                                      key={fileHash + autosuggestions}
                                      initialChanges={autosuggestions}
                                      onSave={async (changedManual, legosUpdates, markTaskDone) => this.save(changedManual, legosUpdates, markTaskDone)}/>
    } else {
      return "No manual"
    }
  }
}
