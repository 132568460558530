import React, { Component } from 'react';
import _ from 'lodash';
import JsonTextEditor from '../common/editors/JsonTextEditor';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import MultipleItemsEditor from '../lego/MultipleItemsEditor';
import { TaskTemplateEditor } from './TaskTemplateEditor';
import { TaskFlowEditor } from './TaskFlowEditor';
import CRUDEditor from '../common/editors/CRUDEditor';


export default class DataProcessTemplateEditor extends CRUDEditor {
  getEditorUi(dataProcessDefinition) {
    let { _id, processType, tasks, dataTypes, states, flows, __unsaved, __new, __deleted } = dataProcessDefinition;

    const processNameEditor = <SingleTextEditor onChange={(json) => this.editField('processType', json)}
                                                value={processType}/>;

    const statesEditor = <JsonTextEditor onChange={(json) => this.editField('states', json)} value={states}/>;

    const dataTypesEditor = <JsonTextEditor onChange={(json) => this.editField('dataTypes', json)} value={dataTypes}/>;

    const tasksEditor = <MultipleItemsEditor onChange={(json) => this.editField('tasks', json)}
                                             value={tasks}
                                             renderComponent={(item, props) => <TaskTemplateEditor
                                             process={dataProcessDefinition}
                                             value={item} {...props}/>}/>;

    const flowsEditor = <MultipleItemsEditor onChange={(json) => this.editField('flows', json)}
                                             value={flows}
                                             defaultNewItem={{
                                               event: 'onState', actions: [{ actionType: 'createTask', 'taskId': '' }]
                                             }}
                                             renderComponent={(item, props) => {
                                               return <TaskFlowEditor process={dataProcessDefinition} {...props}
                                                                      value={item}/>;
                                             }}/>;


    return <table className={'table table-sm'}>
        <tbody>
        <tr>
          <td style={{ width: '140px' }} className={'text-right'}>Name:</td>
          <td>{processNameEditor}</td>
        </tr>
        <tr>
          <td style={{ width: '140px' }} className={'text-right'}>States:</td>
          <td>{statesEditor}</td>
        </tr>
        <tr>
          <td style={{ width: '140px' }} className={'text-right'}>Data Types:</td>
          <td>{dataTypesEditor}</td>
        </tr>
        <tr>
          <td style={{ width: '140px' }} className={'text-right'}>Tasks:</td>
          <td> {tasksEditor} </td>
        </tr>
        <tr>
          <td style={{ width: '140px' }} className={'text-right'}>Flows:</td>
          <td>{flowsEditor}</td>
        </tr>
        </tbody>
      </table>
  }
}
