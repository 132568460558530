import React, { Component } from 'react';

export class SearchFilterButton extends Component {
  toggle() {
    this.props.onChange(!this.props.value)
  }

  render() {
    const stateClass = this.props.value ? '' : 'very-translucent';

    return <span className={`${stateClass} btn-toggle mr-0 p-0`} onClick={this.toggle.bind(this)}>{ this.props.children }</span>;
  }
}
