import __ from './i18n';
import { convertToDevUrl } from './urls';

const cdn = (relativePath) => {
  // Skip absolute urls
  if(relativePath.toLowerCase().startsWith('http')) {
    return relativePath;
  }

  if (relativePath && relativePath[0] !== "/") {
    relativePath = "/" + relativePath;
  }

  if(process.env.NODE_ENV.startsWith('development')) {
    // return `${relativePath}`
    return convertToDevUrl(relativePath)
  } else {
    return `${__('static.domain')}${relativePath}`
  }
};

export default cdn;
