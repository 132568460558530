import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { format, formatDistanceToNow } from 'date-fns';

import GridLayoutLeftBar from '../common/layout/GridLayoutLeftBar';
import MailConversation from './MailConversation';
import { Icon } from './Icon';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SingleTextEditor from './editors/SingleTextEditor';
import { IconButton } from './IconButton';
import { UserAvatarLive } from './UserAvatarLive';
import LeftRightLayout from './layout/LeftRightLayout';

const renderThreadsList = (threads, selectedThreadId, handleSelectThread) => {
  return <div>
    <div> {_.map(threads, thread => renderThreadListItem(thread, selectedThreadId, handleSelectThread))}</div>
  </div>;
};

const renderThreadListItem = (thread, selectedThreadId, handleSelectThread) => {
  let threadId = thread[0]['threadId'];

  const hasUnreadMessage = _.some(thread, m => !m.read && m.type === 'received');
  const labels = _.uniq(_.flatMap(thread, c => _.map(c.labels)));
  const attachments = _.uniq(_.flatMap(thread, m => _.map(m.attachments, 'fileName')));

  let icon = null;
  let sentEmails = _.filter(thread, {type: 'sent'});
  if(sentEmails.length) {
    let {delivered, read, clicked} = sentEmails.at(-1);
    if(clicked) {
      icon = <Icon icon={'ads_click'} size={'sm'} level={'success'}/>
    } else if(read) {
      icon = <Icon icon={'done_all'} size={'sm'} level={'success'}/>
    } else if(delivered) {
      icon = <Icon icon={'done_all'} size={'sm'}  level={'secondary'}/>
    } else {
      icon = <Icon icon={'done'} size={'sm'}  level={'danger'}/>
    }
  }

  const thisThreadSelected = selectedThreadId === threadId
  const unreadClass = hasUnreadMessage ? thisThreadSelected ? 'font-weight-bold bg-light-primary' : 'font-weight-bold bg-white' : null;
  const selectedClass = thisThreadSelected ? 'bg-light-primary' : null;

  const lastDate = _.max(_.map(thread, msg => new Date(msg.date).valueOf()));

  return <div key={thread[0].threadId}
               className={`p-1 border-bottom border-light small ${unreadClass} ${selectedClass}`}
               onClick={() => handleSelectThread(thread)}>
    <LeftRightLayout>
      <div>
        <UserAvatarLive id={thread[0].userId} className={'mr-1 ml-1'}/>

        {hasUnreadMessage ? <Icon level={'secondary'} icon={'mark_email_unread'}/> : null}

        {icon}

        {attachments.length ?
          <Icon className={'pr-1'} level={'secondary'} icon={'attachment'} title={JSON.stringify(attachments)}/> : null}

        <span className={'align-middle ml-2'}>{thread[0].subject} <span className={'badge badge-light ml-1'}>{`${thread.length}`}</span></span>

        {labels.length ? _.map(labels, l => <span key={l} className={'badge badge-primary ml-1'}>{l}</span>) : null}
      </div>

      <div className={'text-secondary pr-2'}>
        { formatDistanceToNow(new Date(lastDate))}
      </div>
    </LeftRightLayout>

  </div>;
};

export function MailConversationsList({ threadIds, userId }) {
  let { page } = useContext(LegoAdminPageContext);
  const mailsService = client.service('services/data/emails/conversations');

  let [threads, setThreads] = useState([]);
  let [selectedThreadId, setSelected] = useState(page.getUrlParam('threadId') || '');
  let [filter, setFilter] = useState({});
  let [lastUpdate, setLastUpdate] = useState('');

  let filteredComments = threads;
  if (filter.text?.trim().length) {
    try {
      const regex = new RegExp(filter.text, 'i');
      filteredComments = _.filter(filteredComments, c => _.some(_.values(c), v => JSON.stringify(v).
                                                                                       match(regex)));
    } catch (err) {
      const regex = new RegExp(_.escapeRegExp(filter.text), 'i');
      filteredComments = _.filter(filteredComments, c => _.some(_.values(c), v => JSON.stringify(v).
                                                                                       match(regex)));
    }
  }

  const editingNewMail = !!page.getUrlParam('editing')

  useEffect(() => {
    if(editingNewMail){
      handleSelectThread()
    } else {
      loadData()
    }
  }, [page.getUrlParam('editing')])

  const handleSelectThread = (thread, isDefault) => {
    if (thread) {
      const threadId = thread[0].threadId;
      setSelected(threadId);
      if(!isDefault) {
        page.setUrlParam('threadId', threadId);
      }
      page.deleteUrlParam('editing');
    } else {
      setSelected('')
      page.deleteUrlParam('threadId');
    }
  };

  const loadData = async () => {
    let mails;
    let query = {};

    if (!(threadIds?.length || userId)) {
      return;
    }

    if (threadIds?.length) {
      query.threadId = { $in: threadIds };
    }
    if (userId) {
      query.userId = userId;
    }

    mails = await page.runAsync(mailsService.find({ query }));

    let orderedThreads = _.groupBy(mails, 'threadId');
    _.forEach(Object.keys(orderedThreads), key => orderedThreads[key] = _.orderBy(orderedThreads[key], 'date', 'asc'));
    orderedThreads = _.orderBy(orderedThreads, t => t.at(-1).date, 'desc');

    setThreads(orderedThreads);
    setLastUpdate(Date());

    if (!selectedThreadId && orderedThreads.length && !editingNewMail) {
      handleSelectThread(orderedThreads[0], true);
    }
  };

  useEffect(() => {
    loadData();
  }, [JSON.stringify(threadIds), userId]);

  return <GridLayoutLeftBar>
    <div className={'bg-light-secondary h-100 overflow-auto'} style={{ width: '500px' }}>
      <span className={'d-flex align-items-center bg-dark p-1'}>
        <SingleTextEditor small className={'AdminInput zoom-90'} value={filter.text}
                          placeholder={'Find by text, labels, etc...'}
                          onChange={(newText) => setFilter({ ...filter, text: newText })}/>
        <IconButton icon={'refresh'} onClick={() => loadData()} title={'Check for new mails'}/>
      </span>
      {renderThreadsList(filteredComments, selectedThreadId, handleSelectThread)}
    </div>
    <div>
      <MailConversation threadId={selectedThreadId} onUpdate={() => loadData()} key={selectedThreadId + lastUpdate}/>
    </div>
  </GridLayoutLeftBar>;
}
