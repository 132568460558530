import React from 'react';
import _ from 'lodash';

import TaskEditorPdfExtraction from "./TaskEditorPdfExtraction";

export default class TaskEditorManualLegoExtraction extends TaskEditorPdfExtraction {
  constructor(props) {
    super(props);

    this.legoService = window.client.service('services/legos');
  }

  async computeSuggestions() {
    const { task } = this.props;
    const { pagesDescription, file } = this.state;
  }

  async save(changedManual, legosUpdates, markTaskDone) {
    if(changedManual) {
      console.log('Updating manual features',changedManual.features);
      await this.updateManual(changedManual._id,  { $set: { features: changedManual.features } });
    }

    const asyncOperations = _.compact(_.map(legosUpdates, (lego) => {
      if(lego.__new && !lego._id) {
        lego.createdBy = this.getLoggedUserSignature();
        lego.updatedBy = lego.createdBy;

        return this.legoService.create(lego);
      } else if(lego.__deleted) {
        return this.legoService.remove(lego._id);
      } else if(lego.__unsaved) {
        lego.updatedBy = this.getLoggedUserSignature();
        return this.legoService.update(lego._id, lego);
      }
    }));

    console.log(`Doing ${asyncOperations.length} CRUD async operations...`)
    await Promise.all(asyncOperations);

    if(markTaskDone) {
      await this.doneAndNext();
    }
  }

  renderExtractionControls() {
    throw new Error("renderExtractionControls must be implemented by TaskEditorManualLegoExtraction subclasses")
  }
}
