import React, { useState, useContext } from 'react';

import _ from 'lodash';
import { IconButton } from '../common/IconButton';
import uuidv4 from '../../../../components/logic/uuidv4';
import InputRegexFilter from '../common/editors/InputRegexFilter';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import { IconButtonToggle } from '../common/IconButtonToggle';
import { Icon } from '../common/Icon';
import InputMultipleSemantics from '../common/editors/InputMultipleSemantics';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SemanticTag from '../semantic/SemanticTag';


function recursiveCount(b) {
  // Don't count symlinks to children
  return (b.reportIds?.length || 0) + _.sum(_.map(b.children, c => c.parentId === b.id ? recursiveCount(c) : 0));
}

function recursiveChildren(b) {
  return (b.children || []).concat(_.flatten(_.map(b.children, recursiveChildren)));
}

function SemanticSelectorModal ({ node, onOk }) {
  let [newSemantics, setNewSemantics] = useState(node.semantics || [])

  const onChange = (value) => setNewSemantics(value);

  return <div>
    <InputMultipleSemantics value={newSemantics} onChange={onChange}/>
    <IconButton className={'m-0 p-0'}
                icon={'check'}
                onClick={() => onOk(newSemantics)}/>
  </div>
}

function Block({ b, onClick, global, onUpdateNode, onRenameNode, fromAltParent, parent, selection, showMaxLevels }) {
  const { id, name, alias, reportIds, children, hidden, hideChildren, fullName, semantics } = b;
  const { filter, forceShowChildren } = global;
  const { page } = useContext(LegoAdminPageContext)


  if (showMaxLevels !== null && showMaxLevels <= 0) {
    return null;
  }

  let filteredChildren = children;
  let nameMatchesFilter = filter && name.match(filter);

  if (filter && !nameMatchesFilter) {
    const matching = _.filter(recursiveChildren(b), n => n.name.match(filter));
    filteredChildren = _.filter(children, c => _.includes(matching, c) || _.intersection(matching, recursiveChildren(c)).length);
  }

  let childrenBlocks = [];
  if (!hideChildren || forceShowChildren) {
    childrenBlocks = _.map(
      _.sortBy(filteredChildren, b => -recursiveCount(b)),
      c => <Block b={c} key={c.id} onClick={onClick} global={global} onUpdateNode={onUpdateNode}
                  onRenameNode={onRenameNode} selection={selection} fromAltParent={c.parentId !== b.id} parent={b}
                  showMaxLevels={showMaxLevels ? showMaxLevels - 1 : null}/>
    );
  }


  const blockClass = [];
  if (_.includes(selection, b)) {
    blockClass.push('block_selectable--selected');
  }

  if (hidden) {
    blockClass.push('block_selectable--hidden');
  }

  const onToggleVisibility = (e) => {
    e.stopPropagation();
    b.hidden = !b.hidden;
    onUpdateNode(b);
  };

  const onRemoveSymlink = () => {
    b.altParentIds = _.without(b.altParentIds, parent.id);
    parent.children = _.without(parent.children, b);
    onUpdateNode(b);
    onUpdateNode(parent);
  };

  const onToggleHideChildren = (e) => {
    e.stopPropagation();
    b.hideChildren = !b.hideChildren;
    onUpdateNode(b);
  };

  const onRename = (e, node) => {
    e.stopPropagation();
    if (e.shiftKey) {
      let newName = prompt('Enter new name (WARNING: All tagged symptoms will ve renamed) : ', node.name);
      if (newName && newName !== node.name) {
        onRenameNode(node, newName);
      }
    } else {
      let alias = prompt('Enter ALIAS: ', node.alias || node.name);
      if (alias) {
        node.alias = alias;
        onUpdateNode(node);
      } else if(alias === '') {
        delete node.alias;
        onUpdateNode(node);
      }
    }
  };

  const onEditFullName = (e, node) => {
    e.stopPropagation()
    let newFullName = prompt('Enter new full name: ', node.fullName || '');
    if (newFullName) {
      node.fullName = newFullName;
      onUpdateNode(node);
    } else if (newFullName === '') {
      delete node.fullName;
      onUpdateNode(node)
    }
  }

  const onEditSemantics = (e, node) => {
    e.stopPropagation()

    const onOk = (semantics) => {
      page.modalActionsBus.emit('close')
      node.semantics = semantics
      onUpdateNode(node)
    }

    page.modalActionsBus.emit('open', <SemanticSelectorModal node={node} onOk={onOk}/>)

  }
  const totalCount = recursiveCount(b);

  const reportIdsInAdminHref = `https://opinautos.com/admin/reports?id=${reportIds[0]}&filters=${encodeURIComponent(JSON.stringify({
    idReports: reportIds, showingAdminExtensions: true
  }))}`;


  const symptomsCount = children?.length ?
    <span className={'text-black-50 small'}>({reportIds?.length || 0})</span> : null;
  let nameOrAlias;
  if (alias) {
    nameOrAlias = <div className={'inline-block align-top'}>
      <div className={'text-dark font-italic'} title={`Original name: "${name}"`}>{alias}</div>
      <div className={'text-secondary small'}>{name} {symptomsCount}</div>
    </div>;
  } else {
    nameOrAlias = <span>{name} {symptomsCount}</span>;
  }
  let renderFullName;
  if (fullName) {
    renderFullName = <div className={'font-italic text-info small'}>{fullName}</div>
  }
  let renderSemantics;
  if(semantics) {
    renderSemantics =<div>{ _.map(semantics, sem => <SemanticTag key={sem} level={'primary'} semKey={sem}/>)}</div>
  }

  return <div className={'block' + (totalCount ? '' : ' block--empty') + (fromAltParent ? ' block--symlink' : '')}>
    <div className={'block_selectable ' + blockClass.join(' ')} onClick={e => onClick(e, b)} title={name}>
      <a className={'block_count text-primary'} href={reportIdsInAdminHref} target={'_blank'}
         onClick={e => e.stopPropagation()}>{totalCount}</a>

      <div><span onDoubleClick={e => onRename(e, b)} className={nameMatchesFilter ? 'bg-highlight' : ''}>
        {nameOrAlias}{' '}
        {renderFullName}
        {b.altParentIds?.length ? <span className={'badge badge-secondary'}>{b.altParentIds.length} refs</span> : null}
      </span>
      {renderSemantics}
      </div>

      <div className={'ml-1'}>
        {fromAltParent ? <IconButton className={'m-0 p-0'} icon={'remove_circle_outline'} level={'primary'}
                                     onClick={onRemoveSymlink}/> : null}

        {filteredChildren?.length ? <span className={(hideChildren ? '' : 'block__hoverVisible')}>
            <IconButton className={'m-0 p-0'}
                        icon={hideChildren ? 'share' : 'share'}
                        level={hideChildren ? 'danger' : 'secondary'}
                        onClick={onToggleHideChildren}/></span>
          : null}

        <span className={'block__hoverVisible'}>
          <IconButton className={'m-0 p-0'} icon={hidden ? 'visibility_off' : 'visibility'} level={'secondary'}
                      onClick={onToggleVisibility}/>
          <IconButton className={'m-0 p-0'} icon={'add_task'} level={'secondary'} title={'Add full name'}
                      onClick={(e) => onEditFullName(e,b)}/>
          <IconButton className={'m-0 p-0'} icon={'data_object'} level={'secondary'} title={'Add semantics'}
                      onClick={(e) => onEditSemantics(e,b)}/>
        </span>
      </div>
    </div>
    <div>
      {childrenBlocks}
      {(children?.length && filter && !nameMatchesFilter) ? <Icon icon={'more_horiz'} level={'secondary'}/> : null}
    </div>
  </div>;
}


export function SymptomsHierarchyEditor({ initialData, onChange, onRenameSymptom }) {

  let [data, setData] = useState(initialData);
  let [filterString, setFilter] = useState(null);
  let [selection, setSelection] = useState([]);
  let [showLevels, setLevels] = useState(null);
  let [forceShowChildren, setForceShowChildren] = useState(false);

  let topLevel = _.sortBy(_.filter(data, b => b.parent == undefined), b => -recursiveCount(b));

  const updateData = updatedData => {
    const dataCopy = [...updatedData];
    setData(dataCopy);
    onChange(dataCopy);
  };

  const onUpdateNode = () => updateData(data);

  const onClickSymptom = (e, b) => {
    if (!selection.length || e.shiftKey || e.ctrlKey) {
      if (e.shiftKey && selection.length === 1 && selection[0].parent === b.parent && !b.parent) {
        let from = filteredTopLevel.indexOf(selection[0]);
        let to = filteredTopLevel.indexOf(b);

        setSelection(filteredTopLevel.slice(Math.min(from, to), Math.max(from, to) + 1));
      } else {
        setSelection(_.union(selection, [b]));
      }
    } else {
      if (!_.includes(selection, b)) {
        for (const sel of selection) {
          if (_.includes(recursiveChildren(sel), b)) {
            alert(`Cannot do that: "${b.name}" is already a deep children of "${sel.name}", it would create a loop`);
            return;
          }

          if (e.altKey && b) {
            sel.altParentIds = _.uniq(_.union(sel.altParentIds, [b.id]));
          } else {
            if (sel.parent) {
              sel.parent.children = _.without(sel.parent.children, sel);
            }

            sel.parent = b;
            sel.parentId = b?.id;
          }
        }
        if (b) {
          b.children = _.uniq(_.union(b.children, selection));
        }
      }

      updateData(data);
      setSelection([]);
    }
  };

  const addGroup = () => {
    let name = prompt('Enter group name');
    if (name) {
      let newObj = { id: uuidv4(), name, reportIds: [], manuallyCreated: true };

      if (!selection.length) {
        data.unshift(newObj);
      } else {
        let minPos = _.min(_.map(selection, s => data.indexOf(s))) || 0;

        let parents = _.uniq(_.map(selection, 'parent'));

        for (const sel of selection) {
          if (sel.parent) {
            sel.parent.children = _.without(sel.parent.children, sel);
          }
          sel.parent = newObj;
          sel.parentId = newObj?.id;
        }

        if (parents.length === 1 && parents[0]) {
          parents[0].children = _.difference(parents[0].children, selection);
          parents[0].children.push(newObj);
          newObj.parent = parents[0];
          newObj.parentId = parents[0].id;
        }

        newObj.children = selection;
        data.splice(minPos, 0, newObj);
        setSelection([]);
      }
      updateData(data);
    }
  };

  const remove = bs => {
    if (!confirm('Are you sure to delete?')) return;

    let newData = [...data];

    for (const b of bs) {
      if (!b.children?.length) {
        if (b.parent) {
          b.parent.children = _.without(b.parent.children, b);
        }
        newData = _.without(newData, b);
      }
    }
    updateData(newData);
    setSelection([]);
  };

  let contextualButtons = null;
  if (selection.length) {
    contextualButtons = (
      <span>
        <span className={'btn btn-sm btn-primary mr-1'} onClick={e => onClickSymptom(e, null)}>Move top level</span>
        <span className={'btn btn-sm btn-danger mr-1'}
              onClick={() => remove(selection)}>Delete {selection.length}...</span>
        <span className={'btn btn-sm btn-secondary mr-1'} onClick={() => setSelection([])}>Cancel</span>
      </span>
    );
  }

  let addBtn = <span className={'btn btn-sm btn-success mr-1'}
                     onClick={addGroup}>{selection.length ? 'Group selected' : 'Add symptom group'}</span>;


  let filteredTopLevel = topLevel;
  let filterRE = null;
  if (filterString) {
    try {
      filterRE = new RegExp(filterString, 'i');
      const matchs = _.filter(data, n => n.name.match(filterRE));
      filteredTopLevel = _.intersection(matchs, topLevel);
      filteredTopLevel = _.union(filteredTopLevel, _.filter(topLevel, n => _.intersection(recursiveChildren(n), matchs).length));
    } catch (err) {
    }
  }

  const inputShowLevels = <div className={'d-flex d-inline text-small'}>&nbsp; Amount of levels to show: &nbsp;
    <SingleTextEditor small className={'mr-2'} style={{ width: '35px' }} value={showLevels} placeholder={'All'}
                      onChange={(l) => setLevels(parseInt(l))}/>
  </div>;

  const toggleForceShowChildren = <IconButtonToggle className={'mr-3 align-middle'} icon={'share'} value={forceShowChildren}
                                                    onChange={() => setForceShowChildren(!forceShowChildren)}/>;

  const globalOptions = { filter: filterRE, forceShowChildren };

  return (
    <div className={selection.length ? 'state-selection' : 'state-notmoving'}>
      <div className={'sticky-top p-2 d-flex bg-white'}>
        <InputRegexFilter id={'symptoms-filter-input'} small className={'w-25 mr-2'} value={filterString}
                          onChange={(str) => setFilter(str)}/>
         {inputShowLevels} {toggleForceShowChildren} {addBtn} {contextualButtons}
      </div>

      <div className={'px-2'}>
        {_.map(filteredTopLevel, b => {
          return (
            <Block b={b} key={b.id} onClick={onClickSymptom} global={globalOptions} onUpdateNode={onUpdateNode}
                   onRenameNode={onRenameSymptom} selection={selection} showMaxLevels={showLevels}/>
          );
        })}
      </div>
    </div>
  );
}
