import GlobalDataCache from './GlobalDataCache';

let globalCache = null;

export default class ManualsDataCache extends GlobalDataCache {
  async loadData() {
    if(!globalCache) {
      const { manuals } = await client.service('/services/data/manuals/list').find()
      globalCache = manuals;
    }
    return globalCache
  }
}
