import React, { Component } from 'react';
import _ from 'lodash';

import SemanticTag from '../../semantic/SemanticTag';
import { IconButton } from '../../common/IconButton';
import ModelContextEditor from '../ModelContextEditor';

export default class LegoReferenceEditor extends Component {
  constructor(props) {
    super(props);

    this.referenceTester = window.client.service('/services/lego-reference-tester');

    this.state = {
      reference: props.reference,
      result: null,
      context:  this.props.context
    };

    this.testReference()
    this.testReference = _.debounce(this.testReference, 200);
  }

  async testReference() {
    let res = await this.referenceTester.get(`{{${this.state.reference}}}`, {query: { context: {model: this.state.context }}});
    this.setState({ result: res, loading: false });
  }

  async referenceChanged(e) {
    let q = e.target.value;

    this.setState({ reference: q, loading: true });
    this.testReference();
  }

  updateContext(newContext) {
    this.setState({context: newContext})
    this.testReference();
  }

  render() {
    let results = null;

    let {loading, result, reference, context} = this.state;

    if (result) {
      if(_.isString(result)) {
        results = <div className={'border p-2'}>
          <div dangerouslySetInnerHTML={{ __html: result }}/>
        </div>
      } else {
        results = <pre className={'bg-light monospace'}>{JSON.stringify(result, true, 2)}</pre>
      }
    } else if(!loading) {
      results = <div className={'alert alert-danger'}>No results</div>;
    }


    return <div style={{minHeight: "200px"}}>
      <div className={'mb-2'}>
        <input autoFocus={true} placeholder={'Type reference...'} type={'text'} className={'form-control'} value={this.state.reference}
               onChange={this.referenceChanged.bind(this)}/>
      </div>

      <div className={'row no-gutters'}>
        {/*<div className={'col-6'}>*/}

        {/*</div>*/}
        <div className={'col-12'}>
          <div>
            <ModelContextEditor value={context} onChange={(ctx) => this.updateContext(ctx)}/>
          </div>


          <div className={'mt-3'+(loading ? ' translucent' : '')}>{results}</div>
          <div className={'mt-3 '}>
            {/*<button onClick={() => this.props.onOk("")} className={'btn btn-primary mr-2'}>Ok</button>*/}
            {/*<button onClick={() => this.props.onCancel} className={'btn btn-default'}>Cancelar</button>*/}
          </div>
        </div>
      </div>
      <button className={'btn btn-primary'} onClick={() => this.props.onOk(this.state.reference)}>Save reference changes</button>
    </div>;
  }
}
