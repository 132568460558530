import _ from 'lodash'

let onPasteHandlers = [];
let captureCbk = null;
let current = null;

export default class GlobalClipboard  {
  static onPasteImage(handler) {
    onPasteHandlers = _.uniq([... onPasteHandlers, handler]);
  }

  static removeOnPasteImage(handler) {
    onPasteHandlers = _.without(onPasteHandlers, handler);
  }

  static pasteImage(imageData, imageCapturedCallback) {
    captureCbk = imageCapturedCallback;
    current = imageData
    for (let handler of onPasteHandlers ) {
      try {
        handler.onPastePotential(imageData)
      } catch(err) {
        console.error("Error in GlobalClipboard paste handler", err)
      }
    }
  }

  static cancelPaste() {
    for (let handler of onPasteHandlers ) {
      try {
        handler.onPastePotential({cancel: true})
      } catch(err) {
        console.error("Error in GlobalClipboard paste handler", err)
      }
    }
  }

  static getActivePasteListeners() {
    return [ ... onPasteHandlers];
  }

  static grabImageOnClipboard() {
    if(captureCbk) {
      captureCbk();
      captureCbk = null;
    }
    let copy = current;
    current = null;

    this.cancelPaste();

    return copy;
  }
}
