import React, { Component, useContext } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import submenuModels from './menus/submenu-models';
import CRUDPage from './CRUDPage';
import { DataIssuesFiltersBar } from '../components/data-issues/DataIssuesFiltersBar';
import NiceDate from '../components/common/NiceDate';
import BadgeId from '../components/common/BadgeId';
import { ImagePreview } from '../components/images/ImagePreview';
import MongoDocEditor from '../components/lego/MongoDocEditor';
import { DataIssuesStatistics } from '../components/data-issues/DataIssuesStatistics';
import { SearchFilterButton } from '../components/common/SearchFilterButton';
import { TypeTag } from '../components/lego/TypeTag';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import { SingleImageUrlEditor } from '../components/common/images/SingleImageUrlEditor';
import TrimSpec from '../components/lego/trims/TrimSpec';
import LegoAdminPageContext from './legoAdminPageContext';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import NotesEditor from '../components/common/editors/NotesEditor';
import { MultipleImagesEditor } from '../components/common/editors/MultipleImagesEditor';

function GenerationsFiltersBar({ filter, onChange }) {
  const { page } = useContext(LegoAdminPageContext)
  const refreshModeloCaches = () => page.runAsync(page.service('/services/data/models').update({}, {}));
  const onFieldChange = (field, value) => {
    let newFilter = _.cloneDeep(filter || {});

    if (field === 'baseModelId') {
      if (value.modelId) {
        value = value.modelId;
      } else if (value.make) {
        value = { $regex: '^' + value.make };
      }
    }

    if (_.isEmpty(value)) {
      delete newFilter[field];
    } else {
      newFilter[field] = _.cloneDeep(value);
    }

    onChange(newFilter);
  };

  let { baseModelId } = filter || {};

  let context = null;
  if(baseModelId?.$regex) {
    context = {make: baseModelId.$regex.slice(1)}
  } else if(baseModelId) {
    context = {modelId: baseModelId}
  }

  return <div className={'d-flex align-items-center'}>
    <span style={{ width: '350px', display: 'inline-block' }} className={'zoom-75 ml-3'}>
        <ModelContextEditor value={context }
                            onChange={(newContext) => onFieldChange('baseModelId', newContext)}/>
      </span>

    <span className={'ml-3 btn btn-sm btn-warning'} onClick={refreshModeloCaches}>Refresh site generations cache</span>
  </div>;
}

export default class GenerationsManager extends CRUDPage {
  constructor(props) {
    super(props, '/services/data/models/gens');

    this.submenu = submenuModels;
    this.state.columnSort = { index: 1, asc: true, sorter: ['genId'] }
    this.newObjectTemplate = {
      baseModelId: null,
      fromYear: 0,
      toYear: 0,
      gen: '1',
      genId: '',
      specs: null,
    }
  }

  getFacetFiltersComponent() {
    return <GenerationsFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  // getSearchHandler() {
  //   const dataIssueSearchFilters = {
  //     label: {
  //       filter: labelFilter => (l => (l.labels?.length && (!labelFilter || _.some(l.labels, x => x.includes(labelFilter.toLowerCase()))))),
  //       description: 'With labels [=<prefix>...]'
  //     },
  //     s: {
  //       filter: txt => (l => l.state && (!txt || l.state.startsWith(txt))),
  //       description: 'With state [=<prefix>...]'
  //     },
  //   };
  //
  //   const nodeTextSearchCustomizer = text => text.replace(/\s+/g, '[\\w_\\s]*');
  //
  //   return new ObjectSearchHandler(dataIssueSearchFilters, nodeTextSearchCustomizer);
  // }

  getColumnsDefinition(objects) {
    return [
      { content: 'Last update', className: 'text-center', sorter: 'updatedAt' },
      { content: 'Gen Id', className: 'text-center', sorter: ['genId'] },
      { content: 'photo', className: 'text-center'},
      { content: 'Gen', className: 'text-center', sorter: ['baseModelId', 'gen'] },
      { content: 'Specs', className: 'text-left', sorter: ['specs'] },
      { content: 'Name', className: 'text-center' },
      { content: 'fromYear', className: 'text-center'},
      { content: 'toYear', className: 'text-center'},
      { content: 'photos', className: 'text-left'},
    ];
  }

  getObjectColumns(generation,def,i,allRows) {
    let {
      genId, baseModelId, gen, specs, name, codenames, fromYear, toYear, photo, referencePhotos, notes, updatedAt, createdAt, updatedBy
    } = generation;

    let modelChangeStyle = {};
    if(i > 0 && allRows[i-1].baseModelId !== baseModelId) {
      modelChangeStyle = {borderTop: '10px solid #555'};
    }

    let genColors = {
      '1': 'bg-light-success',
      '2': 'bg-light-partial-success',
      '3': 'bg-patito',
      '4': 'bg-light-warning',
      '5': 'bg-light-ladrillo',
      '6': 'bg-light-rosa',
      '7': 'bg-light-violeta',
    }


    let genColorClass = genColors[gen.toString().split('.')[0]] || 'bg-light-secondary';

    const isSubgen = gen.toString().split('.').length > 1;
    if(isSubgen) {
      genColorClass += ' text-black-50'
    }

    return [
      <td key={'id'}  style={modelChangeStyle}>
        { updatedAt && <NiceDate t={updatedAt}/> }
      </td>,

      <td  key={'count'}  style={modelChangeStyle}>
        <code className={'text-primary'}>{genId}</code>
      </td>,

      <td  key={'blocked'}  style={modelChangeStyle}>
        {photo?.url ?
        <ImagePreview img50 zoom={true} url={photo?.url}/> : null }
      </td>,

      <td  key={'modelId'} className={genColorClass} style={modelChangeStyle}>
        <div className={'h5 text-left pl-2'}>
          <TrimSpec spec={'modelId'} className={'mr-3'} value={baseModelId}/>
          {gen}
        </div>
      </td>,

      <td  key={'context'} className={genColorClass} style={modelChangeStyle}>
        <div className={'h5 text-left pl-2'}>
          {specs && <LegoContextSummary context={specs} className={'zoom-75'}/>}
        </div>
      </td>,

      <td  key={'name'} className={'text-center '+genColorClass} style={modelChangeStyle}>
        <span className={'h5'}> {name || '-'}</span> <em className={'h6'}>{(codenames || []).join(', ')}</em>
      </td>,

      <td  key={'fromYear'} className={'text-center'} style={modelChangeStyle}>
        {fromYear}
      </td>,

      <td  key={'toYear'}  className={'text-center'} style={modelChangeStyle}>
        {toYear}
      </td>,

      <td  key={'refpics'}  style={modelChangeStyle}>
        { (referencePhotos || []).slice(0,4).map(ph => <ImagePreview key={ph.url} img50 zoom={true} url={ph.url}/>) }
      </td>,
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    let genIdentifier = obj.baseModelId+'_G'+obj.gen;
    let schema = {
      baseModelId: (value, onChange) => <ModelContextEditor value={value ? {modelId: value} : null} onChange={ctx => onChange(ctx?.modelId)}/>,
      specs: (value, onChange) => <ModelContextEditor value={value} onChange={ctx => onChange(ctx)}/>,
      gen: null,
      genId: (value, onChange, obj) => <TrimSpec spec={'genId'} value={`@${obj?.baseModelId}@G${obj?.gen}`}/>,
      name: "string",
      codenames: null,
      photo: (value, onChange) => <SingleImageUrlEditor folder={'model-gen-pictures'}
                                                          category={'generation-picture'}
                                                          value={value} defaultName={genIdentifier}
                                                          onChange={onChange}/>,
      fromYear: 'number',
      toYear: 'number',

      referencePhotos: (value, onChange) => <MultipleImagesEditor value={value} onChange={onChange} folder={'model-gen-ref-pictures'} forceJPG category={'generation-ref-pictures'}/>,

      notes: (value, onChange) => <NotesEditor value={value} onChange={onChange}/>
   }

    let onSave = (changedObj, ... args) => {
      changedObj.genId = `@${changedObj.baseModelId}@G${changedObj.gen}`;

      if(!changedObj.fromYear)
        delete changedObj.fromYear;

      if(!changedObj.toYear)
        delete changedObj.toYear;

      return defaultOnSaveCbk(changedObj, ... args);
    }

    return <MongoDocEditor extendSchema={schema} obj={obj} showFields={_.keys(schema)} onSave={onSave} onCancel={defaultOnCancelCbk} />
  }
}
