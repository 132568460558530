import { useContext, useEffect, useState } from 'react';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { IconButton } from '../common/IconButton';
import { openLego } from './LegoEditorModal';

function legoFuseboxUrl(modelId, year, currentFuseboxId) {
  return `/legos/fusebox?filters=%7B"type"%3A%7B"%24in"%3A%5B"fusebox"%5D%7D%2C"context"%3A%7B"modelId"%3A"${encodeURIComponent(modelId)}"%2C"year"%3A${year}%7D%7D&editing="${currentFuseboxId}"`;
}

export default function LegoEditButton({ legoId, lego, icon, editorProps, extraQuery, children, onRefresh, ...otherProps }) {
  let { page } = useContext(LegoAdminPageContext);
  const openLegoEditor = async (e) => {
    if (legoId) {
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        let uri = `/legos/all?editing="${legoId}"`;
        if (lego && (lego.type === 'fuseboxTemplate' || lego.type === 'fusebox')) {
          uri = legoFuseboxUrl(lego.context[0]?.modelId, lego.context[0]?.year, legoId);
        }
        // Use set timeout because otherwise the key modifiers change how the window is opened
        setTimeout(() => window.open(uri+(extraQuery ? `&${extraQuery}` : ''), '_blank'), 1);
      } else {
        openLego(legoId, page, editorProps);
      }
    }
  };

  return <IconButton icon={icon || 'preview'} level={legoId ? 'primary' : 'secondary'} onClick={openLegoEditor}>
    {children}
  </IconButton>;
}
