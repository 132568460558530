import React, {Component} from 'react';

import cdn from "../interop/cdn";

export function ImgIcon({ icon, gen, material, small, large, color, className, ...other }) {
  if(material) {
    const classes = ['MaterialIcon'];

    if (small) {
      classes.push('MaterialIcon--small')
    }

    if(color) {
      classes.push(`MaterialIcon--${color}`)
    }

    if(className) {
      classes.push(className);
    }

    return <i className={`material-icons align-middle ${classes.join(' ')} ${className || ''}`} {...other }>
      {icon}
    </i>;
  } else {
    const classes = ['Icon'];
    if (small) {
      classes.push('Icon--small')
    }
    if(large) {
      classes.push('Icon--large')
    }

    if(className) {
      classes.push(className);
    }

    let iconVariation = `--${color || 'blue'}`;

    const relativePath = `./images/${gen ? 'generated' : 'design2/icons'}/icon_${icon}${iconVariation}.svg`;

    return <img alt={icon} className={classes.join(' ')} src={cdn(relativePath)} {... other}/>}
}
