import React from 'react';

import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SymptomLegoEditor from './SymptomLegoEditor';

export default class WarningLightsSymptomLegoEditor extends SymptomLegoEditor {
  constructor(props) {
    super(props);

    this.state.dataTypesMap = {
      WHAT_IS: 'Qué es',
      WHY: 'Por qué enciende',
      WHAT_CAN_DO: 'Qué hacer y revisar',
      RISK: 'Riesgo',
      RELATED_SYMPTOMS: 'Síntomas relacionados'
    };

    this.state.symptomsType = 'warning-lights';
    this.state.headerTextLegos = [];
  }
}

WarningLightsSymptomLegoEditor.contextType = LegoAdminPageContext;
