import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { IconButton } from './IconButton';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import ModelContextEditor from '../lego/ModelContextEditor';
import EditObjectFormTable from './editors/EditObjectFormTable';
import SingleTextEditor from './editors/SingleTextEditor';
import GridLayoutLeftBar from './layout/GridLayoutLeftBar';
import TopBarLayout from './layout/TopBarLayout';
import useAsyncEffect from './react-hooks/useAsyncEffect';
import useUser from './react-hooks/useUser';
import definedTemplates from '../../../../emailTemplates/datafeedback/definedEmailTemplates.mjs';
import OpinautosUrlEditor from './editors/OpinautosUrlEditor';


function ModelIdEditor({value, onChange}) {
  const onContextChange = (newVal) => onChange(newVal?.modelId || null)
  return <ModelContextEditor value={value ? {modelId: value} : null} onChange={onContextChange}/>
}

export default function TemplateEmailEditor({ userToId, preloadedState, onSent, dataFeedbackId }) {
  let [templateslist, setList] = useState(_.values(definedTemplates));

  let [selectedTemplate, setSelected] = useState();
  let [templateState, setTemplateState] = useState(preloadedState || {});

  let [htmlPreview, setHtmlPreview] = useState();
  let [subject, setSubject] = useState();

  let [showMobile, setShowMobile] = useState(true);
  let userData = useUser(userToId);

  let { page } = useContext(LegoAdminPageContext);
  const conversationsService = client.service('services/data/emails/conversations');

  // const loadList = async () => {
  //TODO: fix template list query
  // let templates = await page.runAsync(mailsVariationsService.find({query:{} }));
  // setList(templates)
  // };

  const renderTemplate = (template, state) => {
    if (template) {
      page.runAsync(async () => {
        let { html, subject } = await conversationsService.get(template, {
          query: {
            state, options: { renderErrors: true }
          }
        })
        setHtmlPreview(html);
        setSubject(subject);
      });
    }
  };

  const sendEmail = () => {
    if(selectedTemplate && templateState.userId && userData.email) {
      page.runAsync(async () => {
        await conversationsService.create({
          userTo: {
            id: userToId,
            email: userData.email
          },
          template: selectedTemplate,
          templateParams: templateState,
          options: {
            dataFeedbackId, // Could be undefined, but it is supported
            createdBy: page.getLoggedUserSignature()
          }
        })
        onSent();
      })
    }
  }

  const debouncedRenderTemplate = useCallback(_.debounce(renderTemplate, 350), []);

  useAsyncEffect(async () => {
    debouncedRenderTemplate(selectedTemplate, templateState);
  }, [JSON.stringify(templateState)]);

  useAsyncEffect(async () => {
    renderTemplate(selectedTemplate, templateState);
  }, [selectedTemplate?.templatePath]);

  const renderSelectedEmail = () => {
    if (!htmlPreview) {
      return <div className={'text-center text-white bg-secondary full-screen-center'}>Select a campaign...</div>;
    }

    let sizeStyle = { width: '100%', height: '100%', border: 'none', position: 'absolute' };

    if (showMobile) {
      sizeStyle = { width: '340px', marginTop: '0.5em', height: '540px', border: 'none', position: 'absolute', left: 'calc(50% - 160px)' };
    }

    return <TopBarLayout className={'bg-secondary'}>
      <div className={'d-flex justify-content-between bg-light-dark px-3'}>
        <IconButton icon={'refresh'} onClick={() => renderTemplate(selectedTemplate, templateState)}/>

        <h5 className={'mt-3 mb-3 text-white'}>
          <span className={'text-info'}>Subject: </span>{subject}
        </h5>

        <IconButton icon={showMobile ? 'computer' : 'phone_iphone'} onClick={() => setShowMobile(!showMobile)}/>
      </div>

      <iframe
        style={sizeStyle}
        id={'email'}
        name={'email'}
        sandbox={'allow-same-origin'}
        srcDoc={htmlPreview}
      />
    </TopBarLayout>;
  };

  let editorsByType = {
    userId: SingleTextEditor,
    string: SingleTextEditor,
    siteUrl: OpinautosUrlEditor,
    context: ModelContextEditor,
    modelId: ModelIdEditor
  };

  let rows = _.map(selectedTemplate?.parameters, p => [p.name, editorsByType[p.type]]);

  const editField = (fieldPath, newValue) => {
    let newState = _.cloneDeep(templateState);
    _.set(newState, fieldPath, newValue);
    setTemplateState(newState);
  };

  let actionBtns;
  if (selectedTemplate) {
    actionBtns = <div className={'text-center mt-4'}>
      <span className={'btn btn-primary'} onClick={sendEmail}>Send email to <span className={'monospace bg-light-dark p-1 rounded'}>{userData.email}</span></span>
    </div>;
  }

  return <GridLayoutLeftBar style={{ height: 'calc(100% - 42px)' }} className={'bg-light'}>
    <div style={{ width: '600px' }} className={'p-3'}>
      <div className={'text-secondary mb-1'}>Select a template:</div>
      <div className={'overflow-auto pos-relative mb-4 list-group'}>
        {_.map(templateslist, t => <a href="#"
                                      className={'small list-group-item list-group-item-action py-2 ' + (t === selectedTemplate ? 'active' : '')}
                                      key={t.templatePath}
                                      onClick={() => setSelected(t)}>
          {t.templatePath}
        </a>)}
      </div>

      <div className={'px-2'}>
        <div className={'text-secondary mb-1'}>Template parameters:</div>
        <EditObjectFormTable rows={rows} object={templateState} onEditField={editField}
                             className={'rounded table table-sm'}/>
      </div>

      {actionBtns}
    </div>
    {renderSelectedEmail()}
  </GridLayoutLeftBar>;
}
