import React from 'react';

// This module is designed to run in a browser context where window.i18nData is already loaded and contains
// the "json" object with the propper i18n locale for the current context (en.json, es.json, es-ar.json, ...)
const __ = (fullKey, ... params) => {
  let keys = window.i18nData;

  let path = fullKey.split('.');
  let section = keys;
  for (const subkey of path) {
    if (section[subkey]) {
      section = section[subkey];
    } else {
      section = null;
    }

    if (!section) {
      console.error(`Could not find i18n subkey '${subkey}' of '${fullKey}' in window.i18nData`);
      return fullKey;
    }
  }

  // TODO: Support a i18n lib consistent formatting of parameters
  // TODO: - Check if multiple '%s' really work it is used a lot but NOT SUPPORTED BY THE FOLLOWING CODE
  // TODO: - Support pluralization
  if (typeof section === 'string') {
    if(section.match(/%s/)) {
      let i = 0;
      return section.replace(/%s/g, (match, other) => {
        return params[i++];
      });
    } else {
      const parameters = params[0];
      return section.replace(/{{([^}]+)}}/g, (match, paramName) => {
        if (parameters[paramName]) {
          return parameters[paramName];
        } else {
          console.error(`i18n key '${fullKey}' references parameter ${match}, not found in invocation to i18n: `, parameters)
          return match;
        }
      })
    }
  } else {
    alert("i18n interop for react components does not support pluralization YET")
  }
};

// Needed when using i18n values with html markup in them
export const __html = (... args) => {
  return <span dangerouslySetInnerHTML={{__html: __(... args)}} />;
};

export const __n = (key, count) => {
  if(count === 1) {
    return __(`${key}.one`) || __(key);
  } else {
    return __(`${key}.other`) || __(key);
  }
};


export default __;
