import _ from 'lodash'
import React, {Component} from 'react';

import TrimSpecsEditor from './TrimSpecsEditor';

export class TrimSpecBatchEditor extends Component {
  constructor(props) {
    super(props);

    let trims = this.props.trims;

    let specsInCommon = this.specsCommonFactor(trims);

    this.state = {
      editedTrims: [...trims],
      originalCommonFactor: specsInCommon,
      commonFactor: specsInCommon
    };
  }

  specsCommonFactor(trims) {
    if (trims.length) {
      let commonPairs = null

      for (const { specs } of trims) {
        if (!commonPairs) {
          commonPairs = { ...specs };
        } else {
          _.each(commonPairs, (val, key) => {
            if (!specs || !specs[key] || specs[key] !== val) {
              delete commonPairs[key];
            }
          })
        }
      }

      return commonPairs;
    } else {
      return {}
    }
  }

  saveChanges() {
    // Update trims and onSave
    const newTrims = _.map(this.props.trims, (trim) => {
      _.each(this.state.originalCommonFactor, (val, key) => {
        // If a common spec was removed, delte it from trim's specs
        if (!this.state.commonFactor[key]) {
          delete trim.specs[key]
          trim.__changed = true;
        }
      })
      _.each(this.state.commonFactor, (newVal, key) => {
        trim.specs = trim.specs || {}
        trim.specs[key] = newVal
        trim.__changed = true;
      });
    })

    this.props.onSave();
  }

  render() {
    const trims = this.props.trims;
    const { editedTrims, commonFactor, originalCommonFactor } = this.state;

    const thereAreChanges = !_.isEqual(originalCommonFactor, commonFactor);

    return <div className={''}>
      <h5>Editing common specs of {trims.length} trims in batch:</h5>

      <TrimSpecsEditor value={commonFactor}
                       autoFocus={true}
                       onChange={(newSpecs) => this.setState({ commonFactor: newSpecs })}
                       onEnter={this.saveChanges.bind(this)}
      />

      <span className={`mt-1 btn btn-${thereAreChanges ? 'primary' : 'secondary'}`}
            onClick={thereAreChanges ? this.saveChanges.bind(this) : () => false}
      >
        Save changes
      </span>

      <div className={'mt-2 bg-light p-2'}>
        {_.map(trims.slice(0, 50), t => <div key={t._id} className={'small'}>
          <span className={'text-info mr-2'}>{t.year}</span>
          {t.displayName}
        </div>)}
      </div>
    </div>
  }
}
