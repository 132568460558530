import React, {Component} from "react";
import MultiValueSelector from "../../common/editors/MultiValueSelector";
import _ from 'lodash';
import {IconButton} from "../../common/IconButton";
import TrimSpecsEditor from "../trims/TrimSpecsEditor";
import MultipleItemsEditor from "../MultipleItemsEditor";
import {OilNormsDataCache, OilViscositiesDataCache} from "../data-caches/SingleLegoDataCache";

export class OilTypeLegoEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oils: [],
      viscosities: []
    }

    //TODO: Find way to remove onUpdate once the component has been unmounted.
    // maybe receive react component as param and handle that logic inside DataCache?
    this.oilsDataCache = new OilNormsDataCache(({ data }) => this.setState({ oils: _.compact(_.map(data, 'Norma')) }));
    this.viscositiesDataCache = new OilViscositiesDataCache(({ data }) => this.setState({ viscosities: _.map(data, 'name') }));
  }

  updateContext(newContext) {
    if(newContext.length > 0) {
      let lego = _.cloneDeep(this.props.value);
      lego.context = newContext;
      this.props.onChange(lego);
    }
  }

  updatePrimary(list) {
    let val = this.props.value;
    let [viscosities, qualities] = this.splitList(list);
    val.data = { ...val.data };
    val.data.viscosity = viscosities;
    val.data.quality = qualities;

    // Remove empty lists
    val.data = _.pickBy(val.data, v => v && v.length);

    this.props.onChange(val);
  }

  updateSecondary(list) {
    let val = this.props.value;
    let [viscosities, qualities] = this.splitList(list);
    val.data = val.data || {};
    val.data.viscosityAlternative = viscosities;
    val.data.qualityAlternative = qualities;

    // Remove empty lists
    val.data = _.pickBy(val.data, v => v && v.length);

    this.props.onChange(val);
  }

  splitList(mixedSpecifications) {
    let viscosities = _.intersection(this.state.viscosities, mixedSpecifications);
    let qualities = _.difference(mixedSpecifications, viscosities);
    return [viscosities, qualities]
  }

  render() {
    const { context, data, _id } = this.props.value;
    const oils = [... this.state.viscosities, ... this.state.oils];
    const { viscosity, quality, viscosityAlternative, qualityAlternative } = data || {};

    const primary = [...(viscosity || []), ...(quality || [])];
    const secondary = [...(viscosityAlternative || []), ...(qualityAlternative || [])];

    let contexts = _.isArray(context) ? context: [context];
    // Remove 'trims' field from context, as it is useless and should be fixed in original manual's contexts
    contexts = _.map(contexts, ({trims, ... restOfContext}) => restOfContext)

    let header = null;
    let stateBg = 'success'
    if(_id) {
      header = <div className={'small text-secondary '}>Lego {_id} <IconButton icon={''}/></div>
      stateBg = 'secondary'
    }

    let defaultNewContext = {};
    if (contexts[0] && contexts[0].modelId && contexts[0].year) {
      defaultNewContext = { modelId: contexts[0].modelId, year: contexts[0].year };
    }

    const contextEditor = <MultipleItemsEditor value={contexts}
                                               defaultNewItem={defaultNewContext}
                                               buttonsAlwaysVisible={true}
                                               renderComponent={(item, props) => <TrimSpecsEditor
                                               value={item} {...props}/>}
                                               onChange={(specs) => this.updateContext(specs)}
    />

    return <div className={`rounded p-1 bg-light-${stateBg} zoom-90`}>
      {header}

      {contextEditor}

      <div className={''}>
        <MultiValueSelector value={primary} onChange={this.updatePrimary.bind(this)} options={oils}
                            placeholder="Primary specification" className={''}/>

        <MultiValueSelector value={secondary} onChange={this.updateSecondary.bind(this)} options={oils}
                            placeholder="Alternative fallback" className={''}/>
      </div>
    </div>;
  }
}
