import React from 'react';
import MongoDocEditor from '../lego/MongoDocEditor';
import ModelContextEditor from '../lego/ModelContextEditor';
import IssueCategorySelector from './IssueCategorySelector';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import DataIssueStateSwitch from './DataIssueStateSwitch';
import NotesEditor from '../common/editors/NotesEditor';
import IssueSourceEditor from './IssueSourceEditor';
import DataIssueRefsEditor from './DataIssueRefsEditor';
import DataFeedbackRefsEditor from './DataFeedbackRefsEditor';
import FuseboxIssueDataEditor from './FuseboxIssueDataEditor';
import EditObjectFormTable from '../common/editors/EditObjectFormTable';
import { SwitchInput } from '../common/SwitchInput';
import { OpenClosedIcon } from './OpenClosedIcon';
import { IconButton } from '../common/IconButton';
import ModelFuseboxesManualsTable from '../lego/fusebox-editor/ModelFuseboxesManualsTable';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { MailConversationsList } from '../common/MailConversationsList';
import LegoLabelsSelector from '../lego/LegoLabelsSelector';
import _ from 'lodash';

export default class DataIssueEditor extends MongoDocEditor {
  static contextType = LegoAdminPageContext;


  openResourceModal() {
    const { page } = this.context

    const {modelId, year} = this.state.changedObject.dataSelector.vehicle
    page.modalActionsBus.emit('open',<ModelFuseboxesManualsTable modelId={modelId}
                                               year={year}/>, { title: `${modelId} ${year}: Manuals and fuseboxes ` });
  }

  openConversations() {
    const { page } = this.context
    const dataFeedbackService = page.service('/services/data/data-feedback');
    const { involvedDataFeedback } = this.state.changedObject
    dataFeedbackService.find({query:{ _id: {$in: involvedDataFeedback} }}).then((res) => {
      page.modalActionsBus.emit('open', <MailConversationsList threadIds={_.flatMap(res, 'conversationThreadIds')}/>, {fullScreen: true, title:'Email conversations for current data-issue'} )
    })
  }

  getStateEditor(state) {
    const isOpen = this.state.changedObject.isOpen;


    return <span>
      <IconButton icon={'forum'} onClick={() => this.openConversations()}>View conversations</IconButton>
      <IconButton icon={'calendar_view_month'} onClick={() => this.openResourceModal()}>Fusebox/manuals table</IconButton>
      <OpenClosedIcon isOpen={isOpen} className={'mr-1'}/>
      <span className={'align-middle'}>{isOpen ? 'Open issue' : 'Closed issue' }</span>

      <SwitchInput className={'mr-3 ml-2'} value={!isOpen} onChange={v => this.editField('isOpen', !v)}>
      </SwitchInput>


      <DataIssueStateSwitch value={state} onChange={(newState) => {
        this.editField('state', newState);
        if((newState === 'solved' || newState === 'wontfix') && isOpen !== false) {
          this.editField('isOpen', false);
        }
      }}/>
    </span>
  }

  getDataEditor(obj, data) {
    let rows = [
      [
        ['dataType', SingleValueSelector, { className: 'zoom-90', canCreate: true, options: ['fusebox', 'manual', 'model'] }],
        ['issueCategory', IssueCategorySelector, { className: 'zoom-90', dataType: obj.dataType}],
        ['labels', LegoLabelsSelector, {className: 'zoom-90'}],
      ],
      [
        ['dataSelector.vehicle', ModelContextEditor],
        ['source', IssueSourceEditor]
      ],
      [
        ['involvedDataFeedback', DataFeedbackRefsEditor],
        ['blockedBy', DataIssueRefsEditor, {vehicle: obj.dataSelector?.vehicle}]
      ],
      ['data', FuseboxIssueDataEditor, {year: obj.dataSelector.vehicle.year, modelId: obj.dataSelector.vehicle.modelId}],
      ['notes', NotesEditor],
    ];
    return <div>
      <EditObjectFormTable rows={rows} object={this.state.changedObject} onEditField={this.editField.bind(this)}/>
    </div>;
  }
}
