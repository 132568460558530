import React, { Component } from 'react';

export class IntentionTag extends Component {
  static types() {
    return ['glossary', 'glossary-extension', 'guide', 'spec', 'spec-summary', 'product', 'template', 'level-basic', 'level-advanced', 'level-expert', 'warning-lights', 'warning-lights-incomplete' ]
  }

  render() {
    let { intention } = this.props;

    return <span className={'badge badge-info'}>{intention}</span>;
  }
}
