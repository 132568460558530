import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import JsonTextEditor from '../common/editors/JsonTextEditor';
import { IconButton } from '../common/IconButton';
import { LegoStateSwitch } from './LegoStateSwitch';
import ModalManager from '../ModalManager';
import EventEmitter from 'events';
import JsonGUIEditor from "../common/editors/JsonGUIEditor";
import MultipleContextsEditor from "./MultipleContextsEditor";
import TextDiff from "../test-sets/TextDiff";
import MultiCountryEditor from "../common/editors/MultiCountryEditor";
import InputManualsMultipleSemantics from '../common/editors/InputManualsMultipleSemantics';
import BadgeId from '../common/BadgeId';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import NotesEditor from '../common/editors/NotesEditor';


export default class MongoDocEditor extends Component {
  constructor(props) {
    super(props);

    this.modalActionsBus = new EventEmitter();

    this.state = {
      original: this.props.obj,
      changedObject: this.props.changedObj || _.cloneDeep(this.props.obj),
    };

    // To prevent accidental double click
    this.throttledSave = _.debounce(this.save, 500, {leading: true, trailing: false});
  }

  componentDidMount() {
    this.checkChanges();
  }

  editField(fieldPath, newValue) {
    let updated = this.state.changedObject;
    _.set(updated, fieldPath, newValue);
    this.editObject(updated)
  }

  editObject(changedObject) {
    this.setState({ changedObject }, () => this.checkChanges());
  }

  checkChanges() {
    const areEqual = _.isEqual(this.state.changedObject, this.state.original);

    this.setState({ changed: !areEqual });
  }

  getDataEditor(obj, data) {
    const hiddenFields = ['_id', 'updatedAt', 'createdAt', 'createdBy', 'updatedBy', '__new', '__deleted', '__unsaved', '__v', 'state'];

    const schema = {
      'context': (value, onChange) => <MultipleContextsEditor onChange={onChange} value={value}/>,
      // 'country': (value, onChange) => <MultiCountryEditor onChange={onChange} value={value}/>,
      // 'metadata': (value, onChange) => <JsonTextEditor onChange={onChange} value={value}/>,
      ... (this.props.extendSchema || {}),
    }

    // Remove from hiddenFields any key that is also in the schema
    return <JsonGUIEditor hideFields={_.difference(hiddenFields, _.keys(schema))}
                          schema={schema}
                          showFields={this.props.showFields}
                          onChange={(json) => this.editObject(json)}
                          value={obj}/>;
  }

  openModalDiffChanges() {
    this.modalActionsBus.emit('open', <div>
      <div className={'p-2 bg-light monospace small break-word-all'}>
        <TextDiff type={'json'} inputB={JSON.stringify(this.state.changedObject, true, 2)}
                  inputA={JSON.stringify(this.state.original, true, 2)}/>
      </div>
    </div>, false);
  }

  openModalShowJSON() {
    this.modalActionsBus.emit('open', <div>
      <pre className={'small'}>{JSON.stringify(this.state.changedObject, true, 2)}</pre>
    </div>, false);
  }

  getStateEditor(state) {
    return <LegoStateSwitch onChange={(newState) => this.editField('state', newState)} value={state}/>
  }

  save() {
    this.props.onSave(this.state.changedObject, true);
  }

  render() {
    let l = this.state.changedObject;
    let { _id, updatedAt, state, createdAt, __unsaved, __new, __deleted, ... data } = l;

    let rowClasses = 'p-2';
    if (__new) {
      rowClasses += ' bg-light-success';
    }
    if (__unsaved) {
      rowClasses += ' bg-light-warning';
    }
    if (__deleted) {
      rowClasses += ' bg-light-danger';
    }

    if (this.state.changed) {
      rowClasses += ' bg-light-warn';
    }


    let editTime = '';
    if (updatedAt) {
      editTime = ((updatedAt === createdAt) ? 'Created ' : 'Edited ') + dayjs(updatedAt).fromNow();
    }

    const btnShowCode = <IconButton icon={'code'} level={'primary'} onClick={this.openModalShowJSON.bind(this)}/>;

    let btnCompare = null;
    if (this.state.changed) {
      btnCompare = <IconButton icon={'compare'} level={'primary'} description={'See diff of changes'}
                               onClick={this.openModalDiffChanges.bind(this)}>See diff</IconButton>;
    }

    const onSaveClick = () => this.throttledSave();

    const onCancelClick = () => this.props.onCancel();

    return <div className={rowClasses}>
      <LeftRightLayout className={'p-1 mb-2'}>
        <span className={''}>
          <BadgeId id={_id}/>

          {btnShowCode}

          <span className={'text-info small ml-2 mr-2 align-middle'} title={updatedAt}>{editTime}</span>

          {btnCompare}
        </span>

        {this.getStateEditor(state)}
      </LeftRightLayout>

      <div className={'bg-white rounded'}>{this.getDataEditor(l, data)}</div>


      <div className={'mt-3 p-1 '}>
        <button onClick={onSaveClick} className={'btn btn-primary mr-2'}>{__new ? 'Save new' : 'Ok'}</button>

        <button onClick={onCancelClick} className={'btn btn-default mr-2'}>Cancelar</button>
        {
          this.state.changed
            ?
            <button onClick={async () => {
              let updatedObj = await this.props.onSave(l);
              // It could fail and return undefined
              if(updatedObj) {
                this.setState({ original: updatedObj, changedObject: _.cloneDeep(updatedObj) });
                this.checkChanges();
              }
            }} className={'btn btn-default'}>Save changes</button>
            :
            null
        }
      </div>
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
