import _ from 'lodash';
import LegoEditor from '../lego/LegoEditor';
import { ImagePreview } from './ImagePreview';
import { IconButton } from '../common/IconButton';
import LegoContextSummary from '../lego/LegoContextSummary';
import SemanticTag from '../semantic/SemanticTag';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import { SearchFilterButton } from '../common/SearchFilterButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import InputMultipleSemantics from '../common/editors/InputMultipleSemantics';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import GridLayoutLeftBar from '../common/layout/GridLayoutLeftBar';
import { ImgIcon } from '../../../../components/common/ImgIcon';
import ImageSourcePanel from './ImageSourcePanel';

const ImageCardRow = ({ image, selection, setSelection, onLegoChange }) => {
  const isSelected = selection.includes(image.id)
  const iconChecked = <ImgIcon material icon={'check_box'}/>;
  const iconUnchecked = <ImgIcon material icon={'check_box_outline_blank'}/>

  const { page } = useContext(LegoAdminPageContext);

  const openLegoEditor = async (e, { _id }) => {
    e.stopPropagation();

    const legoService = page.service('services/legos');
    const lego = await legoService.get(_id);

    page.openModal(<div>
      <LegoEditor lego={lego} onSave={async (changedLego, closeDialog) => {
        try {
          await onLegoChange(changedLego);
          if (closeDialog) {
            page.closeModal();
          }
        } catch (err) {
          console.log(err);
        }
      }} onCancel={() => {
        page.closeModal();
      }}
      />
    </div>);
  }
  const openPdfPreview = (e, imgId) => {
    e.stopPropagation();
    page.openModal(<ImageSourcePanel imageId={imgId}/>, {fullScreen: true})
  }

  const handleSelect = (event, id) => {
    let newSelection = [...selection];

    if (event.shiftKey) {
      //Select range from last selected image
      const i = images.findIndex(r => r.id === id);
      const lastI = images.findIndex(r => r.id === selection.at(-1));
      if(lastI !== -1){
        const [start, end] = _.sortBy([lastI, i]);
        const newElements = _.map(images.slice(start, end + 1), 'id');
        setSelection(_.union(newSelection, newElements));
      }
    } else {
      if (selection.includes(id)) {
        _.pull(newSelection, id);
      } else {
        newSelection.push(id);
      }
      setSelection(newSelection);
    }
  };

  return <tr key={image.source} style={{border: '5px solid #6c757d'}} className={`${isSelected ? 'bg-warning' : 'bg-light'} m-1 p-05`} onClick={(e) => handleSelect(e, image.id)}>
    <td>{isSelected ? iconChecked : iconUnchecked}</td>
    <td>
      {/*<img style={{maxHeight:'70px', margin:'10px'}} src={image.url}/>*/}
      <ImagePreview img100 url={image.url}/>
    </td>
    <td className={'zoom-75 no-wrap'}>
      <div>
        <IconButton icon={'open_in_new'} title={'Open Lego editor'} onClick={(e) => openLegoEditor(e, image.legoDoc)}>{image.lego}</IconButton>
        <IconButton icon={'picture_as_pdf'} title={'View PDF page'} onClick={(e) => openPdfPreview(e, image.id)}/></div>
      <div style={{ display: 'flex', flexWrap:'wrap', maxWidth: '380px'}}>{_.map(image.context, (ctx, i) => <LegoContextSummary key={image.lego + i} context={ctx}/>)}</div>

      <div style={{position: 'relative', left: '-5px'}}>
        {image.semantic ? <SemanticTag level={'primary'} semKey={image.semantic}/> : null}
        {image.semanticVariation ? <span className={'badge badge-info'}>{image.semanticVariation}</span> : null}
      </div>
    </td>
    <td>
      <div className={'small mr-1'}>{image.lightName}</div>
    </td>
  </tr>
}

const ImagesPanel = ({ images, selection, setSelection, onLegoChange }) => {
  const handleTableSelect = (e) => {
    const tableIds = _.map(images, 'id');
    let isUnselecting = _.intersection(selection, tableIds).length === tableIds.length;
    let newSelection
    if (e.ctrlKey){
      newSelection = isUnselecting ? _.difference(selection, tableIds) : _.concat(selection, tableIds);
    } else {
      newSelection = isUnselecting ? [] : tableIds;
    }
    setSelection(newSelection);
  }


  const iconChecked = <ImgIcon material icon={'check_box'}/>;
  const iconUnchecked = <ImgIcon material icon={'check_box_outline_blank'}/>

  const allSelected = selection.length && _.intersection(selection, _.map(images, 'id')).length === images.length

  return <div>
    <table style={{userSelect: 'none', width: '100%'}}>
      <thead>
        <tr className={'bg-secondary'} style={{position: 'sticky', top: '0px', zIndex: '1'}}>
          <td onClick={handleTableSelect} title={'Ctrl key adds/remove to current selection'}>{allSelected ? iconChecked : iconUnchecked}</td>
          <td><strong>Image</strong></td>
          <td><strong>Metadata</strong></td>
          <td><strong>Light name</strong></td>
        </tr>
      </thead>
      <tbody>
      {_.map(images, (img,i) => <ImageCardRow selection={selection} key={i+img.lego} onLegoChange={onLegoChange} setSelection={setSelection} image={img}/>)}
      </tbody>
    </table>
  </div>
}


const FiltersBar = ({ filters, setFilters, images, filteredImages, selection, setSelection }) => {
  let [textFilter, setTextFilter] = useState(filters.text || '')

  useEffect(() => setTextFilter(filters.text), [filters.text])

  const onFilterChange = (filter, val) => {
    let changedFilters = { ...filters };
    if (_.isEmpty(val) && !(val === true || val === false)){
      delete changedFilters[filter]
    } else {
      changedFilters[filter] = val
    }
    setFilters(changedFilters)
  }

  const debouncedOnFilterChange = useCallback(_.debounce(onFilterChange, 300), [filters])

  useEffect(() => {debouncedOnFilterChange( 'text', textFilter )}, [textFilter])

  return <div className={'bg-patito'}>
    <div className={'d-flex align-items-center'}>
      <div className={'p-2 d-inline-flex align-items-center'} style={{position: 'relative'}}>
        <SingleTextEditor className={'pr-4'} value={textFilter} onChange={newVal => setTextFilter(newVal)} placeholder={'Filter results...'}/>
        <span style={{position: 'absolute', right: '5px'}}>{textFilter ? <IconButton icon={'cancel'} onClick={() => setTextFilter()}/> : null}</span>
      </div>

      <div className={'text-black-50 small'} style={{width: '130px'}}>{filteredImages.length} of {(images || []).length} cluster images</div>

      <div className={'flex-grow-1'}>
        <SearchFilterButton onChange={isOn => onFilterChange('unassignedSemantics', isOn)}
                            value={filters.unassignedSemantics}>
          <span className={'badge badge-secondary'}>Unassigned Semantics</span>
        </SearchFilterButton>

        <SearchFilterButton onChange={isOn => onFilterChange('assignedSemantics', isOn)}
                            value={filters.assignedSemantics}>
          <span className={'badge badge-primary'}>Assigned Semantics</span>
        </SearchFilterButton>

        <SearchFilterButton onChange={isOn => onFilterChange('onlySelected', isOn)}
                            value={filters.onlySelected}>
          <span className={'badge badge-info'}>Only selected</span>
        </SearchFilterButton>
      </div>

      {selection.length ? <div className={'text-black-50 small'}>Selected {selection.length} images <IconButton level={'secondary'} icon={'cancel'} onClick={() => setSelection([])}/></div> : null}

      <span style={{ width: '350px', display: 'inline-block' }} className={'zoom-75 ml-1 mr-2'}>
        <ModelContextEditor value={filters.context} onChange={(newContext) => onFilterChange('context', newContext)}/>
      </span>

      <div className={'text-right overflow-hidden'}>

        {/*{ unsavedObjectsCount ? btnSaveChanges : null }*/}

      </div>
    </div>
  </div>
}

const AssignBtn = ({customLabel, className, selection, ...otherProps}) => {
  let text = `${customLabel ? customLabel : 'Assign'} (${selection.length})`;
  let classes = `btn btn-sm btn-primary zoom-75 ${!selection.length ? 'disabled-zone' : ''}`
  return <span className={className}><span className={classes} {...otherProps}>{text}</span></span>
}

const AssignPanel = ({ clusterVariations, inputVariations, selection, inputSemantic, setInputSemantic, setFilters, onAssign }) => {
  const { page } = useContext(LegoAdminPageContext);

  let [newVariation, setNewVariation] = useState()

  const assignBtnHandler = (selection, assignData, requiredFields) => {
    if (requiredFields && !(assignData.semanticVariation && assignData.semantic)){
      alert('Must complete semantic and semantic variation information')
      return
    }
    page.runAsync(onAssign(selection, _.omitBy(assignData, _.isEmpty)))
  }

  const buildVariationsList = (variationList) => {
    let rows = _.map(variationList, item => <tr key={item.semantic + item.semanticVariation}
                                                className={'mt-1 d-flex align-items-center bg-light-patito'}>
      <td style={{ width: '70px' }} className={'position-relative'}>
        {item.sampleImage ?
          <img src={item.sampleImage} style={{ maxHeight: '70px', maxWidth: '70px', marginRight: '5px' }}/> : null}
        <span style={{ bottom: '4px', left: '4px'}} className={'small position-absolute'}>
        {item.state === 'unpublished' ? <span className={'badge badge-danger'}>unpublished</span> : null}
          {item.state === 'inprogress' ? <span className={'badge badge-warning'}>inprogress</span> : null}
      </span>
      </td>
      <td style={{ width: '200px' }}>
        <div className={'d-flex flex-column'}>
          {item.semantic ?
            <span><SemanticTag onClick={() => setFilters((filters) => ({ ...filters, text: item.semantic }))}
                               extraClass={' zoom-75'} semKey={item.semantic} level={'primary'}/></span> : null}
          <span className={'ml-1 mr-05 small'}
                onClick={() => setFilters((filters) => ({ ...filters, text: item.semanticVariation }))}><span
            className={'badge badge-info'}>{item.semanticVariation}</span></span>
          <span className={'ml-1 small'}>({item.count} images in current cluster)</span>
        </div>
      </td>
      <td>
        <AssignBtn className={'m-1 no-wrap'}
                   onClick={() => assignBtnHandler(selection, { semanticVariation: item.semanticVariation, semantic: item.semantic })}
                   selection={selection}/>
      </td>
    </tr>)
    return <table  style={{width:'100%'}}>
      <tbody>
      {rows}
      </tbody>
    </table>
  }


  return <div style={{width:'350px', height: '100%', padding:'5px'}} className={'bg-light-white'}>
    <div className={'mt-2'}>
      Semantic and variations used in this cluster:
      {buildVariationsList(clusterVariations)}
      <div className={'mt-2'}>Use new Semantic and variation:</div>
      <div className={'d-flex align-items-center mt-2'}>
        <div style={{flexGrow: '1'}}>
          <InputMultipleSemantics className={'zoom-75 flex-fill'} value={inputSemantic} onChange={sem => setInputSemantic(sem)}/>
          <SingleTextEditor small placeholder={'Type new semantic variation...'} value={newVariation} onChange={variation => setNewVariation(variation)}/>
        </div>
        <span className={'ml-1'} style={{maxWidth: '80px'}}><AssignBtn customLabel={'New semantic + variation'} onClick={() => assignBtnHandler(selection, {semantic: inputSemantic?.[0], semanticVariation: newVariation}, true)} selection={selection}/></span>
      </div>
      {buildVariationsList(inputVariations)}
    </div>
  </div>
};

export default function ClusterSemanticsAssign ({clusterId, modalActionsBus}) {
  const [images, setImages] = useState([]);
  const [clusterVariations, setClusterVariations] = useState([]);

  const [filters, setFilters] = useState({unassignedSemantics: true});
  const [selection, setSelection] = useState([])
  const [inputSemantic, setInputSemantic] = useState(null)
  const [inputVariations, setInputVariations] = useState([])

  const legoService = window.client.service('services/legos');
  const clusterSvc = window.client.service('services/data/image-clusters');
  const { page } = useContext(LegoAdminPageContext);

  const getImages = async () => {
    console.time('Getting data took');
    const cluster = await clusterSvc.get(clusterId);
    const clusterIds = _.map(cluster.images, img => img.id.toString());

    const wlLegos = await legoService.find({ query: {type: 'data', intentions:'warning-lights-incomplete', 'data.warning-lights.lightImg.source': {$in:_.map(cluster.images,'id')}}});

    const clusterImages = _.compact(_.flatMap(wlLegos, lego => _.map(lego.data['warning-lights'], (wl, index) => {
      if (clusterIds.includes(wl.lightImg?.source)){
        return {
          id: wl.lightImg.source,
          url: wl.lightImg.url,
          lightName: wl.lightName,
          semantic: wl.semantic,
          semanticVariation: wl.semanticVariation,
          context: lego.context,
          lego: lego._id,
          index: index,
          legoDoc: lego
        }
      }
    })));

    console.timeEnd('Getting data took');
    console.log(`Images in cluster ${cluster._id}: `, cluster.images.length)
    console.log('Images matched with extraction legos: ', clusterImages.length)
    setImages(clusterImages);
  }

  const getSemanticsAndVariations = async () => {
    if (!images.length) {
      return
    }
    let allVariations = _.map(images, img => {
      let obj = { semantic: img.semantic, semanticVariation: img.semanticVariation };
      if (obj.semantic || obj.semanticVariation) {
        return obj;
      }
    });
    const variations = _.groupBy(_.compact(allVariations), item => `${item.semantic}-${item.semanticVariation}`);

    const variationImagesRequest = await legoService.find({ query: { type: 'image', 'data.semanticVariation': { $in: _.map(variations, g => g[0].semanticVariation) } } })

    let variationSamples = _.map(variations, group => {
      const lego = _.find(variationImagesRequest, doc =>
        doc.data.semanticVariation === group[0].semanticVariation &&
        doc.semantics.includes(group[0].semantic)
      );
      return {
        semantic: group[0].semantic,
        semanticVariation: group[0].semanticVariation,
        sampleImage: lego?.data.url,
        state: lego?.state,
        count: group.length
      }
    })
    setClusterVariations(variationSamples);
  }

  const getLegosForInputSemantic = async () => {
    let variations = [];
    if (!_.isEmpty(inputSemantic)){
      const semanticsImageRequest = await legoService.find({query:{type: 'image', intentions: 'glossary', 'semantics': inputSemantic}})
      const groupedByVariation = _.groupBy(semanticsImageRequest, 'data.semanticVariation');
      _.forEach(groupedByVariation, group => {
        const [img] = group;
        if (!_.find(clusterVariations, sample => sample.semantic === img.semantics[0] && sample.semanticVariation === img.data.semanticVariation)) {
          variations.push({
            semantic: img.semantics[0],
            semanticVariation: img.data.semanticVariation,
            sampleImage: img.data.url,
            state: img.state,
            count: 0
          });
        }
      })
    }

    setInputVariations(variations);
  }

  useAsyncEffect(getImages, [clusterId])

  useAsyncEffect(getSemanticsAndVariations, [images])

  useAsyncEffect(getLegosForInputSemantic, [inputSemantic])

  const createImgLego = async(image, assignData) => {
    if (assignData.semantic && assignData.semanticVariation){

      const currentLego = await legoService.find({query: {
        type:'image',
          intentions: 'glossary',
          'data.semanticVariation': assignData.semanticVariation,
          semantics: assignData.semantic
        }});

      if (currentLego.length){
        return
      }

      const res = await legoService.create({
        type: 'image',
        intentions: ['glossary'],
        state: 'inprogress',
        data: {
          url: image.url,
          semanticVariation: assignData.semanticVariation,
        },
        semantics: [assignData.semantic],
        labels: ['wlights:to_draw'],
        createdBy: page.getLoggedUserSignature()
      })

      console.log('Created lego:', res)
    }
  }

  const onAssign = async (ids, assignData) => {
    let promises = [];
    for (const [index, id] of ids.entries()){
      let image = _.find(images, image => image.id === id)

      let set = _.mapKeys(assignData, (value, key) => `data.warning-lights.${image.index}.${key}`)
      set.updatedBy = page.getLoggedUserSignature()
      promises.push(await legoService.update(image.lego, {$set: set }))

      if (index === 0){
        await createImgLego(image, assignData);
      }
    }

    let res = await Promise.all(promises)

    if (res.length){
      let changedImages = [...images];
      for (const doc of res){
        let image = _.find(changedImages, image => image.lego === doc._id.toString());
        for (const field of _.keys(assignData)){
          image[field] = doc.data['warning-lights'][image.index][field]
          image.legoDoc = doc;
        }
      }
      setImages(changedImages);
    }
  }

  const onLegoChange = async (changedLego) => {
    changedLego.updatedBy = page.getLoggedUserSignature();
    await legoService.update(changedLego._id, changedLego);
    await getImages();
  }


  let filteredImages = []

  if(filters.unassignedSemantics){
    filteredImages = _.concat(filteredImages, _.filter(images, img => !(img.semantic && img.semanticVariation)))
  }

  if(filters.assignedSemantics){
    filteredImages = _.concat(filteredImages, _.filter(images, img => img.semantic && img.semanticVariation))
  }

  if(filters.onlySelected){
    filteredImages = _.filter(images, img => selection.includes(img.id));
  }

  if(filters.text){
    try {
      const regex = new RegExp(filters.text, 'i');
      filteredImages = _.filter(filteredImages, img => JSON.stringify(_.pick(img, ['id', 'lego', 'url', 'lightName', 'semantic', 'semanticVariation'])).match(regex));
    } catch (err) {
      const regex = new RegExp(_.escapeRegExp(filters.text), 'i');
      filteredImages = _.filter(filteredImages, img => JSON.stringify(_.pick(img, ['id', 'lego', 'url', 'lightName', 'semantic', 'semanticVariation'])).match(regex));
    }
  }

  if(filters.context){
    //implemented filtering by make, modelId and year
    if(filters.context.make) {
      filteredImages = _.filter(filteredImages, img => _.some(img.context, ctx => ctx.modelId.match(`^${filters.context.make}-`)));
    } else {
      filteredImages = _.filter(filteredImages, img => _.some(img.context, ctx => ctx.modelId = filters.context.modelId));
    }

    if(filters.context.year){
      filteredImages = _.filter(filteredImages, img => _.some(img.context, ctx => ctx.year = filters.context.year));
    }
  }

  return <div className={'d-flex flex-column'} style={{height: '100%'}}>
    <FiltersBar filters={filters} setFilters={setFilters} images={images} filteredImages={filteredImages} selection={selection} setSelection={setSelection}/>
    <div className={'flex-grow-1'} style={{position: 'relative', width: '100%', height: '100%'}}>
    <GridLayoutLeftBar>
        <AssignPanel clusterVariations={clusterVariations} inputVariations={inputVariations} selection={selection} inputSemantic={inputSemantic} setInputSemantic={setInputSemantic} setFilters={setFilters} onAssign={onAssign}/>
        {images.length ? <ImagesPanel images={filteredImages} selection={selection} setSelection={setSelection} onLegoChange={onLegoChange} modalActionsBus={modalActionsBus}/> : 'Fetching data'}
      </GridLayoutLeftBar>
    </div>
  </div>
}
