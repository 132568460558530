import MultipleContextsEditor from "../../lego/MultipleContextsEditor";
import React from "react";
import ManualFeaturesEditor from "./ManualFeaturesEditor";
import SingleTextEditor from "../../common/editors/SingleTextEditor";
import {SwitchInput} from "../../common/SwitchInput";

export default class ManualFeaturesDateEditor extends ManualFeaturesEditor {
  getFeaturesEditionRows({ features }) {
    let { printedDate, printedModelYear } = features || {}

    return [
      <tr key={'printedDate'}>
        <td className={'text-right'}>
          Printed date:
        </td>

        <td className={''}>
          <SwitchInput value={printedDate === false} onChange={(json) => this.editField('features.printedDate', false)}>
            Not found
          </SwitchInput>

          <SingleTextEditor autoFocus={true} id={'main-extraction-input'} value={printedDate && printedDate.year} placeholder={"Year (YYYY)"}
                            onChange={(json) => this.editField('features.printedDate.year', json || undefined)}/>

          <SingleTextEditor value={printedDate && printedDate.month} placeholder={"Month number (OPTIONAL)"}
                            onChange={(json) => this.editField('features.printedDate.month', json || undefined)}/>
        </td>
      </tr>,

      <tr key={'date'}>
        <td className={'text-right'}>
          Model year:
        </td>

        <td className={''}>
          <SingleTextEditor value={printedModelYear} placeholder={'Model year (OPTIONAL)'}
                            onChange={(json) => this.editField('features.printedModelYear', json || undefined)}/>
        </td>
      </tr>
    ]
  }
}
