import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import SemanticTag from '../components/semantic/SemanticTag';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import LegoEditor from '../components/lego/LegoEditor';
import { TypeTag } from '../components/lego/TypeTag';
import { IntentionTag } from '../components/lego/IntentionTag';
import { LegoPreview } from '../components/lego/LegoPreview';
import ObjectSearchHandler from '../components/common/ObjectSearchHandler';
import { LegoFiltersBar } from '../components/common/LegoFiltersBar';
import CRUDPage from './CRUDPage';
import submenu from './menus/submenu-legos';
import { LegoStateBadge } from '../components/lego/LegoStateBadge';
import LegoLabel from '../components/lego/LegoLabel';
import { IconButton } from '../components/common/IconButton';
import ModalBatchEditLegos from '../components/lego/ModalBatchEditLegos';
import { LocalesList } from '../components/lego/LocalesList';

dayjs.extend(relativeTime)

export default class LegosManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/legos');

    this.newObjectTemplate = {
      locales: [window.config.locale],
      state: 'inprogress',
      semantics: [],
      data: null
    }

    this.submenu = submenu;
    // this.registerRemoteCommands({ updateTestsetResults: (newResults) => {  }})
  }

  getSearchHandler() {
    const legoSearchFilters = {
      type: {
        filter: type => (l => l.type && (!type || l.type.startsWith(type))),
        description: 'With type [=<prefix>...]'
      },
      label: {
        filter: labelFilter => (l => (l.labels?.length && (!labelFilter || _.some(l.labels, x => x.includes(labelFilter.toLowerCase()))))),
        description: 'With labels [=<prefix>...]'
      },
      source: {
        filter: substr => (l => l.source && (!substr || JSON.stringify(l.source).includes(substr))),
        description: 'Legos with source [=<substring>...]'
      },
      int: {
        filter: intention => (l => !intention || _.some(l.intentions, int => int.indexOf(intention.toLowerCase()) >= 0)),
        description: 'With intention [=<substring>...]'
      },
      sem: {
        filter: sem => (l => sem && _.some(l.semantics, s => s.toLowerCase().match(sem.toLowerCase()))),
        description: 'With semantic [=<regex>...]'
      },
      s: {
        filter: txt => (l => l.state && (!txt || l.state.startsWith(txt))),
        description: 'With state [=<prefix>...]'
      },
      context_multi: {
        filter: n => (l => l.context && (l.context.length >= (n || 2))),
        description: 'With more than 1 context [or at least <n> contexts...]'
      },
      user: {
        filter: txt => (l => l.updatedBy && (!txt || JSON.stringify(l.updatedBy).match(_.escapeRegExp(txt)))),
        description: 'Updated by [=<userId or username>...]'
      },
      fusebox_withFuses: {
        filter: () => (l => l.data.length && _.every(l.data, box => box.fuses?.length)),
        description: 'Fuseboxes with boxes and all with at least one fuse'
      },
      fusebox_withTableImgs: {
        filter: () => (l => l.data.length && _.every(l.data, box => box.boxTablesImages?.length)),
        description: 'Fuseboxes with boxes and all with at least one fuse'
      },
      fusebox_withDiagramImgs: {
        filter: () => (l => l.data.length && _.every(l.data, box => box.boxDiagramImg)),
        description: 'Fuseboxes with boxes and all with at least one fuse'
      },
      fusebox_hidden: {
        filter: () => (l => l.data.length && _.some(l.data, box => box.hidden)),
        description: 'Fuseboxes with one or more hidden box'
      },
      fusebox_withTemplate: {
        filter: () => (l => l.data.length && _.every(l.data, box => box.templateId)),
        description: 'Fuseboxes with boxes and all with at least one fuse'
      },
      fusebox_withNBoxes: {
        filter: n => (l => l.data.length === parseInt(n || '0')),
        description: '=n Fusebox lego with exactly n fuseboxes'
      }
    };

    const nodeTextSearchCustomizer = text => text.replace(/\s+/g, '[\\w_\\s]*');

    const getSorter = (searchText, textFilter) => {
      // Sort again to get the order: Equal match, Key regex match, other regex match
      const sortMatchesSemantics = l => !_.some(l.semantics, s => s.toUpperCase()
        .startsWith(searchText.toUpperCase()));
      const sortMatchesSemanticsPartial = l => !_.some(l.semantics, s => textFilter(s));

      const sortByDate = l => -new Date(l.updatedAt).valueOf();

      const sortByNew = l => l.__new;

      return [sortByNew, sortMatchesSemantics, sortMatchesSemanticsPartial, sortByDate];
    };

    return new ObjectSearchHandler(legoSearchFilters, nodeTextSearchCustomizer, getSorter);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  buildObjectQuery() {
    // Override CRUD buildObjectQuery to do a special transformations when models are filtered by make
    // If context.make present, ALSO include legos with a modelId that start with that make
    let originalQuery = super.buildObjectQuery();

    if(originalQuery['context.make']) {
      let {$limit, $select, ... query} = originalQuery;
      let restOfQuery = _.omit(query, 'context.make', '$select', '$limit');
      restOfQuery['context.modelId'] = {$regex: `^${query['context.make']}-`}
      originalQuery = { $or: [query, restOfQuery], $select, $limit };
    }
    return originalQuery;
  }

  async openBatchEditContexts(legos) {
    this.openModal(<ModalBatchEditLegos docs={legos} onCancel={() => this.closeModal()}/>, {title: 'Edit legos in batch'});
  }

  async openEdit(lego) {
    let freshLego = lego;
    if(lego._id) {
      // Ensure that the latest version of the lego is used
      freshLego = await this.refreshObject(lego);
      this.setUrlParams({ editing: lego._id })
    }

    let legoEditoRef = React.createRef();

    this.openModal(<div>
      <LegoEditor ref={legoEditoRef} lego={freshLego} onSave={async (changedLego, closeDialog) => {
        try {
          let res = await this.updateOrCreate(lego, changedLego);
          if(closeDialog) {
            this.closeModal()
          } else if(lego._id) {
            return await this.refreshObject(lego);
          }
          return res;
        } catch (err) {
          this.handleError(err);
        }
      }} onCancel={() => {
        return this.modalActionsBus.emit('close');
      }}/>
    </div>, {
      title: lego?._id ? 'Edit lego' : 'Create lego',
      onClose: () => this.deleteUrlParam('editing'),
      closeConfirmationCbk: () => {
        if(legoEditoRef.current && legoEditoRef.current.state.changed) {
          return confirm('Are you sure you wanna close it? You will lose all your changes!');
        } else {
          return true;
        }
      }});
  }

  getColumnsDefinition(objects) {
    return [
      {content: 'Type', className: ''},
      {content: 'Semantics / Intention', className: 'sem-column', sorter: l => l.semantics?.[0]},
      ... this.getRankColumnHeaders(),
      {content: 'Locales', className: 'sem-column', sorter: l => l.locales?.[0]},
      {content: 'Context', className: 'context-column', sorter: (l) => _.min(_.map(l.context, 'year'))},
      {content: 'Data', className: ''},
    ]
  }

  getFacetFiltersComponent() {
    return <LegoFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  getGrouppingObjectColumn(columnHeaderData) {
    return <LegoContextSummary context={JSON.parse(columnHeaderData)}/>
  }

  getGrouppingObjectRowHeader(obj) {
    let { _id, type, intentions, semantics, context, data, updatedAt, state } = obj;
    return <div className={'text-nowrap'}>
      <TypeTag key={type} type={type}/>&nbsp;
      {_.map(intentions, int => <IntentionTag key={int} intention={int}/>)}
      {_.map(semantics, s => <SemanticTag level={'secondary'} key={s} semKey={s}/>)}
    </div>
  }

  getObjectCell(l) {
    return <LegoPreview lego={l} textFilter={this.state.textFilter}/>;
  }

  getSelectionButtons(selection) {
    if(selection?.length) {
      return <IconButton icon={'app_registration'} title={'Edit legos in batch'} onClick={() => this.openBatchEditContexts(selection)}></IconButton>
    }
  }

  groupObjectsInRows(objects) {
    let contextToKey = context => {
      // Destructure to make a more stable json.stringify grouping key
      let { make, model, year, ...other } = context || {};
      return JSON.stringify({ make, model, year, ...other });
    };

    let columns = _.uniq(_.flatten(_.map(objects, o => {
      if (_.isArray(o.context)) {
        return _.map(o.context, contextToKey);
      } else {
        return contextToKey(o.context);
      }
    }))).sort().reverse();

    let columnsIndexes = {};

    for (let i = 0; i < columns.length; i++) {
      columnsIndexes[columns[i]] = i;
    }

    let rows = _.groupBy(objects, o => JSON.stringify([o.semantics, o.type, o.integrity]));

    let matrix = _.mapValues(rows, objs => {
      let values = _.map(columns, () => null);
      _.each(objs, (o) => {
        let contexts = _.isArray(o.context) ? o.context : [o.context];
        _.each(contexts, (ctx,i) => values[columnsIndexes[contextToKey(ctx)]] = {obj: o, isReference: i > 0});
      });
      return values;
    });

    return { columns, rows: matrix}
  }

  getObjectColumns(l, definition) {
    let { _id, type, intentions, semantics, locales, context,generatedContext, labels, data, updatedAt, state } = l;

    let isPublished = !state || state === 'published';
    let publishedClass = isPublished ? '' : (state === 'inprogress' ? ' bg-highlight' : ' bg-light-secondary translucent');

    if(labels?.includes('readonly')) {
      publishedClass = ' bg-light-violeta';
    }

    return [
      <td key={'type'}  className={publishedClass}>
        <div>
          <TypeTag key={type} type={type}/>
          <span className={'text-info small ml-2'}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
        </div>
        <div className={'text-secondary small'}>{_id}</div>
        { state && state !== 'published' ? <LegoStateBadge state={state}/> : null }
        { labels?.length ? _.map(labels, l => <LegoLabel label={l} key={l} className={'ml-1'}/>) : null }
      </td>,

      <td  key={'sem'} className={'text-center sem-column'+publishedClass}>
        <div>{_.map(semantics, s => <SemanticTag level={'secondary'} key={s} semKey={s}/>)}</div>
        <div>{_.map(intentions, int => <IntentionTag key={int} intention={int}/>)}</div>
      </td>,

      ...this.getRankColumns(l),

      <td key={'locales'} className={'text-center ' + publishedClass}>
        <LocalesList locales={locales}/>
      </td>,

      <td key={'ctx'} className={'context-column'+publishedClass}>
        {!generatedContext?.length || context?.length ? <LegoContextSummary context={context}/> : null }
        {
          generatedContext?.length ?
          <span className={'badge badge-warning'} title={'Generated contexts'}>
          💻 {generatedContext.length} <LegoContextSummary context={generatedContext} inline className={'text-ellipsis d-inline-block align-middle'} style={{maxWidth: '200px'}}/>
          </span>
          : null
        }
      </td>,

      <td key={'pre'} className={'data-column'+publishedClass} onClick={() => this.openEdit(l)}><LegoPreview lego={l} textFilter={this.state.textFilter}/></td>
    ];
  }
}
