import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CRUDPage from './CRUDPage';
import submenuLegos from './menus/submenu-legos';
import { ImagePreview } from '../components/images/ImagePreview';
import { Icon } from '../components/common/Icon';
import { VehicleSummary } from '../components/common/VehicleSummary';
import MongoDocEditor from '../components/lego/MongoDocEditor';
import { UserSummary } from '../components/common/UserSummary';
import { StateBadge } from '../components/data-issues/StateBadge';
import BadgeId from '../components/common/BadgeId';
import NiceDate from '../components/common/NiceDate';
import { AwaitingIcon } from '../components/data-issues/AwaitingIcon';
import { DataTypeTag } from '../components/dataprocess/DataTypeTag';
import _ from 'lodash';
import { SwitchInput } from '../components/common/SwitchInput';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import MultiButtonSwitch from '../components/common/editors/MultiButtonSwitch';
import ContributionStateSwitch from '../components/data-issues/ContributionStateSwitch';
import { IconButton } from '../components/common/IconButton';
import ParseTirePressureModal from '../components/lego/tire-pressure/ParseTirePressureModal';

dayjs.extend(relativeTime);

function ContributionsFiltersBar({filter, onChange}) {
  const onChangeHandler = (field, value) => {
    let newFilter = _.cloneDeep(filter || {});
    if (_.isEmpty(value)) {
      delete newFilter[field];
    } else {
      newFilter[field] = _.cloneDeep(value);
    }
    onChange(newFilter);
  };

  let { vehicleContext, contributionType, state, isCasual, awaiting } = filter || {};

  const toggleCasual = v => onChangeHandler('isCasual', isCasual ? undefined : (isCasual === false ? true : false));

  return <div className={'d-flex align-items-center'}>
    <span className={'zoom-75 ml-1'}>
        <ContributionStateSwitch value={state} onChange={v => onChangeHandler('state', v)}/>
    </span>

    <span style={{width: '300px', display: 'inline-block'}} className={'zoom-75 ml-3'}>
      <ModelContextEditor value={vehicleContext} onChange={(newContext)=> onChangeHandler('vehicleContext', newContext, true)}/>
    </span>

    <span className={'zoom-90 ml-1'}>
        <MultiButtonSwitch value={contributionType} options={['fuseboxImg', 'tirePressureImg', 'carProfileImg', 'manual']} multi
                           onChange={v => onChangeHandler('contributionType', v)}/>
    </span>


    <SwitchInput value={isCasual === undefined? undefined : !isCasual} onChange={toggleCasual}  className={'ml-3'}>
      Registered users
    </SwitchInput>
  </div>;
}


export default class ContributionsManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/contributions');
    this.submenu = submenuLegos;
    this.state.summaryOpen = false;

    this.canDelete = false;
    this.canCreate = false;
  }

  getFacetFiltersComponent() {
    return <ContributionsFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    if(this.state.facetFilters.make || this.state.facetFilters.idModelo) {
      await super.fetchObjects({ $sort: { createdAt: -1 }, $limit: 5000});
    } else {
      await super.fetchObjects({ $sort: { createdAt: -1}, $limit: 100 });
    }
  }

  buildObjectQuery() {
    // Override CRUD buildObjectQuery to do a special transformations when models are filtered by make
    // If context.make present, ALSO include legos with a modelId that start with that make
    let originalQuery = super.buildObjectQuery();

    if(originalQuery['vehicleContext.make']) {
      let {$limit, $select, ... query} = originalQuery;
      let restOfQuery = _.omit(query, 'vehicleContext.make', '$select', '$limit');
      restOfQuery['vehicleContext.modelId'] = {$regex: `^${query['vehicleContext.make']}-`}
      originalQuery = { $or: [query, restOfQuery], $select, $limit };
    }
    return originalQuery;
  }

  getColumnsDefinition(objects) {
    return [
      { content: '', className: '' },
      { content: 'Feedback type', className: '' },
      { content: 'Type', className: 'text-center' },
      { content: 'User', className: '' },
      { content: 'Feedback', className: '' },
      { content: 'Context', className: '', sorter: (l) => l.vehicleContext?.year},
    ];
  }

  // getSelectionButtons() {
  //   let parseBtn = null;
  //   let selection = this.state.selection || [];
  //   if(selection.length === 1 && selection[0].contributionType === 'tirePressureImg') {
  //     parseBtn = <IconButton icon={'photo_filter'} title="Parse tire pressure label with AI" onClick={() => this.openParseTirePressureModal(selection[0])}/>
  //   }
  //
  //   return <>
  //     { super.getSelectionButtons(selection) }
  //     { parseBtn }
  //   </>
  // }


  async openParseTirePressureModal(contribution) {
    this.openModal(<ParseTirePressureModal contribution={contribution} onClose={() => this.closeModal()}/>, {title: 'Parse tire pressure label with AI'});
  }


  getObjectColumns(contribution, definition) {
    let {
      _id, updatedAt, userId, isCasual, migratedFromCasual, vehicleContext, state, awaiting, dataType, contributionType,
      points, data, deleted
    } = contribution;

    const imgUrl = data.images?.[0]?.url;

    const awaitingClass = awaiting ? 'bg-light-primary' : 'bg-light'

    return [
      <td className={awaitingClass}>
        <AwaitingIcon awaiting={awaiting}/>
      </td>,

      <td key={'feedbackType'} className={'context-column text-left '+awaitingClass}>
        <div>
          <StateBadge state={state}/>
          <span className={'ml-2'}>{updatedAt ? <NiceDate t={updatedAt}/> : ''}</span>
        </div>
        <BadgeId id={_id}/>
        <div>
        </div>
      </td>,

      <td key={'type'} className={'context-column text-left '}>
        <DataTypeTag type={dataType}/>
        <div className={'text-secondary'}>{contributionType}</div>
      </td>,

      <td key={'user'}>
        <div className={'p-1 rounded '+(isCasual ? 'bg-light' : 'bg-light-success')}>
          <UserSummary userId={userId}/>
          {
            migratedFromCasual ?<span className={'ml-1'}>
              <Icon size={'sm'} icon={'how_to_reg'} level={'success'} title={'Registered after feedback'}/>
            </span> : null}
        </div>
      </td>,

      <td key={'feedback'} style={{ width: '100px' }} className={'text-center p-0'}>
        {imgUrl ? <ImagePreview zoom={true} url={imgUrl}/> : null}
      </td>,

      <td key={'context'}>
        <VehicleSummary vehicle={vehicleContext}/>

        { contributionType === 'tirePressureImg'
          ?
          <IconButton icon={'photo_filter'} title="Parse tire pressure label with AI"
                      onClick={() => this.openParseTirePressureModal(contribution)}/>
          : null
        }

        <div className={' small monospace mt-1'}>
        </div>
      </td>,

    ];
  }

  async getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <MongoDocEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}
