import React, { Component, useContext } from 'react';
import _ from 'lodash';

import Async from 'react-select/lib/Async';
import CountryFlag from '../common/CountryFlag';
import { Icon } from '../common/Icon';
import useAsyncData from '../../../../components/common/effects/useAsyncData';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SingleManualViewer from '../../pages/SingleManualViewer';
import { IconButton } from '../common/IconButton';

let MANUALS = null;

const typeIcons = {
  internet: 'language',
  other: 'description',
  'data-feedback': 'feedback',
  'user-email': 'email',
}

const SOURCES = [
  { str: 'Internet source photo', label: <span>Internet source</span>, value: { type: 'internet', url: '' } },
  { str: 'Data feedback photo', label: <span>User data feedback</span>, value: { type: 'data-feedback' } },
  { str: 'User email photo', label: <span>User email</span>, value: { type: 'user-email'} },
  { str: 'Elsawin VW diagram', label: <span>ELSAWin diagram</span>, value: { type: 'external-diagram', id: 'elsawin' } },
  { str: 'Vivid workshopdata 10.2 diagram', label: <span>Vivid WorkshopData 10.2 diagram</span>, value: { type: 'external-diagram', id: 'vivid_10.2' } },
  { str: 'Identifix service manual', label: <span>Identifix service manual</span>, value: { type: 'external-diagram', id: 'identifix' } },
  { str: 'Other source', label: <span>Other source</span>, value: { type: 'other' } }
];


function SourceTag({ source, label }) {
  const {page} = useContext(LegoAdminPageContext);


  if (source) {
    let { type, url, obj, id, ...other } = source;
    const openManual = () => page.openModal(<SingleManualViewer manualId={id}/>, { title: `Manual ${id}`,  noPadding: true, fullScreen: true});

    let otherInfo = _.isEmpty(other) ? '' : JSON.stringify(other);

    let typeBadge = <span className={'badge badge-secondary'}>{type}</span>;

    if (type === 'owner-manual' && obj) {
      if (obj.isManual) {
        typeBadge = <Icon icon={'menu_book'} level={'secondary'}/>;
      } else {
        typeBadge = <Icon icon={'construction'} level={'secondary'}/>;
      }
    } else {
      typeBadge = <Icon icon={typeIcons[type] || 'schema'} level={'secondary'}/>;
    }

    let extraBg = '';
    if (obj && obj.olderVersionOf) {
      extraBg += ' bg-light-warning';
    }

    return <span className={`small ${extraBg}`}>
      {type === 'owner-manual' ? <a href={`/manuals/edit?editing="${id}"`} target={'_blank'} className={'small mr-1'}
               onClick={e => e.stopPropagation()}>
        ...{id.slice(-6)}
      </a> : null}

      {typeBadge} <span>{label || otherInfo}</span>

      {obj ? <a href={obj.manualPdfUrl} target={'_blank'} className={'ml-2 small'} onClick={e => e.stopPropagation()}>
        <i className={`zoom-75 material-icons align-middle mr-1`}>open_in_new</i>
        PDF
      </a> : null}

      { type === 'owner-manual' && id && <IconButton icon={'search'} className={'ml-2 zoom-75'} onClick={openManual}/> }
    </span>;
  } else {
    return null;
  }
}
export default function ManualSourceSelector({ onChange, value, ...props }) {
  const fetchCompressedManuals = async () => {
    let {manuals} = await client.service('/services/data/manuals/list').find({ query: { $compressed: true } }).
                         catch(console.error);

    return _.map(manuals, ([_id, isManual, idModelo, year, trims, manualPdfUrl, isoLanguage, olderVersionOf, country]) => {
      let m = {
        _id, isManual, idModelo, year, trims,
        manualPdfUrl: manualPdfUrl.startsWith('http') ? manualPdfUrl : 'https://opinautos-manuals.s3.amazonaws.com/published/' + manualPdfUrl,
        isoLanguage, olderVersionOf, country
      };

      return {
        str: `${idModelo} ${trims || ''} ${year} ${isoLanguage} ${country.join(',')} ${_id}`,
        label: _id,
        value: { obj: m, type: 'owner-manual', id: _id }
      };
    });
  };

  const MANUALS = useAsyncData('manualsList', fetchCompressedManuals, []);

  const handleSelectChange = ({ value }, actionMeta) => {
    if (value) {
      let { obj, ...other } = value;
      onChange(other);
    } else {
      onChange(null);
    }
  };

  const getManualLabel = (m) => {
    let { _id, year, idModelo, trims, country, isoLanguage } = m;

    const isoLang = <span className={'badge badge-info'}>{isoLanguage}</span>;
    const flags = country.map(c => <CountryFlag countryCode={c} key={c}/>);

    const trim = trims ? <span className={'text-secondary'}>{trims}</span> : null;
    return <span>{flags} {idModelo} {trim} {year} {isoLang}</span>;
  }

  const getSingleValue = ({ data }) => {
    if (!_.isEmpty(data)) {
      const label = data.value?.obj ? getManualLabel(data.value?.obj) : data.label;
      return <div>
        <SourceTag source={data.value || data} label={label}/>
      </div>;
    } else {
      return null;
    }
  };

  const getOption = ({ innerProps, isSelected, isFocused, value, label }) => {
    let { innerRef, ...filteredProps } = innerProps;

    const finalLabel = value?.obj ? getManualLabel(value?.obj) : label;
    return <div {...filteredProps} className={'p-1 ' + ((isSelected || isFocused) ? 'bg-light-primary' : '')}>
      <SourceTag source={value} label={finalLabel}/>
    </div>;
  };

  let options = [... SOURCES, ... MANUALS];

  let matching = (value && _.find(options, (op) => value.type === "owner-manual" ? (value.id === op.value.id) : (value.type === op.value.type))) || value;

  let loadOptions = async (input) => {
    let regex = /.*/;
    try {
      regex = new RegExp(input || '.*', 'i');
    } catch (e) {
    }

    let results = _.filter(options, ({ str }) => str.match(regex)).slice(0, 50);
    // Change spaces ' ' for '.*'
    if(!results.length) {
      regex = new RegExp(_.escapeRegExp(input).replaceAll(' ', '.*'), 'i');
      results = _.filter(options, ({ str }) => str.match(regex)).slice(0, 50);
    }

    return results;
  };

  return <>
    <Async
      closeOnSelect={true}
      components={{ Option: getOption, SingleValue: getSingleValue }}
      onChange={handleSelectChange}
      loadOptions={loadOptions}
      placeholder="An owner's manual..."
      menuShouldScrollIntoView={false}
      maxMenuHeight={170}
      styles={{ menu: (provided) => ({ ...provided, zIndex: 1000 }) }} // Needed in lego editor
      // menuPositionunion={'fixed'}
      defaultOptions={options.slice(0, 40)}
      value={matching}
      {...props}
    />

    {value?.url !== undefined ? <SingleTextEditor label={'Source url'} small value={value?.url} onChange={(text) => onChange({... value, url: text})}/> : null}
    {value?.type === 'other' ? <SingleTextEditor label={'Other description'} small value={value?.description} onChange={(text) => onChange({... value, description: text})}/> : null}
    {value?.type === 'data-feedback' ? <SingleTextEditor label={'Data feedback _id'} small value={value?.id} onChange={(text) => onChange({... value, id: text})}/> : null}
    {value?.type === 'user-email' ? <SingleTextEditor label={'User email addresss'} small value={value?.email} onChange={(text) => onChange({... value, email: text})}/> : null}
  </>;
}
