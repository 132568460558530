import React from 'react'

const colors = {
  new: 'primary',
  unclear: 'dark',
  rejected: 'danger',
  open: 'warning',
  wontfix: 'secondary',
  solved: 'success',
  inprogress: 'warning',

  blocked: 'dark',
  hard: 'primary',

  published: 'success',
  unpublished: 'danger',
  
  approved: 'success',
  pending: 'primary',
  duplicated: 'warning',
}

export function StateBadge({ state, className, ...other }) {
  let styleClass = colors[state] || 'secondary';

  return <span className={`align-middle badge badge-${styleClass} ${className || ''}`} {...other}>{state}</span>;
}

export let StateColors = colors;
