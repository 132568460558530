import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import { ImagePreview } from './ImagePreview';
import CountryFlag from '../common/CountryFlag';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { IconButton } from '../common/IconButton';
import ImageClusterGallery from './ImageClusterGallery';


function vehicleToString(vehicle) {
  return _.values(_.omit(vehicle, 'trimId')).map(v => _.isObject(v) ? _.values(v).join(' ') : v).join(' ');
}

export default function ImageClusterPreview({ clusterId, maxImages, filterByYear, preloadedCluster, sortByYear }) {
  const maxShown = maxImages || 10;
  const { page } = useContext(LegoAdminPageContext);
  const clusterSvc = page.service('services/data/image-clusters');
  const [cluster, setCluster] = useState(preloadedCluster || null);

  const openExtendedCluster = (e) => {
    e.stopPropagation();
    page.openModal(<div>
      <ImageClusterGallery clusterId={clusterId} filterByYear={filterByYear}/>
    </div>, { title: `Cluster ${clusterId} - ${vehicleToString(cluster.context)}`, fullScreen: true });
  };

  const fetchClusters = () => {
    !preloadedCluster && page.runAsync(clusterSvc.get(clusterId).then(cluster => setCluster(cluster || false)));
  };

  useEffect(fetchClusters, [clusterId]);

  if (cluster) {
    let { images } = cluster;
    let shownCount = 0;
    let filteredCount = images.length;
    if (_.isNumber(filterByYear)) {
      images = _.filter(images, i => i.vehicle.year === filterByYear);
      filteredCount = images.length;
    }

    if (sortByYear) {
      images = _.orderBy(images, 'vehicle.year');
    }
    images = images.slice(0, maxShown);

    return <span className={`border bg-light rounded p-1 inline-block align-middle`}>

    <span style={{ minHeight: '72px' }} className={`d-inline-flex align-items-top`}>

      {_.map(images, ({ id, url, vehicle },j) => {
        const vehicleString = vehicleToString(vehicle);

        shownCount++;
        return <span className={`inline-block`} key={id+'-'+j} title={vehicleString}>
          <ImagePreview img50 cover key={id} url={url || ''} className={'mr-1 bg-light'} zoom context={vehicle}/>

          <div className={'small zoom-90'}>
            <CountryFlag countryCode={(vehicle.countryCode || '').toLowerCase()}/>
            <span className={'ml-1'}>{vehicle.year}</span>
          </div>
        </span>;
      })}

      {cluster.images.length > shownCount ?
        <IconButton className={'align-self-center ml-1 small text-center'} onClick={(e) => openExtendedCluster(e)}>
          <div>{cluster.images.length - shownCount}</div>
          <div>more...</div>
        </IconButton> : null}
  </span>
    </span>;
  } else if (cluster === false) {
    return <span className={'align-items-top text-danger'}>_id "<code>{clusterId}</code>" NOT FOUND</span>;
  } else {
    return <span className={`border bg-light rounded p-1 inline-block align-middle`}>
      <span style={{ minHeight: '72px' }} className={'inline-block text-center text-secondary small'}>
        Loading cluster<br/>
        <span className={'small'}>{clusterId}</span>...
      </span>
    </span>;
  }
}
