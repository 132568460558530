import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { IconButton } from './IconButton';
import { Icon } from './Icon';
import { UserAvatar } from './UserAvatar';
import TextFieldEditor from './editors/TextFieldEditor';
import SingleTextEditor from './editors/SingleTextEditor';
import { format, formatDistanceToNow } from 'date-fns';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { ImagePreview } from '../images/ImagePreview';
import { UserSummary } from './UserSummary';
import useAsyncEffect from './react-hooks/useAsyncEffect';
import LeftRightLayout from './layout/LeftRightLayout';
import { UserAvatarLive } from './UserAvatarLive';
import InputUserId from './InputUserId';

function RewiseAvatar({ createdBy }) {
  return <span className={'inline-block position-relative'}>
    <UserAvatar user={{ id: '', username: '  ' }} style={{ backgroundColor: '#25374a' }}
                className={'Email__avatarRewise'}/>

    {createdBy ? <UserAvatarLive id={createdBy?.id} className={'position-absolute'}
                                 style={{ zoom: 0.5, bottom: '-12px', right: '-8px' }}/> : null}
    </span>;
}

const ConversationMessage = ({ message, replyDrafts, onSend, onDataChange, setWritingTo, openTemplatesModal }) => {
  const {
    fromName, userId, fromAddress, type, msgHtml, originalHtml, msgText, msgId, attachments, read, delivered, clicked,
    date, labels, createdBy
  } = message;

  const handleRemoveLabel = (label) => {
    let changedMessage = { ...message };
    _.pull(changedMessage.labels, label);
    onDataChange(changedMessage);
  };

  const handleAddLabel = () => {
    let label = prompt('Please write the new label name:');
    if (label && !message.labels?.includes(label)) {
      message.labels = [...message.labels || [], label];
      onDataChange(message);
    }
  };

  const changeReadStatus = () => {
    message.read = !message.read;
    onDataChange(message);
  };

  const handleReply = () => {
    if (!_.has(replyDrafts, message.msgId)) {
      setWritingTo({ ...replyDrafts, [message.msgId]: '' });
    }
  };

  const fromUs = type === 'sent';

  const label = (l) =>
    <span key={l} className={'inline-block badge badge-primary ml-1'}>
      {l}
      <IconButton size={'sm'} level={'secondary'} icon={'close'} className={'btn-primary'}
                  onClick={() => handleRemoveLabel(l)}/>
    </span>;

  const attachmentsList = _.map(attachments, (file, i) =>
    <IconButton key={i + file.url} icon={'attach_file'} title={`${file.fileName} ${file.size >> 10}kb`}
                onClick={() => window.open(file.url, '_blank')}>
      {file.contentType.includes('image') ?
        <ImagePreview url={file.url} className={'mr-1 bg-light'} zoom/> : file.fileName}
    </IconButton>);

  const messageClassName = `${fromUs ? 'bg-light' : 'bg-white'}`;

  const markAsReadUnreadButton = message.read ?
    <IconButton icon={'mark_email_unread'} level={'secondary'} title={'Mark as unread'}
                onClick={changeReadStatus}/>
    :
    <IconButton icon={'mark_email_read'} level={'secondary'} title={'Mark as read'}
                onClick={changeReadStatus}/>;


  let emailActions;
  if (!fromUs) {
    emailActions = <>{!fromUs ? markAsReadUnreadButton : null}
      <IconButton icon={'local_offer'} title={'Add label'} onClick={handleAddLabel}/>
      <IconButton icon={'quickreply'} title={'Send templated message'}
                  onClick={() => openTemplatesModal(message)}/>
      <IconButton icon={'reply'} title={'Reply'}
                  onClick={handleReply}/>

      {message.length ?
        <span className={'text-small text-black-50'}>{message.length} chats selected</span> : null}
    </>;
  } else {
    let readDeliveredIcon;
    if (read) {
      readDeliveredIcon = <Icon icon={'done_all'} title={'read'} style={{ color: '#00bb33' }} className={'pl-1'}/>;
    } else if (delivered) {
      readDeliveredIcon = <Icon icon={'done_all'} title={'delivered'} style={{ color: 'gray' }} className={'pl-1'}/>;
    } else {
      readDeliveredIcon = <Icon icon={'done'} title={'not delivered'} style={{ color: 'gray' }} className={'pl-1'}/>;
    }
    const sentMsgStatus = <span>
      {readDeliveredIcon}
      {clicked ? <Icon icon={'ads_click'} title={'clicked'} className={'pl-1'}/> : null}
    </span>;

    emailActions = <>
      {sentMsgStatus}
    </>;
  }

  let touchedHtml = originalHtml || msgHtml || msgText; // originalHtml
  if (touchedHtml && attachments?.length) {
    for (const { cid, url } of attachments) {
      touchedHtml = touchedHtml.replace(`"cid:${cid}"`, `"${url}"`);
    }
  }

  return <div className={`Email border-bottom pt-2 pb-3 ${messageClassName}`}>
    <LeftRightLayout top className={'align-items-top'}>
      <div style={{ width: '42px', zoom: '1.7' }} className={'text-center flex-shrink-0'}>
        {fromUs ? <RewiseAvatar createdBy={createdBy}/> : <UserAvatarLive id={userId}/>}
      </div>

      <div className={'flex-grow-1  pr-2'}>
        <LeftRightLayout>
          <div>
            <span className={''}>
              <span className={'Email__fromName'}>{!fromUs ? fromName : 'Opinautos'}</span>
              <span className={'Email__fromEmail'}>{'<' + fromAddress + '>'}</span>
            </span>
          </div>

          <div>
            <span>
              {attachmentsList.length ? <Icon className={'mr-2'} icon={'attachment'} level={'secondary'}/> : null}

              <span title={date} className={'Email__date'}>
                {format(new Date(date), 'MMM dd, hh:mm aa')} ({formatDistanceToNow(new Date(date))} ago)
              </span>
              <span>{_.map(labels, l => label(l))}</span>
            </span>

            <span className={'Email__actions'}>{emailActions}</span>
          </div>
        </LeftRightLayout>

        <div className={'mt-2 Email__bodyHtml'}>
          {touchedHtml ? <div dangerouslySetInnerHTML={{ __html: touchedHtml }}/> : msgText}
        </div>

        {attachmentsList.length ? <div className={'Email__attachments'}>{attachmentsList}</div> : null}
      </div>
    </LeftRightLayout>
    {_.has(replyDrafts, msgId) ? MessageEditor({ message, replyDrafts, onSend, setWritingTo }) : null}
  </div>;
};

function MessageEditor({ message, replyDrafts, onSend, setWritingTo }) {
  let { page } = useContext(LegoAdminPageContext);
  let msgId = message.msgId;
  let currentUser = page.getLoggedUserSignature();

  const newMessageObject = {
    subject: page.getUrlParam('subject'),
    body: page.getUrlParam('body'),
    recipientIds: page.getUrlParam('recipientIds') || page.getUrlParam('userId'),
    msgId: 'newMail'
  };

  let [newMessage, setNewMessage] = useState(newMessageObject);

  const debouncedSetUrlParams = useCallback(_.debounce(dataObj => {
    if (_.values(dataObj) && isNewMail) {
      page.setUrlParams(dataObj);
    }
  }, 1000), []);

  const onChangeNewMessage = (dataObj) => {
    setNewMessage({ ...newMessage, ...dataObj });
  };

  const onChangeRecipientId = (value) => {
    setNewMessage({ ...newMessage, recipientIds: value });
    debouncedSetUrlParams({ recipientIds: value });
  };

  let isNewMail = page.getUrlParam('editing');

  useEffect(() => debouncedSetUrlParams(newMessage), [newMessage]);

  const onReplyEdit = (text) => {
    let changedWritingTo = { ...replyDrafts };
    changedWritingTo[msgId] = text;
    setWritingTo(changedWritingTo);
  };

  const onDiscard = () => {
    if (isNewMail) {
      page.deleteUrlParam('body', 'subject', 'editing', 'feedbackId', 'recipientIds', 'msgId');
    } else {
      let changedWritingTo = { ...replyDrafts };
      delete changedWritingTo[msgId];
      setWritingTo(changedWritingTo);
    }
  };

  // TODO: prevent re rendering InputUserId when writing subject and body. Performance issues with too many recipients.
  return <div>
    {isNewMail ? <div>
        <div className={'m-1'}>
          <span>
            <InputUserId isClearable={true} isMulti placeholder={'Enter recipient ids'}
                         onChange={onChangeRecipientId} value={newMessage.recipientIds}/>
          </span>
        </div>
      </div>
      :
      null}
    <LeftRightLayout top className={'align-items-top bg-light  mt-2 py-2 border-top'}>
      <div style={{ width: '42px', zoom: '1.7' }} className={'text-center flex-shrink-0'}>
        <RewiseAvatar createdBy={currentUser}/>
      </div>

      <div className={'flex-grow-1 p-2 mr-3 bg-light-primary border border rounded shadow-sm'} key={`${msgId}-reply`}>
        {isNewMail ?
          <div>
            <SingleTextEditor className={'mb-1'} small value={newMessage.subject} placeholder={'Write a subject'}
                              onChange={(text) => onChangeNewMessage({ subject: text })}/>
            <TextFieldEditor showCounter={false} value={newMessage.body}
                             onChange={(text) => onChangeNewMessage({ body: text })}
                             key={`${msgId}-text`} rows={20} minRows={20}/>
          </div>
          :
          <TextFieldEditor showCounter={false} value={replyDrafts[msgId]}
                           onChange={(text) => onReplyEdit(text)}
                           key={`${msgId}-text`}/>
        }
        <div className={'mt-2'}>
          <LeftRightLayout>
            <div>
              {
                isNewMail ?
                  <span className={'btn btn-primary'} onClick={() => onSend(newMessage)}>Send</span>
                  :
                  <span className={'btn btn-primary'}
                        onClick={() => onSend(message, replyDrafts[msgId])}>Send reply</span>
              }
            </div>

            <IconButton icon={'delete'} level={'secondary'} onClick={onDiscard}/>
          </LeftRightLayout>
        </div>
      </div>
    </LeftRightLayout>
  </div>;
}

export default function MailConversation({ threadId, userId, onUpdate }) {
  let { page } = useContext(LegoAdminPageContext);
  const mailsService = client.service('services/data/emails/conversations');

  let [replyDrafts, setReplyDrafts] = useState({});
  let [thread, setThread] = useState([]);

  const existNewMail = !_.isNil(page.getUrlParam('subject')) || !_.isNil(page.getUrlParam('body')) || !_.isNil(page.getUrlParam('recipientIds'));

  const editingNewMail = !!page.getUrlParam('editing');

  const loadData = async () => {
    if (threadId || userId) {
      let query = threadId ? { threadId } : { userId };
      setThread(await page.runAsync(mailsService.find({ query })));
    } else {
      setThread(null);
    }
  };

  useAsyncEffect(() => loadData(), [threadId, userId]);

  const onDataChange = async (changedData) => {
    if (changedData) {
      await page.runAsync(mailsService.update(changedData._id, changedData));
      loadData();
      onUpdate();
    }
  };

  const onSend = async (message, text) => {
    await page.runAsync(mailsService.create({
      messageToReply: message,
      html: text.replace(/\n/g, '<br>').replace(/\t/g, '&emsp;'),
      options: {
        createdBy: page.getLoggedUserSignature()
      }
    }));

    let changedDrafts = { ...replyDrafts };
    delete changedDrafts[message.msgId];
    setReplyDrafts(changedDrafts);

    loadData();
  };

  const NewMailSender = ({ message }) => {
    let [recipientsData, setRecipientsData] = useState({});
    let [sentTo, setSentTo] = useState([]);
    let [allSent, setAllSent] = useState(false);

    const loadData = async () => {
      const recipients = (await page.service('services/data/users/info').find({ query: {search: message.recipientIds} })).users;

      const nonMailRecipients = _.filter(recipients, r => !r.email);
      const mailRecipients = _.difference(recipients, nonMailRecipients);

      setRecipientsData({ noMail: nonMailRecipients, sendTo: mailRecipients });
      sendMail(mailRecipients);
    };

    useAsyncEffect(loadData, [message]);

    const sendMail = (mailRecipients) => {
      page.runAsync(async () => {
        for (const user of mailRecipients) {
          let newMailObject = {
            userTo: { id: user.id, email: user.email },
            subject: message.subject,
            html: message.body.replace(/\n/g, '<br>').replace(/\t/g, '&emsp;'),
            options: {
              createdBy: page.getLoggedUserSignature()
            }
          };

          if (!_.isNil(page.getUrlParam('feedbackId'))) {
            newMailObject.options.feedbackId = page.getUrlParam('feedbackId');
          }
          await mailsService.create(newMailObject);
          setSentTo(sentTo => _.concat(sentTo, user.email));
        }
      });

      setAllSent(true);
    };

    const onClose = () => {
      page.deleteUrlParam('body', 'subject', 'editing', 'feedbackId', 'msgId', 'recipientIds');
      page.closeModal();
    };

    const nonSentTo = recipientsData.noMail?.length ? <div>
      Excluded {recipientsData.noMail.length} user/s that has no email address:
      {_.map(recipientsData.noMail, user => <div key={user.id}
                                                 className={'text-danger small'}>{user.username} - {user.id}</div>)}
    </div> : null;

    const sendTo = recipientsData.sendTo?.length;
    const sentCount = sentTo.length;
    //TODO: check better way to ensure that all mails where sent. Close button appears before ending. runAsync loading effect blocks modal contents.
    return <div className={'p-1'}>
      Sending email to {sendTo} recipient(s).
      <br/>
      {nonSentTo}
      <br/>
      Sent {sentCount} email(s).
      <br/>

      {allSent ? <div className={'btn btn-primary mt-3'} onClick={onClose}>Close</div> : null}
    </div>;
  };

  const onSendNewMail = async (message) => {
    if (message.recipientIds && message.subject && message.body) {
      page.openModal(<NewMailSender message={message}/>, { minSize: false, title: 'Sending emails...' });
    }
  };

  const openTemplatesModal = () => {
    // let state = {
    //   userId,
    //   // modelId: 'Volkswagen-Vento',
    //   // year: 2009,
    //   // url: '/ar/ford/focus/info/fusibles/2001',
    //   // suggestedYear: 2013
    // }
    //
    // let userTo = {
    //   id: userId,
    //   email: 'jsilveira+vento@gmail.com'
    // }
    //
    // page.openModal(<TemplateEmailEditor preloadedState={state} userTo={userTo} onSent={() => page.closeModal()}/>,
    //   {fullScreen: true, noPadding: true, title: 'Send email using template'});
  };

  const orderedThreads = _.orderBy(thread, 'date');

  const allDraftsAreEmpty = _.every(_.values(replyDrafts), val => _.isEmpty(val));
  const selectedDraftIsNotLastMessage = _.keys(replyDrafts).at(-1) !== orderedThreads.at(-1)?.msgId;
  if (allDraftsAreEmpty && selectedDraftIsNotLastMessage && orderedThreads.length) {
    setReplyDrafts({ [orderedThreads.at(-1)?.msgId]: '' });
  }
  if (editingNewMail) {
    return <div>
      <MessageEditor message={{}} onSend={onSendNewMail}/>
    </div>;
  }
  if (thread?.length) {
    return <div>
      {
        existNewMail ?
          <div className={'btn btn-danger d-block'} onClick={() => page.setUrlParam('editing', true)}>There is one
            unsent e-mail</div>
          :
          null
      }
      <LeftRightLayout className={'px-3 py-2 bg-light sticky-top border-bottom'}>
        <div className={''}>
          <div className={'h4 font-weight-normal'}>{orderedThreads[0]?.subject}</div>
          <div>
            <UserSummary userId={orderedThreads[0]?.userId}/>&nbsp;
            <span className={'align-middle small text-secondary'}>{'<' + orderedThreads[0]?.userEmail + '>'}</span>
          </div>
        </div>

        <span className={''}>
        <IconButton icon={'refresh'} onClick={() => loadData()} title={'Check for new mails'}/>
        </span>
      </LeftRightLayout>

      <div className={''}>{_.map(orderedThreads, message =>
        <ConversationMessage message={message} replyDrafts={replyDrafts} setWritingTo={setReplyDrafts}
                             onSend={onSend} key={message.msgId} onDataChange={onDataChange}
                             openTemplatesModal={openTemplatesModal}
        />)}
      </div>

      <div>
      </div>
    </div>;
  } else {
    return <div className={'p-2 text-center'}>No thread messages
      found {threadId ? `for thread id ${threadId}` : `for user id ${userId}`}</div>;
  }
}
