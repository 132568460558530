import React, { Component } from 'react';
import dayjs from 'dayjs';
import EventEmitter from 'events';
import _ from 'lodash';
import ModalManager from '../ModalManager';
import { LegoPreview } from './LegoPreview';
import LegoContextSummary from './LegoContextSummary';
import { IntentionTag } from './IntentionTag';
import { TypeTag } from './TypeTag';
import SemanticTag from '../semantic/SemanticTag';
import { LegoStateBadge } from './LegoStateBadge';

let LEGOS_CACHE = null;

export default class LegoSearchDialog extends Component {
  constructor(props) {
    super(props);

    this.legosService = window.client.service('services/legos');

    this.state = {
      query: '',
      results: LEGOS_CACHE,
      loading: true,
    };

    this.modalActionsBus = new EventEmitter();

    this.searchLegos = _.debounce(this.searchLegos, 150);

    this.searchLegos();
  }

  async searchLegos() {
    this.setState({ loading: true });

    let res = await this.legosService.find({
      query: {
        $select: { 'data.fuses': false, 'data.boxDescription': false },
        $sort: '-updatedAt',
        ... (this.props.legoFilter || {})
      }
    });

    LEGOS_CACHE = res;

    this.setState({ results: res, loading: false });
  }

  async queryChanged(e) {
    let q = e.target.value;

    this.setState({ query: q });
  }

  async delete(item) {
    if(confirm(`THERE IS NO UNDO. Are you sure you want to delete ${item.url}?`)) {
      item.removing = true;
      this.setState({ loading: true, results: this.state.results });
      let res = await this.imagesService.remove(item.url);
      await this.searchLegos();
    }
  }

  render() {
    let legos = null;

    let {query, loading, results} = this.state;

    if (results === null && loading) {
      legos = <div className={'text-center'}>Loading legos...</div>;
    } else {
      if (results) {
        legos = []

        let filteredResults = _.sortBy(results, lego => -lego.updatedAt);

        if(query) {
          filteredResults = _.filter(filteredResults, (l) => JSON.stringify(l).indexOf(query) >= 0);
        }

        legos = filteredResults.map((lego) => {
          let {_id, type, context, intentions, semantics, state, updatedAt} = lego;

          let publishedClass = (!state || state === 'published') ? '' : ' bg-light-secondary';

          return <tr key={_id} onClick={() => this.props.onSave(lego)} className={' '}>
            <td key={'type'}>
              <div>
                <TypeTag key={type} type={type}/>
                <span className={'text-info small ml-2'}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
              </div>
              <div className={'text-secondary small'}>{_id}</div>
              <LegoStateBadge state={state}/>
            </td>

            <td key={'int'}>{_.map(intentions, int => <IntentionTag key={int} intention={int}/>)}</td>

            <td  key={'sem'}className={'text-center sem-column'+publishedClass}>{_.map(semantics, s => <SemanticTag level={'secondary'} key={s}
                                                                                                                    semKey={s}/>)}</td>

            <td key={'ctx'} className={'context-column'+publishedClass}><LegoContextSummary context={context}/></td>

            <td key={'pre'} className={'data-column'+publishedClass}><LegoPreview lego={lego}/></td>
          </tr>;
        });


        legos = <table className={'table table-sm clickable-rows'}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Type</th>
              <th>Semantic</th>
              <th>Context</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>{ legos }</tbody>
        </table>
      } else {
        legos = <div className={'alert alert-danger'}>No legos found ¿?</div>;
      }
    }

    return <div>
      <div>
        <input autoFocus={true} placeholder={'Search names...'} type={'text'} className={'form-control'}
               value={query}
               onChange={this.queryChanged.bind(this)}/>
      </div>

      <div className={'mt-1'}>
        {legos}
      </div>
      {/*<div className={'mt-3 '}>*/}
      {/*<button onClick={() => this.props.onOk("")} className={'btn btn-primary mr-2'}>Ok</button>*/}
      {/*<button onClick={() => this.props.onCancel} className={'btn btn-default'}>Cancelar</button>*/}
      {/*</div>*/}

      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
