import React, { Component, useContext, useState } from 'react';

import { IconButton } from '../common/IconButton';
import SemanticSearchDialog from './SemanticSearchDialog';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';

const SemanticEditor = (props) => {
  const { label, placeholder, value, onChange } = props;

  const { page } = useContext(LegoAdminPageContext);

  const textChanged = (newText) => {
    onChange(newText);
  };

  const openSemanticSearch = (initialSearch = '') => {
    page.openModal(<div>
      <SemanticSearchDialog
        initialSearch={initialSearch}
        onOk={async (semantic) => {
          textChanged(semantic);
          page.closeModal();
        }}
        onCancel={() => page.closeModal()}
      />
    </div>, { title: 'Find semantic' });
  };

  const input = <input type="text" placeholder={placeholder || 'Semantic...'}
                       className={`form-control form-control-sm ${value ? '' : 'bg-light-danger text-danger'}`}
                       onChange={(e) => textChanged(e.target.value)} value={value || ''}/>;

  const searchBtn = <IconButton onClick={() => openSemanticSearch()} level="primary" icon="search"
                                description="Search semantic" className="p-0"/>;

  return <div className="input-group input-group-sm">
    {label && (
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon1">{label}</span>
      </div>
    )}
    {input}
    <div className="input-group-append">
      <span className="input-group-text p-0" id="basic-addon2">{searchBtn}</span>
    </div>
  </div>;
};

export default SemanticEditor;
