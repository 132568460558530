import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash'

import PDFPageTextPreview from "./PDFPageTextPreview";
import HighlightedText from "../common/HighlightedText";
import {IconButton} from "../common/IconButton";
import {SwitchInput} from "../common/SwitchInput";
import ImgCache from "./GlobalImageCache"
import AutocompleteMagic from '../common/editors/AutocompleteMagic';
import { normalize } from '../../../../components/interop/utils';
import BadgeId from '../common/BadgeId';

const ignoreReactSelectFields = (handler) => {
  return (event) => (event.target.id || "").startsWith('react-select') || handler(event);
}


const ignoreInputFields = (handler) => {
  return (event) => {
    if (['INPUT', 'TEXTAREA'].includes(event.target.nodeName) || (event.target.id || "").startsWith('react-select')) {
      return true;
    }
    return handler(event)
  };
};

// const executeScroll = _.debounce((element) => element && element.scrollIntoView({ behavior: 'smooth', block: 'center' }), 250);
const executeScroll = element => element && element?.scrollIntoViewIfNeeded && element.scrollIntoViewIfNeeded(false);

function PagesPreviewPane({ pages, currentPage, onPageClick, pageImgSrc }) {
  useEffect(() => executeScroll(window.$('img#pdf-page-thumb-'+currentPage)[0]), [currentPage])

  return <div>{_.map(pages.slice(0, 1000), (page, index) => <img
    key={pageImgSrc(page.index)}
    onMouseDown={() => onPageClick(page, index)}
    id={'pdf-page-thumb-'+page.index}
    className={`${page.index === currentPage ? '' : 'translucent'} pdf-page-thumb mb-1 mr-1  inline-block`}
    src={pageImgSrc(page.index)}
    loading={'lazy'}/>)}</div>;
}

export default class PDFHtmlViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.startPage ?? 0,
      filterText: props.initialSearch || "",
      lastPages: props.lastPages,
      searchResultsSnippets: [],
      zoom: 1,
      showPagesImages: true
    };

    this.currentPagePreview = React.createRef();

    props.shortcuts.registerKeyMappings({
      'focusSearch': 'ctrl+f',
      'prevResult': 'up',
      'nextResult': ['down'],
      'nextPage': ['pagedown'],
      'nextPageArrow': ['right'],
      'previousPage': ['pageup'],
      'previousPageArrow': ['left'],
      'firstPage': 'home',
      'lastPage': 'end',
      'zoomIn': 'ctrl+plus',
      'zoomOut': 'ctrl+-',
      'zoomReset': 'ctrl+0',
      'toggleImages': 'ctrl+i',
      'goToPage': ['ctrl+g']
    });

    props.shortcuts.registerActionHandlers({
      'focusSearch': this.focusSearch.bind(this),
      'prevResult': ignoreReactSelectFields(this.previousResult.bind(this)),
      'nextResult': ignoreReactSelectFields(this.nextResult.bind(this)),
      'nextPage': this.nextPage.bind(this),
      'nextPageArrow': ignoreInputFields(this.nextPage.bind(this)),
      'previousPageArrow': ignoreInputFields(this.previousPage.bind(this)),
      'previousPage': this.previousPage.bind(this),
      'zoomIn': this.zoomIn.bind(this),
      'zoomOut': this.zoomOut.bind(this),
      'zoomReset': this.zoomReset.bind(this),
      'toggleImages': this.toggleShowImages.bind(this),
      'firstPage': ignoreInputFields(() => this.goToPage(0)),
      'lastPage': ignoreInputFields(() => this.goToPage(this.props.pages.length - 1)),
      'goToPage': ignoreInputFields(() => this.goToPage(parseInt(prompt('Ingrese número de página', 0))-1)),

    });

    this.searchInputRef = React.createRef();
    this.searchResultsRef = React.createRef();

    this.searchDebounced = _.debounce(this.search.bind(this), 100);

    if(props.initialSearch) {
      this.searchDebounced();
    }

    this.prefetchTimeout = null;
  }

  toggleShowImages() {
    this.setState({ showPagesImages: !this.state.showPagesImages });
  }

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.pages !== state.lastPages) {
      return {
        lastPages: props.pages,
        filteredPages: null,
        currentPage: Math.min(state.currentPage, props.pages.length - 1)
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filteredPages === null && this.state.currentFilter && !prevState.filteredPages) {
      this.search();
    }
  }

  focusSearch() {
    const node = this.searchInputRef.current;
    if(node) {
      node.focus();
      node.select();
    }
  }

  zoomIn() {
    const zoom = this.state.zoom;
    this.setState({ zoom: zoom + (zoom >= 1 ? 0.2 : 0.1) })
    this.scrollToFirstSearchResult();
  }

  zoomReset() {
    this.setState({ zoom: 1 })
  }

  zoomOut() {
    const zoom = this.state.zoom;
    this.setState({ zoom: zoom - (zoom > 1 ? 0.2 : 0.1) })
  }

  goToPage(pageIndex) {
    if (pageIndex < this.props.pages.length && pageIndex >= 0) {
      let currentResult = this.state.currentResult;
      if(this.state.filteredPages && _.find(this.state.filteredPages, {index: pageIndex})) {
        currentResult = _.findIndex(this.state.filteredPages, {index: pageIndex});
      }

      // Hack to take part of the state of the current page and replicate it in the next page in order
      // to preserve some UI state, such as "i'm selecting a part of the page" state
      let nextPageProps;
      if(this.currentPagePreview.current) {
        nextPageProps = _.pick(this.currentPagePreview.current.state, 'selectingTextBox', 'selectingBox', 'selectingBoxMessage', 'onBoxSelected', 'onCancel');
      }

      this.setState({ currentPage: pageIndex, currentResult }, () => {
        if(nextPageProps && this.currentPagePreview.current) {
          this.currentPagePreview.current.setState(nextPageProps);
        }
      })
    }
  }

  nextResult() {
    const results = this.state.filteredPages || this.props.pages;
    const current = this.state.currentResult;

    if ((current+1) < results.length) {
      this.setState({ currentResult: current + 1 })
      this.goToPage(results[current + 1].index)
    }
    this.focusSearchResults();
  }

  previousResult() {
    const results = this.state.filteredPages || this.props.pages;

    const current = this.state.currentResult;

    if (current > 0) {
      this.setState({ currentResult: current - 1 })
      this.goToPage(results[current-1].index);
    }
    this.focusSearchResults();
  }

  focusSearchResults() {
    // After search, by pressing enter o DownArrow/UpArrow, focus comes to search results so that
    // Now, LeftArrow/RightArrow funcitonality of moving between pages works (instead of text input behaviour)
    if(this.searchResultsRef.current) {
      this.searchResultsRef.current.focus();
    }

    this.scrollToFirstSearchResult();
  }

  scrollToFirstSearchResult() {
    if(this.state.filterText) {
      // HACK: Dirty scroll to make sure first highlighted result is in scroll
      const $highlighted = $(".clipping-page .highlight");
      if($highlighted.length) {
        $highlighted[0].scrollIntoView({
          // behavior: "smooth",
          block: "center",
          inline: "center"
        })
      }
    }
  }

  nextPage() {
    this.goToPage(this.state.currentPage + 1);
  }

  previousPage() {
    this.goToPage(this.state.currentPage - 1);
  }

  onMouseWheel(e) {
    const {deltaY} = e;
    if(deltaY > 0) {
      this.nextResult();
    } else if(deltaY < 0) {
      this.previousResult();
    }
    // e.preventDefault();
    e.nativeEvent.stopImmediatePropagation()
    e.stopPropagation();
    return false
  }

  onFilterChanged(value) {
    this.setState({ filterText: value })
    this.searchDebounced();
  }

  search() {
    let searches = normalize(this.state.filterText).split('&&');
    let searchResultsSnippets = [];
    let searchRegex;

    let matches = this.props.pages;

    if (searches[0]) {
      for(const search of searches) {
        let start = new Date();
        try {
          searchRegex = new RegExp(search, 'ig');
        } catch (err) {
          searchRegex = new RegExp(_.escapeRegExp(search), 'ig');
        }
        matches = _.filter(matches, p => {
          const s = p.allText;
          let matches = s.match(searchRegex);
          if (matches) {
            const loc = s.indexOf(matches[0]);
            const snippet = s.slice(Math.max(0, loc - 30), loc + matches[0].length + 30);
            searchResultsSnippets.push(<HighlightedText text={snippet} regex={searchRegex}/>)
          }
          return matches
        });
      }

      if(searches.length > 1) {
        try {
          searchRegex = new RegExp(`(${searches.join(')|(')})`, 'ig');
        } catch(err) {
        }
      }

      // console.log(`Searched pdf in ${new Date() - start}ms with ${matches.length}`);
      this.setState({ currentFilter: searchRegex, filteredPages: matches, currentResult: 0, currentPage: matches.length ? matches[0].index : 0, searchResultsSnippets })
    } else {
      this.setState({ currentFilter: searches[0], filteredPages: null, searchResultsSnippets })
    }
    setTimeout(() => this.scrollToFirstSearchResult(),5)
  }

  static generatePageImgUrl(manualHash, imgSize, pageNumber, totalPages, bucket) {
    let domain = `${bucket}.s3.amazonaws.com`;
    domain = domain.replace('startmycar-manuals.s3.amazonaws.com', 'manuals.startmycar.com')
    // domain = domain.replace('opinautos-manuals.s3.amazonaws.com', 'manuals.opinautos.com')
    domain = domain.replace('opinautos-manuals.s3.amazonaws.com', 'd3rdx2e0fux0m7.cloudfront.net' );
    domain = domain.replace('opicarros-manuals.s3.amazonaws.com', 'd2ubriaib6wvzg.cloudfront.net' );
    domain = domain.replace('startmycar-manuals.s3.amazonaws.com', 'd1qmlszngpwayh.cloudfront.net' );

    const zeroPaddingLength = Math.ceil(Math.log10(totalPages+1));
    const pageNumberStr = (pageNumber).toString().padStart(zeroPaddingLength, '0');

    return `https://${domain}/processed/v1/${manualHash}/${imgSize}/page-${pageNumberStr}.jpg`
  }

  pageImgSrc(index, size = 'thumbnails') {
    return PDFHtmlViewer.generatePageImgUrl(this.props.hash, size,index+1, this.props.pages.length, this.props.s3Bucket)
  }

  render() {
    let { pages, globalStyles, originalUrl } = this.props;
    let { currentPage, filterText, filteredPages, currentFilter, searchSummary, searchResultsSnippets, zoom, showPagesImages, showMoreThumbnails } = this.state;

    filteredPages = filteredPages || pages;
    let results = null;
    if (this.state.filteredPages) {
      results = _.map(filteredPages.slice(0, 60), (page, index) => <div
        key={filterText + index}
        onMouseDown={() => this.setState({
          currentResult: index,
          currentPage: page.index
        })}
        className={`bg-${page.index === currentPage ? 'light-primary' : 'secondary'} p-0 mb-1 rounded d-flex align-items-center`}>

        <div className={'mr-2'}>
          <div style={{ backgroundImage: `url(${this.pageImgSrc(page.index)})` }} className={'search-result-img'}/>
        </div>

        <div>
          <div className={''}>
            <span className={'badge badge-primary mr-2'}>Pag {page.index + 1}</span>
            <span className={'text-dark'}>... </span>
            <span className={'small'}>{searchResultsSnippets[index]}</span>
            <span className={'text-dark'}> ...</span>
          </div>
        </div>
      </div>)
    } else {
      results = <PagesPreviewPane pages={pages}
                                  pageImgSrc={this.pageImgSrc.bind(this)}
                                  onPageClick={(page, index) => this.goToPage(index)}
                                  currentPage={currentPage}/>
    }

    let page = pages[currentPage];

    let pdfPage = <div className={'text-white p-4'}>
      No results for search /<span className={'text-warning'}>{(currentFilter || {}).source || ""}</span>/
    </div>;

    const manualHasText = _.sum(pages.slice(0,10).map(p => p.allText.length)) > 200;

    if (page) {
      const pageImage = (showPagesImages && currentPage < 1000) ? this.pageImgSrc(page.index, 'highres') : null;
      const pageImageThumb = this.pageImgSrc(page.index);

      if(showPagesImages) {
        // Prefetch images for next 4 pages and previous 3 pages
        clearTimeout(this.prefetchTimeout);
        this.prefetchTimeout = setTimeout(() => {
          for(let prefetchPageNumber of _.range(Math.max(0, page.index-3), Math.min(page.index+4, pages.length))) {
            ImgCache.load(this.pageImgSrc(prefetchPageNumber, 'highres'))
          }
        }, 200);

        for(let prefetchPageNumber of _.range(Math.max(0,page.index - 30), Math.min(page.index+30, pages.length))) {
          ImgCache.load(this.pageImgSrc(prefetchPageNumber))
        }
      }

      pdfPage = <PDFPageTextPreview pageImage={pageImage}
                                    pageImageThumb={pageImageThumb}
                                    key={this.state.currentPage+this.props.name}
                                    ref={this.currentPagePreview}
                                    lazyPageImage={false} page={page} currentFilter={currentFilter} zoom={zoom}
                                    globalStyles={globalStyles}/>
    }



    let prevPageBtn, nextPageBtn;
    let prevPage = pages[currentPage - 1];
    if(prevPage) {
      let {width, height} = prevPage.viewport;
      const pageStyle = {
        width: `${width/height*300}px`,
        left: 0,
        backgroundImage: `url(${this.pageImgSrc(currentPage - 1, 'highres')}), url(${this.pageImgSrc(currentPage - 1)})`
      };
      prevPageBtn = <div><div onClick={() => this.previousPage()} className={'pdf-page-preview-btn pdf-page'} style={pageStyle}/></div>
    }

    let nextPage = pages[currentPage + 1];
    if(nextPage) {
      let {width, height} = nextPage.viewport;
      const pageStyle = {
        width: `${width/height*300}px`,
        right: 0,
        backgroundImage: `url(${this.pageImgSrc(currentPage + 1, 'highres')}),url(${this.pageImgSrc(currentPage + 1)})`
      };
      nextPageBtn = <div><div onClick={() => this.nextPage()} className={'pdf-page-preview-btn pdf-page'} style={pageStyle}/></div>
    }


    return <div className={'bg-light pdf-html-viewer'}>
      <div className={'row no-gutters '}>
        <div className={'col-3 bg-dark text-white p-2'}>
          <div className={''}>
              <PdfSearchInput
                     onKeyPress={(e) => this.handleSearchEnter(e)}
                     value={filterText}
                     refObject={this.searchInputRef}
                     onChange={this.onFilterChanged.bind(this)}
              />
          </div>

          <div className={'search-results'} ref={this.searchResultsRef} tabIndex={1}>
            {
              manualHasText ? null :
              <div className={'h5 text-center'}>
                <span className={'badge badge-warning'}>Manual with only images</span>
              </div>
            }

            <div className={'mb-2 ml-1'}>
              {filteredPages.length} pages <span className={'text-primary small'}>{currentFilter ? currentFilter.source.slice(0,50)+'...' : ''}</span>
            </div>

            {results}
          </div>
        </div>

        <div className={'col-9 bg-dark p-2 overflow-hidden'}>
          <div className={'d-flex justify-content-between text-white'}>
            <span className={'mr-2'}>
              <a href={originalUrl+'#page='+(page.index+1)} target={'_blank'} className={'text-white'}>
                <IconButton icon={'picture_as_pdf'}/>
                <span className={'mr-2'}>{this.props.name}</span>
              </a>
              <span className={'badge badge-primary mr-2'}>Page {page ? page.index + 1 : '-'} of {pages.length}</span>

              {this.props.id ?<a href={`/manuals/edit?editing="${this.props.id}"`} target={'_blank'} className={'text-white'}>
                <BadgeId id={this.props.id}/>
                <IconButton icon={'edit'}/>
              </a> : null}

            </span>

            <span className={'form-inline text-secondary small'}>
              <SwitchInput value={showPagesImages} onChange={this.toggleShowImages.bind(this)} className={'mr-2'}>
                Show images
              </SwitchInput>

              <IconButton icon={'zoom_out'} level={'primary'} onClick={this.zoomOut.bind(this)}/>
              <span className={''}>{Math.round(zoom * 100)}%</span>
              <IconButton icon={'zoom_in'}  level={'primary'} onClick={this.zoomIn.bind(this)}/>
            </span>
          </div>

          <div className={'text-center px-4'}>
            {prevPageBtn}
            {pdfPage}
            {nextPageBtn}
            <div></div>
          </div>
        </div>
      </div>
    </div>;
  }

  handleSearchEnter(e) {
    if(e.key === 'Enter') {
      this.nextResult();
    }
  }
}

function PdfSearchInput({ refObject, value, onChange, ...other }) {
  const preloadedSearches = {
    'Fusebox': "\\b(fusible|fuse|fusiv|relay)",
    'Oil': "\\b((SAE )?(0|5|10|15|20)w-? ?\\d0|ESE-\\w+-\\w+|WSS\\W\\w+\\W\\w+|GM\\d{4}\\w|(API )?S[MN]|API [SC][A-Z]|ILSAC( GF-\\w+)?|API|ACEA( ?[ABC]\\w)?|dexos( ?[12])?|b71 \\d+|9\\.55535(-\\w+)?|LL.?\\d\\d|MB.?\\d{3}\\.\\d+|RN.?\\d{4}|VW.?5\\d\\d.\\d\\d|STJLR|MS.?\\d+|GM \\d{4}m)\\b",
    'Tire pressure': "lbf?.?(pulg|in)\\.?2?|kgf.?cm2?|psi|kpa|\\bbar(es)?\\b"
  }
  const getOptions = (text) => {
    text = text || '';

    let filters = _.filter(_.keys(preloadedSearches), f => f.toLowerCase().includes(text.toLowerCase()))

    return _.map(filters, name => ({
      value: preloadedSearches[name],
      label: <span>
        <span className={'text-info'}>{name}</span>
        <span className={'monospace text-secondary ml-2 small'}>{preloadedSearches[name].slice(0, 30)}</span>
      </span>
    }));
  };

  return <AutocompleteMagic
    startClosed={true}
    autoSize={false}
    refObject={refObject}
    noStyle={true}
    fullWidth={true}
    className={'w-100 form-control form-control-sm'}
    text={value || ''}
    placeholder={'Search pdf...'}
    onGetOptions={getOptions}
    onChange={(text) => onChange(text)}
    // onCancel={onCancel || (() => false)}
    autoComplete={'off'}
    {...other}
  />;
}
