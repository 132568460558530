import React, { Component } from 'react';
import _ from 'lodash';

import LegosManager from '../LegosManager';
import ModelContextEditor from '../../components/lego/ModelContextEditor';


export default class LegoDefinitionsManager extends LegosManager {
  constructor(... args) {
    super(... args);
    this.state.facetFilters = (this.state.facetFilters || {})
    this.state.facetFilters.intentions = ['definition']
  }

  getColumnsDefinition(objects) {
    let [intentions, ... other] = super.getColumnsDefinition(objects);
    return other;
  }

  // getFacetFiltersComponent() {
  //   let facetFilters = this.state.facetFilters || {};
  //   let { context } = facetFilters;
  //
  //   let onChange = (newContext) => {
  //     if (_.isEmpty(newContext)) {
  //       delete facetFilters.context;
  //     } else {
  //       facetFilters.context = _.cloneDeep(newContext);
  //     }
  //     this.onFiltersChange(facetFilters);
  //   }
  //
  //   return <div>
  //     <span style={{ width: '450px', display: 'inline-block' }} className={'zoom-75 ml-1'}>
  //       <ModelContextEditor value={context} onChange={(newContext) => onChange(newContext)}/>
  //     </span>
  //   </div>
  // }

  onAddNewClick(overrides) {
    // Override defaults for new Button
    super.onAddNewClick({
      type: 'data',
      intentions: ["definition"],
      semantics: ["WHAT"],
      state: 'inprogress'
    })
  }

  getObjectColumns(l, definition) {
    // Ignore type columns, as all articles have the same type
    let [type, ... other] = super.getObjectColumns(l, definition);
    return other;
  }
}
