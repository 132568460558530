import React, { Component } from 'react';

import JsonTextEditor from '../common/editors/JsonTextEditor';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import ArticleMarkupRichTextEditor from './article-editor/ArticleMarkupRichTextEditor';
import MultilineTextEditor from "../common/editors/MultilineTextEditor";
import SemanticEditor from './SemanticEditor';

export default class ArticleEditor extends Component {
  onChange(field, newValue) {
    let copy = {... this.props.value, [field]: newValue };
    this.props.onChange(copy);
  }

  render() {
    let {title, slug, articleTopicShort, content, image, relatedTag, semanticCategory } = this.props.value || {content: ''};
    return <div className={'pl-3 row'}>
      <div className={'col-4'}>
        <div className={'mb-2'}><SingleTextEditor label={'Title'} className={'font-weight-bold'} onChange={this.onChange.bind(this, 'title')} value={title}/></div>

        <SingleTextEditor label={'Image'} small className={'monospace text-secondary mb-2'}
                          placeholder={'Image url...'}
                          onChange={this.onChange.bind(this, 'image')} value={image}/>
        <SingleTextEditor label={'Short title'} small className={'font-italic mb-2'}
                          onChange={this.onChange.bind(this, 'articleTopicShort')} value={articleTopicShort}/>

        <SingleTextEditor label={'Related tags'} small className={'monospace text-info mb-2'}
                          placeholder={'Old site tags...'}
                          onChange={this.onChange.bind(this, 'relatedTag')} value={relatedTag}/>

        <SingleTextEditor label={'Slug'} small className={'monospace text-primary mb-2'}
                          placeholder={'for url, short and lowercase...'}
                          onChange={this.onChange.bind(this, 'slug')} value={slug}/>

        <div>
          <SemanticEditor label={'Category'}  onChange={this.onChange.bind(this, 'semanticCategory')} value={semanticCategory}/>
        </div>
      </div>

      <div className={'col-8 m-0'}>
          <div style={{marginTop: "0px", marginBottom: "-65px"}} className={''}>
            <ArticleMarkupRichTextEditor value={content} onChange={this.onChange.bind(this, 'content')}/>
          </div>
        {/*<MultilineTextEditor onChange={this.onChange.bind(this, 'content')} value={content}/>*/}
      </div>
    </div>;
  }
}
