import React, { Component, useContext, useState } from 'react';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import _ from 'lodash';
import ModelContextEditor from './ModelContextEditor';
import BadgeId from '../common/BadgeId';
import LegoContextSummary from './LegoContextSummary';
import { LocalesSelector } from './LocalesSelector';
import { LocalesList } from './LocalesList';
import LegoLabelsSelector from './LegoLabelsSelector';
import LegoLabel from './LegoLabel';
import { LabelsSummary } from '../data-issues/LabelsSummary';
import DataIssueStateSwitch from '../data-issues/DataIssueStateSwitch';
import { LegoStateBadge } from './LegoStateBadge';
import { OpenClosedIcon } from '../data-issues/OpenClosedIcon';
import { SwitchInput } from '../common/SwitchInput';
import { StateBadge } from '../data-issues/StateBadge';
import { LegoStateSwitch } from './LegoStateSwitch';

function computeObjectsCommonFactor(objects) {
  let commonFactor = { ...objects[0] };
  for (let ctx of objects) {
    _.each(commonFactor, (v, k) => {
      if (!_.isEqual(ctx[k], v)) {
        delete commonFactor[k];
      }
    });
  }
  return commonFactor;
}

function computeArraysCommonFactor(arrays) {
  return _.intersectionWith(... arrays, _.isEqual);
}

function computeCommonFactor(objects, columns) {
  let commonFactor = {};
  for(let { dataField, logic } of columns) {
    let values = _.map(objects, dataField);

    if(logic === LOGIC_SET_UNSET__FIELD) {
      let unique = _.uniq(values);
      if(unique.length === 1 ) {
        commonFactor[dataField] = unique[0];
      }
    } else if (logic === LOGIC_ARRAY_SET_COMMON_FACTOR) {
      commonFactor[dataField] = computeArraysCommonFactor(values);
    } else if (logic === LOGIC_LEGO_CONTEXT) {
      commonFactor[dataField] = computeObjectsCommonFactor(_.flatMap(objects, dataField));
    }
  }
  return commonFactor;
}

export let LOGIC_SET_UNSET__FIELD = 'simple field';
export let LOGIC_ARRAY_SET_COMMON_FACTOR = 'additive_set';
export let LOGIC_LEGO_CONTEXT = 'lego context';


export const defaultColumns = {
  labels: {
    dataField: 'labels',
    valueEditor: (value, onChange) => <span style={{minWidth: '250px'}}><LegoLabelsSelector value={value} onChange={onChange}/></span>,
    valueDisplay: (value) => <LabelsSummary labels={value}/>,
    logic: LOGIC_ARRAY_SET_COMMON_FACTOR
  },

  issueState: {
    dataField: 'state',
    valueEditor: (value, onChange) => <DataIssueStateSwitch value={value} onChange={onChange}/>,
    valueDisplay: (value) => <StateBadge state={value}/>,
    logic: LOGIC_SET_UNSET__FIELD
  },

  legoState: {
    dataField: 'state',
    valueEditor: (value, onChange) => <LegoStateSwitch compact value={value} onChange={onChange}/>,
    valueDisplay: (value) => <LegoStateBadge state={value}/>,
    logic: LOGIC_SET_UNSET__FIELD
  },

  locales:     {
    dataField: 'locales',
    valueEditor: (value, onChange) => <LocalesSelector value={value} onChange={onChange}/>,
    valueDisplay: (value) => <LocalesList locales={value}/>,
    logic: LOGIC_ARRAY_SET_COMMON_FACTOR
  },

  issueIsOpen: {
    dataField: 'isOpen',
    valueEditor: (isOpen, onChange) => <span>
       <span className={'align-middle'}>{isOpen ? 'Open issue' : 'Closed issue'}</span>
      <SwitchInput className={'mr-3 ml-2'} value={!isOpen} onChange={v => onChange(!v)}/>
    </span>,
    valueDisplay: (value) => <OpenClosedIcon isOpen={value} className={'mr-1'}/>,
    logic: LOGIC_SET_UNSET__FIELD
  },

  context: {
    dataField: 'context',
    valueEditor: (value, onChange) => <ModelContextEditor value={value} onChange={onChange}/>,
    valueDisplay: (value) => <LegoContextSummary context={value}/>,
    logic: LOGIC_LEGO_CONTEXT
  }
};


export default function ModalBatchEditLabels({ docs, onCancel, onSave, service, columns }) {
  let { page } = useContext(LegoAdminPageContext);

  columns = columns ? _.map(columns, c => _.isString(c) ? defaultColumns[c] : c) : _.values(defaultColumns);

  let [fieldsCommonFactor] = useState(computeCommonFactor(docs, columns));
  let [fieldsChange, setFieldsChange ] = useState(computeCommonFactor(docs, columns));

  const createTasks = async () => {
    console.log('COMMON', fieldsCommonFactor)
    console.log('CHANGE', fieldsChange)
    const $set = {};
    const $unset = {};
    let $pull = { };
    let $addToSet = { };

    for(let { dataField, logic } of columns) {
      let common = fieldsCommonFactor[dataField];
      let change = fieldsChange[dataField];

      if(logic === LOGIC_ARRAY_SET_COMMON_FACTOR) {
        const removed = _.difference(common, change);
        if(removed.length) {
          $pull[dataField] = {$in: removed};
        }
        const added = _.difference(change, common);
        if(added.length) {
          $addToSet[dataField] = added;
        }
      } else if (logic === LOGIC_SET_UNSET__FIELD) {
        if(dataField in fieldsChange && (!_.isEqual(common, change) || !(dataField in fieldsCommonFactor))) {
          if (change === undefined) {
            $unset[dataField] = true;
          } else {
            $set[dataField] = change;
          }
        }
      } else if (logic === LOGIC_LEGO_CONTEXT) {
        if(dataField in fieldsChange && (!_.isEqual(common, change) || !(dataField in fieldsCommonFactor))) {
          _.each(common, (v, k) => {
            if (change[k] === undefined) {
              $unset['context.$[].' + k] = true;
            }
          })
          _.each(change, (v, k) => {
            if(!_.isEqual(common[k], v)) {
              $set['context.$[].' + k] = v;
            }
          })
        }
      }
    }

    let updateFirstStep = {};
    if(!_.isEmpty($pull))
      updateFirstStep.$pull = $pull;

    if(!_.isEmpty($set))
      updateFirstStep.$set = $set;

    if(!_.isEmpty($unset))
      updateFirstStep.$unset = $unset;

    // Do update in two steps as mongo would fail with conflict if pull and addToSet used on same field on same object
    let updateSecondStep = {};
    if(!_.isEmpty($addToSet))
      updateSecondStep.$addToSet = $addToSet;

    let ids = _.flatMap(docs, doc => doc.dataIssuesIds || [doc._id]);
    page.runAsync(async () => {
      if(!_.isEmpty(updateFirstStep)) {
        console.log(updateFirstStep)
        await Promise.all(ids.map(id => service.update(id, updateFirstStep)));
      }
      if(!_.isEmpty(updateSecondStep)) {
        console.log(updateSecondStep);
        await Promise.all(ids.map(id => service.update(id, updateSecondStep)));
      }
      if(!_.isEmpty(updateFirstStep) || !_.isEmpty(updateSecondStep)) {
        page.selectNone();
        page.closeModal();
        onSave && onSave();
      }
    });
  };

  return <div>
    <table className={'table table-sm'}>
      <thead className={'bg-light'}>
      <tr>
        <th>Ids</th>
        { columns.map(col => <th key={col.dataField} className={'text-center'}>{col.dataField}</th>) }
      </tr>
      <tr>
        <th>({docs.length} documents)</th>
        {columns.map(col => <th key={col.dataField}  className={'text-center'}>{col.valueEditor(fieldsChange[col.dataField], (val) => setFieldsChange({... fieldsChange, [col.dataField]: val}))}</th>)}
      </tr>
      </thead>

      <tbody>
      {docs.map(doc => {

        return <tr key={doc._id}>
          <td>{doc.dataIssuesIds ? 'Cluster' : ''} <BadgeId id={doc._id}/></td>

          {
            columns.map(({ dataField, valueDisplay, logic }) => {
              let val = doc[dataField];
              let predictedVal;

              if(logic === LOGIC_SET_UNSET__FIELD) {
                predictedVal = dataField in fieldsChange ? fieldsChange[dataField] : val;
              } else if (logic === LOGIC_ARRAY_SET_COMMON_FACTOR) {
                predictedVal = _.union(_.difference(val, fieldsCommonFactor[dataField]), fieldsChange[dataField]);
              } else if (logic === LOGIC_LEGO_CONTEXT) {
                return  <td>
                  {(doc[dataField] || []).map((ctx, i) => {
                    const contextPreview = { ..._.omit(ctx, _.keys(fieldsCommonFactor[dataField])), ... fieldsChange[dataField] }

                    return <div className={_.isEqual(ctx, contextPreview) ? '' : 'bg-light-warning'}>
                      <LegoContextSummary key={i} context={contextPreview}/>
                    </div>
                  })}
                </td>
              }

              return <td key={dataField}
                className={'text-center ' + (_.isEqual(val, predictedVal) ? '' : 'bg-light-warning')}>
                {valueDisplay(predictedVal)}
              </td>;
            })
          }
        </tr>;
      })}
      </tbody>
    </table>


    <span className={'btn btn-primary mt-4'} onClick={() => page.runAsync(createTasks)}>
      Update docs
    </span>

    <span className={'btn btn-light ml-1 mt-4'} onClick={() => setFieldsChange(_.cloneDeep(fieldsCommonFactor))}>
      Reset changes
    </span>
  </div>;
}
