import _ from 'lodash'
import React, { useState, useEffect, useContext } from 'react';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';

export default function useLegoQuery(query, defaultData) {
  const { page } = useContext(LegoAdminPageContext);
  const [data, setData] = useState(defaultData);

  useAsyncEffect(async () => {
    let mounted = true;
    try {
      query = {$limit: 100, ... query};
      const result = await page.service('/services/legos').find({ query });

      console.log(`DONE ${JSON.stringify(query)}`);
      if (mounted) {
        setData(result);
      }
    } catch(err) {
      console.error(err);
    }
    return () => mounted = false
  }, []);

  return data;
}
