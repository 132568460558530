import React from 'react';

const styleGrid = {
  height: "100%",
  width: "100%",
  position: "absolute",
  gridTemplateRows: "min-content auto",
  gridTemplateColumns: "auto",
  display: "grid"
}

const styleTop = {
  position: 'relative'
}


const styleContent = {
  position: 'relative',
  overflow: 'auto'
}

export default function TopBarLayout({ style, children, ... otherProps }) {
  if (children.length !== 2) {
    return <div>EXACTLY TWO CHILDREN ARE NEEDED</div>;
  }

  return (
    <div style={{... styleGrid, ... style}} {... otherProps}>
      <div style={styleTop}>
        {children[0]}
      </div>
      <div style={styleContent}>
        {children[1]}
      </div>
    </div>
  );
}
