import React, { Component } from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import FacetsEditor from "./subpages/FacetsEditor";
import FacetsProxy from "../components/facets-manager/FacetsProxy";

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

export default class FacetsManager extends LivePage {
  constructor(props) {
    super(props);

    this.fullScreen = true;

    this.facets = new FacetsProxy(this.service('services/facets'));
  }

  componentDidMount() {
    super.componentDidMount();
  }

  renderPageBody() {
    return  <DndProvider backend={HTML5Backend}>
      <FacetsEditor facetsProxy={this.facets} {... this.props}/>
    </DndProvider>
  }
}
