import React from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import PDFHtmlViewer from "../components/pdf-tools/PDFHtmlViewer";
import CachedPDFManualLoader from "../components/pdf-tools/CachedPDFManualLoader"
import ModelContextEditor from "../components/lego/ModelContextEditor";
import {SwitchInput} from "../components/common/SwitchInput";
import {IconButton} from "../components/common/IconButton";
import submenuManuals from "./menus/submenu-manuals";
import CountryFlag from "../components/common/CountryFlag";
import { getCountryName } from '../../../components/interop/countryCodeConverterFront';
import { ManualsFiltersBar } from '../components/common/ManualsFiltersBar';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default class ManualsViewer extends LivePage {
  constructor(props) {
    super(props);

    this.pdfService = this.service('services/data/manuals');

    this.state = {
      ...this.state,
      pagesDescription: null,
      fileIndex: 0,
      files: [],
      onlyEs: false,
      onlyOwnerManuals: false,
      facetFilters: this.getUrlParam('filters', true) || {},
    };

    this.shortcuts.registerKeyMappings({
      'prevPdf': ['alt+left'],
      'nextPdf': ['alt+right'],
    });

    this.shortcuts.registerActionHandlers({
      'prevPdf': this.previousPdf.bind(this),
      'nextPdf': this.nextPdf.bind(this),
    });

    this.submenu = submenuManuals;

    this.viewer = React.createRef();
  }

  async nextPdf() {
    const { fileIndex, files } = this.state;
    this.loadFile(Math.min((fileIndex + 1), files.length - 1));
  }

  async previousPdf() {
    const { fileIndex, files } = this.state;
    this.loadFile(Math.max(0, (fileIndex - 1)))
  }

  componentDidMount() {
    super.componentDidMount();
    this.mounted = true;
    this.listFiles();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
  }

  async listFiles() {
    const lastHash = this.getUrlParam('fileHash');

    let { context, ... filters } = this.state.facetFilters;

    if(_.isEmpty(context)) {
      if(_.isEmpty(filters) && lastHash) {
        filters['metadata.hash'] = lastHash;
      } else {
        filters.$limit = 100;
      }
    } else {
      _.each(context, (value, key) => filters['context.'+key] = value);
    }

    if (this.state.onlyEs) {
      filters.isoLanguage = { $regex: "^ES" };
    }

    let files = await this.pdfService.find({ query: { ...filters } });

    files = _.sortBy(files, 'context.0.year');
    this.setState({ files }, async () => {
      if (this.state.files && this.state.files.length) {
        let fileIndex = this.state.fileIndex;
        if (lastHash) {
          fileIndex = _.findIndex(this.state.files, f => f.metadata && f.metadata.hash === lastHash)
          fileIndex = fileIndex >= 0 ? fileIndex : 0;
        }

        await this.loadFile(fileIndex);
      }
    })
  }

  filterChanged(facetFilters) {
    this.setState({ facetFilters }, () => this.listFiles())

    if(_.isEmpty(facetFilters)) {
      this.deleteUrlParam('filters')
    } else {
      this.setUrlParams({filters: facetFilters});
    }
  }

  fileName(file) {
    let { modelId, year } = file.context[0] || file.context;
    if(file.context.length > 1) {
      modelId = _.uniq(_.map(file.context, 'modelId')).join('/')
    }
    const countryName = _.map(file.country, c => getCountryName(c))

    return `${modelId}-${year}-${file.isoLanguage}-${countryName}`
  }

  fileNameHeader(file) {
    let { modelId, year } = file.context[0] || file.context;
    if(file.context.length > 1) {
      modelId = _.uniq(_.map(file.context, 'modelId')).join('/')
    }
    return <span>{modelId}-{year}-{file.isoLanguage} <CountryFlag countryCode={file.country[0]}/></span>
  }

  async loadFile(fileIndex) {
    const file = this.state.files[fileIndex];
    const fileHash = file.metadata.hash;
    const s3Bucket = file.metadata.s3Bucket;

    const fileName = this.fileName(file);

    this.setState({ fileIndex });

    await sleep(5);

    await this.runAsync((async () => {
      let pdf = await this.loadPdfSummary(fileHash, s3Bucket);
      if (this.mounted) {
        this.setState({ pagesDescription: pdf, fileHash, fileName, fileId: file._id, s3Bucket })
        this.setUrlParams({ fileHash })
      }
    })(), `Loading ${fileName}...`)

    await sleep(500);

    if (this.mounted) {
      // precache next 2 files
      for (const { metadata } of this.state.files.slice(fileIndex + 1, fileIndex + 3)) {
        await this.loadPdfSummary(metadata.hash, metadata.s3Bucket);
      }
      this.forceUpdate();
    }
  }

  async loadPdfSummary(pdfId, s3Bucket) {
    return await CachedPDFManualLoader.loadManualTextLayers(pdfId, s3Bucket);
  }

  toggleOnlyEs() {
    this.setState({ onlyEs: !this.state.onlyEs }, () => this.listFiles());
  }

  toggleOnlyOwnerManuals() {
    this.setState({ onlyOwnerManuals: !this.state.onlyOwnerManuals }, () => this.listFiles());
  }

  renderPageBody() {
    let { facetFilters, files, pagesDescription, fileName, fileId, fileHash, fileIndex, s3Bucket } = this.state;

    let shownManuals = 100;

    let from = Math.max(fileIndex - shownManuals / 2, 0);
    let to = Math.min(from + shownManuals, files.length);

    let filesButtons = _.map(files.slice(from, to), (file, i) => {
      if(!file.metadata) {
        return <span key={i} className={`btn btn-sm btn-warning mr-1 p-1 position-relative`}>
        <div>
          <span className={'badge badge-dark'}>NOT PROCESSED</span>
          <br/>
          <a href={file.manualPdfUrl} target={'_blank'} className={'text-white'}>
            <IconButton icon={'picture_as_pdf'} level={'dark'}>Open</IconButton>
          </a>
        </div>
        <div className={'text-white small'}>{this.fileNameHeader(file)}</div>
      </span>
      }

      const { metadata: { hash, pages, s3Bucket } } = file;

      const coverImgSrc = PDFHtmlViewer.generatePageImgUrl(hash, 'thumbnails', 1, pages, s3Bucket);
      const isCached = CachedPDFManualLoader.isCached(hash);
      const pdfBgClass = fileIndex === (i + from) ? 'primary' : (isCached ? 'secondary ' : 'secondary translucent');

      return <span key={hash+i} className={`btn btn-sm btn-${pdfBgClass} mr-1 p-1 position-relative`}
                   onClick={() => this.loadFile(i + from)}>
        <div>
          <img src={coverImgSrc} className={''} style={{ height: '80px' }} alt={hash} title={hash}/>
        </div>
        <div className={'text-white small'}>{this.fileNameHeader(file)}</div>
      </span>
    });

    if (files.length > shownManuals) {
      filesButtons.push(<span key={'more'} className={'text-white'}>
        And {files.length - shownManuals} more manuals...
      </span>)
    }

    let originalUrl = ((files ? (files[fileIndex] || {}).manualPdfUrl : '') || '').replace('s3.amazonaws.com/opinautos-manuals', 'manuals.opinautos.com');

    return <React.Fragment>
      <div className={'row no-gutters bg-secondary pb-1'}>
        <div className={'p-2 col-3'}>
          <ManualsFiltersBar filter={facetFilters} onChange={this.filterChanged.bind(this)}/>
        </div>

        <div className={'p-2 col-9 no-wrap overflow-auto ThumbsCarousel'}>
          {filesButtons}
        </div>
      </div>

      <div className={''}>
        {pagesDescription ?
          <PDFHtmlViewer ref={this.viewer} id={fileId} name={fileName} hash={fileHash} pages={pagesDescription.pages}
                         originalUrl={originalUrl} globalStyles={pagesDescription.globalStyles}
                         shortcuts={this.shortcuts} s3Bucket={s3Bucket}/>
          :
          "No pdf loaded"}
      </div>
    </React.Fragment>;
  }
}
