import React from 'react';
import _ from 'lodash';
import TrimSpec from './trims/TrimSpec';

export default function LegoContextSummary({ context, inline, ... props }) {
  let contexts = context || [{}];
  if (!_.isArray(contexts)) {
    contexts = [contexts];
  }

  contexts = _.sortBy(contexts, ctx => ctx.make || ctx.modelId );
  contexts = _.sortBy(contexts, ctx => _.keys(ctx).length );

  let limit = 6;

  let more = null;
  if(contexts.length > limit) {
    more = <div className={'text-secondary small'}>And {contexts.length - limit} more contexts...</div>
  }

  let contextsSpans = _.map(contexts.slice(0,6), (context, i) => {
    const specsPairs = [];
    let contextCopy = { ...context };

    let baseContext = [];
    ['make', 'modelId', 'year', 'trim'].forEach(filter => {
      let filterValue = contextCopy[filter];
      if (filterValue) {
        baseContext.push(filterValue);
        delete contextCopy[filter];
      }
    });

    if(contextCopy.trimSpecs) {
      contextCopy = {... contextCopy, ... contextCopy.trimSpecs};
      delete contextCopy.trimSpecs;
    }

    let generated = false;
    if(contextCopy.runId) {
      delete contextCopy.runId;
      generated = true;
    }

    _.each(contextCopy, (val, key) => {
      if(key === 'genId' && context.modelId && val.startsWith('@'+context.modelId+'@')) {
        val = val.replace('@'+context.modelId, '');
      }
      return specsPairs.push(<TrimSpec key={key} showSpec={true} spec={key} value={val} className={'mr-1'}/>);
    });

    if (!specsPairs.length && !baseContext.length) {
      return <span key={'*'} className={'badge badge-violeta text-warning mx-1'}>✱</span>;
    } else {
      const base = baseContext.join(' ');

      return <div key={i} className={`no-wrap ${inline ? 'd-inline-block mr-1' : 'mb-1 '}`}>
        <span className={'badge badge-violeta mr-1 align-middle'}>{base}</span>
        {specsPairs}
      </div>;
    }
  });
  if(inline) {
    return <span {... props}>{contextsSpans} {more}</span>;
  } else {
    return <div {... props}>{contextsSpans} {more}</div>;
  }
}
