import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { IconButton } from '../IconButton';
import MultilineTextEditor from './MultilineTextEditor';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import { UserAvatarLive } from '../UserAvatarLive';

export default function NotesEditor({ value, onChange }) {
  const { page } = useContext(LegoAdminPageContext);

  const [draft, setDraft] = useState(null);

  const addNote = () => setDraft({ content: '', userId: page.getLoggedUserSignature().id, createdAt: new Date() });

  const deleteNote = (note) => confirm(`Delete note "${note.content.slice(0,30)} (...)"?`) && onChange(_.without(value, note));

  const discardDraftNote = () => setDraft(null);
  const updateDraftNote = (txt) => setDraft({ ...draft, content: txt, createdAt: new Date() });
  const saveNote = () => {
    if (draft && draft.content) {
      onChange([...(value || []), draft]);
      setDraft(null);
    }
  };

  const btnAddComment = <IconButton icon={'add_comment'} level={'success'} className={'mt-1 mb-1'}
                                    onClick={() => addNote()}>
    Add comment
  </IconButton>;

  let comments = null;
  if (value && value.length) {
    comments = _.map(value, (note, i) => {
      const { content, userId, createdAt } = note;
      return <div key={i}
                  className={'bg-light-primary rounded mx-2 my-1 p-2 small'}>
        <div className={'text-secondary mb-1'}><UserAvatarLive id={userId}/>&nbsp; {content}</div>
        <div className={'text-right text-info small'}>
          {new Date(createdAt).toUTCString()}
          <IconButton level={'secondary'} icon={'delete'} className={'ml-2 zoom-75'} onClick={() => deleteNote(note)}/>
        </div>
      </div>;
    });
  }

  let draftNote = null;
  if (draft) {
    draftNote = <div className={'p-1 m-2 mb-4 border bg-light-secondary rounded'}>
      <MultilineTextEditor autoFocus={!draft.content} value={draft.content} onChange={(txt) => updateDraftNote(txt)}/>
      <div className={'mt-1'}>
        <IconButton level={'success'} icon={'send'} onClick={saveNote}>Send</IconButton>

        <IconButton level={'secondary'} icon={'clear'} className={'ml-2'} onClick={discardDraftNote}>
          Discard
        </IconButton>
      </div>
    </div>;
  }

  return <div>
    <div>{comments}</div>

    {draftNote}

    {draft ? null : <div className={'text-center'}>{btnAddComment}</div>}
  </div>;
}
