import React, {Component} from 'react';

import LivePage from "./LivePage";
import HotKeys from "react-hotkeys/es/HotKeys";
import ModalManager from "../components/ModalManager";
import {LoadingOverlay} from "../components/common/LoadingOverlay";
import _ from "lodash";
import LegoAdminPageContext from './legoAdminPageContext';

export default class LiveSubpage extends LivePage {
  constructor(props) {
    super(props);
    this.isSubpage = true;
  }

  getUrlParam(param, isJSON = true) {
    let modal = this.context.page.getUrlParam('modal', true) || {};
    return modal[param];
  }

  setUrlParams(paramsObj) {
    let modal = this.context.page.getUrlParam('modal', true) || {};
    modal = {... modal, ... paramsObj}

    // Remove empty keys
    modal = _.pickBy(modal, v => v !== undefined && v !== null);

    if(_.isEmpty(modal))
      this.context.page.deleteUrlParam('modal');
    else
      this.context.page.setUrlParams({modal});
  }

  deleteUrlParam(paramKey) {
    this.context.page.deleteUrlParam(paramKey);
  }

  render() {
    let { pageIsProcessing, loadingMessage, sessionId, connectionStatus } = this.state;

    const bodyClass = (!connectionStatus || connectionStatus === 'connected') ? '' : 'black-and-white';

    return <HotKeys keyMap={this.shortcuts.keyMappings} handlers={this.shortcuts.handlers}>
      <div className={bodyClass}>
        { this.renderPageBody() }

        <ModalManager actionsBus={this.modalActionsBus}/>
        <ModalManager actionsBus={this.modalErrorsBus}/>
        <LoadingOverlay isLoading={pageIsProcessing > 0} loadingMessage={loadingMessage}/>
      </div>
    </HotKeys>
  }
}

LiveSubpage.contextType = LegoAdminPageContext;
