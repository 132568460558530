import React, {Component} from 'react';

import _ from 'lodash'
import {formatDistance} from 'date-fns'

import {Icon} from "../common/Icon";

function extractUserActions(userId, reports) {
  let userActions = [];

  for(const den of reports) {
    if(den.userId === userId) {
      userActions.push({type: 'report', date: den.fecha, obj: den});
      den.userAlreadyActive = true;
    }
    let prevCommentsCount = 0;
    let prevCommentsDate = 0;
    for(const com of den.comments) {
      if(com.userId === userId) {
        if(!den.userAlreadyActive && prevCommentsCount) {
          userActions.push({type: 'prevComments', date: prevCommentsDate, obj: { count: prevCommentsCount, contenidoEditado: `${prevCommentsCount} PREV COMMENTS` }});
        }

        userActions.push({type: 'comment', date: com.fecha, obj: com});
        den.userAlreadyActive = true;
      } else if(den.userAlreadyActive) {
        userActions.push({type: 'reply', date: com.fecha, obj: com});
      } else {
        prevCommentsCount++;
        prevCommentsDate = com.fecha;
        // userActions.push({type: 'reply', date: com.fecha, obj: com});
      }
    }
  }

  userActions = _.sortBy(userActions, 'fecha')
  return userActions;
}

export {extractUserActions};

export default function UserPostsTimeline({userId, reports}) {
  let userActions = extractUserActions(userId, reports);

  const icons = {
    'comment': 'comment',
    'reply': 'comment',
    'prevComments': 'forum',
    'report': 'add_comment',
  }

  const colors = {
    'comment': 'primary',
    'reply': 'secondary',
    'prevComments': 'secondary',
    'report': 'success',
  }

  const extraClasses = {
    reply: 'ml-4'
  };

  return <div>
    { _.map(userActions, ({type, date, obj: {contenidoEditado}}, i) => {
      return <div className={extraClasses[type] || ''}>
        <Icon icon={icons[type]} level={colors[type]}/>

        { i > 0 ? <span className={'small ml-2'}>{formatDistance(userActions[i - 1].date, date)} later</span> : null }

        <span className={'ml-1'} style={{ maxWidth: '600px', overflow: 'hidden' }}>
          <span className={`small text-${colors[type]} ml-2 text-ellipsis`} title={contenidoEditado}>
            {contenidoEditado.slice(0, 80) + (contenidoEditado.length > 80 ? ' (...)' : '')}
          </span>
        </span>
      </div>;
    })}
  </div>
}
