const cache = new Map();
const timeouts = {};

let delayedTimeout;

export default {
  has: (url) => {
    return cache.has(url)
  },
  load: async (url) => {
    if(cache.has(url)) {
      return cache.get(url);
    } else {
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
          cache.set(url, img);
          resolve(img);
        };
        img.src = url;
      })
    }
  },
}
