export default class FacetsProxy {
  constructor(facetsService) {
    this.service = facetsService;
  }

  static parseId(facetId) {
    const parts = facetId.split(':')
    if (parts.length > 1) {
      return { name: parts[parts.length - 1], type: parts.slice(0, -1).join(':') }
    } else {
      return { name: facetId, type: '' }
    }
  }

  async proxyCall(method, ... params) {
    return this.service.find({query: {method, params}});
  }

  async get(id) {
    return this.proxyCall('get', id)
  }

  async getChildren(ids) {
    return this.proxyCall('getChildren', ids)
  }

  async getChildrenCount(ids) {
    return this.proxyCall('getChildrenCount', ids)
  }

  async getSubtypes(type, options) {
    return this.proxyCall('getSubtypes', type, options);
  }

  async getSubtypesChildren(type, options) {
    return this.proxyCall('getSubtypesChildren', type, options);
  }

  async getChildrenDeep() {
    return this.proxyCall('getChildrenDeep')
  }

  async findMatches(idPattern) {
    return this.proxyCall('findMatches', idPattern);
  }

  async add(id, subsetIds) {
    return this.proxyCall('add', id, subsetIds);
  }

  async remove(id) {
    return this.proxyCall('remove', id);
  }

  async addParent(id, subsetId) {
    return this.proxyCall('addParent', id, subsetId);
  }

  async removeParent(id, subsetId) {
    return this.proxyCall('removeParent', id, subsetId);
  }

  async edit(id, parents, newId) {
    return this.proxyCall('edit', id, parents, newId);
  }

  async refactorName(oldId, newId) {
    return this.proxyCall('refactorName', oldId, newId);
  }
}
