import React, {Component} from 'react';
import LeftRightLayout from "../layout/LeftRightLayout";

const unitsAbbreviations = {
  'liter': 'ℓ'
}

export default function MagnitudeEditor(props) {
  let { className, unit, small, value, label, placeholder, onChange, ...otherProps } = props;

  let classes = ['form-control', 'text-right'];

  if (small) {
    classes.push('form-control-sm', 'p-05')
  }

  if(value === null || value === undefined ) {
    classes.push('bg-light')
  }

  if (label) {
    classes.push(className);
  }

  if (!unit)
    unit = '?'

  const onNumberChange = (e) => {
    const number = e.target.value;
    if(!number && number !== 0) {
      onChange(null);
    } else {
      onChange({ amount: number, unit })
    }
  };

  let input = <input type={'number'} className={classes.join(' ')}
                     onChange={onNumberChange}
                     value={value ? value.amount : ''} placeholder={placeholder}
                     {...otherProps}
  />;

  return <div className={'form-inline justify-content-center'}>
    <span className={'small mr-2'}>{label || ""}</span>
    <div className={"input-group" + (small ? ' input-group-sm ' : ' ') + (className || "")}
         style={{ width: small ? '90px' : '120px' }}>
      {input}
      <div className="input-group-append">
        <div className="input-group-text p-1" id="basic-addon1" title={unit}>{unitsAbbreviations[unit] || unit}</div>
      </div>
    </div>
  </div>
}
