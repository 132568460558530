import React, { Component } from 'react';
import { TypeTag } from '../lego/TypeTag';
import { IntentionTag } from '../lego/IntentionTag';
import { SearchFilterButton } from './SearchFilterButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import _ from 'lodash';
import { SwitchInput } from './SwitchInput';

export class DataFeedbackFiltersBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange(field, value) {
    let newFilter = _.cloneDeep(this.props.filter || {});
    if(field !== 'dataSelector.vehicle') {
      if (value === undefined) {
          delete newFilter[field];
      } else {
        newFilter[field] = value;
      }
    } else {
      if (_.isEmpty(value)) {
        delete newFilter[field];
      } else {
        newFilter[field] = _.cloneDeep(value);
      }
    }
    this.props.onChange(newFilter);
  }

  render() {
    const f = this.props.filter || {};
    let { dataSelector, state, isCasual, awaiting } = f;

    const toggleCasual = v => this.onChange('isCasual', isCasual ? undefined : (isCasual === false ? true : false));

    const toggleAwaiting = v => this.onChange('awaiting', awaiting ? undefined : (awaiting === false ? true : false));

    return <div className={'d-flex align-items-center'}>
      <SwitchInput value={awaiting} onChange={toggleAwaiting}>
        Is awaiting
      </SwitchInput>

      <SwitchInput value={isCasual === undefined? undefined : !isCasual} onChange={toggleCasual}  className={'ml-3'}>
        Registered users
      </SwitchInput>

      <span style={{width: '350px', display: 'inline-block'}} className={'zoom-75 ml-3'}>
        <ModelContextEditor value={f['dataSelector.vehicle']} onChange={(newContext)=> this.onChange('dataSelector.vehicle', newContext, true)}/>
      </span>
    </div>;
  }
}
