import React, { Component } from 'react';
import _ from 'lodash';
import JsonTextEditor from '../common/editors/JsonTextEditor';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import MultipleContextsEditor from '../lego/MultipleContextsEditor';
import ProcessStateChangeSelector from './ProcessStateChangeSelector';
import CRUDEditor from '../common/editors/CRUDEditor';
import { GenericDataTypeEditor } from './GenericDataTypeEditor';
import { GenericDataTypeSummary } from './GenericDataTypeSummary';
import JsonGUIEditor from '../common/editors/JsonGUIEditor';

export default class DataTaskEditor extends CRUDEditor {
  constructor(props) {
    super(props);

    this.dataProcessDefinitionService = window.client.service('services/data-process-definition');
    this.dataTaskActionService = window.client.service('services/data-tasks/action');
  }

  componentDidMount() {
    if (!this.state.processDefinitionsById) {
      this.loadProcessDefinitions().catch(this.handleError);
    }
  }

  async loadProcessDefinitions() {
    this.processDefinitions = await this.dataProcessDefinitionService.find();
    this.setState({ processDefinitionsById: _.keyBy(this.processDefinitions, 'processType') });
  }

  async finishTask(outcomeName) {
    await this.saveChanges();

    await this.dataTaskActionService.create({taskId: this.props.obj._id, taskOutcome: outcomeName}).catch(this.handleError)

    this.props.onCancel();
  }

  getEditorUi(changedObject) {
    const { _id, impact, processType, typeId, context, input, output, notes, doer, state, startedAt, finishedAt, createdAt, updatedAt } = changedObject;

    const { processDefinitionsById } = this.state;

    let inputs = null;
    let finishTaskBtns = null;
    let taskOutputsEditor = null;

    const processDef = (processDefinitionsById || {})[processType];
    if (processType && processDef) {
      const task = _.find(processDef.tasks, { id: typeId });
      if (task) {
        const possibleOutputs = _.compact(_.uniq(_.flatten(_.map(task.outputs, 'data'))));

        const dataTypesByName = _.keyBy(processDef.dataTypes, 'name');

        inputs = _.map(input, (inputValue, inputName) => {
          const typeDef = _.find(processDef.dataTypes, ({ name }) => name === inputName);
          return  <div key={inputName} className={`row align-items-center rounded no-gutters mb-1`}>
            <div className={`col-3 text-secondary text-right p-1`}>{inputName}:</div>
            <div className={'col-9 p-1'}>
              <GenericDataTypeSummary key={inputName} type={typeDef.type} value={inputValue}/>
            </div>
          </div>;
        });

        taskOutputsEditor = _.map(possibleOutputs, (name) => {
          const dataVal = (output || {})[name];
          let dataFieldClass = dataVal ? 'success' : 'secondary';
          return <div key={name} className={`row align-items-center rounded no-gutters mb-1 bg-light-${dataFieldClass}`}>
            <div className={`col-3 text-${dataFieldClass} text-right p-1`}>{name}:</div>
            <div className={'col-9 p-1'}>
              <GenericDataTypeEditor type={(dataTypesByName[name] || {}).type} value={dataVal}
                                     onChange={(val) => this.editField(`output.${name}`, val)}/>
            </div>
          </div>;
        });

        finishTaskBtns = <div className={'row no-gutters'}>
          {
            _.map(task.outputs, ({ name, data }) => {
              let requiredFields = null;

              let missingData = _.filter(data, dataField => !(output || {})[dataField]);
              if (missingData.length) {
                requiredFields = <div className={'text-danger small'}>
                  Requires <strong>{missingData.join(', ')}</strong>
                </div>;
              }

              let btnState = missingData.length || state ? 'outline-secondary' : 'primary';
              if(state && name === state) {
                btnState = 'success'
                requiredFields = <div className={'text-success small strong'}>Selected task output.</div>
              }

              return <div key={name} className={'col-sm p-2 text-center'}>
                <div className={''}>
                  <button disabled={missingData.length} onClick={() => this.finishTask(name)}
                    className={`btn btn-${btnState}`}>{name}</button>
                </div>
                {requiredFields}
              </div>;
            })
          }
        </div>;
      }
    } else {
      inputs = <JsonGUIEditor value={input} onChange={() => true}/>
      taskOutputsEditor = <JsonGUIEditor value={output} onChange={() => true}/>
    }


    return <table className={'table table-sm'}>
      <tbody>
      <tr>
        <td className={'text-center'}>
          <span className={'text-info'}>Impact {impact}</span>
        </td>

        <td className={'text-center'}>
          <span className={'h5 align-bottom'}>
            {typeId} <span className={'text-secondary'}> {processType}</span>
          </span>
        </td>
      </tr>

      <tr>
        <td className={'text-right'}>Context:</td>
        <td className={''}>
          <MultipleContextsEditor onChange={(json) => this.editField('context', json)} value={context}/>
        </td>
      </tr>

      {
        inputs ?
          <tr>
            <td key={'inputs'} style={{ width: '140px' }} className={'text-right'}>Inputs:</td>
            <td>
              {inputs}
            </td>
          </tr> : null
      }

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>Outputs:</td>
        <td>
          {taskOutputsEditor}
        </td>
      </tr>

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>Finish task:</td>
        <td>
          {finishTaskBtns}
        </td>
      </tr>

      </tbody>
    </table>;
  }
}
