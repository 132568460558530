import React from 'react';
import _ from 'lodash';

import LiveSubpage from "../../pages/LiveSubpage";
import LegoEditor from '../lego/LegoEditor';
import { Btn } from '../../../../components/common/Btn';

export default class TaskEditorLegoEdit extends LiveSubpage {
  constructor(props) {
    super(props);

    this.legoService = window.client.service('services/legos');

    this.state = {
      ...this.state
    };

    this.legoEditorRef = React.createRef();
  }

  async nextPdf() {
    this.props.batchEditor.next();
  }

  async previousPdf() {
    this.props.batchEditor.previous();
  }

  componentDidMount() {
    super.componentDidMount();
    console.log('loading task', this.props.task)
    this.loadLego(this.props.task).catch(alert);
  }

  async loadLego(task) {
    try {
      let lego = await this.legoService.get(task.input.legoId);

      if (lego) {
        this.setState({ lego });
      }
    } catch(err) {
      this.setState({error: err.toString()})
    }
  }

  async save(changedLego) {
    changedLego.updatedBy = this.getLoggedUserSignature();
    return this.legoService.update(changedLego._id, changedLego);
  }

  async saveAndNext() {
    if(this.legoEditorRef.current) {
      let editor = this.legoEditorRef.current;
      if(editor.state.changed) {
        await this.save(editor.state.changedObject);
        this.props.batchEditor.doneAndNext().catch(alert);
      } else {
        alert("The lego does not have unsaved changes")
      }
    }
  }

  renderExtractionControls() {
    // This method must be overriden by Subclasses
    return <div className={'p-2 pt-4 text-center'}>
      {
        <span className={'btn btn-primary'} onClick={() => this.runAsync(this.saveAndNext())}>
        Save changes and next
        </span>
      }
    </div>
  }

  renderPageBody() {
    let { lego, error } = this.state;

    return <React.Fragment>
      <div className={'grid-pdf-extraction'}>
        <div className={'bg-white'}>
          {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

          {lego ?
            <LegoEditor ref={this.legoEditorRef} lego={lego} onSave={async (changedLego, closeDialog) => {
              return this.runAsync(this.save(changedLego));
            }} onCancel={() => {
              this.legoEditorRef.current?.discardChanges()
            }}/>
            :
            <div className={'alert alert-info m-2'}>No lego loaded</div>}
        </div>

        <div className={'bg-light'} id={'extractionInputArea'} key={lego?._id}>
          {lego ? this.renderExtractionControls() : <div className={'m-3 text-center'}>No lego loaded</div>}

          <div className={'border border-left-0 border-right-0'}>
            { this.props.batchEditor.getTaskStateControls()}
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}
