import React from "react";
import {SwitchInput} from "../../common/SwitchInput";
import MultipleItemsEditor from "../../lego/MultipleItemsEditor";
import _ from "lodash";
import {Icon} from "../../common/Icon";
import {IconButton} from "../../common/IconButton";
import EventEmitter from "events";
import dayjs from "dayjs";
import ModalManager from "../../ModalManager";
import LegoContextSummary from "../../lego/LegoContextSummary";
import {LegoPreview} from "../../lego/LegoPreview";


export default class ManualFeaturesLegoEditor extends React.Component {
  constructor(props, title, booleanFeaturePath, legoSelector, emptyLegoTemplate) {
    super(props);

    this.title = title;
    this.booleanFeaturePath = booleanFeaturePath;
    this.legoSelector = legoSelector;
    this.emptyLegoTemplate = emptyLegoTemplate;

    this.modalActionsBus = new EventEmitter();

    this.state = {
      original: this.props.manual,
      changedObject: _.cloneDeep(this.props.manual),
      commonContext: true,
    };

    if (props.initialChanges) {
      // merge initial changes with object copy
      this.state.changedObject = _.defaultsDeep(props.initialChanges, this.state.changedObject);
    }

    this.legoService = window.client.service('services/legos');
  }

  toggleHasLegoData() {
    let updated = this.state.changedObject;
    const fieldPath = this.booleanFeaturePath;

    let value = !_.get(updated, fieldPath, false);
    _.set(updated, fieldPath, value);

    this.setState({ changedObject: updated });

    // If no data, try to remove default empty context
    if(value === false && this.state.commonContext) {
      if(this.state.changedLegos.length === 1 && _.isEmpty(this.state.changedLegos[0].data)) {
        this.updateLegos([]);
      }
    }

    this.checkChanges();
  }

  componentDidMount() {
    this.fetchLegos().catch(alert).then(() => this.checkChanges());
  }

  async fetchLegos() {
    let originalLegos = await this.legoService.find({
      query: {
        'source.id': this.props.manual._id,
        $sort: {updatedAt: 1},

        ... this.legoSelector
      }
    })

    let changedLegos = _.cloneDeep(originalLegos);

    let commonContext = false;
    if (originalLegos.length === 0) {
      if(_.get(this.state.changedObject, this.booleanFeaturePath) !== false) {
        // Autofill an empty template for each context in the manual
        changedLegos = [this.getEmptyLegoTemplate(this.useIndividualContext ? this.props.manual.context : [{}])]
      }

      // Use by default the manual's modelid/year as common context for new legos
      commonContext = !this.useIndividualContext;
    }

    // Only show models as common factor if all legos have exactly one context

    this.setState({ originalLegos, changedLegos, commonContext }, () => this.checkChanges());
  }

  getEmptyLegoTemplate(contexts) {
    return {
      context: contexts,
      locales: [window.config.locale],
      source: [{
        id: this.props.manual._id,
        type: "owner-manual"
      }],
      state: 'published',
      __new: true,

      ... this.emptyLegoTemplate
    };
  }

  updateLegos(newLegos) {
    this.setState({ changedLegos: newLegos }, () => this.checkChanges())

    // If a Lego is added/modified and manual.features.XXXXXXXX flag is undefined, set it as true by default
    // We are assuming the manual has the lego data
    const hasLegoFeature = _.get(this.state.changedObject, this.booleanFeaturePath);

    if(newLegos.length > 0 && hasLegoFeature === undefined) {
      this.toggleHasLegoData();
    }
  }

  checkChanges() {
    const manualFeaturesAreEqual = _.isEqual(this.state.changedObject, this.state.original);
    const legosAreEqual = _.isEqual(this.state.changedLegos, this.state.originalLegos);

    this.setState({ changed: !manualFeaturesAreEqual || !legosAreEqual });
  }

  discardChanges() {
    this.setState({ changedObject: _.cloneDeep(this.props.manual), changed: false })
    this.setState({ changedLegos: _.cloneDeep(this.state.originalLegos) })
  }

  restoreLego(_id) {
    let lego = _.find(this.state.originalLegos, { _id });
    if (lego && !_.find(this.state.changedLegos, { _id })) {
      const restored = _.cloneDeep(lego);
      this.updateLegos([restored, ...this.state.changedLegos]);
    }
  }

  expandCommonContext() {
    if (this.state.commonContext) {
      _.each(this.state.changedLegos, lego => {
        let expandedContext = [];
        _.each(this.manualContextNoTrims(), ctx => {
          _.each(lego.context, legoCtx => expandedContext.push({ ...ctx, ...legoCtx }));
        })
        lego.context = expandedContext;
      });
      this.updateLegos(this.state.changedLegos);
      this.setState({commonContext: false})
    }
  }

  async fetchRecentLegos(modelId) {
    const recentLegos = await this.legoService.find({
      query: {
        'source.type': 'owner-manual',
        $limit: 20,
        'context.modelId': modelId,
        $sort: {updatedAt: -1},

        ... this.legoSelector
      }
    })

    const grouped = {};
    for (const lego of recentLegos) {
      for (const source of lego.source ?? []) {
        const { id } = source;
        grouped[id] = grouped[id] ?? [];
        grouped[id].push(lego);
      }
    }
    return grouped;
  }

  async showRecentLegosModal() {
    const modelId = _.get(this.state.changedObject, 'context.0.modelId');
    let recentLegos = await this.fetchRecentLegos(modelId);

    this.modalActionsBus.emit('open', <div>
      <div className={'mt-2 p-1'}>
        <div className={'h5 text-success mb-1'}>Reuse recent lego for {modelId}:</div>
        {
          _.map(recentLegos, (legos, sourceId) => <div className={'border rounded m-2 row'} key={sourceId}>
            <div className={'col-auto p-2'}>
              <div>{dayjs(legos[0].updatedAt).fromNow()}</div>
              <div>
                <span className={'btn btn-sm btn-primary'} onClick={() => {
                  const clones = _.map(legos, ({data, context}) => {
                    // Discard the cloned modelId and year, deduplicate (maybe original lego had multiple years)
                    const cleanContext = _.uniqWith(_.map(context, ({modelId, year, ... other}) => other), _.isEqual)
                    return { ... this.getEmptyLegoTemplate(), data, context: cleanContext };
                  });
                  this.setState({changedLegos: clones, commonChanges: true}, () => this.checkChanges());
                  this.modalActionsBus.emit('close');
                }}>
                  Reuse legos
                </span>
              </div>
            </div>

            <div className={'col-8 p-2'}>
              {
                _.map(legos, lego => <div key={lego._id}>
                  <LegoContextSummary context={lego.context}/>
                  <LegoPreview lego={lego}/>
                </div>)
              }
            </div>
          </div>)
        }
      </div>
    </div>, {
      minSize: false,
      onClose: () => true
    });
  }

  getSingleLegoDataEditor(lego, props) {
    throw new Error("ManualFeaturesLegoEditor should implement 'getSingleLegoDataEditor(lego, props)' method")
  }

  getEditionControls(manualObj) {
    let hasData = _.get(manualObj, this.booleanFeaturePath);

    const { originalLegos, changedLegos } = this.state;

    if (changedLegos === undefined) {
      return <div>Loading existing legos...</div>;
    }

    let manualContexts = this.manualContextNoTrims();

    const newLegoTemplate = this.getEmptyLegoTemplate(this.state.commonContext ? [{}] : manualContexts);

    let commonContext = null;
    if(this.state.commonContext) {
      commonContext = <div className={'mb-2'}>
        <div className={'small mb-1'}>
          <IconButton size={'sm'} icon={'open_in_full'} onClick={() => this.expandCommonContext()}>
            Set model and year lego by lego
          </IconButton>
        </div>

        {_.map(manualContexts, (ctx, i) => <div key={i} className={'mb-1 text-info'}>
          <LegoContextSummary context={ctx}/>
        </div>)}

      </div>
    } else {
      commonContext = <div className={'mb-2 small'}>Each lego defines its modelId/year</div>
    }

    const legosEditor = <MultipleItemsEditor onChange={(json) => this.updateLegos(json)}
                                             value={changedLegos}
                                             defaultNewItem={newLegoTemplate}
                                             renderComponent={this.getSingleLegoDataEditor.bind(this)}/>;

    const markedForDeletion = _.filter(originalLegos, ({ _id }) => !_.find(changedLegos, { _id }));

    const toDeleteList = _.map(markedForDeletion, ({ _id }) => <div key={_id} className={'text-danger'}>
      <Icon icon={'delete_forever'} level={'danger'}/>
      <span className={'small'}>Will delete Lego <span className={'badge badge-danger'}>{_id}</span></span>
      <IconButton icon={'undo'} level={'primary'} onClick={() => this.restoreLego(_id)}/>
    </div>);

    return <React.Fragment>
      <div>
        {commonContext}
        {toDeleteList}
        {legosEditor}
      </div>
      <div className={'text-center'}>
        <SwitchInput value={hasData} className={'inline-block'} onChange={(json) => this.toggleHasLegoData()}>
          {this.state.featureName || 'Has data'}
        </SwitchInput>
      </div>
    </React.Fragment>;
  }

  manualContextNoTrims() {
    let manualContexts = this.props.manual.context;
    if (!_.isArray(manualContexts))
      manualContexts = [manualContexts];
    return _.map(manualContexts, ({trims, ... ctx}) => ctx);
  }

  thereAreNoPotentialMistakes(legosUpdates) {
    for(const lego of legosUpdates) {
      if((lego.__new && !lego._id) || lego.__unsaved) {
        if(_.isEmpty(lego.data)) {
          if(!confirm("You are creating an EMPTY Lego, are you REALLY sure?")) {
            return false;
          }
        }

        if(_.some(lego.context, c => !c.modelId || !c.year)) {
          if(!confirm("You have a context with no modelId or Year, are you REALLY sure?")) {
            return false;
          }
        }

        if(_.isEqual(lego, this.getEmptyLegoTemplate(this.props.manual.context))) {
          if(!confirm("You have the default lego with no modifications, are you sure you wanna save it?")) {
            return false;
          }
        }
      }
    }
    return true;
  }

  async saveLegos(markAsDone) {
      let legosCRUD = _.cloneDeep(this.state.changedLegos);
    // Expand lego's context if using common context
    _.each(legosCRUD, lego => {
      // Mark modified legos for update
      if(lego._id) {
        let original = _.find(this.state.originalLegos, {_id: lego._id});
        if(original && !_.isEqual(original, lego)) {
          lego.__unsaved = true;
        }
      }

      // Expand contexts [a,b,c...] with original manual contexts [x,y,...] => [ax,ay,bx,by,cx,cy ...]
      if (this.state.commonContext) {
        let expandedContext = [];
        _.each(this.manualContextNoTrims(), ctx => {
          _.each(lego.context, legoCtx => expandedContext.push({ ...ctx, ...legoCtx }));
        })
        lego.context = expandedContext;
      }
    });

    // Add deleted legos marked for deletion
    _.each(this.state.originalLegos, lego => {
      if(!_.find(legosCRUD, {_id: lego._id})) {
        legosCRUD.push({... lego, __deleted: true})
      }
    })

    // check for missing contexts or empty legos before saving anything
    if(!this.thereAreNoPotentialMistakes(legosCRUD)) {
      return;
    }

    let updatedManual = null;
    if(_.get(this.state.original, this.booleanFeaturePath) !== _.get(this.state.changedObject, this.booleanFeaturePath)) {
      updatedManual = this.state.changedObject;
    }

    await this.props.onSave(updatedManual, legosCRUD, markAsDone);

    if(updatedManual) {
      this.setState({ original: updatedManual, changedObject: _.cloneDeep(updatedManual) });
    }

    this.checkChanges();

    await this.fetchLegos();
  }

  render() {
    let manual = this.state.changedObject;
    let { _id, context, createdAt, updatedAt, __unsaved, __new, __deleted } = manual;

    let editTime = '';
    if (updatedAt) {
      editTime = ((updatedAt === createdAt) ? 'Created ' : 'Edited ') + dayjs(updatedAt).fromNow();
    }

    return <div className={`p-2 ${this.state.changed ? 'bg-light-warn' : ''}`}>
      <div className={'row no-gutters'}>
        <div className={'col-6 h5'}>{ this.title }</div>
        <div className={'col-6 small'}>
          <div className={'text-secondary small'}>{_id}</div>
          <div className={'text-info ml-2 mr-2'} title={updatedAt}>{editTime}</div>
        </div>
      </div>
      <hr className={'line'}/>

      <div className={'mt-2 bg-light rounded p-2'}>{this.getEditionControls(manual)}</div>

      <div className={'mt-2'}>
        <button disabled={!this.state.changed} onClick={() => this.saveLegos(true)} className={'btn btn-sm btn-primary'}>
          Save and Next
        </button>

        <button disabled={!this.state.changed} onClick={() => this.discardChanges()}
                className={'btn btn-sm btn-link ml-1'}>Discard changes
        </button>

        <button disabled={!this.state.changed} onClick={() => this.saveLegos(false)}
                className={'btn btn-sm btn-link ml-1'}>Save
        </button>
      </div>

      {
        this.showRecentLegosBtn ?
          <div className={'text-center'}>
            <IconButton icon={'history'} onClick={() => this.showRecentLegosModal()}>
              Reuse recent lego...
            </IconButton>
          </div>
          : null
      }

      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
