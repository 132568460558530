import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import ImageClusterPreview from './ImageClusterPreview';
import { IconButton } from '../common/IconButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import { ImagePreview } from './ImagePreview';
import GridLayoutLeftBar from '../common/layout/GridLayoutLeftBar';


const pageMax = 50;
export default function ImageClusterFinder({ sampleImage, clusterType, onClusterSelected, vehicleContext, sortByYear }) {
  const { page } = useContext(LegoAdminPageContext);

  const [context, setContext] = useState(vehicleContext);
  const [clusters, setClusters] = useState(null);

  useAsyncEffect(async () => {
    const clustersService = page.service('/services/data/image-clusters');

    let query = { clusterType, 'context.modelId': context.modelId, $limit: pageMax+1 };

    if(context.make) {
      query['context.modelId'] = {$regex: "^"+context.make+'-'}
    }

    if(query['context.modelId'] && context.year) {
      query['images.vehicle.year'] = context.year;
    }

    console.log('CONTEXT', context)

    await page.runAsync(clustersService.find({ query }).then(res => setClusters(res)));
  }, [JSON.stringify([context])]);

  let more = null;

  let clusterList = <div className={'text-center p-3'}>Loading clusters for {context.modelId}...</div>;
  if (clusters) {
    if (clusters.length) {
      let sortedClusters = _.sortBy(clusters, c => {
        if(sortByYear)
          {
            let closestYearDiff = _.min(_.map(c.images, img => Math.abs((img.vehicle?.year || 0) - parseInt(sortByYear))));
            console.log('CLOSEST YEAR DIFF ', closestYearDiff)
            return  closestYearDiff + 0.5/c.totalImages;
          }
        else
          return -c.totalImages;
      });

      clusterList = _.map(sortedClusters.slice(0, pageMax), c => <div key={c._id}>
        <IconButton icon={'west'} onClick={() => onClusterSelected(c)}/>
        <ImageClusterPreview key={c._id} clusterId={c._id} preloadedCluster={c}/>
      </div>);
    } else {
      clusterList = <div className={'text-center p-3'}>No clusters found for {context.modelId} 😢</div>;
    }

  }

  if(clusters && clusters.length === (pageMax+1)) {
    more = <div className={'alert alert-warning'}>Only showing first {pageMax} clusters</div>;
  } else {

  }


  return <div>
    <GridLayoutLeftBar className={'bg-light'} style={{height: 'calc(100% - 42px)'}}>
      { sampleImage ?
      <div className={''} style={{ width: '400px' }}>
        <div style={{ width: '400px' }}>
          { sampleImage ? <ImagePreview fluidContain url={sampleImage} zoom/> : <div className={'text-center mt-4'}>No sample image</div> }
        </div>
      </div>
      :
      null }

      <div className={''}>
        <div className={''}>
          <div className={'bg-light p-1'}>
            <ModelContextEditor value={context} onChange={setContext}/>
            { sortByYear ? <div className={'alert alert-info m-2 p-1'}>Sorting by distance to year <strong>{sortByYear}</strong> and then image count</div> : null }
          </div>
          {more}
          <div className={'bg-white'}>{clusterList}</div>
        </div>
      </div>
    </GridLayoutLeftBar>
  </div>;
}
