import React, { useContext, useState } from 'react';

import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import _ from 'lodash';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import urls from '../../../../../components/interop/urls';
import { ImagePreview } from '../../images/ImagePreview';
import PDFHtmlViewer from '../../pdf-tools/PDFHtmlViewer';
import CountryFlag from '../../common/CountryFlag';
import SingleManualViewer from '../../../pages/SingleManualViewer';
import { Icon } from '../../common/Icon';
import __ from '../../../../../components/interop/i18n';
import { IconButton } from '../../common/IconButton';
import { getCountryCode, getCountryName } from '../../../../../components/interop/countryCodeConverterFront';

const GLOBAL = 'Global';

function groupByYearCountry(objectsWithContext, currentModelId) {
  let byYearCountry = {};
  for (const obj of (objectsWithContext || [])) {
    for (const c of obj.context) {
      let { year, modelId, country, ...other } = c;

      let countries = country || (obj.country?.length ? obj.country : [GLOBAL]);
      if (_.isString(countries)){
        countries = [countries]
      }
      for (const countryData of countries) {
        let country = getCountryCode(countryData)
        const yearKey = year + (_.isEmpty(other) ? '' : ' ' + (_.values(other).join(' ')));
        let boxes = byYearCountry[yearKey]?.[country] || [];
        byYearCountry[yearKey] = byYearCountry[yearKey] || {};
        byYearCountry[yearKey][country] = [...boxes, obj];
      }
    }
  }
  return byYearCountry;
}


function ManualBlock({ m, manuals }) {
  const { page } = useContext(LegoAdminPageContext);
  const openManual = () => page.openModal(<SingleManualViewer manualId={m._id}
                                                              initialSearch={/\b(fusible|fuse)/.source}/>, { title: `Manual ${m._id}`, noPadding: true });

  const { _id, covered, index, olderVersionOf, metadata, manualPdfUrl } = m;

  const color = covered ? 'success' : (olderVersionOf ? 'warning' : 'primary');

  let display = <>Manual {index + 1}</>;

  if (olderVersionOf && manuals[olderVersionOf]) {
    display = `Older ver. of ${manuals[olderVersionOf].index + 1}`;
  }

  let coverImgSrc = null;
  if (metadata) {
    let { hash, pages, s3Bucket } = metadata;
    coverImgSrc = PDFHtmlViewer.generatePageImgUrl(hash, 'highres', 1, pages, s3Bucket);
  }

  return <span className={'mr-1 inline-block align-middle badge badge-' + color}>
    <div className={'mb-1'}>
      <span onClick={openManual} target={'_blank'} className={'text-white btn btn-xs'}>{display}</span>
    </div>
    {coverImgSrc ? <ImagePreview src={coverImgSrc} img-100 cover zoom={true}/> : null}
  </span>;
}


function FuseboxBlock({ boxIndex, lego, modelo, yearRow }) {
  //match witch context use to generate the fusebox link. Spliting yearRow by ' ' and ',' to handle diverse trims types.
  const selectedContext = _.filter(lego.context, c => _.every(yearRow.split(/[ ,]/), other => JSON.stringify(c).includes(other)))
  const year = selectedContext[0].year
  const other = _.omit(selectedContext[0], 'modelId', 'year');
  const fuseboxUrl = urls.urlFuseboxThumbnail(modelo, year, other, boxIndex);
  const opinautosUrl = `https://${__('global.opinautosDomain')}${urls.urlInfoFusebox(modelo, year, lego._id.toString())}#anchorfusebox${boxIndex}`;
  const editUrl = `/legos/fusebox?editing="${lego._id}"`;

  return <span className={'m-05 inline-block align-middle'}>
        <ImagePreview img-100
                      zoom={true}
                      src={fuseboxUrl}/>
        <div className={'zoom-75'}>
          <a href={opinautosUrl} target={'_blank'} className={'mr-3'}><Icon icon={'share'}/></a>
          <a href={editUrl} target={'_blank'}><Icon icon={'edit'}/></a>
        </div>
      </span>;
}

export default function ModelFuseboxesManualsTable({ modelId, year }) {
  const { page } = useContext(LegoAdminPageContext);

  const legosService = page.service('/services/legos');
  const manualsService = page.service('/services/data/manuals');

  const fetchFuseboxes = () => {
    let query = { type: 'fusebox', 'context.modelId': modelId, $select: { fuses: false } };
    page.runAsync(legosService.find({ query }).then(res => {
      _.each(res, (fbx, i) => {
        fbx.country = fbx.country || [];
        fbx.context = _.filter(fbx.context, c => c.modelId === modelId);
      });
      return setFuseboxes(res);
    }));
  };

  const fetchManuals = () => {
    let query = { 'context.modelId': modelId, manualCategory: 'MANUAL' };
    page.runAsync(manualsService.find({ query }).then(res => {
      // Give each manual a unique index
      _.each(res, (m, i) => {
        m.index = i;
        m.context = _.filter(m.context, c => c.modelId === modelId);
      });

      return setManuals(res);
    }));
  };

  const [fuseboxes, setFuseboxes] = useState(null);
  const [manuals, setManuals] = useState(null);
  const [reducedYears, setReducedYears] = useState(true)

  useAsyncEffect(fetchFuseboxes, [modelId]);
  useAsyncEffect(fetchManuals, [modelId]);

  let [make, ...modelParts] = modelId.split('-');
  let model = modelParts.join(' ');
  let modelo = {
    modeloUrlSafe: model.toLowerCase().replace(' ', '-'),
    marcaUrlSafe: make.toLowerCase()
  };

  // Mark manuals that are source of a lego
  let byId = _.keyBy(manuals, '_id');
  _.each(fuseboxes, fbx => _.each(fbx.source, s => {
    const sourceManual = byId[s.id];
    if (sourceManual) {
      sourceManual.covered = true;
      fbx.country = _.uniq([...fbx.country, ...sourceManual.country]);
    }
  }));

  let byYearBoxes = groupByYearCountry(fuseboxes || [], modelId);
  let byYearManuals = groupByYearCountry(manuals || [], modelId);
  let yearTrimKeys = _.union(_.keys(byYearManuals), _.keys(byYearBoxes));
  let min = _.min(yearTrimKeys.map(k => parseInt(k)));
  let max = _.max(yearTrimKeys.map(k => parseInt(k)));

  let moreDataAvailable = false
  if (reducedYears){
    if (min <= year -3 || max >= year +3){
      moreDataAvailable = true
    }
    min = year - 3
    max = year + 3
  }
  let yearsRange = _.range(min, max + 1).map(y => y.toString());
  let years = _.union(yearsRange, _.filter(yearTrimKeys, k => yearsRange.includes(k.slice(0,4)))).sort();
  let countries = _.uniq(_.flatten(_.union(_.values(byYearManuals),_.values(byYearBoxes)).map(o => _.keys(o))));

  let expandedYearsControls = <span>
    <IconButton onClick={() => setReducedYears(!reducedYears)}>
      {reducedYears ? `Showing selected year +/- 3` : 'Showing all years containing manuals/fuseboex' }
      {reducedYears && moreDataAvailable ? <span><Icon icon={'warning'}/>more manuals/fusebox available for other years</span> : null}
    </IconButton>
  </span>

  let countriesHeader = <tr key={'header'} className={'bg-white sticky-top'}>
    <th className={'bg-light-violeta'}>Year</th>
    <th className={'bg-light-violeta'}>Trim</th>
    {countries.map(c => <th className={'bg-light-violeta'} key={c}><CountryFlag country={c}/> {getCountryName(c)} </th>)}</tr>;

  let lastRowYear = null;
  let yearRows = [countriesHeader].concat(_.map(years, (y) => {
    const justYear = y.slice(0, 4);
    const isSelectedYear = parseInt(justYear) === year

    let countryColumns = _.map(countries, country => {
      let data = _.map(byYearBoxes[y]?.[country], lego => _.map(lego.data, (fbx, i) => <FuseboxBlock key={lego._id + i}
                                                                                                     modelo={modelo}
                                                                                                     lego={lego}
                                                                                                     yearRow={y}
                                                                                                     boxIndex={i}/>));

      data = data.concat(_.map(byYearManuals[y]?.[country], m => <ManualBlock key={m._id} m={m} manuals={byId}/>));

      const background = isSelectedYear ? 'bg-light-ladrillo' : (data.length ? 'bg-white' : 'bg-light-secondary');
      return <td key={country} className={`p-0 ${background}`}>{data}</td>;
    });
    const previousYear = lastRowYear;
    lastRowYear = justYear;
    let sameYearRowsCount = _.filter(years, y => y.startsWith(justYear)).length;
    const yearBackground = isSelectedYear ? 'bg-light-ladrillo' : 'bg-light-violeta'

    return <tr key={y} className={'bg-white'}>
      {justYear !== previousYear ? <th className={`text-left ${yearBackground} pl-3 align-middle`}
                                       style={{ width: '100px' }}
                                       rowSpan={sameYearRowsCount}
      >{justYear}</th> : null}

      <th className={`text-left ${yearBackground} pl-3 align-middle`}
          style={{ width: '100px' }}>{y.replace(/^\d+/, '')}</th>
      {countryColumns}
    </tr>;
  }));

  return <div>
    {expandedYearsControls}
    <table className={'table table-sm table-bordered table-secondary text-center'}>
    <tbody>{yearRows}</tbody>
  </table></div>;
}
