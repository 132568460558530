import GlobalDataCache from './GlobalDataCache';
import _ from 'lodash'

let globalCache = { };

export default class SingleLegoDataCache extends GlobalDataCache {
  constructor(legoQuery, onUpdateCbk = null) {
    super();

    this.query =  legoQuery;
    this.queryId = JSON.stringify(legoQuery);

    if(onUpdateCbk) {
      this.onDataUpdate(onUpdateCbk)
    }
  }

  async loadData() {
    if(!globalCache[this.queryId]) {
      const [lego] = await client.service('/services/legos').find({query: this.query});

      if (lego) {
        globalCache[this.queryId] = lego;
      } else {
        console.error(`Single lego cache: Could not find a lego matching query ${JSON.stringify(this.query)}`);
      }
    }
    return globalCache[this.queryId]
  }
}

export class OilNormsDataCache extends SingleLegoDataCache {
  constructor(onDataUpdate) {
    super({type: 'list', intentions: 'definition', semantics: 'ACEITE_MOTOR'}, onDataUpdate);
  }
}

export class OilViscositiesDataCache extends SingleLegoDataCache {
  constructor(onDataUpdate) {
    super({type: 'list', intentions: 'definition', semantics: 'VISCOSIDAD_ACEITE_MOTOR'}, onDataUpdate);
  }
}
