import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {prettyDuration} from "../components/common/TimeUtils"
import submenu from './menus/submenu-tasks'

dayjs.extend(relativeTime);

import CRUDPage from './CRUDPage';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import DataProcessEditor from '../components/dataprocess/DataProcessEditor';
import DataTaskEditor from '../components/dataprocess/DataTaskEditor';
import { GenericDataTypeSummary } from '../components/dataprocess/GenericDataTypeSummary';
import { LegoStateSwitch } from '../components/lego/LegoStateSwitch';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import { DataTaskTypeSelector } from '../components/tasks/DataTaskTypeSelector';
import { VehicleSummary } from '../components/common/VehicleSummary';
import MongoDocEditor from '../components/lego/MongoDocEditor';

export default class DataTasksManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data-tasks');

    this.submenu = submenu;

    this.dataProcessDefinitionService = this.service('services/data-process-definition');
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this.state.processDefinitionsById) {
      this.loadProcessDefinitions().catch(this.handleError);
    }
  }

  async loadProcessDefinitions() {
    this.processDefinitions = await this.dataProcessDefinitionService.find();
    this.setState({ processDefinitionsById: _.keyBy(this.processDefinitions, 'processType') });
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Id', className: '' },
      { content: 'Doer', className: '' },
      { content: 'Impact', className: '' },
      { content: 'State', className: 'text-center' },
      { content: 'Task', className: '' },
      { content: 'Context', className: '' },
      { content: 'State', className: 'text-center' },
      { content: 'Summary', className: 'text-center' },
    ];
  }

  getFacetFiltersComponent() {
    let facetFilters = this.state.facetFilters || {};
    let { context } = facetFilters;

    let onContextChange = (newContext) => {
      if (_.isEmpty(newContext)) {
        delete facetFilters.context;
      } else {
        facetFilters.context = _.cloneDeep(newContext);
      }
      this.onFiltersChange(facetFilters);
    }

    const onTypeChange = (f) => this.onFiltersChange({... facetFilters, typeId: f});

    return <div className={'d-flex align-items-center'}>
      <span style={{ width: '250px'}} className={'zoom-90'}>
        <DataTaskTypeSelector  value={facetFilters.typeId} onChange={onTypeChange}/>
      </span>

      <span style={{ width: '450px', display: 'inline-block' }} className={'zoom-75 ml-2'}>
        <ModelContextEditor value={context} onChange={(newContext) => onContextChange(newContext)}/>
      </span>
    </div>
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <MongoDocEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>;
  }

  async startTask(task) {
    task.startedAt = new Date();
    await this.views.update(task._id, task);
    this.openEdit(task)
  }

  getObjectColumns(task, definition) {
    let { _id, impact, context, processType, typeId, batchId, input, output, notes, doer, state, startedAt, finishedAt, workedTotalTime, createdAt, updatedAt } = task;


    let realState = 'Not started';
    let extraStateData = null;
    let stateStyle = 'bg-light-secondary';
    let taskQuickActions = null;

    if (startedAt) {
      if (finishedAt) {
        realState = 'Finished';
        stateStyle = 'bg-light-success';
        extraStateData = <div className={'small text-info'}>In {prettyDuration(workedTotalTime)} </div>;
      } else {
        realState = 'In progress';
        stateStyle = 'bg-light-primary';
        extraStateData = <div className={'small text-secondary'}>Since {dayjs(startedAt).fromNow()}</div>;
      }
    } else if(state === 'complete'){
      realState = 'Finished';
      stateStyle = 'bg-light-success';
    } else {
      taskQuickActions =
        <span className={'btn btn-sm btn-success'} onClick={(e) => e.stopPropagation() + this.startTask(task)}>Start Task</span>;
    }

    if(state === 'needsRevision') {
      stateStyle = 'bg-light-warn';
    }

    let inputSummary = _.map(input, (data, dataKey) => {
      if (this.state.processDefinitionsById && this.state.processDefinitionsById[processType]) {
        const processDef = this.state.processDefinitionsById[processType];
        const typeDef = _.find(processDef.dataTypes, ({ name }) => name === dataKey);
        return <GenericDataTypeSummary key={dataKey} type={typeDef.type} value={data}/>;
      } else {
        return <span key={dataKey}>{dataKey}</span>;
      }
    });

    let outputSummary = _.map(output, (data, dataKey) => {
      if (this.state.processDefinitionsById && this.state.processDefinitionsById[processType]) {
        const processDef = this.state.processDefinitionsById[processType];
        const typeDef = _.find(processDef.dataTypes, ({ name }) => name === dataKey);
        return <GenericDataTypeSummary key={dataKey} type={typeDef.type} value={data}/>;
      } else {
        return <span key={dataKey}>{dataKey}</span>;
      }
    });

    return [
      <td key={'id'}>
        <div>
          <span className={'text-info small'} title={updatedAt}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</span>
        </div>
        <div className={'text-secondary small'}>{_id}</div>
      </td>,

      <td key={'doer'}>{doer}</td>,

      <td key={'impact'} className={'text-center'}>{(impact && impact.toFixed(1)) || null}</td>,

      <td key={'finishedState'} className={`text-center ${stateStyle}`}>
        <span className={'text-secondary'}>{realState}</span>
        {extraStateData}
      </td>,

      <td key={'processName'} className={`${stateStyle}`}>
        <strong className={'text-primary'}>{typeId}</strong>
        <div className={'small text-secondary'}>{processType || batchId}</div>
      </td>,

      <td key={'ctx'} className={`context-column ${stateStyle}`}><LegoContextSummary context={context}/></td>,

      <td key={'state'} className={`context-column ${stateStyle} text-center`}>
        {state ? <span className={'badge badge-secondary'}>{state}</span> : null}
      </td>,

      <td key={'summary'} className={'cursor-pointer'} onClick={() => this.openEdit(task)}>
        <div className={'row align-items-center'}>

          <div className={'col-5'}>
            <span className={'text-left align-middle'}>{inputSummary}</span>
          </div>

          <div className={'col-1'}>
            <i className={'material-icons align-middle mr-2 ml-2'}>arrow_right_alt</i>
          </div>

          <div className={'col-6'}>
            <span className={'align-middle'}>{taskQuickActions}</span>
            <span className={'text-left align-middle'}>{outputSummary}</span>
          </div>
        </div>
      </td>
    ];
  }
}
