import React, {useState, useEffect} from 'react';

export function useAllNotes(notesManager) {
  // Try to get cache synchronously to avoid preventable flashes in the UI
  const [allNotes, setAllNotes] = useState(notesManager.cacheLoaded() ? notesManager.allCached() : {});

  useEffect(() => {
    // Done this way as doing it with async every time had a HUGE overhead in performance, measured with Chrome devtools
    if (notesManager.cacheLoaded()) {
      setAllNotes(notesManager.allCached());
    } else {
      notesManager.all().then(all => setAllNotes(all));
    }

    // useState won't re-render if the object does not change, so create copy of cache
    const onNoteEventHandler = (notes) => setAllNotes({ ...notesManager.cache });

    notesManager.addListener('__ALL__', onNoteEventHandler);

    return () => notesManager.removeListener('__ALL__', onNoteEventHandler)
  }, [notesManager])

  return allNotes;
}
