import React, { useState } from 'react';
import LegoContextSummary from '../lego/LegoContextSummary';
import { ImagePreview } from './ImagePreview';
import { ImageClustersEditor } from './ImageClustersEditor';
import GridLayoutLeftBar from '../common/layout/GridLayoutLeftBar';
import { VehicleSummary } from '../common/VehicleSummary';
import _ from 'lodash';
import TopBarLayout from '../common/layout/TopBarLayout';

export function ImageClusterSelector({ context, clusterType, workingImage, workingTemplateId, templates, onClusterChange, dataFeedbackId, ...props }) {

  let [selectedImage, setSelectedImage] = useState({ id: workingImage._id, vehicle: context });

  const [editMode, setEditMode] = useState(false);

  const handleImgSelect = img => {
    if (editMode) {
      setSelectedImage(img);
    }
  };

  const changeEditMode = () => {
    setEditMode(!editMode);
    if (editMode) {
      setSelectedImage({ id: workingImage._id, vehicle: context });
    }
  };

  let modelYearContext = _.pick(context, 'modelId', 'year');

  return <GridLayoutLeftBar className={'bg-light'}>
    <div className={`${selectedImage.id !== workingImage._id ? '' : 'bg-light-primary'}`} style={{ width: '500px' }}>
      <TopBarLayout>
        <div className={'bg-light-primary p-2'}>
          <VehicleSummary vehicle={context}/>
        </div>

        <div className={'full-screen-center p-2 bg-light-primary'}>
          <ImagePreview fluidContain url={workingImage.url} zoom/>
        </div>
      </TopBarLayout>

    </div>

    <div>
      <ImageClustersEditor selectedImage={selectedImage}
                           context={modelYearContext}
                           clusterType={clusterType}
                           onClusterChange={onClusterChange}
                           editMode={editMode}
                           templates={templates}
                           workingTemplateId={workingTemplateId}
                           dataFeedbackId={dataFeedbackId}
                           onImgSelect={handleImgSelect}
                           onChangeEditMode={changeEditMode}/>
    </div>
  </GridLayoutLeftBar>;
}
