import React, { Component } from 'react';

export class IconButton extends Component {
  render() {
    let { icon, fill, level, size, description, className, children, ... other } = this.props;

    let sizes = {
      'sm': 'small',
      'lg': 'h2 m-0',
      'xl': 'h1 m-0',
    }
    let sizeClass = size ? (sizes[size] || '') : '';

    const content = <><i
      className={`material-icons align-middle text-${fill ? 'white' : level || 'default '} ${sizeClass} ${children ? 'mr-1' : ''}`}>
      {icon}
    </i>
      {children ? <span className={'align-middle'}>{children}</span> : null }
    </>;

    let fillStyle = fill ? `btn-${level || 'primary'} text-white` : `${!other.href ? 'btn-link' : ''} text-${level || 'primary'}`

    if(other.href) {
      return <a {...other} className={`${fillStyle} ${className || ''}`} title={description || other.title}>
        {content}
      </a>
    } else {
      return <button {...other} className={`btn btn-xs ${fillStyle} ${className || ''}`}
                     type='button' title={description || other.title}>
        {content}
      </button>
    };
  }
}
