import React from 'react';

import LivePage from './LivePage';

import { MailConversationsList } from '../components/common/MailConversationsList';
import MailConversation from '../components/common/MailConversation';
import { FuseboxTemplateFinder } from '../components/lego/fusebox-editor/FuseboxTemplateFinder';
import { ImagePreview } from '../components/images/ImagePreview';
import _ from 'lodash';
import SingleTextEditor from '../components/common/editors/SingleTextEditor';
import FiltersBar from '../components/common/filters-bar/FiltersBar';
import SingleValueSelector from '../components/common/editors/SingleValueSelector';
import { IconButton } from '../components/common/IconButton';

export default class TestPerceptualHash extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    this.state = {
      ...this.state,
      phash: 'vh/BwOEpLh4=',
      url: '',
      category: 'warning-lights'
    };

    this.fullScreen = true;
    this.menu = [];
    this.submenu = [];

    this.searchSvc = this.service('services/data/image-search');
    this.imgsSvc = this.service('services/data/images');

  }

  componentDidMount() {
    super.componentDidMount();
    this.search();
  }

  async search() {
    const {category, phash, url} = this.state;
    try {
      const res = await this.searchSvc.find({
        query: {
          category, perceptualHash: phash, url, $limit: 100
        }
      })
      this.setUrlParams({phash, url, category})
      this.setState({ res, error: null });
    } catch(err) {
      this.setState({error: err.toString()})
    }
  }

  async moreImageInfo(imageId) {
    const img = await this.imgsSvc.get(imageId);
    this.openModal(<div>
      <pre>{JSON.stringify(img, true, 2)}</pre>
    </div>)
  }

  renderPageBody() {
    let { res, phash, category, url, error } = this.state;

    const setHash = (h) => this.setState({ phash: h }, () => this.search())
    const setUrl = (h) => this.setState({ url: h }, () => this.search())
    const onChangeCategory = (cat) => this.setState({ category: cat }, () => this.search());

    const categories = [
      "car-profile",
      "dashboard-overview",
      "fusebox",
      "fusebox-diagram",
      "fusebox-picture",
      "fusebox-table",
      "generation-picture",
      "lego",
      "tire-pressure",
      "warning-lights"
    ]


    return <div className={'margin'}>
      <FiltersBar>
        <span style={{width: '200px'}}>
          Category: <SingleValueSelector value={category} options={categories} onChange={onChangeCategory} />
        </span>

        <div>Perceptual hash: <SingleTextEditor small value={phash} onChange={setHash}/></div>

        <div style={{width: '300px'}}>Url: <SingleTextEditor small value={url} onChange={setUrl}/></div>
      </FiltersBar>

      {error ? <div className={'alert alert-danger'}>{error}</div> : null}

      {_.map(res, ({ _id, url, perceptualHash, score }) => {
        return <span key={_id} className={'inline-block border'}>
          <ImagePreview url={url} img200 contain onClick={(e) => setHash(perceptualHash)} zoom/><br/>
          <div className={'texte-center small text-info'}>{score}</div>
          <div className={'texte-center small text-danger monospace'}>
            {perceptualHash}
            <IconButton icon={'info'} onClick={(e) => e.preventDefault() + this.moreImageInfo(_id)}/>
          </div>
        </span>;
      })}
    </div>
  }
}
