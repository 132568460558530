import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';

// Global store to hold data and subscribers
const globalStore = {
  data: null,
  subscribers: [],
};

// Function to notify all subscribers about data changes
function notifySubscribers() {
  globalStore.subscribers.forEach(setData => setData(globalStore.data));
}

export default function useModelsData(query, defaultData) {
  let { page } = useContext(LegoAdminPageContext);

  const [data, setData] = useState(globalStore.data || {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching models data...');
        let res = await page.service('/services/data/models').find();
        globalStore.data = res; // Store data in the global static variable.
        notifySubscribers(); // Notify all subscribers about data changes
      } catch (err) {
        console.error(err);
      }
    };

    if (!globalStore.data) {
      globalStore.data = {};
      // Only add listener if data hasn't been fetched yet.
      page.service('/services/data/models/gens').on('generations-changed', fetchData);

      // Make initial fetch of data
      fetchData();
    }

    // Register setData as a subscriber
    globalStore.subscribers.push(setData);

    return () => {
      // This will be called even if most users did not hook to the event, it's harmless
      page.service('/services/data/models/gens').removeListener('generations-changed', fetchData);

      // Unregister setData when component unmounts
      globalStore.subscribers = globalStore.subscribers.filter(func => func !== setData);

      if (!globalStore.subscribers.length) {
        globalStore.data = null;
      }
    };
  }, []);

  return data;
}
