import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import React from 'react';
import { TimeFrom } from './TimeFrom';

export default class TaskInProgressWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onTabActivity = this.onTabActivity.bind(this);
  }

  onTabActivity(event) {
    switch(event.data.event) {
      case 'taskInProgress':
        this.setState({taskInProgress: event.data})
        clearTimeout(this.clearStateTimeout);
        this.clearStateTimeout = setTimeout(() => this.setState({taskInProgress: null}), 5000);
        break;
      case 'taskInProgressPaused':
        clearTimeout(this.clearStateTimeout);
        this.setState({taskInProgress: null})
        break;
    }
  }

  componentDidMount() {
     this.context.page.tabsChannel.addEventListener('message', this.onTabActivity);
  }

  componentWillUnmount() {
    this.context.page.tabsChannel.removeEventListener('message', this.onTabActivity);
  }

  render() {
    if(this.state.taskInProgress) {
      const {taskInProgress} = this.state;

      return <div className={'global-task-in-progress'}>
        <span className={'small text-dark mr-1'}>Task in progress</span> <TimeFrom
        from={taskInProgress.workIntervalStart} extra={taskInProgress.workedTotalTime}/>
      </div>
    } else {
      return null;
    }
  }
}

TaskInProgressWidget.contextType = LegoAdminPageContext;
