import React from 'react';

import _ from 'lodash';
import CreatableSelect from 'react-select/lib/Creatable';

export default class MultiValueSelector extends React.Component {
  handleSelectChange(values, actionMeta) {
    this.props.onChange(_.map(values, 'value'));
  }

  getOption(props) {
    let { innerProps, isSelected, isFocused } = props;
    let { innerRef, ...filteredProps } = innerProps;
    return <div {...filteredProps} className={'p-1 ' + ((isSelected || isFocused) ? 'bg-light-primary' : '')}>
      {props.label}
    </div>;
  };

  getMultiValueLabel(props) {
    let level = _.includes(this.props.options, props.data.value) ? 'primary' : 'danger';
    return <span className={`badge badge-${level}`}>{props.children}</span>;
  };

  render() {
    const { onChange, value, options, placeholder, ...props } = this.props;

    const optionsObjects = _.map(options, (op) => {
      if (op.value && op.label) {
        return { label: op.label, value: op.value };
      } else {
        return { label: op.toString(), value: op };
      }
    });

    let valueOptions = _.map(value, v => _.find(optionsObjects, {value: v}) || { label: v.toString(), value: v });

    return (
      <CreatableSelect closeOnSelect={true}
              isMulti
              components={{
                Option: this.props.getOption || this.getOption.bind(this),
                MultiValueLabel: this.props.getValue || this.getMultiValueLabel.bind(this)
              }}
              onChange={this.handleSelectChange.bind(this)}
              options={optionsObjects}
              menuShouldScrollIntoView={false}
              placeholder={placeholder || ""}
              value={valueOptions}
              {...props}
      />
    );
  }
}

