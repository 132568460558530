import React, { Component } from 'react';
import _ from 'lodash';

import { IconButton } from '../common/IconButton';
import ModelContextEditor from './ModelContextEditor';

export default function MultipleContextsEditor({value, onChange}) {
  const getContexts = () => {
    let contextOrArray = value;
    if (!_.isArray(contextOrArray)) {
      contextOrArray = [contextOrArray || {}];
    }

    if (contextOrArray.length === 0) {
      contextOrArray = [{}];
    }

    return contextOrArray;
  };

  let contexts = getContexts();

  const addContext = () => onChange([...contexts, {}]);

  const updateContext = (index, newContext) => {
    const updated = [... contexts];
    updated[index] = newContext;
    onChange(updated);
  };

  const removeContext = ctx => onChange(_.without(contexts, ctx));

  const copy = (ctx) => onChange([... contexts, _.cloneDeep(ctx)]);

  return <div>
    {_.map(contexts, (ctx, i) => {
      return <div key={i} className={'d-flex mb-1'}>

        <div className={'flex-grow-1'}>
          <ModelContextEditor key={i} value={ctx} onChange={(ctx) => updateContext(i, ctx)}/>
        </div>
        <div className={''}>
          {
            i === contexts.length - 1 ?
              <IconButton onClick={addContext} level={'success'} icon={'add_circle'}
                          description={'Add context'}/> : null
          }

          {
            contexts.length > 1 ?
          <IconButton onClick={() => removeContext(ctx)} level={'danger'} icon={'remove_circle_outline'}
                      description={'Remove context'}/> : null
          }

          <IconButton onClick={() => copy(ctx)} level={'primary'} icon={'content_copy'}
                      description={'Copy'}/>
        </div>

      </div>;
    })}
  </div>;
}
