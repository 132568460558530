import _ from 'lodash'

let referenceTesterService = null;
const cache = {};

export default class LegoReferenceCache  {
  static async testReference(reference, context) {
    if(!referenceTesterService) {
      referenceTesterService = window.client.service('/services/lego-reference-tester');
    }

    let contextKey = JSON.stringify(context);

    let val = (cache[contextKey] || {})[reference];

    if(val) {
      return val;
    } else {
      let res = await referenceTesterService.get(reference, { query: { context: { model: context } } });
      cache[contextKey] = (cache[contextKey] || {})
      cache[contextKey][reference] = res;
      return res;
    }
  }
}
