import React, { useEffect, useState } from 'react';
import _ from 'lodash';

export default function ClipboardEnabled({children}) {
  if(window.navigator?.clipboard) {
    return <>{children}</>
  } else {
    return null;
  }
}
