import _ from "lodash";
import React from 'react';
import StatsTable from "./StatsTable";
import LegoAdminPageContext from '../../pages/legoAdminPageContext';

export default class StatsManuals extends StatsTable {
  async fetchData() {
    this.models = (await this.data.models()).models;
    this.modelsById = _.keyBy(this.models, 'modelId')

    this.pageviewsByModelId = (await this.data.traffic('ar')).trafficBreakdown.pageviewsByModelId

    this.manualsByModel = _.groupBy((await this.data.manuals()).manuals, m => m.idModelo);
  }

  groups() {
    return _.map(this.models, model => {
      return {
        model,
        manuals: this.manualsByModel[model.modelId] || []
      }
    })
  }

  groupKey({model}) {
    return model.modelId;
  }

  groupWeight(key, { model, manuals }) {
    return this.pageviewsByModelId[model.modelId];
  }

  groupCoverage(key, { model, manuals }) {
    return manuals.length ? 1 : 0
  }

  displayGroup(modelId, { model, manuals }) {
    const make = model.marca.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(' ', '-');
    const slug = (this.modelsById[modelId] || {}).slug;

    let manualsLink = manuals.length ? <span className={'small ml-1'}>
          <a target={'_blank'}
             href={`${window.config.opinautosUrl}/${make}/${slug}/info/manuales`}>{manuals.length} manuals</a>
        </span> : null;

    return <div>{modelId} {manualsLink}</div>;
  }
}
