import SingleTextEditor from '../common/editors/SingleTextEditor';
import { useContext, useEffect, useState } from 'react';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { IconButton } from '../common/IconButton';
import LegoEditor from './LegoEditor';
import LegoEditButton from './LegoEditButton';
import { LocalesList } from './LocalesList';

export default function LegoIdEditor({ value, onChange, warnDifferentLocale, className, ... otherProps }) {
  let {page} = useContext(LegoAdminPageContext);
  const legoService = page.service('services/legos');

  let [checkedIdMark, setCheckedIdMark] = useState('')
  let [lego, setLego] = useState(null);
  let background = lego ? 'bg-light-success' : (lego === false ? 'bg-light-danger' : '');
  const validMongoID = (value) => value && value.match(/^[0-9a-fA-F]{24}$/);

  useAsyncEffect(async () => {
    if(value) {
      if(validMongoID(value)) {
        setLego(undefined);
        let lego = await page.runAsync(legoService.get(value));
        if (lego) {
          setCheckedIdMark(<span className="text-success">✓</span>);
          setLego(lego);
        } else {
          setCheckedIdMark(<span className="text-danger">✗</span>);
          setLego(false);
        }
      } else {
        setCheckedIdMark(<span className="text-warning small">Invalid ID</span>);
        setLego(null);
      }
    } else {
      setCheckedIdMark('');
      setLego(null);
    }
  }, [value]);

  let localesWarning = null;
  if(warnDifferentLocale && lego && !lego.locales?.includes(window.config.locale)) {
    localesWarning = <LocalesList locales={lego.locales}/>;
  }

  return <div className={'d-flex align-items-center'}>
    {localesWarning }

    <div style={{width: '40px'}} className={'text-center'}>{checkedIdMark}</div>

    <SingleTextEditor value={value} {... otherProps}
                    className={background + ' mr-1 '+(className || '')}
                    onChange={(txt) => onChange(txt)}/>

    <div style={{width: '40px'}} className={'text-center'}>
      { <LegoEditButton legoId={lego?._id}/>}
    </div>
  </div>
}
