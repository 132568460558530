import MultipleContextsEditor from '../../lego/MultipleContextsEditor';
import React from 'react';
import ManualFeaturesEditor from './ManualFeaturesEditor';
import SingleTextEditor from '../../common/editors/SingleTextEditor';
import _ from 'lodash';
import dayjs from 'dayjs';
import ModalManager from '../../ModalManager';
import { UserAvatar } from '../../common/UserAvatar';
import MultiCountryEditor from '../../common/editors/MultiCountryEditor';
import { IconButton } from '../../common/IconButton';
import InputManualsMultipleSemantics from '../../common/editors/InputManualsMultipleSemantics';
import { LocalesSelector } from '../../lego/LocalesSelector';

export default class ManualUsersUploadEditor extends ManualFeaturesEditor {

  editField(fieldPath, newValue) {
    let updated = this.state.changedObject;
    _.set(updated, fieldPath, newValue);
    this.setState({ changedObject: updated });
    this.checkChanges();
  }

  getFeaturesEditionRows(manual) {
    return [

      <tr key={'context'}>
        <td className={'text-right'}>
          Context:
        </td>

        <td className={''}>
          <MultipleContextsEditor value={manual.context} onChange={(value) => this.editField('context', value)}/>
        </td>
      </tr>,

      <tr key={'language'}>
        <td className={'text-right'}>
          isoLanguage:
        </td>

        <td className={''}>
          <SingleTextEditor value={manual.isoLanguage || ''}
                            onChange={(isoLang) => this.editField('isoLanguage', isoLang.toUpperCase())}/>
        </td>
      </tr>,
      <tr key={'country'}>
        <td className={'text-right'}>
          Country:
        </td>
        <td className={''}>
          <MultiCountryEditor value={manual.country || []}
                              onChange={(countries) => this.editField('country', countries)}/>
        </td>
      </tr>,
      <tr key={'locales'}>
        <td className={'text-right'}>
          Locale:
        </td>
        <td className={''}>
          <LocalesSelector value={manual.locales} onChange={v => this.editField('locales', v)}/>
        </td>
      </tr>,
      <tr key={'manual-category'}>
        <td>Manual Category:</td>
        <td>
          <InputManualsMultipleSemantics value={manual.manualCategory || []}
                                  onChange={(semantics) => this.editField('manualCategory', semantics)}/>
        </td>
      </tr>,
      <tr key={'manual-sub-section'}>
        <td>Manual Subsection:</td>
        <td>
          <InputManualsMultipleSemantics value={manual.manualSubsection || []} compact={false}
                                  onChange={(semantics) => this.editField('manualSubsection', semantics)}/>
        </td>
      </tr>

    ];
  }

  renderTaskControls(manual) {
    const {manualCategory, moderationTag, context: [{ modelId, year }], isoLanguage, country, locales} = manual;

    let requiredFieldsOk = modelId && isoLanguage && locales.length && !moderationTag && manualCategory?.length

    if(manualCategory?.includes('MANUAL')) {
      requiredFieldsOk = requiredFieldsOk && year;
    }
//TODO: requiredFieldsOk could be replaced with 'services/data/manuals/upload' .find validation
    if (moderationTag) {
      return <div><span className={'col col-12 badge badge-success'}>moderationTag: {moderationTag}</span></div>;
    }

    if ((this.props.repeatedManual || '').length) {
      return <div className={'mt-2 zoom-75'}>
        <button disabled={!requiredFieldsOk}
                onClick={async () => {
                  let updatedManual = await this.props.onSave(manual);
                  // It could fail and return undefined
                  if (updatedManual) {
                    this.setState({
                      original: updatedManual,
                      changedObject: _.cloneDeep(updatedManual)
                    });
                    this.checkChanges();
                  }
                }} className={'btn btn-primary'}>
          Save
        </button>
        <button onClick={async () => this.props.onReject(manual, 'duplicated')}
                className={'btn btn-secondary ml-1'}>
          Mark as duplicated
        </button>
      </div>;
    } else {
      return <div className={'mt-2 zoom-75'}>
        <button disabled={!requiredFieldsOk}
                onClick={async () => await this.props.onApprove(manual)}
                className={'btn btn-primary'}>
          Approve
        </button>
        <button onClick={async () => this.props.onReject(manual, 'trash')}
                className={'btn btn-danger ml-1'}>
          Trash
        </button>
        <button onClick={async () => this.props.onReject(manual, 'not-manual')}
                className={'btn btn-secondary ml-1'}>
          Useful, not a manual
        </button>
      </div>;
    }

  }

  loadExistingManual(oldManual, newManual) {
    // Make union by serializing context to prevent duplicates (not using stringify directly due to order of object keys)
    let mergedContexts = _.unionBy(oldManual.context, newManual.context, ctx => JSON.stringify(_.sortBy(_.toPairs(ctx), '0')));

    let tempManual = { ...oldManual };
    if (mergedContexts.length !== oldManual.context.length) {
      if (confirm(`Se agregará: ${JSON.stringify(newManual.context[0], null, 2)}.`)) {
        tempManual.context = mergedContexts;
      }
    }
    _.each(tempManual, (value, path) => this.editField(path, value));
  }

  render() {
    let manual = this.state.changedObject;
    let {
      _id,
      createdAt,
      updatedAt,
      __unsaved,
      __new,
      __deleted,
      originalUploadUserId,
      originalUploadUserName,
      originalUploadName
    } = manual;
    let rowClasses = 'p-2 ';
    if (__new) {
      rowClasses += ' bg-light-success';
    }
    if (__unsaved) {
      rowClasses += ' bg-light-warning';
    }
    if (__deleted) {
      rowClasses += ' bg-light-danger';
    }

    if (this.state.changed) {
      rowClasses += 'bg-light-warn';
    }

    let editTime = '';
    if (updatedAt) {
      editTime = ((updatedAt === createdAt) ? 'Created ' : 'Edited ') + dayjs(updatedAt)
        .fromNow();
    }
    let repeatedManual = this.props.repeatedManual;
    if (repeatedManual.length !== 0) {
      repeatedManual =
        <div>{_.map(repeatedManual, (entry, i) =>
          <div key={i}>
            <a href={entry.manualPdfUrl} target={'_blank'} className={'badge badge-warning'}>
              Repeated:&nbsp;
              {_.map(entry.context, context => `${context.modelId}-${context.year}`)
                .join('; ')}</a>
            <IconButton className={'zoom-75 ml-1'} icon={'edit'}
                        onClick={() => {
                          this.loadExistingManual(entry, manual);
                        }}>Merge</IconButton>
          </div>
        )}
        </div>;
    }

    return <div className={rowClasses}>
      <div className={'row no-gutters'}>
        <div className={'col-6 small'}>
          <div className={'h6'}>Manual metadata</div>
        </div>
        <div className={'col-6 small'}>
          <div className={'text-secondary small'}>{_id}</div>
          <div className={'text-info ml-2 mr-2'} title={updatedAt}>{editTime}</div>
        </div>
      </div>
      <div className={'text-secondary small'}><UserAvatar user={{
        username: (originalUploadUserName || ''),
        id: originalUploadUserId
      }}/> {originalUploadName}</div>
      {repeatedManual}

      <div className={'mt-2 zoom-75'}>
        <table className={'table table-sm'}>
          <tbody>
          {this.getFeaturesEditionRows(manual)}
          </tbody>
        </table>
      </div>

      {this.renderTaskControls(manual)}
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;

  }
}
