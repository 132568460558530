import React from 'react';

import { UserAvatar } from './UserAvatar';
import useUser from './react-hooks/useUser';

export function UserAvatarLive({ id: userId, ...other}) {
  const userData = useUser(userId);

  if (userId?.startsWith('casual-')) {
    return <UserAvatar id={userId} {...other}/>;
  } else {
    if (userData) {
      return <UserAvatar user={userData} {...other}/>;
    } else if (userData === false) {
      return <UserAvatar id={userId} {...other}/>;
    } else {
      return <UserAvatar id={userId} {...other}/>;
    }
  }
}
