import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import _ from 'lodash'
import AutosizeInput from 'react-input-autosize';

class AutocompleteMagic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: this.buildSuggestions(this.props.text || ""),
      // Whether or not the suggestion list is shown
      showSuggestions: props.startClosed ? false : true,
      // What the user has entered
      userInput: this.props.text || ""
    };
  }

  onChange(e) {
    const userInput = e.currentTarget.value;

    this.rebuildSuggestions(userInput);

    if(this.props.onChange) {
      this.props.onChange(userInput)
    }
  };

  saveChange(enterPressed) {
    if(this.props.onSubmit) {
      this.props.onSubmit(this.state.userInput)
    }

    if(this.props.onChange) {
      this.props.onChange(this.state.userInput)
    }

    if(this.props.onEnter && enterPressed) {
      this.props.onEnter(this.state.userInput);
    }
  }

  rebuildSuggestions(userInput) {
    let filteredSuggestions = this.buildSuggestions(userInput);

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput
    });
  }

  buildSuggestions(userInput) {
    const { onGetOptions } = this.props;

    // Filter our suggestions that don't contain the user's input
    let filteredSuggestions = onGetOptions(userInput)

    filteredSuggestions = filteredSuggestions.slice(0, 30);

    // Make sure all suggestions have a label and a value
    filteredSuggestions = _.map(filteredSuggestions, s => s.value ? s : { label: s, value: s })
    return filteredSuggestions;
  }

  onClick(e, value) {
    this.setState({
      activeSuggestion: 0,
      showSuggestions: false,
      userInput: value
    });
    setTimeout(() => this.saveChange(), 1);
    // this.rebuildSuggestions(e.currentTarget.innerText);
  };

  onBlur() {
    this.setState({
      showSuggestions: false
    });
  }

  onKeyDown(e) {
    const {activeSuggestion, filteredSuggestions} = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      let input = this.state.userInput;

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: (input || activeSuggestion > 0) ? (filteredSuggestions[activeSuggestion] || {value: this.state.userInput}).value.trim() : '',
      });

      setTimeout(() => this.saveChange(true), 1);
    }

      // User pressed the up arrow
      if (e.keyCode === 38) {
        if (activeSuggestion === 0) {
          return;
        }

        this.setState({activeSuggestion: activeSuggestion - 1});
      }
      // User pressed the down arrow
      else if (e.keyCode === 40) {
        if (activeSuggestion - 1 === filteredSuggestions.length) {
          return;
        }

        this.setState({activeSuggestion: activeSuggestion + 1});
      } else if (e.key === 'Tab') {
        if(filteredSuggestions.length) {
          const selectedSuggestion = filteredSuggestions[activeSuggestion].value;
          this.setState({
            activeSuggestion: 0,
            showSuggestions: true,
            userInput: selectedSuggestion,
          });

          if(this.props.onChange) {
            this.props.onChange(selectedSuggestion)
          }

          this.rebuildSuggestions(selectedSuggestion);
        }
        e.preventDefault();
        return false;
      } else if (e.key === 'Escape') {
        if(this.props.onCancel) {
          if(this.props.onCancel() === false) {
            this.setState({showSuggestions: false})
          }
        }
      }
  };

  render() {
    let {activeSuggestion, filteredSuggestions, showSuggestions, userInput} = this.state;
    const {autoSize, fullWidth, startClosed, noStyle, refObject, onGetOptions, onCancel, onSubmit, onChange, onBlur, text, onEnter, className, ... otherProps} = this.props;


    let suggestionsListComponent;

    const value = onChange ? text : userInput;
    if(value !== userInput) {
      filteredSuggestions = this.buildSuggestions(value);
    }

    if (showSuggestions) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion.value} onMouseDown={e => this.onClick(e, suggestion.value)}>
                  {suggestion.label}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = null;
      }
    }

    let extraClasses = fullWidth ? ' MagicAutocomplete--full' : '';

    if(!noStyle) {
      extraClasses += ' MagicAutocomplete--default'
    }
    const classNames = 'MagicAutocomplete'+ extraClasses;

    if(autoSize !== false) {
      return <div className={classNames}>
          <AutosizeInput
            {... otherProps}
            type="text"
            inputClassName={'form-control monospace '+(className || "")}
            onChange={this.onChange.bind(this)}
            onKeyDown={this.onKeyDown.bind(this)}
            onFocus={() => this.setState({showSuggestions: true})}
            onBlur={onBlur || (() => this.onBlur())}
            value={value}
            className={className || ""}
          />
          {suggestionsListComponent}
        </div>
    } else {
      return <div className={classNames}>
        <input
          {... otherProps}
          ref={refObject}
          type="text"
          onChange={this.onChange.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          onFocus={() => this.setState({showSuggestions: true})}
          onBlur={onBlur || (() => this.onBlur()) }
          value={value}
          className={className || ""}
        />
        {suggestionsListComponent}
      </div>
    }
  }
}

export default AutocompleteMagic;
