import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CRUDPage from './CRUDPage';
import submenuLegos from './menus/submenu-legos';
import { ImagePreview } from '../components/images/ImagePreview';
import { Icon } from '../components/common/Icon';
import { DataFeedbackFiltersBar } from '../components/common/DataFeedbackFiltersBar';
import { VehicleSummary } from '../components/common/VehicleSummary';
import MongoDocEditor from '../components/lego/MongoDocEditor';
import { UserSummary } from '../components/common/UserSummary';
import { StateBadge } from '../components/data-issues/StateBadge';
import BadgeId from '../components/common/BadgeId';
import NiceDate from '../components/common/NiceDate';
import { AwaitingIcon } from '../components/data-issues/AwaitingIcon';
import { DataTypeTag } from '../components/dataprocess/DataTypeTag';

dayjs.extend(relativeTime);


export default class DataFeedbackManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/data/data-feedback');
    this.submenu = submenuLegos;
    this.state.summaryOpen = false;

    this.canDelete = false;
    this.canCreate = false;
  }

  getFacetFiltersComponent() {
    return <DataFeedbackFiltersBar onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  async fetchObjects() {
    // Change $limit so that UI knows we are showing all the objects (not the first 20)
    if(this.state.facetFilters.make || this.state.facetFilters.idModelo) {
      await super.fetchObjects({ $sort: { createdAt: -1 }, $limit: 5000});
    } else {
      await super.fetchObjects({ $sort: { createdAt: -1}, $limit: 100 });
    }
  }

  getColumnsDefinition(objects) {
    return [
      { content: '', className: '' },
      { content: 'Feedback type', className: '' },
      { content: 'Type', className: 'text-center' },
      { content: 'User', className: '' },
      { content: 'Feedback', className: '' },
      { content: 'Context', className: '' },
    ];
  }

  getObjectColumns(manual, definition) {
    let {
      _id, updatedAt, userId, isCasual, migratedFromCasual, dataSelector, state, awaiting, dataType, feedbackType,
      feedbackContext, data, labels
    } = manual;

    const imgUrl = data.images?.[0]?.url;

    const awaitingClass = awaiting ? 'bg-light-primary' : 'bg-light'

    return [
      <td className={awaitingClass}>
        <AwaitingIcon awaiting={awaiting}/>
      </td>,

      <td key={'feedbackType'} className={'context-column text-left '+awaitingClass}>
        <div>
          <StateBadge state={state}/>
          <span className={'ml-2'}>{updatedAt ? <NiceDate t={updatedAt}/> : ''}</span>
        </div>
        <BadgeId id={_id}/>
        <div>
        </div>
      </td>,

      <td key={'type'} className={'context-column text-left '}>
        <DataTypeTag type={dataType}/>
        <div className={'text-secondary'}>{feedbackType}</div>
      </td>,

      <td key={'user'}>
        <div className={'p-1 rounded '+(isCasual ? 'bg-light' : 'bg-light-success')}>
          <UserSummary userId={userId}/>
          {
            migratedFromCasual ?<span className={'ml-1'}>
              <Icon size={'sm'} icon={'how_to_reg'} level={'success'} title={'Registered after feedback'}/>
            </span> : null}
        </div>
      </td>,

      <td key={'feedback'} style={{ width: '100px' }} className={'text-center p-0'}>
        {imgUrl ? <ImagePreview zoom={true} url={imgUrl}/> : null}
      </td>,

      <td key={'context'}>
        <VehicleSummary vehicle={dataSelector.vehicle}/>

        {data.reason ? <span className={'badge badge-primary m-1'}>{data.reason}</span> : null}

        <div className={' small monospace mt-1'}>
          {feedbackContext.url.replace(/https?:\/\/www.opinautos.com/, '').replace(/#.+$/, '')}
        </div>
      </td>,

    ];
  }

  async getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    return <MongoDocEditor obj={obj} onSave={defaultOnSaveCbk} onCancel={defaultOnCancelCbk}/>
  }
}
