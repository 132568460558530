import _ from "lodash";
import React from 'react';
import StatsTable from "./StatsTable";

const modelKey = (idModelo, year) => `${idModelo}-${year}`;

export default class StatsFuseboxByModel extends StatsTable {
  async fetchData() {
    this.pageviewsByModelId = (await this.data.traffic('ar')).trafficBreakdown.pageviewsByModelId
    this.fuseboxes = (await this.data.fuseboxes()).fuseboxes;
    this.manualsByModel = _.groupBy((await this.data.manuals()).manuals, m => m.idModelo);

    let flattenedFuseboxes = _.flatten(_.map(this.fuseboxes, (fb) => {
      return _.map(_.compact(_.flatten([fb.context])), ({ modelId, year }) => ({ ...fb, modelId, year }));
    }))

    this.fuseboxesByModel = _.groupBy(flattenedFuseboxes, 'modelId');
  }

  groups() {
    return _.map(this.pageviewsByModelId, (pvs, modelId) => ({ modelId, fuseboxes: this.fuseboxesByModel[modelId] || [] }))
  }

  groupKey({modelId}) {
    return modelId;
  }

  groupWeight(key, { modelId }) {
    return this.pageviewsByModelId[modelId];
  }

  groupCoverage(key, { fuseboxes }) {
    return fuseboxes.length ? 1 : 0
  }

  displayGroup(modelKey, { modelId, fuseboxes }) {
    let manuals = null;
    if(!this.manualsByModel[modelId]) {
      manuals = <span className={'small'}><span className={'small badge badge-secondary'}>No manuals</span></span>;
    }

    let fuseboxCount = null;
    if(fuseboxes.length) {
      fuseboxCount = <span className={'text-success'}>({fuseboxes.length})</span>
    } else {
      fuseboxCount = <span className={'text-danger'}>(0)</span>;
    }

    return <div>{modelId} &nbsp; {fuseboxCount} {manuals}</div>;}
}