import React, {Component} from 'react';
import _ from 'lodash';
import {IconButton} from "../common/IconButton";
import ObjectSearchHandler from "../common/ObjectSearchHandler";

let perc = n => <span>{`${(n * 100).toFixed(n < 0.001 ? 2 : 1)}`}<span className={'small'}> %</span></span>

const SMALL_MINIMUN_PORTION_SHARE = 0.0000000001;

export class DataCoverageTable extends Component {
  constructor(props) {
    super(props);
    this.state = { sortField: 'traffic', limit: 20 };

    this.searchHandler = this.getSearchHandler();
  }

  getSearchHandler() {
    // Sort search results by original sort order, reflected on row index
    let getSorter = () => (([, , i]) => i)

    return new ObjectSearchHandler(this.props.objectFilters || {}, t => t, getSorter);
  }

  textFilterChanged(e) {
    this.setState({ textFilter: e.target.value });
  }

  onFiltersChange(newFilters) {
    this.setState({ facetFilters: newFilters });

    this.updateFiltersUrl(newFilters);

    setTimeout(() => this.fetchObjects(), 1);
  }

  lessRows() {
    this.setState({ limit: Math.max(this.state.limit - 10, 10) })
  }

  moreRows() {
    this.setState({ limit: this.state.limit + 10 })
  }

  buildCoverageRows() {
    let { dataCoverage, coverageMetric, displayGroup } = this.props;
    let { textFilter, limit } = this.state;

    ///////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////
    // Support custom/multiple grouping functions to re-group stats
    // Support custom or complex ways of measuring coverage or multiple metrics

    // Default group by key
    let groupFunc = id => id
    // let groupFunc = id => id.split('-')[0];

    // Default, linear additions coverage
    let addFunc = (sum = 0, n) => sum + (n || 0);

    // Default, averaged share-coverage function (if coverage is 1 and share is 0.5 return 1*0.5)
    let shareProportionalCoverage = (share, coverage) => share * coverage;

    // Default float share calculation
    let shareAsRatio = (share) => share;

    // Default display group function
    displayGroup = displayGroup || (groupKey => groupKey)

    // Default float based coverage function
    let displayCoverage = (coverage, share) => {
      let groupCoverage = share ? (coverage / share) : (coverage ? 1 : 0);
      if (groupCoverage === 1) {
        return <span className={'text-success'}>100%</span>;
      } else if (groupCoverage === 0) {
        return <span className={'text-danger'}>0%</span>;
      } else {
        return <span className={''}>{perc(groupCoverage)}</span>;
      }
    };

    // Default float based coverage function
    let displayUpside = (coverage, share) => {
      let groupCoverage = share ? (coverage || 0) / share : 0;
      if (groupCoverage === 1) {
        return <span className={'text-success'}>-</span>;
      } else {
        return <span className={'text-danger'}>{perc(((share || 0) - (coverage || 0)))}</span>;
      }
    };

    let CoverageRow = ({ data, i, id, display, colorize, ...other }) => {
      let { share, coverage } = data;
      coverage = coverage || 0

      // Build a bacgrkound and text color between red and green according to relative coverage
      let ratioCoverage = Math.floor((coverage / (share || coverage || 1)) * 255);
      let rowStyle = { backgroundColor: `rgba(${255 - ratioCoverage},${ratioCoverage},0,0.2)` }
      if (colorize === false) {
        rowStyle = {}
      }
      let textStyle = { color: `rgba(${0.7 * (255 - ratioCoverage)},${0.7 * (ratioCoverage)},0,1)` }

      return <tr {...other} style={rowStyle}>
        <td className={''}>{i || null}</td>
        <td className={''}>{perc(shareAsRatio(share || 0))}</td>
        <td style={textStyle}>{displayCoverage(coverage, share)}</td>
        <td className={''}>{displayUpside(coverage, share)}</td>
        <td className={''}>{display}</td>
      </tr>
    };

    /////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////

    let summary = { coverage: undefined };
    let summaryFilteredData = null;

    let groups = {};
    _.each(coverageMetric.shares, (portion, key) => {
      // Prevent portions with size 0 that would lead to Coverage 0% always
      portion = portion || SMALL_MINIMUN_PORTION_SHARE;
      let groupKey = groupFunc(key, portion);
      groups[groupKey] = groups[groupKey] || { coverage: addFunc() };
      groups[groupKey].share = addFunc(groups[groupKey].share, portion);
      summary.share = addFunc(summary.share, portion);
    });


    _.each(dataCoverage.portions, (portion, key) => {
      // Prevent portions with size 0 that would lead to Coverage 0% always
      let portionShare = coverageMetric.shares[key] || addFunc() || SMALL_MINIMUN_PORTION_SHARE;

      let groupKey = groupFunc(key, portion);
      // In case coverage didn't even include that key
      groups[groupKey] = groups[groupKey] || { share: addFunc(), coverage: addFunc() };

      summary.coverage = addFunc(summary.coverage, shareProportionalCoverage(portionShare, portion));
      groups[groupKey].coverage = addFunc(groups[groupKey].coverage, shareProportionalCoverage(portionShare, portion));
    });

    let orderedCoverage = null;
    if (this.state.sortField === 'traffic') {
      orderedCoverage = _.sortBy(_.toPairs(groups), ([, { share, coverage }]) => shareAsRatio(share)).reverse();
    } else {
      orderedCoverage = _.sortBy(_.toPairs(groups), ([, { share, coverage }]) => (share - coverage)).reverse();
    }
    _.each(orderedCoverage, (arr, i) => arr[2] = i + 1)

    if (textFilter) {
      this.searchHandler.updateFilter(textFilter)
      orderedCoverage = this.searchHandler.search(orderedCoverage || []);
    }

    let topRows = _.map(orderedCoverage.slice(0, limit), ([groupKey, data, i]) => {
      return <CoverageRow key={groupKey} i={i} data={data} display={displayGroup(groupKey)}/>
    });

    // if filtering, compute summary for filtered data
    if (textFilter) {
      summaryFilteredData = { coverage: undefined };

      _.each(orderedCoverage, ([, { share, coverage }]) => {
        summaryFilteredData.coverage = addFunc(summaryFilteredData.coverage, coverage);
        summaryFilteredData.share = addFunc(summaryFilteredData.share, share);
      })

      topRows.unshift(<CoverageRow key={"_all2"} className={'bg-light-secondary h5 CoverageTable-row-sep'}
                                   data={summaryFilteredData} colorize={false}
                                   display={`The ${orderedCoverage.length} rows filtered by "${textFilter}"`}/>)
    }

    topRows.unshift(<CoverageRow key={"_all"} className={'h4 CoverageTable-row-sep'} data={summary}
                                 display={<span className={'text-secondary small'}>{_.toPairs(groups).length} rows</span>}/>)

    if (limit && orderedCoverage.length > limit) {
      let otherDataPoints = orderedCoverage.slice(limit);

      let othersShare = undefined;
      let othersCoverage = undefined;
      _.each(otherDataPoints, ([, { share, coverage }]) => {
        othersCoverage = addFunc(othersCoverage, coverage);
        othersShare = addFunc(othersShare, share);
      });
      topRows.push(<CoverageRow key={"_other"} className={'bg-light-secondary font-weight-bold'}
                                data={{ share: othersShare, coverage: othersCoverage }} colorize={false}
                                display={`Other ${otherDataPoints.length} rows`}/>)
    }

    topRows.push(<tr key={"_limitOptions"} className={'bg-white font-weight-bold'}>
      <td className={''}></td>
      <td className={'text-center'} colSpan={4}>{this.buildRowsLimitControl()}</td>
    </tr>)


    return topRows;
  }

  buildRowsLimitControl() {
    let limit = this.state.limit;
    let predefinedLimits = [10, 20, 50, 100, 200, 500, 1000];

    return <div>
      Showing first {limit} rows <span>

       <IconButton icon={'remove_circle_outline'} onClick={() => this.lessRows()}/>

      {
        predefinedLimits.map(n => <span
          key={n}
          className={'btn btn-xs btn-link' + (n === limit ? ' bg-light-primary rounded' : '')}
          onClick={() => this.setState({ limit: n })}>{n}
          </span>)
      }

      <IconButton icon={'add_circle_outline'} onClick={() => this.moreRows()}/>
    </span>
    </div>
  }

  buildCoverageHeader() {
    let sortButton = (field) => {
      let isOn = this.state.sortField === field;
      return <IconButton level={isOn ? 'success' : 'secondary'}
                         icon={'sort'}
                         onClick={() => this.setState({ sortField: field })}
      />
    };

    return <tr className={'bg-light'}>
      <th>Nth</th>
      <th>Share {sortButton('traffic')}</th>
      <th>Coverage</th>
      <th>Uncovered {sortButton('uncovered')}</th>
      <th>
        <span className={'form-inline'}>
          Group
          <input className={'ml-2 form-control form-control-sm'} placeholder={'Filter...'} type={'text'}
                 onChange={this.textFilterChanged.bind(this)}/>
        </span>
      </th>
    </tr>
  }

  render() {
    let rows = this.buildCoverageRows();

    let columnHeads = this.buildCoverageHeader();

    return <div className={''}>
      <table className={'table table-sm CoverageTable'}>
        <thead>{columnHeads}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>;
  }
}
