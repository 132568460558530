import React, {Component} from 'react';
import { getCountryCode, getCountryName } from '../../../../components/interop/countryCodeConverterFront';

export default function CountryFlag({ countryCode, country, small, ...other }) {
  if (!countryCode) {
    countryCode = getCountryCode((country || 'other').toLowerCase());
  }

  const size = small ? { width: '18px', height: '18px', margin: '-3px 0px' } : { width: '24px', height: '24px' };

  if(countryCode === '*') {
    return <span style={size} title={country || getCountryName(countryCode)} {...other}><span className={'badge badge-violeta text-warning m-1'}>✱</span></span>
  }

  // TODO: Hardcoded for simplicity with legos deploy and bundle using opinautos.com cdd
  return <img src={`https://static.opinautos.com/images/banderas/${countryCode}.png`}
                                                              alt={country || getCountryName(countryCode)}
                                                              title={country || getCountryName(countryCode)}
                                                              style={size} {...other}/>;
};
