let modulesCache = {};

window.require = (moduleName) => {
  let shims = {
    'react': () => window['React'],
    'react-dom': () => window['ReactDOM'],
    'lodash': () => window['lodash'] || window['_'],
  }

  if(modulesCache[moduleName]) {
    return modulesCache[moduleName];
  }

  const requestedModuleFunc = shims[moduleName];
  if(requestedModuleFunc) {
    let module = requestedModuleFunc();
    if(module) {
      console.log("%cUsing 'window."+moduleName+"'", 'color: green;')
      modulesCache[moduleName] = module;
      return module;
    } else {
      console.log("%c'window."+moduleName+"' has to be loaded and available in the window where this bundle was loaded", 'font-size: 18px;color: red;')
      throw new Error(`Could not load window replacement for module: ${moduleName}`);
    }
  } else {
    throw new Error(`Could not resolve module: ${moduleName}`);
  }
};
