import React, { Component, useContext } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import submenuModels from './menus/submenu-models';
import CRUDPage from './CRUDPage';
import NiceDate from '../components/common/NiceDate';
import MongoDocEditor from '../components/lego/MongoDocEditor';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import { SingleImageUrlEditor } from '../components/common/images/SingleImageUrlEditor';
import TrimSpec from '../components/lego/trims/TrimSpec';
import NotesEditor from '../components/common/editors/NotesEditor';
import { MultipleImagesEditor } from '../components/common/editors/MultipleImagesEditor';
import { LocalesList } from '../components/lego/LocalesList';
import CountryFlag from '../components/common/CountryFlag';
import { LocalesSelector } from '../components/lego/LocalesSelector';
import LegoContextSummary from '../components/lego/LegoContextSummary';
import LegoLabelsSelector from '../components/lego/LegoLabelsSelector';

export default class ModelsManager extends CRUDPage {
  constructor(props) {
    super(props, '/services/data/models/all');

    this.submenu = submenuModels;
    this.state.columnSort = { index: 1, asc: true, sorter: ['createdAt'] };

    this.newObjectTemplate = {
      idModelo: '',
      modelo: '',
      marca: '',
      slug: '',
      locales: [window.config.locale]
    };
  }

  getFacetFiltersComponent() {
    const refreshModeloCaches = () => this.runAsync(this.service('/services/data/models').update({}, {}));

    return <span>
      <span className={'ml-3 btn btn-sm btn-warning'}
            onClick={refreshModeloCaches}>Refresh site generations cache</span>
    </span>;
  }

  buildObjectQuery() {
    return { ...super.buildObjectQuery(), $limit: 10000 };
  }

  getColumnsDefinition(objects) {
    return [
      { content: 'Creation', className: 'text-center', sorter: o => new Date(o.createdAt) },
      { content: 'Locales', className: 'text-center' },
      { content: 'Model ID', className: 'text-center', sorter: ['idModelo'] },
      { content: 'Marca', className: 'text-left', sorter: ['marca', 'modelo'] },
      { content: 'Modelo', className: 'text-center', sorter: ['modelo'] },
      { content: 'Slug', className: 'text-center' },
      { content: 'Countries', className: 'text-center' },
    ];
  }

  getObjectColumns(modelObject, def, i, allRows) {
    let { countries, idModelo, slug, modelo, marca, createdAt, locales } = modelObject;

    return [
      <td key={'id'}>
        {createdAt && <NiceDate t={createdAt}/>}
      </td>,

      <td key={'locales'} className={'text-center'}>
        {locales ? <LocalesList locales={locales}/> : '-'}
      </td>,

      <td key={'idModelo'}><LegoContextSummary context={{modelId: idModelo}}/></td>,

      <td key={'modelId'} className={'text-center'}>{marca}</td>,

      <td key={'context'} className={'text-center'}>{modelo}</td>,

      <td key={'name'} className={'text-center monospace'}>
        {slug}
      </td>,

      <td key={'countries'} className={'text-center'}>
        {countries?.map(c => <CountryFlag key={c} countryCode={c}/>)}
      </td>
    ];
  }

  getObjectEditorComponent(obj, defaultOnSaveCbk, defaultOnCancelCbk) {
    let schema = {
      locales: (value, onChange) => <LocalesSelector onChange={onChange} value={value}/>,
      labels: (value, onChange) => <LegoLabelsSelector onChange={onChange} value={value || []}/>,
      notes: (value, onChange) => <NotesEditor value={value} onChange={onChange}/>
    };

    let onSave = (changedObj, ...args) => {
      return defaultOnSaveCbk(changedObj, ...args);
    };

    return <MongoDocEditor extendSchema={schema} obj={obj} showFields={_.keys(schema)} onSave={onSave}
                           onCancel={defaultOnCancelCbk}/>;
  }
}
