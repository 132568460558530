import React from "react";
import _ from "lodash";

export default class ObjectSearchHandler {
  constructor(objectFilters, textFilterCustomizer, getSorter, getTextToSearch = JSON.stringify) {
    let identitySort = () => (objects => objects);

    this.getTextToSearch = getTextToSearch;
    this.objectFilters = objectFilters;
    this.textFilterCustomizer = textFilterCustomizer;
    this.getSorter = getSorter || identitySort;
  }

  buildTextFilter(filterText) {
    let searchRegex = null;
    try {
      if(this.textFilterCustomizer) {
        searchRegex = new RegExp(this.textFilterCustomizer(filterText), 'ig');
      } else {
        searchRegex = new RegExp(filterText, 'ig');
      }
    } catch (err) {
      const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

      searchRegex = new RegExp(escapeRegExp(filterText), 'ig');
    }

    return (text => (text || "").match(searchRegex))
  }

  buildObjectFilter(searchText) {
    const searchCommandRegex = /\s*:(!)?([^\s=]*)=?(?:([^\s]+)(?:\s+|$))?/;

    let knownFilters = _.mapValues(this.objectFilters, (f,command) => f.filter || f);

    let filters = [];
    let commandMatch;

    while (commandMatch = searchText.match(searchCommandRegex)) {
      let [, neg, command, parameter] = commandMatch;
      console.log("COMMANDS", commandMatch)
      let filterGenerator = knownFilters[command];

      if (filterGenerator) {
        let filter = filterGenerator(parameter)

        if (neg) {
          filters.push(node => !filter(node))
        } else {
          filters.push(filter)
        }

      } else {
        // If searched text is ':!notacommand' create filter 'not matching text "notacommand"'
        if(neg && !parameter) {
          let textFilter = this.buildTextFilter(command);
          filters.push((obj) => !textFilter(this.getTextToSearch(obj)))
        } else if(!command && parameter) {
          // For case :=XXX just search for "XXX".
          let textFilter = this.buildTextFilter(parameter);
          filters.push((obj) => textFilter(this.getTextToSearch(obj)))
        } else {
          console.warn(`Unknown filter ${command}=${parameter || ''}`);
        }
      }

      // Consume matching filter
      searchText = searchText.replace(searchCommandRegex, '');
    }

    if (filters.length) {
      return [searchText, obj => _.every(filters, f => f(obj))];
    } else {
      return [searchText, obj => true];
    }
  }

  search(objects) {
    objects = objects.filter(obj => this.objectFilter(obj) && this.textFilter(this.getTextToSearch(obj)));
    return _.sortBy(objects, this.sortFunctions);
  }

  updateFilter(inputText) {
    let [leftText, objectFilter] = this.buildObjectFilter(inputText);

    this.objectFilter = objectFilter;
    this.textFilter = this.buildTextFilter(leftText);
    this.sortFunctions = this.getSorter(leftText, this.textFilter);
    this.leftText = leftText;
  }
}
