import React from 'react';
import _ from 'lodash';

import LivePage from './LivePage';


export default class PageNotFound extends LivePage {
  renderPageBody() {
    return <React.Fragment>
      <div className={''}>
        <h3 className={'text-center mt-5'}>Page not found</h3>
      </div>
    </React.Fragment>;
  }
}
