/* eslint-disable no-param-reassign,no-restricted-syntax */

// HACK: This is coupled with the config of our thumbnail generating lambda
const noThumbnailBlacklist = [
  '/originals/legos/fusebox-diagrams',
  '/originals/legos/fusebox-tables',
  '/originals/legos/fusebox-pictures',
  '/originals/manual-screenshots',
];

// This works for the "new" thumbnail generating lambda; images as attachments for defectos have a different pattern
// See model/attachment.js
function getImageThumbnail(imgUrl, size) {
  imgUrl = imgUrl || '';
  for (const excludedPattern of noThumbnailBlacklist) {
    if (imgUrl.includes(excludedPattern)) {
      return imgUrl;
    }
  }

  // Use cloudfront instead of directly using s3, as it supports http2 and is faster
  return imgUrl.replace(/\/originals\/((?:[^/]+\/)+)([^/]+)$/, `/processed/$1${size}/$2`)
    .replace('https://rewise-images.s3.amazonaws.com', 'https://d1crblsfe59eq8.cloudfront.net');
}

export const getImageThumbnailSmall = (imgUrl) => getImageThumbnail(imgUrl, 's');
export const getImageThumbnailMedium = (imgUrl) => getImageThumbnail(imgUrl, 'm');
