import React, { PureComponent } from 'react';

export class SwitchInput extends PureComponent {
  render() {
    let { value, onChange, triState, label, className, id, ...other } = this.props;

    className = className || '';
    if (value === false) {
      className += ' custom-switch--false';
    }

    let onClick = () => onChange(!value);
    if (triState) {
      onClick = () => onChange(value ? !value : (value === false ? undefined : true));
    }

    return <span onClick={onClick} className={'custom-control no-select custom-switch ' + (className || '')} {...other}>
      <input type="checkbox" className={'custom-control-input '} checked={value || false} id={id} onChange={onChange}/>
      <label className="custom-control-label" htmlFor={id}>{this.props.children || label}</label>
    </span>
  }
}
