import React, { PureComponent } from 'react';
import _ from 'lodash';

import Select from 'react-select/lib/Creatable';
import TrimSpec from './TrimSpec';

export default class TrimPacksEditor extends PureComponent {
  handleSelectChange(values, actionMeta) {
    this.props.onChange(_.map(values, 'value'));
  }

  // getOption(props) {
  //   let {innerProps, isSelected, isFocused} = props;
  //   let {innerRef, ... filteredProps} = innerProps;
  //
  //   let [[field, val]] = _.toPairs(JSON.parse(props.value).value)
  //   const [fieldCategory] = field.split('.');
  //   let color = FIELD_COLORS_MAPPING[fieldCategory] || 'info'
  //
  //   return <div {... filteredProps} className={'p-1 '+((isSelected || isFocused) ? 'bg-light-primary': '')}>
  //     <span>{field}</span>: <span className={`badge badge-${color}`}>{val}</span>
  //   </div>
  // };

  getMultiValueLabel(props) {
    let val = props.data.value ? props.data.value : props.data;

    return <span className={'mr-1 badge badge-pill badge-outline badge-danger'} key={val}>{val}</span>
  };

  render() {
    // let specs = _.flatten(_.map(TrimSpec.SPECS, (vals,key) => _.map(vals, val => ({label: `${key}=${val}`, value: {[key]: val}}))))

    const options = [];

    let other = this.props.value || [];

    const {onChange, value, ... props} = this.props;

    let values = _.map(other, (val) => ({label: val, value: val}))

    let loadOptions = async (input) => {
      let regex = new RegExp();
      try {
        regex = new RegExp((input || "").trim(), 'i');
      } catch(e) {}

      let extra = [];
      let matchSpec = input.match(/([^=\s]+)=([^=\s]+)/);
      if(input && matchSpec) {
        let [,key, val] = matchSpec;
        extra = [{label: input, value: {[key]: val}}]
      }

      return [... other]
    };

    return (
      <Select
        closeOnSelect={true}
        isMulti
        components={{
          // Option: this.getOption.bind(this),
          MultiValueLabel: this.getMultiValueLabel.bind(this),
        }}
        getOptionValue={(option) => JSON.stringify(option)}
        onChange={this.handleSelectChange.bind(this)}
        // loadOptions={loadOptions}
        placeholder="Add pack..."
        menuShouldScrollIntoView={false}
        maxMenuHeight={170}
        // menuPositionunion={'fixed'}

        value={values}
        {... props}
      />
    );
  }
}
