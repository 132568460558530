import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Async from 'react-select/lib/Async';
import useAsyncData from "../../common/effects/useAsyncData";
import UserAvatar from "../../common/UserAvatar";
import useAsyncEffect from '../../../admin-backend/admin-front/components/common/react-hooks/useAsyncEffect';

let uuidRegex = /[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}/i;

export default function InputUserIdBase({ onChange, value, fetchRank, searchUsers, UserAvatar, isMulti, ...otherProps }) {
  //TODO: improve css when used for Legos, (ranking number, selected option on menu, menú z-index sometimes behind other contents on page)
  const rankUsers = useAsyncData('userId', fetchRank.bind(this), []);

  const [usersCache, setUsersCache] = useState({});
  const [prevInputLength, setPrevInputLength] = useState(0)

  let mounted = true;
  const loadCurrentUsersData = async () => {
    const newUsers = _.difference(_.concat(value), _.keys(usersCache))
    const newKeyedRes = _.keyBy(await searchUsers(newUsers), 'id');
    const keyedRank = _.keyBy(rankUsers, 'id');
    if(mounted)
      setUsersCache({... keyedRank, ...usersCache, ...newKeyedRes});
  }

  useAsyncEffect(loadCurrentUsersData, () => mounted = false,  [value, rankUsers])

  const handleSelectChange = (obj, actionMeta) => {
    setPrevInputLength(0);
    return onChange( isMulti ? _.map(obj, 'value') : obj?.value);
  };

  const UserOption = ({user, compactView}) => {
    const emailColor = ({active: 'color-text-solved', confirmed: 'color-text-gray', registered: 'color-text-danger'})[user.level] || ''
    const emailLevel = user.email ? <span className={`ml-05 text-small ${emailColor}`}>{user.email}</span> : null;

    const extendedData = <span>
      <span className={'ml-05 align-middle text-semi-small'}>{user.username || user.id}</span> { user.rank ? <span className={'TagLink'}>{user.rank}º</span>: null } {emailLevel}
    </span>

    return <span>
    <UserAvatar user={user} title={JSON.stringify(user).replace(/\,/g, '\n')}/>
      {compactView ? null : extendedData}
    </span>
  }

  const getOption = (props) => {
    let { innerProps, isSelected, isFocused } = props;
    let { innerRef, ...filteredProps } = innerProps;
    let userData = props.data.label;

    if (!userData) {
      return <div>{props.value}</div>;
    }

    return <div {...filteredProps} className={'p-05 text-left ' + ((isSelected || isFocused) ? 'TagLink--gray' : '')}>
      <UserOption user={userData}/>
    </div>
  };

  const getValue = (props) => {
    let userId = props.data.value;
    let userData = props.data.label;
    if (!userData) {
      return <span key={userId + 'noData'}>...</span>;
    }

    return <span key={userId}>
      <UserOption user={userData} compactView={valueMatch.length >= 10}/>
    </span>
  };

  const getOptionFromUser = user => ({ label: usersCache[user.id], value: user.id });

  let loadOptions = async (input) => {
    let regex = new RegExp();
    try {
      regex = new RegExp((input || "").trim(), 'i');
    } catch (e) {
      regex = new RegExp(_.escape(input))
    }

    let extra = [];

    let searchRes = [];
    // Querying with small prefixes is expensive. Only hit server if length is at least 4
    if(input && input.length > 3) {
      searchRes = await searchUsers(input);
    }
    const serverMatchs = _.map(searchRes, res => ({label: res, value: res.id}));
    const filterByName = _.filter(rankUsers, e => e.username && e.username.match(regex)).map(m => getOptionFromUser(m));
    const filterById = _.filter(rankUsers, e => e.id.match(regex)).map(m => getOptionFromUser(m));
    return [...extra, ... filterByName.slice(0,20), ...filterById.slice(0, 20), ... serverMatchs];
  };

  const getValueMatch = (value) => getOptionFromUser({id: value })

  const valueMatch = value ? ( isMulti ? _.map(_.uniq(value), val => getValueMatch(val)) : getValueMatch(value) ) : null;

  const onInputChange = (input) =>{
    //prevent parsing input as a list when writing usernames that contains spaces
    if (Math.abs(input.length - prevInputLength) === 1){
      setPrevInputLength(input.length)
      return
    }

    const values = _.compact(input.split(/[^\d\w\-.]/));
    if (values.length > 1) {
      if (isMulti){
        handleSelectChange(_.concat(valueMatch, _.map( values, v => ({ value: v }))));
      }else {
        handleSelectChange({ value: values[0] });
      }
    }
  }

  return (
    <Async
      closeOnSelect={true}
      components={{
        Option: getOption.bind(this),
        SingleValue: getValue.bind(this),
        MultiValueLabel: getValue.bind(this)
      }}
      // getOptionValue={(option) => JSON.stringify(option)}
      onChange={handleSelectChange.bind(this)}
      onInputChange={onInputChange}
      loadOptions={loadOptions}
      placeholder="User id or name/email prefix..."
      menuShouldScrollIntoView={false}
      maxMenuHeight={270}
      isClearable={otherProps.isClearable === false ? false : true}
      // menuPositionunion={'fixed'}
      isMulti={isMulti}
      value={valueMatch}
      {...otherProps}
    />
  );
}
