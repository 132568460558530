import React from 'react';

import LiveSubpage from '../../pages/LiveSubpage';
import TopBarLayout from '../common/layout/TopBarLayout';
import { formatDistanceToNow } from 'date-fns'
import { ImagePreview } from '../images/ImagePreview';
import LegoContextSummary from '../lego/LegoContextSummary';
import { UserSummary } from '../common/UserSummary';

function ImageMetadataSummary({image: {width, height, size, mimeType, updatedAt, fileHash}}) {
  return <div>
    <div>
      <span className={'text-info mr-2'}>{width}x{height}</span>
      <span className={'text-secondary mr-2'}>{(size/1024).toFixed(0)}KB</span>
      <span className={'badge badge-secondary mr-2'}>{mimeType}</span>
      <span>{formatDistanceToNow(new Date(updatedAt))} ago</span>
    </div>
  </div>
}

export default class TaskEditorImageUserUpload extends LiveSubpage {
  constructor(props) {
    super(props);

    this.imageService = window.client.service('/services/data/images');
    this.tasksService = window.client.service('services/data-tasks');
    this.dataFeedbackSvc = window.client.service('services/data/data-feedback');
    this.contributionsService = window.client.service('services/data/contributions');

    this.state = {
      ...this.state
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.loadImageMetadata());
    this.runAsync(this.loadContributionData());
  }

  async loadContributionData() {
    let [contribution] = await this.contributionsService.find({
      query: { _id: this.props.task.input.contributionId }
    });

    if (contribution) {
      contribution.vehicleContext = { ...contribution.vehicleContext, ...contribution.vehicleContext.trimSpecs };
      delete contribution.vehicleContext.trimSpecs;
      this.setState({ contribution: contribution });
    }
  }

  async loadImageMetadata() {
    let images = await this.imageService.find({
      query: {'sourceMetadata.contributionId': this.props.task.input.contributionId}
    });

    if (images.length) {
      this.setState({ image: images[0] });
    }
  }

  async createImgClusterDataTask(contributionTask, image){
    const dataTask = await this.tasksService.create({
      typeId: 'imgFuseboxClustering',
      batchId: `imgFuseboxClassify-${contributionTask.context[0].modelId}`,
      impact: 0,
      context: contributionTask.context,
      state: '',
      input: {
        id: image._id,
        contributionId: contributionTask.input?.contributionId,
        dataFeedbackId: contributionTask.input?.dataFeedbackId,
        dataIssueId: contributionTask.input?.dataIssueId,
      },
      output: {}
    });
    console.log('Created imgFuseboxClustering dataTask: ' + JSON.stringify(dataTask))
  }

  renderPageBody() {
    let { error, image, contribution } = this.state;

    let { contributionId, category, imgUrl} = this.props.task?.input || {};
    let output = this.props.task?.output;

    const onApprove = async () => {
      if (!image) {
        const dbImage = await this.imageService.create({
          url: imgUrl,
          sourceType: 'contribution',
          category,
          sourceMetadata: {
            contributionId,
            locale: window.config.locale
          }
        });

        const promises = [this.loadImageMetadata()];
        if (category === 'fusebox') {
          promises.push(this.createImgClusterDataTask(this.props.task, dbImage));
        }
        await Promise.all(promises);
      }
      await this.props.batchEditor.doneAndNext('approved');
    };

    const onReject = async () => {
      let { dataFeedbackId, category} = this.props.task?.input || {};

      if(category === 'fusebox' && dataFeedbackId) {
        await this.dataFeedbackSvc.update(dataFeedbackId, {$set: {awaiting: false, state: 'rejected'}})
      }

      await this.props.batchEditor.doneAndNext('rejected');
    };
    return <React.Fragment>
      <div className={'grid-pdf-extraction'}>
        <div className={'pos-relative bg-secondary'}>
          <TopBarLayout>
            <div>
              {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

              <div className={'h6 m-0 p-2 d-flex justify-content-between align-items-center'}>

                <span>Contribution <span className={'badgCe badge-secondary mr-4'}>{contributionId}</span></span>
                <span>Category <span className={'badge badge-primary mr-4'}>{category}</span></span>
                {contribution ? <span className={'h6 m-0 bg-light p-1 rounded'}>
                  <UserSummary userId={contribution.userId}/>
                </span> : <span/> }
              </div>
            </div>

            <div className={'p-1 full-screen-center'}>
              { imgUrl ? <ImagePreview fluidContain url={imgUrl} className={'mr-1 bg-light'}/> : 'Empty input.imgUrl' }
            </div>
          </TopBarLayout>
        </div>

        <div className={'bg-light'} id={'extractionInputArea'} key={contributionId}>
          <div className={'p-2 small text-center'}>
            <div>
              {contribution ? <LegoContextSummary context={contribution.vehicleContext}/> : 'No contribution found'}
            </div>
            <div>
              {/*{ image ? <pre>{JSON.stringify(image, true, 2)}</pre> : 'No image object'}*/}
              {image ? <ImageMetadataSummary image={image}/> : 'No image yet' }
            </div>
            <div className={'h3 mb-4 mt-2'}>
              {output ? <span className={`badge badge-${output === 'approved' ? 'success' : 'danger'}`}>{output}</span> : '-'}
            </div>

            <span className={'btn btn-danger mr-2'} onClick={async () => this.runAsync(onReject)}>Reject</span>
            <span className={'btn btn-success'} onClick={async () => this.runAsync(onApprove)}>Approve</span>
          </div>

          <div className={'border border-left-0 border-right-0'}>
            {this.props.batchEditor.getTaskStateControls()}
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}
