import _ from "lodash";

export class NotesManager {
  constructor(namespace, user) {
    // Global Feathers client
    this.namespace = namespace;
    this.user = user;
    this.notesSvc = client.service('services/common/notes');
    this.loading = this.all().then(all => {
      this.loading = false;
    })

    this.updateListeners = {};

    const handler = cbk => (obj) => {
      console.log("HANDLER", obj)
      if (obj && obj.namespace === this.namespace) {
        cbk(obj);
      }
    };

    this.notesSvc.on('created', handler((newNote) => {
      const id = newNote.thingId;
      this.cache[id] = [... (this.cache[id] || []), newNote];
      this.notifyListeners(id);
      this.notifyListeners('__ALL__');
    }));

    this.notesSvc.on('updated', handler((updatedNote) => {
      const id = updatedNote.thingId;
      this.cache[id] = _.filter((this.cache[id] || []), note => note._id !== updatedNote._id);
      this.cache[id].push(updatedNote);
      this.notifyListeners(id);
      this.notifyListeners('__ALL__');
    }));

    this.notesSvc.on('removed', handler((deletedNote) => {
      const id = deletedNote.thingId;
      this.cache[id] = _.filter((this.cache[id] || []), note => note._id !== deletedNote._id);
      this.notifyListeners(id);
      this.notifyListeners('__ALL__');
    }));
  }

  addListener(thingId, cbk) {
    if (!this.updateListeners[thingId]) {
      this.updateListeners[thingId] = new Set();
    }
    this.updateListeners[thingId].add(cbk);
  }

  removeListener(thingId, cbk) {
    const cbks = this.updateListeners[thingId];
    if (cbks) {
      cbks.delete(cbk);
      if (!cbks.size) {
        delete this.updateListeners[thingId];
      }
    }
  }

  notifyListeners(thingId) {
    for (const cbk of (this.updateListeners[thingId] || [])) {
      cbk(this.cache[thingId]);
    }
  }

  async all() {
    if(!this.cache) {
      if(this.loading) {
        await this.loading;
      } else {
        console.log("Fetching all notes " + this.namespace)
        let all = await this.notesSvc.find({ query: { namespace: this.namespace } });
        this.cache = _.groupBy(all, 'thingId');
      }
    }

    return this.cache;
  }


  async get(thingId) {
    if (!this.cache) {
      await this.loading;
    }

    return this.cache[thingId];
  }

  cacheLoaded() {
    return !!this.cache;
  }

  allCached() {
    if (!this.cache) {
      throw new Error("getCached should only be called after checking cache was already loaded with cacheLoaded()")
    }

    return this.cache;
  }

  getCached(thingId) {
    if (!this.cache) {
      throw new Error("getCached should only be called after checking cache was already loaded with cacheLoaded()")
    }

    return this.cache[thingId];
  }

  async createOn(thingId, content) {
    let res = await this.notesSvc.create({
      namespace: this.namespace,
      userId: this.user.id,
      createdAt: new Date(),
      thingId,
      content
    });
    this.cache[thingId] = await this.notesSvc.find({ query: { namespace: this.namespace, thingId } });
    // this.notifyListeners(thingId);
    return res;
  }

  async edit(noteId) {
    this.notifyListeners(noteId);
  }

  async remove(noteId) {
    await this.notesSvc.remove(noteId);
    this.notifyListeners(noteId);
  }
}
