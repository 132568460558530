import React, {Component} from 'react';
import _ from 'lodash';

import SemanticEditor from "../SemanticEditor";

const GLOSSARY_VIEW = 'glossary';

export default class GlossaryReferenceEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      semantic: props.semantic,
      text: props.text
    };

    this.dataviewGetService = window.client.service('/services/dataviews/get');

    this.semEditor = React.createRef();
  }

  componentDidMount() {
    const editor = this.semEditor.current;
    if(editor && !this.state.semantic) {
      editor.openSemanticSearch(this.state.text);
    } else {
      this.previewGlossary(this.state.semantic);
    }
  }

  async updateSemantic(newSemantic) {
    if(this.state.semantic !== newSemantic) {
      await this.previewGlossary(newSemantic);
      this.setState({ semantic: newSemantic });
    }
  }

  async previewGlossary(semantic) {
    this.setState({loadingGlossary: true});
    let res = await this.dataviewGetService.find({query: {names: [GLOSSARY_VIEW], contexts: [{what: semantic}]}})
    this.setState({glossary: res[0], loadingGlossary: false});
  }

  render() {
    let results = null;

    let { semantic, text, loadingGlossary, glossary } = this.state;

    return <div style={{ minHeight: "200px" }}>
      <div className={'mb-2 h5'}>
        "{text}"
      </div>

      <div className={'mb-2'}>
        <SemanticEditor ref={this.semEditor} label={"Glossary semantic key"} value={semantic} onChange={(newVal) => this.updateSemantic(newVal)}/>
      </div>

      <button className={'btn btn-primary'} onClick={() => this.props.onOk(this.state.semantic)}>Save
        reference changes
      </button>

      <div className={'mt-2 bg-light-primary p-3'} style={{maxWidth: 400}}>
      {
        loadingGlossary ? "Loading glossary..." : (glossary || <span className={'text-danger'}>NO GLOSSARY FOUND FOR {semantic}</span>)
      }
      </div>
    </div>
  }
}
