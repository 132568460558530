import FacetProxy from "./FacetsProxy";

function getIdColorClass(id = '', config) {
  if (id.startsWith('_'))
    id = id.slice(1);

  const cols = config.typeColors;

  let partialType = id;
  let type = null;

  do {
    if (cols[partialType])
      return cols[partialType];

    type = FacetProxy.parseId(partialType).type;

    // For 'Model.subfield' types
    if (type.match(/\.\w+$/)) {
      type = type.replace(/\.\w+$/, '');
    }

    if (cols[type])
      return cols[type];

    partialType = type;

  } while (type);

  return 'secondary';
}

export {
  getIdColorClass
}
