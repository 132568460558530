import __ from '../interop/i18n';

import { format } from 'date-fns';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

import { es } from 'date-fns/locale'

export const formatNumber = (n) => n; // TODO

export const formatKms = (kms) => `${formatNumber(kms)} ${__('global.distanceUnit')}`;

export const formatDateTime = (fecha) => format(new Date(fecha), 'dd/M/yyyy H:mm a');

export const formatDateFromNow = (fecha) => {
  //TODO: Change locale according to config
  return __('global.dateDistance', { distance: formatDistanceToNowStrict(new Date(fecha), {locale: es}) });
};
