import React from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import ImportJSONButton from '../components/common/ImportJSONButton';
import SemanticTag from '../components/semantic/SemanticTag';
import { SwitchInput } from '../components/common/SwitchInput';
import { IconButton } from '../components/common/IconButton';
import submenuTagging from './menus/submenu-tagging'

const linguisticNoteId = (text, semanticKey) => `${text}|${semanticKey}`;

const toByte = (a = 0,b = 1) => Math.round(255*(a/b));
const toPerc = (x = 1,total = 1, precision = 0) => (x/total*100).toFixed(precision)+'%';

function topN(semLinguistic, n = 10) {
  return _.toPairs(semLinguistic._default || { '-': 0 }).slice(0, n);
}

function LinguisticTop({ data, left }) {
  data = data || {_default: {'-': 0}};

  return <div className={'mr-5'}>
    {_.map(topN(data), ([text, count], i) => {
      const countFull = data._defaultFull?.[text] || 0;
      const countNonAmb = data._defaultNonAmb?.[text] || 0;

      const colA = toByte(countNonAmb, count)/2+128;
      const colB = toByte(countFull,count)/2+128;
      const color = {
        // background: `rgba(255,${toByte(countNonAmb, count)},${toByte(countFull,count)}, 0.3)`
        background: `linear-gradient(${left ? '-' : ''}90deg, rgba(${colB},${colB},255,1) 0%, rgba(255,${colA},${colA},1) 100%)`
      };

      const title = `Ambiguos parse: ${toPerc(count - countNonAmb,count)} - Partial parse: ${toPerc(count - countFull, count)}`;

      const textSpan = <span className={i ? 'text-secondary' : 'font-weight-bold'}>{text}</span>;
      const countSpan = <span style={{ minWidth: '40px' }} className={'small text-primary inline-block text-right mr-2'}>{count}</span>;

      if (!left) {
        return <div style={color} title={title} key={i + text} className={'text-right'}>{textSpan} {countSpan}</div>;
      } else {
        return <div style={color} title={title} key={i + text} className={'text-left'}>{countSpan} {textSpan}</div>;
      }
    })}
  </div>;
}

export default class LinguisticComparison extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;

    this.submenu = submenuTagging;
    this.state.modelId = 'Ford-Focus';
    this.state.limit = 40;
    this.state.filter = '';
    this.state.a = {};
    this.state.b = {};
    this.state.differences = [];
    this.state.all = [];
    this.state.onlyDifferences = true;
    this.state.onlyMissing = false;

    this.nlpService = window.client.service('/services/semantic/nlp');

    // this.semantics = this.service('services/tagging/hierarchy');
    // this.notes = new NotesManager('tagging-linguistics', this.getLoggedUserSignature());
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.fetchData(sampleDataA, sampleDataB));
  }

  async fetchData(a, b) {
    let differences = [];
    let all = [];
    _.each(_.union(_.keys(a), _.keys(b)), (sem) => {
      const lingB = b[sem];
      const lingA = a[sem];

      if(!lingB || !lingA) {
        const count = (lingA || lingB)._countTotal;
        const diff = {sem, count, changed: true, missing: true};
        differences.push(diff);
        all.push(diff);
      } else{
        const topB = topN(lingB)[0];
        const topA = topN(lingA)[0];
        const diff = {sem, count: Math.max(lingA._countTotal, lingB._countTotal), changed: topB[0] !== topA[0]};
        if(diff.changed) {
          differences.push(diff);
        }
        all.push(diff)
      }
    })

    differences = _.sortBy(differences, 'count').reverse()
    all = _.sortBy(all, 'count').reverse()
    // all = _.shuffle(all);

    this.setState({ a, b, differences, all });
  }

  onFilesLoaded(files) {
    if(files.length >= 1) {
      this.runAsync(this.fetchData(files[0], files[1] || { }));
    } else {
      alert('You need to select exactly 2 JSON files with the linguistic')
    }
  }

  modelChanged({ modelId }) {
    this.setState({ modelId }, () => this.fetchData());
  }

  filterChanged(e) {
    this.setState({ filter: e.target.value, limit: 40 });
  }

  renderDifferences() {
    let { a, b, filter, differences, all, onlyDifferences, onlyMissing } = this.state;

    const filterFunc = (rows) => {
      if(filter) {
        let re;
        let regexFormat = filter.match(/^\/(.+)\/$/);
        if(regexFormat) {
          try {
            re = new RegExp(regexFormat[1], 'i');
          } catch(err) {
            re = new RegExp(_.escapeRegExp(regexFormat[1]), 'i');
          }
        } else {
          re = new RegExp(_.escapeRegExp(filter), 'i');
        }

        return _.filter(rows, ({sem, count}) => {
          return count > 0 && sem.match(re);
        })
      } else {
        return rows;
      }
    };

    let rows = [];

    let filteredDifferences = filterFunc(differences);
    if(onlyDifferences && onlyMissing) {
      filteredDifferences = _.filter(filteredDifferences, ({missing}) => missing);
    }

    let diffTotal = _.sum(_.map(filteredDifferences, 'count'));
    let total = _.sum(_.map(all, 'count'));


    const showAncestors = sem => {
      this.runAsync(async () => {
        let res = await this.nlpService.find({ query: { operation: 'ancestors', params: { semantic: sem } } });

        this.modalActionsBus.emit('open', <div>
          Parents de <SemanticTag semKey={sem} level={'primary'}/>:
          <table>
            <tbody>
            {_.map(res.sort(), s => {
              let [text, count] = topN(a[s] || b[s] || {})[0];
              return <tr key={s}>
                <td>
                  <SemanticTag semKey={s} level={'secondary'}/>
                </td>
                <td>
                  <span className={'text-primary mx-3'}>{count}</span>
                </td>
                <td>
                  <em>{text} </em>
                </td>
              </tr>;
            })}

            </tbody>
          </table>
          {!res.length ? <div className={'text-center alert alert-info mt-1'}>No useful parents</div> : null}
        </div>);
      })
    };


    for (const {sem, count, changed} of onlyDifferences ? filteredDifferences : filterFunc(all)) {

      if(rows.length < 100) {
        const sA = a[sem];
        const sB = b[sem];

        rows.push(<tr key={sem}>
          <td className={'text-center monospace text-info text-break'}>
            { sem } <IconButton onClick={() => showAncestors(sem)} icon={'segment'} level={'secondary'}/>
          </td>

          <td className={'text-center'}>
            <div className={'text-primary'}>{ count }</div>
            <div className={'small text-secondary'}>{ (count/total*100).toFixed(2) }%</div>
          </td>

          <td className={!sA ? 'bg-light-danger' : changed ? 'bg-light-warn' : ''}>
            <LinguisticTop data={sA}/>
          </td>

          <td className={!sB ? 'bg-light-danger' : changed ? 'bg-light-warn' : ''}>
            <LinguisticTop left data={sB}/>
          </td>
        </tr>);
      }
    }

    const semsRatio = (filteredDifferences.length/all.length*100).toFixed(1);
    const countsRatio = (diffTotal/total*100).toFixed(1);

    return <div>
      <div className={'m-3'}>
        <h4 className={'text-center'}>There are {differences.length} semantics with a different top 1 (of {_.keys(a).length}/{_.keys(b).length}):</h4>
        <h5 className={'text-center text-secondary'}>({semsRatio}% of semantics, {filteredDifferences.length}, involving {countsRatio}% of total counts)</h5>
      </div>
      <table className={'table table-sm'}>
        <thead>
        <tr className={'text-center'}>
          <th style={{ width: '30%' }}>Semantic</th>
          <th style={{ width: '10%' }}>Total count</th>
          <th style={{ width: '30%' }}>A</th>
          <th style={{ width: '30%' }}>B</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    </div>;
  }

  renderPageBody() {
    const { data, filter, limit, onlyDifferences, onlyMissing } = this.state;

    let filteredData = data || [];

    let res = <div>
      <div className={'row d-flex align-items-center no-gutters bg-light pb-1'}>
        <div className={'p-2 col-4'}>
          <ModelContextEditor value={{ modelId: this.state.modelId }} onChange={this.modelChanged.bind(this)}/>
        </div>

        <div className={'p-2 col-4'}>
          <input type={'text'} className={'form-control form-control-sm'} value={filter || ''}
                 placeholder={'Filter...'}
                 onChange={(e) => this.filterChanged(e)}/>
        </div>

        <ImportJSONButton multi={true} onChange={(files) => this.onFilesLoaded(files)}/>

        <SwitchInput value={onlyDifferences} onChange={(val) => this.setState({onlyDifferences: val})}>
          Only show differences
        </SwitchInput>

        {
          onlyDifferences ? <SwitchInput value={onlyMissing} onChange={(val) => this.setState({ onlyMissing: val })}>
            Only missing linguistics
          </SwitchInput> : null
        }
      </div>
      <div>{this.renderDifferences()}</div>
    </div>;

    return res;
  }
}

const sampleDataA = {
  'CACO': {
    '_countTotal': 5,
    _default: {
      'kko': 10
    }
  },
  'IGUAL': {
    '_countTotal': 5,
    _default: {
      'nais': 5
    }
  },
  'EJE': {
    '_countTotal': 55,
    '_default': {
      'eje': 113,
      'arbol': 75,
      'los ejes': 18,
      'arboles': 12,
      'ej': 6
    },
    '_defaultFull': {
      'eje': 113,
      'arbol': 75,
      'los ejes': 18,
      'arboles': 12,
      'ej': 6
    },
    'chevrolet': {
      '_default': {
        'arbol': 24
      }
    }
  }
};

const sampleDataB = {
  'TESTIGO_CHECK_ENGINE': {
    '_countTotal': 10,
    _default: {
      'kk': 10
    }
  },
  'IGUAL': {
    '_countTotal': 25,
    _default: {
      'nais': 25
    }
  },
  'EJE': {
    '_countTotal': 255,
    '_default': {
      'arbol': 369,
      'eje': 346,
      'ejes': 71
    },
    'renault': {
      '#default': {
        'eje': 32
      }
    },
    'suzuki': {
      '#default': {
        'eje': 12
      }
    },
    'mitsubishi': {
      '#default': {
        'eje': 21
      },
      'eclipse  ': {
        'arbol': 5
      }
    },
    'hyundai': {
      '#default': {
        'eje': 22
      }
    },
    'toyota': {
      '#default': {
        'eje': 16
      }
    },
    'honda': {
      '#default': {
        'eje': 10
      }
    },
    'fiat': {
      '#default': {
        'eje': 11
      }
    },
    'peugeot': {
      '#default': {
        'eje': 28
      },
      '207  ': {
        'arbol': 7
      }
    },
    'volkswagen': {
      'gol  ': {
        'eje': 17
      },
      'polo  ': {
        'eje': 5
      }
    },
    'chevrolet': {
      'cruze  ': {
        'los arboles': 8
      },
      's10  ': {
        'eje': 9
      },
      'aveo  ': {
        'eje': 8
      }
    },
    'nissan': {
      'platina  ': {
        'los arboles': 7
      },
      'march  ': {
        'eje': 5
      }
    },
    'ford': {
      'ranger  ': {
        'eje': 5
      },
      'escape  ': {
        'eje': 5
      }
    },
    'dodge': {
      'ram  ': {
        'eje': 5
      },
      'journey  ': {
        'ejes': 6
      }
    }
  }
}
