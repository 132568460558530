import React, { Component } from 'react';
import _ from 'lodash';

import LegosManager from '../LegosManager';
import ModelContextEditor from '../../components/lego/ModelContextEditor';
import { LegoFiltersBar } from '../../components/common/LegoFiltersBar';


export default class LegoSpecsManager extends LegosManager {
  constructor(... args) {
    super(... args);
    this.state.facetFilters = (this.state.facetFilters || {})
    this.state.facetFilters.type = 'data'
    this.state.facetFilters.intentions = ['spec', 'spec-summary']
  }

  getFacetFiltersComponent() {
    const fields = ['locales', 'context', 'state', 'labels', 'semantics'];
    return <LegoFiltersBar fields={fields} onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  onAddNewClick(overrides) {
    // Override defaults for new Button
    super.onAddNewClick({
      type: 'data',
      intentions: ["spec"],
      semantics: ["TIPO_DE_ACEITE_MOTOR"],
      data: {},
      state: 'inprogress'
    })
  }
}
