import React from 'react';

import TaskEditorManualLegoExtraction from "./TaskEditorManualLegoExtraction";
import ManualFeaturesWarningLightsEditor from './manuals-extraction/ManualFeaturesWarningLightsEditor';
import _ from 'lodash';

export default class TaskEditorManualWarningLightsExtraction extends TaskEditorManualLegoExtraction {
  constructor(props) {
    super(props);

    this.tasksService = window.client.service('services/data-tasks');
    this.state.pdfInitialSearch = /\b(indicador|instrum|testigo|warning lights|indicator light|luces indica|luz de advertencia)/.source
  }

  async save(changedManual, legosUpdates, markTaskDone) {
    if(changedManual) {
      console.log('Updating manual features',changedManual.features);
      await this.updateManual(changedManual._id,  { $set: { features: changedManual.features } });
    }

    const asyncOperations = _.compact(_.map(legosUpdates, (lego) => {
      if(lego.__new && !lego._id) {
        lego.createdBy = this.getLoggedUserSignature();
        lego.updatedBy = lego.createdBy;

        return this.legoService.create(lego);
      } else if(lego.__deleted) {
        return this.legoService.remove(lego._id);
      } else if(lego.__unsaved) {
        lego.updatedBy = this.getLoggedUserSignature();
        return this.legoService.update(lego._id, lego);
      }
    }));


    console.log(`Doing ${asyncOperations.length} CRUD async operations...`)
    let responses = await Promise.all(asyncOperations);

    // TODO: The right way to prevent creating the same task twice would be checking that there is no task for that lego
    // of the same type
    if(this.props.task.state !== 'complete' && markTaskDone) {
      // Dubious patch: extract version (as 'v2') from task batch id
      let [, version] = this.props.task.batchId.match(/-(v\d)/) || [];

      // TODO: Remove the map, seems unnecesary in this data task, there is always one op
      const createCLusteringTasks = _.map(responses, res => {
        return this.tasksService.create({
          typeId: 'warningLightsClustering',
          batchId: `batchWarningLightsClustering-${version || 'OTHER'}`,
          impact: 0,
          context: res.context,
          state: '',
          input: {
            id: res._id,
          },
          output: {}
        });
      });

      console.log(`Creating ${createCLusteringTasks.length} clustering DataTasks...`)
      await Promise.all(createCLusteringTasks);
    }

    if(markTaskDone) {
      await this.doneAndNext();
    }
  }

  renderExtractionControls() {
    let { file, fileHash, autosuggestions } = this.state;

    if (file) {
      return <ManualFeaturesWarningLightsEditor manual={file}
                                                key={fileHash + autosuggestions}
                                                initialChanges={autosuggestions}
                                                onSave={async (changedManual, legosUpdates, markTaskDone) => this.save(changedManual, legosUpdates, markTaskDone)}
                                                shortcuts={this.shortcuts}/>
    } else {
      return "No manual"
    }
  }
}
