import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {UserAvatar} from "./common/UserAvatar";
import { IconButton } from './common/IconButton';
import { LeftRightBox } from '../../../components/common/LeftRightBox';

function SubmenuLink({to, i, location, display}) {
  return <Link to={to} key={to + i}
               className={'btn rounded-0 p-1 px-2 btn-sm ' + (location.pathname.startsWith(to) ? 'btn-secondary' : 'btn-link')}>
    {display}
  </Link>;
}

export default class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    }
  }

  logout() {
    delete localStorage['accessToken'];
    delete localStorage['user'];
    window.location.reload();
  }

  toggleExpanded() {
    this.setState({collapsed: !this.state.collapsed}, () => {
      // Hack to trigger layout calculations of components that measure their parents. Such as fusebox editor
      window.dispatchEvent(new Event('resize'));
    })
  }

  render() {
    let {menu, submenu, location} = this.props;

    let sections = null;

    // If user is not admin, just leave menus that explicitely allow contributors
    if (!window.user.isAdmin) {
      menu = _.filter(menu, m => m.contributorsAllowed);
      submenu = _.filter(submenu, m => m.contributorsAllowed);
    }

    let submenuLeft = _.filter(submenu, m => !m.right);
    let submenuRight = _.filter(submenu, m => m.right);

    sections = <div className={'btn-group'}>
      <Link to={'/'} className={'btn text-white p-2 m-0 ml-2 mr-2'}><img style={{width: '16px'}} src={'/favicon.ico'}/>
      </Link>
      {
        menu.map(({to, display},i) => <Link to={to} key={to+i}
                                            className={'btn rounded-0 mr-1 '+(location.pathname.startsWith(to) ? 'bg-light-secondary text-white' : 'btn-link')}>
          {display}
        </Link>)
      }
    </div>;


    let subsections = null;
    if (submenu && submenu.length) {
      subsections =
        <div className="bg-light-secondary border border-secondary border-left-0 border-right-0 border-top-0">
          <div className={'d-flex justify-content-between'}>
            <span>
              {submenuLeft.map(({ to, display }, i) => <SubmenuLink key={to} to={to} i={i} location={location} display={display}/>)}
            </span>

            <span>
              {submenuRight.map(({ to, display }, i) => <SubmenuLink  key={to} to={to} i={i} location={location} display={display}/>)}
            </span>
          </div>
        </div>;
    }

    let sessionInfo = <span className={'mr-4'}>{
      this.props.connectionStatus ?
        <span
          className={`badge badge-${this.props.connectionStatus === 'connected' ? 'success' : 'warning'}`}>
          {this.props.connectionStatus}
        </span> : null
    }
      <span className={'inline-block m-1 ml-2 monospace'}><UserAvatar user={window.user}/> {window.user.username}</span>

      <button onClick={() => this.logout()} className={'btn btn-link btn-xs ml-2'}>Logout</button>
    </span>;

    const hideShowMenuBtn = <div className={'position-absolute text-right'} style={{zIndex: 999999, right: '0', minWidth: '50px'}}>
      <IconButton icon={this.state.collapsed ? 'menu' : 'expand_less'} className={'mt-1 mr-1 bg-light shadow'} onClick={() => this.toggleExpanded()}>
      </IconButton>
    </div>;


    if(this.state.collapsed)
        return hideShowMenuBtn

    return <div className='bg-dark p-0 container-fluid'>
      {hideShowMenuBtn}
      <div className="d-flex align-items-center justify-content-between text-white">
        <div className='bg-dark d-flex align-items-center'>
          {sections}

          {sessionInfo}
        </div>

        {this.props.children}
      </div>
      {subsections}
    </div>;
  }
}
