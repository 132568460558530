import React from 'react';

import LiveSubpage from '../../pages/LiveSubpage';
import TopBarLayout from '../common/layout/TopBarLayout';
import { ImageClusterSelector } from '../images/ImageClusterSelector';
import TemplatedFuseboxDiagram from '../lego/fusebox-editor/TemplatedFuseboxDiagram';
import { ImagePreview } from '../images/ImagePreview';
import { IconButton } from '../common/IconButton';
import { DataFeedbackSummary } from '../data-issues/DataFeedbackSummary';
import _ from 'lodash';
import Tabs from '../common/layout/Tabs';
import { DataIssuesFinder } from '../data-issues/DataIssuesFinder';
import BadgeId from '../common/BadgeId';
import urls from '../../../../components/interop/urls';
import { DataIssuesForClusterAssistant } from '../data-issues/DataIssuesForClusterAssistant';


export default class TaskEditorImgFuseboxClustering extends LiveSubpage {
  constructor(props) {
    super(props);

    this.imageService = window.client.service('services/data/images');
    this.legoService = window.client.service('services/legos');
    this.clusterSvc = window.client.service('services/data/image-clusters');
    this.dataFeedbackSvc = window.client.service('services/data/data-feedback');

    this.state = {
      ...this.state,
      showTemplates: true,
      templates: [],
      currentCluster: null,
      selectedTab: props.state === 'complete' ? 1 : 0
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.loadImageMetadata());
    this.runAsync(this.getModelFuseboxLegos());
  }


  async loadImageMetadata() {
    const imageId = this.props.task.input.id;

    let images = await this.imageService.find({ query: { '_id': imageId } });

    if (images.length) {
      this.setState({ image: images[0] });
    }

    let clusters = await this.clusterSvc.find({
      query: {
        'images.id': imageId,
        clusterType: 'fusebox-broad'
      }
    });
    if (clusters.length) {
      this.setState({ currentCluster: clusters[0] });
    }
  }

  async getModelFuseboxLegos() {
    let { context } = this.props.task || {};
    let legos = await this.legoService.find({
      query: { type: 'fusebox', context: { $elemMatch: { modelId: context[0].modelId } } }
    });

    if (legos.length) {
      // Inject year to each box to use it later
      _.each(legos, l => _.each(l.data, box => box.year = _.map(l.context, 'year')));

      // Group boxes by template
      let fuseboxesByTemplateId = _.groupBy(_.flatMap(legos, 'data'), 'templateId');
      let templateIds = _.without(_.keys(fuseboxesByTemplateId), 'undefined');

      // Fetch templates used by fuseboxes
      let templates = await this.legoService.find({ query: { _id: {$in: templateIds}, $select: {_id: true, 'data.imageClusters': true} } });

      // Expand image clusters of template to the first representative fusebox
      for(const t of templates) {
        const clusters = t.data[0].imageClusters;
        if(clusters?.length) {
          fuseboxesByTemplateId[t._id][0].imageClusters = clusters;
        }

        // Save a sample for previews
        t.fuseboxRef = fuseboxesByTemplateId[t._id][0]
      }
      // Exclude the undefined templateId from the results
      delete fuseboxesByTemplateId.undefined;

      // Set the year range to the first box of each template
      _.each(fuseboxesByTemplateId, (boxes) => boxes[0].years = `${_.min(_.flatMap(boxes, 'year'))}-${_.max(_.flatMap(boxes, 'year'))}`)

      this.setState({ legos: _.map(fuseboxesByTemplateId, '0'), templates });
    }
  }

  renderPageBody() {
    let { error, image, legos, showTemplates, templateId, templates, selectedTab, currentCluster } = this.state;

    let { context, state, _id, input: { id, dataFeedbackId } } = this.props.task || {};

    const onApprove = async () => {
      if(dataFeedbackId) {
        await this.dataFeedbackSvc.update(dataFeedbackId, {$set: {awaiting: false}})
      }
      await this.props.batchEditor.doneAndNext();
    };

    const onSkip = async () => {
      await this.props.batchEditor.doneAndNext();
    };

    const handleTemplateSelect = (lego) => {
      if (this.state.templateId === lego.templateId) {
        this.setState({ templateId: null });
      } else {
        this.setState({ templateId: lego.templateId });
      }
    };

    let extraDataBar;
    if (legos?.length) {
      extraDataBar = <div>
        <div><IconButton icon={showTemplates ? 'expand_less' : 'expand_more'}
                         onClick={() => this.setState({ showTemplates: !showTemplates })}>Fusebox Templates</IconButton>
        </div>
        {showTemplates ?
          _.map(legos, lego => {
            const selected = this.state.templateId === lego.templateId;

            const used = !!lego.imageClusters?.length;

            return <span key={lego.templateId} className={`btn m-05 p-1 btn-${selected ? 'primary' : (used ? 'success' : 'light')}`}
                         style={{ minHeight: '100px' }}
                         onClick={() => handleTemplateSelect(lego)}>
              <div className={'small zoom-75'}>{lego.years}</div>
              <div className={'small mb-1 zoom-75'}>{lego.boxName}</div>

              {lego.boxDiagramImg?.url ?
                <ImagePreview url={lego.boxDiagramImg.url} zoom img100/>
                :
                <div style={{ height: '200px', maxWidth: '250px' }}>
                  <TemplatedFuseboxDiagram hideTittle={true} fusebox={lego}/>
                </div>
              }
            </span>;
          })
          :
          null}
      </div>;
    }

    const isComplete = state === 'complete';

    let vehicleContext = context[0];

    let modelYearContext = _.pick(vehicleContext, 'modelId', 'year');

    const openDataIssueAssistant = () => {
      if (this.state.currentCluster && !isComplete) {
        this.openModal(<div>
          <DataIssuesForClusterAssistant vehicleContext={modelYearContext}
                                         dataFeedbackId={dataFeedbackId}
                                         dataFeedbackImage={this.state.image}
                                         onAction={() => {
                                           this.closeModal();
                                           this.runAsync(onApprove);
                                         }}
                                         cluster={this.state.currentCluster}/>
        </div>, { title: 'Data issue for feedback assistant...' });
      }
    }

    const onClusterChange = (cluster, changedImage) => {
      if (changedImage.id === image?._id) {
        this.setState({ currentCluster: cluster }, openDataIssueAssistant);
      }
    };

    return <React.Fragment>
      <div className={'grid-pdf-extraction'}>
        <div className={'pos-relative bg-secondary'}>
          {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

          <Tabs names={['Image clustering', 'Data issue creation']}
                tabIndex={selectedTab}
                onTabChange={(tabIndex) => this.setState({selectedTab: tabIndex})}>

            <div className={'p-1 full-screen-center'}>
              {image ? <ImageClusterSelector context={vehicleContext}
                                             clusterType={'fusebox-broad'}
                                             workingImage={image}
                                             workingTemplateId={templateId}
                                             templates={templates}
                                             onClusterChange={onClusterChange}
                                             workingDataFeedbackId={dataFeedbackId}/>
                :
                'Couldn\'t find image corresponding to input.id'}
            </div>

            <div className={'bg-white overflow-auto'}>
              <DataIssuesFinder vehicleContext={modelYearContext}
                                dataFeedbackId={dataFeedbackId}
                                cluster={currentCluster}/>
            </div>
          </Tabs>
        </div>

        <div className={'bg-white'} id={'extractionInputArea'} key={id}>
          <DataFeedbackSummary id={dataFeedbackId}/>

          <div className={'text-center'}>
            Cluster: <BadgeId id={currentCluster?._id || '-'} />

            { !isComplete && currentCluster ? <IconButton icon={'auto_fix_high'} level={'primary'} onClick={openDataIssueAssistant}>Data issue assistant...</IconButton> : null }

          </div>


          <div className={'p-2 small text-center '+(currentCluster?._id ? '' : 'disabled-zone')}>
            <span className={`btn btn-${isComplete ? 'primary' : 'success'}`}
                  onClick={async () => this.runAsync(onApprove)}>
              {isComplete ? 'Resave and next' : 'Done and next'}
            </span>

            { !isComplete && dataFeedbackId ?
            <span className={`ml-1 btn btn-dark btn-sm`} onClick={async () => this.runAsync(onSkip)}>
              Skip feedback and next
            </span> : null}
          </div>

          <div className={'border border-left-0 border-right-0'}>
            {this.props.batchEditor.getTaskStateControls()}
          </div>
          {extraDataBar}
        </div>
      </div>
    </React.Fragment>;
  }
}
