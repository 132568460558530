import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncData from '../../../../components/common/effects/useAsyncData';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import React, { useContext } from 'react';
import _ from 'lodash';

export function DataTaskTypeSelector({ value, onChange, extraOptions, ...other }) {
  let { page } = useContext(LegoAdminPageContext);

  let facetOptions = useAsyncData('taskType', async () => page.service('services/data-tasks/batches').
                                                           get('distinctTypeId'), []);

  let options = _.union(facetOptions, extraOptions)

  return <SingleValueSelector placeholder={'Filter by task type...'}
                              value={value}
                              onChange={onChange}
                              options={options} {...other}/>;
}
