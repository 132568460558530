import React, {Component} from 'react';

import cdn from "../interop/cdn";
import { ImgIcon } from './ImgIcon';

export function Btn({ children, small, xs, primary, secondary, material, className, icon, href, ...other }) {
  const classes = ['Button'];

  if(small) {
    classes.push('Button--small')
  } else if(xs) {
    classes.push('Button--xs')
  }

  const level = primary ? 'primary' : (secondary ? 'secondary' : 'secondary');

  classes.push(`Button--${level}`);
  if(className) {
    classes.push(className);
  }

  let iconImg = null;
  if (icon) {
    if (material) {
      let iconVariation = primary ? 'white' : 'primary';
      iconImg = <ImgIcon material icon={icon} color={iconVariation}/>;
    } else {
      let iconVariation = primary ? '--white' : '--blue';
      iconImg = <img className="LinkButton__icon LinkButton__icon--small mr-1"
                     src={cdn(`/images/design2/icons/icon_${icon}${iconVariation}.svg`)}/>;
    }
  }

  if (href) {
    return <a className={classes.join(' ')} href={href} {... other}>
      {iconImg}
      {children}
    </a>;
  } else {
    return <span className={classes.join(' ')} {...other}>
      {iconImg}
      {children}
    </span>;
  }
}
