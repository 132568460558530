import React, {Component} from "react";
import _ from 'lodash';
import {IconButton} from "../../common/IconButton";
import TrimSpecsEditor from "../trims/TrimSpecsEditor";
import MultipleItemsEditor from "../MultipleItemsEditor";
import MagnitudeEditor from "../../common/editors/MagnitudeEditor";
import SingleValueSelector from '../../common/editors/SingleValueSelector';

const psiToKpa = psi => Math.round(6.89476*psi)
const psiToBar = psi => Math.round(0.0689476*psi*10)/10
const kpaToPsi = kpa => Math.round(kpa / 6.89476)
const kpaToBar = kpa => Math.round(0.01*kpa*10)/10
const barToKpa = bar => Math.round(bar*100)
const barToPsi = bar => kpaToPsi(bar*100);

export default function TirePressureLegoEditor({ value, onChange }) {
  const updateContext = (newContext) => {
    if (newContext.length > 0) {
      let lego = _.cloneDeep(value);
      lego.context = newContext;
      onChange(lego);
    }
  }

  const updateData = (field, newValue) => {
    let lego = _.cloneDeep(value);

    lego.data[field] = newValue;

    if(field === 'pressurePsi') {
      lego.data['pressureKpa'] = psiToKpa(newValue);
      lego.data['pressureBar'] = psiToBar(newValue);
    } else if(field === 'pressureKpa') {
      lego.data['pressurePsi'] = kpaToPsi(newValue);
      lego.data['pressureBar'] = kpaToBar(newValue);
    } else if(field === 'pressureBar') {
      lego.data['pressurePsi'] = barToPsi(newValue);
      lego.data['pressureKpa'] = barToKpa(newValue);
    }

    // Remove null fields in data
    lego.data = _.pickBy(lego.data, v => v);


    onChange(lego);
  }

  const { context, data, _id } = value;

  const { condition, tireLocation, pressurePsi, pressureKpa, pressureBar } = data || {};

  // Context

  let contexts = _.isArray(context) ? context : [context];
  // Remove 'trims' field from context, as it is useless and should be fixed in original manual's contexts
  contexts = _.map(contexts, ({ trims, ...restOfContext }) => restOfContext)

  let header = null;
  let stateBg = 'success'
  if (_id) {
    header = <div className={'small text-secondary '}>Lego {_id} <IconButton icon={''}/></div>
    stateBg = 'secondary'
  }

  let defaultNewContext = {};
  if (contexts[0] && contexts[0].modelId && contexts[0].year) {
    defaultNewContext = { modelId: contexts[0].modelId, year: contexts[0].year };
  }

  const contextEditor = <MultipleItemsEditor value={contexts}
                                             defaultNewItem={defaultNewContext}
                                             buttonsAlwaysVisible={true}
                                             renderComponent={(item, props) => <TrimSpecsEditor
                                               value={item} {...props}/>}
                                             onChange={(specs) => updateContext(specs)}
  />

  return <div className={`rounded p-1 bg-light-${stateBg} zoom-90`}>
    {header}

    {contextEditor}

    <div className={'row row-cols-2 my-2 mx-1'}>
      <SingleValueSelector value={tireLocation} options={['front', 'rear', 'spare']}
                           onChange={v => updateData('tireLocation', v)} />

      <SingleValueSelector value={condition} options={['all', 'normal load', 'max load', 'highway', 'no-highway']}
                           onChange={v => updateData('tireLocation', v)} />
    </div>

    <div className={'d-flex justify-content-between'}>
      <MagnitudeEditor value={{amount: pressurePsi}} onChange={(v) => updateData('pressurePsi', v.amount)} small
                       unit={'psi'}/>
      <MagnitudeEditor value={{amount: pressureKpa}} onChange={(v) => updateData('pressureKpa', v.amount)} small
                        unit={'kpa'}/>
      <MagnitudeEditor value={{amount: pressureBar}} onChange={(v) => updateData('pressureBar', v.amount)} small
                       unit={'bar'}/>
    </div>
  </div>;
}

