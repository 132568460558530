import EventEmitter from 'events';
import React, { Component } from 'react';
import ModalManager from '../../ModalManager';
import _ from 'lodash';
import { IconButton } from '../IconButton';
import LegoEditor from '../../lego/LegoEditor';
import { LegoPreview } from '../../lego/LegoPreview';
import LegoSearchDialog from '../../lego/LegoSearchDialog';

export class LegoReferenceSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.legoService = window.client.service('services/legos');
    this.modalActionsBus = new EventEmitter();

    if(props.value && props.value._id) {
     this.updateLegoPreview(props.value._id);
    }

    this.newTemplate = this.props.legoTemplate || {state: 'inprogress'};
  }

  updateLegoPreview(legoId) {
    this.legoService.get(legoId).then(lego => {
      return this.setState({ lego });
    }).catch(this.handleError);
  }

  delete() {
    this.props.onChange(null);
  }

  getLoggedUserSignature() {
    //TODO: Repeated code
    if(window.user) {
      return {
        username: window.user.username,
        id: window.user.id
      };
    } else {
      return null;
    }
  }

  async updateOrCreate(obj, changedObj) {
    // changedObj will have _id if is an update, otherwise is a save on an new unsaved original obj
    if (changedObj._id) {
      changedObj.updatedBy = this.getLoggedUserSignature();
      return await this.legoService.update(changedObj._id, changedObj);
    } else {
      changedObj.createdBy = this.getLoggedUserSignature();
      changedObj.updatedBy = changedObj.createdBy;

      let newObj = await this.legoService.create(changedObj);
      this.setState({lego: newObj})
      this.props.onChange({_id: newObj._id})
    }
  }

  async openSearchLego() {
    this.modalActionsBus.emit('open', <div>
      <LegoSearchDialog legoFilter={this.props.legoFilter} onSave={async (selectedLego) => {
        this.props.onChange({_id: selectedLego._id})
        this.setState({lego: null})
        this.updateLegoPreview(selectedLego._id);
        return this.modalActionsBus.emit('close');
      }} onCancel={() => {
        return this.modalActionsBus.emit('close');
      }}/>
    </div>);
  }

  async openCreateLego(legoOptions) {
    let freshLego = legoOptions || this.newTemplate;

    let legoEditoRef = React.createRef();

    this.modalActionsBus.emit('open', <div>
      <LegoEditor ref={legoEditoRef} lego={freshLego} onSave={async (changedLego, closeDialog) => {
        try {
          let res = await this.updateOrCreate(freshLego, changedLego);
          if(closeDialog) {
            this.modalActionsBus.emit('close');
          }
          return res;
        } catch (err) {
          this.handleError(err);
        }
      }} onCancel={() => {
        return this.modalActionsBus.emit('close');
      }}/>
    </div>, {
      // onClose: () => this.deleteUrlParam('editing'),
      closeConfirmationCbk: () => {
        if(legoEditoRef.current && legoEditoRef.current.state.changed) {
          return confirm('Are you sure you wanna close it? You will lose all your changes!');
        } else {
          return true;
        }
      }});
  }

  handleError(err) {
    alert((err || '').toString())
  }

  render() {
    const legoRef = this.props.value;
    let {lego} = this.state;
    let result = null;

    if(legoRef) {
      if(lego) {
        result = <div className={'form-control d-flex justify-content-between'} style={{height: 'auto'}}>
          {/*<IconButton icon={'edit'} level={'primary'} onClick={() => this.openCreateLego()}/>*/}
          <div className={'px-2'}><LegoPreview lego={this.state.lego}/></div>
          <IconButton icon={'clear'} level={'secondary'} onClick={() => this.delete()}/>
        </div>
      } else if(lego === null) {
        result = <div className={'form-control text-danger'}>
          Lego with id '{legoRef._id}' not found.
          <IconButton icon={'clear'} level={'secondary'} onClick={() => this.delete()}/>
        </div>
      } else {
        result = <div className={'form-control'}>
          Loading preview of Lego {legoRef._id}...
          <IconButton icon={'clear'} level={'secondary'} onClick={() => this.delete()}/>
        </div>
      }
    } else {
      result = <div className={'form-control d-flex justify-content-around'}>
        <IconButton icon={'search'} onClick={() => this.openSearchLego()}>Pick existing lego...</IconButton>
        <IconButton icon={'create'} onClick={() => this.openCreateLego()}>Create new lego...</IconButton>
      </div>
    }

    return <div className={''}>
      {result}
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
