import _ from "lodash";
import React from 'react';

import {Link} from "react-router-dom";
import StatsTable from "./StatsTable";

const displayMissingYears = (missingYears, pre1990traffic) => {
  if ((missingYears && missingYears.length) || pre1990traffic) {
    let missing = null;
    let oldmodels = null;
    if (missingYears) {
      missing = <span className={'small'}>Vehicles in missing years: {
        missingYears.map(({ year, traffic }) => <span key={year}> <span className={'badge badge-info'}>{year}</span> <span className={'badge badge-light'}>{traffic.toFixed(0)}%</span> </span>)
      }</span>;
    }

    if (pre1990traffic && pre1990traffic > 10) {
      oldmodels = <span className={'badge badge-warning'}> <strong className={''}>{pre1990traffic.toFixed(0)}%</strong> of vehicles before 1990</span>;
    }

    return <span className={'text-secondary ml-1'}>{missing}{oldmodels}</span>;
  } else {
    return null;
  }
}


export default class StatsTrimSources extends StatsTable {
  constructor(title, slug, country) {
    super(title, slug)

    this.country = country;

    this.modelMetadataService = this.data.service('services/models-metadata');
  }

  async addCountryToModelBlacklist(modelId, countryCode) {
    await this.modelMetadataService.patch(null, {
      $push: { countriesBlacklist: countryCode },
      $set: { updatedBy: this.getLoggedUserSignature() }
    }, {query: {modelId}})

    await this.fetchStats()
  }

  async removeCountryFromModelBlacklist(modelId, countryCode) {
    await this.modelMetadataService.patch(null, {
      $pull: { countriesBlacklist: countryCode },
      $set: { updatedBy: this.getLoggedUserSignature() }
    }, {query: {modelId}})

    await this.fetchStats()
  }

  async fetchData() {
    let { trafficBreakdown: { pageviewsByModelId, modelsTrafficDistribution, modelYearsTrafficDistribution } } = await this.data.traffic(this.country)
    this.pageviewsByModelId = pageviewsByModelId
    this.modelsDist = modelsTrafficDistribution;
    this.modelYearDist = modelYearsTrafficDistribution;

    this.models = (await this.data.models()).models;

    let {   matchedTrimCounts, unmatchedTrimCounts, modelsNotInCountry } = await this.data.trims(this.country);
    // Set of modelIds from models WE KNOW were not sold in the country
    this.modelIdsNotSold = new Set(_.map(modelsNotInCountry, 'modelId'));

    let modelTrafficShareFixed = {... (this.modelsDist)}

    let mergeModels = {
      'Volkswagen-Gol power': 'Volkswagen-Gol',
      'Fiat-Palio Adventure': 'Fiat-Palio',
      "Volkswagen-Cross Fox": "Volkswagen-Fox",
      "Fiat-Idea Adventure": "Fiat-Idea",
      "Volkswagen-Nuevo Polo": "Volkswagen-Polo",
      "Chrysler-Grand Caravan": "Chrysler-Caravan",
      "Dodge-Grand Caravan": "Dodge-Caravan",
      "Renault-Stepway": "Renault-Sandero",
      "Fiat-Fire": "Fiat-Uno",

      // Wrong names DB <-> Trims
      // "hyundai-h-1": "hyundai-h1",
      // "chevrolet-clasic": "chevrolet-classic",
      // "325i": "325",
      // "toyota-prado": "toyota-land-cruiser", // trim prado
    }

    // Some models trims appear as different models in our DB, but in the same model in our trims.
    // In those cases, count traffic from subtrims in the corresponding trim modelId
    _.each(mergeModels, (to, from) => {
      (this.modelsDist)[to] = ((this.modelsDist)[to] || 0) + ((this.modelsDist)[from] || 0);
      modelTrafficShareFixed[to] = (modelTrafficShareFixed[to] || 0) + (modelTrafficShareFixed[from] || 0);
      delete modelTrafficShareFixed[from];
    })

    this.modelTrafficShareFixed = modelTrafficShareFixed

    this.unmatchedTrimsByModel = _.groupBy(unmatchedTrimCounts, g => g._id.modelId);
    this.unmatchedTrimsByModelYear = _.mapValues(this.unmatchedTrimsByModel, yearCounts => _.keyBy(yearCounts, o => o._id.year));
    this.unmatchedTrimsByModelTotal = _.mapValues(this.unmatchedTrimsByModel, yearCounts => _.sumBy(yearCounts, 'count'));

    this.matchedTrimsByModel = _.groupBy(matchedTrimCounts, g => g._id.modelId);
    this.matchedTrimsByModelYear = _.mapValues(this.matchedTrimsByModel, yearCounts => _.keyBy(yearCounts, o => o._id.year));
    this.matchedTrimsByModelTotal = _.mapValues(this.matchedTrimsByModel, yearCounts => _.sumBy(yearCounts, 'count'));

    this.missingYearsWarning = {}
    this.pre1990trafficByModel = {}

    this.modelIdsWithTrimSources = _.keyBy(_.uniq(_.map([...   matchedTrimCounts, ... unmatchedTrimCounts], g => g._id.modelId)), modelId => modelId)
  }

  groups() {
    return _.map(this.models, (model) => {
      let modelId = model.modelId;

      let coveredYearsTotal = 0;
      let coveredYearsRatio = 0;
      let pre1990traffic = 0;
      let missingYearsWarning = []

      _.each(this.modelYearDist[modelId], (modelYearTraffic, year) => {
        const m = _.get(this.matchedTrimsByModelYear, `${modelId}.${year}.count`, 0);
        const um = _.get(this.unmatchedTrimsByModelYear, `${modelId}.${year}.count`, 0);

        if(m || um) {
          coveredYearsRatio += modelYearTraffic * (m / (m + um))
          coveredYearsTotal += modelYearTraffic
        } else if(parseInt(year) >= 1990 && modelYearTraffic > 0.02) {
          missingYearsWarning.push({year, traffic: 100*modelYearTraffic})
        }

        if (parseInt(year) < 1990) {
          pre1990traffic += modelYearTraffic;
        }
      })

      pre1990traffic = pre1990traffic * 100;

      return {
        model,
        m: this.matchedTrimsByModelTotal[modelId] || 0,
        um: this.unmatchedTrimsByModelTotal[modelId] || 0,
        coverage: coveredYearsTotal ? coveredYearsRatio / coveredYearsTotal : 1,
        missingYearsWarning,
        pre1990traffic
      }
    })

    // return _.keys({ ... matchedTrimsByModel, ... unmatchedTrimsByModelYear, ... modelsDist})
  }

  groupKey({model}) {
    return model.modelId;
  }

  groupWeight(modelId, { model, m, um }) {
    return this.modelTrafficShareFixed[modelId] || 0
  }

  groupCoverage(modelId, { coverage }) {
    return coverage
  }

  searchFilters() {
    return {
      trims: () => (([modelId, { coverage, share }]) => {
        return this.modelIdsWithTrimSources[modelId];
      }),
      share: (n) => (([modelId, { coverage, share }]) => {
        return share > 0;
      })
    }
  }

  displayGroup(modelId, {model, m, um, pre1990traffic, missingYearsWarning}) {
    const modelIdWithTrim = this.modelIdsWithTrimSources[modelId]

    if (modelIdWithTrim) {
      const trimsUrl = `/trims?country=${encodeURIComponent(JSON.stringify(this.country))}&modelFilter=${encodeURIComponent(JSON.stringify({ modelId: modelIdWithTrim }))}`;
      return <div>
        <Link to={trimsUrl} target={'_blank'} className={'text-primary mr-1'}>{modelIdWithTrim}</Link>
        (<span className={'text-success'}>{m}</span>/<span className={'text-danger'}>{um}</span>)
        { displayMissingYears(missingYearsWarning, pre1990traffic) }
      </div>;
    } else {
      const countryCode = this.country.toUpperCase();

      if (this.modelIdsNotSold.has(modelId)) {
        return <div>{modelId}
          <span className={'text-secondary ml-2'}>(not sold in <strong>{countryCode}</strong>)</span>
          <a href={'javascript:void(0)'}
             className={'btn-link ml-2 small'}
             onClick={() => this.runAsync(this.removeCountryFromModelBlacklist(modelId, this.country))}>
            Add to {countryCode}
          </a>
        </div>;
      } else {
        return <div>
          {modelId} <span className={'text-danger'}>(no trims)</span>
          <a href={'javascript:void(0)'}
             className={'btn-link ml-2'}
             onClick={() => this.runAsync(this.addCountryToModelBlacklist(modelId, this.country))}>
            <span className={'small'}>Remove from models in {countryCode}</span>
            { displayMissingYears(missingYearsWarning, pre1990traffic) }
          </a>
        </div>;
      }
    }
  }
}