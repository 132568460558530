import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { StateBadge } from './StateBadge';
import BadgeId from '../common/BadgeId';
import { Icon } from '../common/Icon';
import NiceDate from '../common/NiceDate';
import { OpenClosedIcon } from './OpenClosedIcon';
import LegoContextSummary from '../lego/LegoContextSummary';
import ImageClusterPreview from '../images/ImageClusterPreview';
import { ImagePreview } from '../images/ImagePreview';
import TemplatedFuseboxDiagram from '../lego/fusebox-editor/TemplatedFuseboxDiagram';
import { IconButton } from '../common/IconButton';
import urls from '../../../../components/interop/urls';

function DataIssueSummary({ issue, currentFeedback }) {
  const {
    dataSelector, isOpen, createdAt, updatedAt, source, state, dataType, issueCategory, data, blockedBy, involvedDataFeedback,
    notes
  } = issue;

  let rowClass = '';

  const isInvolved = _.includes(involvedDataFeedback, currentFeedback);

  if(isOpen === false) {
    rowClass += ' bg-light-partial-success'
  } else if(blockedBy?.length) {
    rowClass += ' bg-light-secondary'
  }

  return <div className={'d-flex align-items-center justify-content-between '+rowClass}>
    <span className={'pl-3'}>
      <OpenClosedIcon isOpen={isOpen}/>
    </span>
    <span>
      <div><StateBadge state={state}/></div>
      { updatedAt && <NiceDate t={updatedAt}/> }
    </span>
    <span className={'text-center no-wrap'} title={`${involvedDataFeedback?.length || 0} involved data feedbacks`}>
      {involvedDataFeedback?.length ? <span>
        <Icon icon={'person'} level={isInvolved ? 'success' : 'secondary'} className={'h3 m-0'}/>
        <strong className={'align-middle'}>{involvedDataFeedback.length}</strong>
        {(isInvolved && issue._id) ? <h5>FEEDBACK IS ALREADY HERE</h5> : null}
      </span> : null }
    </span>
    <span>
      <span className={'badge badge-secondary'}>{issueCategory}</span><br/>
      <span className={'badge badge-light'}>{dataType}</span>
    </span>
    <span>
      <LegoContextSummary context={dataSelector.vehicle || {}}/>
    </span>

    <span>
      {blockedBy?.length ? <span className={'badge badge-dark'}>BLOCKED</span> : null}
    </span>
  </div>;
}


export function DataIssuesForClusterAssistant({ vehicleContext, cluster, dataFeedbackImage, dataFeedbackId, onAction }) {
  const { page } = useContext(LegoAdminPageContext);
  const dataIssuesService = page.service('/services/data/fusebox-data-issues');
  const dataFeedbackService = page.service('services/data/data-feedback');

  const getFuseboxUrl = async (fuseboxLego, model, modelYear) => {
    let { modelId, year, ... otherProps} = _.find(fuseboxLego.context, {year: modelYear, modelId: model});
    let modelo = await page.service('/services/data/models').get(modelId);
    //TODO: Refactor to library fot whole admin backend
    modelo.marcaUrlSafe = modelo.marca.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(' ', '-');
    modelo.modeloUrlSafe = modelo.slug;
    return urls.urlInfoFusebox(modelo, year, fuseboxLego._id.toString());
  }

  const legosService = page.service('/services/legos');

  const [dataIssues, setDataIssues] = useState(null);
  const [fuseboxes, setFuseboxes] = useState([]);
  const [dataFeedback, setDataFeedback] = useState(null);

  const [similarFusebox, setSimilarFusebox] = useState(null);

  const year = vehicleContext.year;
  const modelId = vehicleContext.modelId;

  useEffect(() => {
    fetchDataIssues();
    fetchDataFeedback();
    fetchFuseboxes();
  }, [JSON.stringify([vehicleContext, cluster])]);

  const fetchDataIssues = () => {
    let query = { dataType: 'fusebox', 'data.clusterId': { $in: [cluster._id] } };
    page.runAsync(dataIssuesService.find({ query }).then(res => setDataIssues(res)));
  };

  const fetchDataFeedback = () => {
    page.runAsync(dataFeedbackService.get(dataFeedbackId).then(res => setDataFeedback(res)));
  };

  const fetchFuseboxes = () => {
    let query = { type: 'fusebox', 'context': { $elemMatch: {'modelId': modelId, 'year': year}}, locales: window.config.locale, state: 'published' };
    page.runAsync(legosService.find({ query }).then(res => {
      _.each(res, (fbx, i) => {
        fbx.country = fbx.country || [];
        fbx.context = _.filter(fbx.context, c => c.modelId === modelId);
      });
      return setFuseboxes(res);
    }));
  };

  const close = () => page.modalActionsBus.emit('close');

  const onAddOrEditHandler = (dataIssue, changedObj) => {
    const save = async (obj, shouldClose) => {
      let res;
      if(obj._id) {
        res = await dataIssuesService.update(obj._id, obj);
        fetchDataIssues()
      } else {
        res = await dataIssuesService.create(obj);
        fetchDataIssues()
      }
      if(shouldClose) {
        close();
      }
      return res;
    }

    // page.modalActionsBus.emit('open', <DataIssueEditor changedObj={changedObj}
    //                                                    obj={dataIssue || newObjectTemplate}
    //                                                    onCancel={close}
    //                                                    onSave={(... args) => page.runAsync(save(... args))}/>, { title: dataIssue?._id ? 'Edit data issue' : 'Create data issue' });
  };

  // const filteredIssues = _.filter(dataIssues, di => di.data.clusters.includes(cluster._id));
  const filteredIssues = dataIssues;

  const sameCluster = _.filter(filteredIssues, issue => cluster && (issue.data?.clusterId === cluster._id));
  const sameYear = _.filter(filteredIssues, issue => issue.dataSelector.vehicle.year === year);
  const sameClusterAndYear = _.intersection(sameCluster, sameYear);

  let optionAttach, optionCreateNew, optionUnclear;

  if(sameClusterAndYear.length === 1) {
    let [issue] = sameClusterAndYear;
    const attachToDataIssue = (solveIt) => {
      page.runAsync(async () => {
        // Fetch fresh data issue, add dataFeedbackId and save
        let clone = await dataIssuesService.get(issue._id);
        clone.involvedDataFeedback = _.uniq([... clone.involvedDataFeedback, dataFeedbackId]);
        if(solveIt && similarFusebox) {
          clone.state = 'solved';
          clone.isOpen = false;
          clone.data = clone.data || {};
          clone.data.fuseboxLegoId = similarFusebox._id;
          clone.data.fuseboxUrl = await getFuseboxUrl(similarFusebox, modelId, year);
        }

        await dataIssuesService.update(issue._id, clone)
        onAction();
      })
    };

    optionAttach = <tr>
      <td>
        <DataIssueSummary issue={issue} currentFeedback={dataFeedbackId}/>
      </td>
      <td  className={'text-right pr-2'}>
        {similarFusebox ?
         <span className={`btn btn-success`} onClick={() => attachToDataIssue(true)}> Attach to this issue and solve it</span>
          :
         <span className={`btn btn-primary`} onClick={() => attachToDataIssue(false)}> Attach to this data issue</span>
        }
      </td>
    </tr>
  } else if(sameClusterAndYear.length > 1) {
    optionAttach = <tr>
      <td colSpan={2}>
        <div>There are {sameClusterAndYear.length} data issues for the same cluster, model and year. Solve it manually
          from DataIssues tab
        </div>
      </td>
    </tr>;
  } else if(dataIssues) {
    const newObjectTemplate = {
      dataSelector: { vehicle: vehicleContext ? vehicleContext : { ...cluster.context } },
      source: { type: 'fuseboxFeedback', id: dataFeedbackId },
      state: 'inprogress',
      dataType: 'fusebox',
      issueCategory: 'missingYear',
      isOpen: true,
      data: { clusterId: cluster._id },
      involvedDataFeedback: [dataFeedbackId]
    };

    const createDataIssue = () => page.runAsync(dataIssuesService.create(newObjectTemplate).then(onAction));

    optionCreateNew = <tr>
      <td className={'bg-light-success translucent'}>
        <DataIssueSummary issue={newObjectTemplate} currentFeedback={dataFeedbackId}/>
      </td>
      <td className={'text-right pr-2'}>
         <span className={`btn btn-success`}
               onClick={createDataIssue}> Create new data issue
            </span>
      </td>
    </tr>;

    optionAttach = <tr>
      <td colSpan={2}>
        <div>No existing matching data issue found.</div>
      </td>
    </tr>;
  }

  if(fuseboxes && fuseboxes.length) {
    const markUnclear = () => {
      page.runAsync(async () => {
        await dataFeedbackService.update(dataFeedbackId, { $set: { state: 'unclear' } });
        onAction({ action: 'markUnclear' });
      });
    };

    optionUnclear = <tr>
      <td>
        <div>Fuseboxes found for {modelId} {year}:</div>
        <div className={'d-flex flex-wrap mt-1'}>
          {
            fuseboxes.map(f => <span key={f._id}
                                     className={`btn btn-sm m-1 btn-${f._id === similarFusebox?._id ? 'primary btn-active' :  'secondary'}`}
                                     onClick={() => setSimilarFusebox(f)}>
              <div><LegoContextSummary context={_.find(f.context, {year, modelId})}/></div>
              <div className={'d-flex'}>
                  {f.data.map((box, i) => <span key={i} className={'inline-block'}
                                                style={{ width: '200px', height: '200px' }}>
                <TemplatedFuseboxDiagram hideTittle fusebox={box}/>
                </span>)}
                </div>
          </span>)
          }
        </div>
      </td>
      <td className={'text-right pr-2'} style={{width: '300px'}}>
        {similarFusebox ?<div>
          <div>
          <span className={`btn btn-dark`} onClick={markUnclear}> Mark feedback unclear</span>
          </div>
            <IconButton icon={'remove'} level={'secondary'} onClick={()=>setSimilarFusebox()}>Clear selected fusebox</IconButton>
          </div>
          :
          <span className={'text-info'}>Select similar fusebox and then mark as unclear</span>
        }
      </td>
    </tr>;
  }


  return <div>
    <div className={'d-flex p-1 bg-light align-items-center justify-content-around'}>
      <span>Feedback: <BadgeId id={dataFeedbackId || '-'}/></span>
      <span>Cluster: <BadgeId id={cluster?._id || '-'}/></span>
    </div>

    <div>
      <ImagePreview zoom url={dataFeedbackImage.url}/>
      <ImageClusterPreview clusterId={cluster._id} preloadedCluster={cluster} maxImages={16} sortByYear/>
      { dataFeedback && <div className={'small'}>from <a href={dataFeedback.feedbackContext?.url}
                                       target={'_blank'}
                                                         className={'text-primary'}>{dataFeedback.feedbackContext?.url}</a></div> }
    </div>

    <table className={'table bg-light mt-3'}>
      <tbody>

      { optionAttach }

      { optionCreateNew }

      { optionUnclear }

      </tbody>
    </table>
  </div>
}
