import EventEmitter from 'events';
import React, { Component } from 'react';
import ModalManager from '../../ModalManager';
import _ from 'lodash';
import { IconButton } from '../IconButton';
import ImageSearchDialog from '../../lego/ImageSearchDialog';

export class MultipleImagesPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.modalActionsBus = new EventEmitter();
  }

  zoomImage(url) {
    this.modalActionsBus.emit('open', <div>
      <img style={{ maxWidth: '90vw', maxHeight: '80vh' }} src={url}/>
    </div>, false);
  }

  render() {
    let {} = this.state;

    let images = (this.props.value || []).map(({ url }) => {
      return <span key={url} className={'mr-1 pos-relative inline-block'}>
        <img className={'img-med img-thumbnail'} key={url} src={url}/>
                    <span className={'float-top-left text-center parent-hover-visible'}>
              <IconButton onClick={(e) => e.stopPropagation() + this.zoomImage(url)} level={'primary'} icon={'zoom_in'}
                          description={'Zoom In'}/>
            </span>
      </span>;
    });

    return <div className={''}>
      {images}
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
