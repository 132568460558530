import _ from 'lodash';

export default class SocketStatus {
  constructor(socket) {
    this.socket = socket;

    this.anyHandler = (... params) => {
      this.externalAnyHandler(... params)
    };

    this.externalAnyHandler = () => {;};

    this.handlers = {};

    let watchCnxEvent = eventName => {
      this.on(eventName, (... params) => this.anyHandler(eventName, ... params));
    };

    watchCnxEvent('disconnect', this.anyHandler);
    watchCnxEvent('error', this.anyHandler);
    watchCnxEvent('reconnecting', this.anyHandler);
    watchCnxEvent('reconnect_failed', this.anyHandler);
  }

  onLifecyleEvent(handler) {
    this.externalAnyHandler = handler;
  }

  on(event, handler) {
    this.handlers[event] = this.handlers[event] || [];
    this.handlers[event].push(handler);
    this.socket.on(event, handler);
  }

  destroyHandlers() {
    // Remove particular handlers
    _.each(this.handlers, (handlers, eventName) => _.each(handlers, h => this.socket.off(eventName,h)));
  }
}
