import React, {Component} from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ModalManager from '../../ModalManager';
import EventEmitter from 'events';

dayjs.extend(relativeTime);


export default class ManualFeaturesEditor extends Component {
  constructor(props) {
    super(props);

    this.modalActionsBus = new EventEmitter();

    this.state = {
      original: this.props.manual,
      changedObject: _.cloneDeep(this.props.manual),
    };

    if(props.initialChanges) {
      // merge initial changes with object copy
      this.state.changedObject = _.defaultsDeep(props.initialChanges, this.state.changedObject);
    }
  }

  editField(fieldPath, newValue) {
    if(!fieldPath.startsWith('features.'))
      return alert("Only manual features can be edited")

    let updated = this.state.changedObject;
    _.set(updated, fieldPath, newValue);
    this.setState({ changedObject: updated });
    this.checkChanges();
  }

  componentDidMount() {
    this.checkChanges();
  }

  checkChanges() {
    const areEqual = _.isEqual(this.state.changedObject, this.state.original);

    this.setState({ changed: !areEqual });
  }

  discardChanges() {
    this.setState({ changedObject: _.cloneDeep(this.props.manual), changed: false })
  }

  render() {
    let manual = this.state.changedObject;
    let { _id, context, createdAt, updatedAt, __unsaved, __new, __deleted } = manual;

    let rowClasses = 'p-2 ';
    if (__new) {
      rowClasses += ' bg-light-success';
    }
    if (__unsaved) {
      rowClasses += ' bg-light-warning';
    }
    if (__deleted) {
      rowClasses += ' bg-light-danger';
    }

    if (this.state.changed) {
      rowClasses += 'bg-light-warn';
    }

    let editTime = '';
    if (updatedAt) {
      editTime = ((updatedAt === createdAt) ? 'Created ' : 'Edited ') + dayjs(updatedAt).fromNow();
    }

    return <div className={rowClasses}>
      <div className={'row no-gutters'}>
        <div className={'col-6 h5'}>Manual metadata</div>
        <div className={'col-6 small'}>
          <div className={'text-secondary small'}>{_id}</div>
          <div className={'text-info ml-2 mr-2'} title={updatedAt}>{editTime}</div>
        </div>
      </div>

      <div className={"mt-2"}>
        <div className={""}>
          <table className={'table table-sm'}>
            <tbody>
            {this.getFeaturesEditionRows(manual)}
            </tbody>
          </table>
        </div>
      </div>


      <div className={'mt-2'}>
        <button disabled={!this.state.changed} onClick={async () => {
          let updatedManual = await this.props.onSave(manual);
          // It could fail and return undefined
          if (updatedManual) {
            this.setState({ original: updatedManual, changedObject: _.cloneDeep(updatedManual) });
            this.checkChanges();
          }
        }} className={'btn btn-primary'}>
          Save changes
        </button>

        <button disabled={!this.state.changed} onClick={() => this.discardChanges()}
                className={'btn btn-link ml-2'}>Discard changes
        </button>
      </div>
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }

  getMetadataEditionRows(manual) {
    throw new Error("getMetadataEditionRows must be implemented by subclass")
  }
}

