import React, {useState} from 'react';
import { useDrop } from 'react-dnd'

import {Icon} from "../common/Icon";

const DragAndDropRemoveArea = ({ ...other }) => {
  const [{ dragInProgress, isOverCurrent }, drop] = useDrop({
    accept: "facetId",
    drop(item, monitor) {
      console.log(`DROPPED to delete ${item.facetId} from ${item.parentOf}`)
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
      dragInProgress: monitor.canDrop()
    }),
  })

  if(dragInProgress) {
    return <div ref={drop} className={'p-2 text-center text-' + (isOverCurrent ? 'danger' : 'secondary')}>
      <Icon icon={isOverCurrent ? 'delete' : 'delete'}/>
      <div className={'small'}>{isOverCurrent ? 'Drop to delete' : 'Drag here to delete'}</div>
    </div>
  } else {
    return null;
  }
}

export default DragAndDropRemoveArea;
