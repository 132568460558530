import React, { useContext, useState } from 'react';

import _ from 'lodash';

import GridLayoutLeftBar from '../../common/layout/GridLayoutLeftBar';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import LegoContextSummary from '../LegoContextSummary';
import ModelContextEditor from '../ModelContextEditor';
import TopBarLayout from '../../common/layout/TopBarLayout';
import { IconButton } from '../../common/IconButton';
import { LegoStateBadge } from '../LegoStateBadge';
import { ImagePreview } from '../../images/ImagePreview';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import FiltersBar from '../../common/filters-bar/FiltersBar';
import { SwitchInput } from '../../common/SwitchInput';
import urls from '../../../../../components/interop/urls';
import BadgeId from '../../common/BadgeId';

function urlFuseboxThumbnail(modelId, year, other, fuseIndex) {
  // TODO: NASTY. Must be done in server with proper character sanitization
  let [marcaUrlSafe, modeloUrlSafe] = (modelId || '').toLowerCase().split('-');
  const modelObj = { idModelo: modelId, marcaUrlSafe, modeloUrlSafe };
  return urls.urlFuseboxThumbnail(modelObj, year, other, fuseIndex);
}


function getContextQuery({ ...context }) {
  //TODO: Repeated hack in LegoManager to fake make queries
  if (context.make) {
    context.modelId = { $regex: `^${context.make}-` };
    delete context.make;
  }
  return _.mapKeys(context, (val, key) => 'context.' + key);
}


async function fetchSimilarImageFuseboxes(searchSvc, boxDiagramImg, legoSvc) {
  let images = await searchSvc.find({ query: { category: 'fusebox-diagram', url: boxDiagramImg?.url, $limit: 100 } });
  console.log('images', images);
  let res = await legoSvc.find({
    query: {
      $or: [{ type: 'fuseboxTemplate' }, { type: 'fusebox' }], 'data.boxDiagramImg.url': { $in: _.map(images, 'url') }
    },
    $select: { 'data.fuses': false, 'data.boxDescription': false }
  });
  let imagesByUrl = _.keyBy(images, 'url');
  // Only leave the box with the image
  for (const l of res) {
    for (const box of l.data) {
      const matchingImage = imagesByUrl[box.boxDiagramImg?.url];

      if (matchingImage) {
        box.score = matchingImage.score;
      }
    }
  }
  return res;
}

async function fetchSimilarContextFuseboxes(legoSvc, context) {
  return await legoSvc.find({
    query: {
      $or: [{ type: 'fuseboxTemplate' }, { type: 'fusebox' }],
      $select: { 'data.fuses': false, 'data.boxDescription': false },
      ...getContextQuery(context),
    }
  });
}

function FuseboxTemplateResult({ lego, fuseboxIndex, onClick, selected, ...other }) {
  let box = (lego.data || [])[fuseboxIndex];

  if (box) {
    let { boxDiagramImg, boxAspectRatio } = box;

    const isTemplate = lego.type === 'fuseboxTemplate';
    const usesTemplate = !!box.templateId;
    let bkg = isTemplate ? 'bg-light-gris-blue' : (box.templateId ? 'bg-white' : (lego.state === 'inprogress' ? 'bg-light-warn' : 'bg-light'));

    if(selected && isTemplate)
      bkg = 'bg-light-success'

    const {modelId, year, ... otherContextSpecs} = lego.context[0];
    const boxThumbnail = urlFuseboxThumbnail(modelId, year, otherContextSpecs, fuseboxIndex);
    const thumbnail = <ImagePreview url={boxDiagramImg?.url || boxThumbnail} img200 contain zoom/>;

    return <span
      className={`inline-block align-top pos-relative py-2 border-secondary ${bkg} border text-center`} {...other}>
      <LegoContextSummary context={lego.context}/>

     <span className={'float-bottom-right zoom-75'}>
       <span className={'small text-black-50 mr-2'}>{lego.data[fuseboxIndex].score}</span>
        <LegoStateBadge state={lego.state}/>
      </span>

      {thumbnail}

      {selected ? <div><span className={'badge badge-success mt-1'}>Current template</span></div> : null}

      {!selected && onClick ? <div>
        <IconButton icon={'check_circle_outline'} className={''} level={'primary'} onClick={onClick}>
          {isTemplate ? 'Apply' : (usesTemplate ? 'Use same template' : 'Refactor')}
        </IconButton>
      </div> : null}
    </span>;
  }
}


export function FuseboxTemplateFinder({ onSelected, fuseboxData, currentTemplateId, currentLegoId, context: [fuseboxCtx] }) {
  let { page } = useContext(LegoAdminPageContext);
  let [context, setContext] = useState({ modelId: fuseboxCtx?.modelId || 'Ford-Focus' });
  let [fuseboxes, setFuseboxes] = useState(null);
  let [similarityEnabled, setSimilarity] = useState(true);

  let { boxDiagramImg } = fuseboxData;

  const searchBySimilarity = similarityEnabled && !!boxDiagramImg?.url;

  useAsyncEffect(async () => {
    const searchSvc = page.service('services/data/image-search');
    const legoSvc = page.service('services/legos');

    if (context.modelId || context.make) {
      setFuseboxes(null);

      if(searchBySimilarity) {
        setFuseboxes(await fetchSimilarImageFuseboxes(searchSvc, boxDiagramImg, legoSvc).catch(alert));
      } else {
        setFuseboxes(await fetchSimilarContextFuseboxes(legoSvc, context).catch(alert));
      }
    }
  }, [JSON.stringify(context), similarityEnabled]);

  let results = <div className={'full-screen-center'}>Loading fusebox templates...</div>;

  if (fuseboxes) {
    // Make pairs [Lego, fusebox index inside lego] to handle fusebox legos with multiple fuseboxes
    let individualFuxeboxes = _.flatten(_.map(fuseboxes, lego => _.map(lego.data, (fbx, i) => [lego, i])));

    let sortedBoxes;
    if (searchBySimilarity) {
      individualFuxeboxes = _.filter(individualFuxeboxes, ([lego, i]) => lego.data[i].score !== undefined);

      sortedBoxes = _.sortBy(individualFuxeboxes, ([lego, i]) => {
        const isSameModel = lego.context[0].modelId === context.modelId;
        return lego.data[i].score + (isSameModel ? 0 : 10);
      });
    } else {
      // Sort them by closest fusebox aspect ratio + distance between model years
      const sortByPublished = ([{ state, data }, i]) => (state === 'published' && !data[i].templateId) ? 0 : 1;

      const sortBySimilarityAndYear = ([{ data, context: tempCtx }, i]) => {
        let year = (tempCtx[0] || tempCtx).year || 0;
        const ratioYear = Math.abs(year - fuseboxCtx.year);

        let ratioA = data[i].boxAspectRatio || -1;
        let ratioB = fuseboxData.boxAspectRatio || 0;

        // TODO: Show published first
        return Math.abs(ratioA - ratioB) + ratioYear / 30;
      };

      sortedBoxes = _.sortBy(individualFuxeboxes, sortByPublished, sortBySimilarityAndYear);
    }

    // Separate templates from fuseboxes
    let templates = _.filter(sortedBoxes, ([f,i]) => (f.type === 'fuseboxTemplate' || f.data[i].templateId) && f._id !== currentLegoId);
    let otherFuseboxes = _.filter(sortedBoxes, ([f,i]) => f.type === 'fusebox' && !f.data[i].templateId && f._id !== currentLegoId);

    let groupedByTemplate = _.groupBy(templates, ([fb,i]) => fb.type === 'fuseboxTemplate' ? fb._id : fb.data[i].templateId + (fb.data[i].templateRenameMap ? ' MAP '+fb._id : ''));

    results = <div className={''}>
      <div className={'text-white bg-gris-blue px-2 py-1'}>Templates and fusebox using templates</div>

      {_.map(groupedByTemplate, (groupLegos, key) => {
        let [firstLego, firstIndex] = groupLegos[0];
        const templateId = firstLego.data[firstIndex].templateId || firstLego._id;
        const usesCurrentTemplate = currentTemplateId && currentTemplateId === templateId;
        const hasSameRemapping = _.isEqual(fuseboxData.templateRenameMap, firstLego.data[firstIndex].templateRenameMap);
        const isRemapped = _.isEmpty(firstLego.data[firstIndex].templateRenameMap);
        const selected = usesCurrentTemplate && (hasSameRemapping || firstLego.type === 'fuseboxTemplate');

        return <span className={`border bg-light-${selected ? 'success' : 'gris-blue'} rounded p-1 inline-block m-2 align-top`} key={key}>
          <div className={''}>
              {!selected ? <span>
                <IconButton icon={'check_circle_outline'} level={'primary'} onClick={() => onSelected(firstLego, firstIndex)}>
                  Apply
                </IconButton>
              </span> : null}
              <BadgeId id={templateId}/>

            {!isRemapped ? <span className={'badge badge-info mt-1'}>REMAPPED</span> : null}
          </div>

          {_.map(groupLegos, ([lego, i]) => {
            const usesCurrentTemplate = currentTemplateId && currentTemplateId === lego.data[i].templateId;
            const hasSameRemapping = _.isEqual(fuseboxData.templateRenameMap, lego.data[i].templateRenameMap);
            return <FuseboxTemplateResult key={lego._id}
                                          lego={lego}
                                          fuseboxIndex={i}
                                          selected={usesCurrentTemplate && hasSameRemapping}
            />;})}
        </span>
      })}

      { !templates.length ? <div className={'p-2'}>There are no templates. Check in the <strong>make</strong></div> : null }

      <div className={'bg-light-dark'}>
        <div className={'text-white bg-dark px-2 py-1'}>Fuseboxes that can be refactored into templates</div>
        {_.map(otherFuseboxes, ([lego, i]) => {
            const usesCurrentTemplate = currentTemplateId && currentTemplateId === lego.data[i].templateId;
            const youAreItsTemplate = currentLegoId === lego.data[i].templateId;

            return <FuseboxTemplateResult key={`${lego._id}_${i}`}
                                          lego={lego}
                                          fuseboxIndex={i}
                                          selected={usesCurrentTemplate || youAreItsTemplate}
                                          onClick={() => onSelected(lego, i)}/>;
          }
        )}
      </div>
    </div>;
  }

  return <GridLayoutLeftBar style={{ left: '0', top: '0' }}>
    <div className={'bg-light p-2'} style={{ width: '450px' }}>
      <h5>Finding match for fusebox:</h5>

      <LegoContextSummary context={fuseboxCtx}/>

      <div className={'mt-3'}>
        <ImagePreview url={boxDiagramImg?.url} fluid zoom/>
      </div>
    </div>

    <TopBarLayout>
      <FiltersBar>
        <SwitchInput value={similarityEnabled} onChange={v => setSimilarity(v)}>Search by similarity</SwitchInput>
        { !similarityEnabled ? <ModelContextEditor value={context} onChange={v => setContext(v)} className={'flex-grow-1 ml-3'}/> : null }
      </FiltersBar>
      {results}
    </TopBarLayout>
  </GridLayoutLeftBar>;
}
