import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import LiveSubpage from "../LiveSubpage";
import {IconButton} from "../../components/common/IconButton";
import FullscreenHeaderGrid from "../../components/common/layout/GridLayoutHeader";
import FullscreenLeftBarGrid from "../../components/common/layout/GridLayoutLeftBar";
import SingleTextEditor from "../../components/common/editors/SingleTextEditor";
import FacetCard from "../../components/facets-manager/FacetCard";
import EditableFacetCard from "../../components/facets-manager/EditableFacetCard";
import FacetId from "../../components/facets-manager/FacetId";
import FacetsProxy from "../../components/facets-manager/FacetsProxy";
import {getIdColorClass} from "../../components/facets-manager/facetStyleUtils";
import FacetIdsSelector from "../../components/facets-manager/FacetIdsSelector";
import DragAndDropRemoveArea from "../../components/facets-manager/DragAndDropRemoveArea";
import {Icon} from "../../components/common/Icon";

dayjs.extend(relativeTime);

export default class FacetsEditor extends LiveSubpage {
  constructor(props) {
    super(props);

    this.state = {
      viewFilter: { facets: [] },
      textFilter: '',
      facetsFilter: [],
      lastActiveFilter: [],
      navigationHistory: [[]],
      navigationHistoryPosition: 0,
      loading: true,
      facets: []
    };

    this.shortcuts.registerKeyMappings({
      'focusSearch': 'ctrl+f',
      'addFacet': 'ctrl+a',
      'undoNavigation': 'alt+left',
      'redoNavigation': 'alt+right',
    });

    this.shortcuts.registerActionHandlers({
      'focusSearch': this.focusSearch.bind(this),
      'addFacet': this.openAddFacet.bind(this),
      'undoNavigation': this.undoNavigation.bind(this),
      'redoNavigation': this.redoNavigation.bind(this),
    });

    this.searchInputRef = React.createRef();
    this.loadFilteredDataDebounced = _.debounce(this.loadFilteredData, 100).bind(this)

    this.deleteFacet = this.deleteFacet.bind(this);
    this.copyFacet = this.copyFacet.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    const urlFacetsFilter = this.getUrlParam('facetsFilter') || this.state.facetsFilter;
    const textFilter = this.getUrlParam('textFilter') || this.state.textFilter;

    if(urlFacetsFilter !== this.state.facetsFilter || textFilter !== this.state.textFilter) {
      this.setState({textFilter, urlFacetsFilter})
    }

    this.loadUIData().then(() => this.loadFilteredData());
  }

  async undoNavigation() {
    let {navigationHistory, navigationHistoryPosition} = this.state;
    if(navigationHistoryPosition > 0) {
      return this.navigateHistoryTo(navigationHistoryPosition - 1);
    }
  }

  async redoNavigation() {
    let {navigationHistory, navigationHistoryPosition} = this.state;
    if(navigationHistoryPosition < navigationHistory.length - 1) {
      return this.navigateHistoryTo(navigationHistoryPosition + 1);
    }
  }

  async navigateHistoryTo(navigationHistoryPosition) {
    let {navigationHistory} = this.state;
    const facetIds = navigationHistory[navigationHistoryPosition];
    this.setState({ facetsFilter: facetIds, navigationHistoryPosition });
    this.setUrlParams({ facetsFilter: facetIds && facetIds.length ? facetIds : undefined })
    this.loadFilteredDataDebounced();
  }

  async addNewNavigation(facetIds) {
    let {navigationHistory, navigationHistoryPosition} = this.state;
    navigationHistory = navigationHistory.slice(0, navigationHistoryPosition+1)

    navigationHistory.push(facetIds);

    this.setState({ facetsFilter: facetIds, navigationHistory, navigationHistoryPosition: navigationHistory.length - 1 });
  }

  renderNavigationTrail() {
    let {navigationHistory, navigationHistoryPosition, config} = this.state;

    const maxHistory = navigationHistory.length - 1;

    let to = Math.min(maxHistory+1, navigationHistoryPosition + 3);
    let from = Math.max(0, to - 5);
    to = Math.max(to, from+5);

    return <div>
      History: &nbsp;
      {config && _.map(navigationHistory.slice(from, to), (facetIds, i) => {
        if(facetIds && facetIds.length > 0) {
          let active = (navigationHistoryPosition === (from+i)) ? '' : 'translucent';
          return <span key={i+facetIds[0]} className={active}>
              { (i === 0 && from > 0) ? '...' : null}
              { from+i > 0 ? <Icon icon={'arrow_back'} level={'secondary'}/>: null }&nbsp;
            <FacetId id={facetIds[0]} config={config} onClick={() => this.navigateHistoryTo(from+i)}/>
              { (i === 4 && (to - 1) < maxHistory) ? '...' : null}
            </span>;
        } else {
          return "''"
        }
      })}
    </div>
  }


  async addFacet(newFacet) {
    if (newFacet.id) {
      let res = await this.runAsync(this.props.facetsProxy.add(newFacet.id, newFacet.parents), 'Creating...');
      console.log(res);
      this.setState({facets: [newFacet, ... this.state.facets]});
    }
  }

  async addFacetParent(facetId, newParent) {
    if (facetId && newParent) {
      await this.runAsync((async () => {
        await this.props.facetsProxy.addParent(facetId, newParent);
        await this.refreshFacet(facetId);
      })(), 'Updating...');
    }
  }

  async removeFacetParent(facetId, parentToRemove) {
    if (facetId && parentToRemove) {
      await this.runAsync((async () => {
        await this.props.facetsProxy.removeParent(facetId, parentToRemove);
        await this.refreshFacet(facetId);
      })(), 'Updating...');
    }
  }

  async refreshFacet(id) {
    let updatedFacet = await this.props.facetsProxy.get(id);
    const updatedFacetIndex = _.findIndex(this.state.facets, f => f.id === id);
    if (updatedFacetIndex >= 0 && updatedFacet) {
      this.state.facets[updatedFacetIndex] = updatedFacet;
      this.setState({ facets: this.state.facets });
    }
  }

  async editFacet(id, newFacet) {
    if (newFacet.id) {
      let res = await this.runAsync(this.props.facetsProxy.edit(id, newFacet.parents, newFacet.id), 'Editing...');
      const editedFacetIndex = _.findIndex(this.state.facets, f => f.id === id);
      if (editedFacetIndex >= 0) {
        this.state.facets[editedFacetIndex] = newFacet;
        console.log(res);
        this.setState({ facets: this.state.facets });
      }
    }
  }

  async deleteFacet(id) {
    if (confirm(`Deleting '${id}', are you sure?`)) {
      this.runAsync((async () => {
        await this.props.facetsProxy.remove(id)
        this.setState({ facets: _.filter(this.state.facets, f => f.id !== id) })
      })(), 'Deleting...');
    }
  }

  async copyFacet(facet) {
    await this.openAddFacet(_.cloneDeep(facet));
  }

  groupFacetsByType(uniqueFacets) {
    let byId = _.keyBy(uniqueFacets, 'id');

    for(const f of uniqueFacets) {
      let type = f.id;
      do {
        type = FacetsProxy.parseId(type).type;
        if (byId[type]) {
          (byId[type].subtypesFacets = byId[type].subtypesFacets || []).push(f);
          byId[type].subtypesFacets = _.sortBy(byId[type].subtypesFacets, 'id');
          uniqueFacets = _.without(uniqueFacets, f);
          type = false;
        }
      } while(type);
    }
    return uniqueFacets;
  }

  async loadFilteredData() {
    await this.runAsync((async () => {
      let lastActiveFilter = this.state.facetsFilter || [];

      if(lastActiveFilter.length > 0) {
        const [facets, subtypes, subtypesChildren, children ] = await Promise.all([
          this.props.facetsProxy.get(lastActiveFilter),

          lastActiveFilter.length === 1 ? this.props.facetsProxy.getSubtypes(lastActiveFilter[0]) : (async () => [])(),

          lastActiveFilter.length === 1 ? this.props.facetsProxy.getSubtypesChildren(lastActiveFilter[0]) : (async () => [])(),

          this.props.facetsProxy.getChildren(lastActiveFilter),
        ]);

        let uniqueFacets = _.uniqBy(_.compact([
          ...facets,
          ..._.sortBy(subtypes, 'id'),
          ... _.flatten(_.map(subtypesChildren, '1')),
          ...children
        ]), f => f.id);
        // let uniqueFacets = _.uniqBy(_.compact(_.sortBy(subtypes, 'id')), f => f.id);

        this.setState({ loading: false, facets: uniqueFacets, lastActiveFilter })
      } else {
        const uiMenuChildrens = await this.props.facetsProxy.getSubtypesChildren('UI:Type:MainMenu');
        const facets = _.uniqBy(_.flatten(_.map(uiMenuChildrens, '1'), 'id'));
        this.setState({ loading: false, facets })
      }


    })(), 'Loading facets...')
  }

  async loadUIData() {
    const uiTagsAndChildren = await this.props.facetsProxy.getSubtypesChildren("UI");

    const config = {
      typeColors: {},
      mainMenu: {},
    }
    _.each(uiTagsAndChildren, ([tag, children]) => {
      let {type, name} = FacetsProxy.parseId(tag);

      switch (type) {
        case 'UI:Type:Color':
          for (const typeId of children) {
            config.typeColors[typeId.id] = name;
          }
          break;
        case 'UI:Type:MainMenu':
          for (const typeId of children) {
            config.mainMenu[typeId.id] = {name};
          }
          break;
      }
    });

    // Fetch main menu counts
    const mainMenuCounts = await Promise.all(_.map(_.keys(config.mainMenu), id => this.props.facetsProxy.getChildrenCount(id)));
    _.each(_.keys(config.mainMenu), (key, i) => config.mainMenu[key].count = mainMenuCounts[i]);


    console.log(config);
    this.setState({config});
  }

  async openAddFacet(initialValue = { id: '', parents: [] }) {
    const close = () => this.modalActionsBus.emit('close');

    this.modalActionsBus.emit('open', <div>
      <EditableFacetCard initialValue={initialValue}
                         onCancel={close}
                         config={this.state.config}
                         service={this.props.facetsProxy}
                         onSave={(newFacet) => close() + this.addFacet(newFacet)}
      />
    </div>, {overflow: false, minSize: false, title: 'Add new facet'});
  }

  async openEditFacet(id, parent) {
    let updatedFacet = await this.props.facetsProxy.get(id);

    if(!updatedFacet) {
      return alert(`Could not get facet ${id} from the server.`)
    }

    const close = () => this.modalActionsBus.emit('close');

    this.modalActionsBus.emit('open', <div>
      <EditableFacetCard initialValue={_.cloneDeep(updatedFacet)}
                         selectedParent={parent}
                         config={this.state.config}
                         service={this.props.facetsProxy}
                         onCancel={close}
                         onSave={(newFacet) => close() + this.editFacet(id, newFacet).then()}
      />
    </div>, {overflow: false, title: 'Edit facet'});
  }

  focusSearch() {
    const node = this.searchInputRef.current;
    if (node) {
      node.focus();
    }
    return false;
  }

  textFilterChanged(text) {
    this.setState({ textFilter: text });
    this.setUrlParams({textFilter: text})
    this.loadFilteredDataDebounced();
  }

  facetFilterChanged(facetIds) {
    if(!_.isEqual(facetIds, this.state.facetFilters)) {
      this.addNewNavigation(facetIds);

      if (facetIds && facetIds.length)
        this.setUrlParams({ facetsFilter: facetIds })
      else
        this.setUrlParams({ facetsFilter: undefined })

      this.loadFilteredDataDebounced();
    }
  }

  renderPageBody() {
    let { viewFilter, connectionStatus, textFilter, facetsFilter, lastActiveFilter, facets, config, loading } = this.state;

    // if (textFilter) {
    //   facets = _.filter(facets, trim => JSON.stringify(trim).toLowerCase().indexOf(textFilter.toLowerCase()) >= 0)
    // }

    let facetsViz, facetsChildrenViz;
    if(loading) {
      facetsViz = <h4 className={'text-center mt-5 text-primary'}>Loading UI and config...</h4>;
    } else if (!facets || !facets.length) {
      facetsViz = <h4 className={'text-center mt-5'}>No facet found with id '<span className={'text-danger monospace'}>{lastActiveFilter[0]}</span>'</h4>;
    } else {
      const nestedSliceOfFacets = this.groupFacetsByType(_.cloneDeep(facets.slice(0, 40)));
      const directSubtypes = _.filter(nestedSliceOfFacets, ({id}) => id.startsWith((lastActiveFilter[0] || '')));

      const children = _.difference(nestedSliceOfFacets, directSubtypes);

      [facetsViz, facetsChildrenViz] = _.map([directSubtypes, children], facetsList => {
        return _.map(facetsList, facet => <div key={facet.id} className={'mt-3'}>
          <FacetCard config={config} facet={facet}
                     onDelete={this.deleteFacet}
                     onCopy={this.copyFacet}
                     onEdit={id => this.openEditFacet(id)}
                     onClick={(f,e) => e.ctrlKey ? this.facetFilterChanged([f.id]) : this.openEditFacet(f.id)}
                     onClickParent={(f,parent, e) => e.ctrlKey ? this.facetFilterChanged([parent]) : this.openEditFacet(f.id, parent)}
                     onAddParent={(id, newParent) => this.addFacetParent(id, newParent)}
                     onRemoveParent={(id, newParent) => this.removeFacetParent(id, newParent)}
          />
        </div>);
      });
    }

    let menuItems = [];
    if(!loading) {
      const orderedItems = [ ['', {name: 'All'}], ... _.sortBy(_.toPairs(config.mainMenu), '1.name')]
      menuItems = _.map(orderedItems, ([id, {name, count}]) => {

        const color = getIdColorClass(id, config);
        return <button key={id} className={'list-group-item list-group-item-action '+(facetsFilter[0] === id ? 'active' : '')}
                       onClick={() => this.facetFilterChanged(id ? [id] : [])}>
          {name} { count !== undefined ? <span className={`badge badge-${color}`}>{count}</span> : null}
        </button>;
      });
    }

    let headerBg = textFilter ? 'bg-light-secondary' : 'bg-light'

    const refreshBtn = <IconButton icon={'refresh'} level={'primary'} onClick={() => this.loadFilteredData()}>
      Refresh
    </IconButton>;

    const addBtn = <IconButton icon={'add'} level={'success'} onClick={() => this.openAddFacet()}>
      Create
    </IconButton>;

    return <FullscreenHeaderGrid>
      <div className={`${headerBg} shadow-sm`}>
        <div className={`p-2 d-flex align-items-center `}>
          <span className={'text-left'}>{refreshBtn} {addBtn}</span>

          <span className={'col-6'}>
            <FacetIdsSelector ref={this.searchInputRef}
                              value={facetsFilter}
                              singleValue={true}
                              config={config}
                              service={this.props.facetsProxy}
                              onChange={(ids) => this.facetFilterChanged((ids || []).slice(-1))}/>

            {/*<input ref={this.searchInputRef}*/}
            {/*       autoFocus={true}*/}
            {/*       value={textFilter}*/}
            {/*       className={`form-control`}*/}
            {/*       placeholder={'Search...'} type={'text'}*/}
            {/*       onChange={e => this.textFilterChanged(e.target.value)}/>*/}
          </span>
        </div>

        <div className={'p-2 zoom-90 border border-left-0 border-right-0 border-bottom-0'}>{ this.renderNavigationTrail() }</div>
      </div>

      <FullscreenLeftBarGrid>
        <div className={'p-3 list-group h-100'} style={{minWidth: '200px'}}>
          { menuItems }
          <div><DragAndDropRemoveArea/></div>
        </div>

        <div className={'pt-3 row mr-3 mb-3'}>
          <div className={'col-7'}>
            <h6>Matching facet and subtypes:</h6>
            {facetsViz}
          </div>
          <div className={'col-5'}>
            <h6>Referenced by:</h6>
            {facetsChildrenViz}
          </div>
        </div>
      </FullscreenLeftBarGrid>
    </FullscreenHeaderGrid>
  }
}
