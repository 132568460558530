import _ from 'lodash'
import Immutable from 'immutable'
import React from 'react';
import {
  AtomicBlockUtils,
  CompositeDecorator,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  Modifier,
  SelectionState,
  RichUtils
} from 'draft-js';
import {convertFromHTML, convertToHTML} from 'draft-convert'
import ModalManager from '../../ModalManager';
import EventEmitter from 'events';
import LegoReferenceEditor from '../LegoReferences/LegoReferenceEditor';
import LegoReferenceSpanGenerator from '../LegoReferences/LegoReferenceSpanGenerator';
import ModelContextEditor from '../ModelContextEditor';
import {EditorImage} from "./EditorImage";
import {StyleButton} from "./StyleButton";
import GlossaryRefSpan from "./GlossaryRefSpan";
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import SemanticEditor from "../SemanticEditor";
import GlossaryReferenceEditor from "./GlossaryReferenceEditor";

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'article-summary':
      return 'ArticleSummary';
    case 'article-warning':
      return 'ArticleWarning';
    case 'article-extra':
      return 'ArticleExtra';
    default:
      return null;
  }
}

const BLOCK_TYPES = [
  { label: 'H2', style: 'header-two', icon: 'title' },
  // {label: 'H3', style: 'header-three'},
  // {label: 'Blockquote', style: 'blockquote'},
  { label: 'UL', style: 'unordered-list-item', icon: 'format_list_bulleted' },
  { label: 'OL', style: 'ordered-list-item', icon: 'format_list_numbered' },
  // {label: 'Code Block', style: 'code-block'},
  { label: 'SUMMARY', style: 'article-summary', icon: 'assignment' },
  { label: 'WARNING', style: 'article-warning', icon: 'warning' },
  { label: 'EXTRA', style: 'article-extra', icon: 'info' },
];

const blockRenderMap = Immutable.Map({
  'article-summary': {
    element: 'div'
  },
  'article-warning': {
    element: 'div'
  },
  'article-extra': {
    element: 'div'
  }
});

// Include 'paragraph' as a valid block and updated the unstyled element but
// keep support for other draft default block types
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <span className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </span>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: 'format_bold' },
  { label: 'Italic', style: 'ITALIC', icon: 'format_italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'format_underline' },
  // {label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <span className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </span>
  );
};

const LEGO_REFERENCE_REGEX = /({{[^{}]+}})/g;

function legoReferenceStrategy(contentBlock, callback, contentState) {
  findWithRegex(LEGO_REFERENCE_REGEX, contentBlock, callback);
}

const SPECIAL_VARS_REGEX = /(?:[^{]|^)({[\w+]+})(?:[^{]|$)/g;

function specialVarsStrategy(contentBlock, callback, contentState) {
  findWithRegex(SPECIAL_VARS_REGEX, contentBlock, callback);
}

// function glossaryStrategy(contentBlock, callback, contentState) {
//   findWithRegex(GLOSSARY_REGEX, contentBlock, callback);
// }

const glossaryStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (entityKey !== null && contentState.getEntity(entityKey).getType() === "GLOSSARY_REF");
  }, callback);
};

function findWithRegex(regex, contentBlock, callback) {
  // REGEX MUST COINTAIN EXACTLY ONE CAPTURING GROUP
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    //TODO: Unreliable hack to get index of first capturing group.
    // Could fail in weird regexes where capturing group 1 is substring of previous non caputring string
    start = matchArr.index + (matchArr[0].indexOf(matchArr[1]));
    callback(start, start + matchArr[1].length);
  }
}


export default class ArticleMarkupRichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    let html = this.props.value;
    html = html
      .replace(/<b>/ig, '<strong>')
      .replace(/<\/b>/ig, '</strong>')

    const contentState = convertFromHTML({
      // htmlToStyle: (nodeName, node, currentStyle) => {
      //   if (nodeName === 'span' && node.className === 'ArticleWarning') {
      //     return currentStyle.add('WARNING');
      //   } else {
      //     return currentStyle;
      //   }
      // },

      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'div' && node.className === 'Article__inline-img') {
          console.log('Created entity image')
          return createEntity(
            'IMAGE',
            'IMMUTABLE',
            {
              src: node.style.backgroundImage.replace(/url\(["'](.*)["']\)/, '$1'),
              height: node.style.height
            }
          )
        } else if (nodeName === 'span' && node.className === 'GlossaryRef') {
          return createEntity(
            'GLOSSARY_REF',
            'MUTABLE',
            {
              semantic: node.dataset.glossarySemantic,
            }
          )
        }
      },
      htmlToBlock: (nodeName, node) => {
        if (nodeName === 'p' && node.className === 'ArticleSummary') {
          // let recursive = convertFromHTML(node.outerHTML);
          return 'article-summary'
        } else if (nodeName === 'p' && node.className === 'ArticleWarning') {
          // let recursive = convertFromHTML(node.outerHTML);
          return 'article-warning'
        } else if (nodeName === 'p' && node.className === 'ArticleExtra') {
          // let recursive = convertFromHTML(node.outerHTML);
          return 'article-extra'
        } else if (nodeName === 'div' && node.className === 'Article__inline-img') {
          return { type: 'atomic' }
        }
      }
    })(html);

    const compositeDecorator = new CompositeDecorator([
      {
        strategy: legoReferenceStrategy,
        component: LegoReferenceSpanGenerator(this),
      },
      {
        strategy: glossaryStrategy,
        component: (props) => <GlossaryRefSpan {... props} editor={this}/>
      },
      {
        strategy: specialVarsStrategy,
        component: ({ children }) => <span className={'monospace bg-patito'}>{children}</span>
      }
    ]);

    this.state = {
      editorState: EditorState.createWithContent(contentState, compositeDecorator),
      context: { modelId: 'Chevrolet-Aveo' }
    };

    this.modalActionsBus = new EventEmitter();

    this.fireOnChangeWithHtmlDebounced = _.debounce(this.fireOnChangeWithHtml.bind(this), 300);

    this.focus = () => this.refs.editor.focus();

    this.onChange = (editorState) => {
      this.setState({ editorState });
      this.fireOnChangeWithHtmlDebounced();
    };

    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);

    // setTimeout(() => this.addReference(), 100);
  }

  fireOnChangeWithHtml() {
    let contentState = this.state.editorState.getCurrentContent();

    let options = {
      styleToHTML: (style) => {
      },

      blockToHTML: (block) => {
        if (block.type === 'article-summary') {
          return <p className={'ArticleSummary'}></p>
        } else if (block.type === 'article-warning') {
          return <p className={'ArticleWarning'}></p>
        } else if (block.type === 'article-extra') {
          return <p className={'ArticleExtra'}></p>
        }
      },

      entityToHTML: (entity, originalText) => {
        switch (entity.type) {
          case 'IMAGE':
            const data = entity.data;
            let { src, height } = data;

            return <div className={'Article__inline-img'} style={{
              backgroundImage: `url("${src}")`,
              height: `${height}`
            }}/>;
          case 'GLOSSARY_REF':
            const { semantic } = entity.data;
            return <span className={'GlossaryRef'} data-glossary-semantic={semantic}>{originalText}</span>;
        }

        return originalText;
      },
    }

    let html = convertToHTML(options)(contentState)

    // <li> have dir="auto", remove it
    // Automatically converted " to &quot; break lego references parameters
    html = html.replace(/&quot;/ig, '"')

    this.props.onChange(html);
  }

  handleContextchange(ctx) {
    this.setState({ context: ctx })

    const compositeDecorator = new CompositeDecorator([
      {
        strategy: legoReferenceStrategy,
        component: LegoReferenceSpanGenerator(this),
      }
    ]);

    const newEditorState = EditorState.set(this.state.editorState, { decorator: compositeDecorator });

    this.setState({ editorState: newEditorState })
  }

  _handleKeyCommand(command) {
    // Code command is preventing ctrl+shift+i Chrome shortcut
    if (command === "code")
      return true;

    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    return true;
  }

  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
    // TODO: Research if there is a better way. This causes a blink in user selection
    // Also, a timeout is needed otherwise formatting does not work
    setTimeout(() => this.focus(), 0);
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
    setTimeout(() => this.focus(), 0);
  }

  addImage() {
    let url = prompt('Image url');
    if (url) {
      const { editorState } = this.state;

      let contentState = editorState.getCurrentContent();

      let contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: url, height: '200px' })
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

      let imageInsertedEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');

      this.setState({ editorState: imageInsertedEditorState })
    }
  }

  addGlossary() {
    this.openGlossaryEditor();
  }

  deleteEntity(blockKey, entityKey) {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentBlock = contentState.getBlockForKey(blockKey);

    let selection = SelectionState.createEmpty(blockKey);
    contentBlock.findEntityRanges(
      (character) => character.getEntity() === entityKey,
      (start, end) => {
        selection = selection.merge({ anchorOffset: start, focusOffset: end });
      }
    );

    const newContentState = Modifier.applyEntity(contentState, selection, null);
    const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity')
    this.onChange(newEditorState)
  }

  openGlossaryEditor(entityKey, clickedWord, blockKey) {
    const { editorState } = this.state;
    const content = editorState.getCurrentContent();

    let selection = editorState.getSelection();
    const selected = getFragmentFromSelection(editorState);

    // let semantic = prompt('Semantic');
    if (entityKey || selected) {
      let initialText, glossarySemantic;
      if (entityKey) {
        initialText = clickedWord
        glossarySemantic = content.getEntity(entityKey).getData().semantic;
      } else {
        initialText = selected.map(x => x.getText()).join('');
      }

      this.modalActionsBus.emit('open', <div>
        <GlossaryReferenceEditor text={initialText} semantic={glossarySemantic} onOk={(semantic, text) => {
          if (entityKey) {
            if (semantic) {
              let contentStateWithEntity = content.replaceEntityData(entityKey, { semantic })
              const newEditorState = EditorState.push(editorState, contentStateWithEntity, "apply-entity");
              this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
            } else {
              this.deleteEntity(blockKey, entityKey);
            }
          } else {
            let contentStateWithEntity = content.createEntity('GLOSSARY_REF', 'MUTABLE', { semantic })
            entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.push(editorState, contentStateWithEntity, "create-entity");
            this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
          }

          this.modalActionsBus.emit('close');
        }} value={initialText}/>
      </div>, { minSize: false});
    }
  }

  addReference() {
    this.openReferenceEditor('[data:spec:SPEC_MPG_AVERAGE_USERS]  |  get("byUsers.road.kmPerL.avg")  |  number(1) | table')
  }

  openReferenceEditor(reference, selection) {
    this.modalActionsBus.emit('open', <div>
      <LegoReferenceEditor reference={reference} context={this.state.context} onOk={async (reference) => {
        this.modalActionsBus.emit('close');

        const { editorState } = this.state;

        let contentState = editorState.getCurrentContent();
        contentState = Modifier.replaceText(contentState, selection, `{{ ${reference.trim()} }}`)

        this.setState({ editorState: EditorState.push(editorState, contentState) })

        this.fireOnChangeWithHtmlDebounced();

      }} onCancel={() => this.modalActionsBus.emit('close')}/>
    </div>, true);
  }


  customBlocksRenderer(contentBlock) {
    const type = contentBlock.getType();

    if (type === 'atomic') {
      return {
        component: EditorImage,
        editable: false,
        props: {
          onChange: (newContentState) => {
            return this.updateContentState(newContentState);
          }
        }
      };
    }
  }

  handleReturn(e) {
    const { editorState } = this.state;
    if (e.shiftKey) {
      this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
      return 'handled';
    }
    return 'not-handled';
  }

  updateContentState(newContentState) {
    const newEditorState = EditorState.set(this.state.editorState, { currentContent: newContentState });
    this.setState({ editorState: newEditorState })
  }

  render() {
    const { editorState } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div className="ArticleRichTextEditor">
        <ModalManager actionsBus={this.modalActionsBus} dontRestoreFocus={true}/>
        <div className={'ArticleRichTextEditor__controls'}>
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />

          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />

          <span className="RichEditor-controls">
              <StyleButton
                key={'addImage'}
                label={'Add image'}
                icon={'image'}
                onToggle={() => this.addImage()}
                // style={type.style}
              />
          </span>

          <span className="RichEditor-controls">
              <StyleButton
                key={'addReference'}
                label={'Reference editor'}
                icon={'device_hub'}
                onToggle={() => this.addReference()}
              />
          </span>

          <span className="RichEditor-controls">
              <StyleButton
                key={'addGlossary'}
                label={'Glossary'}
                icon={'menu_book'}
                onToggle={() => this.addGlossary()}
              />
          </span>

          <div className={'ml-3'} style={{ display: 'inline-block', width: '250px' }}>
            <ModelContextEditor value={this.state.context} onChange={this.handleContextchange.bind(this)}/>
          </div>
        </div>
        <div className={className}>
          <Editor
            blockStyleFn={getBlockStyle}
            blockRenderMap={extendedBlockRenderMap}
            blockRendererFn={this.customBlocksRenderer.bind(this)}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            handleReturn={this.handleReturn.bind(this)}
            onChange={this.onChange}
            onTab={this.onTab}
            // placeholder="Tell a story..."
            ref="editor"
            spellCheck={true}
          />
        </div>
        {/*<pre>{JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()), true, 2)}</pre>*/}
      </div>
    );
  }
}
