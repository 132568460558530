import React, { Component } from 'react';
import _ from 'lodash';

import TextareaAutosize from 'react-textarea-autosize';

export default class MultilineTextEditor extends Component {
  render() {
    let {minRows, maxRows, onChange, onBlur, value, className, ... other} = this.props;
    let extraClasses = ' '+ (className || "") ;
    if(this.props.small) {
      extraClasses+= ' form-control-sm'
    }
    return <TextareaAutosize minRows={minRows || 1} maxRows={maxRows || 20} type={'text'}
                             className={'form-control form-control-sm' + (true ? '' : 'text-danger')+extraClasses}
                             onChange={e => onChange(e.target.value)}
                             onBlur={onBlur ? e => onBlur(e.target.value) : null}
                             value={value} { ... other}
    />;
  }
}
