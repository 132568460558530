import EventEmitter from 'events';
import React, { Component } from 'react';
import ModalManager from '../../ModalManager';
import _ from 'lodash';
import { IconButton } from '../IconButton';
import ImageSearchDialog from '../../lego/ImageSearchDialog';
import { SingleImageUrlEditor } from '../images/SingleImageUrlEditor';

export class MultipleImagesEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.modalActionsBus = new EventEmitter();
  }

  zoomImage(url) {
    this.modalActionsBus.emit('open', <div>
      <img style={{ maxWidth: '90vw', maxHeight: '80vh' }} src={url}/>
    </div>, false);
  }

  delete(url) {
    if(confirm('Are you sure')) {
      this.props.onChange(_.filter(this.props.value, img => img.url !== url));
    }
  }

  addImg(img) {
    this.props.onChange([... (this.props.value || []), img]);
  }

  browseImages() {
    this.modalActionsBus.emit('open', <div>
      <ImageSearchDialog folder={''} onOk={async (s3Url) => {
        this.modalActionsBus.emit('close');
        let updatedImageList = [ ... (this.props.value || []), { url: s3Url }]
        this.props.onChange(updatedImageList);
      }} onCancel={() => this.modalActionsBus.emit('close')}/>
    </div>, false);
  }

  render() {
    let {} = this.state;

    const valueImages = this.props.value || [];

    let images = valueImages.map(({ url }) => {
      return <span key={url} className={'mr-1 pos-relative inline-block'}>
        <img className={'img-med img-thumbnail'} key={url} src={url}/>
          <span className={'float-top-left text-center parent-hover-visible'}>
            <IconButton onClick={(e) => e.stopPropagation() + this.zoomImage(url)} level={'primary'} icon={'zoom_in'}
                        className={'p-0'} description={'Zoom In'}/>
          </span>

            <span className={'float-bottom-right text-center parent-hover-visible'}>
              <IconButton onClick={(e) => e.stopPropagation() + this.delete(url)} level={'danger'}
                          icon={'delete_forever'}
                          className={'p-0'}
                          description={'Delete'}/>
            </span>
      </span>;
    });

    if(images.length) {
      images = <div className={'pl-2 pt-2 '}>{images}</div>
    } else {
      images = null;
    }

    let {value, onChange, placeholder, ... otherProps} = this.props;

    let addBtn = <SingleImageUrlEditor forceJPG={this.props.forceJPG}
                                       {...otherProps}
                                       compact={valueImages.length > 0}
                                       value={null}
                                       placeholder={ images ? ' ' : (placeholder || "Add images from S3...") }
                                       onChange={img => this.addImg(img)}/>;

    return <div className={'bg-light rounded'}>
      {images}
      { addBtn }
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
