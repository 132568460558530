import React, {Component} from 'react';
import PropTypes from 'prop-types';

import * as jsdiff from 'diff';

const fnMap = {
  'chars': jsdiff.diffChars,
  'words': jsdiff.diffWords,
  'sentences': jsdiff.diffSentences,
  'json': jsdiff.diffJson
};

const breakInLines = text => {
  let lines = text.replace(/ /g, '\u00a0').split('\n');
  return lines.map((item, index) => {
    if(index < (lines.length - 1)) {
      return (<span key={index}>{item}<br/></span>);
    } else {
      return (<span key={index}>{item}</span>);
    }
  });
};


export default class TextDiff extends Component {
  render() {
    const diff = fnMap[this.props.type](this.props.inputA, this.props.inputB);
    const result = diff.map((part, index) => {
      const spanStyle = {
        backgroundColor: part.added ? 'lightgreen' : part.removed ? 'salmon' : 'lightgrey'
      };
      if(part.added) {
        return <ins key={index}>{breakInLines(part .value)}</ins>
      } else if(part.removed) {
        return <del key={index}>{breakInLines(part.value)}</del>
      } else {
        if(this.props.ellipsis && part.value.length > this.props.ellipsis) {
          let start = part.value.slice(0,this.props.ellipsis/2);
          let end = part.value.slice(this.props.ellipsis/2).slice(-this.props.ellipsis/2);
          return <span key={index}>{breakInLines(start)} <br/><span className={'badge badge-dark'}>(...)</span><br/> {breakInLines(end)}</span>
        } else {
          return <span key={index}>{breakInLines(part.value)}</span>
        }
      }
    });
    return (
      <span className='diff-result'>
        {result}
      </span>
    );
  }
}

TextDiff.defaultProps = {
  inputA: '',
  inputB: '',
  type: 'chars'
}

TextDiff.propTypes = {
  inputA: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  inputB: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  type: PropTypes.oneOf([
    'chars',
    'words',
    'sentences',
    'json'
  ])
}


