import React, { useCallback, useState } from 'react';
import _ from 'lodash';

export default function TextSearchInput({value, onChange, refObj, debounce, placeholder}) {
  const [lastValue, setValue] = useState(value);

  const debouncedOnChange = useCallback(_.debounce(onChange, 250), [onChange]);

  const onChange2 = (e) => {
    setValue(e.target.value);
    if(debounce) {
      debouncedOnChange(e.target.value)
    } else {
      onChange(e.target.value);
    }
  }

  return <input ref={refObj}
                className={'form-control'}
                value={lastValue}
                placeholder={placeholder || 'Search...'}
                type={'text'}
                onChange={onChange2}/>
}
