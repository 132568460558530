import _ from 'lodash';
import React, { Component } from 'react';
import {SelectionState,} from 'draft-js';
import LegoReferenceCache from './LegoReferenceCache';


function isValidLegoReference(reference) {
  //TODO: Find practical way to import code from LegoReference.js
  try {
    let ref = reference.substring(2, reference.length - 2);
    let [selector, ...formatters] = ref.split(/\s*\|\s*/g);
    selector = selector.trim();

    let [type, intentions, semantic] = selector.split(':');

    if (!(type && intentions && semantic)) {
      if (!selector.startsWith('[@') && !selector.startsWith('@')) {
        // console.warn(`Incomplete lego reference: ${selector}`);
        return false;
      }
    }
  } catch (err) {
    return false;
  }
  return true;
}


function isControl(reference) {
  let controlCommands = ['ENDIF', 'IF_REF', 'ELSE'];
  return _.includes(controlCommands.map(s => `${s}`), reference.substring(2, reference.length - 2).trim());
}

export default (editor) => class LegoReferencePreview extends Component {
  constructor(props) {
    super(props);

    this.state = { context: editor.state.context };

    this.debounceResolveReference = _.debounce(this.debounceResolveReference, 300);
  }

  debounceResolveReference(reference) {
    if (!isControl(reference)) {
      LegoReferenceCache.testReference(reference, this.state.context).then(res => this.setState({
        previewReference: reference,
        preview: res
      }));
    } else {
      this.setState({ previewReference: reference, preview: null });
    }
  }

  resolveReferences(reference) {
    this.debounceResolveReference(reference);
  }

  render() {
    let { decoratedText, children } = this.props;
    let level = 'danger';
    let reference = decoratedText;

    if (isValidLegoReference(reference)) {
      level = 'success';
    }


    if (isControl(reference)) {
      let command = reference.substring(2, reference.length - 2).trim().toLowerCase();
      level = ' badge badge-control control-' + command;

      return <span className={`monospace small ` + level}>{children}</span>
    } else {

      let style = {};
      style.position = 'relative';
// style.maxWidth = "20px";


      let nonEditablePreview = null;

      let floatingStyle = {
        userSelect: 'none'
      };

      if (this.state.preview) {
        const isFresh = (reference === this.state.previewReference);
        const hasDivs = this.state.preview?.includes('<div');

        nonEditablePreview = <div contentEditable={false} style={floatingStyle}
                                  className={`ReferencePreview ${hasDivs ? '' : 'inline-block'} ${isFresh ? '' : ' translucent'}`}>
          <div dangerouslySetInnerHTML={{ __html: this.state.preview }}></div>
        </div>;
      } else if (!isControl(reference)) {
        let msg = 'Resolving...'

        if (reference === this.state.previewReference) {
          msg = <span>NO RESULTS. Check syntax, or save Lego to fill caches <span onClick={(e) => {
            this.setState({ previewReference: null });
            e.stopPropagation();
            return false;
          }} className={'btn btn-link m-0 p-0'}>Check again</span></span>;
        }
        nonEditablePreview = <div contentEditable={false} style={floatingStyle}
                                  className={'ReferencePreview translucent text-center p-2'}>{msg}</div>
      }

      if (reference !== this.state.previewReference) {
        this.resolveReferences(reference);
      }

      let openReferenceEditor = () => {
        let { block, start, text } = children[0].props;
        let blockSelection = new SelectionState({
          anchorKey: block.key,
          anchorOffset: start,
          focusKey: block.key,
          focusOffset: start + reference.length,
        });
        editor.openReferenceEditor(reference.replace(/{{|}}/g, ''), blockSelection);
      };

      return <span style={style} onClick={openReferenceEditor}>
        <span className={`inline-lego-ref bg-light-${level} monospace small`}>{children}</span>
        {nonEditablePreview}
      </span>;
    }
  }
};
