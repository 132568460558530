import React, {Component} from 'react';
import _ from 'lodash';

import LivePage from './LivePage';
import submenuEmails from "./menus/submenu-emails";
import {IconButton} from "../components/common/IconButton";
import {computeSignificance} from "../components/stats/statisticalSignificance";
import { LeftRightBox } from '../../../components/common/LeftRightBox';

const hardcodedVariations = {
  'bienvenido.pug': '',
  'denunciaAnswer.pug': 'respuestaDenuncia.participantes',
  'denunciaFirstAnswer.pug': 'respuestaDenuncia.autor',
  'denunciaSolved.pug': 'denunciaResuelta',
  'passwordReset.pug': 'resetPassword',
  'spammerEngagement.pug': 'spammerEngagement',
  'campaigns/newContributorEncouragement.pug': 'newContributorEncouragement'
};

function CTR({count, total}) {
  if(total) {
    const ratio = (count || 0) / total * 100;
    return <span title={`${count} - ${ratio.toFixed(3)}%`}>{ratio.toFixed(0)}%</span>
  } else {
    return '-'
  }
}

function ComparedCTR({count, total, controlCount, controlTotal}) {
  if(!controlTotal || !total || !controlCount) {
    return <CTR count={count} total={total}/>
  } else {
    const ratio = (count / total) / (controlCount / controlTotal) - 1;
    let diff = (ratio*100).toFixed(0);
    const sign = ratio > 0 ? '+' : '';

    const { minSamples, category, significantSampleRatio: r} = computeSignificance(count/total, controlCount/controlTotal, total, controlTotal);

    const opacityBySignificance = r > 1 ? (r/4) : 0;

    const badgeStyle = ({
      color: (ratio > 0.1 ? 'green' : (ratio < -0.1 ? 'darkred': 'gray')),
    });

    if(r > 1) {
      badgeStyle.background = ratio >= 0 ? `rgba(50,255,50, ${opacityBySignificance})` : `rgba(255,80,80, ${opacityBySignificance})`
    } else if(r > 0.5) {
      badgeStyle.background = `rgba(255,255,0, ${0.3*(r-0.5)})`
    }



    if(r < 0.7) {
      badgeStyle.opacity = '0.2'
    }

    return <div className={'inline-block'}>
      <div><CTR count={count} total={total}/></div>
      <div className={`badge badge-light`} style={badgeStyle} title={`sig: ${r}`}>
        {sign}{diff}%
      </div>
    </div>
  }
}

export default class EmailVariationsViewer extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;
    this.submenu = submenuEmails;

    this.state.stats = {};
    this.state.statsDays = 7;
    this.state.showMobile = false;

    this.emails = this.service('services/data/emails/variations');
    this.emailStats = this.service('services/data/emails/stats');
  }

  componentDidMount() {
    super.componentDidMount();

    this.emails.find().then(res => this.setState({ emails: res, controls: {} }))
    this.loadStats();
  }

  async previewEmail(campaign) {
    this.setState({ selectedCampaign: campaign })

    const res = await this.emails.get(campaign.template);

    this.setState({ selectedEmail: { subject: campaign.subject || '', bodyHtml: res } });
  }

  async loadStats() {
    this.setState({ loadingStats: true });
    const stats = await this.emailStats.find({ query: { daysBack: this.state.statsDays } })

    // Find controls
    let controls = {};
    _.each(_.groupBy(this.state.emails, 'campaign'), (emails, campaign) => {
      let marked = _.find(emails, e => !e.variation);
      controls[campaign] = this.state.controls[campaign] || marked || emails[0];
    });

    this.setState({ stats, loadingStats: false, controls });
  }

  async setStatsDay(days) {
    if(this.state.statsDays !== days) {
      this.setState({ statsDays: days }, () => this.runAsync(this.loadStats()));
    }
  }

  useAsVariationControl(email) {
    if(email.campaign) {
      let emails = this.state.emails;
      this.state.controls[email.campaign] = email;
      this.setState({controls: this.state.controls});
    }
  }

  getVarationControl(email) {
    if(email.campaign && this.state.stats) {
      const controlEmail = this.state.controls[email.campaign];

      if(controlEmail)
        return this.state.stats[this.getId(controlEmail)];
    }
  }

  getId({ campaign, variation}) {
    return variation ? `${campaign}.${variation}` : campaign;
  }

  renderMailsTable() {
    if (!this.state.emails) {
      return "Loading emails...";
    }

    let statsDays = this.state.statsDays;

    const sortedEmails = _.sortBy(this.state.emails, '-campaign', '-template', '-variation');
    let lastCampaign = null;
    return <table className={'table table-sm EmailLogTable'}>
      <thead>
      <tr>
        <th rowSpan={2} className={'text-center'}>Campaign / Variation</th>
        <th rowSpan={2} >Subject</th>
        <th colSpan={5} style={{minWidth: '210px'}} className={'text-center'}>
          <div>{_.map([7,15,30], d => <span className={'btn ml-2 p-0 btn-link btn-sm'} key={d} onClick={() => this.setStatsDay(d)}>{d}</span>)}</div>
          Stats last {statsDays} days
        </th>
      </tr>
      <tr>
        <th className={'small text-right'}></th>
        <th className={'small text-right'}>Sent</th>
        <th className={'small text-right'}>Open</th>
        <th className={'small text-center'}>Click</th>
        <th className={'small text-right'}>Uns.</th>
      </tr>
      </thead>
      <tbody>
      {
        _.map(sortedEmails, (email) => {
          const { campaign, variation, template, subject, disabled, notes } = email;

          let campaignId = this.getId(email);
          if(!campaign) {
            campaignId = hardcodedVariations[template] || '---';
          }

          const classes = [];
          if (lastCampaign !== campaign) {
            classes.push('GroupRow');
          }

          if (email === this.state.selectedCampaign) {
            classes.push('SelectedRow')
          }

          let stats = this.state.stats[campaignId] || {};
          let contr = this.getVarationControl(email) || {};
          let isControl = false;
          if(contr === stats) {
            isControl = true;
            contr = {};
          }

          if(disabled) {
            classes.push('DisabledRow')
          }

          lastCampaign = campaign;
          return <tr key={campaign + variation + template} onClick={() => this.previewEmail(email)}
                     className={classes.join(' ')}>
            <td className={'small text-center'}>
              <div className={'text-primary'}>
                {campaign}
                { disabled ? <span className={'ml-1 badge badge-secondary'}>disabled</span> : null}
              </div>
              <div className={'text-secondary break-word-all'}>{variation}</div>
            </td>

            <td>
              <div>{subject}
              </div>
              <div className={'monospace text-secondary'}>
                {template.replace('campaigns/', '').replace('.pug', '')}
              </div>
            </td>

            <td onClick={() => this.useAsVariationControl(email)}>
               {
                 isControl
                   ?
                   <IconButton icon={'brightness_medium'} level={'secondary'}/>
                   :
                 null
               }
            </td>

            <td className={'small  text-right'}>
              {stats.injection || '-'}
            </td>

            <td className={'small text-primary text-right'}>
              <ComparedCTR count={stats.open} total={stats.injection} controlCount={contr.open} controlTotal={contr.injection}/>
            </td>

            <td className={'small text-success text-center no-wrap'}>
              <ComparedCTR count={stats.click} total={stats.injection} controlCount={contr.click} controlTotal={contr.injection}/>
              <span className={'ml-1 text-secondary'}> / <ComparedCTR count={stats.click} total={stats.open} controlCount={contr.click} controlTotal={contr.open}/></span>
            </td>

            <td className={'small text-danger text-right no-wrap'}>
              { stats.unsubscribe ? <CTR count={stats.unsubscribe} total={stats.injection}/> : null }
            </td>
          </tr>
        })
      }
      </tbody>
    </table>
  }

  renderSelectedEmail() {
    if (!this.state.selectedEmail) {
      return <div className={'h3 text-center m-3 mt-5 text-secondary'}>Select a campaign...</div>
    }

    let sizeStyle = { width: '100%', height: 'calc(100% - 100px)', border: 'none', position: 'absolute' };

    const showMobile = this.state.showMobile;

    if(showMobile) {
      sizeStyle = { width: '320px', height: '640px', border: 'none', position: 'absolute', left: 'calc(50% - 160px)' };
    }

    return <div className={''}>
      <div className={'d-flex justify-content-between'}>
        <div>
          <h5 className={'mt-3 mb-3 ml-2'}>
            <IconButton icon={'refresh'} onClick={() => this.previewEmail(this.state.selectedCampaign)}/>
            {this.state.selectedEmail.subject}
          </h5>
        </div>
        <IconButton icon={showMobile ? 'computer' : 'phone_iphone'} onClick={() => this.setState({ showMobile: !showMobile })}/>
      </div>

      <iframe
        style={sizeStyle}
        id={'email'}
        name={'email'}
        sandbox={'allow-same-origin'}
        srcDoc={this.state.selectedEmail.bodyHtml}
      />
    </div>
  }

  renderPageBody() {
    return <div className={'EmailLogsViewer'}>
      <div className={'LayoutLeftBar'} style={{ gridTemplateColumns: 'auto 700px' }}>
        <div className={'overflow-auto pos-relative'}>
          {this.renderMailsTable()}
        </div>
        <div className={'bg-light pos-relative'} style={{ 'width': '700px' }}>
          {this.renderSelectedEmail()}
        </div>
      </div>
      {/*<pre>{JSON.stringify(this.state.emails || 'Loading...', true, 2)}</pre>*/}
    </div>
  }
}
