import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import LivePage from './LivePage';
import { TrimsBuilder } from '../components/lego/trims/TrimsBuilder';
import ModelContextEditor from '../components/lego/ModelContextEditor';
import ObjectSearchHandler from '../components/common/ObjectSearchHandler';
import TrimsViewer from "./subpages/TrimsViewer";

export default class DataProcessManager extends LivePage {
  constructor(props) {
    super(props);

    this.trimService = this.service('services/trims');
    this.trimRawParsedService = this.service('services/parsed-raw-trim');

    let modelFilter = this.getUrlParam('modelFilter') || {};
    if(!modelFilter.modelId) {
      modelFilter.modelId = 'Volkswagen-Vento'
      this.setUrlParams({modelFilter})
    }

    let country = this.getUrlParam('country', true);
    if(!country) {
      country = 'ar';
      this.setUrlParams({country: 'ar'})
    }

    this.state = {
      ... this.state,
      modelFilter,
      country,
      trims: [],
      sourceA: [],
      sourceB: [],
    };

    this.shortcuts.registerKeyMappings({ 'focusSearch': 'ctrl+f', });
    this.shortcuts.registerActionHandlers({
      'focusSearch': this.focusSearch.bind(this),
    });

    this.searchInputRef = React.createRef();
    this.textFilterChangedDebounced = _.debounce(this.textFilterChanged, 250).bind(this)
  }

  componentDidMount() {
    super.componentDidMount();
    this.runAsync(this.loadData(), 'Loading trims...');

    // this.openModalSubpage(TrimsViewer, {modelFilter: {modelId: 'Ford-Fiesta', year: 2011}});
  }

  focusSearch() {
    const node = this.searchInputRef.current;
    if(node) {
      node.focus();
      node.select();
    }
    return false;
  }

  async loadData(ignoreChanges) {
    if(!ignoreChanges && this.changesCount()) {
      let abort = confirm(`You have ${this.changesCount()} unsaved changes, you will lose them if you continue`)
      if(!abort) {
        return;
      }
    }
    let baseModelQuery = {
      ... this.state.modelFilter,
      country: this.state.country,
    }; // {displayName: {$regex: '[mMnN][fF][oO]'}};

    const trims = await this.trimService.find({query: {... baseModelQuery,}})

    const runIdVendetuauto = "77328f47-4af9-4971-b84f-60736e5d2374"
    const runIdWibe = "603559d5-8f5b-4a7d-82fb-c3d3f2361c03"
    const runIdAutocosmos = "182d3175-1aff-11e8-b139-24a074f2fc16"
    const runIdAutocosmosNewer = "1c94a93a-a968-464c-acad-acaa37dac74d"
    const runIdAcara = "50d8b0dc-1cca-11e8-9324-24a074f2fc16"
    const runIdAcaraOlder = "26ed2021-33f5-11ea-9b63-005056c00008"
    const runIdAcaraNewer = "f51ba90b-9254-4da1-bf37-e39b470162db"

    const sourceQueryByCountry = {
      ar: {
        a: {... baseModelQuery, sourceRunId: {$in: [runIdAutocosmos, runIdAutocosmosNewer]}},
        b: {... baseModelQuery, sourceRunId: {$in: [runIdAcara, runIdAcaraOlder, runIdAcaraNewer]}}
      },
      mx: {
        a: {... baseModelQuery, sourceRunId: runIdVendetuauto},
        b: {... baseModelQuery, sourceRunId: runIdWibe},
      }
    }


    const sourceA = await this.trimRawParsedService.find({query: sourceQueryByCountry[this.state.country].a})
    const sourceB = await this.trimRawParsedService.find({query: sourceQueryByCountry[this.state.country].b})


    // const sourceA = await this.trimRawParsedService.find({query: {... baseModelQuery, sourceRunId: runIdVendetuauto}})
    // const sourceB = await this.trimRawParsedService.find({query: {... baseModelQuery, sourceRunId: runIdWibe}})

    this.setState({trims, sourceA, sourceB})
  }

  trimsUpdated(trims) {
    this.setState({ trims });
  }

  sourceUpdated(sourceDoc) {
    this.setState({ sourceA: this.state.sourceA, sourceB: this.state.sourceB });
  }

  modelChanged(modelData) {
    let params = _.pick(modelData, 'modelId', 'year');
    params.modelId = params.modelId || ''

    this.setState({modelFilter: params}, () => {
      this.setUrlParams({modelFilter: params})
      if(params.modelId) {
        this.runAsync(this.loadData(), `Loading trims for ${params.modelId}...`);
      }
    });
  }

  async saveChanges() {
    const newTrims = _.filter(this.state.trims, trim => trim.__new);
    const deletedTrims = _.filter(this.state.trims, trim => trim.__deleted);
    const changedTrims = _.filter(this.state.trims, trim => !trim.__deleted && !trim.__new && trim.__changed);

    const changedSources = [ ... _.filter(this.state.sourceA, s => s.__changed), ... _.filter(this.state.sourceB, s => s.__changed)];

    // First save new trims, and then update matches references with the correct ID
    // Save old 'temporal' trim _ids to update changedSources pointing to them once a real mongo _id is available
    let addNewTrims = _.map(newTrims, async trim => {
      trim._oldId = trim._id;
      let { _id, __new, __changed, ...newTrimData } = trim;
      const newTrim = await this.trimService.create(newTrimData);
      console.log('Adding trim', newTrim);

      for (const src of changedSources) {
        if (src.trimMatch === trim._id) {
          src.trimMatch = newTrim._id;
        }
      }
    });
    await Promise.all(addNewTrims);

    let allOtherAsyncOps = [];

    allOtherAsyncOps.push(..._.map(changedTrims, async trim => {
      await this.trimService.update(trim._id, trim);
      console.log('Updated trim ', trim);
    }));

    allOtherAsyncOps.push(..._.map(deletedTrims, async trim => {
      await this.trimService.remove(trim._id);
      console.log('Deleted trim ', trim);
    }));

    // Save trimMatch of changed sources
    allOtherAsyncOps.push(..._.map(changedSources, async src => {
      let { trimMatch, skipTrim, matchType, updatedBy } = src;
      await this.trimRawParsedService.update(src._id, { $set: { trimMatch, skipTrim, matchType, updatedBy } });
      console.log('Updated src ', src);
    }));

    await Promise.all(allOtherAsyncOps);

    await this.loadData(true);
  }

  textFilterChanged(text) {
    this.setState({ textFilter: text });
  }

  changesCount() {
    let { trims, sourceA, sourceB, sessionId, connectionStatus, textFilter } = this.state;
    let countChanged = docs => _.filter(docs, doc => doc.__changed || doc.__new || doc.__deleted).length;
    return countChanged(trims)+countChanged(sourceA)+countChanged(sourceB)
  }

  renderPageBody() {
    let { country, trims, sourceA, sourceB, sessionId, connectionStatus, textFilter } = this.state;

    let headerBg =  textFilter ? 'bg-primary' : 'bg-light-secondary'

    let changes = this.changesCount();

    // Count how many trims are matched/unmatched
    const isMatched = ({trimMatch, skipTrim}) => trimMatch && skipTrim !== true;
    const isMissingMatch = ({trimMatch, skipTrim}) => !trimMatch && skipTrim !== true;
    let matchedTrims = _.filter(sourceA, isMatched).length + _.filter(sourceB, isMatched).length;
    let unmatchedTrims = _.filter(sourceA, isMissingMatch).length + _.filter(sourceB, isMissingMatch).length;
    let matchPercentage = 100*matchedTrims / ((matchedTrims+unmatchedTrims) || 1)

    return <div className={''}>
      <div className={'bg-dark'}>
        <div className={`${headerBg} p-2 row no-gutters sticky-top`}>
            <span className={'col-3'}>
              <ModelContextEditor value={this.state.modelFilter} onChange={ctx => this.modelChanged(ctx || {})}/>
            </span>


          <span className={'col-3 text-center text-secondary h4 pt-1 m-0'}>
              <span className={'badge badge-info mr-2 '}>{country.toUpperCase()}</span>
              <span className={'text-success'}>{matchedTrims}</span>
              /
              <span className={'text-danger'}>{unmatchedTrims}</span>
              <span className={'text-light'}>&nbsp; {matchPercentage.toFixed(1)}%</span>
            </span>

          <span className={'col-3'}>
              {changes ?
                <span className={'ml-2 btn btn-warning'} onClick={() => this.runAsync(this.saveChanges(), 'Saving...')}>
                Save {changes} changed trims/matchings</span> : null}
            </span>

          <span className={'col-3'}>
               <input ref={this.searchInputRef}
                      className={`form-control ${this.state.textFilter ? '' : ''}`}
                      placeholder={'Search...'} type={'text'}
                      onChange={e => this.textFilterChangedDebounced(e.target.value)}/>
            </span>
        </div>
      </div>

      <TrimsBuilder sourceA={sourceA}
                    sourceB={sourceB}
                    trims={trims}
                    user={this.getLoggedUserSignature()}
                    onSourceChange={this.sourceUpdated.bind(this)}
                    textFilter={this.state.textFilter}
                    onChange={trims => this.trimsUpdated(trims)}/>
    </div>;
  }
}
