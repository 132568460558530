import React, { Component } from 'react';
import _ from 'lodash';

import GlobalClipboard from './GlobalClipboard';

export default class GlobalClipboardZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pasting: false,
      imageData: null,
      mouseX: 100,
      mouseY: 100,
    };

    this.imgRef = React.createRef();

    this.globalOnPasteHandler = this.globalOnPasteHandler.bind(this);

    this.escapeHandler = this.escapeHandler.bind(this)
    this.mouseMove = this.mouseMove.bind(this)
    this.clickOutsideHandler = this.clickOutsideHandler.bind(this)
  }

  globalOnPasteHandler(event) {
    // use event.originalEvent.clipboard for newer chrome versions
    let clipboardData = event.clipboardData || event.originalEvent.clipboardData;

    const items = clipboardData.items;
    const files = clipboardData.files;

    console.log('Global paste event', items, files); // will give you the mime types

    if(!GlobalClipboard.getActivePasteListeners().length) {
      console.log('No active registered paste listeners')
      return;
    }

    // find pasted image among pasted items
    let blob = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === 0) {
        blob = items[i].getAsFile();
      }
    }

    // load image if there is a pasted image
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = event => {
        let imageData = event.target.result;

        this.setState({ pasting: true, imageData });

        GlobalClipboard.pasteImage({ imageData, blob, files }, () => {
          this.setState({ pasting: false, imageData: null });
        });
      };
      reader.readAsDataURL(blob);
    }
  }

  componentDidMount() {
    // window.addEventListener('paste', ... or
    document.onpaste = this.globalOnPasteHandler;
  }

  componentWillUnmount() {
    document.onpaste = null;
  }

  mouseMove(e) {
    let onTarget = false;
    for(const handler of GlobalClipboard.getActivePasteListeners()) {
      if(!handler.ref.current) {
        continue;
      }

      let {left, top, bottom, right} = handler.ref.current.getBoundingClientRect();
      if (e.clientX >= left && e.clientX <= right && e.clientY >= top && e.clientY <= bottom) {
        onTarget = true;
        if(this.state.activeDropZoneHandler !== handler) {
          handler.onPasteHover(true);
          this.setState({ mouseX: e.pageX, mouseY: e.pageY, onDropZone: true, activeDropZoneHandler: handler })
        } else {
          this.setState({ mouseX: e.pageX, mouseY: e.pageY })
        }
      } else if(this.state.activeDropZoneHandler === handler) {
        handler.onPasteHover(false);
      }
    }
    if(!onTarget) {
      this.setState({ mouseX: e.pageX, mouseY: e.pageY, onDropZone: false, activeDropZoneHandler: null })
    }
  }

  escapeHandler(e) {
    if (e.key === "Escape") {
      this.setState({pasting: false, imageData: null})
      GlobalClipboard.cancelPaste();
    }
  }

  clickOutsideHandler(e) {
    if(this.state.activeDropZoneHandler && _.includes(GlobalClipboard.getActivePasteListeners(), this.state.activeDropZoneHandler)) {
      this.state.activeDropZoneHandler.onPaste();
    } else {
      this.setState({ pasting: false, imageData: null })
      GlobalClipboard.cancelPaste();
    }
  }

  render() {
    let {pasting, imageData, onDropZone, mouseX, mouseY} = this.state;
    if (pasting && imageData) {
      let imgStyle = {
        position: 'absolute',
        zIndex: '999999',
        top: mouseY - 50,
        left: mouseX -50,
        pointerEvents: 'auto'
      };

      let classes = ['full-screen-overlay']
      if(onDropZone) {
        classes.push('full-screen-overlay--hot')
      }

      return <div onMouseMove={this.mouseMove} onClick={this.clickOutsideHandler} onKeyPress={this.escapeHandler} className={classes.join(' ')}>
        <img className={'shadow img-200 '+(onDropZone? '' : 'translucent')} ref={this.imgRef} crossOrigin={'anonymous'}
             src={imageData} key={'paste'} alt={`Clipboard image`} style={imgStyle}/>
      </div>;
    } else {
      return <div style={{ display: 'none' }}/>;
    }
  }
}
