import React, { useCallback, useState } from 'react';

import _ from 'lodash';
import AutocompleteMagic from './AutocompleteMagic';

export default function SearchInput({ autoFocus, refObject, debounce, value, onChange, onCancel, searchHandler, ...other }) {
  const getOptions = (text) => {
    text = text || '';

    let specialChars = text.match(/(.*)?(\:\!?)([^:=\s]*)$/);
    if (specialChars) {
      let [all, rest, op, lastToken] = specialChars;

      let filters = _.filter(_.keys(searchHandler.objectFilters), f => f.toLowerCase().startsWith(lastToken.toLowerCase()))
      if(!filters.length) {
        filters = _.filter(_.keys(searchHandler.objectFilters), f => f.toLowerCase().includes(lastToken.toLowerCase()))
      }

      return _.map(filters, name => ({
        value: (rest || '') + op + name + (name === lastToken ? '=' : ''),
        label: <span>
          <span className={'monospace text-info mr-2'}>{op}{name}{name === lastToken ? '=' : ''}</span>
          <span className={'text-secondary small'}>{searchHandler.objectFilters[name]?.description || ''}</span>
        </span>
      }));
    }

    return [];
  };

  const [lastValue, setValue] = useState(value);

  const debouncedOnChange = useCallback(_.debounce(onChange, 250), [onChange]);

  const onChange2 = (text) => {
    setValue(text);
    if(debounce) {
      debouncedOnChange(text)
    } else {
      onChange(text);
    }
  }


  return <AutocompleteMagic
    // fullWidth={true}
    autoSize={false}
    refObject={refObject}
    autoFocus={autoFocus}
    noStyle={true}
    text={lastValue || ''}
    onGetOptions={getOptions}
    onChange={(text) => onChange2(text)}
    onCancel={onCancel || (() => false)}
    autoComplete={'off'}
    {...other}
  />;
}
