import React, { useState } from 'react';
import dayjs from 'dayjs';

export default function InPageNotifications({notifications, onClose}) {
  const [max, setMax] = useState(5);
  const levelMapping = { error: 'danger' };


  return <div style={{position: 'fixed', bottom: '0px', right: '0px', zIndex: 99999, maxHeight: '100%', overflow: 'auto'}}>
    {
      notifications.length > max ? <div className={"mb-2 mr-2 toast fade show bg-light-info p-2"} role="alert">
        And {notifications.length - max} more notifications...
        <div className={'mt-1'}>
          <span className={'mr-2 btn btn-danger btn-small'} onClick={() => onClose(notifications)}>Clear all</span>
          <span className={'btn btn-small btn-primary'} onClick={() => setMax(10000)}>Show all notifications</span>
        </div>
      </div> : null
    }
    {
      notifications.slice(-max).map((notif,i) => <div key={i+notif.time} style={{position: 'relative'}}>
        <div className={"mb-2 mr-2 toast fade show bg-light-"+levelMapping[notif.level] || notif.level} role="alert">
          <div className="toast-header">
            <strong className="mr-auto">{notif.title}</strong>
            <small className="text-muted ml-2">{notif.time && dayjs(notif.time).fromNow()}</small>

            <button type="button" className="ml-2 mb-1 close" onClick={() => onClose([notif])} data-dismiss="toast" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <pre className="toast-body mb-0 text-wrap break-word-all">{notif.body}</pre>
        </div>
      </div>)
    }
  </div>
}
