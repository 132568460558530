import React, {Component} from 'react';
import __ from "../interop/i18n";
import urls from "../interop/urls";

export default function UserAvatar({ user, id: userId, esMecanico, large, ...other }) {
  let { username, id } = { username: '?', id: userId || 'undefined', ... user };

  let avatarColorIndex = 0;
  const AVAILABLE_COLORS = 48;
  try {
    avatarColorIndex = parseInt((id || '000').slice(-2), 16) % AVAILABLE_COLORS;
  } catch (err) {
    console.log('Broken avatar-user');
  }

  const size = large ? 'large' : 'small';

  const initials = (username || "?").replace(/\s/g, '').slice(0, 2).toUpperCase();

  let mechanicAddOn = null;
  if(esMecanico || user?.esMecanico) {
    mechanicAddOn = <img className={'mechanic'} src={cdn("/images/icons/mechanic-wrench2.png")} title={__('views.denuncia.contactProfessional')}/>
  }

  return <a href={urls.urlMemberProfile(id)} target={'_blank'}
            className={`avt${avatarColorIndex} avatar-${size} gen-avatar ${(esMecanico ? " mechanic" : "")}`} title={username} {...other}>
    <span className={'initials'}>{initials}</span>
    { mechanicAddOn }
  </a>;
}
