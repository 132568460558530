import React from 'react';
import _ from 'lodash';

import ManualFeaturesDateEditor from "./manuals-extraction/ManualFeaturesDateEditor";
import autodetectManualDate from "./automatic-suggestions/autodetectManualDate";
import HighlightedText from "../common/HighlightedText";
import TaskEditorPdfExtraction from "./TaskEditorPdfExtraction";

export default class TaskEditorManualDateExtraction extends TaskEditorPdfExtraction {
  constructor(props) {
    super(props);

    this.state.pdfInitialSearch = "\\b(((19|20)\\d{2})|\\d\\d/\\d\\d/\\d\\d)\\b"
  }

  async computeSuggestions() {
    const { task } = this.props;
    const { pagesDescription, file } = this.state;

    let autosuggestionsExplanation = null;
    let autosuggestions = null;

    let  {suggestedFields, resultsByValue} = autodetectManualDate(pagesDescription, file, task);
    let suggestionsPairs = _.sortBy(_.toPairs(resultsByValue), ([year, samples]) => samples.length).reverse();

    if (suggestionsPairs.length && !task.finishedAt) {
      autosuggestions = {features: suggestedFields};
      console.log("Suggestions: ", suggestedFields)
      let suggestionsUI = _.map(suggestionsPairs, ([year, samples], i) => {
        return <div key={year + i}>
          <div className={''}>
            <span className={'badge badge-primary mr-2'}>{year}</span>
            <span className={'text-primary'}>Found in <strong>{samples.length}</strong> pages:</span>
          </div>
          <div className={'small ml-2'}>
            {
              _.map(samples.slice(0, 4), ({ snippet, regex, page }) => {
                return <div key={page}>
                  <span className={'badge badge-secondary mr-2'}>Page {page}</span>
                  ...<HighlightedText key={page} text={snippet} regex={regex}/>...
                </div>;
              })
            }
          </div>
        </div>
      });

      autosuggestionsExplanation = <div className={'m-1 p-1 bg-light-success rounded'}>
        <div className={'h6 text-success mb-1'}>Automatic suggestions candidates:</div>
        {suggestionsUI}
      </div>;
    }
    this.setState({ autosuggestions, autosuggestionsExplanation });
  }

  async save(changedManual) {
    await this.updateManual(changedManual._id,  {
      $set: {
        features: changedManual.features
      }
    });

    await this.doneAndNext();
  }

  renderExtractionControls() {
    let { files, file, fileHash, autosuggestions, autosuggestionsExplanation } = this.state;

    if(file) {
      return <React.Fragment>
        <ManualFeaturesDateEditor manual={file} key={fileHash + autosuggestions}
                                        initialChanges={autosuggestions}
                                        onSave={async (changedManual) => this.save(changedManual)}/>
        {autosuggestionsExplanation}
      </React.Fragment>
    } else {
      return  "No manual"
    }
  }
}
