import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import format from 'date-fns/format'

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import SingleValueSelector from '../common/editors/SingleValueSelector';

export function DataIssuesStatistics() {
  const { page } = useContext(LegoAdminPageContext);

  const [stats, setStats] = useState();
  const [groupByField, setGroup] = useState('Day');

  const statsService = page.service('/services/data/issues/statistics');

  useAsyncEffect(async () => {
    setStats(await statsService.find({query: {daysBefore: groupByField === 'Day' ? 60 : 365}}));
  }, [groupByField]);

  let content;
  if (stats) {
    let { solvedByDay, createdByDay } = stats;

    const groupBy = doc => {
      let d = new Date(doc._id.date);
      return {
        Day: doc._id.date,
        Week: format(d, "yyyy 'Week' ww"),
        Month: format(d, 'yyyy-MM (MMM)'),
      }[groupByField]
    }

    let solved = _.groupBy(solvedByDay, groupBy);
    let created = _.groupBy(createdByDay, groupBy);
    let days = _.union(_.keys(solved), _.keys(created)).sort();
    days.reverse();

    content = <div>
      <div className={'d-flex align-items-center mb-2'}>
        Group by: <SingleValueSelector className={'flex-grow-1 ml-2'} value={groupByField} options={['Day', 'Week', 'Month']} onChange={(v) => setGroup(v)}/>
      </div>
      <table className={'table table-sm'}>
        <thead>
        <tr>
          <th>Date (last 30 days)</th>
          <th className={'text-right px-4'}>Created</th>
          <th className={'text-right px-4'}>Solved</th>
          <th className={'text-center'}>Solved by model</th>
        </tr>
        </thead>
        <tbody>
        {days.map(date => {
          let maxShown = groupByField === 'Day' ? 20 : 5;

          let groups = _.sortBy(solved[date] || [], 'count').reverse();

          return <tr>
            <td className={'text-secondary'}>{date}</td>

            <td className={'text-danger text-right px-4'}>{_.sumBy(created[date] , 'count')}</td>

            <td className={'text-success text-right px-4'}>{_.sumBy(groups, 'count')}</td>

            <td className={'p-0 bg-light'}>
              <table className={'table table-borderless mb-0 ml-2'}>
                <tbody>
                {_.map(groups.slice(0, maxShown), ({ _id: { date, modelId }, count }) =>
                  <tr className={'small'}>
                    <td width={'70px'} className={'text-success text-right'}>{count}</td>
                    <td>{modelId}</td>
                  </tr>)}

                {groups.length > maxShown ? <tr className={'small'}>
                  <td  width={'70px'} className={'text-success text-right'}>{_.sum(groups.slice(maxShown).map(d => d.count))}</td>
                  <td className={'text-info'}>Other {groups.slice(maxShown).length} models</td>
                </tr> : null}
                </tbody>
              </table>

            </td>

          </tr>;
        })}
        </tbody>
      </table>

      {/*<pre>{JSON.stringify(stats.solvedByDay, true, 2)}</pre>*/}
    </div>;
  } else {
    content = 'Loading statistics...';
  }

  return <div>
    {content}
  </div>;
}
