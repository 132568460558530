import React from 'react';

import _ from 'lodash';
import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { TypeTag } from './TypeTag';

export default class LightSelect extends React.Component {
  handleSelectChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { onChange, value, options, ...props } = this.props;

    return (
      <select value={value} onChange={this.handleSelectChange.bind(this)} {...props}>
        {options.map(o => <option key={o} value={o}>{o}</option>)}
      </select>
    );
  }
}
