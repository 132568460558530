import React, {Component} from 'react';

export default React.memo(function GridLayoutHeader({ children, fullScreen, className, ... other }) {
  const arrChildren = React.Children.toArray(children);

  if (!arrChildren) {
    return <div className={'alert alert-danger'}>FullscreenHeaderGrid has to be used with extactly 2 children.</div>;
  }

  let [left, content] = arrChildren;

  return <div className={'LayoutHeader '+(fullScreen ? 'LayoutHeader--fullscreen ' : '') + (className || '')} {... other}>
    <div className={'pos-relative'}>{left}</div>
    <div className={'pos-relative'}>{content}</div>
  </div>
});
