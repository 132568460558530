import React from 'react';
import _ from 'lodash';
import { UserAvatar } from './UserAvatar'

import InputUserIdBase from '../../../../components/admin/inputs/InputUserIdBase';

async function fetchRank() {
  const users = await client.service('/services/data/users/ranking').get({query: 'ranking', params: {limit: 100}});
  return _.map(users, (u) => ({id: u.userId, ... u}))
}

async function searchUsers(search) {
  const UserInfoService = client.service('/services/data/users/info');
  let { totalCount, users } = await UserInfoService.find({ query: { search } });
  return users;
}

export default function InputUserId({ onChange, value, ...otherProps }) {
  return <InputUserIdBase onChange={onChange} value={value} fetchRank={fetchRank}
                          searchUsers={searchUsers} UserAvatar={UserAvatar}
                          {...otherProps}/>
}
