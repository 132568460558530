import { prettyDuration } from '../common/TimeUtils';
import React from 'react';

export class TimeFrom extends React.PureComponent {
  componentDidMount() {
    this.update = setInterval(() => this.forceUpdate(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.update);
  }

  render() {
    const elapsedMs = new Date().valueOf() - this.props.from.valueOf();
    const extraMs = this.props.extra || 0;

    return prettyDuration(elapsedMs + extraMs);
  }
}
