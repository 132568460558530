import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import useLegoData from "../data-caches/useLegoData";

let FIELD_COLORS_MAPPING = {
  'bodyStyle': 'warning',

  'doors': 'primary',
  'engine_fuel': 'primary',

  'engine': 'gris-blue',
  'equipment': 'secondary',
  'other': 'secondary',
  'year': 'violeta',
  'modelId': 'violeta',
  'genId': 'success',
  'country': 'dark',

  // TODO: Used in casual user vehicle
  'trimText': 'secondary',
  'fuelType': 'gris-blue',
  'transmission': 'gris-blue',
  'countryCode': 'secondary',
  'trimId': 'light',

  '_source': 'partial-success',

  // TODO: Used in legos
  'body': 'dark',
  'trims': 'turquesa',
  'trim': 'turquesa',
  'fuel': 'dark',
};

function TrimSpec({ spec, value, className, showSpec, ...otherProps }) {
    const specs = useLegoData('trimSpecs', {});
    const [fieldCategory] = spec.split('_');
    let color = FIELD_COLORS_MAPPING[spec] || FIELD_COLORS_MAPPING[fieldCategory] || (specs[spec] ? 'info' : 'danger');

    let not = null;
    value = (value || '').toString();
    if(value.slice(0,1) === '!') {
      not =  <span className={'small badge badge-warning'}>NOT</span>;
      value = value.slice(1);
    }

    return <span className={`badge badge-${color} inline-block align-middle ${className || ""}`} title={`${spec}=${not ? '!' : ''}${value}`} {...otherProps}>
      <div className={not ? 'text-warning' : ''}>{not} {value}</div>
      { showSpec ? <div className={'zoom-75 translucent'}>{spec}</div> : null}
    </span>
};

// export default React.memo(TrimSpec);
export default TrimSpec;

// let res = <span className={'badge badge-success'}>COPY</span>
// export default ({spec, value}) => res;
