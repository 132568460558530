import { useContext, useEffect, useState } from 'react';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';

const userCache = {};

export default function useUser(userId) {
  const { page } = useContext(LegoAdminPageContext);
  const [userData, setData] = useState(userCache[userId] || null);

  useEffect(() => {
    if(userId && !userCache[userId]) {
      page.service('services/data/users/info').get(userId).then(data => {
        setData(data || false);
        userCache[userId] = data;
      }).catch(console.error);
    }
  }, [userId]);

  return userData;
}
