import React, { useContext, useEffect, useState } from 'react';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import CachedPDFManualLoader from '../pdf-tools/CachedPDFManualLoader';
import PDFTwoWayComparison from '../pdf-tools/PDFTwoWayComparison';
import ManualSourceSelector from '../lego/ManualSourceSelector';

export default function ManualComparison({manualIdA, manualIdB, from, to}) {
  let {page} = useContext(LegoAdminPageContext);
  let [manualsData, setManuals] = useState(null);
  let [[idA, idB], setSource] = useState([manualIdA, manualIdB]);

  useEffect(() => {
    page.runAsync(async () => {
      setManuals(null)
      let [a,b] = await page.service('services/data/manuals').find({query: {_id: {$in: [idA, idB]}}})
      if(a && b) {
        let resA = await CachedPDFManualLoader.loadManualTextLayers(a.metadata.hash, a.metadata.s3Bucket);
        let resB = await CachedPDFManualLoader.loadManualTextLayers(b.metadata.hash, b.metadata.s3Bucket);

        resA.hash = a.metadata.hash;
        resB.hash = b.metadata.hash;
        resA.s3Bucket = a.metadata.s3Bucket;
        resB.s3Bucket = b.metadata.s3Bucket;

        setManuals([resA, resB]);
      } else {
        alert('Some pdfs not found')
      }
    }, 'Loading manuals...')
  }, [idA, idB])

  let comparison;
  if (manualsData) {
    let [{}, {}] = manualsData;
    comparison = <PDFTwoWayComparison pdfA={manualsData[0]} pdfB={manualsData[1]} from={from} to={to}/>;
  } else {
    comparison = <div className={'full-screen-center'}>Loading manuals #{idA} and #{idB}</div>;
  }

  return <div>
    <div className={'row bg-light no-gutters'}>
      <div className={'col-6 p-2'}>
        <ManualSourceSelector value={{ id: idA }} onChange={(m) => setSource([m.id, idB])}/>
      </div>
      <div className={'col-6 p-2'}>
        <ManualSourceSelector value={{ id: idB }} onChange={(m) => setSource([idA, m.id])}/>
      </div>
    </div>
    {comparison}
  </div>;
}
