import React, { PureComponent } from 'react';
import _ from 'lodash';

import Async from 'react-select/lib/Async';
import TrimSpec from './TrimSpec';
import useLegoData from "../data-caches/useLegoData";

let FIELD_COLORS_MAPPING = {
  'engine': 'primary',
  'equipment': 'secondary',
  'other': 'secondary',
}

export default function TrimSpecsEditor({value, onChange, ... props}) {
  const handleSelectChange = (values, actionMeta) => {
    let mergedContext = {};
    _.each(values, ({value}) => mergedContext = {... mergedContext, ... value});
    onChange(mergedContext);
  }

  const getOption = (props) => {
    let {innerProps, isSelected, isFocused} = props;
    let {innerRef, ... filteredProps} = innerProps;

    let [[field, val]] = _.toPairs(JSON.parse(props.value).value)
    const [fieldCategory] = field.split('.');
    let color = FIELD_COLORS_MAPPING[fieldCategory] || 'info'

    return <div {... filteredProps} className={'p-1 '+((isSelected || isFocused) ? 'bg-light-primary': '')}>
      <span>{field}</span><span className={'text-secondary'}>=</span><TrimSpec spec={field} value={val}/>
    </div>
  };

  const getMultiValueLabel = (props) => {
    let [[field, val]] = props.data.value ? _.toPairs(props.data.value) : _.toPairs(props.data);
    return <TrimSpec className={'mr-1'} key={`${field}-${val}`} spec={field} value={val}/>
  };

  const originalSpecs = useLegoData('trimSpecs', {});

  let specs = _.flatten(_.map(originalSpecs, (vals,key) => _.map(vals, val => ({label: `${key}=${val}`, value: {[key]: val}}))))

  const options = [... specs];

  let { ... other } = value || {};

  let values = [];

  _.each(other, (val, key) => values.push({label: '='+  val, value: {[key]: val}}))

  // Always show modelId first, then year, then anything else
  values = _.sortBy(values, ({value}) => value.modelId ? 1 : (value.year ? 2 : 3))

  let loadOptions = async (input) => {
    let regex = new RegExp();
    try {
      regex = new RegExp((input || "").trim(), 'i');
    } catch(e) {}

    let extra = [];
    let matchSpec = input.match(/([^=\s]+)=([^=\s]+)/);
    if(input && matchSpec) {
      let [,key, val] = matchSpec;
      extra = [{label: input, value: {[key]: val}}]
    }

    const valueEqualsInput = ({value}) => !(_.values(value)[0] || '').toLowerCase().toString() === input;
    const valueStartsWithInput = ({value}) => !(_.values(value)[0] || '').toLowerCase().toString().startsWith(input);
    const keyStartsWithInput = ({value}) => !(_.keys(value)[0] || '').startsWith(input);

    const sortedOptions = _.sortBy(_.filter(options, ({label}) => label.match(regex)), valueEqualsInput, valueStartsWithInput, keyStartsWithInput);
    return [... extra, ... sortedOptions.slice(0, 20)]
  };

  return (
    <Async
      closeOnSelect={true}
      isMulti
      components={{
        Option: getOption,
        MultiValueLabel: getMultiValueLabel,
      }}
      getOptionValue={(option) => JSON.stringify(option)}
      onChange={handleSelectChange}
      loadOptions={loadOptions}
      placeholder="specName=specValue..."
      menuShouldScrollIntoView={false}
      maxMenuHeight={170}
      styles={{ menu: (provided) => ({... provided, zIndex: 1000})}}
      // menuPositionunion={'fixed'}

      value={values}
      {... props}
    />
  );
}
