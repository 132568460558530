import React, { Component } from 'react';
import dayjs from 'dayjs'
import EventEmitter from 'events';
import _ from 'lodash';

import { IconButton } from '../common/IconButton';
import ModalManager from '../ModalManager';
import LeftRightLayout from '../common/layout/LeftRightLayout';

let IMAGES_CACHE = null;

export default class ImageSearchDialog extends Component {
  constructor(props) {
    super(props);

    this.imagesService = window.client.service('services/s3-images-legos');

    this.state = {
      query: '',
      results: IMAGES_CACHE,
      loading: true,
      folder: props.folder || '',
      limit: 1000,
      viewLimit: 100
    };

    this.modalActionsBus = new EventEmitter();

    this.fetchImages = _.debounce(this.fetchImages, 150);

    this.fetchImages();
  }

  async bindAndHandleErrors(method) {
    return method.bind(this);
  }

  async fetchImages() {
    this.setState({ loading: true });

    let res = await this.imagesService.find({query: {folder: this.state.folder, limit: this.state.limit+1}});

    IMAGES_CACHE = res;

    this.setState({ results: res, loading: false });
  }

  async queryChanged(e) {
    let q = e.target.value;

    this.setState({ query: q });
  }

  filterSubfolder(folder) {
    this.setState({folder: `${this.state.folder}${folder}`})
    this.fetchImages();
  }

  onFolderUpClick() {
    this.setState({ folder: '' });
    this.fetchImages();
  }

  getFolderBlock(folder) {
    return <div key={folder} title={folder} onClick={() => this.filterSubfolder(folder)}
                className={'ImageMosaic ImageMosaic--folder m-1'}>
      <span className={''}>
              <div className={'mb-1'}>{folder}</div>
            </span>
    </div>
  }

  async delete(item) {
    if(confirm(`THERE IS NO UNDO. Are you sure you want to delete ${item.url}?`)) {
      item.removing = true;
      this.setState({ loading: true, results: this.state.results });
      let res = await this.imagesService.remove(item.url);
      await this.fetchImages();
    }
  }

  zoom({url}) {
    this.modalActionsBus.emit('open', <div>
      <img style={{maxWidth: '90vw', maxHeight: '80vh'}} src={url}/>
    </div>, false);
  }

  loadMore() {
    this.setState({limit: this.state.limit*5}, () => this.fetchImages())
  }

  render() {
    let images = null;

    let {folder, query, loading, results, limit, viewLimit} = this.state;

    let imageCount = '...';

    let loadMoreBtn = null;

    if(!loading && (results.files.length+results.folders.length) === (limit+1)) {
      loadMoreBtn = <a href={'#'} className={'ml-2 small'}  role="button" onClick={() => this.loadMore()}>(first {limit}, load more...)</a>;
    }

    if (results === null && loading) {
      images = <div className={'text-center'}>Loading from S3...</div>;
    } else {
      if (results) {
        images = []

        let filteredResults = _.sortBy(results.files, img => -img.lastModified);

        filteredResults = _.filter(filteredResults, ({url}) => {
          let [protocol,,domain,originalsFolder,legosFolder, ... restOfKey] = url.split('/')
          return restOfKey.join('/').startsWith(folder) && !url.endsWith('/');
        })

        const folders = _.filter(results.folders, folderPath => {
          let [originalsFolder, legosFolder, ... restOfKey] = folderPath.split('/')
          return restOfKey.join('/').startsWith(folder);
        }).map(subfolder => subfolder.replace(`originals/legos/${folder}`, ''))


        if(query) {
          filteredResults = _.filter(filteredResults, ({url}) => url.indexOf(query) >= 0);
        }

        let byFolders = _.groupBy(filteredResults, ({url}) => url.split('/')[6] ? url.split('/')[5]+'/' : '');

        _.each(folders, (subfolder) => images.push(this.getFolderBlock(subfolder)))

        imageCount = byFolders[folder]?.length || 0;

        images = images.concat(_.map((byFolders[folder] || []).slice(0, viewLimit), (item) => {
          let {url, size, lastModified, removing} = item;
          return <div key={url} title={url} onClick={() => this.props.onOk(url)} className={'ImageMosaic m-1 '+(removing ? 'translucent' : '')}>

            <span className={'float-top-left text-center parent-hover-visible'}>
              <IconButton onClick={(e) => e.stopPropagation() + this.zoom(item) } level={'primary'} icon={'zoom_in'}
                          description={'Zoom In'}/>
            </span>

            <span className={'float-top-right text-center parent-hover-visible'}>
              <IconButton onClick={(e) => e.stopPropagation() + this.delete(item) } level={'danger'} icon={'delete_forever'}
                          description={'Delete'}/>
            </span>

            <img src={url} className={'ImageMosaic__img'}/>

            <span className={'ImageMosaic__metadata'}>
              <span className={''}>{Math.ceil(size/(1024))}kb </span>
              <span className={'text-info'}>{dayjs(lastModified).fromNow()}</span>
            </span>

          </div>;
        }));

        let seeAllBtn = (byFolders[folder] || []).length > viewLimit ? <div className={'text-center mt-2 mb-2'}>
        <span className={'btn btn-primary'} onClick={() => this.setState({viewLimit: 100000})}>
            View all results (UI will be slow)
        </span></div> : null;

        images = <div className={''}>{ images } { seeAllBtn }</div>
      } else {
        images = <div className={'alert alert-danger'}>No images found ¿?</div>;
      }
    }

    return <div style={{ width: '760px', height: '500px' }}>
      <div>
        <input autoFocus={true} placeholder={'Search names...'} type={'text'} className={'form-control'}
               value={query}
               onChange={this.queryChanged.bind(this)}/>
      </div>

      <div className={'mt-3 p-1 monospace bg-white'}>
        <LeftRightLayout>
          <span>
            <IconButton onClick={this.onFolderUpClick.bind(this) } icon={'subdirectory_arrow_left'} level={'primary'}></IconButton>
            {"/"+folder}
            {loading ? <span className={'ml-3 text-info'}>Loading...</span> : null}
            { loadMoreBtn }
          </span>

          <span className={'text-secondary small'}>{imageCount} images</span>
        </LeftRightLayout>
      </div>

      <div className={'mt-1'}>{images}</div>
      {/*<div className={'mt-3 '}>*/}
      {/*<button onClick={() => this.props.onOk("")} className={'btn btn-primary mr-2'}>Ok</button>*/}
      {/*<button onClick={() => this.props.onCancel} className={'btn btn-default'}>Cancelar</button>*/}
      {/*</div>*/}

      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
