import React, { Component, createRef } from 'react';
import _ from 'lodash';

import { IconButton } from '../../common/IconButton';
import { SingleImageUrlEditor } from '../../common/images/SingleImageUrlEditor';
import MultipleItemsEditor from '../MultipleItemsEditor';
import TrimSpecsEditor from '../trims/TrimSpecsEditor';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import { ExtractedTextEditor } from '../../common/editors/ExtractedTextEditor';

export class WarningLightsPdfExtractionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      actionIndex: 0,
      executingCombo: false
    };

    this.childImage = createRef();
    this.childText = createRef();
    this.scrollRef = createRef();

    props.shortcuts.registerKeyMappings( {
      'addLight': ['shift+ctrl+l']
    });

    props.shortcuts.registerActionHandlers({
      'addLight': () => this.startAddLightCombo()
    });

    this.actions = [
      () => this.addItemCombo('warning-lights'),
      () => this.childText.current.copyFromPdf('Select light name text... '),
      () => this.childImage.current.pickPdfScreenshot('Select the light symbol...'),
      () => this.setState({actionIndex: 0}, () => this.executeNextComboAction())
    ];
  }

  startAddLightCombo() {
    this.setState({executingCombo: true, actionIndex: 0}, () => this.executeNextComboAction());
  }

  executeNextComboAction () {
    const { actionIndex, executingCombo } = this.state;

    if (executingCombo) {
      this.setState({ actionIndex: actionIndex + 1 });
      this.actions[actionIndex]();
    }
  }
  getContextIdentifier() {
    return this.props.context && this.props.context.length ? _.values(this.props.context[0]).join('-').toLowerCase() : null;
  }

  editField(elementType, elementIndex, field, newValue) {
    let lego = _.cloneDeep(this.props.value);

    if(!lego.data[elementType]) {
      lego.data[elementType] = []
    }

    if (!lego.data[elementType][elementIndex]){
      _.remove(lego.data[elementType], _.isEmpty);
      lego.data[elementType].push({});
    }

    if (field){
      lego.data[elementType][elementIndex][field] = newValue;
    }



    this.props.onChange(lego);
  }

  updateContext(newContext) {
    if (newContext.length > 0) {
      let lego = _.cloneDeep(this.props.value);
      lego.context = newContext;
      this.props.onChange(lego);
    }
  }

  addItem (itemType) {
    this.editField(itemType, this.props.value.data[itemType]?.length || 0)
  }

  addItemCombo (itemType) {
    this.addItem (itemType);
    this.scrollRef.current.scrollIntoView({behavior: 'smooth'});
    setTimeout(() => this.executeNextComboAction(),10);
  }

  render() {
    const { context, data, _id } = this.props.value || {};
    const { executingCombo } = this.state;

    let header = null;
    let stateBg = 'success'
    if(_id) {
      header = <a target={'_blank'} className={'small '} href={`/legos/all?editing="${_id}"`}>Lego {_id} <IconButton icon={''}/></a>
      stateBg = 'secondary'
    }

    let contexts = _.isArray(context) ? context : [context];

    let defaultNewContext = {};
    if (contexts[0] && contexts[0].modelId && contexts[0].year) {
      defaultNewContext = { modelId: contexts[0].modelId, year: contexts[0].year };
    }

    const delItem = (type, index) => {
      const lego = _.cloneDeep(this.props.value);
      lego.data[type].splice(index, 1);
      this.props.onChange(lego);
    };

    const contextEditor = <MultipleItemsEditor value={contexts}
                                               defaultNewItem={defaultNewContext}
                                               buttonsAlwaysVisible={true}
                                               renderComponent={(item, props) => <TrimSpecsEditor
                                               value={item} {...props}/>}
                                               onChange={(specs) => this.updateContext(specs)}
    />;

    const elementEditor = (i, onDelete, onChangeName, onChangeImg, valueName, valueImg, folder, isLast) => <div className={'rounded px-1 mb-1 '+( (executingCombo && isLast) ? ' bg-warning' : 'bg-white')} key={i}>
      <strong>#{i+1}</strong><IconButton icon={'delete'} level={'secondary'} onClick={onDelete}/>
      <ExtractedTextEditor placeholder={"Name"}
                           overlayMessage={'Select light name text... '}
                           value={valueName}
                           className={'mb-1 zoom-90'}
                           onChange={onChangeName}
                           onTextSelected={() => isLast && setTimeout(() => this.executeNextComboAction(), 0)}
                           onCancel={() => isLast && setTimeout(() => this.setState({ executingCombo: false }),0)}
                           ref={isLast ? this.childText : null}/>

      <SingleImageUrlEditor forceJPG={true} value={valueImg}
                            defaultName={this.getContextIdentifier()}
                            manualId={this.props.manualId}
                            manualPdfUrl={this.props.manualPdfUrl}
                            placeholder={'Icon'}
                            overlayMessage={'Select the light symbol...'}
                            folder={folder}
                            category={folder}
                            trimThreshold={10}
                            onChange={onChangeImg}
                            onSelected={() => isLast && setTimeout(() => this.executeNextComboAction(), 0)}
                            onCancel={() => isLast && setTimeout(() => this.setState({ executingCombo: false }),0)}
                            ref={isLast ? this.childImage : null} hideButtonsOnSelected/>
    </div>;

    const lights = _.map(data?.['warning-lights'] || [], ({ lightImg, lightName },i) => {
      let onDelete = () => {
        delItem('warning-lights', i);
        this.setState({executingCombo: false});
      };
      let onChangeName = txt => this.editField('warning-lights', i, 'lightName', txt);
      let onChangeImg = img => this.editField('warning-lights', i, 'lightImg', img);
      return elementEditor(i, onDelete, onChangeName, onChangeImg, lightName, lightImg, 'warning-lights', data['warning-lights'].length === i + 1);
      }
    )

    const dashboards = _.map(data?.['dashboard-overview'] || [], ({ dashboardImg, dashboardName },i) =>
      elementEditor(i, () => delItem('dashboard-overview', i), txt => this.editField('dashboard-overview', i, 'dashboardName', txt), img => this.editField('dashboard-overview', i,'dashboardImg', img), dashboardName, dashboardImg, 'dashboard-overview')
    )

    return <div className={`rounded p-1 bg-light-${stateBg} zoom-90`}>
      {header}

      {contextEditor}
      {dashboards}
      <IconButton icon={'add'} level={'success'} onClick={() => this.addItem('dashboard-overview')}>Add Dashboard Image #{dashboards.length+1}</IconButton>

      {lights}
      <IconButton icon={'add'} level={'success'} onClick={() => this.startAddLightCombo()}>Add Light #{lights.length+1}&emsp;(Shift+Ctrl+L)</IconButton>
      <div ref={this.scrollRef}/>
    </div>;
  }
}

WarningLightsPdfExtractionEditor.contextType = LegoAdminPageContext;
