import React, {Component} from 'react';
import _ from "lodash";

// TODO: This component is the same as FullTag in repository knowledge-builder.

const wrap = {whiteSpace: 'unset'};

const entityRename = {
  'MULTIPLE_EVENTS': '⚡⚡',
  'EVENT': '⚡',
  'PROP_AND': '&'
}

const tagToString = (tag) => {

  if(tag && tag.entity) {
    let {entity, props} = tag;

    const entityName = entityRename[entity] || entity.toString();

    if(entity === 'PROP_AND') {
      return <span key={'AND'} className={'inline-block p-1'}>
        &{ _.map(_.values(props), (val, i) => <span className={'badge-dotted ml-1'} key={i}>{tagToString(val)}</span>) }
      </span>
    } else if(entity === 'PROP_OR') {
      return <span key={'OR'} className={'inline-block'}>
        { _.map(_.values(props), (val, i) => <span>{i > 0 ? ' | ' : null}<span className={'badge rounded'} key={i}>{tagToString(val)}</span></span>) }
      </span>
    }

    if (props && _.keys(props).length > 0) {
      let propTags = _.map(props, (val, key) => {
        return <span key={key} className={'badge inline mr-1 pt-0 pb-0'}>
          <span style={{borderBottom: 'solid 1px rgba(0,0,0,0.15)'}}>{ tagToString(val)}</span><br/>
          <span className={ (key.toString()).endsWith('^') ? 'inline text-dark' : 'inline text-warning'}>
            {key}
          </span>
        </span>;
      });
      return <span key={entityName} className={'mr-1'}>{entityName} {propTags}</span>;
    } else {
      return " "+entityName;
    }
  } else {
    if(tag && tag.value)
      return <span className={'bg-light-dark pl-1 pr-1'}>{tag.value} </span>;
    else {
      return (tag || "").toString();
    }
  }
};

function parseSemanticKey(semKey) {
  let consumeSem = (key) => {
    if (!key || !key.length)
      return null;

    const isLiteral = key.match(/^"([^"]+)"(.*$)/);

    if(isLiteral) {
      const [,token,rest] = isLiteral;
      return [rest, {value: token}]
    }

    const [entity] = key.match(/^[$\w]+/);
    const props = {};
    const effects = {};

    if (!entity)
      return null;

    // Consume entity
    key = key.substring(entity.length);
    if (key.startsWith('(')) {
      key = key.substring(1); // Consume "("
      while (!key.startsWith(')')) {
        // $ and ! are needed by semantic pattern matching in Invariants
        // ^ is needed by semantic side effects
        const [, propKey, brackets, restOfKey] = key.match(/^([\w$!^]+)=(\[)?(.*)$/);
        key = restOfKey;

        let propValue = null;

        // Parse many or only one
        if (brackets) {
          let isOr = false;
          const values = [];

          while (!key.startsWith(']')) {
            const [leftKey, value] = consumeSem(key);
            values.push(value);
            key = leftKey;

            if (key.startsWith(','))
              key = key.substring(1); // Consume ","
            else if (key.startsWith('|')) {
              key = key.substring(1); // Consume "|"
              isOr = true;
            }
          }

          if (values.length > 1) {
            if (isOr) {
              propValue = { entity: 'PROP_OR', props: { ...values } };
            } else {
              propValue = { entity: 'PROP_AND', props: { ...values } };
            }
          } else {
            propValue = values[0];
          }


          key = key.substring(1); // Consume "]"
        } else {
          const [leftKey, value] = consumeSem(key);
          propValue = value;
          key = leftKey;
        }

        // Consume "," or "|" that might be present separating props
        if (key.startsWith(',') || key.startsWith('|')) {
          key = key.substring(1);
        }
        key = key.trim();

        if (propKey.endsWith('^')) {
          effects[propKey] = propValue;
        } else {
          props[propKey] = propValue;
        }
      }
      key = key.substring(1); // Consume ")"
    }

    const keyValue = {entity};

    if (_.keys(props).length) {
      keyValue.props = props;
    }

    if (_.keys(effects).length) {
      keyValue.sideEffects = effects;
    }

    return [key, keyValue];
  };

  const [leftKey, keyValue] = consumeSem(semKey, {});
  if(leftKey.trim()) {
    throw `semKey "${semKey}" could not be fully parsed, "${leftKey}" left`
  }

  return keyValue;
}

// It is recursive in case a tag object with nested arrays is used instead of strings
export default function SemanticTag({ tag, semKey, level, extraNode, style, extraClass, ...other }) {
  try {
    tag = tag || parseSemanticKey(semKey);
  } catch (e) {
    tag = { entity: semKey };
    level = 'danger';
  }

  let { entity, start, end, props } = tag;

  let cat = level;


  const className = `align-middle m-1 badge badge-${cat || 'outline'} ${extraClass || ''}`;

  return <span {...other} title={semKey} style={{... wrap, ... style}} className={className}>
          {tagToString({ entity, props })} {extraNode || null}
        </span>;
}
