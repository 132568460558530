import React, { Component } from 'react';
import ManualsDataCache from './data-caches/ManualsDataCache';

export default class PdfManualSourceSummary extends Component {
  constructor(props) {
    super(props);

    this.state = { manuals: [] };

    this.manualsDataCache = new ManualsDataCache();
    this.manualsDataCache.onDataUpdate(manuals => this.setState({manuals}))
  }

  render() {
    const manualRef = { ...this.props.value };
    manualRef.obj = this.state.manuals.find(m => m._id === manualRef.id);

    if (manualRef.obj) {
      let { _id, year, idModelo, locale, country, isoLanguage, manualPdfUrl } = manualRef.obj;

      return <span className={'small inline-block text-center'}>
          <a href={manualPdfUrl} target={'_blank'} className={'mr-2 small'} onClick={e => e.stopPropagation()}>
            <i className={`zoom-75 material-icons align-middle mr-1`}>open_in_new</i> PDF
          </a>
      <span className={'badge badge-secondary'}>{manualRef.type}</span>
      <br/>
        <span>{idModelo.split('-')[1]} {year} {isoLanguage}</span>
        <br/>
    </span>;
    } else {
      return <span className={'text-danger'}>Manual id {manualRef.id}</span>;
    }
  }
}
