import _ from 'lodash';
import React from 'react';
import { useContext, useState } from 'react';

import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import { LocalesSelector } from '../LocalesSelector';
import { Icon } from '../../common/Icon';
import LegoLabel from '../LegoLabel';
import { SwitchInput } from '../../common/SwitchInput';
import { LocaleFlag } from '../LocaleFlag';
import LegoEditButton from '../LegoEditButton';

export function ModalTranslateFusebox({ lego, onTranslationDone }) {
  let [boxes, setBoxes] = useState(lego.data.map(() => true));
  let [locale, setLocales] = useState(['es', 'en', 'pt'].filter(loc => _.some(lego.labels, l => l === `translateTo:${loc}` || l.startsWith(`translatedTo:${loc}`)))[0]);

  const getIdFromLabels = (locale) => _.compact(_.map(lego.labels, l => l.match(new RegExp(`^translatedTo:${locale}_(\\w+)$`, 'i'))?.[1]))[0];

  let [translationId, setId] = useState(getIdFromLabels(locale));
  let [translatedLego, setTranslatedLego] = useState(null);
  let { page } = useContext(LegoAdminPageContext);

  const updateTranslatedLego = async () => {
    if (translationId) {
      setTranslatedLego(await page.service('services/legos').get(translationId));
    } else {
      setTranslatedLego(null);
    }
  };

  useAsyncEffect(updateTranslatedLego, [translationId]);

  const translate = () => {
    let params = { _id: lego._id, boxesToTranslate: boxes, targetLocale: locale };
    page.runAsync(async () => {
      let newLegoId = await page.service('services/data/fusebox-translation').create(params);
      setId(newLegoId);
      onTranslationDone(newLegoId);
    }, 'Translating...');
  };

  const updateTranslation = () => {
    let params = { boxesToTranslate: boxes, save: true };
    page.runAsync(async () => {
      let newLegoId = await page.service('services/data/fusebox-translation').update(translationId, params);
      updateTranslatedLego();
      onTranslationDone(newLegoId);
    }, 'Translating...');
  };

  const ellipsis = (s, n = 40) => (s || '').slice(0, n) + (s?.length > n ? '...' : '');

  const setBox = (i, checked) => {
    boxes[i] = checked;
    setBoxes([...boxes]);
  };


  return <div>
    <div className={'text-center'}>
      <div className={'text-center mb-3'}>
        Translate to: <LocalesSelector multi={false} value={locale} onChange={(v) => {
        setLocales(v);
        setId(getIdFromLabels(v));
      }}/>
      </div>

      {locale && _.some(boxes) && !translatedLego ?
        <>
          <button onClick={translate} className={'btn btn-success mr-2'}>
            <Icon icon={'translate'}/> Translate selected boxes to <LocaleFlag locale={locale}/>
          </button>
        </> : null}

      {locale && _.some(boxes) && translatedLego
        ?
        <button onClick={updateTranslation} className={'btn btn-primary mr-2'}>
          <Icon icon={'refresh'}/> Update translation
        </button>
        : null}

      {translationId ? <>

        <LegoLabel className={'ml-2'} label={`translatedTo:${locale}_${translationId}`}/>
        <LegoEditButton legoId={translationId}>{translationId}</LegoEditButton>
      </> : null}

    </div>

    <div className={'mt-2 d-flex justify-content-around'}>
      {lego.data.map((box, i) => {
        let hasTranslation = translatedLego && translatedLego.data[i]?.isTranslated;

        return <div key={i}
                    className={'rounded border ' + (boxes[i] ? (hasTranslation ? 'bg-light' : 'bg-light-primary') : 'bg-light-danger')}>

          <div className={'text-center'}>
            <SwitchInput value={boxes[i]} onChange={(checked) => setBox(i, checked)}>
              Translate box
            </SwitchInput>
          </div>

          <table className={'small'}>
            <thead>
            <tr>
              <th colSpan={2}>
                <div className={'text-center h6'}>[{i + 1}] {ellipsis(box.boxName, 40)}</div>
              </th>
              {hasTranslation ? <th>
                <div className={'text-center h6'}>[{i + 1}] {ellipsis(translatedLego.data[i].boxName, 40)}</div>
              </th>: null}
            </tr>
            </thead>
            <tbody>
            {box.fuses.map((fuse, fuseIndex) => {

              return <tr key={fuseIndex} className={'border-bottom'}>
                <td className={'px-2'}>{fuse.id}</td>
                <td className={'text-secondary px-2'}>{ellipsis(fuse.description)}</td>
                {
                  hasTranslation ?
                    <td className={'text-secondary bg-light-success px-2'}>
                      {ellipsis(translatedLego.data[i].fuses[fuseIndex]?.description)}
                    </td>
                    : null}
              </tr>;
            })}
            </tbody>
          </table>
        </div>;
      })}
    </div>
  </div>;
}
