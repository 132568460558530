import React from 'react';
import SingleValueSelector from '../common/editors/SingleValueSelector';

let options = [
  {
    label: 'Warning light snippet', value: {
      id: 'warning-lights',
      default: {
        tags: []
      },
      fields: [
        { id: 'snippetShowFailed', label: `❌ Snippet didn't show and should`, type: 'tag' },
        { id: 'snippetShowOk', label: '✅ Snippet shown ok', type: 'tag' },
        { id: 'snippetShowWrong', label: '❌ Snippet shown but shouldn\'t', type: 'tag' },
        { id: 'snippetImageWrong', label: '❌🖼 Snippet shown but shouldn\'t', type: 'tag' },
        { id: 'linguisticProblem', label: '❌✒ Linguistic: broken or weird', type: 'tag' },
        { id: 'firstReportNotMatch', label: '❌ Reports: Do not match ling/semantic', type: 'tag' },
      ]
    }
  }
];

export const metricOptions = options.map(op => op.value);

export function PageSampleMetricSelector({ ... props }) {
  return <SingleValueSelector options={options} placeholder={'Select a metric...'} {... props}/>;
}
