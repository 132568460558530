import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Modal from 'react-modal';
import _ from 'lodash'
import LeftRightLayout from "./common/layout/LeftRightLayout";

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    position: 'relative',
    inset: 0,
    padding: '0.5em',
    maxHeight: "95%",
    maxWidth: "94%"
  },
  titleBar : {
    margin: '-0.5em -0.5em 0.5em -0.5em'
  }
};

const fullScreenStyle = {
  width: '95%',
  height: '94%',
  display: 'flex',
  flexDirection: 'column'
}

export default class ModalManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modals: [],
      restoreFocus: this.props.restoreFocus
    };


    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);


    this.bus = this.props.actionsBus;

    // For accesibility, part of Modal
    Modal.setAppElement('#root');
  }

  componentDidMount() {
    this.bus.on('open', this.openModal);
    this.bus.on('close', this.closeModal);
  }

  componentWillUnmount() {
    this.bus.off('open', this.openModal);
    this.bus.off('close', this.closeModal);
  }

  openModal(modalContent, minSizeOrOptions = true) {
    let options = {
      minSize: true,
      fullScreen: false,
      overflow: true,
    }

    if (_.isObject(minSizeOrOptions)) {
      options = { ...options, ...minSizeOrOptions };
    } else {
      options = { ...options, minSize: minSizeOrOptions };
    }

    this.state.modals.push({content: modalContent, options})
    this.setState({modals: this.state.modals});
  }

  async closeModal(triggerEvent) {
    // If there is a trigger event, it was closed via escape or mouse event
    let modal = this.state.modals.at(-1);

    if(modal && (!triggerEvent || !modal.options.closeConfirmationCbk || await modal.options.closeConfirmationCbk())) {
      if(modal.options.onClose) {
        modal.options.onClose()
      }

      this.state.modals.pop();
      this.setState({modals: this.state.modals});
    }
  }

  render() {
    let modalsDom = this.state.modals.map(({content, options}, i) => {
      let style = {
        content: {... customStyles.content},
        overlay: {... customStyles.overlay},
        titleBar: {... customStyles.titleBar}
      };

      if(options.minSize) {
        style.content.minHeight = "50%";
        style.content.minWidth = "70%";
      }

      if(options.fullScreen) {
        style.content = {... style.content, ... fullScreenStyle}
      }

      if(options.overflow === false) {
        style.content.overflow = 'visible';
      }

      if(options.noPadding) {
        style.content.padding = '0';
        style.titleBar.margin = '0'
      }

      return <Modal
        key={i}
        isOpen={true}
        shouldReturnFocusAfterClose={!this.props.dontRestoreFocus}
        onRequestClose={this.closeModal}
        style={style}
      >
        <LeftRightLayout className={'bg-light flex-grow-0'} style={style.titleBar}>
          <h5 className={'m-0 ml-3'}>{options.title || ''}</h5>
          <span className={'btn btn-link p-0 m-2'} style={{zIndex: 999}} onClick={this.closeModal}><i className={'material-icons'}>close</i></span>
        </LeftRightLayout>

        {content}
      </Modal>
    });

    return <>{modalsDom}</>;
  }
}
