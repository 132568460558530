import React, { Component } from 'react';
import _ from 'lodash';
import { IconButton } from '../common/IconButton';
import { Icon } from '../common/Icon';
import { ShortcutsManager } from '../common/ShortcutsManager';
import { prettyDuration } from '../common/TimeUtils';
import { OnOffIcon } from '../../../../components/common/OnOffIcon';
import NotesEditor from '../common/editors/NotesEditor';
import { Link } from 'react-router-dom';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { TimeFrom } from './TimeFrom';

const StatBadge = ({ stat, children, className, level, bg, ...other }) => {
  let [center, ... below] = children;
  return <div className={`StatBadge ${className || ''}`}>
    <span className={`text-center align-middle p-1 inline-block bg-${bg || 'secondary'}`} {...other}>
      <div className={`h5 m-0 monospace text-${level || 'white'}`}>{stat}</div>
      <div className={'small'}>{center}</div>
    </span>
    <div className={'small text-center text-secondary'}>{below}</div>
  </div>
}

export default class BatchTaskEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      paused: true,
      isAutoPause: false,
      draftNote: null
    };

    this.tasksService = window.client.service('services/data-tasks');

    this.editorRef = React.createRef();

    // If no mouse/keyboard activity in 30 seconds, pause batch
    this.onActivityResetAutoPause = _.throttle(() => {
      clearTimeout(this.pauseTimeout);
      if(this.state.paused && this.state.isAutoPause) {
        this.start();
      }
      this.pauseTimeout = setTimeout(() => {
        if(!this.state.paused) {
          console.log(new Date().toLocaleTimeString()+' AUTOPAUSE -----')
          this.pause(true);
        }
      }, 30000)
    }, 1000);

    this.onOtherTabActivity = this.onOtherTabActivity.bind(this);
    this.broadCastTaskInProgress = this.broadCastTaskInProgress.bind(this);
  }

  componentDidMount() {
    this.loadBatch().catch((err => alert(err)));

    $('html').mousemove(this.onActivityResetAutoPause);
    $('html').keydown(this.onActivityResetAutoPause);
    // Make sure key events captured by shortcuts also renew autoPause time
    ShortcutsManager.onAnyShortcut(this.onActivityResetAutoPause)
    this.onActivityResetAutoPause();
  }

  onOtherTabActivity(e) {
    if(e.data?.event === 'activity') {
      console.log(new Date().toLocaleTimeString()+' ACTIVIDAD, HOJALDRE')
      this.onActivityResetAutoPause()
    }
  }

  componentWillUnmount() {
    // Remove handler so that once component is destroyed, it does not continue as ghost
    clearTimeout(this.pauseTimeout);
    $('html').unbind('mousemove', this.onActivityResetAutoPause);
    $('html').unbind('keydown', this.onActivityResetAutoPause);
    this.context.page.tabsChannel.removeEventListener('message', this.onOtherTabActivity)
    ShortcutsManager.offAnyShortcut(this.onActivityResetAutoPause)

    if(!this.state.paused) {
      this.pause(false);
    }
  }

  async loadBatch() {
    const tasks = await this.props.loadBatchTasks();

    const firstNonFinishedTask = _.findIndex(tasks, t => !t.finishedAt);
    await this.openTask(!_.isNumber(this.props.initialTask) ? Math.max(0, firstNonFinishedTask) :this.props.initialTask );
    this.setState({ loading: false, tasks })
  }

  async saveWorkIntervalSoFar() {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    // Stop working on task, update task total time and save worked interval
    if (currentTask && !this.state.paused) {
      const duration = new Date() - this.state.workIntervalStart;
      let lastWorkInterval = {
        start: this.state.workIntervalStart,
        duration,
        userId: window.user.id
      };
      currentTask.startedAt = currentTask.startedAt || this.state.workIntervalStart;

      (currentTask.workedIntervals = currentTask.workedIntervals || []).push(lastWorkInterval)
      currentTask.workedTotalTime = (currentTask.workedTotalTime || 0) + duration

      await this.updateTask(this.state.currentTask);
    }
  }

  async updateTask(taskIndex) {
    const task = this.state.tasks[taskIndex]
    task.updatedBy = this.props.user;
    this.state.tasks[taskIndex] = await this.tasksService.update(task._id, task);
    this.setState({tasks: this.state.tasks});
  }

  async pause(isAutoPause = false) {
    clearInterval(this.broadcastingInterval);
    $(window).unbind('beforeunload', this.preventClose);

    try {
      this.context.page.tabsChannel.postMessage({ event: 'taskInProgressPaused' })
    }catch(e) {
      console.error('Error sending message to other tabs', e.toString())
    }

    if(!isAutoPause) {
      this.context.page.tabsChannel.removeEventListener('message', this.onOtherTabActivity)
    }

    await this.saveWorkIntervalSoFar();

    this.setState({ paused: true, isAutoPause });
  }

  preventClose(e) {
    return 'Vas a perder el tiempo de tu task!!!'; //This is displayed on the dialog
  }

  broadCastTaskInProgress() {
    const task = this.state.tasks[this.state.currentTask];
    const { batchId, workedTotalTime } = task;
    const workIntervalStart = this.state.workIntervalStart;
    this.context.page.tabsChannel.postMessage({event: 'taskInProgress', batchId, workIntervalStart, workedTotalTime})
  }

  start() {
    this.context.page.tabsChannel.addEventListener('message', this.onOtherTabActivity);
    $(window).on('beforeunload', this.preventClose);

    clearInterval(this.broadcastingInterval);
    this.broadcastingInterval = setInterval(this.broadCastTaskInProgress, 1000);
    this.broadCastTaskInProgress();
    this.setState({ paused: false, workIntervalStart: new Date() })
  }

  async openTask(taskIndexInBatch) {
    await this.saveWorkIntervalSoFar();
    this.setState({ workIntervalStart: new Date(), currentTask: taskIndexInBatch })

    if(this.props.onCurrentTaskChange) {
      this.props.onCurrentTaskChange(taskIndexInBatch);
    }
  }

  async next() {
    const current = this.state.currentTask;

    let nextTaskIndex = current + 1;
    if(this.state.hideComplete) {
      nextTaskIndex = _.findIndex(this.state.tasks, (t,i) => i > current && t.state !== 'complete')
    }

    if (nextTaskIndex < this.state.tasks.length && nextTaskIndex >= 0) {
      await this.openTask(nextTaskIndex)
    } else {
      await this.pause(false);
    }
  }

  async previous() {
    const current = this.state.currentTask;

    let prevTaskIndex = current - 1;
    if(this.state.hideComplete) {
      prevTaskIndex = _.findLastIndex(this.state.tasks, (t,i) => i < current && t.state !== 'complete')
    }

    if (prevTaskIndex >= 0) {
      await this.openTask(prevTaskIndex)
    }
  }


  async doneAndNext(output = undefined) {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    if (currentTask) {
      currentTask.state = 'complete'
      currentTask.finishedAt = new Date();

      if(output !== undefined) {
        currentTask.output = output;
      }

      // When paused, next does not save task changes. Ensure that happens
      if(this.state.paused) {
        await this.updateTask(this.state.currentTask);
      } else {
        await this.next();
      }
    }
  }

  async markForRevision() {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    if (currentTask) {
      currentTask.state = 'needsRevision'
      await this.updateTask(this.state.currentTask);
    }
  }

  async markRevisionDone() {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    if (currentTask) {
      if(currentTask.finishedAt)
        currentTask.state = 'complete'
      else
        currentTask.state = ''

      await this.updateTask(this.state.currentTask);
    }
  }

  async reopenTask() {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    if (currentTask) {
      if(currentTask.state !== 'needsRevision') {
        currentTask.state = ''
      }
      currentTask.finishedAt = null
      await this.updateTask(this.state.currentTask);
    }
  }

  async updateNotes(updatedNotes) {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    if (currentTask) {
      currentTask.notes = updatedNotes;
      await this.updateTask(this.state.currentTask);
      this.setState({ draftNote: null })
    }
  }

  getTaskStateControls() {
    let currentTask = (this.state.tasks || {})[this.state.currentTask];
    if (currentTask) {
      const {state, finishedAt, notes} = currentTask;


      let btnMarkAsDone = null;
      if(finishedAt) {
        btnMarkAsDone = <IconButton icon={'undo'} level={'primary'} onClick={() => this.reopenTask()}>
          Reopen task
        </IconButton>;
      } else {
        btnMarkAsDone = <IconButton icon={'done'} level={'success'} onClick={() => this.doneAndNext()}>
          Mark as done
        </IconButton>;
      }

      let btnMarkForRevision;
      if(state === 'needsRevision') {
        btnMarkForRevision = <IconButton icon={'assignment_turned_in'} level={'primary'}
                                         onClick={() => this.markRevisionDone()}>

          Close revision
        </IconButton>;
      } else {
        btnMarkForRevision = <IconButton icon={'live_help'} level={'warning'}
                                         onClick={() => this.markForRevision()}>

          Mark for revision
        </IconButton>;
      }

      return <React.Fragment>
        <div className={'small text-center text-secondary mt-2'}>Task {currentTask._id}</div>

        <div className={'text-center d-flex align-items-center justify-content-around mt-2'}>
          {btnMarkForRevision} {btnMarkAsDone}
        </div>

        <div className={'border border-left-0 border-right-0 border-bottom-0 mb-4'}>
          <NotesEditor value={notes} onChange={updatedNotes => this.updateNotes(updatedNotes)}/>
        </div>
      </React.Fragment>
    } else {
      return null;
    }
  }

  getTaskStateColor(task, taskIndex, currentTask) {
    let stateColor = 'secondary';
    if (taskIndex === currentTask) {
      if (this.state.paused) {
        stateColor = 'light-primary';
      } else {
        stateColor = 'primary';
      }
    } else if (task.finishedAt) {
      stateColor = 'light-success';
    } else if (task.workedTotalTime > 0) {
      stateColor = 'light-warn';
    }
    return stateColor;
  }

  getTaskIcon(task, taskIndex, currentTask) {

    if (task.state === 'needsRevision') {
      return <Icon level={'warning'} icon={'help'}/>;
    } else if (task.finishedAt) {
      return <Icon level={'success'} icon={'done'}/>;
    } else if (taskIndex === currentTask) {
      if (this.state.paused) {
        return <Icon level={'primary'} icon={'pause'}/>;
      } else {
        return <Icon level={'white'} className={'animate-rotating-slow position-relative'} icon={'refresh'}/>;
      }
    } else if (task.startedAt) {
      return <Icon level={'warning'} icon={'pause'}/>;
    } else {
      return <Icon level={'dark'} icon={'more_horiz'}/>
    }
  }

  render() {
    const { getTaskComponent } = this.props;
    const { loading, workIntervalStart, tasks, currentTask, paused, hideComplete } = this.state;

    if (loading) {
      return <div ref={this.editorRef} className={'p-3'}>Loading tasks...</div>
    } else if(!tasks?.length) {
      return <div className={'p-2 text-center'}>Batch appears to have no tasks</div>
    } else {
      const task = tasks[currentTask];

      const { batchId, typeId, workedTotalTime } = task;

      const taskComponent = getTaskComponent(task, tasks, this);

      let batchAverage = null;
      let totalBatchTime = 0;
      const numberOfStartedTasks = _.filter(tasks, t => t.workedTotalTime > 0).length;
      if (numberOfStartedTasks > 0) {
        totalBatchTime = _.sumBy(tasks, t => t.workedTotalTime || 0);
        batchAverage = totalBatchTime / numberOfStartedTasks;
      }

      let activeTaskPairs = _.map(tasks, (t,i) => [t,i]);
      if(hideComplete)
        activeTaskPairs = _.filter(activeTaskPairs, ([t,i]) => t.state !== 'complete' || currentTask === i);

      const shownTasks = 10;
      let sliceFrom = Math.max(0, currentTask - shownTasks / 2);
      let sliceTo = sliceFrom + shownTasks + 1;
      // Reaching the end, ensure all tasks are shown
      if (sliceTo >= activeTaskPairs.length) {
        sliceFrom = Math.max(0, activeTaskPairs.length - shownTasks);
      }
      const tasksSlice = activeTaskPairs.slice(sliceFrom, sliceTo);

      return <div ref={this.editorRef} className={'BatchTaskEditor'}>
        <div className={'bg-dark p-1 px-2 text-white d-flex justify-content-between align-items-center zoom-75'}>

          <div>
            <div className={'align-middle inline-block ml-1 mr-3 h6'}>
              <div><Link to={`/tasks/batches?${this.context.page.getUrlParamComponent('filters')}`}><Icon icon={'arrow_back'}/> {typeId}</Link></div>
              <div><span className={'text-light ml-4'}>{batchId}</span></div>
            </div>

          </div>

          <div className={'m-0 no-select'}>
            <div className={'pauseStartButton inline-block'}>

              {
                paused ?
                  <IconButton icon={'play_circle_filled'} size={'xl'} level={'success'} onClick={() => this.start()}>
                    <span className={'h4 align-middle'}>Resume task</span>
                  </IconButton>
                  :
                  <IconButton icon={'pause_circle_filled'} size={'xl'} onClick={() => this.pause()}>
                    <span className={'h4 align-middle'}>Pause task</span>
                  </IconButton>
              }
            </div>

            <IconButton icon={'arrow_back_ios'} size={'lg'} onClick={this.previous.bind(this)}/>
            <span className={'align-middle h4'}>
              {currentTask + 1}
              <span className={'small text-secondary m-2'}>of</span>
              {tasks.length}
            </span>
            <IconButton icon={'arrow_forward_ios'}  size={'lg'} onClick={this.next.bind(this)}/>
          </div>

          <div>
            <span>
              <StatBadge stat={prettyDuration(totalBatchTime)} bg={`light-primary`}>
                <Icon icon={'functions'} level={'primary'}/>
                Total
              </StatBadge>

              <StatBadge stat={prettyDuration(batchAverage)} bg={`light-info`} className={'mr-4'}>
                <Icon icon={'timer'} level={'info'}/>
                Avg
              </StatBadge>
            </span>

            <span>
            {
              _.map(tasksSlice, ([t,i]) => {
                const taskNumber = i;
                const taskIcon = this.getTaskIcon(t, taskNumber, currentTask)
                const taskColor = this.getTaskStateColor(t, taskNumber, currentTask);

                let time = t.workedTotalTime ? prettyDuration(t.workedTotalTime) :
                  <span className={'translucent'}>-</span>;
                if (taskNumber === currentTask && !paused) {
                  time = <TimeFrom from={this.state.workIntervalStart} extra={workedTotalTime}/>;
                }

                return <StatBadge stat={time} bg={taskColor} key={taskNumber} onClick={() => this.openTask(taskNumber)}>
                  <div>{taskIcon}</div>
                  {taskNumber + 1}
                </StatBadge>
              })
            }
              {
                sliceTo < activeTaskPairs.length ?
                  <StatBadge stat={"..."} bg={'light-secondary'} key={'more'}>
                    <div>{activeTaskPairs.length - sliceTo} more</div>
                    <span>&nbsp;</span>
                  </StatBadge>
                  :
                  <StatBadge stat={'Finish'} bg={'success'} key={'finish'}>
                    <div>Line</div>
                    <span>&nbsp;</span>
                  </StatBadge>
              }

            </span>

            <span className={'ml-2 pb-3 inline-block align-middle'}>
              <OnOffIcon value={hideComplete} onChange={(v) => this.setState({hideComplete: v})} icon={'remove_done'} title={'Hide complete tasks'}/>
            </span>

            <span className={'inline-block text-secondary text-center align-middle ml-1'}>

            </span>
          </div>
        </div>

        <div className={`BatchTaskEditor__workArea bg-dark task-${paused ? 'paused' : 'started'}`}>
          {taskComponent}
        </div>
      </div>
    }
  }
}

BatchTaskEditor.contextType = LegoAdminPageContext;
