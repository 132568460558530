import _ from 'lodash'
import {useState, useEffect} from 'react';

const cache = {};
const globalLegoCacheFetching = {};


const getDataFromCache = async (dataId, asyncFetcher, setData) => {
  if (!cache[dataId]) {
    // All this logic is to prevent multiple simultaneous instances of the same component requesting the async data
    // So the first one asking fot something that is not in the cache will make the async request and then will call
    // all the callbacks for other components waiting for the very same data
    if(!globalLegoCacheFetching[dataId]) {
      globalLegoCacheFetching[dataId] = []

      const data = await asyncFetcher();

      if (data) {
        cache[dataId] = data;

        setData(cache[dataId]);

        console.log(`useAsyncData: Dispatching ${globalLegoCacheFetching[dataId].length} waiting callbacks`)
        for(const cbk of globalLegoCacheFetching[dataId]) {
          cbk(cache[dataId]);
        }
        delete globalLegoCacheFetching[dataId];
      }
    } else {
      // Push setData callback so that when the
      globalLegoCacheFetching[dataId].push(setData);
    }
  } else {
    setData(cache[dataId]);
  }
};

const tryGetDataFromCache = (dataId) => {
  if (cache[dataId]) {
    return cache[dataId];
  }
  return null;
}

export default function useAsyncData(dataId, asyncFetcher, defaultData) {
  // First try to get data from cache in a synchronous call
  // This is to prevent flashes in the UI just because of an async call in useEffect
  const cacheData = tryGetDataFromCache(dataId, asyncFetcher);
  const [data, setData] = useState(cacheData || defaultData);

  useEffect(() => {
    if (!cacheData) {
      getDataFromCache(dataId, asyncFetcher, setData).catch(console.error);
    }
  });

  return data;
}
