import React, {Component} from 'react';

import {ImgIcon} from "./ImgIcon";

export function OnOffIcon({ icon, value, color, triState, onChange, title, ...other }) {
  let toggleValue;
  if(triState) {
    toggleValue = () => {
      if(value === true) {
        onChange(false);
      } else if(value === false) {
        onChange(undefined);
      } else {
        onChange(true);
      }
    }
  } else {
    toggleValue = () =>  onChange(!value);
  }

  let style ={};
  if(color && value) {
    style.color = color;
  }

  let stateClass = value ? 'on' : (triState && value === undefined ?  'tri' : 'off')

  return <span className={'OnOffIcon OnOffIcon--' + stateClass}
               title={title || ''}
               onClick={toggleValue}>
    <ImgIcon material icon={icon} style={style} {...other}/>
  </span>
}
