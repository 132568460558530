import React from 'react';

import _ from "lodash";
import Select, { createFilter } from 'react-select';
import { TypeTag } from './TypeTag';

export default class TypeSelector extends React.Component {
  handleSelectChange(value, actionMeta) {
    this.props.onChange(value.value);
  }

  getOption(props) {
    let {innerProps, isSelected, isFocused} = props;
    let {innerRef, ... filteredProps} = innerProps;
    return <div {... filteredProps} className={'p-1 '+((isSelected || isFocused) ? 'bg-light-primary': '')}>
      <TypeTag type={props.value}/>
    </div>
  };

  getMultiValueLabel(props) {
    return <TypeTag type={props.children}/>
    ;
  };

  render() {
    const options = TypeTag.types().map(t =>{ return {label: t, value: t}});

    const {onChange, value, ... props} = this.props;

    let v = this.props.value;
    return (
      <Select
        closeOnSelect={true}
        // components={{ Option: this.getOption.bind(this), MultiValueLabel: this.getMultiValueLabel.bind(this) }}
        onChange={this.handleSelectChange.bind(this)}
        options={options}
        placeholder="Pick an action..."
        menuShouldScrollIntoView={false}
        simpleValue
        value={{label: v, value: v}}
        {... props}
      />
    );
  }
}
