import React, { Component } from 'react';

export class DataTypeTag extends Component {
  static types() {
    return ['LegoRef', 'ManualRef', 'Photos']
  }

  render() {
    let { type } = this.props;

    let colors = {
      LegoRef: 'success',
      LegoFuseboxRef: 'success',
      ManualRef: 'primary',
      Photos: 'secondary',
      fuseboxImg: 'gris-blue'
    }

    return <span className={`badge badge-${colors[type] || 'primary'}`}>{type}</span>;
  }
}
