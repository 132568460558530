import _ from "lodash";

import React, {useState} from 'react';
import SingleTextEditor from "../common/editors/SingleTextEditor";
import FacetParentsSelector from "./FacetParentsSelector";
import FacetProxy from "./FacetsProxy";
import {getIdColorClass} from "./facetStyleUtils";
import FacetIdsSelector from "./FacetIdsSelector";


const EditableFacetCard = ({ initialValue, selectedParent, service, config, onSave, onCancel }) => {
  let [{ id, parents }, setFacet] = useState(initialValue || {});

  const color = getIdColorClass(id, config);

  return <div className={''} style={{minWidth: '400px'}}>
    <div className={`bg-light-${color} rounded p-3 `}>
      <div className={'mb-2'}>
        <SingleTextEditor
          small
          placeholder={`New facet id (e.g. 'Make:Ford')...`}
          value={id}
          tabIndex={1}
          autoFocus={!selectedParent}
          onChange={(newId) => setFacet({ id: newId, parents })}/>
      </div>

      <span className={''}>
        <FacetIdsSelector
          autoFocus={selectedParent}
          selectedId={selectedParent}
          tabIndex={2}
          value={parents}
          config={config}
          service={service}
          onChange={(vs) => setFacet({id, parents: vs})}
        />
      </span>
    </div>

    <div className={'mt-2'}>
      <button tabIndex={3} className={'btn btn-primary mr-2'+(!id ? ' disabled' : '')} onClick={() => id && onSave({ id, parents })}>
        Save
      </button>

      <button tabIndex={4}  className={'btn btn-secondary'} onClick={() => onCancel()}>Cancel</button>
    </div>
  </div>
}

export default EditableFacetCard;
