import React from 'react';
import _ from 'lodash';

import PDFHtmlViewer from "../../components/pdf-tools/PDFHtmlViewer";
import CachedPDFManualLoader from "../../components/pdf-tools/CachedPDFManualLoader"
import LiveSubpage from "../../pages/LiveSubpage";
import {IconButton} from "../common/IconButton";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


export default class TaskEditorPdfExtraction extends LiveSubpage {
  constructor(props) {
    super(props);

    this.pdfService = this.service('services/data/manuals');

    this.state = {
      ...this.state,
      pagesDescription: null,
      fileIndex: _.indexOf(this.props.tasks, this.props.task),
      pdfInitialSearch: '',
      files: [],
    };

    this.shortcuts.registerKeyMappings({
      'prevPdf': ['alt+left'],
      'nextPdf': ['alt+right'],
      'focusTaskInput': ['tab']
    });

    this.shortcuts.registerActionHandlers({
      'prevPdf': this.previousPdf.bind(this),
      'nextPdf': this.nextPdf.bind(this),
      'focusTaskInput': this.focusTaskInput.bind(this)
    });
  }

  focusTaskInput() {
    //TODO: Hacked. Do it in a more generic way that works for any feature editor
    const $focused = $(':focus');

    // Focus on year input unless an input ot btn is already focused in the input area
    if ($focused.parents('#extractionInputArea').length === 0) {
      const $dateInput = $('#main-extraction-input');
      $dateInput.focus()
    } else {
      return true;
    }
  }

  async nextPdf() {
    this.props.batchEditor.next();
  }

  async previousPdf() {
    this.props.batchEditor.previous();
  }

  async openPdf(taskIndex) {
    this.props.batchEditor.openTask(taskIndex);
  }

  componentDidMount() {
    super.componentDidMount();
    this.mounted = true;
    this.listManualsFromTasks(this.props.tasks);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.tasks !== newProps.tasks && newProps.tasks) {
      console.log("Changed tasks", newProps.tasks)
      this.listManualsFromTasks(newProps.tasks).catch(alert);
    }

    if (this.props.task !== newProps.task && newProps.task) {
      const newTaskIndex = newProps.tasks.indexOf(newProps.task);
      if (newTaskIndex >= 0) {
        this.loadManual(newTaskIndex).catch(alert)
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
  }

  async listManualsFromTasks(tasks) {
    const ids = _.map(tasks, task => task.input.manual.id);

    let filter = {
      _id: { $in: _.map(ids, id => id.toString()) }
    };

    console.log("Fetching", filter)

    let files = await this.pdfService.find({ query: filter });

    // Ensure files are in the same order as tasks

    let filesById = _.keyBy(files, '_id');
    files = _.map(ids, id => filesById[id]);

    this.setState({ files }, async () => {
      if (this.state.files && this.state.files.length) {
        let fileIndex = this.state.fileIndex;
        await this.loadManual(fileIndex);
      } else {
        this.setState({ pagesDescription: null, file: null })
        alert(`No manual found matching ${JSON.stringify(filter)}`)
      }
    })
  }

  fileName(file) {
    let { modelId, year } = file.context[0] || file.context;
    if(file.context.length > 1) {
      modelId = _.uniq(_.map(file.context, 'modelId')).join('/')
    }
    return _.compact([modelId, year, file.isoLanguage, file.country]).join(' -' )
  }

  async loadManual(fileIndex) {
    const file = this.state.files[fileIndex];

    if(!file || !file.metadata) {
      this.setState({fileIndex, file: null, pagesDescription: null, autosuggestions: null})
      return;
    }

    const fileHash = file.metadata.hash;
    const s3Bucket = file.metadata.s3Bucket;

    const fileName = this.fileName(file);

    this.setState({ fileIndex });

    await sleep(5);

    await this.runAsync((async () => {
      try {
        let pagesDescription = await this.loadPdfSummary(fileHash, s3Bucket);
        this.setState({ pagesDescription, file, fileHash, fileName, autosuggestions: null, error: null }, () => this.computeSuggestions())
      } catch(error) {
        this.setState({error: error.toString(), pagesDescription: null})
      }

    })(), `Loading ${fileName}...`)

    await sleep(500);

    await this.precacheNextFiles(fileIndex);
  }

  async precacheNextFiles(fileIndex) {
    // precache next 2 files. Compact first because there could be missing files from broken manual references
    for (const { metadata } of _.compact(this.state.files.slice(fileIndex + 1, fileIndex + 2))) {
      try {
        await this.loadPdfSummary(metadata.hash, metadata.s3Bucket);
      } catch(err) {
        console.error(err.toString(), metadata);
      }
    }
    this.forceUpdate();
  }

  async loadPdfSummary(pdfId, s3Bucket) {
    return await CachedPDFManualLoader.loadManualTextLayers(pdfId, s3Bucket);
  }

  async computeSuggestions() {
    // This method can be overriden by Subclasses
    this.setState({ autosuggestions: null, autosuggestionsExplanation: null });
  }

  async updateManual(manualId, updateSetter) {
    let updatedFile = await this.pdfService.update(manualId, updateSetter);

    let indexOf = _.findIndex(this.state.files, { _id: manualId });
    if (indexOf >= 0) {
      this.state.files[indexOf] = updatedFile;
      console.log("Updated manual", updatedFile)
      this.setState({ files: this.state.files });
    }
    return updatedFile;
  }

  async doneAndNext() {
    this.props.batchEditor.doneAndNext().catch(alert);
  }

  renderExtractionControls() {
    // This method must be overriden by Subclasses
    return <div className={'alert alert-danger'}>Override renderExtractionControls() in subclasses</div>
  }

  componentDidUpdate() {
    let $selected = $(".ThumbsCarousel .selectedThumb");
    // if($selected[0]) {
    //   console.log("Updated!",$selected.text())
    //   $selected[0].scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //     inline: "center"
    //   })
    // }
  }

  markDone() {
    this.props.batchEditor.doneAndNext().catch(alert)
  }

  reopen() {
    this.props.batchEditor.reopenTask().catch(alert)
  }


  markForRevision() {
    this.props.batchEditor.markForRevision().catch(alert);
  }

  renderPageBody() {
    let { files, pagesDescription, file, fileName, fileHash, fileIndex, autosuggestions, autosuggestionsExplanation, error } = this.state;

    let shownManuals = 100;

    let from = Math.max(fileIndex - shownManuals / 2, 0);
    let to = Math.min(from + shownManuals, files.length);

    let filesButtons = _.map(files.slice(from, to), (file, i) => {
      if(!file || !file.metadata) {
        let task = this.props.tasks[from+i]
        let message;
        if(file) {
          message = `Manual ${this.fileName(file)} has no metadata`;
        } else {
          let manualId = task.input?.manual?.id || task.input?.uploaded_manual?.originalFile
          message = `Manual ${manualId} from task ${task._id} missing in DB`;
        }

        return <span key={i} className={`btn btn-sm btn-warning mr-1 p-1 position-relative`}><span className={'small'}>{message}</span></span>
      }
      const { metadata: { hash } } = file;
      const isSelected = fileIndex === (i + from);
      const isCached = CachedPDFManualLoader.isCached(hash);

      const pdfBgClass = isSelected ? 'primary selectedThumb' : (isCached ? 'secondary ' : 'secondary translucent');

      return <span key={hash + i} className={`btn btn-sm btn-${pdfBgClass} mr-1 p-1 position-relative`}
                   onClick={() => this.openPdf(i + from)}>
        <div>
          {/*<img src={coverImgSrc} className={''} style={{ height: '80px' }} alt={hash} title={hash}/>*/}
        </div>
        <div className={'text-white small'}>{this.fileName(file)}</div>
      </span>
    });

    if (files.length > shownManuals) {
      filesButtons.push(<span key={'more'} className={'text-white'}>
        And {files.length - shownManuals} more manuals...
      </span>)
    }

    return <React.Fragment>
      <div className={'grid-pdf-extraction'}>
        <div className={''}>
          <div className={'bg-secondary'}>
            <div className={'p-2 no-wrap overflow-auto ThumbsCarousel'}>
              {filesButtons}
            </div>
          </div>

          {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

          {pagesDescription ?
            <PDFHtmlViewer name={fileName} hash={fileHash} pages={pagesDescription.pages}
                           originalUrl={file.manualPdfUrl} initialSearch={this.state.pdfInitialSearch}
                           globalStyles={pagesDescription.globalStyles} shortcuts={this.shortcuts}
                           s3Bucket={file.metadata.s3Bucket}/>
            :
            <div className={'alert alert-info m-2'}>No pdf loaded</div>}

        </div>
        <div className={'bg-light'} id={'extractionInputArea'} key={fileHash}>
          {pagesDescription ? this.renderExtractionControls() : <div className={'m-3 text-center'}>No PDF structure loaded</div>}

          <div className={'border border-left-0 border-right-0'}>
            { this.props.batchEditor.getTaskStateControls()}
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}
