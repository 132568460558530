import React, {Component} from 'react';

export function LeftRightBox({ children, className, ... other }) {
  if (children.length !== 2) {
    console.error(`LeftRightBox requires exactly two children, got instead ${children.length}`)
  }

  let [left, right] = children;
  return <div className={`LeftRightBox ${className || ''}`} {... other}>
    <div className="LeftRightBox__left">{left}</div>
    <div className="LeftRightBox__right">{right}</div>
  </div>
}
