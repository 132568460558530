import _ from "lodash";
import React from 'react';
import StatsTable from "./StatsTable";
import * as PropTypes from "prop-types";

class YearsCoverageTable extends React.PureComponent {
  render() {
    const {modelId, manualsLink, manuals} = this.props;

    return <table className={"YearsCoverageTable"}>
      <tbody>
      <tr>
        <td style={{ width: "22.5%" }}>
          <div>{modelId} {manualsLink}</div>
        </td>
        {_.map(_.range(1990, 2026).reverse(), y => {
          const yearManuals = _.filter(manuals, { year: y });
          let hasManual = yearManuals.length > 0;

          return <td key={y} style={{ width: '2.5%' }} className={hasManual ? 'bg-success' : 'bg-secondary'}>
            <span className={'small'}>{hasManual ? yearManuals.length : '-'}</span>
          </td>;
        })}
      </tr>
      </tbody>
    </table>;
  }
}

export default class StatsManualsByYear extends StatsTable {
  async fetchData() {
    this.models = (await this.data.models()).models;
    this.modelsById = _.keyBy(this.models, 'modelId')

    this.pageviewsByModelId = (await this.data.traffic('ar')).trafficBreakdown.pageviewsByModelId

    this.manualsByModel = _.groupBy((await this.data.manuals()).manuals, m => m.idModelo);
  }

  groups() {
    return _.map(this.models, model => {
      return {
        model,
        manuals: this.manualsByModel[model.modelId] || []
      }
    })
  }

  groupKey({model}) {
    return model.modelId;
  }

  groupWeight(key, { model, manuals }) {
    return this.pageviewsByModelId[model.modelId];
  }

  groupCoverage(key, { model, manuals }) {
    return manuals.length ? 1 : 0
  }

  displayGroup(modelId, { model, manuals }) {
    const make = model.marca.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(' ', '-');
    const slug = (this.modelsById[modelId] || {}).slug;

    let manualsLink = manuals.length ? <span className={'small ml-1'}>
          <a target={'_blank'}
             href={`https://opinautos.com/${make}/${slug}/info/manuales`}>({manuals.length})</a>
        </span> : null;

    let yearsTable = <YearsCoverageTable modelId={modelId} manualsLink={manualsLink} manuals={manuals}/>

    return yearsTable;
  }
}
