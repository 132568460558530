import React, { Component } from 'react';
import ManualSourceSelector from '../lego/ManualSourceSelector';
import { MultipleImagesEditor } from '../common/editors/MultipleImagesEditor';
import PdfManualSourceSummary from '../lego/PdfManualSourceSummary';
import { MultipleImagesPreview } from '../common/data-previews/MultipleImagesPreview';

export class GenericDataTypeSummary extends Component {
  render() {
    let { type, value } = this.props;

    switch (type) {
      case 'LegoRef':
      case 'LegoFuseboxRef':
        return <span>Lego {value._id}</span>
      case 'ManualRef':
        return <PdfManualSourceSummary value={value}/>
      case 'Photos':
        return <MultipleImagesPreview value={value}/>
      default:
        return <span className={'alert alert-danger'}>Unsupported type {type}</span>
    }
  }
}
