import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import { ImagePreview } from './ImagePreview';
import CountryFlag from '../common/CountryFlag';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { IconButton } from '../common/IconButton';
import MultiCountryEditor from '../common/editors/MultiCountryEditor';
import TextSearchInput from '../common/TextSearchInput';


function vehicleToString(vehicle) {
  return _.values(_.omit(vehicle, 'trimId')).map(v => _.isObject(v) ? _.values(v).join(' ') : v).join(' ');
}

export default function ImageClusterGallery({ clusterId, filterByYear, preloadedCluster }) {
  const { page } = useContext(LegoAdminPageContext);
  const clusterSvc = page.service('services/data/image-clusters');
  const [cluster, setCluster] = useState(preloadedCluster || null);
  const [ignoreFilter, setIgnoreFilter] = useState(false);
  const [countriesFilter, setCountriesFilter] = useState(null);
  const [textFilter, setTextFilter] = useState('');

  useEffect(() => {
    !preloadedCluster && page.runAsync(clusterSvc.get(clusterId).then(cluster => setCluster(cluster || false)));
  }, [clusterId]);

  if (cluster) {
    let { images } = cluster;
    if (_.isNumber(filterByYear) && !ignoreFilter) {
      images = _.filter(images, i => i.vehicle?.year === filterByYear);
    }
    // const filteredCount = images.length;

    if(countriesFilter?.length) {
      images = _.filter(images, i => _.includes(countriesFilter, i.vehicle?.countryCode));
    }

    if(textFilter) {
      images = _.filter(images, i => _.includes(JSON.stringify(i.vehicle).toLowerCase(), textFilter.toLowerCase()));
    }

    images = _.orderBy(images, 'vehicle.year');

    return <div className={``}>
      <div className={'bg-light-info d-flex align-items-center px-3 mb-1 p-1'}>
        <span>{images.length} images {images.length !== cluster.images.length ? `(filtered of ${cluster.images.length})` : null}</span>
        {filterByYear ?
          <IconButton icon={ignoreFilter ? 'filter_alt' : 'clear'} level={ignoreFilter ? 'primary' : 'danger'}
                      onClick={() => setIgnoreFilter(!ignoreFilter)}>
            {ignoreFilter ? `Click to filter by year ${filterByYear}` : `Filtering by ${filterByYear}, click to see all ${cluster.images.length} images`}
          </IconButton> : null}

        <span style={{ minWidth: '300px' }} className={'ml-4'}>
          <MultiCountryEditor value={countriesFilter} onChange={setCountriesFilter} placeholder={'Select countries'}/>
        </span>

        <span style={{ minWidth: '300px' }} className={'ml-4'}>
          <TextSearchInput value={textFilter} onChange={setTextFilter} placeholder={'Filter trims...'}/>
        </span>
      </div>

      <div className={`border bg-light rounded p-1 align-middle`}>
        <span style={{ minHeight: '72px' }} className={`align-items-top`}>
          {_.map(images, ({ id, url, vehicle },j) => {
              const vehicleString = vehicleToString(vehicle);
              const newYear = vehicle.year !== images[j-1]?.vehicle?.year;

              return <>
              <span  style={newYear ? {borderLeft: 'solid 3px', paddingLeft: '4px'} : {}} className={`inline-block`} key={id+'-'+j} title={vehicleString}>
                {newYear ? <div key={'border-left border-primary pl-2 year'+j}>
                  <h3 onClick={() => setFilterByYear(vehicle.year)} className={'text-primary'}>{vehicle.year}</h3>
                </div> : null}

                <ImagePreview img200 cover key={id} url={url || ''} className={'mr-1 bg-light'} zoom context={vehicle}/>

                <div className={'small zoom-90'}>
                  <CountryFlag countryCode={(vehicle.countryCode || '').toLowerCase()}/>
                  <span className={'ml-1'}>{vehicle.year}</span>
                </div>
              </span></>;
            })}
        </span>
      </div>
    </div>;
  } else if (cluster === false) {
    return <span className={'align-items-top text-danger'}>_id "<code>{clusterId}</code>" NOT FOUND</span>;
  } else {
    return <div className={`border bg-light rounded p-5`}>
      <div style={{ minHeight: '72px' }} className={'inline-block text-center text-secondary'}>
        Loading cluster<br/>
        <span className={'small'}>{clusterId}</span>...
      </div>
    </div>;
  }
}
