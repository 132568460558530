import React, { Component } from 'react';
import _ from 'lodash';

import LegosManager from '../LegosManager';
import ModelContextEditor from '../../components/lego/ModelContextEditor';
import { LegoFiltersBar } from '../../components/common/LegoFiltersBar';


export default class LegoArticlesManager extends LegosManager {
  constructor(... args) {
    super(... args);
    this.state.facetFilters = (this.state.facetFilters || {})
    this.state.facetFilters.type = {$in: ['article', 'subarticle']}
  }

  getColumnsDefinition(objects) {
    let [type, ... other] = super.getColumnsDefinition(objects);
    return other;
  }

  getFacetFiltersComponent() {
    const fields = ['intentions', 'locales', 'context', 'state'];
    return <LegoFiltersBar fields={fields} onChange={this.onFiltersChange.bind(this)} filter={this.state.facetFilters}/>
  }

  onAddNewClick(overrides) {
    // Override defaults for new Button
    super.onAddNewClick({
      type: 'article',
      intentions: ["guide"],
      semantics: ["WHAT"],
      state: 'inprogress'
    })
  }

  getObjectColumns(l, definition) {
    // Ignore type columns, as all articles have the same type
    let [type, ... other] = super.getObjectColumns(l, definition);
    return other;
  }
}
