import React from "react";
import _ from "lodash";

import ManualFeaturesLegoEditor from "./ManualFeaturesLegoEditor";
import { IconButton } from '../../common/IconButton';
import LegoContextSummary from '../../lego/LegoContextSummary';
import { Icon } from '../../common/Icon';
import { SwitchInput } from '../../common/SwitchInput';
import { WarningLightsPdfExtractionEditor } from '../../lego/extraction-data-editors/WarningLightsPdfExtractionEditor';


const warningLightsSelector = {
  type: 'data',
  intentions: 'warning-lights-incomplete',
  'source.type': 'owner-manual',
}

const defaultNewEmptyLego = {
  data: {
    'warning-lights': []
  },
  type: 'data',
  intentions: ['warning-lights-incomplete'],
  state: 'inprogress',
  semantics: ['LUZ_TESTIGO'],
}

export default class ManualFeaturesWarningLightsEditor extends ManualFeaturesLegoEditor {
  constructor(props) {
    super(props, 'Warning Lights', 'features.hasWarningLights', warningLightsSelector, defaultNewEmptyLego);
    this.state.commonContext = false;
    this.state.featureName = 'Manual has warning Lights';
    this.useIndividualContext = true;
  }

  getEditionControls(manualObj) {
    let hasData = _.get(manualObj, this.booleanFeaturePath);

    const { originalLegos, changedLegos } = this.state;

    if (changedLegos === undefined) {
      return <div>Loading existing legos...</div>;
    }

    let manualContexts = this.manualContextNoTrims();

    let commonContext = null;
    if(this.state.commonContext) {
      commonContext = <div className={'mb-2'}>
        <div className={'small mb-1'}>
          <IconButton size={'sm'} icon={'open_in_full'} onClick={() => this.expandCommonContext()}>
            Set model and year lego by lego
          </IconButton>
        </div>

        {_.map(manualContexts, (ctx, i) => <div key={i} className={'mb-1 text-info'}>
          <LegoContextSummary context={ctx}/>
        </div>)}

      </div>
    } else {
      commonContext = <div className={'mb-2 small'}>Each lego defines its modelId/year</div>
    }

    const pdfUrl = this.props.manual.manualPdfUrl;
    const manualId = this.props.manual._id;
    const legosEditor = <WarningLightsPdfExtractionEditor manualPdfUrl={pdfUrl} manualId={manualId}
                                                          value={changedLegos[0]}
                                                          onChange={(json) => this.updateLegos([json])}
                                                          shortcuts={this.props.shortcuts}/>;

    const markedForDeletion = _.filter(originalLegos, ({ _id }) => !_.find(changedLegos, { _id }));

    const toDeleteList = _.map(markedForDeletion, ({ _id }) => <div key={_id} className={'text-danger'}>
      <Icon icon={'delete_forever'} level={'danger'}/>
      <span className={'small'}>Will delete Lego <span className={'badge badge-danger'}>{_id}</span></span>
      <IconButton icon={'undo'} level={'primary'} onClick={() => this.restoreLego(_id)}/>
    </div>);

    return <React.Fragment>
      <div>
        {commonContext}
        {toDeleteList}
        {legosEditor}
      </div>
      <div className={'text-center'}>
        <SwitchInput value={hasData} className={'inline-block'} onChange={(json) => this.toggleHasLegoData()}>
          {this.state.featureName || 'Has data'}
        </SwitchInput>
      </div>
    </React.Fragment>;
  }
}
