import React from 'react';

import _ from "lodash";
import Select from 'react-select';
import { DataTypeTag } from './DataTypeTag';

export default class DataFieldSelector extends React.Component {
  handleSelectChange(values, actionMeta) {
    this.props.onChange(_.map(values, 'value'));
  }

  getOption(props) {
    let {innerProps, isSelected, isFocused} = props;
    let {innerRef, ... filteredProps} = innerProps;
    return <div {... filteredProps} className={'p-1 '+((isSelected || isFocused) ? 'bg-light-primary': '')}>
      <DataTypeTag type={props.value}/>
    </div>
  };

  getMultiValueLabel(props) {
    return <DataTypeTag type={props.children}/>
  };

  render() {
    const {onChange, value, fields, ... props} = this.props;

    const options = _.map(fields, ({type,name}) =>{ return {label: name, value: name}});

    let values = _.map(this.props.value || [], v => {
      let match = _.find(options, op => op.value == v);
      if(!match) {
        match = { label: v, value: v };
        options.push(match)
      }
      return match
    });

    return (
      <Select
        closeOnSelect={true}
        isMulti
        components={{ Option: this.getOption.bind(this), MultiValueLabel: this.getMultiValueLabel.bind(this) }}
        onChange={this.handleSelectChange.bind(this)}
        options={options}
        menuShouldScrollIntoView={false}
        placeholder="None"
        value={values}
        {... props}
      />
    );
  }
}
