import React from "react";
import _ from "lodash";

import {OilTypeLegoEditor} from "../../lego/extraction-data-editors/OilTypeLegoEditor";
import ManualFeaturesLegoEditor from "./ManualFeaturesLegoEditor";
import OilQuantityLegoEditor from "../../lego/extraction-data-editors/OilQuantityLegoEditor";


const oilLegoSelector = {
  type: 'data',
  intentions: 'spec',
  semantics: 'CAPACIDAD_DE_ACEITE_MOTOR',
  'source.type': 'owner-manual',
}

const defaultNewEmptyLego = {
  data: {},
  type: 'data',
  intentions: ['spec'],
  semantics: ['CAPACIDAD_DE_ACEITE_MOTOR'],
}

export default class ManualFeaturesOilEditor extends ManualFeaturesLegoEditor {
  constructor(props) {
    super(props, 'Extract Oil Capacity', 'features.hasOilQuantityData', oilLegoSelector, defaultNewEmptyLego);
    this.showRecentLegosBtn = true;
  }

  getSingleLegoDataEditor(lego, props) {
    return <OilQuantityLegoEditor value={lego} {...props}/>;
  }
}
