// Taken from http://www.evanmiller.org/ab-testing/sample-size.html

const alpha_level = 0.05;
const power_level = 0.80;

const min_effect = 0.05;
const mde_is_relative = 0;
const conversion_rate = 0.20;

function num_subjects(alpha, beta, p, delta) {
  const t_alpha2 = ppnd(1.0 - alpha / 2);
  const t_beta = ppnd(beta);

  const sd1 = Math.sqrt(2 * p * (1.0 - p));
  const sd2 = Math.sqrt(p * (1.0 - p) + (p + delta) * (1.0 - p - delta));

  return (t_alpha2 * sd1 + t_beta * sd2) * (t_alpha2 * sd1 + t_beta * sd2) / (delta * delta);
}

function ppnd(p) {
  const a0 = 2.50662823884;
  const a1 = -18.61500062529;
  const a2 = 41.39119773534;
  const a3 = -25.44106049637;
  const b1 = -8.47351093090;
  const b2 = 23.08336743743;
  const b3 = -21.06224101826;
  const b4 = 3.13082909833;
  const c0 = -2.78718931138;
  const c1 = -2.29796479134;
  const c2 = 4.85014127135;
  const c3 = 2.32121276858;
  const d1 = 3.54388924762;
  const d2 = 1.63706781897;
  let r;
  const split = 0.42;
  let value;

  /*
   0.08 < P < 0.92
   */
  if (Math.abs(p - 0.5) <= split) {
    r = ( p - 0.5 ) * ( p - 0.5 );

    value = ( p - 0.5 ) * ( ( (
      a3 * r
      + a2 ) * r
      + a1 ) * r
      + a0 ) / ( ( ( (
      b4 * r
      + b3 ) * r
      + b2 ) * r
      + b1 ) * r
      + 1.0 );
  }
  /*
   P < 0.08 or P > 0.92,
   R = min ( P, 1-P )
   */
  else if (0.0 < p && p < 1.0) {
    if (0.5 < p) {
      r = Math.sqrt(-Math.log(1.0 - p));
    } else {
      r = Math.sqrt(-Math.log(p));
    }

    value = ( ( (
      c3 * r
      + c2 ) * r
      + c1 ) * r
      + c0 ) / ( (
      d2 * r
      + d1 ) * r
      + 1.0 );

    if (p < 0.5) {
      value = -value;
    }
  }
  /*
   P <= 0.0 or 1.0 <= P
   */
  else {
    value = NaN;
  }

  return value;
}

export function computeSignificance(ctrA, ctrB, samplesA, samplesB){
  const minSamples = num_subjects(0.05, 0.8, ctrA, Math.abs(ctrA - ctrB));

  const sample = Math.min(samplesA, samplesB);

  const diff = sample / minSamples;
  let category = "none";

  const res = {
    minSamples: Math.ceil(minSamples),
    significantSampleRatio: diff
  };

  if(ctrA === 0 || ctrB === 0){
    category = "none";
  } else if(diff > 2){
    category = "high"
  } else if (diff > 1.3) {
    category = "med"
  } else if (diff > 1) {
    category = "low"
  } else if (diff > 0.8) {
    category = "almost"
  }
  res.category = category;
  return res;
};
