import React, { useEffect, useState } from 'react';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';

export default function useEditableLegoData(query, defaultData) {
  const [data, setData] = useState(defaultData);
  const [legoId, setId] = useState(null);

  let mounted = true;

  useAsyncEffect(async () => {
    console.log('Querying ' + JSON.stringify(query));
    try {
      const [lego] = await client.service('/services/legos').find({ query });
      if(lego && mounted) {
        setData(lego.data);
        setId(lego._id)
      }
    } catch (err) {
      console.error(err);
    }
  }, () => mounted = false, []);

  return [data, setData, legoId];
}
