import _ from "lodash";
import React from 'react';
import FacetProxy from "./FacetsProxy";
import {getIdColorClass} from "./facetStyleUtils";
import {useDrag} from 'react-dnd';

const typePropDefinitionRegex = /(^.*)\.([^.:]+)$/;
const idOfPropDefinitionRegex = /(^.*)\.([^.:]+):[^.:]+$/;

const FacetId = React.memo(({ id, parentOf, config, onMovedOut, ...otherProps }) => {
  let { name, type } = FacetProxy.parseId(id);

  let color = 'secondary';
  if (config) {
    color = getIdColorClass(id, config);
  }

  const classes = [];
  if (otherProps && otherProps.onClick) {
    classes.push('link-shadow');
  }

  // If the prop draggable is found, add logic to make the item draggable
  let ref = null;
  let extraStyle = {};
  if (otherProps.draggable) {
    const [{ opacity }, dragRef] = useDrag({
      item: { type: 'facetId', facetId: id, parentOf: parentOf },
      collect: (monitor) => ({ opacity: monitor.isDragging() ? 0.5 : 1 }),
      end(item, monitor) {
        // For limitations on the logic of react-dnd, this is the only please where
        // we can differentiate a "drag and drop" with or without ALT-KEY (copy)
        if (monitor.didDrop()) {
          const dropResult = monitor.getDropResult()
          // When ALT-KEY is used, dropEffect would be 'copy', so don´t do anything
          if (dropResult && dropResult.dropEffect === 'move' && onMovedOut) {
            onMovedOut(id);
          }
        }
      }
    })
    ref = dragRef;
    extraStyle.opacity = opacity;
  }

  let potentialProp = type.match(typePropDefinitionRegex);
  if (parentOf && potentialProp) {
    let [, path, prop] = potentialProp;

    if (parentOf.startsWith(path)) {
      type = prop;
      classes.push('badge', 'bg-light-white', `text-${color}`);
    } else {
      classes.push('badge', `badge-${color || 'secondary'}`);
    }
  } else {
    classes.push('badge', `badge-${color || 'secondary'}`);
  }

  return <span ref={ref}
               key={id}
               style={extraStyle}
               title={id}
               className={`mr-2 ${classes.join(' ')}`}
               {...otherProps}>
    <span className={'font-weight-normal'}>{type}{type ? ':' : ''} </span>
    {name}
  </span>
});

export {
  typePropDefinitionRegex,
  idOfPropDefinitionRegex
};

export default FacetId;
