import React, { useContext, useEffect, useState } from 'react';

import LiveSubpage from '../../pages/LiveSubpage';
import { ImagePreview } from '../images/ImagePreview';
import { IconButton } from '../common/IconButton';
import _ from 'lodash';
import EventEmitter from 'events';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import GridLayoutLeftBar from '../common/layout/GridLayoutLeftBar';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import LegoContextSummary from '../lego/LegoContextSummary';

const ClusterPreview = ({ cluster, selection, editClusterKeyword, onSelectionChange, onSearchChange, addToCluster, showImages = 5 }) => {
  const [showMore, setShowMore] = useState(false);
  const [allImages, setAllImages] = useState(null);
  const {_id, images, context, totalImages} = cluster;

  useEffect(() => setAllImages(null), [cluster])

  let renderImages = (allImages || images);
  if(!showMore) {
    renderImages = renderImages.slice(0, showImages);
  }

  const handleClick = (event, imgId) => {
    if (event.altKey) {
      //search from clicked image
      onSearchChange(_.find(images, r => r.id === imgId).url);
    } else {
      // event.stopPropagation();
      handleSelect(event, imgId);
    }
  };

  const handleSelect = (event, id) => {
    let newSelection = [...selection];

    if (event.shiftKey) {
      //Select range from last selected image
      const i = renderImages.findIndex(r => r.id === id);
      const lastI = renderImages.findIndex(r => r.id === selection.at(-1));
      if(lastI !== -1){
        const [start, end] = _.sortBy([lastI, i]);
        const newElements = _.map(renderImages.slice(start, end + 1), r => r.id);
        onSelectionChange(_.union(newSelection, newElements));
      }
    } else {
      if (selection.includes(id)) {
        _.pull(newSelection, id);
      } else {
        newSelection.push(id);
      }
      onSelectionChange(newSelection);
    }
  };

  const toggleExpandCluster = () => {
    if(!showMore) {
      const clusterSvc = window.client.service('services/data/image-clusters');
      clusterSvc.find({query: {_id}}).then(([c]) => setAllImages(c?.images))
    }
    setShowMore(!showMore);
  }

  let toggleShowMoreBtn = <IconButton icon={showMore ? 'navigate_before' : 'navigate_next'}
                                                                           title={'show more'}
                                                                           level={'secondary'}
                                                                           onClick={toggleExpandCluster}/>;

  return <div className={'block overflow-auto'}>
    {addToCluster ? <IconButton icon={'add'} onClick={() => addToCluster(_id)}/> : null}

    <span className={'position-absolute badge badge-primary'}
          onClick={() => editClusterKeyword && editClusterKeyword(_id, context?.keywords)}>
        {context?.keywords || 'empry keywords'} ({totalImages})
      </span>

    {_.map(renderImages, img => <span key={img.id}
                                      className={`inline-block p-1 ${selection?.includes(img.id) ? 'bg-med-primary' : ''}`}>
          <ImagePreview url={img.url} img100
                        onClick={(event) => onSelectionChange && handleClick(event, img.id)}/>
        </span>)}

    {totalImages > renderImages.length ? toggleShowMoreBtn : null}
  </div>;
};

const ClustersPanel = ({ clusters, selection, onSelectionChange, onSearchChange, addToCluster, editClusterKeyword, ...otherProps }) => {
  const [showMore, setShowMore] = useState(false);
  const [clusterKeyFilter, setClusterKeyFilter] = useState('');

  const handleClusterKeyChange = (newKey) => setClusterKeyFilter(newKey);

  const createClusterBtn = <IconButton icon={'add_circle'} onClick={() => addToCluster()}>
    Create cluster with {selection.length} images
  </IconButton>;

  const clearSelectionBtn = <IconButton title={'Clear selection'}
                                        icon={'indeterminate_check_box'}
                                        onClick={() => onSelectionChange([])}/>;

  let displayClusters = clusters;
  if (clusterKeyFilter) {
    let regex;
    try {
      regex = new RegExp(clusterKeyFilter, 'i');
    } catch(err) {
      regex = new RegExp(_.escapeRegExp(clusterKeyFilter), 'i');
    }
    displayClusters = _.filter(displayClusters, c => c.context?.keywords.match(regex));
  }

  displayClusters = showMore ? displayClusters : displayClusters.slice(0, 10);

  return <div {...otherProps}>
    {selection.length ? <span>{clearSelectionBtn}{createClusterBtn}</span> : null}<br/>
    <span className={'block'}>
      {clusterKeyFilter ? <IconButton icon={'close'} onClick={()=> handleClusterKeyChange()}/> : null}
      <SingleTextEditor placeholder={'Filter by keywords'} value={clusterKeyFilter} onChange={handleClusterKeyChange}/>
    </span>
    {_.map(displayClusters, (cluster) => <ClusterPreview key={cluster._id} cluster={cluster}
                                                                          selection={selection}
                                                                          onSelectionChange={onSelectionChange}
                                                                          onSearchChange={onSearchChange}
                                                                          editClusterKeyword={editClusterKeyword}
                                                                          addToCluster={addToCluster}/>)}
    {displayClusters.length >= 10 ?
      <IconButton icon={showMore ? 'expand_less' : 'expand_more'} title={'show more'} level={'secondary'}
                  onClick={() => setShowMore(!showMore)}>{showMore ? 'Hide' : 'Show remaining'} {clusters.length - 10} clusters</IconButton>
      :
      null}

  </div>;
};

function ImageClustersModal({ searchUrl }) {
  const [results, setResults] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [selection, setSelection] = useState([]);
  const [url, setUrl] = useState(searchUrl);

  const searchSvc = window.client.service('services/data/image-search');
  const clusterSvc = window.client.service('services/data/image-clusters');
  const { page } = useContext(LegoAdminPageContext);

  const search = async () => {
    try {
      const res = await searchSvc.find({ query: { category: 'warning-lights', url, $limit: 100 } });

      setResults(res);

      setSelection([]);
      getClusters(_.map(res, '_id'));
    } catch (err) {
      console.log(err);
    }
  };

  const getClusters = async (ids) => {
    try {
      let query = {
        clusterType: 'warning-lights',
        $onlySample: 1,
        $limit: 500
      };

      if(ids) {
        let similarClusters = await clusterSvc.find({ query: {... query,  ['images.id']: { $in: ids }}});
        let allClusters = await clusterSvc.find({ query: query })

        setClusters(_.uniqBy([... similarClusters, ... allClusters], '_id'));
      } else {
        setClusters(await clusterSvc.find({ query: query }));
      }

    } catch (err) {
      console.log(err);
    }
  };

  const relevanceSorter = (cluster) => {
    return _.min(_.map(cluster.images, img => _.keyBy(results, '_id')[img.id]?.score));
  };

  const sortClusters = () => {
    if (results.length) {
      setClusters(_.orderBy(clusters, relevanceSorter, 'asc'));
    }
  };

  useEffect(sortClusters, [JSON.stringify(results), JSON.stringify(clusters)]);

  const editClusterKeyword = async (clusterId, oldKey) => {
    //TODO: maybe is better to move this inside RenderCluster
    let newKey = prompt(`Keyword para el cluster:`, oldKey);
    if (newKey) {
      await clusterSvc.update(clusterId, {
        $set: { 'context.keywords': newKey, updatedBy: page.getLoggedUserSignature().id },
      });
      getClusters(_.map(results, '_id'));
    }
  };

  const handleClick = ({ event, clusterId, imgId }) => {
    if (event.altKey) {
      //search from clicked image
      setUrl(_.find(results, r => r._id === imgId).url);
    } else {
      event.stopPropagation();
      if (!clusterId) {
        handleSelect(event, imgId);
      }
    }
  };

  const handleSelect = (event, id) => {
    let newSelection = [...selection];

    if (event.shiftKey) {
      //Select range from last selected image
      const i = displayResults.findIndex(r => r._id === id);
      const lastI = displayResults.findIndex(r => r._id === selection.at(-1));
      const [start, end] = _.sortBy([lastI, i]);
      const newElements = _.map(_.filter(displayResults.slice(start, end + 1), img=> !img.cluster), r => r._id);
      setSelection(_.union(newSelection, newElements));
    } else {
      if (selection.includes(id)) {
        _.pull(newSelection, id);
      } else {
        newSelection.push(id);
      }
      setSelection(newSelection);
    }
  };

  const removeFromCurrentCluster = async () => {
    const currentClusters = await clusterSvc.find({ query: { clusterType: 'warning-lights', 'images.id': { $in: selection } } });
    for (const cluster of currentClusters) {
      const res = await clusterSvc.update(cluster._id, {
        $pull: { images: { id: selection } },
        $set: { updatedBy: page.getLoggedUserSignature().id },
      });
      if (!res.images.length) {
        await clusterSvc.remove(cluster._id);
      }
    }
  };

  const addToCluster = async (clusterId) => {
    if (!selection.length) {
      return;
    }

    if (clusterId) {
      //Add to existing cluster
      await removeFromCurrentCluster();
      await clusterSvc.update(clusterId, {
        $addToSet: { images: _.map(selection, s => ({ id: s })) },
        $set: { updatedBy: page.getLoggedUserSignature().id },
      });
    } else {
      //Create new cluster
      const keywords = prompt('Ingrese una keyword para esta luz');
      if (!keywords) {
        return;
      }
      await removeFromCurrentCluster();
      await clusterSvc.create({
        clusterType: 'warning-lights',
        images: _.map(_.uniq(selection), s => ({ id: s })),
        context: { keywords: keywords },
        updatedBy: page.getLoggedUserSignature().id
      });
    }

    setSelection([]);
    getClusters(_.map(results, '_id'));
  };

  useAsyncEffect(() => {
    search();
  }, [JSON.stringify(url)]);

  useEffect(() => updateDisplayResults(), [results, JSON.stringify(clusters)]);

  const updateDisplayResults = () => {
    let displayResults = _.cloneDeep(results);
    let clustersCopy = _.cloneDeep(clusters);

    for (const img of displayResults) {
      let currentCluster = _.find(clustersCopy, cluster => _.find(cluster.images, clusterImg => clusterImg.id === img._id));
      if (currentCluster && currentCluster.shown) {
        img.hide = true;
      } else if (currentCluster) {
        currentCluster.shown = true;
        img.cluster = currentCluster._id;
      }
    }

    setDisplayResults(_.filter(displayResults, res => !res.hide));
  };

  const imagesPanel = <div style={{ width: '50vw' }}>
    {_.map(displayResults, ({ _id, url, perceptualHash, score, cluster }) => {
      const isSelected = selection.includes(_id);
      const isLastSelected = selection.slice(-1)[0] === _id;

      let classes = ['inline-block border p-1'];
      cluster && classes.push('bg-med-success');
      isLastSelected && classes.push('bg-primary');
      isSelected && classes.push('bg-med-primary');
      const currentCluster = _.find(clusters, c => c._id === cluster)

      return <span key={_id} className={classes.join(' ')}>
        {cluster ? <span
          className={'position-absolute badge badge-info'}>{currentCluster?.context.keywords} ({currentCluster?.images.length})</span> : null}
        <ImagePreview url={url} img100 contain
                      onClick={(event) => handleClick({ event: event, clusterId: cluster, imgId: _id })}/>
        {/*<div className={'text-center small text-info'}>{score}</div>*/}
        </span>;
    })}
  </div>;

  return <GridLayoutLeftBar>
    {imagesPanel}
    <ClustersPanel style={{ width: '40vw' }}
                   clusters={clusters} selection={selection} onSelectionChange={sel => setSelection(sel)} addToCluster={addToCluster}
                   onSearchChange={url => setUrl(url)}
                   editClusterKeyword={editClusterKeyword}/>
  </GridLayoutLeftBar>;
}

export default class TaskEditorWarningLightsClustering extends LiveSubpage {
  constructor(props) {
    super(props);

    this.imageService = window.client.service('services/data/images');
    this.legoService = window.client.service('services/legos');
    this.clusterSvc = window.client.service('services/data/image-clusters');

    this.modalActionsBus = new EventEmitter();

    this.state = {
      ...this.state,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadLego().then(() => this.loadClusters());
  }

  async loadLego() {
    let legoData = await this.legoService.find({ query: { '_id': this.props.task.input.id } });
    this.setState({ lego: legoData[0] });
  }

  async loadClusters() {
    let imageIds = _.map(this.state.lego.data['warning-lights'], l => l.lightImg?.source);
    let clusters = await this.clusterSvc.find({
      query: {
        clusterType: 'warning-lights',
        "images.id": {$in : imageIds} ,
        $onlySample: 2
      }
    });

    this.setState({ clusters: clusters });
  }

  renderPageBody() {
    let { error, lego, clusters } = this.state;

    let { context, state, input: { id } } = this.props.task || {};

    const onApprove = async () => {
      await this.props.batchEditor.doneAndNext();
    };

    const isComplete = state === 'complete';

    const openClusteringModal = (url, lightName) => {
      this.modalActionsBus.emit('open', <ImageClustersModal
        searchUrl={url}/>, { onClose: () => this.loadClusters(), title: lightName, fullScreen: true });
    };

    const getImgCluster = (imgId) => {
      return _.find(clusters, c => _.map(c.images, 'id').includes(imgId));
    };

    let images = null;
    if (lego) {
      images = _.map(lego.data['warning-lights'], l => {
        if (!l.lightImg){
          return
        }
        let cluster = getImgCluster(l.lightImg.source);

        return <div key={l.lightImg.source} style={{ width: '33%', display: 'inline-block' }}>
          <div className={'block'}>
            <span title={l.lightName}
                  className={`zoom-75 border p-2 ${getImgCluster(l.lightImg.source) ? 'bg-med-success' : 'bg-danger'}`}>
              <ImagePreview url={l.lightImg.url} img100 contain
                            onClick={() => openClusteringModal(l.lightImg.url, l.lightName)}/>
            </span>
            {cluster ?
              <span className={'bg-dark zoom-90 overflow-auto'}><ClusterPreview
                cluster={cluster}
                showImages={3}/></span> : null}
          </div>
        </div>;
      });
    }

    return <React.Fragment>
      <div className={'grid-pdf-extraction'}>
        <div className={'pos-relative bg-secondary'}>
          {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

          <div className={'p-1'}>
            {images}
          </div>
        </div>

        <div className={'bg-white'} id={'extractionInputArea'} key={id}>
          <div className={'p-2 text-center border'}>
            <LegoContextSummary context={context}/>
          </div>
          <div className={'p-2 small text-center'}>
            <span className={`btn btn-${isComplete ? 'primary' : 'success'}`}
                  onClick={async () => this.runAsync(onApprove)}>
              {isComplete ? 'Resave and next' : 'Done and next'}
            </span>
          </div>

          <div className={'border border-left-0 border-right-0'}>
            {this.props.batchEditor.getTaskStateControls()}
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}
