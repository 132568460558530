import React from 'react';
import _ from 'lodash';

import Async from 'react-select/lib/Async';
import SemanticTag from '../../semantic/SemanticTag';

export default function InputMultipleSemantics({ onChange, onBlur, value, preloadedSemantics, serviceFind, ...otherProps }) {
  // const tags = useAsyncData('tags'+modelId, fetchTags.bind(this, modelId), []);
  preloadedSemantics = preloadedSemantics || [];

  const handleSelectChange = (objs, actionMeta) => onChange(_.map(objs, 'value'));

  const handleBlur = (objs, actionMeta) => onBlur && onBlur(_.map(objs, 'value'));

  const getOption = (props) => {
    let { innerProps, isSelected, isFocused, data: {label, value, notParsed} } = props;
    let { innerRef, ...filteredProps } = innerProps;

    let tag = null;
    if(notParsed) {
      tag = <span>
          <SemanticTag level={'secondary'} semKey={value}/> (use literally as semantic)
        </span>;
    } else {
      tag = <SemanticTag level={'primary'} semKey={value}/>;
    }

    return <div {...filteredProps} className={'p-05 text-left ' + ((isSelected || isFocused) ? 'bg-light-primary' : '')}>
      { tag }
    </div>
  };

  const getMultiValueLabel = ({ data: {value} }) => {
    return <span key={value} title={value}>
      <SemanticTag level={'primary'} semKey={value}/>
    </span>
  };

  const getOptionFromSemantic = sem => ({ label: sem, value: sem });

  let loadOptions = async (input) => {
    try {
      let semanticRepFind;
      if(serviceFind) {
        semanticRepFind = (query) => serviceFind({query: query.query.text});
      } else {
        semanticRepFind = (query) => window.client.service('/services/semantic/representation/fromId').find(query);
      }

      const keyReg = new RegExp(_.escapeRegExp(input), 'i');
      const preloadedOptions = _.filter(preloadedSemantics, tag => (tag || '').match(keyReg));

      // If one or more preloaded options match the input, just return them
      if (preloadedOptions.length || !input) {
        return _.map(preloadedOptions, getOptionFromSemantic);
      }

      let parsedSemantics = await semanticRepFind({ query: {text: input} });

      const parsedOptions = _.map(_.uniq([...parsedSemantics]), getOptionFromSemantic);
      if (!_.includes(parsedSemantics, input)) {
        parsedOptions.push({label: input, value: input, notParsed: true})
      }

      return parsedOptions;
    } catch (err) {
      console.error(err);
    }
  };

  const values = _.map(value, getOptionFromSemantic);

  const customStyles = {
    multiValue: (provided, state) => ({ display: 'flex', }),
    multiValueRemove: (provided, state) => ({ ...provided, paddingLeft: '0' })
  }

  return (
    <Async
      closeOnSelect={true}
      isMulti
      defaultOptions={true}
      components={{
        Option: getOption.bind(this),
        MultiValueLabel: getMultiValueLabel.bind(this),
      }}
      // getOptionValue={(option) => JSON.stringify(option)}
      onChange={handleSelectChange}
      onBlur={handleBlur}
      loadOptions={loadOptions}
      placeholder="No arranca, etc..."
      menuShouldScrollIntoView={false}
      maxMenuHeight={270}
      isClearable={true}
      styles={customStyles}
      // menuPositionunion={'fixed'}

      value={values}
      {...otherProps}
    />
  );
}
