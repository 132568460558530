import React, { Component } from 'react';
import { LocaleFlag } from './LocaleFlag';
import { Icon } from '../common/Icon';

export default function LegoLabel({ label, className, ...other }) {
  let [prefix, sufix] = (label || '').split(':');

  let body;
  let extraStyle = '';
  if (label === 'readonly') {
    body = <span className={'text-white'}>readonly</span>;
    extraStyle = 'LegoLabel--programatic';
  } else if (prefix === 'translatedFrom' && sufix) {
    body = <><Icon icon={'translate'} className={'small text-white mr-1'}/>
      <a href={`/legos/all?editing="${sufix}"`} target={'_blank'}>From...</a>
    </>;
    extraStyle = 'LegoLabel--programatic';
  } else if (prefix === 'translatedTo' && sufix) {
    let [locale, id] = sufix.split('_');
    body = <span className={'text-white'}><Icon icon={'translate'} className={'small text-white mr-1'}/>
                <a href={`/legos/all?editing="${id}"`} target={'_blank'}>
                    to <LocaleFlag small locale={locale}/>
                </a>
            </span>;

    extraStyle = 'LegoLabel--programatic';
  } else {
    body = <><span className={'text-white'}>{prefix}</span>{sufix ? ':' : ''}{sufix}</>;
  }

  if(prefix === 'batch') {
    extraStyle = 'LegoLabel--batch';
  }

  return <span onDoubleClick={() => prompt('', label)} className={`LegoLabel ${extraStyle} ${className || ''}`} title={label} {...other}>
       {body}
    </span>;
}
