import React, {Component} from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import LivePage from './LivePage';
import {DataCoverageTable} from '../components/stats/DataCoverageTable';
import StatsTrimSources from "../components/stats/StatsTrimSources";
import StatsDataSource from "../components/stats/StatsDataSource";
import StatsManuals from "../components/stats/StatsManuals";
import StatsFuseboxByManuals from "../components/stats/StatsFuseboxByManuals";
import StatsFuseboxByModel from "../components/stats/StatsFuseboxByModel";
import StatsTrimIssues from "../components/stats/StatsTrimIssues";
import StatsManualsByYear from "../components/stats/StatsManualsbyYear";

export default class StatsManager extends LivePage {
  constructor(props) {
    super(props, 'services/dataviews');

    this.data = new StatsDataSource();


    this.allStats = {
      'trims': [
        new StatsTrimSources(`Trims AR matching by pvs`, 'trims', 'ar'),

        new StatsTrimIssues('Trims AR data issues', 'trims-ar-issues'),

        new StatsTrimSources(`Trims MX matching by pvs`, 'trims-mx', 'mx'),
      ],
      'manuals': [
        new StatsManuals('Models with >1 manual by pvs', 'manuals'),
        new StatsManualsByYear('Manuals years by pvs', 'manuals-year-table'),
      ],
      'fuseboxes': [
        new StatsFuseboxByModel('Model with >1 fusebox by pvs', 'fuseboxes-by-model'),

        new StatsFuseboxByManuals('Manuals with fuse boxes by fb-pvs', 'fuseboxes'),
      ]
    }

    const category = this.props.match.params.category;

    const stats = this.allStats[category];

    const selectedStat = this.getUrlParam('stat') || stats[0].slug;

    this.state = {
      ...this.state,
      loading: true,
      selectedStat,
      stats,
      category
    }

    this.submenu = _.map(this.allStats, (stats, cat) => ({ to: `/stats/${cat}`, display: _.startCase(cat) }));
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchSelectedStat();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const category = this.props.match.params.category;
    if(category !== this.state.category) {
      let stats = this.allStats[category];
      const selectedStat = this.getUrlParam('stat') || stats[0].slug;

      this.setState({category, selectedStat, stats}, () => {
        this.runAsync(this.fetchSelectedStat(), `Loading stat ${selectedStat}...`);
      });
    }
  }

  async fetchSelectedStat() {
    this.setState({ loading: true })

    let stat = _.find(this.state.stats, {slug: this.state.selectedStat});
    stat.page = this;

    // Load stat data
    await stat.fetchStats()

    // Set as the current loaded stat
    this.setState({ loading: false, stat })
    console.log("Fetched: ", this.state.selectedStat, stat)
  }

  async refresh() {
    for(const s of this.state.stats) {
      if(s.slug === this.state.selectedStat) {
        await s.fetchStats()
      }
    }

    this.forceUpdate();
  }

  async selectStat(stat) {
    this.setUrlParams({stat: stat.slug})

    this.setState({ selectedStat: stat.slug}, () => {
      this.runAsync(this.fetchSelectedStat(), `Loading stat ${stat.slug}...`);
    });
  }

  buildStatsSelector() {
    return <div className={'bg-secondary p-2'}>
      <div className={'bg-secondary btn-group'}>
        {_.map(this.state.stats, (stat) => {
          let selectedClass = this.state.selectedStat === stat.slug ? 'btn-light' : 'btn-outline-light';
          return <span
            key={stat.title}
            onClick={() => this.selectStat(stat)}
            className={'btn btn-sm ' + selectedClass}>
          {stat.title}
        </span>
        })}
      </div>
    </div>
  }

  renderPageBody() {
    let { loading, dataCoverage, coverageMetric, selectedStat, stat } = this.state;

    let loadingScreen = null;
    let statsCoverageTable = null;
    let statsSelector = null;

    if (!stat && loading) {
      loadingScreen = <div className={'text-center pt-5 text-info'}>
        <h4>Fetching traffic and content statistics...</h4>
      </div>
    } else {
      statsSelector = this.buildStatsSelector();

      statsCoverageTable = <DataCoverageTable
        dataCoverage={stat.dataCoverage()} coverageMetric={stat.coverageMetric()} displayGroup={stat.displayRow} objectFilters={stat.searchFilters()}/>
    }

    return <div className={''} key={this.props.match.params.category}>
      {loadingScreen}
      {statsSelector}
      {statsCoverageTable}
    </div>;
  }
}
