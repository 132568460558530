import React, { Component } from 'react';
import { SearchFilterButton } from '../common/SearchFilterButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import _ from 'lodash';
import CountryFlag from "../common/CountryFlag";
import { PureComponent } from 'react';

class SwitchInput extends PureComponent {
  render() {
    let { value, onChange, label, className, id, ...other } = this.props;
    className = className || "";
    if (value === false) {
      className += ' custom-switch--false'
    }

    return <span onClick={onChange} className={"custom-control no-select custom-switch " + (className || '')} {...other}>
      <input type="checkbox" className={"custom-control-input "} id={id} onChange={onChange} checked={value} />
      <label className="custom-control-label" htmlFor={id}>{this.props.children || label}</label>
    </span>
  }
}

export class UserTrimsFiltersBar extends Component {
  constructor(props) {
    super(props);
    this.statsService = client.service('/services/data/usertrims-stats');
    this.state = { stats: null };
  }

  onChange(field, value, isOn) {
    let newFilter = _.cloneDeep(this.props.filter || {});

    if (field !== 'context') {
      if (isOn) {
        newFilter[field] = _.union(newFilter[field], [value]);
      } else {
        newFilter[field] = _.without(newFilter[field], value);
        if (!newFilter[field].length)
          delete newFilter[field];
      }
    } else {
      let { modelId, make, year } = value;

      if (make) {
        newFilter.make = make;
        delete newFilter.idModelo;
      } else if (modelId) {
        newFilter.modelId = modelId;
        delete newFilter.make;
      } else {
        delete newFilter.make;
        delete newFilter.modelId;
      }
      if (year) {
        newFilter.year = year;
      } else {
        delete newFilter.year;
      }
    }
    this.props.onChange(newFilter);
  }

  onChangeSwitch(field) {
    let newFilter = _.cloneDeep(this.props.filter || {});
    if (newFilter[field]) {
      if (newFilter[field].$exists === true) {
        delete newFilter[field];
      } else {
        newFilter[field] = { $exists: true };
      }
    } else {
      newFilter[field] = { $exists: false };
    }
    this.props.onChange(newFilter);
  }
  render() {
    let { country, modelId, year } = this.props.filter || {};

    let context = {};

    if (modelId) {
      context.modelId = modelId;
    }
    if (year) {
      context.year = year;
    }

    return <div>
      <span>
        {
          _.map(['ar', 'mx'], loc =>
            <SearchFilterButton key={loc} onChange={isOn => this.onChange('country', loc, isOn)}
              value={_.includes(country, loc)}>
              <span key={loc} className={'badge badge-primary'}><CountryFlag countryCode={loc} /></span>
            </SearchFilterButton>)
        }
      </span>
      <span style={{ width: '350px', display: 'inline-block' }} className={'zoom-75 ml-3'}>
        <ModelContextEditor value={context} onChange={(newContext) => this.onChange('context', newContext, true)} />
      </span>
      <span style={{ width: '150px', display: 'inline-block' }} className={'zoom-75 ml-3'}>
        <div>
          {_.map(['selectedTrimName', 'otherTrimName', 'otherYear'], field =>
            <div key={field}>
              <SwitchInput value={this.props.filter[field] ? this.props.filter[field].$exists : ''} key={field} onChange={() => this.onChangeSwitch(field)} className={'mr-2'}>
                {field}
              </SwitchInput>
            </div>
          )}
        </div>
      </span>
    </div>;
  }
}
