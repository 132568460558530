import React, { Component } from 'react';
import _ from 'lodash';
import { FileDrop } from 'react-file-drop';

import GlobalClipboard from '../GlobalClipboard';

let c = 0;
export default class ClipboardDropZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pasting: false,
    };
    this.c = c++;

    this.ref = React.createRef();
    this.onPastePotential = this.onPastePotential.bind(this);
    this.onPasteHover = this.onPasteHover.bind(this);
    this.onPasteClick = this.onPasteClick.bind(this);
    this.onFileDropped = this.onFileDropped.bind(this);
  }

  onPastePotential({ imageData, cancel }) {
    if (!cancel) {
      this.setState({ pasting: true, imageData });
    } else {
      this.setState({ pasting: false, imageData });
    }
  }

  onPasteHover(inside) {
    this.setState({ inside });
  }

  onPasteClick() {
    let img = GlobalClipboard.grabImageOnClipboard();
    this.setState({ pasting: false });
    console.log('Pasted image');
    this.props.onPaste(img);
  }

  componentDidMount() {
    this.handler = {
      onPastePotential: this.onPastePotential,
      onPasteHover: this.onPasteHover,
      onPaste: this.onPasteClick,
      ref: this.ref
    }
    GlobalClipboard.onPasteImage(this.handler);
  }

  componentWillUnmount() {
    GlobalClipboard.removeOnPasteImage(this.handler);
  }

  onFileDropped(files, event) {
    // For some reason the drag&drop library fires two events, one for the frame (which is the document)
    // and once for the target. Luckly, one is warapped an the other is not. Filter one by checking 'nativeEvent'
    if (files.length && this.state.inside && !event.nativeEvent) {
      console.log('Dropped' + event.nativeEvent, files, event);
      const reader = new FileReader();
      reader.onload = (event) => {
        //TODO: Filter non-images
        let imageData = event.target.result;
        this.props.onPaste({ imageData, files });
      };
      reader.readAsDataURL(files[0]);
      this.setState({ dragging: false, inside: false });
    } else {
      try {
        let string = event.dataTransfer.getData('text/plain');
        if(string && string.match(/^http\S+\.(jpg|jpeg|png)$/i)) {
          console.log('IMAGE URL PASTED ', string)
        }
      } catch(err) {

      }
      this.setState({ dragging: false });
    }
  }

  render() {
    let { pasting, inside, dragging } = this.state;

    let res = null;
    if (pasting) {
      res = <div ref={this.ref} className={'clipboard-paste-zone font-large' + (inside ? ' hover' : '')}>
        Click here to use image
      </div>;
    } else {
      res = null;
    }

    let draggingElement = null;

    if (dragging) {
      draggingElement = <div className={'clipboard-paste-zone font-large' + (inside ? ' hover' : '')}>
        {inside ? 'Drop' : 'Drag'} your image here
      </div>;
    }

    let style = { display: 'none' };
    if (dragging || pasting) {
      style = { color: 'purple' };
    }

    return <div style={style}>
      <FileDrop onDrop={this.onFileDropped}
                disableDoubleDrop={true}
                onDragOver={() => this.setState({ inside: true })}
                onDragLeave={() => this.setState({ inside: false })}
                onFrameDragEnter={() => this.setState({ dragging: true })}
                onFrameDragLeave={() => this.setState({ dragging: false, inside: false })}
      >
        {draggingElement}
      </FileDrop>
      {res}
    </div>;
  }
}
