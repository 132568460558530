import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { IconButton } from '../common/IconButton';
import { VehicleSummary } from '../common/VehicleSummary';
import TopBarLayout from '../common/layout/TopBarLayout';
import ModelContextEditor from '../lego/ModelContextEditor';
import DataIssueEditor from './DataIssueEditor'
import { StateBadge } from './StateBadge';
import BadgeId from '../common/BadgeId';
import { Icon } from '../common/Icon';
import NiceDate from '../common/NiceDate';
import { OpenClosedIcon } from './OpenClosedIcon';
import { DataTypeTag } from '../dataprocess/DataTypeTag';
import ModelFuseboxesManualsTable from '../lego/fusebox-editor/ModelFuseboxesManualsTable'
import LegoContextSummary from '../lego/LegoContextSummary';

function DataIssueSummaryRow({ issue, currentFeedback, onRemove, onRowClick, onAddFeedback, onIssueClick, onDeleteClick }) {
  const {
    dataSelector, isOpen, createdAt, updatedAt, source, state, dataType, issueCategory, data, blockedBy, involvedDataFeedback,
    notes
  } = issue;

  let rowClass = onRowClick ? 'clickable-row' : '';

  let feedbackBtn;
  const isInvolved = _.includes(involvedDataFeedback, currentFeedback);

  if(isInvolved) {
    feedbackBtn = <IconButton level={'danger'} icon={'person_remove'} onClick={() => onRemove(issue)}>Remove</IconButton>
    rowClass += ' bg-highlight'
  } else {
    feedbackBtn = <IconButton level={'primary'} icon={'person_add_alt'} onClick={() => onAddFeedback(issue)}>Associate</IconButton>
  }

  if(isOpen === false) {
    rowClass += ' bg-light-partial-success'
  } else if(blockedBy?.length) {
    rowClass += ' bg-light-secondary'
  }

  return <tr className={rowClass} onClick={() => onRowClick && onRowClick(issue)}>
    <td className={'pl-3'}>
      <OpenClosedIcon isOpen={isOpen}/>
    </td>


    <td>
      <div><StateBadge state={state}/></div>
      <NiceDate t={updatedAt}/>
    </td>

    <td className={'text-center no-wrap'} title={`${involvedDataFeedback?.length || 0} involved data feedbacks`}>
      {involvedDataFeedback?.length ? <span>
        <Icon icon={'person'} level={isInvolved ? 'success' : 'secondary'} className={'h3 m-0'}/>
        <strong className={'align-middle'}>{involvedDataFeedback.length}</strong>
      </span> : null }
    </td>

    <td className={'text-left'}>
      {feedbackBtn}
    </td>

    <td>
      <span className={'badge badge-secondary'}>{issueCategory}</span><br/>
      <span className={'badge badge-light'}>{dataType}</span>
    </td>

    <td>
      <LegoContextSummary context={dataSelector.vehicle || {}}/>
    </td>


    <td>
      {blockedBy?.length ? <span className={'badge badge-dark'}>BLOCKED</span> : null}

      {/*{involvedDataFeedback?.length ? <span>{involvedDataFeedback.length} data feedbacks</span> : null}*/}
    </td>

    <td>
      {/*<div>{source.type}</div>*/}
      {/*<div><BadgeId id={source.id}/></div>*/}
    </td>

    <td>
      {
        _.map(data, (val,key) => <div key={key} className={'small'}>
          <span className={'text-secondary'}>{key}:</span>
          <span className={'ml-1 text-info monospace break-word-all'}>{JSON.stringify(val)}</span>
        </div>)
      }
      {/*<pre style={{maxWidth: '300px'}} className={'text-info  small mb-0'}>{JSON.stringify(data, true, 2)?.replace(/\s*[{\}](\n|$)/g, '')}</pre>*/}
    </td>

    {/*<td>*/}
    {/*  <pre className={'small'}>{JSON.stringify(issue, true, 2)}</pre>*/}
    {/*</td>*/}

    <td>
      <IconButton level={'primary'} icon={'edit'} onClick={() => onIssueClick(issue)}/>
    </td>
    <td>
      <IconButton level={'danger'} icon={'delete'} onClick={() => onDeleteClick(issue)}/>
    </td>
  </tr>;
}


export function DataIssuesFinder({ vehicleContext, cluster, dataFeedbackId, onRowClick }) {
  const { page } = useContext(LegoAdminPageContext);
  const [context, setContext] = useState(vehicleContext)

  const dataIssuesService = page.service('/services/data/fusebox-data-issues');
  const manualsService = page.service('/services/data/manuals');

  const [dataIssues, setDataIssues] = useState([]);
  const [hasManual, setHasManual] = useState(null);

  const openResourceModal = () => page.openModal(<ModelFuseboxesManualsTable modelId={context.modelId} year={context.year}/>, {title: `${vehicleContext.modelId} ${vehicleContext.year}: Manuals and fuseboxes `});

  useEffect(() => {
    fetchDataIssues();
    // openResourceModal();
    checkForManual()
  }, [JSON.stringify([context, cluster])]);

  //TODO: fetch dataFeedbackIds of items in current cluster, required by schemaDataIssue
  const fetchDataFeedbackIds = () => {

  };

  const fetchDataIssues = () => {
    let query = {};
    if (context) {
      if(context.make) {
        query = { dataType: 'fusebox', 'dataSelector.vehicle.modelId': {$regex: '^'+context.make+'-'} };
      } else {
        query = { dataType: 'fusebox', 'dataSelector.vehicle.modelId': context.modelId };
      }
    } else {
      query = { dataType: 'fusebox', 'data.clusterId': { $in: [cluster._id] } };
    }

    page.runAsync(dataIssuesService.find({ query }).then(res => setDataIssues(res)));
  };

  const checkForManual = () => {
    let query = { 'context.modelId': context.modelId, 'context.year': context.year, manualCategory: 'MANUAL' };
    page.runAsync(manualsService.find({ query }).then(res => {
      if (res.length === 0){
        setHasManual(false)
      }
      })
    )
  };

  const onRemoveHandler = dataIssue => {
    let clone = _.cloneDeep(dataIssue);
    if (cluster) {
      _.pull(clone.involvedDataFeedback, dataFeedbackId);
    }
    onAddOrEditHandler(dataIssue, clone);
  };

  const onAddFeedback = dataIssue => {
    let clone = _.cloneDeep(dataIssue);
    if (cluster && dataFeedbackId) {
      clone.involvedDataFeedback = _.uniq([... clone.involvedDataFeedback, dataFeedbackId]);
    }
    onAddOrEditHandler(dataIssue, clone);
  };

  const onAddOrEditHandler = (dataIssue, changedObj) => {
    const newObjectTemplate = {
      dataSelector: { vehicle: context ? context : { ...cluster.context } },
      source: { type: 'fuseboxFeedback', id: dataFeedbackId },
      state: 'inprogress',
      dataType: 'fusebox',
      issueCategory: 'missingYear',
      isOpen: true,
      data: cluster ? { clusterId: cluster._id, hasManual: hasManual } : {hasManual: hasManual},
      involvedDataFeedback: dataFeedbackId ? [dataFeedbackId] : []
    };

    const close = () => page.modalActionsBus.emit('close');

    const save = async (obj, shouldClose) => {
      let res;
      if(obj._id) {
        res = await dataIssuesService.update(obj._id, obj);
        fetchDataIssues()
      } else {
        res = await dataIssuesService.create(obj);
        fetchDataIssues()
      }
      if(shouldClose) {
        close();
      }
      return res;
    }

    page.modalActionsBus.emit('open', <DataIssueEditor changedObj={changedObj}
                                                       obj={dataIssue || newObjectTemplate}
                                                       onCancel={close}
                                                       onSave={(... args) => page.runAsync(save(... args))}/>, { title: dataIssue?._id ? 'Edit data issue' : 'Create data issue' });
  };

  const onDeleteHandler = (issueId) => {
    if(confirm('Are you sure? There is no undo')) {
      page.runAsync(dataIssuesService.remove(issueId)).then(fetchDataIssues);
    }
  };

  // const filteredIssues = _.filter(dataIssues, di => di.data.clusters.includes(cluster._id));
  const filteredIssues = dataIssues;

  let dataFeedbackNotice = null;
  if(dataFeedbackId && !_.some(filteredIssues, issue => (issue.involvedDataFeedback || []).includes(dataFeedbackId))) {
    dataFeedbackNotice = <tr className={'bg-dark text-white'}>
      <td colSpan={'100%'} className={'text-center'}>Data feedback is not assigned to any issue</td>
    </tr>
  }

  const year = vehicleContext.year;
  const modelId = vehicleContext.modelId;

  const sameCluster = _.filter(filteredIssues, issue => cluster && (issue.data?.clusterId === cluster._id));
  const sameYear = _.filter(filteredIssues, issue => issue.dataSelector.vehicle.year === year);
  const sameClusterAndYear = _.intersection(sameCluster, sameYear);
  let rowGroups = [
    ['Same cluster and year', 'bg-light-success', sameClusterAndYear],
    [`Issues ${modelId} from same cluster`, 'bg-light-primary', _.difference(sameCluster, sameClusterAndYear)],
    [`Issues ${modelId} year ${year}`, 'bg-light-secondary', _.difference(sameYear, sameClusterAndYear)],
    [`Other ${modelId} issues`, 'bg-light-secondary', _.difference(filteredIssues, sameYear, sameCluster)],
  ];

  let rows = [];
  _.each(rowGroups, ([name, classes, issues]) => {
    rows.push(<tr className={classes} key={name}>
      <td colSpan={'100%'} className={'text-center'}>{name}</td>
    </tr>);

    for(const issue of issues) {
      rows.push(<DataIssueSummaryRow issue={issue} key={issue._id}
                                     currentFeedback={dataFeedbackId}
                                     onRowClick={onRowClick}
                                     onIssueClick={onAddOrEditHandler}
                                     onDeleteClick={() => onDeleteHandler(issue._id)}
                                     onAddFeedback={onAddFeedback}
                                     onRemove={onRemoveHandler}/>)
    }
  });

  return <TopBarLayout>
    <div className={'d-flex p-1 bg-light align-items-center justify-content-around'}>
      <IconButton icon={'add'} level={'success'} onClick={() => onAddOrEditHandler()}>Add new</IconButton>

      <div style={{minWidth: '300px'}} className={'zoom-90'}>
        <ModelContextEditor value={context} onChange={ctx => setContext(ctx)}/>
      </div>

      <IconButton icon={'calendar_view_month'} onClick={openResourceModal}>Fusebox/manuals table</IconButton>

      <span>Feedback: <span className={'badge badge-secondary'}>{dataFeedbackId || '-'}</span></span>

      <span>Cluster: <span className={'badge badge-secondary'}>{cluster?._id || '-'}</span></span>
    </div>

    <table className={'table table-sm bg-white'}>
      <tbody>
      { dataFeedbackNotice }

      {rows}

      </tbody>
    </table>
  </TopBarLayout>
}
