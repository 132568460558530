import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import { useContext, useState } from 'react';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import SemanticTag from './SemanticTag';
import _ from 'lodash';
import SemanticEditor from '../lego/SemanticEditor';
import InputMultipleSemantics from '../common/editors/InputMultipleSemantics';

function AncestorsPairsTree({tree}) {
  let map = {};
  let nodes = new Set();
  let notTopLevel = new Set();
  _.each(tree, (parents, semantic) => {
    _.each(parents, (val, parent) => {
      map[parent] = map[parent] || {};
      if(!map[parent][semantic]) {
        map[semantic] = map[semantic] || {};
        map[parent][semantic] = map[semantic];
      }
      nodes.add(parent);
    });
    notTopLevel.add(semantic);
  });

  function pruneSeen(tree, seen = new Set()) {
    _.each(tree, (children, parent) => {
        if(seen.has(parent)) {
          delete tree[parent];
        } else {
          seen.add(parent);
          pruneSeen(children, seen);
        }
    });
  }

  pruneSeen(map);

  function SemanticsTree({ map }) {
    return <div>
      {_.map(map, (children, parent) => <div>
        <SemanticTag style={{transform: 'translateX(-5px)'}}  semKey={parent} level={'secondary'}/>

        <div className={'ml-4 border-left border-primary'}>
          <SemanticsTree map={children}/>
        </div>
      </div>)
      }
    </div>;
  }

  return <SemanticsTree map={_.pick(map, _.difference([... nodes], [...notTopLevel]))}/>
}

export default function SemanticAncestorsModal({ semantic }) {
  const [ancestors, setAncestors] = useState(null);
  const [sem, setSem] = useState(semantic);

  const { page } = useContext(LegoAdminPageContext);
  const nlpService = page.service('services/semantic/nlp');

  useAsyncEffect(async () => {
    setAncestors(await nlpService.find({ query: { operation: 'ancestorsPairs', params: { semantics: [sem] } } }));
  }, [sem]);

  let res = <div className={'mt-1'}>Loading...</div>;

  function topN(semLinguistic, n = 10) {
    return _.toPairs(semLinguistic._default || { '-': 0 }).slice(0, n);
  }

  if(ancestors) {
    console.log(ancestors)
    if(_.keys(ancestors).length) {
      res = <AncestorsPairsTree tree={ancestors}/>
    } else {
      res = <div className={'text-center alert alert-info mt-1'}>No useful parents</div>;
    }
  }

  return <div>
    Parents de:
    <InputMultipleSemantics value={[sem]} onChange={sems => sems.length && setSem(sems.slice(-1)[0])}/>

    <div className={'mt-2 ml-1'}>
      {res}
    </div>
  </div>;
}
