import React, { useContext, useState } from 'react';

import BadgeId from '../common/BadgeId';
import ImageClusterPreview from '../images/ImageClusterPreview';
import { IconButton } from '../common/IconButton';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import { SwitchInput } from '../common/SwitchInput';
import _ from 'lodash';
import ImageClusterFinder from '../images/ImageClusterFinder';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';

export default function ClusterIdEditor({ value, filterByYear, onChange, modelId }) {
  const [filterByYearEnabled, setFilterByYearEnabled] = useState(_.isNumber(filterByYear))
  const filterByYearSwitch =  filterByYear ? <SwitchInput className={'mr-3 ml-2 small'} value={filterByYearEnabled} onChange={() => setFilterByYearEnabled(!filterByYearEnabled)}>
    year {filterByYear}
  </SwitchInput> : null

  const { page } = useContext(LegoAdminPageContext);

  const openSearchClusters =  () => {
    page.openModal(<div>
        <ImageClusterFinder clusterType={'fusebox-broad'}
                            vehicleContext={{modelId}}
                            sortByYear={filterByYearEnabled ? filterByYear : false}
                            onClusterSelected={(c) => {
                              if(c) {
                                onChange(c._id);
                              }
                              page.closeModal();
                            }}
        />
      </div>
      , { fullScreen: true, noPadding: true, title: 'Find image cluster' })
  }

  if (value && value.match(/^[a-f\d]{24}$/i)) {
    return <span className={'inline-block'}>
      <div className={'text-secondary'}>Cluster <BadgeId id={value}/>
        {filterByYearSwitch}
        </div>
      <ImageClusterPreview clusterId={value} maxImages={5} filterByYear={filterByYearEnabled ? filterByYear : false}/>
      <IconButton icon={'clear'} level={'danger'} onClick={() => onChange(null)}/>
    </span>;
  } else {
    return <span className={'text-secondary d-flex align-items-center'}>Cluster id:&nbsp;
      <SingleTextEditor style={{ width: '300px' }}
                        value={value || ''}
                        small
                        placeholder={'Enter cluster _id...'}
                        onChange={onChange}/>

      {value && !value.match(/^[a-f\d]{24}$/i) ? <span className={'badge badge-danger ml-2'}>INVALID ID</span> : null}

      <IconButton icon={'search'} level={'primary'} onClick={openSearchClusters}/>
    </span>;
  }
}
