import _ from "lodash";

export default class StatsDataSource {
  constructor() {
    this.services = {};

    this.modelMetadataService = this.service('services/models-metadata');
    this.trafficStatsService = this.service('services/data/stats/traffic');
    this.legoStatsService = this.service('services/data/stats/lego');
    this.trimStatsService = this.service('services/data/stats/trims');
    this.dataIssuesService = this.service('services/data/issues');
    this.manualsService = this.service('services/data/manuals/list');
    this.modelListService = this.service('/services/data/models')
  }

  service(serviceEndpoint) {
    // services[serviceEndpoint] = window.client.service(serviceEndpoint)
    // return this.services[serviceEndpoint];
    return window.client.service(serviceEndpoint)
  }

  async traffic(countryCode) {
    return this.trafficStatsService.find({query: {country: countryCode}});
  }

  async trims(countryCode) {
    return this.trimStatsService.find({query: {country: countryCode}});
  }

  async trimIssues(countryCode) {
    return this.dataIssuesService.find({query: {summary: true}});
  }

  async fuseboxes() {
    return this.legoStatsService.find();
  }

  async manuals() {
    return this.manualsService.find();
  }

  async models() {
    return this.modelListService.find({query: {fullModel: true}});
  }

}
