import React from 'react';

import { Icon } from '../common/Icon';

export function OpenClosedIcon({ isOpen, ... props }) {
  if(isOpen === true || isOpen === false) {
    return <Icon icon={isOpen ? 'pending_actions' : 'check_circle_outline'}
                 title={`Data issue is ${isOpen ? 'OPEN' : 'CLOSED'}`}
                 level={isOpen ? 'secondary' : 'success'} {...props}/>;
  } else {
    return <Icon icon={'indeterminate_check_box'}
                 title={`Data issue has indeterminate state`}
                 level={'secondary'} {...props}/>;
  }
}
