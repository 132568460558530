import React, {Component} from 'react';

import { Icon } from './Icon';

export function IconButtonToggle({ icon, value, triState, onChange, title, ...other }) {
  let toggleValue;
  if(triState) {
    toggleValue = () => {
      if(value === true) {
        onChange(false);
      } else if(value === false) {
        onChange(undefined);
      } else {
        onChange(true);
      }
    }
  } else {
    toggleValue = () =>  onChange(!value);
  }

  let stateClass = value ? 'on' : (triState && value === undefined ?  'tri' : 'off')

  return <span className={'OnOffIcon OnOffIcon--' + stateClass}
               title={title || ''}
               onClick={toggleValue}>
    <Icon icon={icon} {...other}/>
  </span>
}
