import React, {Component} from 'react';
import {SearchFilterButton} from '../common/SearchFilterButton';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import ModelContextEditor from '../lego/ModelContextEditor';
import _ from 'lodash';
import CountryFlag from "../common/CountryFlag";

export class CrawlingFiltersBar extends Component {
  constructor(props) {
    super(props);
    this.state = { snapshotsList: null };
    this.crawlIdService = client.service('services/data/scrapping/crawl-ids');
  }

  componentDidMount() {
    this.loadCrawlIds().catch(err => console.error(err));
  }

  async loadCrawlIds() {
    let query = { $sort: { date_time: 1 } }
    let crawlIdColl = await this.crawlIdService.find({ query });
    this.setState({ crawlIdColl });
  }

  onChange(field, value, isOn) {
    let newFilter = _.cloneDeep(this.props.filter || {});

    if (field !== 'context') {
      if (isOn) {
        newFilter[field] = _.union(newFilter[field], [value]);
      } else {
        newFilter[field] = _.without(newFilter[field], value);
        if (!newFilter[field].length)
          delete newFilter[field];
      }
    } else {
      let { modelId, make, year } = value;

      if (make) {
        newFilter.make = make;
        delete newFilter.idModelo;
      } else if (modelId) {
        newFilter.idModelo = modelId;
        delete newFilter.make;
      } else {
        delete newFilter.make;
        delete newFilter.idModelo;
      }

      if (year) {
        newFilter.year = year.toString();
      } else {
        delete newFilter.year;
      }
    }
    if (field === 'country') {
      delete newFilter.crawl_id;
      if (newFilter.country) {
        newFilter.crawl_id = [];
        for (const country of newFilter.country) {
          const countryCrawls = _.filter(this.state.crawlIdColl, { country });
          const latestCrawl = (_.maxBy(countryCrawls, 'date_time') || {}).crawl_id;
          newFilter.crawl_id.push(latestCrawl);

          if (newFilter.country.length === 1) {
            this.setState({ snapshotsList: countryCrawls });
          }
        }
      }
    }
    if (field === 'crawl_id') {
      delete newFilter.crawl_id;
      newFilter.crawl_id = value;
    }
    this.props.onChange(newFilter);
  }

  render() {
    let { make, year, country, crawl_id, idModelo } = this.props.filter || {};

    let context = {};

    if (year) {
      context.year = year;
    }

    if (make) {
      context.make = make;
    }

    if (idModelo) {
      context.modelId = idModelo;
    }

    let snapshot_span = null;
    if ((country || []).length === 1 && crawl_id) {
      const countrySnapshots = _.map(this.state.snapshotsList, snap => {
        return { value: snap.crawl_id.toString(), label: snap.crawl_id.slice(0, 7) }
      });

      snapshot_span = <span style={{ width: '120px', display: 'inline-block' }} className={'zoom-75 ml-3'}>
        <SingleValueSelector value={crawl_id[0].slice(0, 7)}
                             onChange={value => this.onChange('crawl_id', [value])}
                             options={countrySnapshots}
        />
      </span>
    }

    return <div>
      <span>
        {
          _.map(_.uniq(_.map(this.state.crawlIdColl, 'country')), loc =>
            <SearchFilterButton key={loc} onChange={isOn => this.onChange('country', loc, isOn)}
                                value={_.includes(country, loc)}>
              <span key={loc} className={'badge badge-primary'}><CountryFlag country={loc}/></span>
            </SearchFilterButton>)
        }
      </span>

      {snapshot_span}

      <span style={{ width: '350px', display: 'inline-block' }} className={'zoom-75 ml-3'}>
        <ModelContextEditor value={context} onChange={(newContext) => this.onChange('context', newContext, true)}/>
      </span>
    </div>;
  }
}
