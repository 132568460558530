import React, {Component} from 'react';
import _ from 'lodash';

import {format} from 'date-fns'

import LivePage from './LivePage';
import CountryFlag from "../components/common/CountryFlag";
import UserPostsTimeline, { extractUserActions } from '../components/stats/UserPostsTimeline';

export default class RegisteredUserStatsViewer extends LivePage {
  constructor(props) {
    super(props);

    // Screen divided in two panes requires a fullscreen layout
    this.fullScreen = true;

    // this.submenu = submenuEmails;

    this.users = this.service('services/data/users/activity');
  }

  componentDidMount() {
    super.componentDidMount();

    this.users.find().then((res) => {
      this.setState({ users: res })
    })
  }

  renderUsersFunnel() {
    if (!this.state.users) {
      return null;
    }

    let userSlices = {};

    _.each(this.state.users, user => {
      let actions = extractUserActions(user.id, user.reports);

      let g = actions[0]?.type || 'No action'; //user.level;

      userSlices[g] = (userSlices[g] || []);
      userSlices[g].push({... user, actions})
    })

    const userActions = (user) => _.filter(user.actions, a => a.type === 'report' || a.type === 'comment')

    const filters = [
      ['New users', user => true],

      ['Comment again', user => userActions(user) > 1],

      ['Comments >= 2', user => user.actions.length > 2],

      ['Comments >= 10', user => user.actions.length > 10],
    ]

    const graphData = {
      labels: _.map(filters, '0'),
        subLabels: _.keys(userSlices),
        colors: [
          ['#FFB178', '#FF78B1', '#FF3C8E'],
          ['#a0bbff', '#EC77FF'],
          ['#4fec7b', '#146b2a'],
          ['#A0F9FF', '#7795FF'],
          ['#75b0a1'],
      ].slice(0, _.keys(userSlices).length),
        values: _.map(filters, ([name, func]) => _.map(userSlices, (c) => _.filter(c, func).length))
    };

    const graph = new FunnelGraph({
      container: '.funnel',
      gradientDirection: 'horizontal',
      data: graphData,
      displayPercent: true,
      direction: 'horizontal',
      width: 500,
      height: 200,
      subLabelValue: 'percent'
    });

    graph.draw();

    return null;
  }

  renderUsersTable() {
    if (!this.state.users) {
      return "Loading users...";
    }

    return <table className={'table table-sm table-striped EmailLogTable'}>
      <thead>
      <tr className={'text-center'}>
        <th>User</th>
        <th>Fecha</th>
        <th>Activity</th>
        <th>UserTo</th>
      </tr>
      </thead>
      <tbody>
      {
        _.map(this.state.users, (user) => {
          const { id, fechaCreacion, username, pais, level, reports, ... userFields } = user;
          return <tr key={user}>
            <td className={'p-1 text-center'}>
              <div><CountryFlag countryCode={pais}/> {username}</div>
              <div className={'small monospace text-secondary'}>{id}</div>
            </td>

            <td className={'text-center'}>
              {format(fechaCreacion, 'D MMM YYYY HH:mm')}
              <div className='asdsad'>
                <span className={'badge badge-secondary'}>{level}</span>
              </div>
            </td>

            <td>
              <UserPostsTimeline userId={id} reports={reports}/>
            </td>

            <td>
              {/*<pre>{JSON.stringify(user, true, 4)}</pre>*/}
            </td>
          </tr>
        })
      }
      </tbody>
    </table>
  }

  textFilterChanged(e) {
    const textFilter = e.target.value;

    // Save in url
    if(textFilter)
      this.setUrlParams({textFilter})
    else
      this.deleteUrlParam('textFilter');

    this.setState({ textFilter });
  }

  renderPageBody() {
    return <div className={'EmailLogsViewer'}>
      <div>
        <div className={'bg-secondary row no-gutters align-items-center sticky-top'}>
          <div className={'col-3 p-2'}>
            <input ref={this.searchInputRef} className={'form-control'} defaultValue={this.state.textFilter} placeholder={'Search...'} type={'text'}
                   onChange={this.textFilterChanged.bind(this)}/>
          </div>
        </div>
        <div>
          { this.renderUsersFunnel() }
          <div style={{background: '#393862'}}>
            <div className={'funnel'}></div>
          </div>
        </div>
        <div className={'overflow-auto pos-relative'}>
          {/*{this.renderUsersTable()}*/}
        </div>
      </div>
      {/*<pre>{JSON.stringify(this.state.emails || 'Loading...', true, 2)}</pre>*/}
    </div>
  }
}
