import React, { Component } from 'react';
import _ from 'lodash';
import JsonTextEditor from '../common/editors/JsonTextEditor';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import MultipleContextsEditor from '../lego/MultipleContextsEditor';
import ProcessStateChangeSelector from './ProcessStateChangeSelector';
import CRUDEditor from '../common/editors/CRUDEditor';
import { GenericDataTypeEditor } from './GenericDataTypeEditor';
import ProcessIdSelector from './ProcessSelector';

export default class DataProcessEditor extends CRUDEditor {
  constructor(props) {
    super(props);

    this.dataProcessDefinitionService = window.client.service('services/data-process-definition');
    this.dataTasksService = window.client.service('services/data-tasks');
  }

  componentDidMount() {
    if (!this.state.processDefinitionsById) {
      this.loadProcessDefinitions().catch(this.handleError);
    }
  }

  async loadProcessDefinitions() {
    this.processDefinitions = await this.dataProcessDefinitionService.find();
    this.setState({ processDefinitionsById: _.keyBy(this.processDefinitions, 'processType') });
  }

  async launchTask(task, parentProcess, processDefinition) {
    let res = await this.dataTasksService.create({
      processType: parentProcess.processType,
      parentProcessId: parentProcess._id,
      typeId: task.id,
      impact: parentProcess.impact,
      context: parentProcess.context,
      state: '',
      input: _.pick(parentProcess.data, task.inputs),
      output: {}
    })

    let updatedPendingTasks = [... this.state.changedObject.pendingTasks, res._id]
    this.editField('pendingTasks', updatedPendingTasks)
  }

  getEditorUi(changedObject) {
    const { _id, impact, processType, context, state, data, finishedTasks, pendingTasks } = changedObject;

    const { processDefinitionsById } = this.state;

    let validStates = {};
    const processTemplate = (processDefinitionsById || {})[processType];

    if (processType && processTemplate) {
      validStates = (processDefinitionsById[processType] || {}).states;
    }

    let launchTaskButtons = [];
    let processDataEditors = [];

    if (processTemplate) {
      processDataEditors = _.map(processTemplate.dataTypes, ({type, name}) => {
        let dataFieldClass = (data || {})[name] ? 'success' : 'secondary';
        return <div key={name} className={`row align-items-center rounded no-gutters mb-1 bg-light-${dataFieldClass}`}>
          <div className={`col-3 text-${dataFieldClass} text-right p-1`}>{name}:</div>
          <div className={'col-9 p-1'}>
            <GenericDataTypeEditor type={type} value={(data || {})[name]} onChange={(val) => this.editField(`data.${name}`, val)}/>
          </div>
        </div>;
      });

      launchTaskButtons = _.map(processTemplate.tasks, (task) => {
        const { id, inputs } = task;
        const taskInputsReady = _.every(inputs, inputField => (data || {})[inputField]);
        const taskBtnColor = taskInputsReady ? 'primary' : 'outline-secondary';

        return <button key={id} disabled={!taskInputsReady} className={`btn btn-${taskBtnColor} btn-xs ml-1`} onClick={() => taskInputsReady && this.launchTask(task, changedObject, processTemplate)}>{id}</button>;
      });
    }


    return <table className={'table table-sm'}>
      <tbody>
      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>processType:</td>
        <td>
          <ProcessIdSelector onChange={(txt) => this.editField('processType', txt)} value={processType}/>
        </td>
      </tr>

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>Impact:</td>
        <td>
          {impact}
        </td>
      </tr>

      <tr>
        <td className={'text-right'}>Context:</td>
        <td className={''}>
          <MultipleContextsEditor onChange={(json) => this.editField('context', json)} value={context}/>
        </td>
      </tr>

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>State:</td>
        <td>
          <ProcessStateChangeSelector validStates={validStates} onChange={(json) => this.editField('state', json)}
                                      value={state}/>
        </td>
      </tr>

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>Data:</td>
        <td>{processDataEditors}</td>
      </tr>

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>Pending tasks:</td>
        <td>{pendingTasks.length}</td>
      </tr>

      <tr>
        <td style={{ width: '140px' }} className={'text-right'}>Finished tasks:</td>
        <td>{finishedTasks.length}</td>
      </tr>


      {
        _id
          ?
          <tr>
            <td colSpan={2} className={'small text-center pt-2 pb-2 bg-light'}>Launch a data task manually: {launchTaskButtons}</td>
          </tr>
          :
          null
      }
      </tbody>
    </table>;
  }
}
