import React, { Component } from 'react';
import _ from 'lodash';

import SemanticTag from '../semantic/SemanticTag';
import { IconButton } from '../common/IconButton';

export default class SemanticSearchDialog extends Component {
  constructor(props) {
    super(props);

    this.semanticRep = window.client.service('/services/semantic/representation/fromId');

    this.state = {
      query: props.initialSearch || '',
      loading: !!props.initialSearch,
      results: null,
    };

    if(this.state.query) {
      this.parseQuery();
    }

    this.parseQuery = _.debounce(this.parseQuery, 300);
  }

  async updateSemantic() {
    let semantics = this.state.changedObject.semantics;

    this.setState({ semanticLookups: await this.semanticRep.get(semantics) });
  }

  async parseQuery() {
    let res = await this.semanticRep.find({ query: {text: this.state.query} });
    this.setState({ results: res, loading: false });
  }

  async queryChanged(e) {
    let q = e.target.value;

    this.setState({ query: q, loading: true });
    this.parseQuery();
  }

  render() {
    let results = null;

    if (this.state.loading) {
      results = <div className={'text-center'}>Loading...</div>;
    } else if (this.state.query) {
      if (this.state.results && this.state.results.length) {
        results = _.map(_.uniq(this.state.results), key => {
          return <div key={key} onClick={() => this.props.onOk(key)}>
              <span className={'btn btn-light mb-1'}>
              Usar <SemanticTag level='primary' semKey={key}/> <i className={`material-icons align-middle text-primary `}>arrow_right_alt</i>
              </span>
          </div>;
        });
      } else {
        results = <div className={'alert alert-danger'}>Could not parse query</div>;
      }
    }


    return <div style={{minWidth: "400px", minHeight: "200px"}}>
      <div>
        Type a query to parse its semantic:

        <input autoFocus={true} placeholder={'Type query...'}
               type={'text'}
               className={'form-control mt-2'}
               value={this.state.query}
               onChange={this.queryChanged.bind(this)}/>
      </div>
      <div className={'mt-3'}>{results}</div>
      <div className={'mt-3 '}>
        {/*<button onClick={() => this.props.onOk("")} className={'btn btn-primary mr-2'}>Ok</button>*/}
        {/*<button onClick={() => this.props.onCancel} className={'btn btn-default'}>Cancelar</button>*/}
      </div>
    </div>;
  }
}
