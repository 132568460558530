import _ from 'lodash';
import React, {Component} from 'react';
import {IconButton} from "../../common/IconButton";

const GlossaryRefSpan = ({ editor, children, contentState, entityKey, offsetKey, decoratedText, blockKey }) => {
  const { semantic } = contentState.getEntity(entityKey).getData();

  const clickDelete = () => editor.deleteEntity(blockKey, entityKey);
  const clickEdit = () => editor.openGlossaryEditor(entityKey, decoratedText, blockKey);

  return <span className={'GlossaryRef'} title={'Ver glosario de ' + semantic}>
    <span contentEditable={false} className={'GlossaryRef__semantic'}>
        <span onClick={clickEdit}  className={'badge badge-primary'}>{semantic}</span>
        <IconButton icon={'clear'} level={'danger'} className={'m-0 p-0'} onClick={clickDelete}/>
      </span>
    {children}
    </span>;
};

export default GlossaryRefSpan;
