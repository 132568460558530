import _ from 'lodash';

let ch,
  cw;

function colorPixel(x, y, data, color) {
  // find the starting index of the r,g,b,a of pixel x,y
  const start = (y * cw + x) * 4;

  // DEBUG IMAGE SEGMENTS WITH DIFFERENT COLORS
  data[start + 0] = color % 140;
  data[start + 1] = color % 13 * 12;
  data[start + 2] = 255 - data[start + 2];
  data[start + 3] = 200;
}

function floodFill(startX, startY, blackWhiteVersion, data) {
  let newPos,
    x,
    y,
    reachLeft,
    reachRight;

  const drawingBoundLeft = 0,
    drawingBoundTop = 0,
    drawingBoundRight = cw - 1,
    drawingBoundBottom = ch - 1,
    pixelStack = [[startX, startY]];

  let topLeftX = startX;
  let topLeftY = startY;
  let bottomRightX = startX;
  let bottomRightY = startY;


  while (pixelStack.length) {
    newPos = pixelStack.pop();
    x = newPos[0];
    y = newPos[1];

    // Go up as long as there is white and are inside the canvas
    while (y >= drawingBoundTop && blackWhiteVersion[y * cw + x]) {
      y -= 1;
    }

    y += 1;
    reachLeft = false;
    reachRight = false;

    // Go down as long as there is white  and in inside the canvas
    while (y <= drawingBoundBottom && blackWhiteVersion[y * cw + x]) {
      // For Debugging by painting different colors
      // colorPixel(x, y, data, startX + startY);
      blackWhiteVersion[y * cw + x] = 0;

      topLeftX = Math.min(topLeftX, x);
      topLeftY = Math.min(topLeftY, y);
      bottomRightX = Math.max(bottomRightX, x);
      bottomRightY = Math.max(bottomRightY, y);

      if (x > drawingBoundLeft) {
        if (blackWhiteVersion[y * cw + (x - 1)]) {
          if (!reachLeft) {
            // Add pixel to stack
            pixelStack.push([x - 1, y]);
            reachLeft = true;
          }
        } else if (reachLeft) {
          reachLeft = false;
        }
      }

      if (x < drawingBoundRight) {
        if (blackWhiteVersion[y * cw + (x + 1)]) {
          if (!reachRight) {
            // Add pixel to stack
            pixelStack.push([x + 1, y]);
            reachRight = true;
          }
        } else if (reachRight) {
          reachRight = false;
        }
      }

      y += 1;
    }
  }

  return { x1: topLeftX - 1, y1: topLeftY - 1, x2: bottomRightX + 1, y2: bottomRightY + 1 };
}

function areNextToEachOther(a, b) {
  let close = (v1, v2) => Math.abs(v1 - v2) < 10;
  let closeAxisY = (close(a.y1, b.y1) && close(a.y2, b.y2) && (close(a.x2, b.x1) || close(a.x1, b.x2)));
  let closeAxisX = (close(a.x1, b.x1) && close(a.x2, b.x2) && (close(a.y2, b.y1) || close(a.y1, b.y2)));
  return closeAxisX || closeAxisY;
}

function merge(a, b) {
  return { x1: Math.min(a.x1, b.x1), y1: Math.min(a.y1, b.y1), x2: Math.max(a.x2, b.x2), y2: Math.max(a.y2, b.y2) };
}

function mergeRectanglesCloseToEachOther(rectangles) {
  let leftRectangles = [...rectangles];
  const res = [];
  rectangles.forEach(r1 => {
    // Already deleted by previous merge
    if (!_.includes(leftRectangles, r1)) {
      return;
    }

    leftRectangles = _.without(leftRectangles, r1);

    for (let r2 of leftRectangles) {
      if (r1 !== r2 && areNextToEachOther(r1, r2)) {
        leftRectangles = _.without(leftRectangles, r2);
        res.push(merge(r1, r2));
        return;
      }
    }
    res.push(r1);
  });
  return res;
}

function findRectangles(originalImageData, width, height, blackThreshold = 0.95, minSize = 0) {
  ch = height;
  cw = width;
  const shapes = [];

  // Create black and white version of the image based on the give parameters
  const monochromeData = makeMonochromeCopy(originalImageData, width, height, blackThreshold, false);

  const samplePixelsEvery = 5;

  for (let y = 0; y < height; y += samplePixelsEvery) {
    for (let x = 0; x < width; x += samplePixelsEvery) {
      const isLine = !monochromeData[y * width + x];

      if (!isLine) {
        const voidSize = floodFill(x, y, monochromeData, originalImageData);

        if (((voidSize.x2 - voidSize.x1) * (voidSize.y2 - voidSize.y1)) / (ch * cw) * 100 > minSize) {
          shapes.push(voidSize);
        }
      }
    }
  }

  return shapes;
}

function makeMonochromeCopy(data, width, height, blackThreshold = 0.95, invert = false) {
  let threashold = Math.round(blackThreshold * 255 * 3);
  const buffer = new Uint8Array(new ArrayBuffer(height * width));

  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      const start = (y * width + x) * 4;

      const r = data[start + 0];
      const g = data[start + 1];
      const b = data[start + 2];
      const a = data[start + 3];

      let color = (((r + g + b) < threashold) || a < 255) ? 0 : 255;
      if (invert) {
        color = 255 - color;
      }

      buffer[(y * width + x)] = color;
    }
  }
  return buffer;
}


function makeMonochrome(data, width, height, blackThreshold = 0.95, invert = false) {
  let threashold = Math.round(blackThreshold * 255 * 3);

  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      const start = (y * width + x) * 4;

      const r = data[start + 0];
      const g = data[start + 1];
      const b = data[start + 2];

      let color = ((r + g + b) < threashold) ? 0 : 255;
      if (invert) {
        color = 255 - color;
      }

      data[start + 0] = color;
      data[start + 1] = color;
      data[start + 2] = color;
      data[start + 3] = 255;
    }
  }
}

export {
  findRectangles, mergeRectanglesCloseToEachOther, makeMonochrome
}
