import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

import JsonTextEditor from '../common/editors/JsonTextEditor';
import IntentionsSelector from './IntentionsSelector';
import TypeSelector from './TypeSelector';
import { IconButton } from '../common/IconButton';
import ArticleEditor from './ArticleEditor';
import SymptomLegoEditor from './SymptomLegoEditor';
import TextFieldEditor from '../common/editors/TextFieldEditor';
import SourceEditor from './SourceEditor';
import { LegoStateSwitch } from './LegoStateSwitch';
import ModalManager from '../ModalManager';
import EventEmitter from 'events';
import MultipleContextsEditor from './MultipleContextsEditor';
import FuseboxLegoEditor from './fusebox-editor/FuseboxLegoEditor';
import { SingleImageUrlEditor } from '../common/images/SingleImageUrlEditor';
import JsonListEditor from '../common/editors/JsonListEditor';
import JsonGUIEditor from "../common/editors/JsonGUIEditor";
import TextDiff from '../test-sets/TextDiff';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import InputMultipleSemantics from '../common/editors/InputMultipleSemantics';
import LegoLabelsSelector from './LegoLabelsSelector';
import BadgeId from '../common/BadgeId';
import LegoContextSummary from './LegoContextSummary';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import WarningLightsSymptomLegoEditor from './WarningLightsSymptomLegoEditor';
import { ImagePreview } from '../images/ImagePreview';
import NotesEditor from '../common/editors/NotesEditor';
import { LocalesSelector } from './LocalesSelector';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import ArticleMarkupRichTextEditor from './article-editor/ArticleMarkupRichTextEditor';


export default class LegoEditor extends Component {
  static contextType = LegoAdminPageContext;

  constructor(props) {
    super(props);

    this.modalActionsBus = new EventEmitter();

    this.state = {
      original: this.props.lego,
      changedObject: _.cloneDeep(this.props.lego),
    };

    this.dataEditorRef = React.createRef();

    this.legoForceUpdateEventService = window.client.service('/services/legos-force-update-event');
    this.legoTemplateRenameService = window.client.service('/services/legos/rename-fusebox-template');
  }

  componentDidMount() {
    if(this.state.changedObject.context && !_.isArray(this.state.changedObject.context)) {
      this.editField('context', [this.state.changedObject.context]);
    }
  }

  async defaultSave(changedLego, closeDialog) {
    if (changedLego._id) {
      changedLego.updatedBy = this.context.page.getLoggedUserSignature();
      const res = await this.context.page.service('/services/legos').update(changedLego._id, changedLego);
      if (closeDialog) {
        this.context.page.modalActionsBus.emit('close');
      }
      if(this.props.onAfterSave) {
        this.props.onAfterSave(res);
      }
      return res;
    } else {
      alert('Default LegoEditor cannot create legos without an onSave prop handling it');
    }
  }

  editField(field, newValue) {
    if(this.state.original?.labels?.includes('readonly')) {
      if(!confirm('The lego is readonly, are you sure you want to change it?')) {
        return;
      }
    }
    let updated = this.state.changedObject;
    _.set(updated, field, newValue);
    this.setState({ changedObject: updated }, () => this.checkChanges());
  }

  checkChanges() {
    const areEqual = _.isEqual(this.state.changedObject, this.state.original);

    this.setState({ changed: !areEqual });
  }

  discardChanges() {
    this.setState({changed: false, changedObject: _.cloneDeep(this.state.original)})
  }

  openModalDiffChanges() {
    this.modalActionsBus.emit('open', <div>
      <div className={'p-2 bg-light monospace small break-word-all'}>
        <TextDiff type={'json'} inputB={JSON.stringify(this.state.changedObject, true, 2)} ellipsis={100}
                  inputA={JSON.stringify(this.state.original, true, 2)}/>
      </div>
    </div>, false);
  }

  async onSave(lego, closeDialog) {
    if(this.state.changed) {
      let res;
      if (this.props.onSave) {
        res = await this.props.onSave(lego, closeDialog);
      } else {
        res = await this.defaultSave(lego, closeDialog)
      }

      // In case this is an existing fusebox template, confirm if dependant fuseboxes should be updated
      if (lego.type === 'fuseboxTemplate' && !lego.__new) {
        if (!_.isEmpty(lego.data[0].templateRenameMap)) {
          if (confirm('The fusebox template has a rename map, do you want to apply that rename map to all the fuseboxes using the template and then clean it?')) {
            res = await this.legoTemplateRenameService.update({ query: { 'templateId': lego._id } }, {});
            // There is no point in not updating any other fusebox... no need to ask
            await this.legoForceUpdateEventService.find({ query: { 'data.templateId': lego._id } });
            return res;
          }
        }

        if (confirm('Update fuseboxes that depend on this template?')) {
          await this.legoForceUpdateEventService.find({ query: { 'data.templateId': lego._id } });
        }
      }

      if (lego.type === 'fusebox') {
        let translations = _.filter(lego.labels, l => l.match(/translatedTo:/));

        if (translations.length && confirm(`Fusebox has ${translations.length} translations, do you want to update them?`)) {
          this.dataEditorRef.current?.openTranslateDialog();
        }
      }

      return res;
    } else {
      this.props.onCancel();
    }
  }

  getDataEditor(lego, data) {
    if (lego.type === 'article' ) {
      // Use updatedAt as key to ensure that when a 'save' is made, the article editor gets rebuilt
      // This is useful for when new references have been added
      return <ArticleEditor key={(lego.updatedAt || "new").toString()} onChange={(json) => this.editField('data', json)} value={data}/>;
    } else if (lego.type === 'image') {
      return <div>
        <SingleImageUrlEditor large={true} onChange={(json) => this.editField('data', json)} value={data}/>

        <SingleTextEditor value={lego.data?.semanticVariation}
                          label={'Semantic variation'}
                          placeholder={'None (default)'}
                          className={"mt-1"}
                          small
                          onChange={(text) => this.editField('data.semanticVariation', text)}
        />
      </div>
    } else if (lego.type === 'fuseboxTemplate' || lego.type === 'fusebox') {
      return <FuseboxLegoEditor ref={this.dataEditorRef} isTemplate={lego.type === 'fuseboxTemplate'} lego={lego} onChange={(json) => this.editField('data', json)} value={data} changed={this.state.changed}  onReload={() => this.refreshDbLego()}/>;
    } else if (lego.type === 'text') {
      return <TextFieldEditor rows={20} onChange={(json) => this.editField('data', json)} value={data}/>;
    } else if (lego.type === 'subarticle') {
      return <ArticleMarkupRichTextEditor value={data} onChange={html => this.editField('data', html)}/>;
    } else if (lego.type === 'list') {
      return <JsonListEditor className={'list-editor'} treatValuesAsString={true} valueToDiff={this.state.original?.data} onChange={(json) => this.editField('data', json)} value={data}/>;
    } else if (lego.type === 'symptom' && lego.intentions?.includes('warning-lights')) {
      return <WarningLightsSymptomLegoEditor key={(lego.updatedAt || "new").toString()} onChange={(json) => this.editField('data', json)} value={data} semantics={lego.semantics}/>;
    } else if (lego.type === 'symptom') {
      return <SymptomLegoEditor key={(lego.updatedAt || "new").toString()} onChange={(json) => this.editField('data', json)} value={data}/>;
    } else if (lego.type === 'data' && lego.intentions?.includes('warning-lights-incomplete')) {
      const schema = {
        url: (val, onChange) => <div className={'d-flex'}>
          <SingleTextEditor small className={'zoom-90'} value={val} onChange={onChange}/>
          {val && val.match(/(jpe?g|png)$/i) ? <ImagePreview img50 url={val}/> : null}
        </div>
      };
      return <JsonGUIEditor rows={20} schema={schema} editOnClick={true} onChange={(json) => this.editField('data', json)} value={data}/>;
    } else {
      if(_.isObject(data)) {
        return <JsonGUIEditor rows={20} editOnClick={true} onChange={(json) => this.editField('data', json)}
                               value={data}/>;
      } else {
        return <JsonTextEditor rows={20} className={'monospace'} onChange={(json) => this.editField('data', json)}
                               value={data}/>;
      }
    }
  }

  refreshDbLego() {
    this.context.page.runAsync(async () => {
      const freshLego = await this.context.page.service('services/legos').get(this.state.original._id);
      this.setState({ original: freshLego}, () => this.discardChanges());
    });
    // this.props?.onRefresh();
  }

  render() {
    let l = this.state.changedObject;
    let changed = this.state.changed;
    let { _id, type, intentions, semantics, locales, context, generatedContext, data, updatedAt, source, labels, notes, state, createdAt, clonedId, __unsaved, __new, __deleted } = l;

    let rowClasses = 'p-1 ';
    if (__new) {
      rowClasses += ' bg-light-success';
    }
    if (__unsaved) {
      rowClasses += ' bg-light-warning';
      console.log(l)
    }
    if (__deleted) {
      rowClasses += ' bg-light-danger';
    }

    if (changed) {
      rowClasses += ' bg-light-warn';
    }

    if (labels?.includes('readonly')) {
      rowClasses += ' bg-light-violeta';
    }

    let twoCols = _.includes(['article'], type);
    let twoEvenCols = _.includes(['image'], type);

    let editTime = '';
    if (updatedAt) {
      editTime = ((updatedAt === createdAt) ? 'Created ' : 'Edited ') + dayjs(updatedAt).fromNow();
    }

    let btnCompare = null;
    if (changed) {
      btnCompare = <IconButton icon={'compare'} level={'primary'} description={'See diff of changes'}
                               onClick={this.openModalDiffChanges.bind(this)}>See diff</IconButton>;
    }

    let readonlyWarning = null;
    if(labels?.includes('readonly') && changed) {
      readonlyWarning = <div className={'alert alert-danger'}>WARNING: This lego is supposed to be <strong>READ ONLY</strong>, you should not change it!</div>
    }

    return <div>
      <LeftRightLayout className={rowClasses}>
        <div className={'h5 mb-0 d-flex align-items-center'}>
          <span className={'small'}>
            <span className={'ml-2'}><BadgeId id={_id}/></span>

            <span className={'text-info small ml-2 mr-2 align-middle'} title={updatedAt}>{editTime}</span>
          </span>

          <IconButton icon={'code'} level={'primary'} onClick={() => {
            this.modalActionsBus.emit('open', <div>
              <pre className={'small'}>{JSON.stringify(this.state.changedObject, true, 2)}</pre>
            </div>, false);
          }}/>

          {btnCompare}

          { clonedId ? <a target={'_blank'} href={`https://legos.startmycar.com/legos/fusebox?editing="${clonedId}"`} className={'small'}><span className={'badge badge-dark'}>Cloned from: <code>{clonedId}</code></span></a> : null}
        </div>

        <div>
          <LocalesSelector onChange={(locales) => this.editField('locales', locales)} value={locales}/>
          &nbsp;&nbsp;&nbsp;
          <LegoStateSwitch onChange={(newState) => this.editField('state', newState)} value={state}/>
        </div>
      </LeftRightLayout>

      { readonlyWarning }

      <div className={(twoCols || twoEvenCols) ? 'row m-0' : ''}>
        <div className={twoCols ? 'col-4' : (twoEvenCols ? 'col-6' : '')}>
          <table className={'table table-sm LegoEditorTable'}>
            <tbody>
            <tr>
              <td style={{ width: '120px' }} className={'text-right'}>Type:</td>
              <td style={{ width: '40%' }}>
                <TypeSelector value={type} onChange={(type) => this.editField('type', type)}/>
              </td>
              <td className={'text-right'}>Intentions:</td>
              <td style={{ width: '40%' }}>
                <IntentionsSelector value={intentions}
                                    onChange={(intentions) => this.editField('intentions', intentions)}/>
              </td>
            </tr>

            <tr>
              <td className={'text-right'}>
                <div>Semantics:</div>
              </td>
              <td colSpan={3}>
                <InputMultipleSemantics value={semantics} onChange={(semantics) => this.editField('semantics', semantics)}/>
              </td>
            </tr>

            <tr>
              <td className={'text-right'}>Context:</td>
              <td colSpan={3}>
                {/*<LegoContextSummary context={context}/>*/}
                <MultipleContextsEditor onChange={(json) => this.editField('context', json)} value={context}/>
              </td>
            </tr>

            {
              generatedContext?.length
              ?
              <tr>
                <td className={'text-right small pt-0 text-secondary'}>Generated:</td>
                <td colSpan={3} className={'zoom-75'}>
                  <LegoContextSummary inline context={generatedContext}/>
                </td>
              </tr> : null
            }

            <tr>
              <td className={'text-right'}>Source:</td>
              <td className={''}>
                <SourceEditor onChange={(json) => this.editField('source', json)} value={source}/>
              </td>
              <td className={'text-right'}>Labels:</td>
              <td className={''}>
                <LegoLabelsSelector onChange={(values) => this.editField('labels', values)} value={labels || []}/>
              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div className={twoCols ? 'col-8' : (twoEvenCols ? 'col-6' : '')}>
          {this.getDataEditor(l, data)}
        </div>
      </div>

      <div className={'mt-3 ' + rowClasses}>
        <button onClick={() => this.context.page.runAsync(() => this.onSave(l, true))}
                className={`btn btn-${changed ? 'primary' : 'secondary'} mr-2`}>{__new ? 'Save new lego' : changed ? 'Save and close' : 'Ok'}</button>


        {
          changed
            ?
            <>
              <button onClick={() => this.props.onCancel()} className={'btn btn-default mr-2'}>Cancel</button>

              <button onClick={() => {
                this.context.page.runAsync(async () => {
                  let updatedLego = await this.onSave(l);
                  // It could fail and return undefined
                  if(updatedLego) {
                    this.setState({ original: updatedLego, changedObject: _.cloneDeep(updatedLego) });
                    this.checkChanges();
                  }
                })
              }} className={'btn btn-default'}>Save changes</button>
            </>
            :
            null
        }
      </div>

      <div>
        {/*<div className={'border border-left-0 border-right-0 border-bottom-0 mb-4'}>*/}
          <NotesEditor value={notes} onChange={updatedNotes => this.editField('notes', updatedNotes)}/>
        {/*</div>*/}
      </div>
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}
