import React from 'react';
import _ from 'lodash'

export default class HighlightedText extends React.PureComponent {
  render() {
    let {regex, text, highlightClass, ... other} = this.props;

    highlightClass = highlightClass || 'bg-highlight'


    if(!regex) {
      return text;
    } else {
      if(_.isString(regex)) {
        regex = new RegExp(_.escapeRegExp(regex), 'gi');
      }

      if(!regex.flags.startsWith('g')) {
        regex = new RegExp(regex.source, 'g'+regex.flags);
      }

      let spans = [];

      let lastRes = 0, result;
      while ((result = regex.exec(text || ""))) {
        let {index, '0': match} = result;
        let past = text.substring(lastRes, index);
        if(past) {
          spans.push(past)
        }
        // Prevent the loop from hanging infinitely
        if (regex.lastIndex === result.index) {
          regex.lastIndex++;
        }
        spans.push(<span key={index} className={highlightClass}>{ match }</span>)
        lastRes = index+match.length;
      }

      let past = text.substring(lastRes, text.length);
      if(past) {
        spans.push(past)
      }

      return <span {... other}>{spans}</span>;
    }
  }
};
