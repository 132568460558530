import React, { Component } from 'react';

import _ from 'lodash';

import { FUSE_COLORS, FUSE_NAMES_EN, FUSE_NAMES_PT, FUSE_NAMES_ES, FUSE_SIZES, getSemanticIcon } from './FuseboxData.mjs';

let FUSE_NAMES_LOCALIZED;
// Unused locales will be tree-shaked
if(window.config?.locale === 'en') {
  FUSE_NAMES_LOCALIZED = FUSE_NAMES_EN;
} else if(window.config?.locale === 'pt') {
  FUSE_NAMES_LOCALIZED = FUSE_NAMES_PT;
} else {
  FUSE_NAMES_LOCALIZED = FUSE_NAMES_ES;
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


function contrastRequiresBlack(bgColor) {
  const {r,g,b} = _.isString(bgColor) ? hexToRgb(bgColor) : bgColor
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return (yiq >= 128);
}

function fuseImageFormat(fuse) {
  let { type, format } = fuse.kind || {};
  if(type) {
    let imageSpecification = FUSE_SIZES[type] && (FUSE_SIZES[type][format] || FUSE_SIZES[type]['all']);

    if (FUSE_SIZES[type] && fuse.vertical) {
      imageSpecification = FUSE_SIZES[type][format + "-vertical"] || FUSE_SIZES[type]['all-vertical'] || imageSpecification;
    }

    return imageSpecification;
  }
}

function mergeLayoutBoxes({left, top, width, height, ... other}, l2) {
  const newLeft = Math.min(left, l2.left);
  const newTop = Math.min(top, l2.top);
  return {
    left: newLeft,
    top: newTop,
    width: Math.max(left+width, l2.left+l2.width) - newLeft,
    height: Math.max(top+height, l2.top+l2.height) - newTop,
    ... other
  }
}

function areTouching({left, top, width, height, ... other}, f2) {
  return !((top + height) < f2.top
    || (left + width) < f2.left
    || top > (f2.top + f2.height)
    || left > (f2.left + f2.width))
}

function rotateAspectAware(cx, cy, x, y, angle, aspectRatio) {
  // Coordinates are % of a box with a certain aspect ratio, so when rotating points, first convert to 1:1 box,
  // and then convert the result again
  cy = cy * aspectRatio;
  y = y * aspectRatio;

  // Rotate in the same coordinate system as CSS (Y axis increases downwards)
  const radians = -(Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (x - cx)) - (sin * (-y + cy)) + cx,
    ny = (cos * (-y + cy)) + (sin * (x - cx)) - cy;

  return [nx, -ny / aspectRatio];
}

class FuseboxDiagram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // fusebox: this.preprocessDiagram(this.props.fusebox),
      selected: this.props.selected || [],
      maxHeight: 100,
      maxWidth: 100,
      showSearchBar: false
    }

    this.containerRef = React.createRef();
    this.titleRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);

    let fuseboxId = Math.random();
  }

  // Used from the .pug fusebox page through React's component ref
  selectFuseByIndex(fuseIndex) {
    let matchingFuse = this.props.fusebox.fuses[fuseIndex];
    if(matchingFuse) {
      this.selectFuse(matchingFuse)
    }
  }

  selectFusesByIndexes(fuseIndexes) {
    let matchingFuses = _.compact(fuseIndexes.map(fuseIndex => this.props.fusebox.fuses[fuseIndex]));
    this.setState({ selected: matchingFuses, hoverSelection: true });
  }

  // Used from the .pug fusebox page through React's component ref
  hoverFuseByIndex(fuseIndex) {
    let matchingFuse = this.props.fusebox.fuses[fuseIndex];
    if(matchingFuse) {
      this.hoverFuse(matchingFuse)
    }
  }

  selectFuse(fuse, e) {
    if(fuse) {
      this.setState({ selected: [fuse], hoverSelection: false });
      if(e) {
        e.stopPropagation();
      }
    } else {
      this.setState({ selected: [], hoverSelection: false });
    }

    if(this.props.onSelectFuse) {
      this.props.onSelectFuse(fuse, e);
    }
  }

  hoverFuse(fuse, e) {
    if (fuse && (!this.state.selected.length || this.state.hoverSelection)) {
      this.setState({ selected: [fuse], hoverSelection: true });
    } else if (this.state.hoverSelection) {
      this.setState({ selected: [], hoverSelection: false });
    }

    if (this.props.onHoverFuse) {
      this.props.onHoverFuse(fuse, e);
    }
  }

  renderFuse(fuse, viewBox, i) {
    let {kind, layout, id, vertical, onlyBlockSelection, description, npc, option, totalOptions} = fuse;

    if(!layout?.width) {
      if(this.props.development && !this.props.marks) {
        return <span className={'badge badge-danger m-1'} key={id + i}>{id}</span>
      } else {
        return null
      }
    }

    let { amp, variation, type, format, notUsed } = kind;

    // Cheap way to reuse the same image in different fuse formats
    format = (format || "")
      .replace(/cartridge-flf$/i, 'cartridge-flm')

    let colorClass = '';

    if(amp || (type === 'fuse' && notUsed === false)) {
      colorClass += ` fuse-amp-${(amp || '').toString().replace('.', '_')}`;
    }

    if(variation && !notUsed) {
      colorClass += ` fuse-var-${variation}`;
    }

    if(notUsed === true || (type === 'fuse' && notUsed !== false && !amp)) {
      colorClass += ` ${type}-empty`;
      colorClass += ` fuse-amp-empty`;
    }

    if (vertical) {
      colorClass += " vertical"
    }

    if(totalOptions) {
      colorClass += ` option-${option}-of-${totalOptions}`;
    }

    const formatClass = format  ? `${type}-${format}` : ""

    let fuseStyle = this.getBoxCss(layout, viewBox);

    // Order components from right to left, and from their bottom
    fuseStyle.zIndex = `${Math.max(1, Math.round(10*(layout.top+layout.height) - layout.left))}`;

    if(layout.rotation) {
      fuseStyle.transform = `rotate(${layout.rotation}deg)`
    }
    if(layout.translateX) {
      fuseStyle.transform = ((fuseStyle.transform || '') + ` translateX(${layout.translateX})`).trim();
    }
    if(layout.translateY) {
      fuseStyle.transform = ((fuseStyle.transform || '') + ` translateY(${layout.translateY})`).trim();
    }


    let fuseClass = `fuse fuse-${type} ${colorClass} ${formatClass}`

    if(onlyBlockSelection) {
      fuseClass = `fuse fuse-transparent`
    }

    if(npc) {
      fuseClass += ' fuse-decoration';
    }

    if(!description && type !== 'fuse' && !npc && !this.props.development) {
      fuseClass += ' fuse-non-interactive';
    }

    const idSafeString = str => str.replace(" ", "");
    let fuseId = "fuse" + idSafeString(id);

    let inner = null;

    if (this.state.selected?.length || this.props.highlightedIds?.length) {
      if (_.includes(_.map(this.state.selected, 'id'), id) || _.includes(this.props.highlightedIds, id)) {
        if(!this.state.hoverSelection) {
          fuseClass += ' highlighted'
        } else {
          fuseClass += ' hovered'
        }
        fuseStyle.zIndex = '1001'
      } else {
        fuseClass += ' translucent'
      }
    }

    if (type === "fuse" && !onlyBlockSelection) {
      inner = <div className={'amp'}>{amp}<span>A</span></div>;
    }

    if (type === "relay") {
      let text = (layout.hideText || (!description && id.toString().length <= 4)) ? '' : id;
      inner = <div className={'relay'}><span className={'small'}>{text}</span></div>;
    }

    const fuseMark = this.props.marks?.[id.toString()];
    if(fuseMark) {
      fuseClass += ' marked';
    }

    if(!npc) {
      return <div
        onClick={(e) => this.selectFuse(fuse, e)}
        onMouseEnter={(e) => this.hoverFuse(fuse, e)}
        onMouseLeave={(e) => this.hoverFuse(null, e)}
        key={fuseId + '_' + i}
        className={fuseClass} style={fuseStyle}>
        <div className={'inner'}>{inner}</div>
        {fuseMark ? <span className={'fuse-mark'}>{fuseMark}</span> : null}
      </div>
    } else {
      return <div
        key={fuseId + '_' + i}
        className={fuseClass} style={fuseStyle}>
        <div className={'inner'}>{inner}</div>
        {fuseMark ? <span className={'fuse-mark'}>{fuseMark}</span> : null}
      </div>
    }
  }

  renderIcon(icon, viewBox, i, isLightBackground) {
    const {layout, fuseId, fuse, entity, arrow} = icon;

    if(!layout?.width) {
      return null
    }

    let iconStyle = this.getBoxCss(layout, viewBox);


    let iconClass = `fuseIcon fuseIcon-${entity}`;
    const iconSelected = _.includes(_.map(this.state.selected, 'id'), fuseId) || _.includes(this.props.highlightedIds, fuseId);
    if (this.state.selected?.length || this.props.highlightedIds?.length) {
      if (iconSelected) {
        if(!this.state.hoverSelection) {
          iconClass += ' highlighted'
        } else {
          iconClass += ' hovered'
        }
        iconStyle.zIndex = '1001'
      } else {
        iconClass += ' translucent'
      }
    }

    if(isLightBackground && layout.orientation !== 'overlay') {
      iconClass += ' iconDark';
    }

    if(layout.orientation) {
      iconClass += ` iconOrientation-${layout.orientation}`;
    }

    return <div
      onClick={(e) => this.selectFuse(fuse, e)}
      onMouseEnter={(e) => this.hoverFuse(fuse, e)}
      onMouseLeave={(e) => this.hoverFuse(null, e)}
      key={entity+i}
      className={iconClass} style={iconStyle}>
        <div className={'iconArrow'}></div>
    </div>
  }

  getBoxCss(layout, viewBox) {
    let { left, top, width, height } = viewBox;
    return {
      width: `${layout.width/(width/100)}%`,
      height: `${layout.height/(height/100)}%`,
      left: `${(layout.left - left)/width*100}%`,
      top: `${(layout.top - top)/height*100}%`,
    };
  }

  updateDimensions() {
    let {fitScreen, fitMargins} = this.props;

    fitMargins = {top: 10, left: 0, maxWidth: Infinity, ... (fitMargins || {})};

    if(this.state.showSearchBar) {
      fitMargins.top += 40;
    }

    let $container = fitScreen ? $(window) : $(this.containerRef.current);

    if($container.length) {

      let h = $container.outerHeight();
      let w = $container.outerWidth();
      if(fitScreen) {
        // For some reason, window.outerHeight returns null in recently opened tabs that still remain hidden
        // Also, innerHeight will change in mobile when the url bar appears/dissapears on scroll
        h = window.innerHeight;
      }

      const titleHeight = $(this.titleRef.current).outerHeight();

      let maxHeight = h - (this.state.fullScreen ? 0 : fitMargins.top) - titleHeight;
      let maxWidth = Math.min(fitMargins.maxWidth, w - ((this.state.fullScreen ? 0 : fitMargins.left)));

      // console.warn(`Updating diagram dimensions to ${maxHeight}x${maxWidth} (- title ${titleHeight}px)`)

      this.setState({maxHeight, maxWidth});
    } else {
      console.warn("No container length to update diagram dimensions")
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();

    // Sometimes the scrollbar is not yet renderer, producing incorrect width
    setTimeout(() => this.updateDimensions(), 1)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  toggleFullScreen() {
    const fullScreen = !this.state.fullScreen;
    this.setState({fullScreen}, () => this.updateDimensions())
    if(this.props.onFullScreenToggle) {
      this.props.onFullScreenToggle(fullScreen)
    }
  }

  render() {
    try {
      const { boxName, boxDescription, viewBox, fuses, icons, backgroundColor } = this.preprocessDiagram(_.cloneDeep(this.props.fusebox));

      const isLightBackground = backgroundColor && contrastRequiresBlack(backgroundColor);
      const diagramBackground = backgroundColor ? {backgroundColor} : {};

      let maxHeight = this.state.maxHeight;
      let maxWidth = this.state.maxWidth;

      let h,w=null;
      if (maxHeight / maxWidth > viewBox.aspectRatio) {
        h = viewBox.aspectRatio * maxWidth
        w = maxWidth
      } else {
        w = maxHeight / viewBox.aspectRatio
        h = maxHeight
      }
      let boxStyle = { height: `${h}px`, width: `${w}px` };

      let containerStyle = this.props.fitScreen ? {} : {height: `100%`, width: `100%`};

      let fullScreenClass = this.state.fullScreen ? 'fusebox-diagram--fullScreen' : '';

      let selectedInfo = null, selectedArrow;

      if (this.state.selected?.length) {
        let selected = _.filter(fuses, f => _.includes(_.map(this.state.selected, 'id'), f.id));
        // In some edge cases, the fuses are changed and an outdated selection remains
        let layout = selected[0]?.layout;
        if(layout) {
          let fuseCenterX = layout.left+layout.width/2;
          let diagramCenter = viewBox.left + viewBox.width/2;
          let fuseToTheLeft = fuseCenterX < diagramCenter;
          let globeSide = (fuseToTheLeft ? ' box-right' : ' box-left')
          selectedInfo = <div className={'selectedfuse ' + globeSide}>
            <div className={'globe'}> {
              // Use fuse.description in key in case fuseId is repeated, happens in some fusebox variations
              _.map(selected, fuse => {
                  let { amp, type, format } = fuse.kind || {};
                  const ampColor = amp ? { backgroundColor: FUSE_COLORS[fuse.kind.format]?.[amp] } : null;

                  const displayType = FUSE_NAMES_LOCALIZED.types[type] || type;
                  const displayFormat = FUSE_NAMES_LOCALIZED?.formats?.[type]?.[format] || '';

                  // if(ampColor)
                  //   debugger;
                let icons = this.getSemanticIcons(fuse.relatedEntities);
                let iconsImgs = icons.length ? <div className={'fuse-icons'}>
                  { icons.map(entity => {
                    return <div key={entity} className={`fuseIcon fuseIcon-${entity}`}/>;
                  }) }
                </div> : null;

                return <div className={'fuse-info'} key={fuse.id + (fuse.option || '')}>
                  <div className={'fuse-id-format'}>
                    <span className={'fuse-number'}>{fuse.id}</span>

                    <span className={'ml-1 fuse-format'}>
                      {displayType} {displayFormat}
                      {amp ? <span className={'ml-1 amp'} style={ampColor}>{fuse.kind.amp}A</span> : null}
                    </span>
                  </div>

                  <div className={'fuse-icon-description'}>
                    {iconsImgs}

                    <div className={'fuse-description'}>{fuse.description}</div>
                    {fuse.individualFusePages?.length ? fuse.individualFusePages.map(f => {
                      return f.urlWithAnchor? <div style={{fontSize: '12px'}} className={'pt-05'}>
                        <a className={'text-weight-normal'} href={f.urlWithAnchor}>{f.problemsTitle}...</a>
                      </div> : null;
                    }) : null}
                  </div>
                </div>;
                }
              )
            }
            </div>
          </div>;

          let x1 = w*(fuseCenterX-viewBox.left)/viewBox.width;
          let y1 = h*(layout.top+layout.height/2-viewBox.top)/viewBox.height;
          selectedArrow = <svg width={w} height={h}>
            <line x1={x1} y1={y1} x2={fuseToTheLeft ? w*0.8 : w*0.2} y2={h/2} stroke={'white'} strokeLinecap="round" strokeWidth={3}/>
            <circle cx={x1} cy={y1} r={7} stroke={'white'} fill={'none'} strokeWidth={1}/>
          </svg>
        }
      }

      let searchBar;
      if(this.state.showSearchBar) {
        searchBar = <div className={'fusebox-search-bar'}>
          <input type="text" placeholder="Search fuses..." className={'fusebox-search-bar__input'} onChange={(e) => this.search(e.target.value)}/>
        </div>
      }


      return <div ref={this.containerRef} style={containerStyle} className={`fusebox-diagram ${fullScreenClass} ${isLightBackground ? 'fusebox-diagram--light' : ''}`}>
        { searchBar}
        <div ref={this.titleRef} className={'text-center fusebox-title'}>
          {this.props.hideTittle ? null :
          <span>

              { boxName }

            <span onClick={()=> this.toggleFullScreen()} className={'text-white ml-2 margin'} title={'Full screen'}>
              <span alt={'Full screen'} className={`IconSvg IconSvg--${this.state.fullScreen ? 'ExitFullScreen' : 'FullScreen'}`}/>
            </span>
            </span>}

          </div>
          <div style={boxStyle} className={'fusebox-viewport'} onClick={(e) => this.selectFuse(null, e)}>
            <div className={'fusebox'} style={diagramBackground}>
              {
                _.map(fuses, (fuse, i) => this.renderFuse(fuse, viewBox, i))
              }

              {
                _.map(icons, (icon, i) => this.renderIcon(icon, viewBox, i, isLightBackground))
              }

              {selectedArrow}
            </div>
          </div>
          {selectedInfo}
          <span className={`fusebox-diagram__exit-fullscreen`} onClick={()=> this.toggleFullScreen()}>
            Exit full screen
            <span alt={'Close full screen'} className={`IconSvg IconSvg--ExitFullScreen`}/>
          </span>
        </div>
    } catch(err) {
      return <div className={'bg-dark text-white p-5'}>
        <div className={'text-center mb-3'}>There was a problem trying to build fusebox diagram, check that the diagram is finished</div>
        <div className={'alert alert-danger'}>{err.toString()}</div>
      </div>
    }
  }

  search(value) {
    console.log('Searching for', value)
  }

  mergeMultiBlockFuses(box) {
    // Find and merge multiblock fuses
    const blockTypes = ['fuse-block', 'fuse-mpf', 'fuse-mpf2'];
    let blockFusesIds = {};
    let extras = [];
    box.fuses = _.filter(box.fuses, (f, i) => {
      const type = f.kind?.type;

      if (f.layout && (blockTypes.includes(type) || f.kind?.format === 'micro3')) {
        const sameIdBlock = blockFusesIds[type]?.[f.id];
        let collidingBlocks = _.filter(_.values(blockFusesIds[type]), block => areTouching(block.layout, f.layout))

        if (sameIdBlock || collidingBlocks.length) {
          if (sameIdBlock) {
            sameIdBlock.description += '.\n\n' + f.description;
            return false;
          } else {
            let anchorBlock;
            for(const collidingBlock of collidingBlocks) {
              if(anchorBlock) {
                // F was already processed, merge other blocks that appear to be part of the block
                anchorBlock.layout = mergeLayoutBoxes(collidingBlock.layout, anchorBlock.layout);

                // If we are merging a block merely created to draw the block, delete it, the anchor already covers it
                if(collidingBlock.npc) {
                  extras = _.without(extras, collidingBlock)
                } else {
                  collidingBlock.onlyBlockSelection = true;
                }
              } else {
                anchorBlock = collidingBlock;
                if (!anchorBlock.npc) {
                  anchorBlock.onlyBlockSelection = true;
                  let newBlock = {
                    ..._.cloneDeep(anchorBlock),
                    id: 'multi__' + anchorBlock.id,
                    description: 'multiblock',
                    npc: true,
                    onlyBlockSelection: false
                  };
                  blockFusesIds[type][anchorBlock.id] = newBlock;
                  extras.push(newBlock);
                  anchorBlock = newBlock;
                }

                anchorBlock.layout = mergeLayoutBoxes(f.layout, anchorBlock.layout);
                // console.log(`Merged: ${collidingBlock.id} - ${f.id}`)
                f.onlyBlockSelection = true;

                anchorBlock = collidingBlock;
              }
            }
          }
        } else {
          blockFusesIds[type] = blockFusesIds[type] || {};
          blockFusesIds[type][f.id] = f;
        }
      }
      return true;
    });
    return box.fuses.concat(extras);
  }

  preprocessDiagram(originalBox) {
    let box = _.cloneDeep(originalBox)

    let aspectRatio = box.boxAspectRatio || 1;


    if (box.boxRotation) {
      _.each(box.fuses, fuse => {
        let { height, width, top, left, rotation } = fuse.layout || {};
        let { type, format } = fuse.kind || {};

        if (height) {
          switch (box.boxRotation) {
            case 90:
              fuse.layout.height = width;
              fuse.layout.width = height;
              fuse.layout.top = left;
              fuse.layout.left = 100 - top - height;
              break;
            case 180:
              fuse.layout.top = 100 - top - height;
              fuse.layout.left = 100 - left - width;
              break;
            case 270:
              fuse.layout.height = width;
              fuse.layout.width = height;
              fuse.layout.top = 100 - left - width;
              fuse.layout.left = top;
              break;
          }
        }
      });

      if(box.boxRotation === 90 || box.boxRotation === 270) {
        aspectRatio = 1/aspectRatio;
      }

      box.boxAspectRatio = aspectRatio;
    }

    box.fuses = this.mergeMultiBlockFuses(box);

    let fusesWithLayout = _.filter(box.fuses, f => f.layout?.width);

    // Detect which fuses have boxes that are already constructed "rotated" by shape
    _.each(fusesWithLayout, fuse => {
      // Mark used to rotate text in mini/standard vertical fuses
      let { height, width } = fuse.layout;
      let formatSize = fuseImageFormat(fuse);

      if (formatSize?.isVertical) {
        if ((width / 1.1) / aspectRatio > height) {
          fuse.vertical = true;
        }
      } else {
        if (width * 1.1 / aspectRatio < height) {
          fuse.vertical = true;
        }
      }

      // When rotating the original fusebox, some fuses that were already rotated due to being vertical
      if (formatSize?.asymetrical && box.boxRotation) {
        switch (box.boxRotation) {
          case 90:
            if (!fuse.vertical) {
              fuse.layout.rotation = 180 + (fuse.layout.rotation || 0);
            }
            break;
          case 180:
            // if (!fuse.vertical) {
              fuse.layout.rotation = 180 + (fuse.layout.rotation || 0);
            // }
            break;
          case 270:
            if (fuse.vertical) {
              fuse.layout.rotation = (fuse.layout.rotation || 0) - 180;
            }
            break;
        }
      }
    });

    // Re-scale fuses according to their type/format real world dimension
    let scaleMultiplier = parseFloat(box.boxScaleMultiplier);
    scaleMultiplier = isNaN(scaleMultiplier) ? 1: scaleMultiplier;

    let scale = this.computeAverageFuseScale(fusesWithLayout, box.boxAspectRatio) * scaleMultiplier;

    _.each(fusesWithLayout, fuse => this.changeSizeToScaleModel(fuse, box.boxAspectRatio, scale))

    // Remove all margins, find bounding box containing all fuse boxes
    let minY = 100;
    let minX = 100;
    let maxY = 0;
    let maxX = 0;

    _.each(fusesWithLayout, ({ layout: {top, left, height, width, rotation }, kind, description}) => {
      // if(kind?.type !== 'fuse' && !description) {
      //   return;
      // }

      let [x,y,x2,y2] = [left, top, left+width, top+height];
      if(rotation) {
        let a = box.boxAspectRatio;
        [x, y] = rotateAspectAware(x+width/2, y+height/2, x, y, rotation, box.boxAspectRatio);
        [x2, y2] = rotateAspectAware(left+width/2, top+height/2, x2, y2, rotation, box.boxAspectRatio);
      }

      minY = Math.min(minY, y, y2);
      minX = Math.min(minX, x, x2);
      maxY = Math.max(maxY, y, y2);
      maxX = Math.max(maxX, x, x2);
    });

    box.icons = this.buildIconsMarks(fusesWithLayout, box.boxAspectRatio, maxX - minX, maxY- minY, scale);
    _.each(box.icons, ({ layout: {top, left, height, width }}) => {
      let [x,y,x2,y2] = [left, top, left+width, top+height];
      minY = Math.min(minY, y, y2);
      minX = Math.min(minX, x, x2);
      maxY = Math.max(maxY, y, y2);
      maxX = Math.max(maxX, x, x2);
    });

    // Leave room for icons that indicate common fuses
    let gapForIconsVertical = 0;
    let gapForIconsHorizontal = 0;

    let vertRatio = 1;
    let horiRatio = 1;
    if (box.boxAspectRatio > 1) {
      horiRatio = box.boxAspectRatio;
    } else {
      vertRatio = 1 / box.boxAspectRatio;
    }

    // if(!_.filter(fusesWithLayout, fuse => (fuse.relatedEntities || []).length).length) {
    //   gapForIconsVertical = 0;
    //   gapForIconsHorizontal = 0;
    // }

    let margin = 2;

    // Crop everything leaving a % margin horizontally and vertically. As the '3d' fuse drawings go bottom up, leave
    // 80% of the vertical margin for the upper part of the diagram. The horizontal margin is centered
    let croppedWidth = maxX - minX;
    let croppedHeight = maxY - minY;

    let AR = croppedHeight/croppedWidth;
    const marginVertical = Math.max(margin, gapForIconsVertical)*(AR > 1 ? 1 : 1/AR) * vertRatio * croppedHeight / 100;
    const marginHorizontal = Math.max(margin, gapForIconsHorizontal)*(AR > 1 ? AR : 1) * horiRatio * croppedWidth / 100;

    box.viewBox = {
      top: minY - marginVertical,
      left: minX - marginHorizontal,
      width: croppedWidth+2*marginHorizontal,
      height: croppedHeight+2*marginVertical,
    };
    box.viewBox.aspectRatio = (box.viewBox.height / box.viewBox.width)*box.boxAspectRatio

    // Find fuse ids with multiple options, and leave a mark so that they can be rendered split in 2 or 3
    let fusesById = _.groupBy(fusesWithLayout, 'id');
    _.each(fusesById, ( fuses, id) => {
      if(fuses.length > 1) {
        _.each(fuses, (f,i) => {
          f.option = i;
          f.totalOptions = fuses.length;
        })
      }
    });


    // _.each(fusesWithLayout, fuse => {
    //   fuse.layout.height = fuse.layout.height * ratioHeight;
    //   fuse.layout.width = fuse.layout.width * ratioWidth;
    //   fuse.layout.top = (fuse.layout.top - minY + marginVertical/2) * ratioHeight;
    //   fuse.layout.left = (fuse.layout.left - minX + marginHorizontal/2) * ratioWidth;
    // });

    // box.boxAspectRatio = box.boxAspectRatio * (croppedHeight + marginVertical) / (croppedWidth + marginHorizontal);
    // box.boxLayout = null;

    box.processed = true;

    return box;
  }

  getSemanticIcons(semantics) {
    return _.uniq(_.compact((semantics || []).map(sem => getSemanticIcon(sem))));
  }

  buildIconsMarks(fuses, aspectRatio, width, height, scaleInMM) {
    let fusesWithIcons = _.filter(fuses, f => !f.npc && this.getSemanticIcons(f.relatedEntities).length > 0)

    // Icon height and width in %
    // Scale according to the real size of the diagram once it is cropped
    // Ensure that even the icon is "physically" at least the height of a micro to ensure in small diagrams with few
    // fuses that the icons are not too small;
    let scale = Math.max(width, height) / 100;
    let minScaleInMM = scaleInMM*6*(aspectRatio > 1 ? 1/aspectRatio : 1)
    let iW = Math.max(5*scale, minScaleInMM);
    let iH = Math.max(5*scale, minScaleInMM);
    if(aspectRatio < 1) {
      iH = iH/aspectRatio;
    } else {
      iW = iW*aspectRatio;
    }

    // console.log('Aspect ratio', aspectRatio, iW, iH)

    const fixRotatedLayout = (layout) => {
      let { top, left, width, height, rotation, translateX, translateY} = layout;
      if(rotation === 90) {
        let newLeft = left + width / 2;
        let newTop = top + height / 2;
        let newWidth = height * aspectRatio;
        let newHeight = width / aspectRatio;

        return {
          left: newLeft - newWidth / 2 - newWidth*parseFloat(translateY || '0')/100,
          top: newTop - newHeight / 2 + newHeight*parseFloat(translateX  || '0')/100,
          height: newHeight,
          width: newWidth
        };
      } else {
        return layout;
      }
    };

    const isInside = ({top: ft, left: fl, width: fw, height: fh, rotation}, x, y) => {
      return ft < y && (ft + fh) > y && fl < x && (fl + fw) > x;
    }

    const collidesFusesOrIcon = ({centerX, centerY}) => {
      for(const {layout, kind, description} of fuses) {
        if(kind?.type !== 'other' && isInside(fixRotatedLayout(layout), centerX, centerY)) {
          return true;
        }
      }

      for(const {layout} of icons) {
        if(isInside(layout, centerX, centerY)) {
          return true;
        }
      }

      return false;
    };

    let icons = [];

    _.each(fusesWithIcons, fuse => {
      let { layout, id, kind, relatedEntities, vertical } = fuse;

      const { top, left, width, height, rotation} = fixRotatedLayout(layout);

      // Not using _.minBy, as lodash/underscore is not available in bundle
      const posTop = {centerX: left+width/2, centerY: top - iH/2, orientation: 'top'};
      const posBottom = {centerX: left+width/2, centerY: top + height + iH/2, orientation: 'bottom'};
      const posRight = {centerX: left+width+iW/2, centerY: top + height/2, orientation: 'right'};
      const posLeft = {centerX: left-iW/2, centerY: top + height/2, orientation: 'left'};

      // Fallbacks
      const posCenterLeft = {centerX: left+iW/4, centerY: top + height/2, orientation: 'center'};
      const posCenterBottom = {centerX: left+width/2, centerY: top + height, orientation: 'center'};

      const candidatePositions = aspectRatio < 1 ? [posTop, posBottom, posLeft, posRight] : [posLeft, posRight, posTop, posBottom ];


      let iconPos = null;
      if(kind && kind.type === 'relay') {
        iconPos = {centerX: left+width/2, centerY: top+height*0.32, overlay: true};
        // Remove relay text and show icon only
        fuse.layout.hideText = true;
      } else {
        for (const pos of candidatePositions) {
          if (!collidesFusesOrIcon(pos)) {
            iconPos = pos;
            break;
          }
        }
      }

      if(!iconPos) {
        const hasHorizontalShape = (width/aspectRatio) > (height*1.5);
        iconPos = hasHorizontalShape ? posCenterLeft : posCenterBottom;
      }
      const sizeCorrection = iconPos.orientation === 'center' ? 0.8 : 1

      icons.push({
        layout: {
          height: iH*sizeCorrection,
          width: iW*sizeCorrection,
          top: iconPos.centerY - iH/2*sizeCorrection,
          left: iconPos.centerX - iW/2*sizeCorrection,
          orientation: iconPos.overlay ? 'overlay' : iconPos.orientation
        },
        fuseId: id,
        fuse: fuse,
        entity: this.getSemanticIcons(relatedEntities)[0],
      })
    })

    return icons;
  }

  computeAverageFuseScale(fuses, aspectRatio) {
    let scales = [];
    _.each(fuses, (fuse) => {
      let { kind: { type, format }, layout, vertical } = fuse;

      let rule = fuseImageFormat(fuse);

      let width = layout.width;
      let height = layout.height * aspectRatio;

      if (rule && width && rule.width) {
        if (vertical && !rule.isVertical) {
          scales.push(Math.min(height / rule.width, width / rule.height));
        } else {
          scales.push(Math.min(width / rule.width, height / rule.height));
        }
      }
    });
    return scales.length && (_.sum(scales) / scales.length);
  }

  changeSizeToScaleModel(fuse, aspectRatio, scaleInMM) {
    // For each fuse, we discard the layout height and width, and only use the location (centerX and centerY)
    // of the diagram, and replace the dimensions with real world "at scale" dimensions.
    let { kind, layout, vertical, onlyBlockSelection } = fuse;

    let rule = fuseImageFormat(fuse);

    if (rule && layout.width && !onlyBlockSelection) {
      // If there is a special image for vertical box, don't rotate anything
      if (vertical && rule.isVertical) {
        vertical = false;
      }

      let { width, height, left, top } = layout;
      let centerX = left + width / 2;
      let centerY = top + height / 2;

      let w, h;

      let oX = rule.offsetX || 0;
      let oY = rule.offsetY || 0;

      if (rule.width && scaleInMM) {
        h = rule.height * (rule.pngH || 1) * scaleInMM;
        w = rule.width * (rule.pngW || 1) * scaleInMM;
      } else {
        if(vertical && !rule.dontRotateVertical) {
          h = width * (rule.pngH || 1);
          w = aspectRatio * height * (rule.pngW || 1);
        } else {
          w = width * (rule.pngW || 1);
          h = aspectRatio * height * (rule.pngH || 1);
        }
      }

      if (vertical && !rule.dontRotateVertical) {
        // Don't need to swap height and width because it is coming from the rule that it's not rotated
        layout.rotation = (layout.rotation || 0) + 90;
      }

      // When rotated, to make the rotation like the original rectangle, no matter the image of the fuse,
      // we first rotate and afterwards the image box is translated according to the image definition
      if (layout.rotation) {
        layout.translateX = `${oX * 100}%`;
        layout.translateY = `${oY * 100}%`;

        oX = 0;
        oY = 0;
      }

      layout.width = w;
      layout.height = h / aspectRatio;
      layout.left = centerX - w / 2 + w * oX;
      layout.top = centerY - (h / aspectRatio) / 2 + (h/ aspectRatio) * oY;
    }
  }
}

// For production
export default FuseboxDiagram

// // For development
// function FB(props) {
//   return <FuseboxDiagram {... props}/>
// }
// export default FB
