import React, { useEffect, useState } from 'react';
import { IconButton } from '../common/IconButton';

function getMouseEventRelativeLocation(e) {
  let { clientX, clientY, screenX, screenY } = e;

  // target could be the selection box, currentTarget is always the overlay
  const { left, top, width, height } = e.currentTarget.getBoundingClientRect();

  return { x: (clientX - left)/width*100, y: (clientY - top)/height*100 };
}

function getSelectedBox(start, end) {
  let left = Math.min(end.x, start.x);
  let top = Math.min(end.y, start.y);
  let width = Math.abs(end.x - start.x);
  let height = Math.abs(end.y - start.y);
  return { left, top, width, height };
}


export function BoxSelectionOverlay({onSelection, onCancel, onBoxMove, message}) {
  // const overlayRef = useRef();
  let [startPoint, setStart] = useState(null);
  let [endPoint, setEnd] = useState(null);
  let [mouseDownTime, setMouseDownTime] = useState(null);
  let [mousePosition, setMousePosition] = useState(null);

  const onMouseDown = (e) => {
    if (!startPoint || endPoint) {
      setStart(getMouseEventRelativeLocation(e));
      setMousePosition(getMouseEventRelativeLocation(e));
      setEnd(null);
    } else {
      setEnd(getMouseEventRelativeLocation(e));
    }
    setMouseDownTime(new Date().valueOf());
  };

  const onMouseUp = (e) => {
    // This allows BOTH click-dragging and click to start + click to end.
    // If the mouse up takes longer than 300ms, assume it is a click-drag
    if ((new Date().valueOf() - mouseDownTime) > 300) {
      setEnd(getMouseEventRelativeLocation(e));
    }
  };

  let currentSelection, onMouseMove;

  if (startPoint && !endPoint) {
    onMouseMove = (e) => setMousePosition(getMouseEventRelativeLocation(e));
  }

  // While the overlay is opened, capture escape/enter keys
  // Effect is binded to changes in endPoint, so that the onSelection always references the latest closure
  // with the last 'endPoint' value
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        onCancel();
      } else if (e.key === 'Enter' && endPoint) {
        e.preventDefault();
        onSelection({ ... getSelectedBox(startPoint, endPoint)});
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [endPoint])

  let confirmBtns = null;

  if (mousePosition || endPoint) {
    let end = mousePosition || endPoint;
    let { left, top, width, height } = getSelectedBox(startPoint, end);

    if(endPoint) {
      const onUseClick = (e) => {
        e.stopPropagation();
        onSelection({left, top, width, height})
      };

      let stylePosition = { left: left + '%', top: (top + height) + '%' };

      confirmBtns = <div  className={'BoxSelectionOverlay__btns'} style={stylePosition}>
        <IconButton size={'sm'}
                    className={'bg-success'}
                    icon={'done'}
                    onClick={onUseClick} onMouseDown={e => e.stopPropagation()}
                    level={'light'}>Ok</IconButton>

        <IconButton size={'sm'}
                    className={'ml-2 bg-dark'}
                    icon={'clear'}
                    onClick={() => onCancel()} onMouseDown={e => e.stopPropagation()}
                    level={'light'}>Cancel</IconButton>
      </div>;
    }

    const boxStyle = { left: left+'%', top: top+'%', width: width+'%', height: height+'%' };

    currentSelection = <div style={boxStyle} className={'BoxSelectionOverlay__box'}></div>;

    if(onBoxMove) {
      onBoxMove({left, top, width, height})
    }
  }


  return <div className={'BoxSelectionOverlay'}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              tabIndex={25}
              onMouseUp={onMouseUp}>

    <div className={'BoxSelectionOverlay__overlay'}>
      {currentSelection}
    </div>

    <div className={'BoxSelectionOverlay__message'}>
      <IconButton
                                                                 className={'bg-dark mr-2 align-top'}
                                                                 icon={'clear'}
                                                                 onClick={() => onCancel()}
                                                                 onMouseDown={e => e.stopPropagation()}
                                                                 onMouseUp={e => e.stopPropagation()}
                                                                 level={'light'}>Cancel</IconButton>
      {message || ''}
    </div>

    { confirmBtns }
  </div>;
}
