import React, { Component, useContext, useState } from 'react';
import _ from 'lodash';

import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import JsonListEditor from '../common/editors/JsonListEditor';
import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import useEditableLegoData from './data-caches/useEditableLegoData';
import { IconButton } from '../common/IconButton';


function ColorListEditor({onColorPick, selected, ... props}) {
  const getExtraHeader = () => [<th key={'color-preview'} colSpan={2} className={'text-center align-middle'}>preview</th>,];

  const getExtraCells = function (row, rowIndex) {
    let color = row.color;

    const onClick = () => onColorPick(color);

    return [
      <td key={'preview'} style={{ backgroundColor: color, width: '50px' }} className={'text-center p-1'}>
      </td>,

      <td key={'pick-color'} className={'text-center align-middle'}>
        {color === selected ? <span className={'text-success'}>SELECTED</span> : <a href={'javascript:void(0)'} onClick={onClick}>Use color</a> }
      </td>
    ];
  };

  return <JsonListEditor {...props} getExtraHeader={getExtraHeader} getExtraCells={getExtraCells}/>;
}

export default function ModalColorSelector({ value, onChange }) {
  const { page } = useContext(LegoAdminPageContext);
  const [colors, setColors, legoId] = useEditableLegoData({ type: 'list', intentions: 'definition', semantics: 'FUSEBOX_COLORS' });
  const [originalColors, setOriginalColors] = useState();

  if(colors && !originalColors) {
    setOriginalColors(_.cloneDeep(colors));
  }

  if (!colors) {
    return <div>Cargando...</div>;
  } else {
    const unsavedChanges = !_.isEqual(originalColors, colors);

    const saveChanges = () => {
      page.runAsync(async () => {
        let res = await page.service('/services/legos').update(legoId, {$set: {data: colors}});
        setOriginalColors(res.data);
        console.log('Updated lego', res);
      })
    }

    let onColorPick = (color) => {
      if(unsavedChanges && confirm('Do you wanna save changes in colors list?')) {
        saveChanges();
      }

      if (color && !color.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/)) {
        return alert(`Invalid HEX color "${color}", must be in the format "#ffffff", lowercase`);
      }
      onChange(color);
    };

    return <div>
      {value ?
      <div>
        <span style={{backgroundColor: value, width: '50px', height: '24px', display: 'inline-block'}} className={'mr-2 align-middle'}></span>

        <IconButton icon={'clear'} level={'danger'} onClick={() => onColorPick(undefined)}>Clear selected color '{value}'</IconButton>
      </div> : null }

      {unsavedChanges ? <div className={unsavedChanges ? 'bg-light-warn' : ''}><IconButton level={'success'} icon={'save'} onClick={saveChanges}>Save changes</IconButton></div> : null}

      <ColorListEditor onColorPick={onColorPick} value={colors} selected={value} onChange={(colors) => setColors(colors)}/>
    </div>
  }
}
