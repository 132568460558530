import _ from 'lodash';
import React from 'react';
import StatsTable from './StatsTable';

const modelKey = (idModelo, year) => `${idModelo}-${year}`;

export default class StatsFuseboxByManuals extends StatsTable {
  async fetchData() {
    const manuals = (await this.data.manuals()).manuals;

    this.manualsByModel = _.groupBy(manuals, m => m.idModelo);
    this.manualsByModelYear = _.groupBy(manuals, ({ idModelo, year }) => modelKey(idModelo, year));

    const {
      fuseboxPageviewsByModelId,
      modelYearsTrafficDistribution
    } = (await this.data.traffic('ar')).trafficBreakdown;

    this.fuseboxPageviewsByModelId = fuseboxPageviewsByModelId;
    this.modelYearsTrafficDistribution = modelYearsTrafficDistribution;
    this.fuseboxes = (await this.data.fuseboxes()).fuseboxes;

    let flattenedFuseboxes = _.flatten(_.map(this.fuseboxes, (fb) => {
      return _.map(_.compact(_.flatten([fb.context])), ({ modelId, year }) => ({ ...fb, modelId, year }));
    }))

    this.fuseboxByModelYear = _.groupBy(flattenedFuseboxes, ({ modelId, year }) => modelKey(modelId, year));
  }

  groups() {
    return _.map(this.manualsByModelYear, (manuals, modelYear) => {
      return {
        modelId: manuals[0].idModelo,
        year: manuals[0].year,
        manuals: manuals,
        fuseboxes: this.fuseboxByModelYear[modelYear] || []
      }
    })
  }

  groupKey({modelId, year}) {
    return modelKey(modelId, year);
  }

  groupWeight(key, { modelId, year, manuals }) {
    const yearWeight = (this.modelYearsTrafficDistribution[modelId] || {})[year] || 0;
    return yearWeight * (this.fuseboxPageviewsByModelId[modelId] || 0);
  }

  groupCoverage(key, { model, manuals, fuseboxes }) {
    return fuseboxes.length ? 1 : 0
  }

  displayGroup(modelKey, { modelId, manuals, fuseboxes}) {
    let pdfLinks = _.map(this.manualsByModelYear[modelKey], ({ manualPdfUrl, _id }, i) => {
        const hasFB = _.some(fuseboxes, fb => _.includes(_.map(fb, 'source.id'), _id));
        return <a className={'small m-1'+(hasFB ? ' border border-success rounded px-1': '')} key={i} target={'_blank'} href={manualPdfUrl}>
          PDF{i > 0 ? (i + 1) : ''}
        </a>;
      }
    )
    return <div>
      {modelKey} {pdfLinks}
    </div>;
  }
}
