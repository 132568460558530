import React, { useContext, useEffect, useState } from 'react';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import TextDiff from '../../test-sets/TextDiff';
import useLegoQuery from '../data-caches/useLegoQuery';
import { IconButton } from '../../common/IconButton';
import { SwitchInput } from '../../common/SwitchInput';
import LegoContextSummary from '../LegoContextSummary';

function fuseboxToText({fuses, templateId, templateRenameMap}) {
  return fuses.map(({id, kind, description}) => {
    let remappedId = templateRenameMap ? templateRenameMap[id] || id : id;
    return `[${remappedId}] ${kind?.amp || '-'} A - ${description}. `;
  }).sort().join('\n');
}
export default function FuseboxComparison({fuseboxIdA, fuseboxIdB, onMergeFuseboxLegos}) {
  let [a] = useLegoQuery({ _id: fuseboxIdA }, []);
  let [b] = useLegoQuery({ _id: fuseboxIdB }, []);

  let [normalizeStrings, setNormalize] = useState(false);
  let comparison;

  if (a && b) {
    comparison = <div>
      <div className={'d-flex justify-content-around'}>
        <div className={'d-flex justify-content-around align-items-center'}>
          <SwitchInput value={normalizeStrings} onChange={v => setNormalize(v)}>Ignore accents and case</SwitchInput>
        </div>

        <div className={'rounded border p-1'}>
          <del>{fuseboxIdA}: </del><LegoContextSummary context={a.context[0]}/>
          <IconButton icon={'merge'} level={'primary'} onClick={() => onMergeFuseboxLegos(fuseboxIdB, fuseboxIdA)}>
            Merge both and keep <del>the red version</del>
          </IconButton>
        </div>

        <div className={'rounded border p-1'}>
          <ins>{fuseboxIdB}: </ins><LegoContextSummary context={b.context[0]}/>
          <IconButton icon={'merge'} level={'primary'} onClick={() => onMergeFuseboxLegos(fuseboxIdA, fuseboxIdB)}>
            Merge both and keep <ins>the green version</ins>
          </IconButton>
        </div>
      </div>

      {a.data.map((boxA, i) => {
        let boxB = b.data[i];

        if(!boxB)
          return <div className={'alert alert-danger'}>Missing box {i+1}</div>

        let textA = fuseboxToText(boxA);
        let textB = fuseboxToText(boxB);
        if(normalizeStrings) {
          textA = textA.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ +/g, ' ').toLowerCase();
          textB = textB.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ +/g, ' ').toLowerCase();
        }

        return <div key={i}>
          <hr/>
          <h5 className={'mt-2'}>Box {i+1} (<TextDiff inputA={boxA.boxName}  inputB={boxB.boxName}/> )</h5>
          Templated id: <TextDiff inputA={boxA.templateId} inputB={boxB.templateId} type={'sentences'}/>
          <br/>
          Fuses:
          <br/>
          <TextDiff inputA={textA} inputB={textB} type={'words'}/>
        </div>;
      })}
    </div>;
  } else {
    comparison = <div className={'full-screen-center'}>Loading fuseboxes #{fuseboxIdA} and #{fuseboxIdB}</div>;
  }

  return <div className={''}>
    {comparison}
  </div>;
}
