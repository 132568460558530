export function dataURItoBlob(dataURI, format) {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) { // eslint-disable-line
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: format });
}

export async function convertToJPG(file, jpgQuality = 0.80) {
  return new Promise((resolve, reject) => {
    if (file.type.match('image.*')) {
      const img = new Image();

      const reader = new FileReader();

      // Read in the image file as a data URL.
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        if (evt.target.readyState === FileReader.DONE) {
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, img.width, img.height);
            let dataUrl = canvas.toDataURL('image/jpeg', jpgQuality);

            let blob = dataURItoBlob(dataUrl);
            let newFile = new File([blob], file.name.replace(/\.png$/i, '.jpg'), { type: 'image/jpeg' });
            resolve(newFile);
          };
          img.src = evt.target.result;
        }
      };
    } else {
      reject('Not an image ' + file);
    }
  });
}
