import React, { Component } from 'react';

export default class SingleTextEditor extends Component {
  render() {
    let {className, small, value, label, placeholder, onChange, onBlur, ... otherProps} = this.props;
    let extraClasses = ' ';

    if(!label) {
      extraClasses += (className || "") ;
    }

    if(small) {
      extraClasses+= ' form-control-sm'
    }

    onBlur = onBlur || (() => null);

    let input = <input type={'text'} className={'form-control' + (true ? '' : 'text-danger') + extraClasses}
                       spellCheck="false"
                       onChange={e => onChange(e.target.value)}
                       onBlur={e => onBlur(e.target.value)}
                       value={value || ""} placeholder={placeholder}
      { ... otherProps}
    />;

    if(label) {
      return <div className={"input-group"+(small? ' input-group-sm ': ' ')+(className || "")}>
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">{label}</span>
        </div>
        {input}
      </div>
    } else {
      return input
    }
  }
}
